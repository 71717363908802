import axios from 'axios';
import { UPLOAD_FILE_FOR_REPORT } from '../../../constants';
import moment from 'moment';

export const uploadFileForReport =
  (data, setSelectedNewMedia, onSuccess, onError) => (dispatch) => {
    const bodyFormData = new FormData();
    // bodyFormData.append('files', data.files);
    for (let i = 0; i < data.files.length; i++) {
      bodyFormData.append('files', data.files[i]);
    }
    bodyFormData.append('reportId', data.reportId);
    bodyFormData.append('tags', data?.tags || []);
    bodyFormData.append('date', moment(new Date()).format('YYYY-MM-DD'));
    const token = localStorage.jwtToken;
    dispatch(addUploadFileForReport());
    axios({
      method: 'post',
      url: `/report/uploadFile`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        await setSelectedNewMedia(res.data.data);
        await onSuccess();
        dispatch(setUploadFileForReport(res));
      })
      .catch((err) => {
        dispatch(errorUploadFileForReport(err.response));
        onError(err.response);
      });
  };

export const addUploadFileForReport = () => ({
  type: UPLOAD_FILE_FOR_REPORT.LOAD,
});

export const setUploadFileForReport = (data) => ({
  type: UPLOAD_FILE_FOR_REPORT.SUCCESS,
  payload: { file: data.data.data },
});

export const errorUploadFileForReport = (error) => ({
  type: UPLOAD_FILE_FOR_REPORT.FAIL,
  payload: error,
});
