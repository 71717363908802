import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import DateSelector from '../../../../components/DateSelector';
import DateTimeContainer from '../../../../components/DateTimeContainer';
import Dropdown from '../../../../components/Dropdown';
import NewFormHeader from '../../../../components/NewFormHeader';
import Textbox from '../../../../components/Textbox';
import TimeSelector from '../../../../components/TimeSelector';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../../redux/actions/adminActions/getUserListByType';
import {
  dateRangeArray,
  getDateRangeValue,
  getErrorMessage,
  isAdmin,
  isClient,
  jobStatusArray,
} from '../../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import MultiSelectDropdown from '../../../../components/MultiSelectDropdown';
import { fetchCategoryList } from '../../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import moment from 'moment';
import { useAlert } from 'react-alert';
import FormSwitch from '../../../../components/Switch';
import { createFilter } from '../../../../redux/actions/filterActions/addFilter';
import { fetchFilterById } from '../../../../redux/actions/filterActions/getFilterById';
import { updateFilter } from '../../../../redux/actions/filterActions/updateFilter';
import { deleteFilter } from '../../../../redux/actions/filterActions/deleteFilter';
import { Colors } from '../../../../utils/color';
import { fetchJobFormsList } from '../../../../redux/actions/jobActions/jobActions/jobFormsList';
import { fetchDropdownPropertyList } from '../../../../redux/actions/commonActions/dropdownPropertyList';

const JobNewFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [technicianList, setTechnicianList] = useState(
    props.technicianList || []
  );
  const [clientList, setClientList] = useState(props.clientList || []);
  const [adminList, setAdminList] = useState(props.adminList || []);
  const [jobFormList, setJobFormList] = useState([]);
  const [dropdownPropertyList, setDropdownPropertyList] = useState([]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props?.isEdit) {
      if (!isClient()) {
        props.fetchDropdownPropertyList(onError);
        props.fetchUserListByType('client', (err) => onError(err));
      }
      props.fetchUserListByType('admin', (err) => onError(err));
      // props.fetchUserListByType('technician', (err) => onError(err));
      props.fetchUserListByType('array', (err) => onError(err), '', [
        'admin',
        'technician',
        'superadmin'
      ]);
      props.fetchJobFormsList((err) => onError(err));
    }
  }, []);

  useEffect(() => {
    const data = props.jobFormsListData ? props.jobFormsListData : [];
    const newData = data.map((item) => ({
      title: item.formName,
      value: item._id,
    }));
    setJobFormList(newData);
  }, [props.jobFormsListData]);

  useEffect(() => {
    const data = props.dropdownPropertyListData
      ? props.dropdownPropertyListData
      : [];
    const newData = data.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setDropdownPropertyList(newData);
  }, [props.dropdownPropertyListData]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title:
            data?.type === 'client'
              ? item.lastName + ', ' + item.firstName
              : item.name,
          value: item._id,
          image: item.profilePicture,
          // color: item?.color,
        };
        if (data?.type === 'array') tempData.color = item?.color;
        return tempData;
      }) || [];
    if (data?.type === 'array') setTechnicianList(newData);
    // if (data?.type === 'technician') setTechnicianList(newData);
    if (data?.type === 'admin') setAdminList(newData);
    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  useEffect(() => {
    if (props.isEdit && props.selectedId) {
      props.fetchFilterById(props?.selectedId, onError);
    }
  }, [props.isEdit, props.selectedId]);

  useEffect(() => {
    if (props?.isEdit && props?.getFilterData) {
      let data = props.getFilterData.filter;
      const newAssignedMember = technicianList?.filter((item) =>
        data?.assignedMember?.some((el) => el === item.value)
      );
      const newClients = clientList?.filter((item) =>
        data?.client?.some((el) => el === item?.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        data?.property?.some((el) => el === item.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        data?.jobStatus?.some((el) => el === item?.value)
      );
      const newAdmins = adminList?.filter((item) =>
        data?.admin?.some((el) => el === item?.value)
      );
      const newJobFormId = jobFormList?.filter((item) =>
        data?.jobForm?.some((el) => el === item.value)
      );
      setFieldValue('filterName', props.getFilterData?.filterName || '');
      setFieldValue('jobForm', newJobFormId || []);
      setFieldValue('startDate', data?.startDate || null);
      setFieldValue('endDate', data?.endDate || null);
      setFieldValue('showAllJob', data?.showAllJob || false);
      setFieldValue('assignedTeamMember', newAssignedMember || []);
      setFieldValue('client', newClients || []);
      setFieldValue('property', newProperties || []);
      setFieldValue('admin', newAdmins || []);
      setFieldValue('jobStatus', newJobStatus || []);
      setFieldValue('selectedDateRange', data?.dateRange || 'All Time');
    }
  }, [
    props.getFilterData,
    clientList,
    jobStatusArray,
    adminList,
    technicianList,
    jobFormList,
    dropdownPropertyList,
  ]);

  useEffect(() => {
    if (props.selectedValues) {
      const newAssignedMember = technicianList?.filter((item) =>
        props.selectedValues?.assignedTeamMember?.some(
          (x) => x?.value === item.value
        )
      );
      const newClients = clientList?.filter((item) =>
        props.selectedValues?.client?.some((el) => el?.value === item?.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        props.selectedValues?.property?.some((el) => el?.value === item.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        props.selectedValues?.jobStatus?.some((el) => el?.value === item?.value)
      );
      const newAdmins = adminList?.filter((item) =>
        props.selectedValues?.admin?.some((el) => el?.value === item?.value)
      );
      const newJobFormId = jobFormList?.filter((item) =>
        props.selectedValues?.jobForm?.some((x) => x?.value === item.value)
      );
      setFieldValue('jobForm', newJobFormId || []);
      setFieldValue('startDate', props.selectedValues?.startDate || null);
      setFieldValue('endDate', props.selectedValues?.endDate || null);
      setFieldValue('showAllJob', props.selectedValues?.showAllJob || false);
      setFieldValue('assignedTeamMember', newAssignedMember || []);
      setFieldValue('client', newClients || []);
      setFieldValue('property', newProperties || []);
      setFieldValue('admin', newAdmins || []);
      setFieldValue('jobStatus', newJobStatus || []);
      setFieldValue(
        'selectedDateRange',
        props.selectedValues?.selectedDateRange || 'All Time'
      );
    }
  }, [
    props.selectedValues,
    clientList,
    jobStatusArray,
    adminList,
    technicianList,
    jobFormList,
    dropdownPropertyList,
  ]);

  const formik = useFormik({
    initialValues: {
      filterName: '',
      jobStatus: [],
      jobForm: [],
      client: [],
      admin: [],
      property: [],
      assignedTeamMember: [],
      startDate: '',
      endDate: '',
      showAllJob: false,
      selectedDateRange: 'All Time',
    },
    validationSchema: Yup.object().shape({
      filterName: Yup.string().required('Filtername is required.'),
    }),
    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = async (values) => {
    const data = { type: 'JOB', filterName: values.filterName };
    let filter = { dateRange: values.selectedDateRange };
    if (values.jobStatus.length > 0)
      filter.jobStatus = values.jobStatus.map((el) => el.value);
    if (values.client.length > 0)
      filter.client = values.client.map((el) => el.value);
    if (values.property.length > 0)
      filter.property = values.property.map((el) => el.value);
    if (values.admin.length > 0)
      filter.admin = values.admin.map((el) => el.value);
    if (values.assignedTeamMember.length > 0)
      filter.assignedMember = values.assignedTeamMember.map(
        (item) => item.value
      );
    if (values.jobForm.length > 0) {
      let jobFormId = values.jobForm.map((item) => item.value);
      filter.jobForm = jobFormId;
    }
    if (isAdmin()) filter.showAllJob = values.showAllJob;
    if (values.selectedDateRange === 'All Time') {
      if (values.startDate)
        filter.startDate = moment(values.startDate).format('YYYY-MM-DD');
      if (values.endDate)
        filter.endDate = moment(values.endDate).format('YYYY-MM-DD');
    }
    // else {
    //   const dateRange = await getDateRangeValue(values.selectedDateRange);
    //   filter.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
    //   filter.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    // }
    if (Object.keys(filter).length) data.filter = filter;
    if (props.isEdit) {
      data.filterId = props?.selectedId;
      props.updateFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    } else {
      props.createFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props?.isEdit ? Strings.EDIT_FILTER : Strings.NEW_FILTER}
          onNewClose={props.onClose}
        />
        {props.categoryListLoading ||
        props.UserListByTypeLoading ||
        props.dropdownPropertyListDataLoading ||
        props.jobFormsListLoading ||
        props.getFilterLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='filterName'
                  onChange={handleChange('filterName')}
                  value={values.filterName}
                  label={Strings.FILTER_NAME}
                  error={Boolean(touched.filterName && errors.filterName)}
                  helperText={touched.filterName && errors.filterName}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <label className={classes.attribute_title_text}>
                  {Strings.FILTER_ATTRIBUTES.toUpperCase()}
                </label>
                <MultiSelectDropdown
                  name='client'
                  onChange={(value) => setFieldValue('client', value)}
                  value={values.client}
                  label={Strings.CLIENT}
                  title={Strings.TYPE_TO_SEARCH + ' the client'}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={clientList}
                  searchable
                />
                {!isClient() && (
                  <MultiSelectDropdown
                    name='property'
                    onChange={(value) => setFieldValue('property', value)}
                    value={values.property}
                    label={Strings.PROPERTY}
                    // error={Boolean(touched.client && errors.client)}
                    // helperText={touched.client && errors.client}
                    title={Strings.TYPE_TO_SEARCH + ' the property'}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={dropdownPropertyList}
                    searchable
                  />
                )}
                {!isAdmin() && (
                  <MultiSelectDropdown
                    name='admin'
                    onChange={(value) => setFieldValue('admin', value)}
                    value={values.admin}
                    label={Strings.ADMIN}
                    title={Strings.TYPE_TO_SEARCH}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={adminList}
                    searchable
                  />
                )}
                <MultiSelectDropdown
                  name='assignedTeamMember'
                  onChange={(value) =>
                    setFieldValue('assignedTeamMember', value)
                  }
                  value={values.assignedTeamMember}
                  label={Strings.TEAM_MEMBER_ASSIGNED}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                />

                <Dropdown
                  name='selectedDateRange'
                  onChange={(e) => {
                    setFieldValue('selectedDateRange', e.target.value);
                    // const data = getDateRangeValue(e.target.value);
                    // setFieldValue('startDate', data?.startDate || null);
                    // setFieldValue('endDate', data?.endDate || null);
                  }}
                  value={values.selectedDateRange}
                  label={Strings.SELECT_DATE_RANGE}
                  // error={Boolean(touched.selectedDateRange && errors.selectedDateRange)}
                  // helperText={touched.selectedDateRange && errors.selectedDateRange}
                  title={Strings.SELECT_DATE_RANGE}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={dateRangeArray}
                  placeholder={Strings.SELECT_DATE_RANGE}
                />

                {/* {props.isActiveTabToday && ( */}
                {values.selectedDateRange === 'All Time' && (
                  <DateTimeContainer
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='startDate'
                      onChange={(value) => setFieldValue('startDate', value)}
                      value={values.startDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.START_DATE}
                    />
                    <DateSelector
                      name='endDate'
                      onChange={(value) => setFieldValue('endDate', value)}
                      value={values.endDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.END_DATE}
                    />
                  </DateTimeContainer>
                )}
                {/* )} */}
                <MultiSelectDropdown
                  name='jobStatus'
                  onChange={(value) => setFieldValue('jobStatus', value)}
                  value={values.jobStatus}
                  label={Strings.JOB_STATUS}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobStatusArray}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='jobForm'
                  onChange={(value) => {
                    setFieldValue('jobForm', value);
                  }}
                  value={values?.jobForm}
                  label={Strings.JOB_FORM}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={jobFormList}
                  hideAvatar
                />
                {/* <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  label={Strings.JOB_TITLE}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                /> */}
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_JOB}
                    value={values.showAllJob}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllJob', !values.showAllJob)
                    }
                  />
                )}
                <Button
                  type='submit'
                  text={props.isEdit ? Strings.UPDATE : Strings.SAVE}
                  loading={props.addFilterLoading || props.updateFilterLoading}
                  disabled={props.addFilterLoading || props.updateFilterLoading}
                  className={classes.add_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
                {props.isEdit && (
                  <Button
                    type='button'
                    text={Strings.REMOVE}
                    loading={props.deleteFilterLoading}
                    disabled={props.deleteFilterLoading}
                    className={classes.remove_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={Colors.RED}
                    onClick={() => {
                      props.deleteFilter(props.selectedId, onError, () => {
                        props.setIsFilterChange(true);
                        props.onClose();
                        props.filterDialogOnClose();
                      });
                    }}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  addFilterData: state.addFilterData.data,
  addFilterLoading: state.addFilterData.loading,

  getFilterData: state.getFilterData.data,
  getFilterLoading: state.getFilterData.loading,

  updateFilterLoading: state.updateFilterData.loading,

  deleteFilterLoading: state.deleteFilterData.loading,

  jobFormsListData: state.jobFormsListData.data,
  jobFormsListLoading: state.jobFormsListData.loading,

  dropdownPropertyListData: state.dropdownPropertyListData.data,
  dropdownPropertyListDataLoading: state.dropdownPropertyListData.loading,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  fetchUserListByType,
  createFilter,
  fetchFilterById,
  updateFilter,
  deleteFilter,
  fetchJobFormsList,
  fetchDropdownPropertyList,
})(JobNewFilter);
