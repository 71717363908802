import React, { useState, useEffect, useRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import DateTimeContainer from '../../../components/DateTimeContainer';
import DateSelector from '../../../components/DateSelector';
import TimeSelector from '../../../components/TimeSelector';
import Textbox from '../../../components/Textbox';
import Dropdown from '../../../components/Dropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import FormSwitch from '../../../components/Switch';
import {
  defaultMediaType,
  getErrorMessage,
  issuePriorityArray,
  issueStatusArray,
  issueTypeArray,
} from '../../../utils/appUtils';
import NewField from '../../../components/NewField';
import { AddIssueSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { createIssue } from '../../../redux/actions/jobActions/issuesActions/addIssue';
import { fetchIssueById } from '../../../redux/actions/jobActions/issuesActions/getIssueById';
import { updateIssue } from '../../../redux/actions/jobActions/issuesActions/updateIssue';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { fetchVendorDropDownList } from '../../../redux/actions/adminActions/vendorDropdownList';
import { confirmIssue } from '../../../redux/actions/jobActions/reportActions/confirmIssue';
import { fetchClientListName } from '../../../redux/actions/commonActions/clientListName';
import { fetchClientJobs } from '../../../redux/actions/commonActions/clientJobs';
import { icons } from '../../../utils/icons';
import MediaDetails from '../../Media/MediaDetails';
import classNames from 'classnames';
import Attachment from '../../../components/Attachment';
import { fetchClientById } from '../../../redux/actions/clientActions/getClientById';
import { uploadFile } from '../../../redux/actions/adminActions/uploadFile';
import { deleteFile } from '../../../redux/actions/adminActions/deleteFile';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import NewCategoryType from '../../Jobs/JobForms/CategoryTypes/NewCategoryType';
import AddNotes from '../NotesDialog';
import { Stack } from '@mui/material';
import ReactQuillTextbox from '../../../components/ReactQuillTextbox';
import CommonDialog from '../../../components/CommonDialog';
import { deleteIssue } from '../../../redux/actions/jobActions/issuesActions/deleteIssue';
import NewVendor from '../../Vendor/NewVendor';

const NewIssue = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const inputRef = useRef(null);
  const [clientList, setClientList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [selectedIssueData, setSelectedIssueData] = useState(null);
  const [minDueDate, setMinDueDate] = useState(new Date());
  const [minNotificationDate, setMinNotificationDate] = useState(new Date());
  const [attachments, setAttachments] = useState([]);
  const [openImageInfoDialog, setOpenImageInfoDialog] = useState(false);
  const [fetchedFileInfo, setFetchedFileInfo] = useState({});
  const [propertyList, setPropertyList] = useState([]);
  const [deletedFilesList, setDeletedFilesList] = useState([]);
  const [vendorCategoryList, setVendorCategoryList] = useState([]);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [openAddVendorDialog, setOpenAddVendorDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedIssueId, setSelectedIssueId] = useState('');
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchCategoryList('vendor', (err) => onError(err));
  }, [props.addCategoryData]);

  useEffect(() => {
    let data = props.categoryListData ? props.categoryListData : [];
    data.sort(function (a, b) {
      if (a.categoryName < b.categoryName) {
        return -1;
      }
      if (a.categoryName > b.categoryName) {
        return 1;
      }
      return 0;
    });
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.categoryName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorCategoryList(newData);
  }, [props.categoryListData]);

  useEffect(() => {
    props.fetchClientListName(onError);
    if (props.isEdit)
      props.fetchIssueById(props.data._id, (err) => onError(err));
  }, []);

  useEffect(() => {
    props.fetchVendorDropDownList((err) => onError(err));
  }, [props?.addVendorData]);

  useEffect(() => {
    if (props?.clientListNameData?.length > 0) {
      const data = props.clientListNameData ? props.clientListNameData : [];
      const newData = data?.map((item) => {
        return {
          title: item.lastName + ', ' + item.firstName,
          value: item._id,
        };
      });
      setClientList(newData);
    }
  }, [props.clientListNameData]);

  useEffect(() => {
    const data = props.vendorDropDownListData
      ? props.vendorDropDownListData
      : [];
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.fullName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorList(newData);
  }, [props.vendorDropDownListData]);

  useEffect(() => {
    const data = props.issueData ? props.issueData : null;
    setSelectedIssueData(data);
  }, [props.issueData]);

  const formik = useFormik({
    initialValues: {
      title: '',
      dueDate: '',
      type: '0',
      // assignedTo: [],
      category: '',
      client: '',
      property: '',
      vendor: '',
      job: '',
      priority: '0',
      status: 'New',
      description: '',
      suppliesNeeded: '',
      notification: false,
      visible: false,
      teamMember: '0',
      notificationDate: '',
      notificationTime: moment(),
      attachments: [],
      deletedFiles: [],
    },
    validationSchema: AddIssueSchema,

    onSubmit: (values) => {
      addNewIssue(values);
    },
  });

  const addNewIssue = (values) => {
    const data = {
      issueTitle: values.title,
      dueDate: moment(values.dueDate).endOf('day'),
      type: values.type,
      // assignedTo: values.assignedTo?.map((item) => item.value),
      client: values.client,
      property: values.property,
      job: values.job ? values.job : 'NA',
      priority: values.priority,
      notification: values.notification,
      visible: values.visible,
      attachments: values.attachments,
      status: values.status,
    };
    if (values.vendor) data.vendor = values.vendor;
    if (values.category) data.category = values.category;
    // if (values.assignedTo && values.assignedTo.length > 0)
    //   data.assignedTo = values.assignedTo?.map((item) => item.value);
    // if (values.description?.replace('<p><br></p>', ''))
    data.description = values.description?.replace('<p><br></p>', '');
    data.suppliesNeeded = values.suppliesNeeded || '';
    if (values.notification) {
      data.notificationDate =
        values.notificationDate &&
        values.notificationTime &&
        moment(
          moment(values.notificationDate).format('YYYY-MM-DD') +
            ' ' +
            moment(values.notificationTime).format('HH:mm')
        ).format();
    }
    let fetchedIssueData = props?.fetchedIssueData;
    if (props.isApproveIssue) {
      let newData = {
        ...data,
        reportId: fetchedIssueData?.reportId,
        elementId: fetchedIssueData?.elementId,
        suppliesNeeded: fetchedIssueData?.suppliesNeeded,
        discussWithAdmin: fetchedIssueData?.discussWithAdmin,
        followUp: fetchedIssueData?.followUp,
        images: fetchedIssueData?.images || [],
      };
      if (fetchedIssueData?.elementId)
        newData.elementId = fetchedIssueData.elementId;
      if (fetchedIssueData?.reportId)
        newData.reportId = fetchedIssueData.reportId;
      props.confirmIssue(newData, onError, () => {
        props.onSuccess(
          fetchedIssueData?.elementId,
          fetchedIssueData?.parentId,
          fetchedIssueData?.elementType
        );
        props.onClose();
      });
    } else if (props.isEdit) {
      data.deletedFiles = values.deletedFiles || [];
      props.updateIssue(selectedIssueData?._id, data, props.onClose, (err) =>
        onError(err)
      );
    } else {
      props.createIssue(data, (err) => onError(err), props.onClose);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.client)
      props.fetchClientById(values.client, (err) => onError(err));
  }, [values.client]);

  useEffect(() => {
    const data = props.clientData ? props.clientData : {};
    const newData = data?.properties?.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setPropertyList(newData || []);
    if (newData && newData.length === 1 && values.client && !props.isEdit) {
      setFieldValue('property', newData[0].value);
    }
  }, [props.clientData]);

  useEffect(() => {
    if (
      (props.isEdit && selectedIssueData) ||
      // (props.isNewIssue && props.fetchedIssueData) ||
      (props.isApproveIssue && props.fetchedIssueData)
    ) {
      const {
        issueTitle,
        dueDate,
        type,
        client,
        property,
        job,
        priority,
        vendor,
        status,
        description,
        suppliesNeeded,
        notification,
        notificationDate,
        attachments,
        category,
        visible,
        discussWithAdmin,
      } = props?.fetchedIssueData ? props?.fetchedIssueData : selectedIssueData;
      setFieldValue('title', issueTitle || '');
      setFieldValue('dueDate', dueDate || '');
      setFieldValue('type', type || '0');
      setFieldValue('client', client?._id || '');
      setFieldValue('category', category || '');
      setFieldValue('job', job || 'NA');
      setFieldValue('priority', priority || '0');
      setFieldValue('vendor', vendor || '');
      setFieldValue('status', status || 'New');
      setFieldValue('description', description || '');
      setFieldValue('suppliesNeeded', suppliesNeeded || '');
      setFieldValue('notification', notification || false);
      setFieldValue('visible', visible);
      setFieldValue('property', property?._id || '');
      setFieldValue('attachments', attachments || []);
      if (discussWithAdmin) setFieldValue('status', 'Team Discussion Needed');
      if (notification) {
        setFieldValue('notificationDate', notificationDate);
        setFieldValue('notificationTime', notificationDate);
      }
      dueDate
        ? new Date(dueDate) > new Date()
          ? setMinDueDate(new Date())
          : setMinDueDate(new Date(dueDate))
        : setMinDueDate(new Date());
      // notificationDate
      //   ? new Date(notificationDate) > new Date()
      //     ? setMinNotificationDate(new Date())
      //     : setMinNotificationDate(new Date(notificationDate))
      //   : setMinNotificationDate(new Date());
      setAttachments(attachments || []);
    }
  }, [selectedIssueData]);

  useEffect(() => {
    // if (props?.clientJobsData?.length > 0) {
    const data = props?.clientJobsData || [];
    const newData = data?.map((item) => {
      return {
        title: item.title,
        value: item._id,
      };
    });
    newData.unshift({ title: 'NA', value: 'NA' });
    setJobList(newData);
    // }
  }, [props.clientJobsData]);

  useEffect(() => {
    if (values?.client !== '') {
      props.fetchClientJobs(values?.client, onError);
    }
  }, [values?.client]);

  const onUploadAttachmentsFile = (files) => {
    let fetchedData;
    if (props.isEdit || props.isApproveIssue) {
      fetchedData = files?.map((el) => ({
        ...el,
        new: true,
      }));
    } else fetchedData = files;
    const newData = [...attachments, ...fetchedData];
    setAttachments(newData);
    setFieldValue('attachments', newData);
  };

  const handleFileUpload = async (file) => {
    let newFiles = file.target.files || {};
    if (Object(newFiles)?.length > 0) {
      const data = { files: newFiles };
      props.uploadFile(data, (res) => onUploadAttachmentsFile(res), onError);
    }
    // let urlFiles = [...attachments];
    // let allAttachments = [...values.attachments, ...file.target.files];
    // for (let i of file.target.files) {
    //   let image = URL.createObjectURL(i);
    //   let size = await formatBytes(i.size);
    //   let type = defaultMediaType[i.type] ? defaultMediaType[i.type] : i.type;
    //   let data = { url: image, type: type, name: i.name, size: size };
    //   urlFiles.push(data);
    // }
    // setAttachments(urlFiles);
    // setFieldValue('attachments', allAttachments);
  };

  const onClickRemove = async (file, index) => {
    let data = { url: file?.url };
    if (!props.isEdit) props.deleteFile(data, onError);
    let newAttachments = attachments.filter((i, ind) => index !== ind);
    let attachs = [...values.attachments];
    let newAttachments1 = attachs.filter((i, ind) => index !== ind);
    await setAttachments(newAttachments);
    await setFieldValue('attachments', newAttachments1);
    if (props?.isEdit) {
      let deleteData = {
        _id: file?._id,
        url: file?.url,
      };
      setDeletedFilesList([...deletedFilesList, deleteData]);
      setFieldValue('deletedFiles', [...deletedFilesList, deleteData]);
    }
  };

  const onClickPreview = (item, index) => {
    setOpenImageInfoDialog(true);
    setFetchedFileInfo({
      title: item.name,
      url: item.url,
      size: `${item.type} • ${item.size}`,
    });
  };

  //========= delete Task =========
  const onDeleteTask = () => {
    props.deleteIssue(
      props?.data?._id,
      () => {
        setDeleteTaskDialog(false);
        props?.onClose();
      },
      (err) => onError(err)
    );
  };

  //==== add notes ===
  const addNoteHandle = () => {
    setSelectedIssueId(selectedIssueData?._id);
    setAddNoteDialog(true);
  };

  if (addNoteDialog) {
    return (
      <AddNotes
        isIssue
        selectedId={selectedIssueId}
        onNewClose={() => {
          setAddNoteDialog(false);
        }}
      />
    );
  }

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={
            props.isApproveIssue
              ? Strings.CONFIRM_ISSUE
              : props.isEdit
              ? Strings.EDIT_ISSUE
              : Strings.NEW_ISSUE
          }
          addNote={
            (props.isEdit && selectedIssueData && selectedIssueData?._id) ||
            false
          }
          onNewClose={props.onClose}
          onAddNoteClick={addNoteHandle}
        />
        {props.clientListNameLoading ||
        props.issueDataLoading ||
        props.categoryListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  label={Strings.ISSUE_TITLE}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  placeholder={Strings.ENTER_TITLE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <DateSelector
                  name='dueDate'
                  label={Strings.DUE_DATE}
                  onChange={(value) => setFieldValue('dueDate', value)}
                  // onChange={handleChange('dueDate')}
                  value={values.dueDate}
                  error={Boolean(touched.dueDate && errors.dueDate)}
                  helperText={touched.dueDate && errors.dueDate}
                  containerClassName={classes.form_input_container}
                  format='MMM dd, yyyy'
                  placeholder={Strings.SAMPLE_DATE}
                  minDate={minDueDate}
                />
                <Dropdown
                  name='type'
                  onChange={handleChange('type')}
                  value={values.type}
                  label={Strings.TYPE}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueTypeArray}
                />
                <Dropdown
                  name='category'
                  onChange={handleChange('category')}
                  value={values.category}
                  label={Strings.CATEGORY}
                  // error={Boolean(touched.vendor && errors.vendor)}
                  // helperText={touched.vendor && errors.vendor}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={vendorCategoryList}
                  searchable
                  addOption
                  tooltipTitle={Strings.NEW_CATEGORY}
                  onAddClick={() => setOpenAddCategoryDialog(true)}
                />
                <Dropdown
                  name='client'
                  onChange={(value) => {
                    setFieldValue('client', value);
                    setFieldValue('job', '');
                    setFieldValue('property', '');
                  }}
                  value={values.client}
                  label={Strings.CLIENT}
                  error={Boolean(touched.client && errors.client)}
                  helperText={touched.client && errors.client}
                  title={Strings.SELECT_THE_CLIENT}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={clientList}
                  searchable
                />
                <Dropdown
                  name='property'
                  onChange={(value) => {
                    setFieldValue('property', value);
                  }}
                  value={values.property}
                  label={Strings.PROPERTY}
                  error={Boolean(touched.property && errors.property)}
                  helperText={touched.property && errors.property}
                  title={
                    !values.client
                      ? Strings.SELECT_THE_CLIENT
                      : Strings.TYPE_TO_SEARCH
                  }
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={propertyList}
                  searchable
                  disabled={!values.client || props.clientLoading}
                />
                <Dropdown
                  name='job'
                  onChange={handleChange('job')}
                  value={values.job}
                  label={Strings.JOB}
                  error={Boolean(touched.job && errors.job)}
                  helperText={touched.job && errors.job}
                  title={
                    values.client === ''
                      ? Strings.SELECT_THE_CLIENT
                      : Strings.TYPE_TO_SEARCH_JOB
                  }
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobList}
                  searchable
                  disabled={values.client === '' || props.clientJobsLoading}
                />

                {/* <MultiSelectDropdown
                  name='assignedTo'
                  onChange={(value) => setFieldValue('assignedTo', value)}
                  value={values.assignedTo}
                  label={Strings.ASSIGN_TO}
                  // error={Boolean(touched.assignedTo && errors.assignedTo)}
                  // helperText={touched.assignedTo && errors.assignedTo}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                /> */}
                <Dropdown
                  name='vendor'
                  onChange={handleChange('vendor')}
                  value={values.vendor}
                  label={Strings.VENDOR}
                  // error={Boolean(touched.vendor && errors.vendor)}
                  // helperText={touched.vendor && errors.vendor}
                  title={Strings.TYPE_TO_SEARCH_VENDOR}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={vendorList}
                  searchable
                  addOption
                  tooltipTitle={Strings.NEW_VENDOR}
                  onAddClick={() => setOpenAddVendorDialog(true)}
                />
                <Dropdown
                  name='status'
                  onChange={handleChange('status')}
                  value={values.status}
                  label={Strings.STATUS}
                  // error={Boolean(touched.status && errors.status)}
                  // helperText={touched.status && errors.status}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueStatusArray}
                />
                <Dropdown
                  name='priority'
                  onChange={handleChange('priority')}
                  value={values.priority}
                  label={Strings.PRIORITY}
                  error={Boolean(touched.priority && errors.priority)}
                  helperText={touched.priority && errors.priority}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issuePriorityArray}
                />
                {/* <Textbox
                  name='description'
                  onChange={handleChange('description')}
                  value={values.description}
                  label={Strings.ISSUE_SUMMARY}
                  // error={Boolean(touched.description && errors.description)}
                  // helperText={touched.description && errors.description}
                  placeholder={Strings.ISSUE_SUMMARY}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  multiline
                  rows={6}
                /> */}
                <Stack mt={1}>
                  <ReactQuillTextbox
                    id='filterIssueDescription'
                    onChange={(value) => setFieldValue('description', value)}
                    value={values.description}
                    label={Strings.ISSUE_SUMMARY}
                  />
                </Stack>

                {/* <Stack mt={1}>
                  <ReactQuillTextbox
                    name='suppliesNeeded'
                    onChange={handleChange('suppliesNeeded')}
                    value={values.suppliesNeeded}
                    label={Strings.SUPPLIES_NEEDED}
                  />
                </Stack> */}
                <Textbox
                  name='suppliesNeeded'
                  onChange={handleChange('suppliesNeeded')}
                  value={values.suppliesNeeded}
                  label={Strings.SUPPLIES_NEEDED}
                  // error={Boolean(
                  //   touched.suppliesNeeded && errors.suppliesNeeded
                  // )}
                  // helperText={touched.suppliesNeeded && errors.suppliesNeeded}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  multiline
                  rows={4}
                />

                <FormSwitch
                  label={`Visible To Client`}
                  value={values.visible}
                  containerClassName={classes.form_input_container}
                  onChange={() => setFieldValue('visible', !values.visible)}
                />

                <FormSwitch
                  label={`${Strings.REMINDER}?`}
                  value={values.notification}
                  containerClassName={classes.form_input_container}
                  onChange={() =>
                    setFieldValue('notification', !values.notification)
                  }
                />
                {values.notification && (
                  <>
                    {/* <Dropdown
                      name='teamMember'
                      onChange={handleChange('teamMember')}
                      value={values.teamMember}
                      label={Strings.TEAM_MEMBER_OR_GROUP}
                      error={Boolean(touched.teamMember && errors.teamMember)}
                      helperText={touched.teamMember && errors.teamMember}
                      title={Strings.TYPE_TO_SEARCH}
                      labelClassName={classes.form_label}
                      // className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      // optionArray={technicianList}
                      searchable
                    /> */}
                    <DateTimeContainer
                      label={Strings.SELECT_DATE_TIME}
                      containerClassName={classes.form_input_container}
                    >
                      <DateSelector
                        name='notificationDate'
                        onChange={(value) => {
                          setFieldValue('notificationDate', value);
                          if (props.isEdit) {
                            setFieldValue(
                              'notificationTime',
                              moment(value)
                                .hour(moment(values.notificationTime).hour())
                                .minute(
                                  moment(values.notificationTime).minute()
                                )
                            );
                          }
                        }}
                        // onChange={handleChange('notificationDate')}
                        value={values.notificationDate}
                        error={Boolean(
                          touched.notificationDate && errors.notificationDate
                        )}
                        helperText={
                          touched.notificationDate && errors.notificationDate
                        }
                        format=' MMM dd, yyyy'
                        placeholder={Strings.SAMPLE_DATE}
                        containerClassName={classes.date_selector_container}
                        minDate={minNotificationDate}
                      />
                      <TimeSelector
                        name='notificationTime'
                        onChange={(value) =>
                          setFieldValue(
                            'notificationTime',
                            value ? moment(value, 'HH:mm') : ''
                          )
                        }
                        // onChange={handleChange('notificationTime')}
                        value={values.notificationTime}
                        error={Boolean(
                          touched.notificationTime && errors.notificationTime
                        )}
                        helperText={
                          touched.notificationTime && errors.notificationTime
                        }
                        placeholder={Strings.SAMPLE_TIME}
                      />
                    </DateTimeContainer>
                    {/* <NewField
                      text={Strings.NEW_MEMBER}
                      containerClassName={classes.form_input_container}
                      onClick={() => {}}
                    /> */}
                  </>
                )}
                <input
                  type='file'
                  ref={inputRef}
                  className={classes.selectFiles}
                  onChange={(event) => handleFileUpload(event)}
                  multiple
                  accept='*'
                />
                <Button
                  text={Strings.ADD_ATTACHMENTS}
                  className={classes.add_new_button}
                  leftIcon={icons(theme).attachmentIcon}
                  onClick={() => inputRef.current.click()}
                  loading={props.uploadFileLoading}
                  disabled={
                    props.uploadFileLoading ||
                    props.addIssueLoading ||
                    props.updateIssueLoading ||
                    props.confirmIssueLoading
                  }
                  loaderColor={theme.palette.background_button}
                />
                <Attachment
                  attachments={attachments}
                  onClickRemove={onClickRemove}
                  onClickPreview={onClickPreview}
                />
                <Button
                  type='button'
                  onClick={() => handleSubmit()}
                  text={
                    props.isApproveIssue
                      ? Strings.CONFIRM_ISSUE
                      : props.isEdit
                      ? Strings.UPDATE_ISSUE
                      : Strings.ADD_NEW_ISSUE
                  }
                  loading={
                    props.addIssueLoading ||
                    props.updateIssueLoading ||
                    props.confirmIssueLoading
                  }
                  disabled={
                    props.addIssueLoading ||
                    props.updateIssueLoading ||
                    props.confirmIssueLoading ||
                    props.uploadFileLoading
                  }
                  containerClassName={classes.add_button_container}
                  className={classes.add_button}
                  loaderColor={theme.palette.background_button_text}
                />
                {props.isEdit && (
                  <Button
                    text={Strings.DELETEISSUE}
                    loading={props.deleteTaskLoading}
                    disabled={
                      props.deleteTaskLoading || props.updateIssueLoading
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDeleteTaskDialog(true);
                    }}
                    containerClassName={classes.add_button_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
      {openImageInfoDialog && (
        <MediaDetails
          fileInfo={fetchedFileInfo}
          onNewClose={() => {
            setOpenImageInfoDialog(false);
            setFetchedFileInfo();
          }}
          isOnlyMediaPreview={true}
          isAddTagsRequired={true}
        />
      )}
      {openAddCategoryDialog && (
        <NewCategoryType
          data={selectedCategory}
          onNewClose={() => {
            setOpenAddCategoryDialog(false);
          }}
          type={'vendor'}
        />
      )}
      {openAddVendorDialog && (
        <NewVendor
          onNewClose={() => {
            setOpenAddVendorDialog(false);
          }}
        />
      )}
      {deleteTaskDialog && (
        <CommonDialog
          title={Strings.ISSUES}
          message={Strings.DELETE_ISSUE}
          onSuccess={() => onDeleteTask()}
          onClose={() => setDeleteTaskDialog(false)}
          loading={props.deleteTaskLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addIssueLoading: state.addIssueData.loading,

  issueData: state.issueData.data,
  issueDataLoading: state.issueData.loading,

  updateIssueLoading: state.updateIssueData.loading,

  vendorDropDownListData: state.vendorDropDownListData.data,
  vendorDropDownListLoading: state.vendorDropDownListData.loading,
  addVendorData: state.addVendorData.data,

  confirmIssueLoading: state.confirmIssueData.loading,

  clientListNameData: state.clientListNameData.data,
  clientListNameLoading: state.clientListNameData.loading,
  clientJobsData: state.clientJobsData.data,
  clientJobsLoading: state.clientJobsData.loading,

  clientData: state.clientData.data,
  clientLoading: state.clientData.loading,

  uploadFileLoading: state.uploadFileData.loading,

  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  addCategoryData: state.addCategoryData.data,

  deleteTaskLoading: state.deleteIssueData.loading,
  deleteTaskData: state.deleteIssueData.data,
});

export default connect(mapStateToProps, {
  createIssue,
  fetchIssueById,
  updateIssue,
  fetchVendorDropDownList,
  confirmIssue,
  fetchClientListName,
  fetchClientJobs,
  uploadFile,
  deleteFile,
  fetchClientById,
  fetchCategoryList,
  deleteIssue,
})(NewIssue);
