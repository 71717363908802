import { UPDATE_RECURRENCE } from '../../../constants';

const initializeState = {
  data: null,
  loading: false,
  error: {},
};

export default function (state = initializeState, { type, payload } = {}) {
  switch (type) {
    case UPDATE_RECURRENCE.LOAD:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_RECURRENCE.SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case UPDATE_RECURRENCE.FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
