import axios from 'axios';
import { MEDIA_LIST_FOLDER } from '../../constants';

export const fetchMediaListFolder = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadMediaListFolder());

  axios
    .get(`/media/folders`, config)
    .then((res) => dispatch(setMediaListFolder(res)))
    .catch((err) => {
      dispatch(errorMediaListFolder(err.response));
      onError(err.response);
    });
};

export const loadMediaListFolder = () => ({
  type: MEDIA_LIST_FOLDER.LOAD,
});

export const setMediaListFolder = (listFolder) => ({
  type: MEDIA_LIST_FOLDER.SUCCESS,
  payload: listFolder.data.data,
});

export const errorMediaListFolder = (error) => ({
  type: MEDIA_LIST_FOLDER.FAIL,
  payload: error,
});
