import React, { useRef } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { createTheme, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';
import moment from 'moment';
import { TimePicker } from 'antd';

const TimeSelector = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const timeRef = useRef();
  const theme1 = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            // background: `transparent url(${Calendar})`,
            marginRight: -7,
            // borderRadius: 0,
            // '&:hover': {
            //   backgroundColor: 'transparent',
            // },
            // '&:focus': {
            //   backgroundColor: 'transparent',
            // },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
          },
          notchedOutline: {
            border: `1px solid ${theme.palette.input_border}`,
          },
          input: {
            padding: 13,
            fontSize: 14,
            borderRadius: 8,
            fontFamily: "'Open Sans', sans-serif",
            outline: 'none',
            fontWeight: 400,
            backgroundColor: 'transparent',
            color: theme.palette.profile_title,
            '&::placeholder': {
              color: props.placeholderColor
                ? props.placeholderColor
                : theme.palette.input_placeholder,
              opacity: 1,
            },
          },
        },
      },
    },
  });

  return (
    <div
      className={classNames(
        classes.time_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classNames(
            classes.time_label,
            props.labelClassName && props.labelClassName
          )}
          htmlFor={props.label}
        >
          {props.label}
        </label>
      )}
      {/* <ThemeProvider theme={theme1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            value={props.value}
            components={{
              OpenPickerIcon: () => <img src={icons(theme).clockIcon} alt='' />,
            }}
            onChange={(newValue) => props.onChange(newValue)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder,
                  }}
                  sx={{
                    svg: { width: '0px', height: '0px' },
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      </ThemeProvider> */}
      <div className={classes.time_input}>
        <TimePicker
          onSelect={(value) => {
            props.onChange(value._d ? value._d : value.$d);
          }}
          // onOk={(value) => {
          //   props.onChange(value._d ? value._d : value.$d);
          // }}
          use12Hours
          value={props.value !== '' ? moment(props.value) : null}
          placeholder={props.placeholder}
          format={'hh:mm a'}
          suffixIcon={<img src={icons(theme).clockIcon} alt='' />}
          clearIcon={
            <img
              src={icons(theme).addIcon}
              alt=''
              style={{ transform: `rotate(45deg)`, height: 20, width: 20 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onChange('');
              }}
            />
          }
          style={{
            width: '100%',
            border: `1px solid ${theme.palette.input_border}`,
            padding: 11,
            fontSize: 14,
            borderRadius: 8,
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: 400,
            backgroundColor: 'transparent',
          }}
        />
        {/* <input
          type='time'
          ref={timeRef}
          className={classNames(
            classes.time,
            props.className && props.className
          )}
          onChange={(e) => {
            console.log('hello', e.target.value)
            props.onChange(e.target.value)}}
          name={props.name}
          value={moment(props.value).format('HH:mm')}
          placeholder={props.placeholder}
        />
        <img
          src={icons(theme).clockIcon}
          className={classes.clock_icon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            timeRef.current.showPicker();
          }}
          alt=''
        /> */}
      </div>
      {props.error && (
        <p
          className={classNames(
            classes.time_error,
            props.isIncreseDecrese && classes.time_error1
          )}
        >
          {props.helperText}
        </p>
      )}
      {props.isIncreseDecrese && (
        <div className={classes.increse_decrese_container}>
          <img
            src={icons(theme).minusIcon}
            className={classes.increse_decrese}
            onClick={props.handleDecreseTime}
            alt=''
          />
          <img
            src={icons(theme).plusIcon}
            className={classes.increse_decrese}
            onClick={props.handleIncreseTime}
            alt=''
          />
        </div>
      )}
    </div>
  );
};

export default TimeSelector;
