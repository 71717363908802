import axios from 'axios';
import { ARCHIVE_UNARCHIVE_CLIENT } from '../../constants';

export const archiveUnarchive = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(archiveUnarchiveClient());
  axios
    .post(`/client/archiveUnarchive`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setArchiveUnarchiveClient(res));
    })
    .catch((err) => {
      dispatch(errorArchiveUnarchiveClient(err.response));
      onError(err.response);
    });
};

export const archiveUnarchiveClient = () => ({
  type: ARCHIVE_UNARCHIVE_CLIENT.LOAD,
});

export const setArchiveUnarchiveClient = (data) => ({
  type: ARCHIVE_UNARCHIVE_CLIENT.SUCCESS,
  payload: data.data,
});

export const errorArchiveUnarchiveClient = (error) => ({
  type: ARCHIVE_UNARCHIVE_CLIENT.FAIL,
  payload: error,
});
