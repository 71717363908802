import React, { useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import useStyles from './styles';
import classNames from 'classnames';

const regex = /(.+)@(.+){2,}\.(.+){2,}/;

export default function TagsInputField({ ...props }) {
  const classes = useStyles();

  const handleAddChip = (chip) => {
    props.selectedTags(chip);
  };

  return (
    <>
      <TagsInput
        value={props.tags}
        onChange={handleAddChip}
        onlyUnique
        validate={(chip) => regex.test(chip)}
        disabled={props.disabled}
        className={props.className}
        inputProps={{ placeholder: props.placeholder }}
        renderTag={props.renderTag}
      />
      {props.error && (
        <p
          className={classNames(
            classes.input_error,
            props.errorClassName && props.errorClassName
          )}
        >
          {props.helperText}
        </p>
      )}
    </>
  );
}
