import React, { useState } from 'react';
import { Strings } from '../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoginSchema } from '../../utils/validationSchemas';
import Textbox from '../../components/Textbox';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/authActions/login';
import { NavLink, useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../utils/appUtils';
import { icons } from '../../utils/icons';
import { useAlert } from 'react-alert';
// import useConnectSocket from '../../hooks/socket';
import jwtDecode from 'jwt-decode';
import OneSignal from 'react-onesignal';
import { useTheme } from '@mui/material/styles';

const LoginForm = (props) => {
  const { classes, onLogin, loading } = props;
  const theme = useTheme();
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('rememberMe')
        ? localStorage.getItem('email')
        : '',
      password: localStorage.getItem('rememberMe')
        ? localStorage.getItem('password')
        : '',
      remember: localStorage.getItem('rememberMe') ? true : false,
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      onLogin(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <div className={classes.login_form_container}>
      <label htmlFor={Strings.WELCOME_BACK} className={classes.welcome_text}>
        {Strings.WELCOME_BACK}
      </label>
      <FormikProvider value={formik}>
        <Form
          className={classes.login_form}
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Textbox
            type='email'
            name='email'
            onChange={handleChange('email')}
            value={values.email}
            label={Strings.EMAIL_ADDRESS}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            placeholder={Strings.ENTER_EMAIL}
          />
          <Textbox
            type={passwordFieldType}
            name='password'
            onChange={handleChange('password')}
            value={values.password}
            label={Strings.PASSWORD}
            error={Boolean(touched.password && errors.password)}
            containerClassName={classes.inputMargin}
            helperText={touched.password && errors.password}
            placeholder={Strings.ENTER_PASSWORD}
            className={classes.input_field}
            rightIcon={
              passwordFieldType === 'password'
                ? icons(theme).eyeOffIcon
                : icons(theme).eyeIconBlue
            }
            rightIconClassName={classes.eyeIcon}
            onRightIcon={() =>
              setPasswordFieldType(
                passwordFieldType === 'password' ? 'text' : 'password'
              )
            }
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Checkbox
              id='remember'
              name='remember'
              checked={values.remember}
              label={Strings.REMEMBER_PASSWORD}
              onChange={() => setFieldValue('remember', !values.remember)}
              containerContainerClass={classes.checkboxContainer}
            />
            {/* <NavLink to='/forgotpassword' className={classes.link}>
              {Strings.FORGOT_PASSWORD}
            </NavLink> */}
          </div>
          <Button
            type='submit'
            text={Strings.LOGIN}
            loading={loading}
            disabled={loading}
          />
        </Form>
      </FormikProvider>
      {/* <label className={classes.link_label}>
        {Strings.DONT_HAVE_ACCOUNT}{' '}
        <a className={classes.signup_link} href='#'>
          {Strings.SIGNUP}
        </a>
      </label> */}
    </div>
  );
};

const Login = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const alert = useAlert();
  // const { connectSocket } = useConnectSocket();
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };
  // const OneSignal = window.OneSignal;

  const handleOnSuccessLogin = () => {
    navigate('/');
    const token = localStorage.jwtToken;
    const decoded = jwtDecode(token);
    OneSignal.setExternalUserId(decoded.userId);
    OneSignal.setSubscription(true);
    // OneSignal.sendTag('userId', decoded.userId, (tagsSent) => {
    //   // Callback called when tag has finished sending
    //   console.log('new_app_version TAG SENT', tagsSent);
    // });
  };

  const onLogin = (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    props.login(
      data,
      () => handleOnSuccessLogin(),
      (err) => onError(err),
      values.remember
    );
    // setTimeout(() => {
    //   connectSocket(); // Connecting socket here
    // }, 3000);
  };
  return (
    <div className={classes.login_container}>
      <div className={classes.main_container}>
        <div className={classes.left_container}>
          <div className={classes.login_mask} />
          {/* <img className={classes.left_image} src={icons().loginImage} alt='' /> */}
          <img className={classes.logo} src={icons().loginLogo} alt='' />
          <img className={classes.login_text} src={icons().loginText} alt='' />
        </div>
        <div className={classes.right_container}>
          <img
            className={classes.gray_logo}
            src={icons().grayLoginLogo}
            alt=''
          />
          <LoginForm
            classes={classes}
            onLogin={onLogin}
            loading={props.loginDataLoading}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.loginData.data,
  loginDataLoading: state.loginData.loading,
});

export default connect(mapStateToProps, {
  login,
})(Login);
