import axios from 'axios';
import { ADD_ISSUE_NOTE } from '../../constants';

export const createIssueNote =
  (iId, data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(addIssueNote());
    axios
      .post(`/jobs/createIssueNote?issueId=${iId}`, data, config)
      .then((res) => {
        onSuccess(res.data.data);
        dispatch(setAddIssueNote(res.data.data));
      })
      .catch((err) => {
        dispatch(errorAddIssueNote(err.response));
        onError(err.response);
      });
  };

export const addIssueNote = () => ({
  type: ADD_ISSUE_NOTE.LOAD,
});

export const setAddIssueNote = (data) => ({
  type: ADD_ISSUE_NOTE.SUCCESS,
  payload: data,
});

export const errorAddIssueNote = (error) => ({
  type: ADD_ISSUE_NOTE.FAIL,
  payload: error,
});
