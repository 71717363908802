import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Checkbox from '../Checkbox';
import { Colors } from '../../utils/color';
import { Avatar } from '@material-ui/core';
import { icons } from '../../utils/icons';

const Input = styled('input')(({ theme }) => ({
  padding: 13,
  paddingRight: 32,
  width: '100%',
  fontFamily: "'Open Sans', sans-serif",
  color: theme.palette.profile_title,
  fontWeight: 400,
  fontSize: 14,
  border: `1px solid ${theme.palette.input_border}`,
  '&:focus': {
    border: `1px solid ${theme.palette.background_button}`,
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.input_placeholder,
  },
  borderRadius: 8,
  background: `transparent url(${
    icons(theme).downArrow
  }) no-repeat right 0.8rem center/1rem 4rem`,
}));

const Listbox = styled('ul')(({ theme, multiple }) => ({
  borderRadius: 8,
  width: '100%',
  margin: 0,
  marginTop: 4,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: Colors.WHITE,
  overflow: 'auto',
  maxHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  '& li': {
    fontFamily: "'Open Sans', sans-serif",
    color: Colors.DARK_GRAY,
    fontWeight: 400,
    fontSize: 14,
    padding: multiple ? '13px 16px 13px 8px' : '13px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& li.${autocompleteClasses.focused}`]: {
    background: Colors.SIDEBAR_BLUE,
    cursor: 'pointer',
  },
  '& li:active': {
    background: multiple ? 'transparent' : Colors.SIDEBAR_BLUE,
  },
  boxShadow:
    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}));

//multiple
const Root = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  width: 100%;
`
);

const InputWrapper = styled('div')(
  ({ theme, focused }) => `
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${
    focused ? theme.palette.background_button : theme.palette.input_border
  };
`
);

// function Tag(props) {
//   const { label, onDelete, ...other } = props;
//   return (
//     <div {...other}>
//       <span>{label}</span>
//     </div>
//   );
// }

// const StyledTag = styled(Tag)(
//   ({ theme }) => `
//   padding: 0px 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center,
//   height: 19px;
//   margin: 4px;
//   color: ${theme.palette.search_text};
//   font-weight: 400;
//   font-size: 14;
//   background-color: ${theme.palette.dropdown_tag_background};
//   outline: 0;
//   overflow: hidden;
// `
// );

export default function MultiSelectDropdown(props) {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
    value,
  } = useAutocomplete({
    id: 'tags-standard',
    options: props.optionArray ? props.optionArray : [],
    onChange: (event, value) => {
      props.onChange(value);
    },
    getOptionLabel: (option) => option.title,
    multiple: true,
    value: props.value,
    disableCloseOnSelect: true,
  });

  return (
    <div
      className={classNames(
        classes.dropdown_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classNames(
            classes.dropdown_label,
            props.labelClassName && props.labelClassName
          )}
          htmlFor={props.label}
        >
          {props.label}
        </label>
      )}
      <Root>
        <div style={{ width: '100%', display: 'flex' }} {...getRootProps()}>
          <InputWrapper
            ref={setAnchorEl}
            className={focused ? 'focused' : ''}
            focused={focused}
          >
            <Input
              name='multiSelect'
              type='text'
              style={{ border: 'none' }}
              readOnly={true}
              placeholder={props?.inputValue}
              disabled={props.disabled}
              {...getInputProps()}
            />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox multiple {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
              return (
                <li {...getOptionProps({ option, index })}>
                  <Checkbox
                    id='remember'
                    name='remember'
                    checked={props?.value?.includes(option)}
                    containerContainerClass={classes.checkboxContainer}
                    checkboxContainerClass={classes.list_checkbox}
                    checkedContainerClass={classes.checked_checkbox}
                  />
                  <span>{option.title}</span>
                </li>
              );
            })}
          </Listbox>
        ) : null}
      </Root>
      {props.error && (
        <p className={classes.dropdown_error}>{props.helperText}</p>
      )}
    </div>
  );
}
