import axios from 'axios';
import { isAdmin, isSuperAdmin } from '../../../utils/appUtils';
import { DASHBOARD_COUNT } from '../../constants';
import moment from 'moment-timezone';

export const fetchDashboardCount = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDashboardCount());
  let url = isSuperAdmin()
    ? `/admin/superAdminCount?currentDate=${moment().format(
        'YYYY-MM-DD',
      )}&timeZone=${moment.tz.guess()}`
    : isAdmin()
    ? `/admin/adminCount?currentDate=${moment().format(
        'YYYY-MM-DD',
      )}&timeZone=${moment.tz.guess()}`
    : '/client/clientCount';
  axios
    .get(url, config)
    .then((res) => dispatch(setDashboardCount(res.data.data)))
    .catch((err) => {
      dispatch(errorDashboardCount(err.response));
      onError(err.response);
    });
};

export const loadDashboardCount = () => ({
  type: DASHBOARD_COUNT.LOAD,
});

export const setDashboardCount = (countData) => ({
  type: DASHBOARD_COUNT.SUCCESS,
  payload: countData,
});

export const errorDashboardCount = (error) => ({
  type: DASHBOARD_COUNT.FAIL,
  payload: error,
});
