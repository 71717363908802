import axios from 'axios';
import moment from 'moment-timezone';
import { JOB_SERIES_LIST } from '../../../constants';

export const fetchJobSeriesList = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJobSeriesList());

  data.timeZone = moment.tz.guess();

  axios
    .post(`/jobs/viewSeries`, data, config)
    .then((res) => dispatch(setJobSeriesList(res.data.data)))
    .catch((err) => {
      dispatch(errorJobSeriesList(err.response));
      onError(err.response);
    });
};

export const loadJobSeriesList = () => ({
  type: JOB_SERIES_LIST.LOAD,
});

export const setJobSeriesList = (jobList) => ({
  type: JOB_SERIES_LIST.SUCCESS,
  payload: jobList,
});

export const errorJobSeriesList = (error) => ({
  type: JOB_SERIES_LIST.FAIL,
  payload: error,
});
