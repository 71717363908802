import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dropdown_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  dropdown_label: {
    marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  dropdown_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },
  checkboxContainer: {
    // marginLeft: 'auto',
    marginRight: 10,
  },
  list_checkbox: {
    border: `1px solid ${Colors.THIN_BORDER}`,
    width: 22,
    height: 22,
  },
  checked_checkbox: {
    backgroundColor: theme.palette.background_button,
  },
}));
