import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  navbar_container: {
    // width: '100%',
    height: 81,
    display: 'flex',
    [theme.breakpoints.between('xs', 'md')]: {
      backgroundColor: theme.palette.secondary_background,
      borderBottom: `1px solid ${theme.palette.background_border}`,
      alignItems: 'center',
      padding: '0px 15px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      height: 75,
    },
  },
  navbar_logo: {
    width: 40,
    height: 50,
  },
  menu_logo: {
    width: 32,
    height: 20,
    cursor: 'pointer',
  },
  navbar_left_container: {
    width: 'calc(65% - 36px)',
    height: '100%',
    padding: '0px 18px',
    backgroundColor: theme.palette.background,
    zIndex: 1,
  },
  navbar_left_other_container: {
    paddingRight: 0,
    width: 'calc(65% - 18px)',
  },
  navbar_right_container: {
    width: 'calc(35% - 36px)',
    padding: '0px 18px',
    height: '100%',
    borderLeft:
      theme.palette.mode === 'dark'
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    zIndex: 1,
  },
  navbar_other_right_container: {
    paddingLeft: 0,
    width: 'calc(35% - 18px)',
    backgroundColor: theme.palette.background,
    borderLeft: 'none',
  },
  navbar_right_inner_container: {
    width: '100%',
    height: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  navbar_left_inner_container: {
    width: '100%',
    height: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    display: 'flex',
    alignItems: 'center',
  },
  navbar_title: {
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.title_text,
    fontSize: 28,
    fontWeight: 300,
  },
  logout_icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginRight: 7,
  },
  bell_icon_container: {
    position: 'relative',
    width: 24,
    height: 24,
    marginRight: 17,
    minWidth: 24,
    minHeight: 24,
  },
  bell_icon: {
    width: '100%',
    height: '100%',
  },
  bel_count: {
    position: 'absolute',
    backgroundColor: theme.palette.notification_badge,
    borderRadius: '50%',
    minWidth: 16,
    minHeight: 16,
    textAlign: 'center',
    padding: 2,
    color: Colors.WHITE,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    top: -8,
    right: -7,
  },
  theme_box: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 12,
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
  },
  theme_label: {
    color: theme.palette.profile_title,
    fontSize: 12,
    fontWeight: 400,
    marginRight: 10,
    whiteSpace: 'nowrap',
  },
  root_dialog_paper_class: {
    borderRadius: '12px !important',
    width: 400,
    maxHeight: '400px !important',
    overflow: 'hidden !important',
    backgroundColor: theme.palette.sidebar_background,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  dialog_list: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 100,
  },
  notification_container: {
    width: '100%',
    overflow: 'hidden',
  },
  notification_list_container: {
    overflow: 'auto',
    maxHeight: 363,
  },
  notification_label_container: {
    padding: '0px 10px 5px 10px',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    display: 'flex',
  },
  no_notification_txt: {
    color: theme.palette.title_text,
    fontSize: 15,
  },
  notification_label: {
    color: theme.palette.title_text,
    fontSize: 15,
    flex: 1,
  },
  clear_label: {
    color: theme.palette.background_button,
    fontSize: 15,
    cursor: 'pointer',
  },
  notification_data_container: {
    display: 'flex',
    width: '100%',
  },
  message: {
    fontSize: 14,
    padding: '2px 10px 2px 10px',
    color: theme.palette.title_text,
    marginRight: 'auto',
  },
  from: {
    fontSize: 12,
    color: theme.palette.title_text,
  },
  message_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  message_time_container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 0px 2px 10px',
  },
  removeIcon: {
    height: 18,
    width: 18,
    marginLeft: 10,
    cursor: 'pointer',
  },
  message_title: {
    display: 'flex',
  },
  emailBtnContainer: {
    marginRight: 10,
  },
  email_button: {
    width: 150,
  },
}));
