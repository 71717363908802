import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { icons } from '../../utils/icons';
import moment from 'moment';
import classNames from 'classnames';
import Tabs from '../Tabs';
import { CircularProgress } from '@material-ui/core';
import { Strings } from '../../utils/strings';
import {
  isAdmin,
  isClient,
  issuesDetailTabArray,
  issuesDetailTabArrayDashboard,
  manageNotesHref,
  setLocaleStorageVal,
  statusColor,
  userId,
} from '../../utils/appUtils';
import MediaImageContainer from '../MediaImageContainer';
import NewIssue from '../../pages/Issues/NewIssue';
import Button from '../Button';
import { Link, useNavigate } from 'react-router-dom';

const NotesDataList = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.data) {
      manageNotesHref('dataNote');
    }
  }, [props.data]);

  return (
    <div className={classes.relatedJob_container}>
      {props.data && (
        <div
          id='dataNote'
          className={classes.note_data}
          style={{
            borderBottom: !props.isBottomBorder && 'none',
            padding: 0,
            alignItems: 'center',
          }}
          dangerouslySetInnerHTML={{
            __html: props.data,
          }}
        />
      )}
      {props?.report && (
        <Button
          text={Strings.VIEW_REPORT}
          className={classes.button}
          loaderColor={props.theme.palette.background_button_text}
          onClick={() => {
            props?.navigate('/app/report', {
              state: {
                jobId: props?.report,
              },
            });
          }}
        />
      )}
    </div>
  );
};

const IssuesContainer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [listData, setListData] = useState([]);
  const [openIssues, setOpenIssues] = useState([]);
  const [detailsActiveTab, setDetailsActiveTab] = useState([]);
  const [showEditIssueDialog, setShowEditIssueDialog] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState({});
  const navigate = useNavigate();
  let timer;

  useEffect(() => {
    setDetailsActiveTab(new Array(props.data?.length || 0).fill('details'));
    if (props.data && props?.data?.length) {
      if (props?.limit) {
        let limit =
          props?.limit > props?.data?.length
            ? props?.data?.length
            : props?.limit;
        let newData = structuredClone(props?.data);
        newData = newData.slice(0, limit);
        setListData(newData);
      } else setListData(props?.data);
    } else setListData(props?.data);
  }, [props.data]);

  const handleOpenIssues = (index) => {
    if (openIssues.findIndex((item, i) => item === index) === -1) {
      setOpenIssues([...openIssues, index]);
    } else {
      setOpenIssues(openIssues.filter((item) => item !== index));
    }
  };

  // === Issues details tabs list ===
  const getIssueTabList = useCallback(
    (data) => {
      if (data) {
        return isClient()
          ? [
              { id: 'details', name: 'Task Summary', hideDataCount: true },
              {
                id: 'notes',
                name: 'Comments and History',
                length: data?.notes?.length || 0,
              },
            ]
          : [
              { id: 'details', name: 'Task Summary', hideDataCount: true },
              {
                id: 'notes',
                name: 'Comments and History',
                length: data?.notes?.length || 0,
              },
              {
                id: 'internal-notes',
                name: 'Internal Notes',
                length: data?.internalNotes?.length || 0,
              },
              { id: 'related-jobs', name: 'Related Jobs', hideDataCount: true },
              { id: 'supplies', name: 'Supplies Needed', hideDataCount: true },
              { id: 'media', name: 'Media', hideDataCount: true },
            ];
      }
    },
    [props.data]
  );

  // === check any new note/internal notes create and unread or not ===
  const isUnReadData = useCallback(
    (data) => {
      if (data) {
        let isUnreadInternalNotes =
          data?.internalNotes?.some((el) => el?.unReadBy?.includes(userId())) ||
          false;
        let isUnreadNotes =
          data?.notes?.some((el) => el?.unReadBy?.includes(userId())) || false;
        if (isUnreadInternalNotes || isUnreadNotes) return true;
        else return false;
      }
      return false;
    },
    [props.data]
  );

  // ===== sort data by date descending order =====
  const sortData = (array) => {
    const newData = structuredClone(array);
    let data = newData?.sort((a, b) => {
      if (moment(a?.createdAt) < moment(b?.createdAt)) return 1;
      if (moment(a?.createdAt) > moment(b?.createdAt)) return -1;
      return 0;
    });
    return data || array;
  };

  return (
    <>
      {props.loading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : listData?.length === 0 ? (
        <div className={classes.flexView}>
          <p className={classes.no_data_message}>
            {props.noDataString ? props.noDataString : Strings.NO_DATA_FOUND}
          </p>
        </div>
      ) : (
        <>
          {listData.map((item, index) => (
            <div className={classes.container} key={item._id}>
              <div
                className={classes.issues_header}
                onClick={(event) => {
                  clearTimeout(timer);
                  if (event.detail === 1) {
                    timer = setTimeout(() => {
                      handleOpenIssues(index);
                    }, 200);
                  } else if (event.detail === 2) {
                    setSelectedIssue(item);
                    setShowEditIssueDialog(true);
                  }
                }}
              >
                {!props.noCollapse && (
                  <img
                    className={classes.toggle_arrow}
                    src={
                      openIssues.findIndex((item) => item === index) !== -1
                        ? icons(theme).downArrow
                        : icons(theme).rightArrow
                    }
                    alt=''
                    // onClick={() => handleOpenIssues(index)}
                  />
                )}
                <div
                  style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                >
                  <Stack direction='row' alignItems='center' gap={0.75}>
                    {isUnReadData(item) ? (
                      <div className={classes.redDot} />
                    ) : null}
                    <label htmlFor='date' className={classes.issues_title}>
                      {item?.issueTitle}
                    </label>
                  </Stack>
                  {item.client && (
                    <div className={classes.client_name}>
                      {`Client: ${item?.client?.name}`}
                    </div>
                  )}
                  {item.property && props.isSuppliesNeeded && (
                    <div className={classes.client_name}>
                      {`Property: ${item?.property?.name}`}
                    </div>
                  )}
                </div>
                <label htmlFor='Issues' className={classes.date_label}>
                  {item.dueDate && moment(item.dueDate).format('MMM D, YYYY')}
                </label>
              </div>
              <div
                className={classes.issues_footer}
                onClick={() => handleOpenIssues(index)}
              >
                {/* {item.notes && (
                  <label
                    htmlFor='Items'
                    className={classNames(
                      classes.date_label,
                      classes.note_text
                    )}
                    style={{ marginLeft: 0 }}
                  >
                    {item.notes && item.notes?.[item.notes.length - 1]?.note}
                  </label>
                )} */}

                {item.status && (
                  <div
                    className={classes.issue_status}
                    style={{ backgroundColor: statusColor(item.status) }}
                  >
                    {item.status}
                  </div>
                )}
                {((props?.noCollapse && item.description) ||
                  (props.isPropertyArray && item.clientName)) && (
                  <label
                    className={classes.date_label}
                    style={{ marginLeft: 0, marginTop: 5 }}
                  >
                    {props.noCollapse
                      ? item.description
                      : props.isPropertyArray && `Client: ${item.clientName}`}
                  </label>
                )}
              </div>
              {!props?.noCollapse && openIssues.includes(index) && (
                <div className={classes.detail_conainer}>
                  <Tabs
                    underlineTabs
                    // noDataLength
                    containerClassName={classes.issue_details_container}
                    tabClassName={classes.issue_details_tab}
                    dataList={getIssueTabList(item)}
                    activeTab={detailsActiveTab[index]}
                    onChange={(value) => {
                      let newTab = [...detailsActiveTab];
                      newTab[index] = value;
                      setDetailsActiveTab(newTab);
                    }}
                    tabIndex={index}
                  />
                  {/* {detailsActiveTab[index] === 'notes' && (
                    <NotesDataList data={item.notes || []} />
                  )} */}
                  {detailsActiveTab[index] === 'notes' && (
                    <>
                      {sortData(item.notes)?.map((item1, index) => (
                        <>
                          <NotesDataList
                            data={item1.note}
                            isBottomBorder={false}
                          />
                          <div className={classes.note_details_container}>
                            <p
                              className={classes.note_data}
                              style={{ paddingBottom: 0, marginBottom: 2 }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {Strings.CREATED_BY}:{' '}
                              </span>
                              {`${item1.createdBy.name} ( ${
                                moment(item1.createdAt).format('ll') + // LL - Jul 14, 2023
                                ' ' +
                                moment(item1.createdAt).format('LT')
                              } )`}
                            </p>
                            {item1?.updatedBy ? (
                              <p
                                className={classes.note_data}
                                style={{ paddingBottom: 0, marginBottom: 2 }}
                              >
                                <span style={{ fontWeight: 600 }}>
                                  {Strings.UPDATED_BY}:{' '}
                                </span>
                                {`${item1?.updatedBy?.name} ( ${
                                  moment(item1.updatedAt).format('ll') + // LL - Jul 14, 2023
                                  ' ' +
                                  moment(item1.updatedAt).format('LT')
                                } )`}
                              </p>
                            ) : null}
                          </div>
                        </>
                      ))}
                    </>
                  )}
                  {detailsActiveTab[index] === 'details' && (
                    <NotesDataList data={item?.description} />
                  )}

                  {detailsActiveTab[index] === 'internal-notes' && (
                    <>
                      {sortData(item.internalNotes)?.map((item1, index) => (
                        <>
                          <NotesDataList
                            data={item1.note}
                            isBottomBorder={false}
                          />
                          <div className={classes.note_details_container}>
                            <p
                              className={classes.note_data}
                              style={{ paddingBottom: 0, marginBottom: 2 }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {Strings.CREATED_BY}:{' '}
                              </span>
                              {`${item1.createdBy.name} ( ${
                                moment(item1.createdAt).format('ll') + // LL - Jul 14, 2023
                                ' ' +
                                moment(item1.createdAt).format('LT')
                              } )`}
                            </p>
                            {item1?.updatedBy ? (
                              <p
                                className={classes.note_data}
                                style={{ paddingBottom: 0, marginBottom: 2 }}
                              >
                                <span style={{ fontWeight: 600 }}>
                                  {Strings.UPDATED_BY}:{' '}
                                </span>
                                {`${item1?.updatedBy?.name} ( ${
                                  moment(item1.updatedAt).format('ll') + // LL - Jul 14, 2023
                                  ' ' +
                                  moment(item1.updatedAt).format('LT')
                                } )`}
                              </p>
                            ) : null}
                          </div>
                        </>
                      ))}
                    </>
                  )}

                  {detailsActiveTab[index] === 'related-jobs' && (
                    <>
                      {item?.linkedJobs?.map((item1) => (
                        <NotesDataList
                          data={item1?.title}
                          theme={theme}
                          report={item1?.report && item1._id}
                          navigate={navigate}
                        />
                      ))}
                    </>
                  )}

                  {detailsActiveTab[index] === 'supplies' && (
                    <>
                      <NotesDataList data={item?.suppliesNeeded} />
                    </>
                  )}

                  {detailsActiveTab[index] === 'media' && (
                    <>
                      {item?.attachments && item?.attachments?.length > 0 && (
                        <div>
                          <MediaImageContainer
                            imageArray={item?.attachments}
                            itemsPerRow={2}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
          {props?.limit && props?.limit < props?.data?.length ? (
            <Stack alignItems='center' py={1.25}>
              <Link
                to='/app/issues'
                className={classes.link_txt}
                onClick={() => {
                  let data = { filter: { property: [props?.propertyId] } };
                  if (isAdmin()) data.filter.showAllIssue = false;
                  setLocaleStorageVal('IssuesData', data);
                }}
              >
                {Strings.SEE_MORE}
              </Link>
            </Stack>
          ) : null}
        </>
      )}
      {showEditIssueDialog && !isClient() && (
        <NewIssue
          isEdit={Object.keys(selectedIssue).length > 0}
          data={selectedIssue}
          onClose={() => {
            setSelectedIssue({});
            setShowEditIssueDialog(false);
          }}
        />
      )}
    </>
  );
};

IssuesContainer.propTypes = {
  limit: PropTypes.number,
  data: PropTypes.array,
};

export default IssuesContainer;
