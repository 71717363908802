import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 5,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 6,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'hidden',
  },
  job_detail_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    height: 'inherit',
    overflow: 'auto',
  },
  common_container: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  margin_bottom_15: {
    marginTop: 15,
  },
  address_container: {
    marginTop: 15,
    cursor: 'pointer',
  },
  section_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 15,
  },
  assigned_to_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  text_common: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.profile_title,
    wordWrap: 'break-word',
    '& a': {
      color: theme.palette.background_button,
    },
  },
  text_light_blue: {
    color: Colors.LIGHT_BLUE,
    fontWeight: 600,
  },
  text_section_header: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  job_type_container: {
    padding: '3px 12px;',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 40,
    backgroundColor: 'transparent',
    color: theme.palette.form_switch_background,
    border: `1px solid ${theme.palette.form_switch_background}`,
  },
  job_status_container: {
    padding: '3px 12px;',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 40,
    color: Colors.WHITE,
    backgroundColor: theme.palette.background_button,
    border: `1px solid ${theme.palette.background_button}`,
  },
  icon_small: {
    height: 24,
    width: 24,
  },
  icons: {
    width: 35,
    height: 35,
  },
  house_image: {
    width: '100%',
    height: 180,
    objectFit: 'cover',
    borderRadius: 8,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportHistory_container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 10,
  },
  reportHistory_field_container: {
    width: 'calc(40% - 5px)',
    display: 'flex',
  },
  reportHistory_value_container: {
    width: 'calc(60% - 5px)',
    display: 'flex',
  },
  summary_data: {
    gap: 0,
  },
  email_list: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.profile_title,
    marginBottom: 10,
    marginTop: 5,
  },
  appointment_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    border: 'none',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    paddingBottom: 10,
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  add_new_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
  },
  custom_wrapper: {
    width: 'auto',
  },
  backGround_image: {
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));
