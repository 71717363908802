import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import NewFormHeader from '../../components/NewFormHeader';
import { Strings } from '../../utils/strings';
import Textbox from '../../components/Textbox';
import Button from '../../components/Button';
import { AddNotesSchema } from '../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createIssueNote } from '../../redux/actions/clientActions/addIssueNote';
import { createJobNote } from '../../redux/actions/jobActions/jobActions/addJobNote';
import { useAlert } from 'react-alert';
import { getErrorMessage, isClient } from '../../utils/appUtils';
import { CircularProgress, useMediaQuery } from '@mui/material';
import FormSwitch from '../../components/Switch';
import { icons } from '../../utils/icons';
import { fetchTagsList } from '../../redux/actions/mediaActions/tagsList';
import { createTag } from '../../redux/actions/mediaActions/createTag';
import { deleteTag } from '../../redux/actions/mediaActions/deleteTag';

const ManageTagsDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [tagsList, setTagsList] = useState([]);
  const [newTagValue, setNewTagValue] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedTagId, setSelectedTagId] = useState('');

  // useEffect(() => {
  //   getTagList();
  // }, []);

  const getTagList = () => {
    props.fetchTagsList(onError);
  };

  useEffect(() => {
    if (props?.tagsListData.length > 0) {
      const newList = props?.tagsListData?.map((item) => {
        item.readOnly = true;
        item.isDeleteVisible = true;
        return item;
      });

      setTagsList(newList);
    }
  }, [props.tagsListData]);

  const onClickNewTag = () => {
    const check = tagsList.every((item) => item.name !== '');
    const ranId = new Date().valueOf().toString();
    if (check) {
      const data = { name: '', _id: ranId };
      const newData = [...tagsList, data];
      setTagsList(newData);
      setSelectedId(ranId);
    }
  };

  const onClickSave = async () => {
    if (newTagValue !== '') {
      const data = { name: newTagValue };
      await props.createTag(data, onError, getTagList);
      await setNewTagValue('');
      await setSelectedId('');
    }
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      notes: '',
      internalNote: true,
    },
    validationSchema: AddNotesSchema,
    onSubmit: (values) => {
      if (!props.isJob) {
        const data = {
          note: values.notes,
        };
        if (!isClient()) {
          data.internalNotes = values.internalNote;
        }
        props.createIssueNote(
          props.selectedId,
          data,
          () => props.onClose(),
          (err) => onError(err),
        );
      } else {
        const data = {
          note: values.notes,
          internalNotes: isClient() ? false : values.internalNote,
        };
        props.createJobNote(
          props.selectedId,
          data,
          () => props.onClose(),
          (err) => onError(err),
        );
      }
    },
  });

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={Strings.TAGS}
            onNewClose={() => {
              props.onClose();
            }}
          />
          {props.tagsListLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{
                  color: theme.palette.background_button,
                }}
              />
            </div>
          ) : (
            <div className={classes.form_container}>
              <FormikProvider
                value={formik}
                style={{ display: 'flex', height: '100%' }}
              >
                <Form
                  noValidate
                  autoComplete='off'
                  onSubmit={handleSubmit}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {tagsList.map((item) => (
                    <Textbox
                      name='tags'
                      onChange={(e) => {
                        setNewTagValue(e.target.value);
                        const newTags = tagsList.map((item) => {
                          if (item._id === selectedId) {
                            item.name = e.target.value;
                          }
                          return item;
                        });
                        setTagsList(newTags);
                      }}
                      value={item.name}
                      placeholder={Strings.TYPE_HERE}
                      rightIcon={
                        item.isDeleteVisible && icons(theme).deleteIcon
                      }
                      containerClassName={classes.tags_input}
                      readOnly={item.readOnly}
                      onRightIcon={() => {
                        setSelectedTagId(item?._id);
                        props.deleteTag(item._id, onError, () => {
                          getTagList();
                          setSelectedTagId('');
                        });
                      }}
                      rightIconLoader={
                        (props?.deleteTagLoading &&
                          selectedTagId === item?._id) ||
                        false
                      }
                    />
                  ))}
                  <div className={classes.add_new_tag} onClick={onClickNewTag}>
                    <img
                      src={icons(theme).plusIcon}
                      alt=''
                      className={classes.plus_icon}
                      crossOrigin='Anonymous'
                    />
                    <span>New Tag</span>
                  </div>

                  <Button
                    type='submit'
                    text={Strings.SAVE}
                    disabled={newTagValue === ''}
                    containerClassName={classes.add_button_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                    onClick={onClickSave}
                    loading={props.createTagLoading}
                  />
                </Form>
              </FormikProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // addIssueNoteLoading: state.addIssueNoteData.loading,
  // addJobNoteLoading: state.addJobNoteData.loading,
  tagsListData: state.tagsListData.data,
  tagsListLoading: state.tagsListData.loading,
  createTagData: state.createTagData.data,
  createTagLoading: state.createTagData.loading,
  deleteTagLoading: state.deleteTagData.loading,
});

export default connect(mapStateToProps, {
  fetchTagsList,
  createTag,
  deleteTag,
})(ManageTagsDialog);
