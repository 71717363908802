import axios from 'axios';
import { DELETE_JUNCTURE } from '../../constants';

export const deleteJuncture = (id, closeDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteJuncture());
  axios
    .delete(`/client/juncture/${id}`, config)
    .then((res) => {
      dispatch(setDeleteJuncture(res));
      closeDialog();
    })
    .catch((err) => {
      dispatch(errorDeleteJuncture(err.response));
      onError(err.response);
    });
};

export const loadDeleteJuncture = () => ({
  type: DELETE_JUNCTURE.LOAD,
});

export const setDeleteJuncture = (data) => ({
  type: DELETE_JUNCTURE.SUCCESS,
  payload: data,
});

export const errorDeleteJuncture = (error) => ({
  type: DELETE_JUNCTURE.FAIL,
  payload: error,
});
