import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';
import classNames from 'classnames';
import { Stack } from '@mui/material';
import CustomizedTooltips from '../CustomizedTooltips';
import { Strings } from '../../utils/strings';

export default function NewFormHeader(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classNames(classes.dialog_header, props.containerClassName)}
    >
      <Stack
        flexDirection='row'
        gap={1.5}
        alignItems='center'
        overflow='hidden'
      >
        {props.title && (
          <label htmlFor='title' className={classes.dialog_label}>
            {props.title}
          </label>
        )}
        {props?.addNote && (
          <CustomizedTooltips title={Strings.ADD_NOTE}>
            <img
              className={classes.close_icon}
              src={icons(theme).addNote}
              alt='add notes'
              onClick={props.onAddNoteClick}
            />
          </CustomizedTooltips>
        )}
      </Stack>
      <img
        className={classes.close_icon}
        src={props.closeIcon ? props.closeIcon : icons(theme).closeIcon}
        alt=''
        onClick={props.onNewClose}
      />
    </div>
  );
}
