import classNames from 'classnames';
import React, { useEffect } from 'react';
import useStyles from './styles';
import { isClient } from '../../utils/appUtils';

const Tabs = (props) => {
  const classes = useStyles();
  const {
    dataList,
    noDataLength,
    activeTab,
    onChange,
    tabIndex,
    notesNotification,
    internalNotesNotification,
    notScroll,
    disabledBtn,
  } = props;

  const selectedTab = activeTab ? activeTab : dataList[0]?.id;

  useEffect(() => {
    if (selectedTab) {
      let elem = document.getElementById(selectedTab + tabIndex || '');
      if (!notScroll) {
        elem?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [selectedTab, notScroll]);

  return (
    <div
      className={classNames(
        classes.tabs_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {dataList &&
        dataList.length > 0 &&
        dataList.map((obj) => {
          return (
            <button
              key={obj.id}
              id={obj.id + tabIndex || ''}
              type='button'
              className={classNames(
                classes.tabs_button,
                props.underlineTabs && classes.underline_tabs_button_container
              )}
              onClick={() => {
                onChange(obj.id);
                let elem = document.getElementById(obj.id + tabIndex || '');
                elem.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'nearest',
                });
              }}
              disabled={!!disabledBtn}
            >
              <div className={classes.tab_container}>
                <div
                  className={classNames(
                    classes.tab_width,
                    props.underlineTabs && classes.underline_tabs_button,
                    selectedTab === obj.id && classes.active_tab,
                    selectedTab === obj.id &&
                      props.underlineTabs &&
                      classes.underline_tab_active,
                    props.tabClassName && props.tabClassName
                  )}
                >
                  {obj.name}&nbsp;
                  {!noDataLength && !obj?.hideDataCount
                    ? `(${obj.length})`
                    : ''}
                </div>
                {((obj.id === 'notes' && notesNotification) ||
                  ((obj.id === 'internalNotes' ||
                    obj.id === 'internal-notes') &&
                    internalNotesNotification)) && (
                  <div className={classes.dot} />
                )}
                {obj.badgeColor && (
                  <div
                    className={classNames(
                      props.tabClassName && props.tabClassName
                    )}
                  >
                    <div
                      style={{ backgroundColor: obj.badgeColor }}
                      className={classes.badge}
                    />
                  </div>
                )}
              </div>
              {/* {obj.data && obj.data.length > 0 ? ` (${obj.data.length})` : null} */}
            </button>
          );
        })}
    </div>
  );
};

export default Tabs;
