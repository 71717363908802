import React from 'react';
import { icons } from '../../utils/icons';
import { Strings } from '../../utils/strings';
import useStyle from './styles';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import CountUp from 'react-countup';
import classNames from 'classnames';

const CommonProgressCard = (props) => {
  const {
    progressData,
    subTitleText,
    onViewClick,
    backgroundColor,
    isDate,
    date,
    showDataPercentage,
    percentageValue,
    isViewList,
    showCountOneLine,
    onCardClick,
  } = props;
  const classes = useStyle();
  const theme = useTheme();

  return (
    <div
      className={classNames(
        classes.card_container,
        props.containerClassName && props.containerClassName,
      )}
      style={{
        backgroundColor: backgroundColor && backgroundColor,
        flexDirection: showCountOneLine && 'row',
        alignItems: showCountOneLine && 'center',
        cursor: onCardClick && 'pointer',
      }}
      onClick={onCardClick && onViewClick}
    >
      {isViewList && (
        <div className={classes.text_view_list_container}>
          <label className={classes.text_view_list} onClick={onViewClick}>
            {props.navigationText}
          </label>
        </div>
      )}
      {isDate && !showCountOneLine && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 17,
            marginTop: !isViewList && 22,
          }}
        >
          <label className={classes.text_progress}>
            {moment(date).format('D MMM')}
          </label>
          <span className={classes.date_day_text}>
            {moment(date).format('ddd').toUpperCase()}
          </span>
        </div>
      )}
      {!isDate && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 17,
            marginTop: !isViewList && 22,
          }}
        >
          {!showCountOneLine && (
            <label className={classes.text_progress}>
              {/* {progressData} */}
              <CountUp start={0} end={progressData || 0} duration={2} />
            </label>
          )}
          {showDataPercentage && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 10,
              }}
            >
              <span
                className={classes.percentage_text}
              >{`${percentageValue}%`}</span>
              <img
                src={icons(theme).greenUpArrow}
                alt=''
                className={classes.percentage_upArrow_icon}
              />
            </div>
          )}
        </div>
      )}
      {showCountOneLine && (
        <label className={classes.text_progress} style={{ marginRight: 10 }}>
          <CountUp start={0} end={progressData || 0} duration={2} />
        </label>
      )}
      <label
        className={classes.text_subTitle}
        style={{ marginTop: showCountOneLine && '0' }}
      >
        {subTitleText}
      </label>
    </div>
  );
};

export default CommonProgressCard;
