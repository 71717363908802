import { CONFIRM_ISSUE } from '../../../constants';
import axios from 'axios';

export const confirmIssue = (data, onError, onDialogClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadConfirmIssue());
  axios
    .put(`/report/confirmIssue`, data, config)
    .then((res) => {
      dispatch(setConfirmIssue(res.data));
      onDialogClose();
    })
    .catch((err) => {
      dispatch(failConfirmIssue(err.response));
      onError(err.response);
    });
};

export const loadConfirmIssue = () => ({
  type: CONFIRM_ISSUE.LOAD,
});

export const setConfirmIssue = (data) => ({
  type: CONFIRM_ISSUE.SUCCESS,
  payload: data,
});

export const failConfirmIssue = (error) => ({
  type: CONFIRM_ISSUE.FAIL,
  payload: error,
});
