import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  issues_header: {
    paddingTop: 10,
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginRight: 10,
  },
  issues_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    cursor: 'pointer',
  },
  date_label: {
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 'auto',
  },
  issues_footer: {
    paddingTop: 5,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
    cursor: 'pointer',
  },
  issue_status: {
    padding: '3px 12px',
    marginLeft: 'auto',
    // backgroundColor: theme.palette.background_button,
    backgroundColor: Colors.NEVY_BLUE,
    borderRadius: 40,
    color: theme.palette.background_button_text,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
  },
  note_text: {
    whiteSpace: 'nowrap',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  detail_conainer: {
    marginTop: 15,
    width: '100%',
    paddingBottom: 10,
  },
  note_data: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.profile_title,
    padding: '10px 0px',
    // borderBottom: `1px solid ${theme.palette.background_border}`,
    marginTop: 5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '& a': {
      color: theme.palette.background_button,
    },
  },
  issue_details_container: {
    marginTop: '0px !important',
  },
  issue_details_tab: {
    color: theme.palette.background_button,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  client_name: {
    display: 'flex',
    width: '100%',
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: 14,
    marginTop: 5,
  },
  note_details_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    // marginTop: 10,
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  relatedJob_container: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    // borderBottom: '1px solid #E5E5E5'
  },
  button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    height: 28,
    fontSize: 12,
  },
  link_txt: {
    color: theme.palette.background_button,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 600,
  },
  redDot: {
    minHeight: 10,
    minWidth: 10,
    borderRadius: '100%',
    background: Colors.RED,
  },
}));
