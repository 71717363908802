import axios from 'axios';
import { CLEAR_NOTIFICATIONS } from '../../constants';

export const clearNotifications = (onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClearNotifications());
  axios
    .delete('/notification/clear', config)
    .then((res) => {
      callback();
      dispatch(setClearNotifications(res.data.data));
    })
    .catch((err) => {
      dispatch(errorClearNotifications(err.response));
      onError(err.response);
    });
};

export const loadClearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS.LOAD,
});

export const setClearNotifications = (data) => ({
  type: CLEAR_NOTIFICATIONS.SUCCESS,
  payload: data,
});

export const errorClearNotifications = (error) => ({
  type: CLEAR_NOTIFICATIONS.FAIL,
  payload: error,
});
