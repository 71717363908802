import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: Colors.EXTRA_GRAY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog_container: {
    background: theme.palette.dialog_background,
    width: 430,
    padding: 25,
    zIndex: 3,
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 'calc(100% - 50px)',
      height: 'calc(100% - 50px)',
    },
  },
  form_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 12,
  },
  add_button_container: {
    marginTop: 17,
  },
  add_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  note_text: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.form_label,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  date_selector_container: {
    width: '100%',
  },
  disableInput: {
    backgroundColor: theme.palette.disable_color,
    borderRadius: 8,
  },

  phoneLinkStyle: {
    textDecoration: 'none !important',
    color: theme.palette.background_button,
  },
  dialogText: {
    // fontWeight: 300,
    fontSize: '14px !important',
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.title_text,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    // border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  arrivalDepartureStyle: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
}));
