import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  toolbox_container: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  back_icon: {
    width: 24,
    height: 24,
    marginLeft: 15,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  searchbox_container: {
    marginLeft: '0 !important',
    width: '250px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  searchSelection_container: {
    marginLeft: '10px !important',
    width: '250px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  label: {
    marginLeft: 10,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.search_text,
  },
  dateSelector_container: {
    marginLeft: 16,
    width: '100%',
    maxWidth: '250px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      maxWidth: '100% !important',
    },
  },
  dropdown_saved_container: {
    marginLeft: 16,
    width: '100%',
    maxWidth: '250px !important',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 48%',
      marginLeft: 0,
    },
  },
  right_container: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  right_container_report_top: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  add_new_container: {
    // minWidth: 100,
    width: 'max-content !important',
  },
  secondary_btn_container: {
    marginRight: 15,
  },
  secondary_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  add_new_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
  },
  filter_main_container: {
    display: 'flex',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.search_text,
    alignItems: 'center',
  },
  filter_container: {
    marginLeft: 25,
    display: 'flex',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.search_text,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  filter_icon: {
    marginLeft: 10,
    cursor: 'pointer',
    height: 24,
    width: 24,
  },
  refresh_icon: {
    marginLeft: 10,
    cursor: 'pointer',
    height: 26,
    width: 26,
  },
  download_container: {
    display: 'flex',
    alignItems: 'center',
  },
  download_label: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.search_text,
    cursor: 'pointer',
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  view_all_text: {
    fontWeight: 600,
    cursor: 'pointer',
    color: Colors.LIGHT_BLUE,
  },
  date_container_class: {
    display: 'flex',
  },
  manage_tags_container: {
    height: 47,
    marginLeft: 15,
  },
  manage_tags_button: {
    width: 'max-content',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 13px',
  },
  toggle_btn: {
    marginRight: 15,
  },
  selected_count_container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  selected_count_txt: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
    flex: 1,
  },
  clearIcon: {
    height: 23,
    width: 23,
    marginLeft: 5,
    cursor: 'pointer',
  },
  form_input_container: {
    display: 'flex',
    flexDirection: 'row !important',
    gap:10,
  },
  reportActionDropdownStyle: {
    width: '217px !important',
  },
  actionBtnStyle: {
    height: 45,
  }
}));
