import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  media_card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.dialog_background,
    borderRadius: '8px',
    boxShadow: `0px 2px 4px ${theme.palette.media_box_shadow}`,
    '&:hover $file_icon_container': {
      display: 'flex !important',
    },
    position: 'relative',
  },
  selected_Item: {
    position: 'absolute',
    height: '25px',
    width: '25px',
    right: 10,
    top: 10,
  },
  checkbox_container: {
    minWidth: 22,
    minHeight: 22,
    maxWidth: 22,
    maxHeight: 22,
    backgroundColor: theme.palette.chat_profile_background,
    border: `1.5px solid ${theme.palette.checkbox_border}`,
  },
  labelClassName: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.profile_title,
    marginLeft: 10,
  },
  image_container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    minHeight: 130,
    maxHeight: 160,
    height: 160,
    boxShadow: `0px 0.5px 0px 0px ${theme.palette.media_box_shadow}`,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    [theme.breakpoints.down('sm')]: {
      minHeight: 90,
      maxHeight: 120,
      height: 120,
    },
  },
  noDetails_image_container: {
    width: '100%',
    position: 'relative',
    // boxShadow: `0px 2px 4px ${theme.palette.media_box_shadow}`,
    // minHeight: 130,
    // maxHeight: 160,
    height: 160,
    borderRadius: 5,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      // minHeight: 90,
      maxHeight: 160,
      // height: 120,
    },
  },
  media_text_container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px',
    // border: '1px solid rgba(0,0,0,0.05)',
    borderRadius: '0 0 8px 8px',
    // boxShadow: `0px 2px 4px ${theme.palette.media_box_shadow}`,
    // backgroundColor: theme.palette.dialog_background,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 12px',
      borderRadius: '0 0 6px 6px',
    },
  },
  file_icon_container: {
    display: 'none',
    position: 'absolute',
    left: 15,
    bottom: 15,
    height: '32px',
    // width: '64px',
    minWidth: 'fit-content',
    borderRadius: '4px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background,
    cursor: 'pointer',
    boxShadow: `0px 2px 4px ${theme.palette.media_box_shadow}`,
    [theme.breakpoints.down('sm')]: {
      height: 24,
      width: 24,
      left: 10,
      bottom: 15,
    },
  },
  three_dot: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
  media_page_image: {
    width: '100%',
    // height: '100%',
    // minHeight: '100%',
    // maxHeight: 160,
    objectFit: 'cover',
    borderRadius: '8px 8px 0 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  report_page_image: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    minHeight: '100%',
    objectFit: 'cover',
    // maxHeight: 160,
    // height: 160,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 5.5,
      // maxHeight: 120,
      // height: '100%',
    },
  },
  icon_image: {
    height: '16px',
    width: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 14,
      width: 14,
    },
  },
  icon_image_menu: {
    height: '22px',
    width: '22px',
  },
  media_text_overflow: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text_title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.media_title_text,
  },
  text_subTitle: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 5,
    color: theme.palette.media_title_text,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: 25,
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  tags_container: {
    display: 'flex',
    marginTop: 10,
    gap: 5,
    overflow: 'auto',
    paddingTop: 5,
    paddingBottom: 5,
  },
  tags: {
    border: `1px solid ${theme.palette.background_button}`,
    borderRadius: 32,
    padding: '2px 7px',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.background_button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    minWidth: 'fit-content',
  },
  icons_container: {
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `2px solid ${theme.palette.background_button}`,
    cursor: 'pointer',
  },
}));
