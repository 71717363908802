import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import DateSelector from '../../../components/DateSelector';
import DateTimeContainer from '../../../components/DateTimeContainer';
import Dropdown from '../../../components/Dropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import Textbox from '../../../components/Textbox';
import TimeSelector from '../../../components/TimeSelector';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import {
  getErrorMessage,
  isAdmin,
  jobStatusArray,
  dateRangeArray,
  getDateRangeValue,
  isClient,
} from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import moment from 'moment';
import { useAlert } from 'react-alert';
import FormSwitch from '../../../components/Switch';
import JobNewFilter from './JobNewFilter';
import { fetchJobFormsList } from '../../../redux/actions/jobActions/jobActions/jobFormsList';
import { fetchDropdownPropertyList } from '../../../redux/actions/commonActions/dropdownPropertyList';

const JobFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [technicianList, setTechnicianList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [openJobNewFilterDialog, setOpenJobNewFilterDialog] = useState(false);
  const [jobFormList, setJobFormList] = useState([]);
  const [dropdownPropertyList, setDropdownPropertyList] = useState([]);
  // const [dateRange1, setDateRange1] = useState('All Time');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props.filterData) {
      const {
        client,
        admin,
        // jobForm,
        jobStatus,
        // jobType,
        startDate,
        endDate,
        showAllJob,
        dateRange,
      } = props.filterData;
      // setFieldValue('client', client || '');
      // setFieldValue('admin', admin || '');
      // setFieldValue('jobForm', jobForm || []);
      // setFieldValue('jobStatus', jobStatus || '0');
      // setFieldValue('jobType', jobType || '0');
      // setDateRange1(dateRange);
      setFieldValue('startDate', startDate || '');
      setFieldValue('endDate', endDate || '');
      if (isAdmin()) setFieldValue('showAllJob', showAllJob || false);
    }
    // props.fetchCategoryList('form', (err) => onError(err));
    props.fetchJobFormsList((err) => onError(err));
    if (!isClient()) props.fetchDropdownPropertyList((err) => onError(err));
    props.fetchUserListByType('client', (err) => onError(err));
    props.fetchUserListByType('admin', (err) => onError(err));
    props.fetchUserListByType('array', (err) => onError(err), '', [
      'admin',
      'technician',
      'superadmin'
    ]);
    // props.fetchUserListByType('technician', (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.jobFormsListData ? props.jobFormsListData : [];
    const newData = data.map((item) => ({
      title: item.formName,
      value: item._id,
    }));
    setJobFormList(newData);
  }, [props.jobFormsListData]);

  useEffect(() => {
    const data = props.dropdownPropertyListData
      ? props.dropdownPropertyListData
      : [];
    const newData = data.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setDropdownPropertyList(newData);
  }, [props.dropdownPropertyListData]);

  useEffect(() => {
    if (props.filterData) {
      const {
        assignedMember,
        client,
        admin,
        jobStatus,
        jobForm,
        dateRange,
        property,
      } = props.filterData;
      const newAssignedMember = technicianList?.filter((item) =>
        assignedMember?.some((x) => x === item.value)
      );
      const newJobFormId = jobFormList?.filter((item) =>
        jobForm?.some((x) => x === item.value)
      );
      const newClients = clientList?.filter((item) =>
        client?.some((x) => x === item.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        property?.some((x) => x === item.value)
      );
      const newAdmins = adminList?.filter((item) =>
        admin?.some((x) => x === item.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        jobStatus?.some((x) => x === item.value)
      );
      if (assignedMember)
        setFieldValue('assignedTeamMember', newAssignedMember || []);
      if (jobForm) setFieldValue('jobForm', newJobFormId);
      if (client) setFieldValue('client', newClients || []);
      if (property) setFieldValue('property', newProperties || []);
      if (admin) setFieldValue('admin', newAdmins || []);
      if (jobStatus) setFieldValue('jobStatus', newJobStatus || []);
      if (dateRange)
        setFieldValue('selectedDateRange', dateRange || 'All Time');
    }
  }, [
    technicianList,
    clientList,
    adminList,
    jobFormList,
    dropdownPropertyList,
  ]);

  // useEffect(() => {
  //   const data = props.categoryListData ? props.categoryListData : [];
  //   const newData = data.map((item) => ({
  //     title: item.categoryName,
  //     value: item._id,
  //   }));
  //   setCategoryList(newData);
  // }, [props.categoryListData]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};

    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title: data?.type === 'client'? item.lastName+ ', '+ item.firstName :  item.name,
          value: item._id,
          image: item.profilePicture,
          // color: item?.color,
        };
        // if (data?.type === 'technician') tempData.image = item?.profilePicture;
        if (data?.type === 'array') tempData.color = item?.color;
        
        return tempData;
      }) || [];
    if (data?.type === 'array') setTechnicianList(newData);
    // if (data?.type === 'technician') setTechnicianList(newData);
    if (data?.type === 'admin') setAdminList(newData);
    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  const formik = useFormik({
    initialValues: {
      // jobType: '0',
      jobStatus: [],
      jobForm: [],
      client: [],
      property: [],
      admin: [],
      assignedTeamMember: [],
      startDate: '',
      endDate: '',
      showAllJob: false,
      selectedDateRange: 'All Time',
      // date: '',
    },
    // validationSchema: AddJobSchema,

    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = (values) => {
    const data = {};
    if (values.jobStatus.length > 0)
      data.jobStatus = values.jobStatus.map((el) => el.value);
    if (values.client.length > 0)
      data.client = values.client.map((el) => el.value);
    if (values.property.length > 0)
      data.property = values.property.map((el) => el.value);
    if (values.admin.length > 0)
      data.admin = values.admin.map((el) => el.value);
    if (values.assignedTeamMember.length > 0)
      data.assignedMember = values.assignedTeamMember.map((item) => item.value);
    // if (values.jobType !== '0') data.jobType = values.jobType;
    if (values.jobForm.length > 0) {
      let jobFormId = values.jobForm.map((item) => item.value);
      data.jobForm = jobFormId;
    }
    if (isAdmin()) data.showAllJob = values.showAllJob;
    if (values.selectedDateRange !== 'All Time') {
      // data.dateRange = values.selectedDateRange;
      const dateRange = getDateRangeValue(values.selectedDateRange);
      data.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      data.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    } else {
      if (values.startDate)
        data.startDate = moment(values.startDate).format('YYYY-MM-DD');
      if (values.endDate)
        data.endDate = moment(values.endDate).format('YYYY-MM-DD');
    }
    data.dateRange = values?.selectedDateRange;
    props.setFilterData(data);
    props.onClose();
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader title={Strings.FILTER_BY} onNewClose={props.onClose} />
        {props.categoryListLoading ||
        props.UserListByTypeLoading ||
        props.dropdownPropertyListDataLoading ||
        props.jobFormsListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <MultiSelectDropdown
                  name='client'
                  onChange={(value) => setFieldValue('client', value)}
                  value={values.client}
                  label={Strings.CLIENT}
                  // error={Boolean(touched.client && errors.client)}
                  // helperText={touched.client && errors.client}
                  title={Strings.TYPE_TO_SEARCH + ' the client'}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={clientList}
                  searchable
                />
                {!isClient() && (
                  <MultiSelectDropdown
                    name='property'
                    onChange={(value) => setFieldValue('property', value)}
                    value={values.property}
                    label={Strings.PROPERTY}
                    // error={Boolean(touched.client && errors.client)}
                    // helperText={touched.client && errors.client}
                    title={Strings.TYPE_TO_SEARCH + ' the property'}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={dropdownPropertyList}
                    searchable
                  />
                )}
                {!isAdmin() && (
                  <MultiSelectDropdown
                    name='admin'
                    onChange={(value) => setFieldValue('admin', value)}
                    value={values.admin}
                    label={Strings.ADMIN}
                    // error={Boolean(touched.admin && errors.admin)}
                    // helperText={touched.admin && errors.admin}
                    title={Strings.TYPE_TO_SEARCH}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={adminList}
                    searchable
                  />
                )}
                <MultiSelectDropdown
                  name='assignedTeamMember'
                  onChange={(value) =>
                    setFieldValue('assignedTeamMember', value)
                  }
                  value={values.assignedTeamMember}
                  label={Strings.TEAM_MEMBER_ASSIGNED}
                  // error={Boolean(
                  //   touched.assignedTeamMember && errors.assignedTeamMember
                  // )}
                  // helperText={
                  //   touched.assignedTeamMember && errors.assignedTeamMember
                  // }
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                />
                <Dropdown
                  name='selectedDateRange'
                  onChange={(e) => {
                    if (e.target.value === 'All Time') {
                      setFieldValue('startDate', null);
                      setFieldValue('endDate', null);
                    }
                    setFieldValue('selectedDateRange', e.target.value);
                    // setDateRange1(e.target.value);
                    // if (e.target.value === 'All Time') {
                    //   const data = getDateRangeValue(e.target.value);
                    //   setFieldValue('startDate', data?.startDate || null);
                    //   setFieldValue('endDate', data?.endDate || null);
                    // }
                  }}
                  value={values.selectedDateRange}
                  label={Strings.SELECT_DATE_RANGE}
                  // error={Boolean(touched.selectedDateRange && errors.selectedDateRange)}
                  // helperText={touched.selectedDateRange && errors.selectedDateRange}
                  title={Strings.SELECT_DATE_RANGE}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={dateRangeArray}
                  placeholder={Strings.SELECT_DATE_RANGE}
                />
                {/* {!props.isActiveTabToday && ( */}
                {values.selectedDateRange === 'All Time' && (
                  <DateTimeContainer
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='startDate'
                      onChange={(value) => setFieldValue('startDate', value)}
                      value={values.startDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.START_DATE}
                    />
                    <DateSelector
                      name='endDate'
                      onChange={(value) => setFieldValue('endDate', value)}
                      value={values.endDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.END_DATE}
                    />
                    {/* <TimeSelector
                    name='startTime'
                    onChange={handleChange('startTime')}
                    value={values.startTime}
                    // error={Boolean(touched.startTime && errors.startTime)}
                    // helperText={touched.startTime && errors.startTime}
                  /> */}
                  </DateTimeContainer>
                )}
                {/* )} */}
                {/* <DateSelector
                  name='date'
                  label={Strings.DATE}
                  onChange={handleChange('date')}
                  value={values.date}
                  // error={Boolean(touched.date && errors.date)}
                  // helperText={touched.date && errors.date}
                  containerClassName={classes.form_input_container}
                /> */}
                {/* <Dropdown
                  name='jobType'
                  onChange={handleChange('jobType')}
                  value={values.jobType}
                  label={Strings.JOB_TYPE}
                  // error={Boolean(touched.jobType && errors.jobType)}
                  // helperText={touched.jobType && errors.jobType}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={categoryList}
                /> */}
                <MultiSelectDropdown
                  name='jobStatus'
                  onChange={(value) => setFieldValue('jobStatus', value)}
                  value={values.jobStatus}
                  label={Strings.JOB_STATUS}
                  // error={Boolean(touched.jobStatus && errors.jobStatus)}
                  // helperText={touched.jobStatus && errors.jobStatus}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobStatusArray}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='jobForm'
                  onChange={(value) => {
                    setFieldValue('jobForm', value);
                  }}
                  value={values.jobForm}
                  label={Strings.JOB_FORM}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={jobFormList}
                  hideAvatar
                />
                {/* <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  label={Strings.JOB_TITLE}
                  // error={Boolean(touched.title && errors.title)}
                  // helperText={touched.title && errors.title}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                /> */}
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_JOB}
                    value={values.showAllJob}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllJob', !values.showAllJob)
                    }
                  />
                )}
                <div
                  className={classes.flexView}
                  style={{ marginTop: 0, gap: 15 }}
                >
                  <Button
                    type='button'
                    text={Strings.SAVE_FILTER}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.save_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={theme.palette.background_button_text}
                    onClick={() => setOpenJobNewFilterDialog(true)}
                  />
                  <Button
                    type='submit'
                    text={Strings.FILTER_NOW}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.add_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>

                <Button
                  type='button'
                  text={Strings.CLEAR_FILTERS}
                  onClick={() => {
                    resetForm();
                    props.setFilterData(isAdmin() ? { showAllJob: false } : {});
                    props.onClose();
                  }}
                  // loading={props.addVendorLoading}
                  // disabled={props.addVendorLoading}
                  className={classes.secondary_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
      {openJobNewFilterDialog && (
        <JobNewFilter
          setIsFilterChange={props?.setIsFilterChange}
          filterDialogOnClose={() => props.onClose()}
          onClose={() => setOpenJobNewFilterDialog(false)}
          selectedValues={values}
          technicianList={technicianList}
          clientList={clientList}
          adminList={adminList}
          // isActiveTabToday={props.isActiveTabToday}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  jobFormsListData: state.jobFormsListData.data,
  jobFormsListLoading: state.jobFormsListData.loading,

  dropdownPropertyListData: state.dropdownPropertyListData.data,
  dropdownPropertyListDataLoading: state.dropdownPropertyListData.loading,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  fetchUserListByType,
  fetchJobFormsList,
  fetchDropdownPropertyList,
})(JobFilter);
