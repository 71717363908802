import React, { useEffect } from 'react';
import NewFormHeader from '../../../../../components/NewFormHeader';
import { Strings } from '../../../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../../../components/Textbox';
import { AddCategoryTypeSchema } from '../../../../../utils/validationSchemas';
import Dropdown from '../../../../../components/Dropdown';
import {
  getErrorMessage,
  programTypeArray,
} from '../../../../../utils/appUtils';
import Button from '../../../../../components/Button';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { createCategory } from '../../../../../redux/actions/jobActions/categoryActions/addCategoryType';
import { updateCategory } from '../../../../../redux/actions/jobActions/categoryActions/updateCategoryType';
import { useAlert } from 'react-alert';

const NewCategoryType = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      const { categoryName, status } = props.data;
      setFieldValue('name', categoryName);
      setFieldValue('status', status);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      status: 'Active',
    },
    validationSchema: AddCategoryTypeSchema,

    onSubmit: (values) => {
      addCategory(values);
    },
  });

  const addCategory = (values) => {
    const data = {
      categoryName: values.name,
      status: values.status,
      type: props.type ? props.type : 'form',
    };
    if (props.isEdit)
      props.updateCategory(props.data._id, data, props.onNewClose, (err) =>
        onError(err)
      );
    else props.createCategory(data, (err) => onError(err), props.onNewClose);
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={
            props.isEdit
              ? Strings.EDIT_CATEGORY_TYPE
              : Strings.NEW_CATEGORY_TYPE
          }
          onNewClose={props.onNewClose}
        />
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.form_details}>
              <Textbox
                name='name'
                onChange={handleChange('name')}
                value={values.name}
                label={Strings.CATEGORY_NAME}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                placeholder={Strings.ENTER_CATEGORY_NAME}
                labelClassName={classes.form_label}
                className={classes.form_input}
              />
              <Dropdown
                name='status'
                onChange={handleChange('status')}
                value={values.status}
                label={Strings.STATUS}
                // error={Boolean(touched.category && errors.category)}
                // helperText={touched.category && errors.category}
                labelClassName={classes.form_label}
                // className={classes.form_input}
                containerClassName={classes.form_input_container}
                optionArray={programTypeArray}
              />
              <Button
                type='submit'
                text={
                  props.isEdit
                    ? Strings.UPDATE_CATEGORY
                    : Strings.ADD_NEW_CATEGORY
                }
                loading={
                  props.isEdit
                    ? props.updateCategoryLoading
                    : props.addCategoryLoading
                }
                disabled={
                  props.isEdit
                    ? props.updateCategoryLoading
                    : props.addCategoryLoading
                }
                containerClassName={classes.add_button_container}
                className={classes.add_button}
                loaderColor={theme.palette.background_button_text}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  addCategoryData: state.addCategoryData.data,
  addCategoryLoading: state.addCategoryData.loading,

  updateCategoryData: state.updateCategoryData.data,
  updateCategoryLoading: state.updateCategoryData.loading,
});

export default connect(mapStateToProps, {
  createCategory,
  updateCategory,
})(NewCategoryType);
