import axios from 'axios';
import { JOB_FORMS_LIST } from '../../../constants';

export const fetchJobFormsList = (onError, data) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJobFormsList());

  axios
    .post(`/jobs/listForms`, data || {}, config)
    .then((res) => dispatch(setJobFormsList(res.data.data)))
    .catch((err) => {
      dispatch(errorJobFormsList(err.response));
      onError(err.response);
    });
};

export const loadJobFormsList = () => ({
  type: JOB_FORMS_LIST.LOAD,
});

export const setJobFormsList = (jobFormList) => ({
  type: JOB_FORMS_LIST.SUCCESS,
  payload: jobFormList,
});

export const errorJobFormsList = (error) => ({
  type: JOB_FORMS_LIST.FAIL,
  payload: error,
});
