import axios from 'axios';
import { UPDATE_ISSUE_NOTE } from '../../../constants';

export const updateIssueNote =
  (iID, nID, data, onError, onDialogClose) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateIssueNote());
    axios
      .put(`/issue/updateIssueNote?issueId=${iID}&noteId=${nID}`, data, config)
      .then((res) => {
        dispatch(setUpdateIssueNote(res.data.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateIssueNote(err.response));
        onError(err.response);
      });
  };

export const loadUpdateIssueNote = () => ({
  type: UPDATE_ISSUE_NOTE.LOAD,
});

export const setUpdateIssueNote = (data) => ({
  type: UPDATE_ISSUE_NOTE.SUCCESS,
  payload: data,
});

export const errorUpdateIssueNote = (error) => ({
  type: UPDATE_ISSUE_NOTE.FAIL,
  payload: error,
});
