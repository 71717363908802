import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useStyles from './styles';
import Sidebar from '../../helpers/Sidebar';
import NavBar from '../../helpers/Navbar';

const MainLayout = () => {
  const classes = useStyles();
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    goToTop();
  }, []);

  return (
    <div className={classes.layout_conainer}>
      <Sidebar />
      <div className={classes.main_layout}>
        <NavBar />
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
