import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_container: {
    backgroundColor: theme.palette.dialog_background,
    width: 400,
    height: 300,
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
  dialog_header: {
    minHeight: 30,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.profile_title,
    display: 'flex',
    justifyContent: 'center',
  },
  dialog_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  close_icon: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 25,
    height: 25,
    cursor: 'pointer',
  },
  dialog_body: {
    width: '100%',
    height: '100%',
    padding: '15px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_footer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialog_action_button_container: { minWidth: 80, display: 'flex' },
  dialog_action_button: {
    height: 35,
    color: theme.palette.background_button_text,
    backgroundColor: theme.palette.background_button,
    borderRadius: 8,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  action_margin: {
    marginRight: 10,
  },
}));
