import { DOWNLOAD_REPORT } from '../../../constants';
import axios from 'axios';
import { DownloadCSVorPDF } from '../../../../utils/appUtils';

export const downloadReport =
  (reportId, fileName, isParamJobId, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    };
    let endPoint = isParamJobId
      ? `/report/download?reportId=${reportId}`
      : `/report/generateReport?reportId=${reportId}`;
    dispatch(loadDownloadReport());
    axios
      .get(endPoint, config)
      .then((res) => {
        DownloadCSVorPDF(res.data, `${fileName}.pdf`);
        dispatch(setDownloadReport(res));
      })
      .catch((err) => {
        dispatch(errorDownloadReport(err.response));
        onError(err.response);
      });
  };

export const loadDownloadReport = () => ({
  type: DOWNLOAD_REPORT.LOAD,
});

export const setDownloadReport = (reportData) => ({
  type: DOWNLOAD_REPORT.SUCCESS,
  payload: reportData.data.data,
});

export const errorDownloadReport = (error) => ({
  type: DOWNLOAD_REPORT.FAIL,
  payload: error,
});
