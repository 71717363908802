import axios from 'axios';
import { UPDATE_JOB_NOTE } from '../../../constants';

export const updateJobNote =
  (jID, nID, data, onError, onDialogClose) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateJobNote());
    axios
      .put(`/jobs/updateJobNote?jobId=${jID}&noteId=${nID}`, data, config)
      .then((res) => {
        dispatch(setUpdateJobNote(res.data.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateJobNote(err.response));
        onError(err.response);
      });
  };

export const loadUpdateJobNote = () => ({
  type: UPDATE_JOB_NOTE.LOAD,
});

export const setUpdateJobNote = (data) => ({
  type: UPDATE_JOB_NOTE.SUCCESS,
  payload: data,
});

export const errorUpdateJobNote = (error) => ({
  type: UPDATE_JOB_NOTE.FAIL,
  payload: error,
});
