import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';

export default makeStyles((theme) => ({
  dropdown_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  dropdown_label: {
    marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  dropdown_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },
  formControl: {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.input_border}`,
      borderRadius: 8,
      width: '100%',
      "&.Mui-focused": {
        border: `1px solid ${theme.palette.background_button}`,
      }
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: 13,
      width: '100%',
      fontFamily: "'Open Sans', sans-serif",
      color: theme.palette.profile_title,
      fontWeight: 400,
      fontSize: 14,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  select: {
    width: '100%',
    fontSize: '12px',
    appearance: 'none',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    background: `transparent url(${
      icons(theme).downArrow
    }) no-repeat right 0.8rem center/1rem 4rem`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    display: 'none',
  },
  paper: {
    borderRadius: 8,
    marginTop: 4,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontFamily: "'Open Sans', sans-serif",
      color: Colors.DARK_GRAY,
      fontWeight: 400,
      fontSize: 14,
    },
    '& li:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected': {
      color: Colors.DARK_GRAY,
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
  },
}));
