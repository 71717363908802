import axios from 'axios';
import { CALENDAR_JUNCTURES } from '../../constants';
import moment from 'moment-timezone';

export const fetchCalendarJunctures =
  (startDate, endDate, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadCalendarJunctures());

    axios
      .get(
        `/admin/adminJunctures?startDate=${startDate}&endDate=${endDate}&timeZone=${moment.tz.guess()}`,
        config
      )
      .then((res) => dispatch(setCalendarJunctures(res)))
      .catch((err) => {
        dispatch(errorCalendarJunctures(err.response));
        onError(err.response);
      });
  };

export const loadCalendarJunctures = () => ({
  type: CALENDAR_JUNCTURES.LOAD,
});

export const setCalendarJunctures = (junctureList) => ({
  type: CALENDAR_JUNCTURES.SUCCESS,
  payload: junctureList.data.data,
});

export const errorCalendarJunctures = (error) => ({
  type: CALENDAR_JUNCTURES.FAIL,
  payload: error,
});
