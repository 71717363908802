import { GET_FORECAST_WEATHER } from '../../constants';
import { weatherAxios } from '../../../utils/appUtils';

export const getForecastWeather = (coords, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const key = '2e877edcead39c72b4140144c4a01a9c';
  dispatch(loadGetForecastWeather());
  const config = {
    params: { lat: '26.242099', lon: '-81.762694', appid: key, units: 'imperial' },
  };
  weatherAxios
    .get(`forecast`, config)
    .then((res) => {
      dispatch(setGetForecastWeather(res));
    })
    .catch((err) => {
      dispatch(errorGetForecastWeather(err.response));
      onError(err.response);
    });
};

export const loadGetForecastWeather = () => ({
  type: GET_FORECAST_WEATHER.LOAD,
});

export const setGetForecastWeather = (data) => ({
  type: GET_FORECAST_WEATHER.SUCCESS,
  payload: data.data,
});

export const errorGetForecastWeather = (error) => ({
  type: GET_FORECAST_WEATHER.FAIL,
  payload: error,
});
