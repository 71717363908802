import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  report_container: {
    width: '100%',
    height: 'calc(100% - 80px)',
    backgroundColor: theme.palette.background,
    paddingTop: 20,
    overflow: 'hidden',
    flexDirection: 'column',
  },
  toolbar_title: {
    display: 'inline-block',
    paddingTop: 15,
    fontSize: 24,
    fontWeight: 300,
    color: theme.palette.title_text,
  },
  properties_container: {
    // paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5,
    },
  },
  report_details: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    // justifyContent: 'space-between',
    // gap: 50,
    flex: 1,
    marginBottom: 15,
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      gap: 10,
    },
  },
  report_property_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 48%',
      paddingTop: 25,
    },
    marginRight: 15,
  },
  reports_actions_container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: 10,
  },
  action_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '10px 25px',
    height: 40,
  },
  new_media_container: {
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    cursor: 'pointer',
  },
  text_dark_blue: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
  text_common: {
    color: theme.palette.profile_title,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 14,
    wordWrap: 'break-word',
    '& a': {
      color: theme.palette.background_button,
    },
    '& p': {
      margin: 0,
    },
  },
  information_common_title: {
    color: theme.palette.profile_title,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    // [theme.breakpoints.down('sm')]: {
    //   display: 'flex',
    //   width: '66%',
    // },
  },
  report_view_container: {
    // height: 'calc(100% - 127px)',
    // overflow: 'auto',
    // [theme.breakpoints.down('md')]: {
    //   height: 'auto',
    //   overflow: 'visible',
    // },
    paddingBottom: 5,
  },
  information_container: {
    // padding: '0px 10px 0px 0',
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: `1px solid ${theme.palette.background_border}`,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  information_data_container: {
    paddingTop: 15,
  },
  information_data_item_container: {
    padding: '16px 0 16px 34px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  information_data_content_container: {
    width: 'calc(100% - 90px)',
    marginRight: 90,
    padding: '12px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  information_title_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 0px 16px 0',
    cursor: 'pointer',
    // backgroundColor: theme.palette.background_button,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  information_data_title_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 15,
    marginLeft: 20,
  },
  flex_view: {
    display: 'flex',
    alignItems: 'center',
  },
  flex_column_view: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  information_title_sub_content: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      width: 'calc(100% - 35px)',
      paddingTop: 10,
      paddingLeft: 35,
    },
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  information_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    marginLeft: 10,
    cursor: 'pointer',
  },
  issue_container: {
    backgroundColor: Colors.LIGHT_GREEN,
    // borderRadius: 40,
    padding: '10px 12px;',
    fontWeight: 400,
    fontSize: 14,
    color: Colors.WHITE,
    marginLeft: 16,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: 'max-content',
    },
  },
  red_issue_container: {
    backgroundColor: Colors.TOMATO_RED,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
    },
  },
  issue_supply_box: {
    border: `1px solid ${theme.palette.background_border}`,
    borderRadius: 8,
    padding: 12,
    // marginTop: 8,
  },
  hide_border: {
    border: 'none',
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  issue_supply_box_container: {
    display: 'flex',
    width: '100%',
    gap: 12,
    paddingTop: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 8,
      paddingTop: 20,
    },
  },
  information_title_container1: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  report_elements_container: {
    display: 'flex',
    // paddingLeft: 34,
    flexDirection: 'row',
    gap: 15,
    marginTop: 10,
    // paddingBottom: 10,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      flexWrap: 'wrap',
    },
  },
  report_elements_container1: {
    display: 'flex',
    paddingLeft: 34,
    flexDirection: 'row',
    gap: 15,
    paddingBottom: 10,
    paddingTop: 10,
    // borderBottom: `1px solid ${theme.palette.background_border}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
      flexWrap: 'wrap',
    },
  },
  detail_section: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    // width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  media_section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  issue_found_detail: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    marginTop: 10,
  },
  issue_found_list: {
    width: '40%',
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
  },
  issue_found_desc: {
    // width: '60%',
    // height: '100%',
  },
  radio_view: {
    display: 'flex',
    flexDirection: 'row',
  },
  new_media: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  information_title_sub_content1: {
    width: 'auto',
    paddingLeft: 0,
  },
  options_text: {
    display: 'inline-block',
    marginBottom: 10,
  },
  radio_containerStyle: {
    alignItems: 'flex-start',
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    // border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: theme.palette.background,
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  checkboxContainer: {
    marginRight: 10,
  },
  checked_checkbox: {
    height: 20,
    width: 20,
  },
  list_checkbox: {
    height: 20,
    width: 20,
  },
  checkBoxMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    // marginBottom: 10,
  },
  date_selector_container: {
    width: '90%',
  },
  related_issue_container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.table_head_background,
    borderRadius: 5,
    marginBottom: 20,
  },
  related_issues: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    paddingBottom: 15,
    marginTop: 15,
  },

  // client report
  client_info_title_containeer: {
    display: 'flex',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background_button,
    // width: '90%',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      // padding: 0,
    },
  },
  client_information_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.background_button_text,
    // marginLeft: 10,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.profile_title,
      fontSize: 14,
    },
  },
  client_information_common_title: {
    color: theme.palette.profile_title,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '66%',
      fontSize: 14,
    },
  },
  client_info_sub_content_grp: {
    borderTop: `1px solid ${theme.palette.background_border}`,
  },
  client_info_sub_content: {
    display: 'flex',
    // borderRight: `1px solid ${theme.palette.background_border}`,
    // borderBottom: `1px solid ${theme.palette.background_border}`,
    // borderLeft: `1px solid ${theme.palette.background_border}`,
    border: `1px solid ${theme.palette.background_border}`,
    width: 'calc(100% - 2px)',
    flexDirection: 'column',
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      backgroundColor: theme.palette.background_border,
      padding: 12,
      borderRadius: 8,
      width: 'calc(100% - 24px)',
    },
  },
  client_report_elements_container: {
    display: 'flex',
    paddingLeft: 20,
    flexDirection: 'row',
    gap: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    // paddingBottom: 10,
    // [theme.breakpoints.down('md')]: {
    //   paddingLeft: 0,
    //   flexWrap: 'wrap',
    // },
  },
  client_information_data_title_container: {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    // borderBottom: `1px solid white`,
    // marginTop: 20,
    // marginBottom: 15,
    paddingBottom: '10px !important',
    marginBottom: '10px !important',
  },
  client_detail_section: {
    display: 'flex',
    flexDirection: 'column',
    // paddingBottom: 10,
    // width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  client_issue_container: {
    backgroundColor: Colors.LIGHT_GREEN,
    // borderRadius: 40,
    padding: '10px 12px;',
    fontWeight: 400,
    fontSize: 14,
    color: Colors.WHITE,
    marginLeft: 16,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: 80,
      padding: '5px 6px;',
      fontSize: 10,
    },
  },
  client_report_elements_container1: {
    display: 'flex',
    paddingLeft: 20,
    flexDirection: 'row',
    gap: 15,
    paddingBottom: 10,
    paddingTop: 10,
    // borderBottom: `1px solid ${theme.palette.background_border}`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      flexWrap: 'wrap',
    },
  },
  client_notes_margin_btm: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  client_report_property_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 50%',
      paddingTop: 0,
    },
    marginRight: 15,
  },
  margin_left_20: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  client_report_elements_container: {
    display: 'flex',
    paddingLeft: 20,
    flexDirection: 'row',
    gap: 15,
    // marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      flexWrap: 'wrap',
      // marginTop: 5,
    },
  },
  detail_width: {
    width: '97%',
    [theme.breakpoints.down('sm')]: {
      width: '98%',
    },
  },
  issue_confirmed_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: 10,
    marginRight: 5,
    marginTop: 2,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  report_notes_align: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    flex: 1,
  },
  reportImgSection: {
    maxHeight: 200,
    width: '100%',
    border: `1px dashed ${theme.palette.background_button}`,
    borderRadius: 20,
    backgroundColor: theme.palette.drop_image_background,
    overflow: 'hidden',
    height: '100%',
    minHeight: 185,
  },
  issue_found_detail_align: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 10,
  },
  mediaSectionBox: {
    overflow: 'auto',
    padding: 10,
    borderRadius: 20,
  },
  emptyMediaBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: 200,
    },
  },
  galleryIcon: {
    borderRadius: '50%',
    background: theme.palette.background_border,
    padding: 10,
  },
  galleryiconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  editReportMainContainer: {
    border: `1px solid ${theme.palette.background_border}`,
    // borderTop: 'none',
    marginBottom: 20,
  },
  mainLabel: {
    color: Colors.WHITE,
  },
  mainItemSubtitle: {
    fontWeight: 600,
  },
  addMediaText: {
    color: theme.palette.background_button,
    fontWeight: 600,
  },
  taskFoundContainer: {
    display: 'flex',
    gap: 5,
    flex: 1,
    alignItems: 'center',
  },
  status_container: {
    fontSize: 14,
    backgroundColor: Colors.NEVY_BLUE,
    padding: '3px 12px;',
    borderRadius: 40,
    color: Colors.WHITE,
    width: 'max-content',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  summaryTaskContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  summaryTaskContainerNoWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  edit_icon: {
    height: 20,
    width: 20,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  form_input_container: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondary_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  selectContainer: {
    background: theme.palette.background,
    width: '100%',
  },
  add_new_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
  },
  taskButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  taskButtonContainerNoWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  unOrder_list: {
    margin: 0,
    listStyleType: 'square',
    '& li::marker': {
      color: theme.palette.background_button,
    },
  },
  notes_border: {
    padding: '8px 0',
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  view_all_text: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: Colors.LIGHT_BLUE,
      textDecoration: 'underline',
    },
  },
  plus_add_button: {
    cursor: 'pointer',
    marginLeft: 20,
    height: 24,
    width: 24,
  },
}));
