import React from 'react';
import useStyles from './styles';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';

const ImageViewer = (props) => {
  const { image, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.modal_mask_container}>
      <img
        src={icons(theme).closeWhiteIcon}
        className={classes.close_icon}
        onClick={() => onClose && onClose()}
      />
      <div className={classes.image_container}>
        <img src={image} alt='' className={classes.image} />
      </div>
    </div>
  );
};

export default ImageViewer;
