import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import DateSelector from '../../../../components/DateSelector';
import NewFormHeader from '../../../../components/NewFormHeader';
import Textbox from '../../../../components/Textbox';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import {
  dateRangeArray,
  getDateRangeValue,
  getErrorMessage,
  isAdmin,
  isClient,
  issuePriorityArray,
  issueStatusArray,
  issueTypeArray,
} from '../../../../utils/appUtils';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../../redux/actions/adminActions/getUserListByType';
import MultiSelectDropdown from '../../../../components/MultiSelectDropdown';
import { CircularProgress } from '@material-ui/core';
import { fetchDropdownJobList } from '../../../../redux/actions/jobActions/jobActions/dropdownJobList';
import moment from 'moment';
import { useAlert } from 'react-alert';
import FormSwitch from '../../../../components/Switch';
import { createFilter } from '../../../../redux/actions/filterActions/addFilter';
import { fetchFilterById } from '../../../../redux/actions/filterActions/getFilterById';
import { updateFilter } from '../../../../redux/actions/filterActions/updateFilter';
import { deleteFilter } from '../../../../redux/actions/filterActions/deleteFilter';
import { Colors } from '../../../../utils/color';
import { fetchVendorDropDownList } from '../../../../redux/actions/adminActions/vendorDropdownList';
import DateTimeContainer from '../../../../components/DateTimeContainer';
import Dropdown from '../../../../components/Dropdown';
import { fetchCategoryList } from '../../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { fetchDropdownPropertyList } from '../../../../redux/actions/commonActions/dropdownPropertyList';
import { Stack } from '@mui/material';
import ReactQuillTextbox from '../../../../components/ReactQuillTextbox';

const IssuesNewFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [technicianList, setTechnicianList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [vendorCategoryList, setVendorCategoryList] = useState([]);
  const [dropdownPropertyList, setDropdownPropertyList] = useState([]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props?.isEdit) {
      props.fetchDropdownJobList((err) => onError(err));
      if (!isClient()) {
        props.fetchUserListByType('client', (err) => onError(err));
        props.fetchUserListByType('admin', (err) => onError(err));
        // props.fetchUserListByType('technician', (err) => onError(err));
      }
      props.fetchDropdownPropertyList(onError);
      props.fetchVendorDropDownList((err) => onError(err));
      props.fetchCategoryList('vendor', (err) => onError(err));
    }
  }, []);

  useEffect(() => {
    const data = props.dropdownPropertyListData
      ? props.dropdownPropertyListData
      : [];
    const newData = data.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setDropdownPropertyList(newData);
  }, [props.dropdownPropertyListData]);

  useEffect(() => {
    const data = props.categoryListData ? props.categoryListData : [];
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.categoryName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorCategoryList(newData);
  }, [props.categoryListData]);

  useEffect(() => {
    if (!isClient()) {
      const data = props.UserListByTypeData ? props.UserListByTypeData : {};
      const newData =
        data?.data?.map((item) => {
          const tempData = {
            title:
              data?.type === 'client'
                ? item.lastName + ', ' + item.firstName
                : item.name,
            value: item._id,
            image: item?.profilePicture,
          };
          return tempData;
        }) || [];
      if (data?.type === 'technician') setTechnicianList(newData);
      if (data?.type === 'client') setClientList(newData);
      if (data?.type === 'admin') setAdminList(newData);
    }
  }, [props.UserListByTypeData]);

  useEffect(() => {
    const data = props.JobListData ? props.JobListData : [];
    const newData =
      data?.map((item) => ({
        value: item._id,
        title: item?.title,
      })) || [];
    setJobList(newData);
  }, [props.JobListData]);

  useEffect(() => {
    const data = props.vendorDropDownListData
      ? props.vendorDropDownListData
      : [];
    const newData =
      data.map((item) => {
        const tempData = {
          title: item.fullName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorList(newData);
  }, [props.vendorDropDownListData]);

  useEffect(() => {
    if (props.isEdit && props.selectedId) {
      props.fetchFilterById(props?.selectedId, onError);
    }
  }, [props.isEdit, props.selectedId]);

  useEffect(() => {
    if (props?.isEdit && props?.getFilterData) {
      let data = props.getFilterData.filter;
      const newType = issueTypeArray?.filter((item) =>
        data?.type?.some((x) => x === item.value)
      );
      const newPriority = issuePriorityArray?.filter((item) =>
        data?.priority?.some((x) => x === item.value)
      );
      const newStatus = issueStatusArray?.filter((item) =>
        data?.status?.some((x) => x === item.value)
      );
      const newClient = clientList?.filter((item) =>
        data?.client?.some((x) => x === item.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        data?.property?.some((x) => x === item.value)
      );
      const newVendor = vendorList?.filter((item) =>
        data?.vendor?.some((x) => x === item.value)
      );
      const newJob = jobList?.filter((item) =>
        data?.job?.some((x) => x === item.value)
      );
      const newCategory = vendorCategoryList?.filter((item) =>
        data?.category?.some((x) => x === item.value)
      );
      const newAdmins = adminList?.filter((item) =>
        data?.adminAssigned?.some((x) => x === item.value)
      );
      setFieldValue('filterName', props.getFilterData?.filterName || '');
      setFieldValue('type', newType);
      setFieldValue('category', newCategory);
      setFieldValue('priority', newPriority);
      setFieldValue('status', newStatus);
      setFieldValue('client', newClient);
      setFieldValue('property', newProperties);
      setFieldValue('vendor', newVendor);
      setFieldValue('job', newJob);
      setFieldValue('showAllIssue', data?.showAllIssue || false);
      setFieldValue('issueTitle', data?.issueTitle || '');
      setFieldValue('dueDate', data?.dueDate || null);
      setFieldValue('description', data?.description || '');
      setFieldValue('suppliesNeeded', data?.suppliesNeeded || '');
      setFieldValue('selectedDateRange', data?.dateRange || 'All Time');
      setFieldValue('startDate', data?.startDate || null);
      setFieldValue('endDate', data?.endDate || null);
      setFieldValue('adminAssigned', newAdmins || []);
    }
  }, [
    props.getFilterData,
    clientList,
    vendorList,
    jobList,
    vendorCategoryList,
    dropdownPropertyList,
    adminList,
  ]);

  useEffect(() => {
    if (props.selectedData) {
      const newType = issueTypeArray?.filter((item) =>
        props.selectedData?.type?.some((x) => x.value === item.value)
      );
      const newPriority = issuePriorityArray?.filter((item) =>
        props.selectedData?.priority?.some((x) => x.value === item.value)
      );
      const newStatus = issueStatusArray?.filter((item) =>
        props.selectedData?.status?.some((x) => x.value === item.value)
      );
      const newClient = clientList?.filter((item) =>
        props.selectedData?.client?.some((x) => x.value === item.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        props.selectedData?.property?.some((x) => x?.value === item.value)
      );
      const newVendor = vendorList?.filter((item) =>
        props.selectedData?.vendor?.some((x) => x.value === item.value)
      );
      const newJob = jobList?.filter((item) =>
        props.selectedData?.job?.some((x) => x.value === item.value)
      );
      const newCategory = vendorCategoryList?.filter((item) =>
        props.selectedData?.category?.some((x) => x.value === item.value)
      );
      const newAdmins = adminList?.filter((item) =>
        props.selectedData?.adminAssigned?.some((x) => x.value === item.value)
      );
      setFieldValue('type', newType);
      setFieldValue('category', newCategory);
      setFieldValue('priority', newPriority);
      setFieldValue('status', newStatus);
      setFieldValue('client', newClient);
      setFieldValue('property', newProperties);
      setFieldValue('vendor', newVendor);
      setFieldValue('job', newJob);
      setFieldValue('adminAssigned', newAdmins || []);
      setFieldValue('showAllIssue', props.selectedData?.showAllIssue || false);
      setFieldValue('issueTitle', props.selectedData?.issueTitle || '');
      setFieldValue('dueDate', props.selectedData?.dueDate || null);
      setFieldValue('description', props.selectedData?.description || '');
      setFieldValue('suppliesNeeded', props.selectedData?.suppliesNeeded || '');
      setFieldValue('startDate', props.selectedData?.startDate || null);
      setFieldValue('endDate', props.selectedData?.endDate || null);
      setFieldValue(
        'selectedDateRange',
        props.selectedData?.selectedDateRange || 'All Time'
      );
    }
  }, [
    props.selectedData,
    clientList,
    vendorList,
    jobList,
    vendorCategoryList,
    dropdownPropertyList,
    adminList,
  ]);

  const formik = useFormik({
    initialValues: {
      filterName: '',
      issueTitle: '',
      // dueDate: null,
      selectedDateRange: 'All Time',
      startDate: null,
      endDate: null,
      type: [],
      category: [],
      priority: [],
      status: [],
      // assignedTo: [],
      client: [],
      property: [],
      vendor: [],
      job: [],
      description: '',
      suppliesNeeded: '',
      adminAssigned: [],
      showAllIssue: false,
    },
    validationSchema: Yup.object().shape({
      filterName: Yup.string().required('Filtername is required.'),
    }),

    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = (values) => {
    const data = {
      type: props.dashboardFilter
        ? 'ISSUE_SUPERADMIN_DASHOARD'
        : isClient()
        ? 'ISSUE_CLIENT'
        : 'ISSUE',
      filterName: values.filterName,
    };
    let filter = {};
    if (values.issueTitle) filter.issueTitle = values.issueTitle;
    // if (values.dueDate)
    //   filter.dueDate = moment(values.dueDate).format('YYYY-MM-DD');
    if (values.type?.length > 0)
      filter.type = values.type?.map((el) => el.value);
    if (values.category?.length > 0)
      filter.category = values.category?.map((el) => el.value);
    if (values.status?.length > 0)
      filter.status = values.status?.map((el) => el.value);
    if (values.priority?.length > 0)
      filter.priority = values.priority?.map((el) => el.value);
    if (values.client?.length > 0)
      filter.client = values.client?.map((el) => el.value);
    if (values.property.length > 0)
      filter.property = values.property.map((el) => el.value);
    if (values.vendor?.length > 0)
      filter.vendor = values.vendor?.map((el) => el.value);
    if (values.job?.length > 0) filter.job = values.job?.map((el) => el.value);
    if (values.adminAssigned?.length > 0)
      filter.adminAssigned = values.adminAssigned?.map((el) => el.value);
    if (values.description) filter.description = values.description;
    if (values.suppliesNeeded) filter.suppliesNeeded = values.suppliesNeeded;
    if (isAdmin()) filter.showAllIssue = values.showAllIssue;
    if (values.selectedDateRange) filter.dateRange = values.selectedDateRange;
    // if (values.selectedDateRange !== 'All Time') {
    //   const dateRange = getDateRangeValue(values.selectedDateRange);
    //   filter.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
    //   filter.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    // }
    if (values.selectedDateRange === 'All Time') {
      if (values.startDate)
        filter.startDate = moment(values.startDate).format('YYYY-MM-DD');
      if (values.endDate)
        filter.endDate = moment(values.endDate).format('YYYY-MM-DD');
    }
    if (Object.keys(filter).length) data.filter = filter;
    if (props.isEdit) {
      data.filterId = props?.selectedId;
      props.updateFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    } else {
      props.createFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props?.isEdit ? Strings.EDIT_FILTER : Strings.NEW_FILTER}
          onNewClose={props.onClose}
        />
        {props.UserListByTypeLoading ||
        props.JobListLoading ||
        props.vendorDropDownListLoading ||
        props.dropdownPropertyListDataLoading ||
        props.getFilterLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='filterName'
                  onChange={handleChange('filterName')}
                  value={values.filterName}
                  label={Strings.FILTER_NAME}
                  error={Boolean(touched.filterName && errors.filterName)}
                  helperText={touched.filterName && errors.filterName}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <label className={classes.attribute_title_text}>
                  {Strings.FILTER_ATTRIBUTES.toUpperCase()}
                </label>
                <Textbox
                  name='issueTitle'
                  onChange={handleChange('issueTitle')}
                  value={values.issueTitle}
                  label={Strings.ISSUE_TITLE}
                  placeholder={Strings.ENTER_TITLE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                {!isClient() && (
                  <Dropdown
                    name='selectedDateRange'
                    onChange={(e) => {
                      setFieldValue('selectedDateRange', e.target.value);
                    }}
                    value={values.selectedDateRange}
                    label={Strings.SELECT_DATE_RANGE}
                    title={Strings.SELECT_DATE_RANGE}
                    labelClassName={classes.form_label}
                    containerClassName={classes.form_input_container}
                    optionArray={dateRangeArray}
                    placeholder={Strings.SELECT_DATE_RANGE}
                  />
                )}
                {!isClient() && values.selectedDateRange === 'All Time' && (
                  <DateTimeContainer
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='startDate'
                      onChange={(value) => setFieldValue('startDate', value)}
                      value={values.startDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.START_DATE}
                    />
                    <DateSelector
                      name='endDate'
                      onChange={(value) => setFieldValue('endDate', value)}
                      value={values.endDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.END_DATE}
                    />
                  </DateTimeContainer>
                )}
                {/* {!isClient() && (
                  <DateSelector
                    name='dueDate'
                    label={Strings.DUE_DATE}
                    onChange={(value) => setFieldValue('dueDate', value)}
                    // onChange={handleChange('dueDate')}
                    value={values.dueDate}
                    containerClassName={classes.form_input_container}
                    format='MMM dd, yyyy'
                    placeholder={Strings.SAMPLE_DATE}
                  />
                )} */}
                <MultiSelectDropdown
                  name='type'
                  onChange={(value) => setFieldValue('type', value)}
                  value={values.type}
                  label={Strings.TYPE}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueTypeArray}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='category'
                  onChange={(value) => setFieldValue('category', value)}
                  value={values.category}
                  label={Strings.CATEGORY}
                  // error={Boolean(touched.type && errors.type)}
                  // helperText={touched.type && errors.type}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={vendorCategoryList}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='priority'
                  onChange={(value) => setFieldValue('priority', value)}
                  value={values.priority}
                  label={Strings.PRIORITY}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issuePriorityArray}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='status'
                  onChange={(value) => setFieldValue('status', value)}
                  value={values.status}
                  label={Strings.STATUS}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueStatusArray}
                  hideAvatar
                />
                {!isClient() && (
                  <>
                    {!isAdmin() && (
                      <MultiSelectDropdown
                        name='adminAssigned'
                        onChange={(value) =>
                          setFieldValue('adminAssigned', value)
                        }
                        value={values.adminAssigned}
                        label={Strings.ADMIN_ASSIGNED}
                        // error={Boolean(touched.admin && errors.admin)}
                        // helperText={touched.admin && errors.admin}
                        title={Strings.TYPE_TO_SEARCH}
                        labelClassName={classes.form_label}
                        // className={classes.form_input}
                        containerClassName={classes.form_input_container}
                        optionArray={adminList}
                        searchable
                      />
                    )}
                    <MultiSelectDropdown
                      name='client'
                      onChange={(value) => setFieldValue('client', value)}
                      value={values.client}
                      label={Strings.CLIENT}
                      title={Strings.TYPE_TO_SEARCH_CLIENT}
                      labelClassName={classes.form_label}
                      // className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      //   optionArray={technicianList}
                      searchable
                      optionArray={clientList}
                    />
                  </>
                )}
                <MultiSelectDropdown
                  name='property'
                  onChange={(value) => setFieldValue('property', value)}
                  value={values.property}
                  label={Strings.PROPERTY}
                  // error={Boolean(touched.client && errors.client)}
                  // helperText={touched.client && errors.client}
                  title={Strings.TYPE_TO_SEARCH + ' the property'}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={dropdownPropertyList}
                  searchable
                />
                <MultiSelectDropdown
                  name='vendor'
                  onChange={(value) => setFieldValue('vendor', value)}
                  value={values.vendor}
                  label={Strings.VENDOR}
                  title={Strings.TYPE_TO_SEARCH_VENDOR}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  searchable
                  optionArray={vendorList}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='job'
                  onChange={(value) => setFieldValue('job', value)}
                  value={values.job}
                  label={Strings.JOB}
                  title={Strings.TYPE_TO_SEARCH_JOB}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobList}
                  searchable
                  hideAvatar
                />
                {!isClient() && (
                  <>
                    {/* <Textbox
                      name='description'
                      onChange={handleChange('description')}
                      value={values.description}
                      label={Strings.ISSUE_SUMMARY}
                      placeholder={Strings.TYPE_HERE}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                    /> */}
                    <Stack mt={1}>
                      <ReactQuillTextbox
                        id='filterIssueSummary'
                        onChange={handleChange('description')}
                        value={values.description}
                        label={Strings.ISSUE_SUMMARY}
                      />
                    </Stack>
                    <Textbox
                      name='suppliesNeeded'
                      onChange={handleChange('suppliesNeeded')}
                      value={values.suppliesNeeded}
                      label={Strings.SUPPLIES_NEEDED}
                      placeholder={Strings.TYPE_HERE}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                    />
                    {/* <Stack mt={1}>
                      <ReactQuillTextbox
                        name='suppliesNeeded'
                        onChange={handleChange('suppliesNeeded')}
                        value={values.suppliesNeeded}
                        label={Strings.SUPPLIES_NEEDED}
                      />
                    </Stack> */}
                  </>
                )}
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_ISSUE}
                    value={values.showAllIssue}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllIssue', !values.showAllIssue)
                    }
                  />
                )}
                <Button
                  type='submit'
                  text={props.isEdit ? Strings.UPDATE : Strings.SAVE}
                  loading={props.addFilterLoading || props.updateFilterLoading}
                  disabled={props.addFilterLoading || props.updateFilterLoading}
                  className={classes.add_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
                {props.isEdit && (
                  <Button
                    type='button'
                    text={Strings.REMOVE}
                    loading={props.deleteFilterLoading}
                    disabled={props.deleteFilterLoading}
                    className={classes.remove_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={Colors.RED}
                    onClick={() => {
                      props.deleteFilter(props.selectedId, onError, () => {
                        props?.setIsFilterChange(true);
                        props.onClose();
                        props.filterDialogOnClose();
                      });
                    }}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  JobListData: state.dropdownListJobData.data,
  JobListLoading: state.dropdownListJobData.loading,

  vendorDropDownListData: state.vendorDropDownListData.data,
  vendorDropDownListLoading: state.vendorDropDownListData.loading,

  addFilterData: state.addFilterData.data,
  addFilterLoading: state.addFilterData.loading,
  getFilterData: state.getFilterData.data,
  getFilterLoading: state.getFilterData.loading,

  updateFilterLoading: state.updateFilterData.loading,

  deleteFilterLoading: state.deleteFilterData.loading,

  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  dropdownPropertyListData: state.dropdownPropertyListData.data,
  dropdownPropertyListDataLoading: state.dropdownPropertyListData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchDropdownJobList,
  createFilter,
  fetchFilterById,
  updateFilter,
  deleteFilter,
  fetchVendorDropDownList,
  fetchCategoryList,
  fetchDropdownPropertyList,
})(IssuesNewFilter);
