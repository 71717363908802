import React, { useState, useEffect, useCallback, useRef } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import NewFormHeader from '../../../../components/NewFormHeader';
import { Strings } from '../../../../utils/strings';
import { useFormik, Form, FormikProvider } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import Button from '../../../../components/Button';
import NewField from '../../../../components/NewField';
import JobFormTools from './ToolsModal';
import Textbox from '../../../../components/Textbox';
import {
  getErrorMessage,
  makeUniqId,
  programTypeArray,
} from '../../../../utils/appUtils';
import AdditionalFormField from '../../../../components/AdditionalFormField';
import { connect, useDispatch } from 'react-redux';
import { fetchCategoryList } from '../../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { CircularProgress } from '@material-ui/core';
import { createJobForm } from '../../../../redux/actions/jobActions/jobActions/addJobForm';
import { AddJobFormSchema } from '../../../../utils/validationSchemas';
import { useAlert } from 'react-alert';
import {
  fetchJobFormById,
  setJobFormById,
} from '../../../../redux/actions/jobActions/jobActions/getJobFormById';
import { updateJobForm } from '../../../../redux/actions/jobActions/jobActions/updateJobForm';
import { debounce } from 'lodash';

const NewJobForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const formNameRef = useRef();
  // const [showToolBox, setShowToolBox] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  // const [categoryList, setCategoryList] = useState([]);
  const [isShowBtn, setIsShowBtn] = useState(props?.isEdit ? false : true);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props.isEdit && props.data) {
      props.fetchJobFormById(props.data?._id, (err) => onError(err));
    }
    // props.fetchCategoryList('form', (err) => onError(err), true);
    return () => {
      setAdditionalFields([]);
      dispatch(setJobFormById({}));
    };
  }, []);

  useEffect(() => {
    if (props.isEdit && props.data) {
      if (props.jobFormData && Object.entries(props.jobFormData)?.length) {
        const data = props.jobFormData ? props.jobFormData : {};
        setFieldValue('formName', data?.formName || '');
        formNameRef.current.value = data?.formName || '';
        setFieldValue('formStatus', data?.formStatus || 'Active');
        setAdditionalFields(data?.formElements ?? []);
      }
    }
  }, [props.jobFormData]);

  // useEffect(() => {
  //   const data = props.categoryListData ? props.categoryListData : [];
  //   const newData = data.map((item) => ({
  //     title: item.categoryName,
  //     value: item._id,
  //   }));
  //   const isCategory =
  //     newData.filter((item) => item.value === props?.data?.formCategory?._id) ||
  //     [];
  //   if (props.data && props.isEdit && isCategory?.length === 0) {
  //     setCategoryList([
  //       {
  //         title: props?.data?.formCategory?.categoryName,
  //         value: props?.data?.formCategory?._id,
  //       },
  //       ...newData,
  //     ]);
  //   } else {
  //     setCategoryList(newData);
  //   }
  // }, [props.categoryListData]);

  const formik = useFormik({
    initialValues: {
      formName: '',
      // category: '0',
      formStatus: 'Active',
    },
    validationSchema: AddJobFormSchema,

    onSubmit: (values) => {
      addNewJobForm(values);
    },
  });

  const addNewJobForm = (values) => {
    const isTitleEmpty = additionalFields.some((x) => !x.title);
    const uploadFileElements = additionalFields.filter(
      (x) => x.elementType === 'Upload File'
    );
    const isUploadFileElementsEmpty = uploadFileElements.some(
      (x) => !x.fileSize || !x.fileType || !x.allowedFiles
    );
    const filterElements = additionalFields.filter(
      (item) =>
        item.elementType !== 'Upload File' &&
        item.elementType !== 'Group' &&
        item.elementType !== 'Text Input'
    );
    const isElementOptionEmpty = filterElements.some((x) => {
      if (x.options?.length > 0) {
        return x.options.some((y) => !y.option);
      } else {
        return true;
      }
    });

    const filterGroupElements = additionalFields.filter(
      (item) => item.elementType === 'Group'
    );

    const isElementGroupTitleEmpty = filterGroupElements.some((x) =>
      x.groupElements?.some((item) => !item.title)
    );

    const isElementGroupOptionEmpty = filterGroupElements.some((x) =>
      x.groupElements?.some((item) => {
        if (item.options?.length > 0) {
          return item.options.some((y) => !y.option);
        } else if (item.elementType === 'Text Input') {
          return false;
        } else {
          return true;
        }
      })
    );

    const isElementGroupUploadFileFieldsEmpty = filterGroupElements.some((x) =>
      x.groupElements?.some(
        (item) =>
          item.elementType === 'Upload File' &&
          (!item?.fileSize || !item?.fileType || !item?.allowedFiles)
      )
    );

    if (isTitleEmpty) alert.error(Strings.TITLE_EMPTY);
    else if (isElementOptionEmpty) alert.error(Strings.OPTION_EMPTY);
    else if (isUploadFileElementsEmpty)
      alert.error(Strings.FILL_UPLOAD_FILE_ALL_FIELDS);
    else if (isElementGroupTitleEmpty)
      alert.error(Strings.GROUPS_ELEMENTS_TITLE_EMPTY);
    else if (isElementGroupOptionEmpty)
      alert.error(Strings.GROUPS_ELEMENTS_OPTION_EMPTY);
    else if (isElementGroupUploadFileFieldsEmpty)
      alert.error(Strings.FILL_GROUP_UPLOAD_FILE_ALL_FIELDS);
    else {
      const data = {
        formName: values.formName,
        // formCategory: values.category,
        formStatus: values.formStatus,
        formElements: additionalFields,
      };
      if (props.isEdit && !props.isCopyForm)
        // we use same create api for copying form using isCopyForm & isEdit flag
        props.updateJobForm(props.data._id, data, props.onClose, (err) =>
          onError(err)
        );
      else props.createJobForm(data, (err) => onError(err), props.onClose);
    }
  };

  // const addNewField = async (fieldType) => {
  //   let newField = '';
  //   if (fieldType === 'Upload File') {
  //     newField = {
  //       elementType: fieldType,
  //       title: Strings.SELECTED_DROPDOWN,
  //       required: false,
  //       visible: true,
  //       fileSize: '',
  //       allowedFiles: null,
  //       fileType: '',
  //       // options: [
  //       //   { option: '', placeholder: Strings.FILE_SIZE },
  //       //   { option: '', placeholder: Strings.FILE_TYPE },
  //       //   { option: '', placeholder: Strings.NUMBER_OF_FILE },
  //       // ],
  //     };
  //   } else if (fieldType === 'Text Input') {
  //     newField = {
  //       elementType: fieldType,
  //       title: Strings.SELECTED_DROPDOWN,
  //       required: false,
  //       visible: true,
  //     };
  //   } else if (fieldType === 'Group') {
  //     newField = {
  //       elementType: fieldType,
  //       title: Strings.FOCUS_WEEK_PLACEHOLDER,
  //       required: false,
  //       visible: true,
  //     };
  //   } else {
  //     let uniqID = await makeUniqId(22);
  //     newField = {
  //       elementType: fieldType,
  //       title: Strings.SELECTED_DROPDOWN,
  //       required: false,
  //       visible: true,
  //       options:
  //         fieldType === 'Check List'
  //           ? [{ option: Strings.NA, color: '#ED4242', id: uniqID }]
  //           : [
  //               {
  //                 option: Strings.ISSUES_FOUND,
  //                 color: '#ED4242',
  //                 id: `issue${uniqID}`,
  //               },
  //               {
  //                 option: Strings.NO_ISSUES_FOUND,
  //                 color: '#ED4242',
  //                 id: `no_issue${uniqID}`,
  //               },
  //               { option: Strings.NA, color: '#ED4242', id: `na${uniqID}` },
  //             ],
  //     };
  //   }
  //   let _id = await makeUniqId(22);
  //   newField.id = _id;
  //   setAdditionalFields((prevField) => [...prevField, newField]);
  //   setShowToolBox(false);
  // };

  const manageFormData = useCallback(
    debounce((data) => {
      let newData = structuredClone(additionalFields);
      newData = data;
      setAdditionalFields(newData);
    }, 200),
    [additionalFields]
  );

  const handleFormName = useCallback(
    debounce((data) => setFieldValue('formName', data), 500),
    []
  );

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={
            props.isEdit && props.isCopyForm
              ? Strings.COPY_FORM
              : props.isEdit && !props.isCopyForm
              ? Strings.EDIT_JOB_FORM
              : Strings.NEW_JOB_FORM
          }
          onNewClose={props.onClose}
        />
        {props?.jobFormLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  inputRef={formNameRef}
                  name='formName'
                  onChange={(e) => {
                    formNameRef.current.value = e.target.value;
                    handleFormName(formNameRef.current.value);
                  }}
                  value={values?.formName || ''}
                  label={Strings.FORM_NAME}
                  error={Boolean(touched.formName && errors.formName)}
                  helperText={touched.formName && errors.formName}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                {/* <Dropdown
                  name='category'
                  onChange={handleChange('category')}
                  value={values.category}
                  label={Strings.CATEGORY}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={categoryList}
                /> */}
                <Dropdown
                  name='formStatus'
                  onChange={handleChange('formStatus')}
                  value={values.formStatus}
                  label={Strings.FORM_STATUS}
                  error={Boolean(touched.formStatus && errors.formStatus)}
                  helperText={touched.formStatus && errors.formStatus}
                  // title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={programTypeArray}
                />
                {(additionalFields.length > 0 || !props?.isEdit) && (
                  <AdditionalFormField
                    isEdit={props?.isEdit}
                    additionalFields={additionalFields}
                    manageFormData={manageFormData}
                    setIsShowBtn={setIsShowBtn}
                  />
                )}
                {/* <NewField
                  containerClassName={classes.form_input_container}
                  onClick={() => setShowToolBox(!showToolBox)}
                />
                {showToolBox && (
                  <JobFormTools
                    onDropDownClick={() => addNewField('Dropdown')}
                    onTextInputClick={() => addNewField('Text Input')}
                    onRadioClick={() => addNewField('Radio')}
                    // onUploadFileClick={() => addNewField('Upload File')}
                    onCheckListClick={() => addNewField('Check List')}
                    onNewGroupClick={() => addNewField('Group')}
                    isFromGroupField={false}
                  />
                )} */}
                {isShowBtn ? (
                  <Button
                    type='submit'
                    text={
                      props.isEdit && props.isCopyForm
                        ? Strings.COPY_FORM
                        : props.isEdit && !props.isCopyForm
                        ? Strings.UPDATE_FORM
                        : Strings.ADD_NEW_FORM
                    }
                    loading={
                      props.updateJobFormLoading || props.addJobFormLoading
                    }
                    disabled={
                      props.updateJobFormLoading || props.addJobFormLoading
                    }
                    containerClassName={classes.form_input_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                  />
                ) : null}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  addJobFormLoading: state.addJobFormData.loading,

  jobFormData: state.jobFormData.data,
  jobFormLoading: state.jobFormData.loading,

  updateJobFormData: state.updateJobFormData.data,
  updateJobFormLoading: state.updateJobFormData.loading,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  createJobForm,
  fetchJobFormById,
  updateJobForm,
})(NewJobForm);
