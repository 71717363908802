import axios from 'axios';
import { PROGRAM_LIST } from '../../constants';

export const fetchProgramList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadProgramList());

  axios
    .get(`/client/listProgram`, config)
    .then((res) => dispatch(setProgramList(res)))
    .catch((err) => {
      dispatch(errorProgramList(err.response));
      onError(err.response);
    });
};

export const loadProgramList = () => ({
  type: PROGRAM_LIST.LOAD,
});

export const setProgramList = (userList) => ({
  type: PROGRAM_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorProgramList = (error) => ({
  type: PROGRAM_LIST.FAIL,
  payload: error,
});
