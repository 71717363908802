import { useTheme } from '@mui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/Button';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import { AssignUserSchema } from '../../../utils/validationSchemas';
import useStyles from './styles';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import { getErrorMessage } from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { fetchIssueById } from '../../../redux/actions/jobActions/issuesActions/getIssueById';
import { updateIssueByPerformAction } from '../../../redux/actions/jobActions/issuesActions/updateIssueByPerformAction';

const AssignUserDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [technicianList, setTechnicianList] = useState([]);
  const [selectedIssueData, setSelectedIssueData] = useState(null);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchIssueById(props.selectedIssueId, (err) => onError(err));
    props.fetchUserListByType('technician', (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.issueData ? props.issueData : null;
    setSelectedIssueData(data);
  }, [props.issueData]);

  useEffect(() => {
    if (selectedIssueData && technicianList.length > 0) {
      const newData = technicianList.filter((item) =>
        selectedIssueData.assignedTo.some((x) => x._id === item.value)
      );
      setFieldValue('assignedTo', newData);
    }
  }, [selectedIssueData, technicianList]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title: item.name,
          value: item._id,
        };
        if (data?.type === 'technician') tempData.image = item?.profilePicture;
        return tempData;
      }) || [];
    if (data?.type === 'technician') setTechnicianList(newData);
  }, [props.UserListByTypeData]);

  const formik = useFormik({
    initialValues: {
      assignedTo: [],
    },
    validationSchema: AssignUserSchema,
    onSubmit: (values) => {
      const data = {
        assignedTo: values.assignedTo?.map((item) => item.value),
      };
      props.updateIssueByPerformAction(
        props.selectedIssueId,
        data,
        (err) => onError(err),
        () => props.onNewClose()
      );
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue, handleChange } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader title='Assign to user' onNewClose={props.onNewClose} />
        {props.UserListByTypeLoading || props.issueDataLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_container}>
                <MultiSelectDropdown
                  name='assignedTo'
                  onChange={(value) => setFieldValue('assignedTo', value)}
                  value={values.assignedTo}
                  label={Strings.ASSIGN_TO}
                  error={Boolean(touched.assignedTo && errors.assignedTo)}
                  helperText={touched.assignedTo && errors.assignedTo}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                />
                <Button
                  type='submit'
                  text={Strings.ASSIGN_TO_USER}
                  loading={props.updateIssueByActionLoading}
                  disabled={props.updateIssueByActionLoading}
                  containerClassName={classes.add_button_container}
                  className={classes.add_button}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  issueData: state.issueData.data,
  issueDataLoading: state.issueData.loading,

  updateIssueByActionLoading: state.updateIssueByActionData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchIssueById,
  updateIssueByPerformAction,
})(AssignUserDialog);
