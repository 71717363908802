import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  action_button: {
    backgroundColor: theme.palette.background_button,
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button_text,
    fontWeight: 600,
    borderRadius: 8,
    cursor: 'pointer',
    padding: '13px 24px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 150,
    minWidth: 140,
  },
  action_button_arrow: {
    marginLeft: 10,
  },
}));
