import axios from 'axios';
import { CALENDAR_DATA } from '../../constants';
import moment from 'moment-timezone';

export const fetchCalendarData = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  data.timeZone = moment.tz.guess();
  dispatch(loadCalendarData());

  axios
    .post(`/admin/calender`, data, config)
    .then((res) => dispatch(setCalendarData(res?.data?.data || {})))
    .catch((err) => {
      dispatch(errorCalendarData(err.response));
      onError(err.response);
    });
};

export const loadCalendarData = () => ({
  type: CALENDAR_DATA.LOAD,
});

export const setCalendarData = (userList) => ({
  type: CALENDAR_DATA.SUCCESS,
  payload: userList,
});

export const errorCalendarData = (error) => ({
  type: CALENDAR_DATA.FAIL,
  payload: error,
});
