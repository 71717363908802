import React from 'react';
import useStyles from './styles';
import { icons } from '../../../../../utils/icons';

const JobFormTools = (props) => {
  const { isFromGroupField } = props;
  const classes = useStyles();
  const toolsArray = [
    {
      id: 'dropdown',
      title: 'Dropdown',
      icon: icons().dropDownIcon,
      onClick: props.onDropDownClick,
    },
    {
      id: 'textInput',
      title: 'Text Input',
      icon: icons().textInputIcon,
      onClick: props.onTextInputClick,
    },
    {
      id: 'radio',
      title: 'Radio',
      icon: icons().radioIcon,
      onClick: props.onRadioClick,
    },
    // {
    //   id: 'uploadFile',
    //   title: 'Upload File',
    //   icon: icons().uploadFileIcon,
    //   onClick: props.onUploadFileClick,
    // },
    {
      id: 'checkList',
      title: 'Check List',
      icon: icons().checkListIcon,
      onClick: props.onCheckListClick,
    },
    {
      id: 'newGroup',
      title: 'New Group',
      icon: icons().newGroupIcon,
      onClick: props.onNewGroupClick,
    },
  ];

  if (isFromGroupField) {
    toolsArray.pop();
  }
  return (
    <div className={classes.toolbox_modal}>
      <div className={classes.toolbox_container}>
        {toolsArray.map((data) => (
          <div key={data.id} className={classes.tools_container}>
            <img
              src={data.icon}
              alt=''
              className={classes.tools_icon}
              onClick={data.onClick}
            />
            <label className={classes.tools_text} onClick={data.onClick}>
              {data.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobFormTools;
