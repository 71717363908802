import axios from 'axios';
import { GET_MEDIA_BY_PROPERTY } from '../../constants';

export const fetchMediaByProperty = (pId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadMediaByProperty());

  axios
    .get(`/client/getMediaByProperty?propertyId=${pId}`, config)
    .then((res) => dispatch(setMediaByProperty(res.data.data)))
    .catch((err) => {
      dispatch(errorMediaByProperty(err.response));
      onError(err.response);
    });
};

export const loadMediaByProperty = () => ({
  type: GET_MEDIA_BY_PROPERTY.LOAD,
});

export const setMediaByProperty = (data) => ({
  type: GET_MEDIA_BY_PROPERTY.SUCCESS,
  payload: data,
});

export const errorMediaByProperty = (error) => ({
  type: GET_MEDIA_BY_PROPERTY.FAIL,
  payload: error,
});
