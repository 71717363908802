import axios from 'axios';
import { ADD_JOB } from '../../../constants';

export const createJob = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addJob());
  axios
    .post(`/jobs/createJob`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAdd(res));
    })
    .catch((err) => {
      dispatch(errorAdd(err.response));
      onError(err.response);
    });
};

export const addJob = () => ({
  type: ADD_JOB.LOAD,
});

export const setAdd = (data) => ({
  type: ADD_JOB.SUCCESS,
  payload: data.data,
});

export const errorAdd = (error) => ({
  type: ADD_JOB.FAIL,
  payload: error,
});
