import React, { useRef, useState, useEffect } from 'react';
import { Avatar, Grid, useMediaQuery, CircularProgress } from '@mui/material';
import useStyles from './styles';
import UserProfile from '../../assets/images/user-avatar.svg';
import { Strings } from '../../utils/strings';
import Textbox from '../../components/Textbox';
import { useTheme } from '@mui/styles';
import classNames from 'classnames';
import { icons } from '../../utils/icons';
import Button from '../../components/Button';
import TitleContainer from '../../components/TitleContainer';
import TableToolbox from '../../components/TableToolbox';
import Searchbox from '../../components/Searchbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/windowDimention';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  convertBase64,
  downloadFile,
  getErrorMessage,
  getLastMessageTime,
} from '../../utils/appUtils';
import { fetchRoomsList } from '../../redux/actions/chatActions/roomList';
import { createRoom } from '../../redux/actions/chatActions/createRoom';
import { updateRoomsList } from '../../redux/actions/chatActions/updateRoomsList';
import { fetchRoomData } from '../../redux/actions/chatActions/getRoomData';
import { createMessage } from '../../redux/actions/chatActions/createMessage';
import { updateRoomData } from '../../redux/actions/chatActions/updateRoomData';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import useConnectSocket from '../../hooks/socket';
import { uploadFile } from '../../redux/actions/adminActions/uploadFile';
import { clearUploadFile } from '../../redux/actions/adminActions/clearUploadFile';
import { Colors } from '../../utils/color';

const assignedJobArray = ['Test Job', 'Test Job 1', 'Test Job 2', 'Test Job 3'];

const CommonLoader = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.flexView} style={props.style}>
      <CircularProgress
        style={{
          color: theme.palette.background_button,
        }}
      />
    </div>
  );
};

const CommonUserMessageBox = (props) => {
  const { data, classes } = props;
  let ifLastMsg = data?.lastMessage;
  let ifNotTextMsg = !data?.lastMessage?.content?.text;
  let subMsg =
    data?.lastMessage?.content?.text ||
    data?.lastMessage?.content?.filename ||
    '';
  let time = data?.lastMessage
    ? getLastMessageTime(data?.lastMessage?.createdAt)
    : '';
  let userName = data?.participants?.name;
  let userProfile = data?.participants?.profilePicture;
  let isActive = false;

  return (
    <div
      className={classNames(
        classes.user_message_box,
        props.selectedRoom?.participants?._id === data?.participants?._id &&
          classes.selected_box_style
      )}
      onClick={props.onClick}
    >
      <div className={classes.user_message_box_container}>
        <div
          style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}
        >
          <div className={classes.flex_view} style={{ marginRight: 15 }}>
            <div
              className={classes.active_dot}
              style={{ backgroundColor: isActive && '#0088CC' }}
            />
            <Avatar
              src={userProfile}
              alt=''
              style={{ height: 32, width: 32 }}
            />
          </div>
          <div
            className={classes.flex_column_view}
            style={{
              width: '100%',
              wordBreak: 'break-word',
              overflow: 'hidden',
            }}
          >
            <div className={classes.flex_view}>
              <span className={classes.text_small_bold}>{userName}</span>
              <span
                className={classes.time_text}
                style={{
                  marginLeft: 'auto',
                  marginRight: 10,
                  minWidth: 'max-content',
                }}
              >
                {time}
              </span>
            </div>
            <span className={classes.messages_sub_text}>
              {ifLastMsg && ifNotTextMsg && (
                <img
                  style={{ height: 12, width: 12, marginRight: 2 }}
                  src={icons(props.theme).fileIcon}
                  alt=''
                />
              )}
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '95%',
                }}
              >
                {subMsg}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileDetailBox = (props) => {
  const { classes, profileImage, name, userType, joinYear } = props;
  return (
    <div className={classes.profile_detail_box}>
      <div className={classes.profile_image_container}>
        <Avatar
          src={profileImage}
          alt=''
          style={{ height: '150px', width: '150px' }}
        />
      </div>
      <label className={classes.profile_name_text}>{name}</label>
      {/* <div
        className={classes.flex_view}
        style={{ marginTop: 5, flexWrap: 'wrap' }}
      >
        <label
          className={classes.text_small_light}
          style={{ marginRight: 'auto' }}
        >
          {userType}
        </label>
        <label className={classes.text_small_light}>
          {`${Strings.MEMBER_SINCE} joinYear`}
        </label>
      </div> */}
    </div>
  );
};

const MessageContainer = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [downloadfileLoader, setDownloadfileLoader] = useState(false);
  const { classes, theme, data, currentUserId, index, chatRoomData } = props;
  let message, file, fileName, fileSize, profileImage, fileType, createdAt;
  const isSentMessage = data?.sender?._id === currentUserId;
  const isTextMessage = data?.type === 'text';
  if (isTextMessage) message = data?.content?.text;
  profileImage =
    data?.sender?._id === currentUserId
      ? localStorage.profilePicture
      : data?.sender?.profilePicture;
  file = data?.content?.path;
  fileName = data?.content?.filename;
  fileSize = data?.content?.size;
  fileType = data?.type;
  createdAt = data?.createdAt;
  const timeOfMessage = moment(data?.createdAt).format('LT');
  let indexes = [];
  let commonValue = '';
  for (let i in chatRoomData) {
    const date = moment(chatRoomData[i].createdAt).format('LL');
    if (commonValue === '' || commonValue !== date) {
      indexes.push(i);
      commonValue = date;
    }
  }
  const getDateAndTime = (data) => {
    const date = moment(data).format('LL');
    return date;
  };

  return (
    <>
      <div
        className={classNames(
          classes.chat_message_container,
          isSentMessage && classes.chat_message_sent_container,
          !isSentMessage && classes.chat_message_receive_container
        )}
        onMouseEnter={() => {
          if (props.width > 1024) {
            setIsHover(true);
          }
        }}
        onMouseLeave={() => {
          if (props.width > 1024) {
            setIsHover(false);
          }
        }}
      >
        {indexes.length > 0 && indexes.includes(index.toString()) && (
          <div className={classes.daylist}>{getDateAndTime(createdAt)}</div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            wordBreak: 'break-word',
            whiteSpace: 'break-spaces',
            flexDirection: isSentMessage ? 'row-reverse' : 'row',
            width: '100%',
          }}
        >
          <Avatar
            src={profileImage || ''}
            alt=''
            style={
              isSentMessage
                ? { height: 24, width: 24, marginLeft: 10 }
                : { height: 24, width: 24, marginRight: 10 }
            }
          />
          {isTextMessage && (
            <label
              className={classNames(
                !isSentMessage ? classes.receive_message : classes.sent_message
              )}
            >
              {message}
            </label>
          )}

          {file && (
            <>
              <div
                className={
                  isSentMessage
                    ? classes.sent_file_container
                    : classes.receive_file_container
                }
              >
                <div
                  className={classNames(
                    classes.file_icon_container,
                    isSentMessage
                      ? classes.sent_file_icon_background
                      : classes.receive_file_icon_background
                  )}
                >
                  <img
                    src={
                      isSentMessage
                        ? icons(theme).browseIconWhite
                        : icons(theme).browseIcon
                    }
                    alt=''
                    className={classes.file_icon}
                  />
                </div>
                <div className={classes.flex_column_view}>
                  <span
                    className={
                      isSentMessage
                        ? classes.text_small_white
                        : classes.text_small_light
                    }
                  >
                    {fileName}
                  </span>
                  <span
                    className={
                      isSentMessage
                        ? classes.text_small_white
                        : classes.text_small_light
                    }
                  >
                    {props.getFileSize(fileSize)}
                  </span>
                </div>
              </div>
              {file && (isHover || props.width < 1024 || downloadfileLoader) && (
                <div
                  className={classes.download_bcgrnd}
                  onClick={async () => {
                    await downloadFile(
                      file,
                      fileType,
                      fileName,
                      setDownloadfileLoader
                    );
                    await setIsHover(false);
                  }}
                >
                  {downloadfileLoader ? (
                    <CircularProgress
                      style={{
                        color: Colors.NEVY_BLUE,
                      }}
                      size={18}
                    />
                  ) : (
                    <img
                      src={icons(theme).downloadIcon}
                      className={classes.downloadIcon}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className={classes.flex_view}>
          <label
            className={classes.time_text}
            style={{
              marginTop: 5,
              marginLeft: !isSentMessage && 32,
              marginRight: isSentMessage && 32,
            }}
          >
            {timeOfMessage.toLowerCase()}
          </label>
        </div>
      </div>
    </>
  );
};

const Chat = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const inputFileRef = useRef(null);
  const chatRoomRef = useRef(null);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  let { userId } = useParams();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabView = useMediaQuery(theme.breakpoints.down('md'));
  const [rooms, setRooms] = useState(props.roomListData || []);
  const [tempRooms, setTempRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [chatRoomData, setChatRoomData] = useState([]);
  const [searchRoom, setSearchRoom] = useState('');
  const [searchChat, setSearchChat] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [message, setMessage] = useState('');
  const [newMessage, setNewMessage] = useState({});
  const [newRoom, setNewRoom] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedFileInfo, setSelectedFileInfo] = useState('');
  const alert = useAlert();
  const { connectSocket } = useConnectSocket();
  const { width, height } = useWindowDimensions();
  const decoded = jwtDecode(localStorage.getItem('jwtToken'));
  const currentUserId = decoded.userId;
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const scrollToBottom = () => {
    chatRoomRef?.current?.scrollTo({
      top: chatRoomRef.current.scrollHeight,
      // behavior: 'smooth',
    });
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyDownHandler);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [selectedFile, selectedFileType, selectedFileInfo, message]);

  useEffect(() => {
    props.fetchRoomsList(onError);
  }, []);

  useEffect(() => {
    if (!global?.socket?.connected) {
      // console.log('connecting socket Chat.js');
      connectSocket(); // reconnecting socket
    }
  }, [global?.socket]);

  useEffect(() => {
    if (global?.socket) {
      global?.socket?.on('message.new', (arg) => {
        // console.log('new message alert');
        setNewMessage(arg);
      });

      global?.socket?.on('room.new', (arg) => {
        // console.log('new room alert');
        setNewRoom(arg);
      });
    }
  }, []);

  useEffect(() => {
    const data = props.roomListData ? props.roomListData : [];
    setRooms(data);
    setFilteredRooms(data);
    setTempRooms(data);
  }, [props.roomListData]); // get and list room list data

  useEffect(() => {
    const data = tempRooms || [];
    if (
      state &&
      state.admin &&
      data &&
      data.length > 0 &&
      !props.roomListLoading
    ) {
      const selectedAdmin = data.filter(
        (item) => item.participants._id === state.admin
      )[0];
      setSelectedRoom(selectedAdmin);
      if (!global?.socket?.connected) {
        connectSocket(); // reconnecting socket
      }
      if (!selectedAdmin._id) {
        props.createRoom(selectedAdmin?.participants?._id, onError);
      } else {
        props.fetchRoomData(selectedAdmin._id, onError);
        onClickClear(); // clear selected file data
        setMessage(''); // clear message if user added text message
      }
      if (state.issueTitle)
        setMessage(`Referencing Issue ${state.issueTitle}, client notes below`);
    }
  }, [tempRooms]);

  useEffect(() => {
    const data = props.getRoomData ? props.getRoomData : [];
    setChatRoomData(data);
  }, [props.getRoomData]); //chatRoom data

  useEffect(() => {
    if (chatRoomData.length > 0) {
      scrollToBottom();
    }
  }, [chatRoomData]);

  useEffect(() => {
    let data = newRoom || {};
    if (Object.keys(data).length !== 0) {
      props.fetchRoomData(data._id, onError);
      const newData = rooms.map((item) => {
        if (data.participants.includes(item?.participants?._id)) {
          item._id = data._id;
        }
        return item;
      });
      if (searchRoom !== '') {
        const filterData = [];
        newData.filter((item) => {
          let name = item?.participants?.name;
          if (name.toLowerCase().indexOf(searchRoom.toLowerCase()) >= 0) {
            filterData.push(item);
          }
        });
        setFilteredRooms(filterData);
      } else {
        setRooms(newData);
        setFilteredRooms(newData);
        // props.updateRoomsList(newData);
      }
      data = {};
      setNewRoom({});
    }
  }, [newRoom]);

  useEffect(() => {
    let data = newMessage || {};
    if (Object.keys(data).length !== 0) {
      // updating roomlist Start //
      const newData = rooms.map((item) => {
        if (
          (item?.participants._id === data.receiver ||
            item?.participants._id === data.sender) &&
          item._id === data.room
        ) {
          item.lastMessage = data;
        }
        return item;
      });
      if (searchRoom !== '') {
        const filterData = [];
        newData.filter((item) => {
          let name = item?.participants?.name;
          if (name.toLowerCase().indexOf(searchRoom.toLowerCase()) >= 0) {
            filterData.push(item);
          }
        });
        setFilteredRooms(filterData);
      } else {
        setRooms(newData);
        setFilteredRooms(newData);
        // props.updateRoomsList(newData);
      }
      data = {};
      setNewMessage({});
      // updating roomlist End //
    }
  }, [newMessage]);

  useEffect(() => {
    const newRoom = props.createdRoomData || {};
    if (selectedRoom && Object.keys(newRoom).length !== 0) {
      let data = selectedRoom;
      data._id = newRoom._id;
      setSelectedRoom(data);
    }
  }, [props.createdRoomData]);

  useEffect(() => {
    let msgData = newMessage || {};
    const sameid = chatRoomData.filter((item) => item?._id === msgData?._id);
    if (
      msgData &&
      selectedRoom &&
      msgData?.room === selectedRoom?._id &&
      sameid?.length === 0
    ) {
      let newMsgData = {};
      newMsgData.content = msgData?.content || '';
      newMsgData.createdAt = msgData?.createdAt || '';
      newMsgData.type = msgData?.type || '';
      newMsgData.updatedAt = msgData?.updatedAt || '';
      newMsgData._id = msgData?._id || '';
      newMsgData.room = msgData?.room || '';
      newMsgData.sender = {
        name:
          msgData?.sender === currentUserId
            ? localStorage.userName
            : selectedRoom?.participants?.name || '',
        profilePicture:
          msgData?.sender === currentUserId
            ? localStorage.profilePicture
            : selectedRoom?.participants?.profilePicture || '',
        _id: msgData?.sender || '',
      };
      newMsgData.receiver = {
        name:
          msgData?.receiver === currentUserId
            ? localStorage.userName
            : selectedRoom?.participants?.name || '',
        profilePicture:
          msgData?.receiver === currentUserId
            ? localStorage.profilePicture
            : selectedRoom?.participants?.profilePicture || '',
        _id: msgData?.receiver || '',
      };
      let newChatData = [...chatRoomData, newMsgData];
      props.updateRoomData(newChatData);
      msgData = {};
      setNewMessage({});
      onClickClear(); // clear selected file data
      setMessage(''); // clear message if user added text message
    }
  }, [newMessage]); // when getting event for new message

  // useEffect(() => {
  //   const data = props.getCreateMessageData || {};
  //   const sameid = chatRoomData.filter((item) => item._id === data._id);
  //   if (sameid.length === 0 && Object.keys(data).length !== 0) {
  //     const userName = localStorage.userName;
  //     const profilePicture = localStorage.profilePicture;
  //     let newMsgData = {};
  //     newMsgData.content = data.content;
  //     newMsgData.createdAt = data.createdAt;
  //     newMsgData.type = data.type;
  //     newMsgData.updatedAt = data.updatedAt;
  //     newMsgData._id = data._id;
  //     newMsgData.room = data.room;
  //     newMsgData.receiver = {
  //       name: selectedRoom?.participants?.name || '',
  //       profilePicture: selectedRoom?.participants?.profilePicture,
  //       _id: data.receiver,
  //     };
  //     newMsgData.sender = {
  //       name: userName,
  //       profilePicture: profilePicture,
  //       _id: data.sender,
  //     };
  //     let newChatData = [...chatRoomData, newMsgData];
  //     props.updateRoomData(newChatData);
  //   }
  // }, [props.getCreateMessageData]);

  const filterRoomsBySearch = (e) => {
    setMessage('');
    setSelectedFile('');
    if (inputFileRef && inputFileRef.current) inputFileRef.current.value = '';
    setSelectedFileInfo('');
    setSearchRoom(e.target.value);
    if (e.target.value !== '') {
      const filterData = [];
      rooms.filter((item) => {
        let name = item?.participants?.name;
        if (name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
          filterData.push(item);
        }
      });
      setFilteredRooms([...filterData]);
    } else {
      setFilteredRooms(rooms);
    }
  };

  const onClickRoomList = async (data) => {
    if (!selectedRoom || (selectedRoom && selectedRoom._id !== data._id)) {
      setSelectedRoom(data);
      if (!global?.socket?.connected) {
        await connectSocket(); // reconnecting socket
      }
      if (!data._id) {
        await props.createRoom(data?.participants?._id, onError);
      } else {
        await props.fetchRoomData(data._id, onError);
        await onClickClear(); // clear selected file data
        await setMessage(''); // clear message if user added text message
      }
    }
  };

  useEffect(() => {
    if (props?.uploadFileData && props?.uploadFileData?.file?.length > 0) {
      const data = {
        receiverId: selectedRoom.participants._id,
        roomId: selectedRoom._id,
        type: props?.uploadFileData?.file[0].type,
        content: {
          path: props?.uploadFileData?.file[0].url,
          filename: props?.uploadFileData?.file[0].fileName,
          size: props?.uploadFileData?.file[0].size,
        },
      };
      props.createMessage(data, onError);
      setTimeout(() => {
        props.clearUploadFile();
      }, 2000);
    }
  }, [props.uploadFileData]);

  const sendMessage = async () => {
    if (message !== '') {
      const data = {
        receiverId: selectedRoom.participants._id,
        roomId: selectedRoom._id,
        type: 'text',
        content: {
          text: message,
        },
      };

      await props.createMessage(data, onError);
      // await setMessage('');
    } else {
      const data = {
        files: selectedFile,
      };
      await props.uploadFile(data, '', onError);
      // await setSelectedFile('');
      // await setSelectedFileType('');
      // await setSelectedFileInfo('');
    }
  };

  const getFileSize = (data) => {
    const size = data / 1000000;
    return size.toFixed(2) + 'mb';
  };

  const onChangeFileInput = async (e) => {
    const file = e.target.files;
    // setSelectedFile(URL.createObjectURL(file));
    // console.log('file', file);
    // if (file[0].type.includes('image')) {
    //   const base64 = await convertBase64(file[0]);
    //   setSelectedImage(base64);
    //   setSelectedFile(file);
    // } else {
    const splitType = file[0].name.split('.');
    const type = splitType[splitType.length - 1];
    await setSelectedFile(file);
    await setSelectedFileType(type);
    await setSelectedFileInfo(
      file[0].name + ` (${getFileSize(file[0].size)}) `
    );
    setMessage('');
    // }
  };

  useEffect(() => {
    onClickClear();
  }, [props.createMessageLoading]);

  const onClickClear = () => {
    if (!props.uploadFileLoading && !props.createMessageLoading) {
      setSelectedFile('');
      setSelectedFileType('');
      setSelectedImage('');
      setSelectedFileInfo('');
      setMessage('');
      if (inputFileRef?.current?.value) {
        inputFileRef.current.value = '';
      }
    }
  };

  const onKeyDownHandler = async (e) => {
    if (
      width > 1024 &&
      e.keyCode === 13 &&
      !e.shiftKey &&
      (selectedFileInfo || message.trim().length > 0)
    ) {
      // (Enter button) Key KeyCode - (shift + enter) to go next line
      e.preventDefault(); // Prevent textfield newline when enter
      await sendMessage();
    }
  };

  // useEffect(() => {
  //   const data = filteredRooms || [];
  //   const selectedAdmin = data.filter(
  //     (item) => item.participants._id === state.admin
  //   )[0];
  //   if (
  //     state &&
  //     state.admin &&
  //     data &&
  //     data.length > 0 &&
  //     selectedAdmin &&
  //     !props.roomListLoading
  //   ) {
  //     setSelectedRoom(selectedAdmin);
  //     if (!global?.socket?.connected) {
  //       connectSocket(); // reconnecting socket
  //     }
  //     if (!selectedAdmin._id) {
  //       props.createRoom(selectedAdmin?.participants?._id, onError);
  //     } else {
  //       props.fetchRoomData(selectedAdmin._id, onError);
  //       onClickClear(); // clear selected file data
  //       setMessage(''); // clear message if user added text message
  //     }
  //   }
  // }, [filteredRooms]);

  return (
    <div className={classes.chat_container} style={{ height: height - 75 }}>
      {props.roomListLoading ? (
        <CommonLoader />
      ) : (
        <>
          <div
            className={classNames(
              classes.chat_left_container,
              !selectedRoom && classes.container_width
            )}
          >
            <TitleContainer
              containerClassName={classes.title_container}
              containerStyle={{
                display: isMobileView && selectedRoom && 'none',
              }}
              title={Strings.CHAT}
            />
            {!isTabView && (
              <div className={classes.header_container}>
                <div className={classes.left_header_container}>
                  <div className={classes.flex_view} style={{ height: '100%' }}>
                    <label className={classes.text_light}>
                      {`${Strings.MESSAGES}`}
                    </label>
                  </div>
                </div>

                <div
                  className={classNames(
                    classes.right_header_container,
                    !selectedRoom && classes.no_selection_style
                  )}
                >
                  {selectedRoom && (
                    <div
                      className={classes.flex_view}
                      style={{ height: '100%' }}
                    >
                      {/* <Textbox
                  name=''
                  onChange={(e) => setSearchChat(e.target.value)}
                  value={searchChat}
                  placeholder={Strings.TYPE_TO_SEARCH}
                  className={classes.chat_search_input}
                  leftIcon={icons(theme).searchIcon}
                  leftIconClassName={classes.search_icon}
                /> */}
                      <label className={classes.toolbar_label}>
                        {selectedRoom?.participants?.name}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={classes.content_container}>
              <div
                className={classes.left_content_container}
                style={{ display: isMobileView && selectedRoom && 'none' }}
              >
                <Textbox
                  name=''
                  onChange={(e) => filterRoomsBySearch(e)}
                  value={searchRoom}
                  placeholder={Strings.TYPE_TO_SEARCH}
                  className={classes.message_search_input}
                  containerClassName={classes.message_search_box}
                  leftIcon={icons(theme).searchIcon}
                />
                <div className={classes.user_list_container}>
                  {!props.roomListLoading &&
                    filteredRooms.length > 0 &&
                    filteredRooms.map((data, index) => (
                      <CommonUserMessageBox
                        key={index}
                        classes={classes}
                        data={data}
                        onClick={() => onClickRoomList(data)}
                        selectedRoom={selectedRoom}
                        theme={theme}
                      />
                    ))}
                </div>
              </div>
              <div
                className={classNames(
                  classes.right_content_container,
                  !selectedRoom && classes.no_selection_style,
                  !selectedRoom && classes.no_selection_right_style
                )}
                style={{ display: isMobileView && !selectedRoom && 'none' }}
              >
                {!selectedRoom ? (
                  <div className={classes.no_selection_text}>
                    {Strings.INITIAT_CHAT}
                  </div>
                ) : (
                  <>
                    {isTabView && (
                      <div className={classes.right_content_toolbar}>
                        {isMobileView && (
                          <img
                            src={icons(theme).backIcon}
                            alt=''
                            className={classes.back_icon}
                            onClick={() => setSelectedRoom(null)}
                          />
                        )}
                        <label className={classes.toolbar_label}>
                          {selectedRoom?.participants?.name}
                        </label>
                        {/* <Searchbox
                  value={searchChat}
                  containerClassName={classes.searchbox_container}
                  onChange={(e) => setSearchChat(e.target.value)}
                /> */}
                      </div>
                    )}
                    <div
                      ref={chatRoomRef}
                      className={classes.chat_message_view_container}
                    >
                      {props.getRoomDataLoading ? (
                        <CommonLoader />
                      ) : (
                        chatRoomData.map((data, index) => (
                          <MessageContainer
                            classes={classes}
                            theme={theme}
                            key={index}
                            index={index}
                            data={data}
                            currentUserId={currentUserId}
                            getFileSize={getFileSize}
                            chatRoomData={chatRoomData}
                            width={width}
                          />
                        ))
                      )}
                    </div>
                    <div
                      className={classes.chat_message_type_container}
                      // onKeyDown={onKeyDownHandler}
                    >
                      <Textbox
                        name='note'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder={
                          selectedFileInfo
                            ? selectedFileInfo
                            : Strings.YOUR_MESSAGE
                        }
                        className={classNames(
                          classes.information_input,
                          classes.message_input
                        )}
                        multiline
                        rows={2}
                        disabled={selectedFileInfo}
                        autoFocus={!isMobileView}
                      />
                      <div className={classes.message_action_container}>
                        {/* {selectedImage !== '' && (
                          <div className={classes.selected_files_container}>
                            <img
                              className={classes.selected_files}
                              src={selectedImage}
                              alt=''
                            />

                            <img
                              className={classes.close_icon}
                              src={icons(theme).closeIcon}
                              alt=''
                              onClick={onClickClear}
                            />
                          </div>
                        )} */}

                        {selectedFile && typeof selectedFile[0] === 'object' && (
                          <div className={classes.selected_files_container}>
                            <img
                              className={classes.selected_files}
                              src={icons(theme).dummyFile}
                              alt=''
                            />

                            <p className={classes.file_type}>
                              {selectedFileType.toUpperCase()}
                            </p>

                            <img
                              className={classes.close_icon_left}
                              src={icons(theme).closeIcon}
                              alt=''
                              onClick={onClickClear}
                            />
                          </div>
                        )}

                        <input
                          id='fileInput'
                          style={{ display: 'none' }}
                          ref={inputFileRef}
                          onChange={onChangeFileInput}
                          type='file'
                          accept='*'
                        />
                        <img
                          onClick={() => inputFileRef.current.click()}
                          className={classes.browse_button}
                          src={icons(theme).browseIcon}
                          alt=''
                        />
                        <Button
                          containerClassName={
                            classes.message_send_button_container
                          }
                          className={classes.message_send_button}
                          loading={
                            props.uploadFileLoading ||
                            props.createMessageLoading
                          }
                          disabled={
                            props.uploadFileLoading ||
                            props.createMessageLoading ||
                            (selectedFile === '' && message === '') ||
                            (message && message.trim().length === 0)
                          }
                          icon={icons(theme).sendIcon}
                          onClick={sendMessage}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {!isTabView && selectedRoom && (
            <div className={classes.chat_right_container}>
              <ProfileDetailBox
                classes={classes}
                profileImage={selectedRoom?.participants?.profilePicture}
                name={selectedRoom?.participants?.name}
                userType='Technician'
                joinYear='2022'
              />
              {/* <div className={classes.assigned_task_container}>
            <label className={classes.text_small_blue}>
              {Strings.ASSIGNED_TASK.toUpperCase()}
            </label>
            {assignedJobArray.map((data, index) => (
              <label className={classes.text_bold} key={index}>
                {data}
              </label>
            ))}
          </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  roomListLoading: state.roomListData.loading,
  roomListData: state.roomListData.rooms,
  createdRoomData: state.createRoomData.data,
  getRoomData: state.getRoomData.data,
  getRoomDataLoading: state.getRoomData.loading,
  getCreateMessageData: state.createMessageData.data,
  createMessageLoading: state.createMessageData.loading,
  uploadFileLoading: state.uploadFileData.loading,
  uploadFileData: state.uploadFileData.data,
});

export default connect(mapStateToProps, {
  fetchRoomsList,
  createRoom,
  updateRoomsList,
  fetchRoomData,
  createMessage,
  updateRoomData,
  uploadFile,
  clearUploadFile,
})(Chat);
