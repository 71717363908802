import { GET_REPORT } from '../../../constants';
import axios from 'axios';

export const getReport = (jobId, onError, isParamJobId) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetReport());
  let endpoint = isParamJobId ? `/report?jobId=${jobId}&isWeb=${true}` : `/report/view?jobId=${jobId}&isWeb=${true}`
  axios
    .get(endpoint, config)
    .then((res) => dispatch(setGetReport(res.data.data)))
    .catch((err) => {
      dispatch(errorGetReport(err.response));
      onError(err.response);
    });
};

export const loadGetReport = () => ({
  type: GET_REPORT.LOAD,
});

export const setGetReport = (reportData) => ({
  type: GET_REPORT.SUCCESS,
  payload: reportData,
});

export const errorGetReport = (error) => ({
  type: GET_REPORT.FAIL,
  payload: error,
});
