import { useEffect } from 'react';
import Router from './routes';
import { ThemeProvider } from '@mui/material/styles';
import store from './redux/store';
import { Provider } from 'react-redux';
import './App.css';
import CloseIcon from './assets/images/close-white.svg';
import ErrorIcon from './assets/images/error-icon.svg';
import SuccessIcon from './assets/images/success-icon.svg';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { LayoutProvider } from './context/LayoutContext';
import useWindowTheme from './hooks/windowTheme';
// import useConnectSocket from './hooks/socket';
import { ONESIGNAL_APP_ID, ONESIGNAL_SAFARI_WEB_ID } from './utils/appUtils';
import OneSignal from 'react-onesignal';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '20px 10px 10px 10px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 100,
    fontSize: '14px',
  },
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    className={`alert-style ${options.type === 'error' && 'alert-error'} ${
      options.type === 'success' && 'alert-success'
    }`}
    style={style}
  >
    <img
      className='alert-icon'
      src={options.type === 'error' ? ErrorIcon : SuccessIcon}
    />
    {message}
    <img className='alert-close-icon' src={CloseIcon} onClick={close} />
  </div>
);

function App() {
  // const { connectSocket } = useConnectSocket();

  // useEffect(() => {
  //   if (localStorage.jwtToken && !global?.socket?.connected) {
  //     connectSocket(); // reconnecting socket
  //   }
  // }, [global?.socket]);

  useEffect(() => {
    OneSignal.init({
      appId: ONESIGNAL_APP_ID,
      safari_web_id: ONESIGNAL_SAFARI_WEB_ID,
      allowLocalhostAsSecureOrigin: true,
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',
      autoResubscribe: true,
      welcomeNotification: {
        disable: true,
      },
    });
    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) console.log('Push notifications are enabled!');
      else OneSignal.showNativePrompt();
    });
  }, []);

  return (
    <LayoutProvider>
      <Provider store={store}>
        <ThemeProvider theme={useWindowTheme()}>
          <AlertProvider template={AlertTemplate} {...options}>
            <Router />
          </AlertProvider>
        </ThemeProvider>
      </Provider>
    </LayoutProvider>
  );
}

export default App;
