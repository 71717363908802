import { UPDATE_JUNCTURE } from '../../constants';
import axios from 'axios';

export const updateJuncture =
  (junId, data, onError, onDialogClose) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateJuncture());
    axios
      .put(`/client/updateJuncture?junctureId=${junId}`, data, config)
      .then((res) => {
        dispatch(setUpdateJuncture(res.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateJuncture(err.response));
        onError(err.response);
      });
  };

export const loadUpdateJuncture = () => ({
  type: UPDATE_JUNCTURE.LOAD,
});

export const setUpdateJuncture = (junctureData) => ({
  type: UPDATE_JUNCTURE.SUCCESS,
  payload: junctureData,
});

export const errorUpdateJuncture = (error) => ({
  type: UPDATE_JUNCTURE.FAIL,
  payload: error,
});
