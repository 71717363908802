import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  White_menu: {
    position: 'absolute',
    right: 12,
    top: 12,
    height: 22,
    width: 22,
    cursor: 'pointer'
  },
  action_button_arrow: {
    marginLeft: 0,
    height: 24,
    width: 24,
  },
}));
