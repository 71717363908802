import axios from 'axios';
import { VENDOR_LIST } from '../../constants';

export const fetchVendorList = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadVendorList());

  const search = data.search;
  const payload = data ? structuredClone(data) : {};
  delete payload.search;

  axios
    .post(`/admin/listVendors?searchString=${search}`, payload, config)
    .then((res) => dispatch(setVendorList(res)))
    .catch((err) => {
      dispatch(errorVendorList(err.response));
      onError(err.response);
    });
};

export const loadVendorList = () => ({
  type: VENDOR_LIST.LOAD,
});

export const setVendorList = (userList) => ({
  type: VENDOR_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorVendorList = (error) => ({
  type: VENDOR_LIST.FAIL,
  payload: error,
});
