import axios from 'axios';
import { CLIENT_DIRECTIVE_REMINDER } from '../../../constants';

export const clientDirectiveReminder =
  (id, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(addClientDirectiveReminder());
    axios
      .post(`/issue/clientDirectiveReminder?issueId=${id}`, {}, config)
      .then((res) => {
        onSuccess('Successfully sent reminder');
        dispatch(setClientDirectiveReminder(res));
      })
      .catch((err) => {
        dispatch(errorClientDirectiveReminder(err.response));
        onError(err.response);
      });
  };

export const addClientDirectiveReminder = () => ({
  type: CLIENT_DIRECTIVE_REMINDER.LOAD,
});

export const setClientDirectiveReminder = (data) => ({
  type: CLIENT_DIRECTIVE_REMINDER.SUCCESS,
  payload: data.data,
});

export const errorClientDirectiveReminder = (error) => ({
  type: CLIENT_DIRECTIVE_REMINDER.FAIL,
  payload: error,
});
