import axios from 'axios';
import { DASHBOARD_ISSUE_LIST } from '../../constants';
import moment from 'moment-timezone';

export const fetchDashboardIssueList = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  data.currentDate = moment().format('YYYY-MM-DD');
  data.timeZone = moment.tz.guess();

  dispatch(loadDashboardIssueList());
  axios
    .post(`/admin/dashboard/listIssue`, data, config)
    .then((res) => {
      dispatch(setDashboardIssueList(res));
    })
    .catch((err) => {
      dispatch(errorDashboardIssueList(err.response));
      onError(err.response);
    });
};

export const loadDashboardIssueList = () => ({
  type: DASHBOARD_ISSUE_LIST.LOAD,
});

export const setDashboardIssueList = (data) => ({
  type: DASHBOARD_ISSUE_LIST.SUCCESS,
  payload: data.data.data,
});

export const errorDashboardIssueList = (error) => ({
  type: DASHBOARD_ISSUE_LIST.FAIL,
  payload: error,
});
