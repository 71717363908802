import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  },
  issues_header: {
    display: 'flex',
    alignItems: 'center',
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginRight: 10,
  },
  issues_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    // cursor: 'pointer',
  },
  date_label: {
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 'auto',
  },
  issues_footer: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  issue_status: {
    padding: '3px 12px',
    marginLeft: 'auto',
    // backgroundColor: theme.palette.background_button,
    backgroundColor: Colors.NEVY_BLUE,
    borderRadius: 40,
    color: theme.palette.background_button_text,
    fontSize: 14,
    fontWeight: 400,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: 25,
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  link_txt: {
    color: theme.palette.background_button,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 600,
  },
}));
