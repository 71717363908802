import axios from 'axios';
import { UPDATE_PROGRAM_TYPE } from '../../constants';

export const updateProgramType =
  (pId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateProgramType());
    axios
      .put(`client/updateProgram/${pId}`, data, config)
      .then((res) => {
        dispatch(setUpdateProgramType(res));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateProgramType(err.response));
        onError(err.response);
      });
  };

export const loadUpdateProgramType = () => ({
  type: UPDATE_PROGRAM_TYPE.LOAD,
});

export const setUpdateProgramType = (data) => ({
  type: UPDATE_PROGRAM_TYPE.SUCCESS,
  payload: data.data,
});

export const errorUpdateProgramType = (error) => ({
  type: UPDATE_PROGRAM_TYPE.FAIL,
  payload: error,
});
