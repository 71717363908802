import React, { useEffect, useRef, useState, useCallback } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { debounce } from 'lodash';

const Textbox = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props?.value || '');
  const [cursorPosition, setCursorPosition] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const textareaRef = useRef(null);
  const [selection, setSelection] = useState({ start: null, end: null });

  useEffect(() => {
    if (props?.value) setValue(props?.value);
    else setValue('');
  }, [props?.value]);

  const handleOnChange = useCallback(
    debounce((e) => props.onChange(e), 0),
    [props.onChange]
  );

  useEffect(() => {
    if (textareaRef.current && cursorPosition !== null) {
      textareaRef.current.setSelectionRange(selection.start, selection.end);
      textareaRef.current.scrollTop = scrollPosition;
    }
  }, [cursorPosition, value]);

  const handleChange = (e) => {
    const { value, selectionStart, selectionEnd } = e.target;
    setSelection({ start: selectionStart, end: selectionEnd });
    setScrollPosition(textareaRef.current.scrollTop);
    setValue(value);
    setCursorPosition(selectionEnd);
    props.onChange(e);
  };

  const handleKeyDown = (e) => {
    const { selectionStart, selectionEnd, value } = e.target;
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCursorPosition(value.length);
      setSelection({ start: value.length, end: value.length });
    }
    if (textareaRef.current) {
      setScrollPosition(textareaRef.current.scrollTop);
    }
  };

  const handleBlur = () => {
    setCursorPosition(null);
  };

  return (
    <div
      className={classNames(
        classes.textbox_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classNames(
            classes.input_label,
            props.labelClassName && props.labelClassName
          )}
          htmlFor={props.label}
        >
          {props.label}
        </label>
      )}
      {props.leftIcon && (
        <img
          className={classNames(
            classes.left_icon,
            props.leftIconClassName && props.leftIconClassName
          )}
          src={props.leftIcon}
          alt=''
          onClick={props.onLeftIcon && props.onLeftIcon}
        />
      )}
      {props.multiline ? (
        <textarea
          className={classNames(
            classes.textbox,
            props.className && props.className
          )}
          rows={props.rows ? props.rows : '7'}
          // cols='70'
          value={value}
          onChange={handleChange}
          placeholder={props.placeholder}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          ref={textareaRef}
        />
      ) : (
        <input
          type={props.type ? props.type : 'text'}
          className={classNames(
            classes.textbox,
            props.className && props.className
          )}
          onChange={(e) => {
            setValue(e.target.value);
            handleOnChange(e);
          }}
          onClick={props.onClick}
          name={props.name}
          value={value}
          placeholder={props.placeholder}
          disabled={props.disabled}
          ref={props.inputRef && props.inputRef}
          readOnly={props.readOnly}
          onKeyDown={props.onKeyDown ? props.onKeyDown : handleKeyDown}
          onBlur={props.onBlur ? props.onBlur : handleBlur}
          min={props.min}
          maxLength={props.maxlength}
        />
      )}
      {props.error && (
        <p
          className={classNames(
            classes.input_error,
            props.errorClassName && props.errorClassName
          )}
        >
          {props.helperText}
        </p>
      )}
      {props.rightIcon &&
        (props.rightIconLoader ? (
          <CircularProgress size={20} className={classes.right_icon} />
        ) : (
          <img
            className={classNames(
              classes.right_icon,
              props.rightIconClassName && props.rightIconClassName
            )}
            src={props.rightIcon}
            alt=''
            onClick={props.onRightIcon && props.onRightIcon}
          />
        ))}
    </div>
  );
};

export default React.memo(Textbox);
