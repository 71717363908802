import { ADD_MY_NOTE } from '../../constants';

const initialState = {
  loading: false,
  data: {},
  error: {},
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ADD_MY_NOTE.LOAD:
      return {
        ...state,
        loading: true,
      };
    case ADD_MY_NOTE.SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case ADD_MY_NOTE.FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
