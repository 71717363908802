import axios from 'axios';
import { ADD_JOB_NOTE } from '../../../constants';

export const createJobNote = (jId, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addJobNote());
  axios
    .post(`/jobs/createJobNote?jobId=${jId}`, data, config)
    .then((res) => {
      onSuccess();
      dispatch(setAddJobNote(res.data.data));
    })
    .catch((err) => {
      dispatch(errorAddJobNote(err.response));
      onError(err.response);
    });
};

export const addJobNote = () => ({
  type: ADD_JOB_NOTE.LOAD,
});

export const setAddJobNote = (data) => ({
  type: ADD_JOB_NOTE.SUCCESS,
  payload: data,
});

export const errorAddJobNote = (error) => ({
  type: ADD_JOB_NOTE.FAIL,
  payload: error,
});
