import classNames from 'classnames';
import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';
import { Avatar } from '@material-ui/core';
import { Badge } from '@mui/material';

export default function Checkbox(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classNames(
        classes.container,
        props.containerContainerClass && props.containerContainerClass
      )}
    >
      <div
        className={classNames(
          classes.check_container,
          props.checked && classes.checkedContainer,
          props.checkboxContainerClass && props.checkboxContainerClass,
          props.checked &&
            props.checkedContainerClass &&
            props.checkedContainerClass
        )}
        onClick={(e) => {
          if (props?.isTableCheckbox) e.stopPropagation();
          props.onChange?.(props.value);
        }}
      >
        {props.checked && (
          <img
            className={classes.checked_icon}
            src={props?.checkedIcon || icons().rightIcon}
            alt=''
          />
        )}
      </div>
      {props.image && !props?.displayBadge ? (
        <Avatar
          style={{ width: 32, height: 32, marginLeft: 10 }}
          alt={props.label}
          src={props.image}
        />
      ) : null}
      {props?.displayBadge && props?.image ? (
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant='dot'
          className={classes.profileBadge}
          sx={{
            '& .MuiBadge-badge': {
              background: props?.color,
              color: props?.color,
            },
          }}
        >
          <Avatar
            style={{ width: 32, height: 32, marginLeft: 10 }}
            alt={props.label}
            src={props.image}
          />
        </Badge>
      ) : null}
      {props.color && !props.image && (
        <div
          style={{
            width: 32,
            height: 32,
            marginLeft: 10,
            borderRadius: '50%',
            backgroundColor: props.color,
          }}
        />
      )}
      {props.label && (
        <label
          className={classNames(
            classes.check_label,
            props.labelClassName && props.labelClassName
          )}
        >
          {props.label}
        </label>
      )}
    </div>
  );
}
