import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 2,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: theme.palette.form_input_background,
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 12,
  },
  add_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  form_group: {
    backgroundColor: theme.palette.group_form_background,
    borderRadius: 8,
    marginTop: 12,
    padding: '6px 16px 12px',
    border: `1px solid ${theme.palette.group_form_background}`,
  },
  group_header_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 2,
  },
  toggle_arrow: {
    width: 15,
    height: 15,
    cursor: 'pointer',
    paddingRight: 5,
  },
  group_label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
  action_icons: {
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  group_form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: theme.palette.form_input_background,
    color: theme.palette.form_label,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  group_form_field_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingTop: 5,
    padding: '8px 0px 4px 0px',
  },
  form_switch_label: {
    color: theme.palette.search_text,
    fontWeight: 600,
  },
  right_icon: {
    width: 24,
    height: 24,
    position: 'absolute',
    right: 13,
    cursor: 'pointer',
  },
  form_input_switch_container: {
    marginRight: 8,
  },
  form_field_input_container: {
    marginLeft: 12,
  },
  form_group_field: {
    backgroundColor: theme.palette.group_form_background,
    borderRadius: 8,
    marginTop: 12,
    padding: '6px 0px 12px',
  },
  add_option_label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
  add_option_contain: {
    paddingTop: 10,
  },
  dropdown: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: theme.palette.form_input_background,
    borderRadius: 8,
  },
}));
