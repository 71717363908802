import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 2,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
  },
  profile_upload_container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
  profile_border: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.background_button}`,
    position: 'relative',
  },
  profile_image: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  upload_icon_container: {
    position: 'absolute',
    backgroundColor: Colors.SIDEBAR_BLUE,
    width: 24,
    height: 24,
    borderRadius: '50%',
    bottom: -2,
    right: -5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  upload_icon: {
    width: 16,
    height: 16,
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    // border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 12,
  },
  phoneno_container: {
    display: 'flex',
  },
  countryCodeContainer: {
    marginRight: 5,
    minWidth: 100,
    maxWidth: 150,
  },
  phoneno_margin: {
    marginTop: 38,
  },
  add_button_container: {
    marginTop: 17,
  },
  add_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  disableInput: {
    backgroundColor: theme.palette.disable_color,
  },
  errorClassName: {
    position: 'inherit',
    bottom: 'auto',
    margin: 0,
  },
}));
