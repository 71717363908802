import axios from 'axios';
import { ADD_PROPERTY } from '../../constants';

export const createProperty = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addProperty());
  axios
    .post(`/client/addProperty`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAddProperty(res));
    })
    .catch((err) => {
      dispatch(errorAddProperty(err.response));
      onError(err.response);
    });
};

export const addProperty = () => ({
  type: ADD_PROPERTY.LOAD,
});

export const setAddProperty = (data) => ({
  type: ADD_PROPERTY.SUCCESS,
  payload: data.data,
});

export const errorAddProperty = (error) => ({
  type: ADD_PROPERTY.FAIL,
  payload: error,
});
