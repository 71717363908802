import axios from 'axios';
import { ADD_MY_NOTE } from '../../constants';

export const addMyNote = (data, onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddMyNote());
  axios
    .post(`/admin/notes`, data, config)
    .then((res) => {
      dispatch(setAddMyNote(res));
      callback?.();
    })
    .catch((err) => {
      dispatch(errorAddMyNote(err.response));
      onError(err.response);
    });
};

export const loadAddMyNote = () => ({
  type: ADD_MY_NOTE.LOAD,
});

export const setAddMyNote = (data) => ({
  type: ADD_MY_NOTE.SUCCESS,
  payload: data.data,
});

export const errorAddMyNote = (error) => ({
  type: ADD_MY_NOTE.FAIL,
  payload: error,
});
