import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useStyles from './styles';

export const ProfileBox = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { profileImage, name, role } = props;
  return (
    <div className={classes.profile_container}>
      <Avatar sx={{ width: 46, height: 46 }} alt='' src={profileImage} />
      <div className={classes.profile_name_container}>
        <label htmlFor='name' className={classes.profile_name}>
          {name}
        </label>
        <label htmlFor='role' className={classes.role}>
          {role}
        </label>
      </div>
      {/* <img
          className={classes.down_arrow}
          src={theme.palette.mode === 'dark' ? DownArrowWhite : DownArrow}
          alt=''
        /> */}
    </div>
  );
};
