import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';
// import DownArrowWhite from '../../assets/images/down-arrow-white.svg';

export default makeStyles((theme) => ({
  time_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    marginLeft: 10
  },
  time: {
    padding: 12,
    fontSize: 14,
    borderRadius: 8,
    fontFamily: "'Open Sans', sans-serif",
    outline: 'none',
    fontWeight: 400,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.input_border}`,
    color: theme.palette.profile_title,
    appearance: 'none',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    cursor: 'text',
    // background: `transparent url(${
    //   theme.palette.mode === 'dark' ? DownArrowWhite : DownArrow
    // }) no-repeat right 1rem center/1rem 4rem`,
    '&::-webkit-calendar-picker-indicator': {
      // background: `transparent url(${icons(theme).clockIcon})`,
      // width: 12,
      // height: 12,
      // backgroundRepeat: 'no-repeat',
      display: 'none'
    },
    width: '100%'
  },
  time_input: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  clock_icon: {
    position: 'absolute',
    right: 15,
    alignSelf: 'center',
    cursor: 'pointer'
  },
  title_color: {
    color: theme.palette.input_placeholder,
  },
  time_label: {
    marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  time_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },
  time_error1: {
    bottom: -5,
  },
  increse_decrese_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  increse_decrese: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    margin: '0px 5px',
  },
}));
