import axios from 'axios';
import { NOTIFICATION_COUNT } from '../../constants';

export const fetchNotificationCount = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadNotificationCount());
  axios
    .get('/notification/unreadCount', config)
    .then((res) => dispatch(setNotificationCount(res.data.data)))
    .catch((err) => {
      dispatch(errorNotificationCount(err.response));
      onError(err.response);
    });
};

export const loadNotificationCount = () => ({
  type: NOTIFICATION_COUNT.LOAD,
});

export const setNotificationCount = (countData) => ({
  type: NOTIFICATION_COUNT.SUCCESS,
  payload: countData,
});

export const errorNotificationCount = (error) => ({
  type: NOTIFICATION_COUNT.FAIL,
  payload: error,
});
