import axios from 'axios';
import { NOTIFICATION_LIST } from '../../constants';

export const fetchNotificationList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadNotificationList());
  axios
    .get('/notification/list', config)
    .then((res) => dispatch(setNotificationList(res.data.data)))
    .catch((err) => {
      dispatch(errorNotificationList(err.response));
      onError(err.response);
    });
};

export const loadNotificationList = () => ({
  type: NOTIFICATION_LIST.LOAD,
});

export const setNotificationList = (data) => ({
  type: NOTIFICATION_LIST.SUCCESS,
  payload: data,
});

export const errorNotificationList = (error) => ({
  type: NOTIFICATION_LIST.FAIL,
  payload: error,
});
