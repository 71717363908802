import axios from 'axios';
import { GET_CLIENTS_AVAILABILITY } from '../../constants';

export const fetchClientsAvailability = (category, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientsAvailability());

  axios
    .get(`/admin/userByStatus?userAvailability=${category}`, config)
    .then((res) => dispatch(setClientsAvailability(res)))
    .catch((err) => {
      dispatch(errorClientsAvailability(err.response));
      onError(err.response);
    });
};

export const loadClientsAvailability = () => ({
  type: GET_CLIENTS_AVAILABILITY.LOAD,
});

export const setClientsAvailability = (clientList) => ({
  type: GET_CLIENTS_AVAILABILITY.SUCCESS,
  payload: clientList.data.data,
});

export const errorClientsAvailability = (error) => ({
  type: GET_CLIENTS_AVAILABILITY.FAIL,
  payload: error,
});
