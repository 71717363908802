import axios from 'axios';
import { UPDATE_JOB_FORM } from '../../../constants';

export const updateJobForm =
  (fId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateJobForm());
    axios
      .put(`jobs/updateJobForm?formId=${fId}`, data, config)
      .then((res) => {
        dispatch(setUpdateJobForm(res));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateJobForm(err.response));
        onError(err.response);
      });
  };

export const loadUpdateJobForm = () => ({
  type: UPDATE_JOB_FORM.LOAD,
});

export const setUpdateJobForm = (data) => ({
  type: UPDATE_JOB_FORM.SUCCESS,
  payload: data.data,
});

export const errorUpdateJobForm = (error) => ({
  type: UPDATE_JOB_FORM.FAIL,
  payload: error,
});
