import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Strings } from '../../utils/strings';
import { icons } from '../../utils/icons';
import { Colors } from '../../utils/color';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Avatar, CircularProgress, Grid } from '@material-ui/core';
import classNames from 'classnames';
import Searchbox from '../../components/Searchbox';
import MultiSelectOptions from '../../components/MultiSelectOptions';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import NextPrevious from '../../components/NextPreviousButton';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarFilter from './CalendarFilter';
import { AvatarGroup } from '@mui/material';
import NewJob from '../Jobs/NewJob';
import UserImage from '../../assets/images/user-avatar.svg';
import JobDetails from '../Jobs/JobDetails';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../redux/actions/adminActions/getUserListByType';
import {
  fetchCalendarData,
  setCalendarData,
} from '../../redux/actions/calendarActions/calendarData';
import { getErrorMessage, hexToRgbaGradient } from '../../utils/appUtils';
import { useAlert } from 'react-alert';
import {
  setUpdateJob,
  updateJob,
} from '../../redux/actions/jobActions/jobActions/updateJob';
import { updateAppointment } from '../../redux/actions/jobActions/jobActions/updateAppointment';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import store from '../../redux/store';
import { listFilter } from '../../redux/actions/filterActions/listFilter';
import Dropdown from '../../components/Dropdown';
import NewFilter from './CalendarFilter/NewFilter';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import FormSwitch from '../../components/Switch';
import { useDispatch } from 'react-redux';
import { setDeleteJob } from '../../redux/actions/jobActions/jobActions/deleteJob';
import { debounce } from 'lodash';
import { clearJobData } from '../../redux/actions/jobActions/jobActions/getJobById';

const timePeriodArray = ['MONTH', 'WEEK', 'DAY'];
const staticRoleOptions = [
  { title: 'Technician', value: 'technician' },
  { title: 'Admin', value: 'admin' },
  { title: 'Client', value: 'client' },
];
const filterStore = 'CalendarsFilter';

const CalendarStatus = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.data.map((item, index) => (
        <div
          className={classes.status_container}
          style={{ marginRight: index + 1 === props.data.length ? 0 : 25 }}
        >
          <div
            className={classes.status_indicator}
            style={{ backgroundColor: item.color }}
          />
          {`${item.length} ${item.label}`}
        </div>
      ))}
    </>
  );
};

const CalendarHeader = (
  toolbar,
  getCalendarData,
  timePeriod,
  setTimePeriod,
  date,
  setDate,
  startEndDate,
  setStartEndDate,
  calendarData,
  technicianList,
  setFilter,
  setSearch,
  setShowFilterDialog,
  setShowAddJobDialog,
  setSelectedFilter,
  selectedFilter,
  filterDropdownList,
  setOpenEditFilterDialog,
  onNavigate,
  setSelectedSlotDate,
  onRefreshClick,
  isCollapsedView,
  onClickCollapseIcon,
  setShowWorkWeek,
  handleFilterSelectionClick
) => {
  const classes = useStyles();
  const theme = useTheme();
  // const [timePeriod, setTimePeriod] = useState('MONTH');
  // const [date, setDate] = useState(new Date());
  // const [startEndDate, setStartEndDate] = useState();
  // const [showFilterDialog, setShowFilterDialog] = useState(false);
  // const [showAddJobDialog, setShowAddJobDialog] = useState(false);
  const fetchStartEndDate = (fetchDate) => {
    let start = moment(fetchDate).startOf('week');
    let end = moment(fetchDate).endOf('week');
    if (moment(start).format('MMM') === moment(end).format('MMM')) {
      start = moment(start).format('MMM D');
      end = moment(end).format('D');
    } else {
      start = moment(start).format('MMM D');
      end = moment(end).format('MMM D');
    }
    setStartEndDate(`${start} - ${end}`);
    let startDate = moment(fetchDate).startOf('week').format('YYYY-MM-DD');
    let endDate = moment(fetchDate).endOf('week').format('YYYY-MM-DD');
    let data = { startDate, endDate };
    if (technicianList.length > 0) data.technicians = technicianList;
    getCalendarData(data);
  };

  const calendarStatusArray = [
    {
      label: 'Not Started',
      color: Colors.STATUS_GRAY,
      length: calendarData?.notStarted || 0,
    },
    {
      label: 'In Progress',
      color: Colors.NEVY_BLUE,
      length: calendarData?.inProgress || 0,
    },
    {
      label: 'Complete',
      color: Colors.STATUS_GREEN,
      length: calendarData?.completed || 0,
    },
  ];

  const goToDayView = () => {
    localStorage.removeItem(filterStore);
    setFilter(null);
    toolbar.onView('day');
    setShowWorkWeek(false);
    setTimePeriod('DAY');
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    let data = { startDate, endDate };
    if (technicianList.length > 0) data.technicians = technicianList;
    getCalendarData(data);
    setSelectedFilter('');
  };
  const goToWeekView = () => {
    localStorage.removeItem(filterStore);
    setFilter(null);
    toolbar.onView('week');
    setTimePeriod('WEEK');
    fetchStartEndDate(date);
    setSelectedFilter('');
    setShowWorkWeek(true);
  };
  const goToMonthView = () => {
    localStorage.removeItem(filterStore);
    setFilter(null);
    toolbar.onView('month');
    setTimePeriod('MONTH');
    const startOfMonth = moment(date)
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date).clone().endOf('month').format('YYYY-MM-DD');
    let data = { startDate: startOfMonth, endDate: endOfMonth };
    if (technicianList.length > 0) data.technicians = technicianList;
    setSelectedFilter('');
    getCalendarData(data);
    setShowWorkWeek(false);
  };
  const onNextClick = () => {
    let mDate = toolbar.date;
    // let mDate = date;
    let newDate;
    if (timePeriod === 'MONTH') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      const startOfMonth = moment(newDate)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment(newDate)
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD');
      let data = { startDate: startOfMonth, endDate: endOfMonth };
      if (technicianList.length > 0) data.technicians = technicianList;
      getCalendarData(data);
    } else if (timePeriod === 'WEEK') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 7,
        1
      );
      fetchStartEndDate(newDate);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 1,
        1
      );
      setSelectedSlotDate(newDate);
      let startDate = moment(newDate).format('YYYY-MM-DD');
      let endDate = moment(newDate).format('YYYY-MM-DD');
      let data = { startDate, endDate };
      if (technicianList.length > 0) data.technicians = technicianList;
      getCalendarData(data);
    }
    setFilter(null);
    setSelectedFilter('');
    setDate(newDate);
    toolbar.onNavigate('next', newDate);
  };

  const onClickToday = () => {
    let startDate = moment(new Date()).format('YYYY-MM-DD');
    let endDate = moment(new Date()).format('YYYY-MM-DD');
    let data = { startDate, endDate };
    if (technicianList.length > 0) data.technicians = technicianList;
    getCalendarData(data);
    setSelectedSlotDate(startDate);
  };

  const onPreviousClick = () => {
    let mDate = toolbar.date;
    // let mDate = date;
    let newDate;
    if (timePeriod === 'MONTH') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      const startOfMonth = moment(newDate)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment(newDate)
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD');
      let data = { startDate: startOfMonth, endDate: endOfMonth };
      if (technicianList.length > 0) data.technicians = technicianList;
      getCalendarData(data);
    } else if (timePeriod === 'WEEK') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 7,
        1
      );
      fetchStartEndDate(newDate);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 1,
        1
      );
      setSelectedSlotDate(newDate);
      let startDate = moment(newDate).format('YYYY-MM-DD');
      let endDate = moment(newDate).format('YYYY-MM-DD');
      let data = { startDate, endDate };
      if (technicianList.length > 0) data.technicians = technicianList;
      getCalendarData(data);
    }
    setFilter(null);
    setSelectedFilter('');
    setDate(newDate);
    toolbar.onNavigate('prev', newDate);
  };

  return (
    <>
      <div className={classes.header_container}>
        <div className={classes.header_top_container}>
          <Grid container>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                marginRight: 'auto',
              }}
            >
              <div className={classes.time_period_container}>
                {timePeriodArray.map((item) => (
                  <Button
                    text={item}
                    containerClassName={classes.time_period_button_container}
                    className={classNames(
                      classes.time_period_button,
                      timePeriod === item && classes.selected_time_period_button
                    )}
                    onClick={() => {
                      setTimePeriod(item);
                      item === 'MONTH'
                        ? timePeriod !== 'MONTH' && goToMonthView()
                        : item === 'WEEK'
                        ? timePeriod !== 'WEEK' && goToWeekView()
                        : timePeriod !== 'DAY' && goToDayView();
                    }}
                  />
                ))}
              </div>
              <div
                className={classes.filter_main_container}
                style={{ opacity: technicianList.length > 0 ? 1 : 0.5 }}
              >
                <CustomizedTooltips title={Strings.FILTERS}>
                  <div className={classes.filter_container}>
                    <label htmlFor='filters'>{Strings.FILTERS}</label>
                    <img
                      className={classes.filter_icon}
                      src={icons(theme).filterIcon}
                      alt=''
                      onClick={() => {
                        if (technicianList.length > 0) {
                          setSearch('');
                          setShowFilterDialog(true);
                        }
                      }}
                    />
                  </div>
                </CustomizedTooltips>
                <CustomizedTooltips title={Strings.REFRESH}>
                  <img
                    className={classes.refresh_icon}
                    src={icons(theme).refreshIcon}
                    alt=''
                    onClick={() => {
                      onRefreshClick();
                    }}
                  />
                </CustomizedTooltips>
                <CustomizedTooltips title={Strings.CLEAR_FILTERS}>
                  <img
                    className={classes.refresh_icon}
                    src={icons(theme).clearFilterIcon}
                    alt=''
                    onClick={async () => {
                      // same functionality happens like Today Button so no need to add for perticular function
                      await localStorage.removeItem(filterStore);
                      await setFilter(null);
                      setSelectedFilter('');
                      setTimePeriod('DAY');
                      setShowWorkWeek(false);
                      onNavigate(new Date());
                      toolbar.onView('day');
                      onClickToday();
                    }}
                  />
                </CustomizedTooltips>
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* ===== functionality not final  for show day on day selection ===== */}
              {timePeriod === 'DAY' ? (
                <span style={{ color: theme.palette.profile_title }}>
                  {moment(date).format('dddd')}
                </span>
              ) : null}
              <NextPrevious
                showDateView
                showStartEndDate={timePeriod === 'WEEK' ? true : false}
                date={moment(date).format(
                  timePeriod === 'MONTH' ? 'MMM' : 'MMM D'
                )}
                year={moment(date).format('YYYY')}
                startEndDate={startEndDate}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                containerClassName={classes.date_action_container}
                isDay={timePeriod === 'DAY' ? true : false}
                onDateChange={(val) => {
                  setDate(val);
                  let startDate = moment(val).format('YYYY-MM-DD');
                  let endDate = moment(val).format('YYYY-MM-DD');
                  let data = { startDate, endDate };
                  if (technicianList.length > 0)
                    data.technicians = technicianList;
                  getCalendarData(data);
                }}
                value={date}
              />
            </Grid>
            <Grid item xs={3} className={classes.today_newJob_btncontainer}>
              {(timePeriod !== 'DAY' ||
                !moment(date).isSame(new Date(), 'day')) && (
                <Button
                  text={Strings.TODAY}
                  className={classes.new_button}
                  onClick={async () => {
                    setTimePeriod('DAY');
                    setShowWorkWeek(false);
                    onNavigate(new Date());
                    toolbar.onView('day');
                    await onClickToday();
                  }}
                />
              )}
              <Button
                text={Strings.NEW_JOB}
                className={classes.new_button}
                onClick={() => {
                  setSearch('');
                  setShowAddJobDialog(true);
                }}
              />
              <CustomizedTooltips
                title={
                  isCollapsedView ? Strings.EXPAND_VIEW : Strings.COLLAPSE_VIEW
                }
              >
                <img
                  src={
                    isCollapsedView
                      ? icons(theme).collapseLeftIcon
                      : icons(theme).collapseRightIcon
                  }
                  style={{
                    width: 25,
                    cursor: 'pointer',
                  }}
                  onClick={onClickCollapseIcon}
                />
              </CustomizedTooltips>
            </Grid>
          </Grid>
        </div>
        {/* <div className={classes.header_bottom_container}>
          <Grid container> */}
        {/* <Grid item xs={2} style={{ display: 'flex', marginRight: 'auto' }}>
              <div
                className={classes.filter_main_container}
                style={{ opacity: technicianList.length > 0 ? 1 : 0.5 }}
              >
                <CustomizedTooltips title={Strings.FILTERS}>
                  <div className={classes.filter_container}>
                    <label htmlFor='filters'>{Strings.FILTERS}</label>
                    <img
                      className={classes.filter_icon}
                      src={icons(theme).filterIcon}
                      alt=''
                      onClick={() => {
                        if (technicianList.length > 0) {
                          setSearch('');
                          setShowFilterDialog(true);
                        }
                      }}
                    />
                  </div>
                </CustomizedTooltips>
                <CustomizedTooltips title={Strings.REFRESH}>
                  <img
                    className={classes.refresh_icon}
                    src={icons(theme).refreshIcon}
                    alt=''
                    onClick={() => {
                      onRefreshClick();
                    }}
                  />
                </CustomizedTooltips>
                <CustomizedTooltips title={Strings.CLEAR_FILTERS}>
                  <img
                    className={classes.refresh_icon}
                    src={icons(theme).clearFilterIcon}
                    alt=''
                    onClick={async () => {
                      // same functionality happens like Today Button so no need to add for perticular function
                      await localStorage.removeItem(filterStore);
                      await setFilter(null);
                      setSelectedFilter('0');
                      setTimePeriod('DAY');
                      setShowWorkWeek(false);
                      onNavigate(new Date());
                      toolbar.onView('day');
                      onClickToday();
                    }}
                  />
                </CustomizedTooltips>
              </div>
            </Grid> */}
        {/* <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CalendarStatus data={calendarStatusArray} />
            </Grid> */}
        {/* <Grid item xs={3}>
              {filterDropdownList?.length > 0 && (
                <Dropdown
                  onChange={(e) => {
                    setSelectedFilter(e.target.value);
                    handleFilterSelectionClick(e.target.value);
                  }}
                  value={selectedFilter}
                  title={Strings.SELECT_SAVED_FILTER}
                  className={classes.form_input}
                  containerClassName={classes.dateSelector_container}
                  optionArray={filterDropdownList}
                  placeholder={Strings.SELECT_SAVED_FILTER}
                  isEditIcon
                  onEditIconClick={(value) =>
                    setOpenEditFilterDialog({ ...value, isShow: true })
                  }
                  disabled={technicianList.length > 0 ? false : true}
                  isFixedWidth
                />
              )}
            </Grid> */}
        {/* </Grid>
        </div> */}
      </div>
      {/* {showFilterDialog && (
        <CalendarFilter
          setFilterData={setFilterData}
          filterData={filter}
          onClose={() => setShowFilterDialog(false)}
        />
      )}
      {showAddJobDialog && (
        <NewJob onNewClose={() => setShowAddJobDialog(false)} />
      )} */}
    </>
  );
};

const EventComponent = (props) => {
  const { event } = props;
  const classes = useStyles();
  const theme = useTheme();

  // let findColor = event?.users?.filter(
  //   (item) => item._id === event?.resourceId
  // );
  let gradient = hexToRgbaGradient(event?.users[0]?.color);
  let background = `linear-gradient(0deg, ${gradient} , ${gradient})` || '';

  return (
    <div
      className={`event-start ${classes.event_outer_container}`}
      style={{
        borderLeft: `2px solid ${event?.users[0]?.color}`,
        background: background,
      }}
    >
      <div
        className={classNames(classes.event_container)}
        style={{
          justifyContent: !event.isShowProfile && 'center',
          // color: event?.users[0]?.color,
        }}
      >
        <div
          className={classes.header_top_container}
          style={{ alignItems: 'flex-end', gap: 5 }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            {event?.client && (
              <span
                className={classNames(
                  classes.event_subTitle_text,
                  classes.event_text_overflow
                )}
              >
                {event.client + ` (Client)`}
              </span>
            )}
            {event?.technician && (
              <span
                className={classNames(
                  classes.event_subTitle_text,
                  classes.event_text_overflow
                )}
              >
                {event.technician +
                  ` ${
                    event.technician.split(',').length > 1
                      ? '(Technicians)'
                      : '(Technician)'
                  }`}
              </span>
            )}
            {event?.admin && (
              <span
                className={classNames(
                  classes.event_subTitle_text,
                  classes.event_text_overflow
                )}
              >
                {event.admin + ` (Admin)`}
              </span>
            )}
            <span
              className={classNames(
                classes.event_subTitle_text,
                classes.event_text_overflow
              )}
            >
              {event?.propertyName || '-'}
            </span>
            {/* {!event?.admin &&
              !event?.technician &&
              !event?.client &&
              event.subTitle && (
                <span
                  className={classNames(
                    classes.event_subTitle_text,
                    classes.event_text_overflow
                  )}
                >
                  {event.subTitle}
                </span>
              )} */}
          </div>
          {/* {event.isShowProfile && (
            <AvatarGroup
              sx={{
                '& .MuiAvatar-root': {
                  width: 24,
                  height: 24,
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: 14,
                  fontWeight: 400,
                  border: `1px solid ${theme.palette.background_button_text}`,
                  color: theme.palette.profile_title,
                  backgroundColor: theme.palette.table_head_background,
                },
              }}
              spacing={5}
              max={2}
            >
              {event.users.map((item, index) => (
                <Avatar
                  className={classNames(classes.common_cell)}
                  alt={item.name}
                  key={index}
                  src={item.profilePicture}
                />
              ))}
            </AvatarGroup>
          )} */}
        </div>
        {event?.start && event?.end && (
          <span
            className={classNames(
              classes.event_subTitle_text,
              classes.event_text_overflow
            )}
            style={{ display: 'inline-block', marginTop: 5 }}
          >
            {`${moment(event.start).format('hh:mm')} - ${moment(
              event.end
            ).format('hh:mm')}`}
          </span>
        )}
        <span
          className={classNames(
            classes.event_title_text,
            classes.event_text_overflow
          )}
        >
          {event?.title}
        </span>
      </div>
    </div>
  );
};

const UserProfile = (props) => {
  const { profilePicture, name } = props;
  const classes = useStyles();
  return (
    <div className={classes.userProfile_container}>
      <Avatar src={profilePicture} alt='' style={{ height: 32, width: 32 }} />
      {name && (
        <span className={classes.month_day_text} style={{ fontSize: 11 }}>
          {name}
        </span>
      )}
    </div>
  );
};

const TechnicianListView = (props) => {
  const {
    filterDropdownList,
    setSelectedFilter,
    handleFilterSelectionClick,
    setOpenEditFilterDialog,
    technicianList,
    selectedFilter,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTeamValue, setSelectedTeamValue] = useState(
    Strings.SELECT_AN_OPTION
  );

  const onChangeMultiSelectOption = (value) => {
    props.setSelectedTeam(value);
    let string = '';
    if (value.length > 2) {
      string = `${value[0].title} +2`;
    } else if (value.length >= 1) {
      string =
        value.length === 1
          ? `${value[0]?.title}`
          : `${value[0]?.title}, ${value[1]?.title}`;
    } else {
      string = Strings.SELECT_AN_OPTION;
    }
    setSelectedTeamValue(string);
  };

  return (
    <div
      className={
        props.isCollapsedView
          ? classes.calendar_right_container_hide
          : classes.calendar_right_container
      }
    >
      {props.view !== 'month' && (
        <FormSwitch
          label={Strings.SHOW_24_HRS_TIME}
          value={props.show24HrTime}
          containerClassName={classes.toggle_btn}
          onChange={() => props.onChangeTimeToggle()}
        />
      )}

      {props.view === 'week' && (
        <FormSwitch
          label={Strings.SHOW_WEEKDAYS_ONLY}
          value={props.showWorkWeek}
          containerClassName={classes.toggle_btn}
          onChange={() => props.onChangeWorkWeekToggle()}
        />
      )}

      <label className={classes.technician}>{Strings.TEAM}</label>
      <label className={classNames(classes.technician, classes.filter_label)}>
        {Strings.SELECT_FILTER_TEAM}
      </label>
      <div style={{ marginTop: 10 }}>
        <MultiSelectOptions
          name='assignedTo'
          onChange={onChangeMultiSelectOption}
          value={props.selectedTeam}
          inputValue={selectedTeamValue}
          disabled={props.UserListByTypeLoading || props.loading}
          // label={Strings.ASSIGN_TO}
          // error={Boolean(touched.assignedTo && errors.assignedTo)}
          // helperText={touched.assignedTo && errors.assignedTo}
          // title={Strings.TYPE_TO_SEARCH}
          labelClassName={classes.form_label}
          // className={classes.form_input}
          containerClassName={classes.form_input_container}
          optionArray={staticRoleOptions}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        {filterDropdownList?.length > 0 && (
          <Dropdown
            onChange={(e) => {
              setSelectedFilter(e);
              handleFilterSelectionClick(e);
            }}
            value={selectedFilter}
            title={Strings.SELECT_SAVED_FILTER}
            className={classes.form_input}
            containerClassName={classes.dateSelector_container}
            optionArray={filterDropdownList}
            placeholder={Strings.SELECT_SAVED_FILTER}
            isEditIcon
            onEditIconClick={(value) =>
              setOpenEditFilterDialog({ ...value, isShow: true })
            }
            disabled={technicianList.length > 0 ? false : true}
            isFixedWidth
            searchable
          />
        )}
      </div>
      <Searchbox
        value={props.search}
        containerClassName={classes.searchbox_container}
        className={classes.searchbox}
        onChange={props.onChangeSearch}
      />
      {props.loading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : props.technicianList && props.technicianList?.length === 0 ? (
        <div className={classes.flexView}>
          <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
        </div>
      ) : (
        <div className={classes.technician_list_container}>
          <Checkbox
            label={Strings.SELECT_ALL}
            checkboxContainerClass={classes.checkbox_container}
            labelClassName={classes.labelClassName}
            checkedContainerClass={classes.checked_checkbox}
            checked={props.technicianList?.every((item) =>
              props.selectedTechnician.includes(item._id)
            )}
            onChange={() => props.onChangeSelectedTechnician('', true)}
          />
          {props.technicianList?.map((item) => (
            <Checkbox
              label={item?.name}
              containerContainerClass={classes.checkbox_margin}
              checkboxContainerClass={classes.checkbox_container}
              labelClassName={classes.labelClassName}
              checkedContainerClass={classes.checked_checkbox}
              image={item?.profilePicture}
              checked={props.selectedTechnician.includes(item._id)}
              onChange={() => props.onChangeSelectedTechnician(item._id)}
              color={item?.color}
              displayBadge={item?.profilePicture}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CalendarScreen = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const clickRef = useRef(null);
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('day');
  const [showJobDetail, setShowJobDetail] = useState(false);
  const [editJobDialog, setEditJobDialog] = useState(false);
  const [addJobDialog, setAddJobDialog] = useState(false);
  const [show24HrTime, setShow24HrTime] = useState(false);
  const [showWorkWeek, setShowWorkWeek] = useState(false);
  const [isCollapsedView, setIsCollapsedView] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [search, setSearch] = useState('');
  const alert = useAlert();
  const [timePeriod, setTimePeriod] = useState('DAY');
  const [date, setDate] = useState(new Date());
  const [startEndDate, setStartEndDate] = useState();
  const [filter, setFilter] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [selectedSlotDate, setSelectedSlotDate] = useState('');
  const DnDCalendar = withDragAndDrop(Calendar);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [selectedTech, setSelectedTech] = useState('');
  const [resources, setResources] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [allTechnician, setAllTechnician] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState();
  const [isFilterChange, setIsFilterChange] = useState(false);
  const dispatch = useDispatch();

  // === start week from monday ===
  moment.locale('en-gb', {
    week: {
      dow: 1,
    },
  });

  // ==== date box background ===
  const calendarStyle = () => ({
    style: {
      backgroundColor: theme.palette.background,
    },
  });

  const unWindData = (data) => {
    let results = [];
    data?.forEach((subArray) => {
      subArray?.users?.forEach((item) => {
        let data = { ...subArray };
        data.resourceId = item?._id;
        results.push(data);
      });
    });
    return results;
  };

  useEffect(() => {
    if (view === 'day' || view === 'month') {
      setShowWorkWeek(false);
    }
  }, [view]);

  // ==== set showProfile or not ====
  useEffect(() => {
    const tempData = props.calendarData?.jobs ? props.calendarData?.jobs : [];
    const resourceData = view === 'day' ? unWindData(tempData) : tempData;
    const newTempData = resourceData.map((item) => {
      const data = {
        title: item?.title,
        isShowProfile: true,
        users: item?.users,
        jobStatus: item?.jobStatus,
        subTitle: item?.client?.name,
        jobId: item?._id,
        appointmentId: item?.appointmentId,
        propertyName: item?.property?.name,
      };
      if (selectedTeam.length > 0) {
        for (let i of selectedTeam) {
          let str = '';
          if (i.value === 'technician') {
            for (let j of item.users) {
              str = str !== '' ? str + ', ' + j.name : j.name;
            }
          } else if (i.value === 'client') {
            str = str !== '' ? str + ', ' + item.client.name : item.client.name;
          } else if (i.value === 'admin') {
            str = str !== '' ? str + ', ' + item.admin.name : item.admin.name;
          }
          data[i.value] = str;
        }
      }

      if (view === 'day') data.resourceId = item?.resourceId;
      if (item.startDate) data.start = new Date(item.startDate);
      if (item.endDate) data.end = new Date(item.endDate);
      return data;
    });
    if (view === 'day') {
      let newData = newTempData.map((data) => {
        data['isShowProfile'] = false;
        return data;
      });
      setEvents(newData);
    } else {
      let newData = newTempData.map((data) => {
        data['isShowProfile'] = true;
        return data;
      });
      setEvents(newData);
    }
  }, [view, props.calendarData, selectedTeam]);

  useEffect(() => {
    // ===== Column for day view ====
    let resss;
    if (selectedTechnician.length > 0) {
      let newData = technicianList.filter((item) =>
        selectedTechnician.includes(item._id)
      );
      resss = newData.map((item) => {
        return {
          id: item._id,
          title: (
            <UserProfile
              profilePicture={item.profilePicture}
              name={item.name}
            />
          ),
        };
      });
    } else {
      resss = technicianList.map((item) => {
        return {
          id: item._id,
          title: (
            <UserProfile
              profilePicture={item.profilePicture}
              name={item.name}
            />
          ),
        };
      });
    }

    setResources(resss);
  }, [technicianList, selectedTechnician]);

  const getCalendarData = (data) => {
    props.fetchCalendarData(data, (err) => onError(err));
  };

  useEffect(() => {
    if (!showFilterDialog && !addJobDialog && props.UserListByTypeData) {
      let data = {};
      const storedFilter = localStorage.getItem(filterStore) || {};
      let prevFilter =
        Object.keys(storedFilter).length > 0 ? JSON.parse(storedFilter) : {};
      if (prevFilter?.filterId) {
        setSelectedFilter(prevFilter?.filterId || '');
      }
      setFilter(prevFilter?.filter);
      if (timePeriod === 'WEEK') {
        let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
        let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
        data.startDate = startDate;
        data.endDate = endDate;
      } else if (timePeriod === 'MONTH') {
        const startDate = moment(date)
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment(date)
          .clone()
          .endOf('month')
          .format('YYYY-MM-DD');
        data.startDate = startDate;
        data.endDate = endDate;
      } else {
        let startDate = moment(date).format('YYYY-MM-DD');
        let endDate = moment(date).format('YYYY-MM-DD');
        data.startDate = startDate;
        data.endDate = endDate;
      }

      const userList = props.UserListByTypeData ? props.UserListByTypeData : {};
      if (
        allTechnician?.length === 0 &&
        userList?.type === 'array' &&
        userList?.data?.length > 0
      ) {
        setAllTechnician([...userList?.data]);
      }
      if (userList?.type === 'array') {
        setTechnicianList(userList?.data || []);
        const selectedT = userList?.data?.map((item) => {
          return item._id;
        });
        // setSelectedTechnician(selectedT);
        const storedSelectedTechnician =
          localStorage.getItem('technicianSelected');
        const storedSelectedTechnicianList =
          storedSelectedTechnician !== 'undefined'
            ? JSON.parse(storedSelectedTechnician)
            : [];
        if (
          storedSelectedTechnicianList &&
          storedSelectedTechnicianList.length > 0
        ) {
          setSelectedTechnician(storedSelectedTechnicianList || []);
          data.technicians = storedSelectedTechnicianList;
        } else {
          setSelectedTechnician(selectedT || []);
          data.technicians = selectedT;
        }
        if (prevFilter) {
          data.filter = { ...prevFilter?.filter };
        }
        getCalendarData(data);
      }
    }
  }, [props.UserListByTypeData]);

  useEffect(() => {
    if (props.addJobData.length > 0 || props.addJobData?.data) {
      let data1 = {};
      if (timePeriod === 'WEEK') {
        let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
        let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
        data1.startDate = startDate;
        data1.endDate = endDate;
      } else if (timePeriod === 'MONTH') {
        const startDate = moment(date)
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment(date)
          .clone()
          .endOf('month')
          .format('YYYY-MM-DD');
        data1.startDate = startDate;
        data1.endDate = endDate;
      } else {
        let startDate = moment(date).format('YYYY-MM-DD');
        let endDate = moment(date).format('YYYY-MM-DD');
        data1.startDate = startDate;
        data1.endDate = endDate;
      }
      if (selectedTechnician.length > 0) data1.technicians = selectedTechnician;
      getCalendarData(data1);
    }
  }, [props.addJobData]);

  const onClickCollapseIcon = () => {
    setIsCollapsedView(!isCollapsedView);
  };

  const { components, defaultDate, formats } = useMemo(
    () => ({
      components: {
        toolbar: (toolbar) =>
          CalendarHeader(
            toolbar,
            getCalendarData,
            timePeriod,
            setTimePeriod,
            date,
            setDate,
            startEndDate,
            setStartEndDate,
            props.calendarData,
            selectedTechnician,
            setFilter,
            setSearch,
            setShowFilterDialog,
            setAddJobDialog,
            setSelectedFilter,
            selectedFilter,
            filterDropdownList,
            setOpenEditFilterDialog,
            onNavigate,
            setSelectedSlotDate,
            onRefreshClick,
            isCollapsedView,
            onClickCollapseIcon,
            setShowWorkWeek,
            handleFilterSelectionClick
          ),
        event: EventComponent,
      },
      defaultDate: date,
      formats: {
        dateFormat: (date, culture, localizer) =>
          localizer.format(date, 'D', culture),
        weekdayFormat: (date, culture, localizer) =>
          localizer.format(date, 'ddd', culture).toUpperCase(),
        dayFormat: (date, culture, localizer) =>
          localizer.format(date, 'D ddd', culture).toUpperCase(),
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, 'ha', culture),
      },
    }),
    [
      props.calendarData,
      timePeriod,
      date,
      startEndDate,
      filterDropdownList,
      selectedFilter,
      openEditFilterDialog,
      isCollapsedView,
    ]
  );

  useEffect(() => {
    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  useEffect(() => {
    !isFilterChange && props.listFilter('CALENDAR_WEB', onError);
  }, []);

  useEffect(() => {
    if (isFilterChange) {
      const storedFilter = localStorage.getItem(filterStore) || {};
      let prevFilter =
        Object.keys(storedFilter).length > 0 ? JSON.parse(storedFilter) : {};
      if (prevFilter?.filterId) {
        localStorage.removeItem(filterStore);
      }
      props.listFilter('CALENDAR_WEB', onError);
      selectedFilter && setFilterData({});
      setFilter(null);
      setSelectedFilter('');
      setIsFilterChange(false);
    }
  }, [isFilterChange]);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el?.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  const handleFilterSelectionClick = (value) => {
    let filterData = filterDropdownList?.filter((el) => el.value === value)[0];
    setFilterData(filterData?.filter || {});
    let filterStoreData = JSON.stringify({
      filter: { ...filterData?.filter },
      filterId: value,
    });
    localStorage.setItem(filterStore, filterStoreData);
  };

  const onSelectEvent = useCallback((calEvent) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setSelectedJobId(calEvent.jobId);
      setShowJobDetail(true);
    }, 250);
  }, []);

  const onDoubleClickEvent = useCallback((calEvent) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setSelectedJobId(calEvent.jobId);
      setSelectedAppointmentId(calEvent.appointmentId);
      setEditJobDialog(true);
    }, 250);
    dispatch(clearJobData());
  }, []);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getTechSearch = (searchStr) => {
    props.fetchUserListByType(
      'array',
      (err) => onError(err),
      searchStr || search,
      ['admin', 'technician', 'superadmin']
    );
  };

  useEffect(() => {
    getTechSearch();
    // props.fetchUserListByType('technician', (err) => onError(err), search);
  }, []);

  const delayedSearch = useCallback(
    debounce((search) => getTechSearch(search), 600),
    []
  );

  // useEffect(() => {
  //   const data = props.UserListByTypeData ? props.UserListByTypeData : {};
  //   if (data?.type === 'array') {
  //     setTechnicianList(data?.data || []);
  //     const data1 = data?.data?.map((item) => {
  //       return item._id;
  //     });
  //     console.log('data1', data1);
  //     setSelectedTechnician(data1);
  //   }
  // }, [props.UserListByTypeData]);

  const onChangeSelectedTechnician = (id, allSelect) => {
    let tempTechnicianList = [];
    if (allSelect && !id) {
      const all = technicianList?.every((item) =>
        selectedTechnician.includes(item._id)
      );
      if (!all) {
        const data = technicianList?.map((item) => {
          return item._id;
        });
        tempTechnicianList = data;
        setSelectedTechnician(data);
      } else {
        setSelectedTechnician([]);
        tempTechnicianList = [];
      }
    } else {
      const existId = selectedTechnician.includes(id);
      if (existId) {
        const data = selectedTechnician.filter((item) => item !== id);
        setSelectedTechnician(data);
        tempTechnicianList = data;
      } else {
        const data = [...selectedTechnician, id];
        setSelectedTechnician(data);
        tempTechnicianList = data;
      }
    }
    let data = {};
    if (timePeriod === 'WEEK') {
      let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
      let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else if (timePeriod === 'MONTH') {
      const startDate = moment(date)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDate = moment(date).clone().endOf('month').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else {
      let startDate = moment(date).format('YYYY-MM-DD');
      let endDate = moment(date).format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    }
    setFilter(null);
    setSelectedFilter('');
    if (tempTechnicianList.length > 0) data.technicians = tempTechnicianList;
    getCalendarData(data);
    localStorage.setItem(
      'technicianSelected',
      JSON.stringify(data.technicians)
    );
  };

  // const onClickClearFilter = () => {
  //   console.log('selectedTechnician', selectedTechnician);
  //   setFilter(null);
  //   setSelectedFilter('0');
  //   let data;
  //   if (selectedTechnician.length > 0) data.technicians = selectedTechnician;
  //   getCalendarData(data);
  // };

  const onRefreshClick = () => {
    let data = {};
    if (timePeriod === 'WEEK') {
      let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
      let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else if (timePeriod === 'MONTH') {
      const startDate = moment(date)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDate = moment(date).clone().endOf('month').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else {
      let startDate = moment(date).format('YYYY-MM-DD');
      let endDate = moment(date).format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    }
    if (selectedTechnician.length > 0) data.technicians = selectedTechnician;
    if (filter) data.filter = filter;
    getCalendarData(data);
  };
  useEffect(() => {
    if (props.updateJobData || props.deleteJobData) {
      onRefreshClick();
      dispatch(setUpdateJob({ data: null }));
      dispatch(setDeleteJob(null));
    }
  }, [props.updateJobData, props.deleteJobData]);

  const setFilterData = (filterData, clearDropdown) => {
    localStorage.setItem(filterStore, JSON.stringify({ filter: filterData }));

    let data = {};
    if (timePeriod === 'WEEK') {
      let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
      let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else if (timePeriod === 'MONTH') {
      const startDate = moment(date)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDate = moment(date).clone().endOf('month').format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    } else {
      let startDate = moment(date).format('YYYY-MM-DD');
      let endDate = moment(date).format('YYYY-MM-DD');
      data.startDate = startDate;
      data.endDate = endDate;
    }
    if (selectedTechnician.length > 0) data.technicians = selectedTechnician;
    if (JSON.stringify(filterData) === '{}' && Object.keys(filter).length > 0) {
      clearDropdown && setFilter(null);
      getCalendarData(data);
      clearDropdown && setSelectedFilter('');
    } else if (JSON.stringify(filterData) !== '{}') {
      setFilter(filterData);
      data.filter = filterData;
      getCalendarData(data);
      clearDropdown && setSelectedFilter('');
    }
  };

  const handleShowMore = (events, date) => {
    setDate(date);
    setShowWorkWeek(false);
    setTimePeriod('DAY');
    let data = {};
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    data.startDate = startDate;
    data.endDate = endDate;
    if (selectedTechnician.length > 0) data.technicians = selectedTechnician;
    getCalendarData(data);
  };

  const onSelectSlot = useCallback((slotInfo) => {
    // console.log('event-', slotInfo);
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      // console.log('event-start', moment(slotInfo?.start).hours());
      // console.log('event-end', moment(slotInfo?.end).hours());
      if (slotInfo?.start) {
        const startDate =
          // moment(slotInfo?.start).hours() === 0
          //   ? moment(slotInfo?.start).hours(7)
          slotInfo?.start;
        const endDate =
          // moment(slotInfo?.end).hours() === 0
          //   ? moment(slotInfo?.end).hours(8)
          slotInfo?.end;
        setSelectedSlotDate({
          start: startDate,
          end: endDate,
          technician: slotInfo?.resourceId,
        });
        setSelectedTech(slotInfo?.resourceId || '');
        setAddJobDialog(true);
      }
    }, 250);
  }, []);

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  const today = new Date();

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      if (
        moment(start).valueOf() !== moment(event.start).valueOf() ||
        moment(end).valueOf() !== moment(event.end).valueOf()
      ) {
        const data = {
          job: event.jobId,
          appointmentId: event.appointmentId,
          startDate: moment(start).format(),
          endDate: moment(end).format(),
          technicianId: event?.resourceId || event?.users[0]?._id,
        };
        props.updateAppointment(
          data,
          () => {
            onRefreshClick();
            // let newEvents = [...props.calendarData.jobs];
            // const tempEvents = [...newEvents];
            // const itemIndex = tempEvents.findIndex(
            //   (item) => item._id === event.jobId
            // );
            // if (itemIndex !== -1) {
            //   tempEvents[itemIndex].startDate = moment(start).utc();
            //   tempEvents[itemIndex].endDate = moment(end).utc();
            //   newEvents = tempEvents;
            //   store.dispatch(
            //     setCalendarData({
            //       completed: props.calendarData?.completed || 0,
            //       inProgress: props.calendarData?.inProgress || 0,
            //       jobs: newEvents,
            //       notStarted: props.calendarData?.notStarted || 0,
            //     })
            //   );
            // }
          },
          (err) => onError(err)
        );
      }
    },
    [props.calendarData]
  );

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      // if (
      //   moment(start).valueOf() !== moment(event.start).valueOf() ||
      //   moment(end).valueOf() !== moment(event.end).valueOf()
      // ) {
      let newEndDate = new Date(new Date(end).getTime() + 1);
      const data = {
        job: event.jobId,
        appointmentId: event.appointmentId,
        startDate: moment(start).format(),
        endDate: moment(newEndDate).format(),
        technicianId: event?.resourceId || event?.users[0]?._id,
      };

      if (resourceId && resourceId !== event?.resourceId) {
        data.newTechnicianId = resourceId;
      }
      props.updateAppointment(
        data,
        () => {
          onRefreshClick();
          // let newEvents = [...props.calendarData.jobs];
          // const tempEvents = [...newEvents];
          // const itemIndex = tempEvents.findIndex(
          //   (item) => item._id === event.jobId
          // );
          // if (itemIndex !== -1) {
          //   tempEvents[itemIndex].startDate = moment(start).utc();
          //   tempEvents[itemIndex].endDate = moment(end).utc();
          //   newEvents = tempEvents;
          //   store.dispatch(
          //     setCalendarData({
          //       completed: props.calendarData?.completed || 0,
          //       inProgress: props.calendarData?.inProgress || 0,
          //       jobs: newEvents,
          //       notStarted: props.calendarData?.notStarted || 0,
          //     })
          //   );
          // }
        },
        (err) => onError(err)
      );
      // }
    },
    [props.calendarData]
  );

  return (
    <div className={classes.calendar_container}>
      <div
        className={
          isCollapsedView
            ? classes.calendar_left_container_full
            : classes.calendar_left_container
        }
      >
        {show24HrTime ? (
          <DnDCalendar
            timeslots={4} // how many slots in day and week view we want
            step={15} // how much min slots we need to show (day and week view)
            startAccessor='start'
            // endAccessor='end'
            endAccessor={({ end }) => new Date(end?.getTime() - 1)}
            localizer={localizer}
            events={props.calendarDataLoading ? [] : events}
            resources={
              view === 'day' &&
              resources.length > 0 &&
              selectedTechnician.length > 0 &&
              resources
            }
            components={components}
            defaultDate={defaultDate}
            defaultView={showWorkWeek ? 'work_week' : view}
            views={['day', 'week', 'month', 'work_week']}
            formats={formats}
            showMultiDayTimes
            dayPropGetter={calendarStyle}
            onView={(view, date) => {
              setTimePeriod(
                view === 'day' ? 'DAY' : view === 'month' ? 'MONTH' : 'WEEK'
              );
              setView(view);
            }}
            onDoubleClickEvent={onDoubleClickEvent}
            onSelectEvent={(slotInfo) => {
              onSelectEvent(slotInfo);
            }}
            style={{ height: 850 }}
            allowMultiple
            onShowMore={(events, date) => handleShowMore(events, date)}
            onSelectSlot={onSelectSlot} // on slot click and drag slot
            selectable
            onNavigate={onNavigate}
            resizable
            onEventResize={resizeEvent}
            onEventDrop={moveEvent}
            dayLayoutAlgorithm='no-overlap'
          />
        ) : (
          <DnDCalendar
            min={
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                7
              )
            }
            max={
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                19
              )
            }
            timeslots={4} // how many slots in day and week view we want
            step={15} // how much min slots we need to show (day and week view)
            startAccessor='start'
            // endAccessor='end'
            endAccessor={({ end }) => new Date(end?.getTime() - 1)}
            localizer={localizer}
            events={props.calendarDataLoading ? [] : events}
            resources={
              view === 'day' &&
              resources.length > 0 &&
              selectedTechnician.length > 0 &&
              resources
            }
            components={components}
            defaultDate={defaultDate}
            defaultView={showWorkWeek ? 'work_week' : view}
            views={['day', 'week', 'month', 'work_week']}
            formats={formats}
            showMultiDayTimes
            dayPropGetter={calendarStyle}
            onView={(view, date) => {
              setTimePeriod(
                view === 'day' ? 'DAY' : view === 'month' ? 'MONTH' : 'WEEK'
              );
              setView(view);
            }}
            onDoubleClickEvent={onDoubleClickEvent}
            onSelectEvent={(slotInfo) => {
              onSelectEvent(slotInfo);
            }}
            // style={{ height: 'fit-content' }}
            allowMultiple
            onShowMore={(events, date) => handleShowMore(events, date)}
            onSelectSlot={onSelectSlot} // on slot click and drag slot
            selectable
            onNavigate={onNavigate}
            resizable
            onEventResize={resizeEvent}
            onEventDrop={moveEvent}
            dayLayoutAlgorithm='no-overlap'
          />
        )}
      </div>
      <TechnicianListView
        technicianList={technicianList || []}
        onChangeSearch={(e) => {
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
        search={search}
        loading={props.UserListByTypeLoading}
        selectedTechnician={selectedTechnician}
        onChangeSelectedTechnician={onChangeSelectedTechnician}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        view={view}
        show24HrTime={show24HrTime}
        onChangeTimeToggle={() => setShow24HrTime(!show24HrTime)}
        showWorkWeek={showWorkWeek}
        onChangeWorkWeekToggle={() => setShowWorkWeek(!showWorkWeek)}
        isCollapsedView={isCollapsedView}
        filterDropdownList={filterDropdownList}
        setSelectedFilter={setSelectedFilter}
        handleFilterSelectionClick={handleFilterSelectionClick}
        setOpenEditFilterDialog={setOpenEditFilterDialog}
        selectedFilter={selectedFilter}
      />
      {showJobDetail && (
        <JobDetails
          onNewClose={() => setShowJobDetail(false)}
          fetchJobId={selectedJobId}
        />
      )}
      {(editJobDialog || addJobDialog) && (
        <NewJob
          isEdit={editJobDialog ? true : false}
          data={
            editJobDialog && selectedJobId && selectedAppointmentId
              ? {
                  _id: selectedJobId,
                  appointmentId: selectedAppointmentId,
                }
              : null
          }
          dateData={addJobDialog && selectedSlotDate}
          selectedTech={selectedTech}
          allTechnician={allTechnician}
          onNewClose={(data) => {
            setSelectedSlotDate('');
            // if (editJobDialog && data) {
            //   let newEvents = [...props.calendarData.jobs];
            //   const tempEvents = [...newEvents];
            //   const itemIndex = tempEvents.findIndex(
            //     (item) => item._id === data._id
            //   );
            //   if (itemIndex !== -1) {
            //     tempEvents[itemIndex].startDate = moment(data.startDate).utc();
            //     tempEvents[itemIndex].endDate = moment(data.endDate).utc();
            //     tempEvents[itemIndex].client = data.client;
            //     tempEvents[itemIndex].jobStatus = data.jobStatus;
            //     // tempEvents[itemIndex].jobType = data.jobType;
            //     tempEvents[itemIndex].title = data.title;
            //     tempEvents[itemIndex].admin = data.admin;
            //     tempEvents[itemIndex].users = data.users;
            //     newEvents = tempEvents;
            //     store.dispatch(
            //       setCalendarData({
            //         completed: props.calendarData?.completed || 0,
            //         inProgress: props.calendarData?.inProgress || 0,
            //         jobs: newEvents,
            //         notStarted: props.calendarData?.notStarted || 0,
            //       })
            //     );
            //   }
            // }
            setEditJobDialog(false);
            setAddJobDialog(false);
            setSelectedTech('');
            setSelectedJobId('');
            setSelectedAppointmentId('');
          }}
        />
      )}
      {showFilterDialog && (
        <CalendarFilter
          setFilterData={setFilterData}
          filterData={filter}
          onClose={() => setShowFilterDialog(false)}
          setIsFilterChange={setIsFilterChange}
        />
      )}
      {openEditFilterDialog?.isShow && (
        <NewFilter
          isEdit={true}
          onClose={() => setOpenEditFilterDialog()}
          selectedId={openEditFilterDialog?.value}
          setIsFilterChange={setIsFilterChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  calendarData: state.calendarData.data,
  calendarDataLoading: state.calendarData.loading,

  addJobData: state.addJobData.data,

  updateJobData: state.updateJobData.data,
  updateJobLoading: state.updateJobData.loading,

  updateRecurrenceData: state.updateRecurrence.data,
  cancelRecurrenceData: state.cancelRecurrence.data,

  listFilterData: state.listFilterData.data,
  listFilterLoading: state.listFilterData.loading,

  deleteJobData: state.deleteJobData.data,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchCalendarData,
  updateJob,
  listFilter,
  updateAppointment,
})(CalendarScreen);
