import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  drawer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    maxHeight: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background,
    transition: '250ms',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  drawer_container: {
    padding: 15,
  },
  active: {
    maxHeight: '100%',
  },
  dialog_mask: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: -1,
    opacity: 0,
  },
  active_mask: {
    zIndex: 5,
    transition: 'opacity 250ms',
    opacity: 1,
  },
  close_icon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  drawer_header: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header_label: {
    color: theme.palette.profile_title,
    fontWeight: 600,
    fontSize: 16,
  },
  drawer_body: {
    width: 'calc(100% - 10px)',
    marginBottom: 25,
    padding: '0px 5px',
  },
}));
