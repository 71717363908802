import { IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    minWidth: 190,
    marginLeft: -8,
    backgroundColor: theme.palette.background,
    color: theme.palette.background_button,
    boxShadow:
      theme.palette.mode === 'dark'
        ? `8px 12px 36px ${Colors.MODAL_SHADOW_WHITE}`
        : '8px 12px 36px rgba(35, 52, 90, 0.25)',
    '& .MuiMenu-list': {
      padding: '5px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

const CommonPopover = (props) => {
  const theme = useTheme();
  const {
    icon,
    btnImg,
    children,
    anchorOrigin,
    transformOrigin,
    options,
    onSelectOption,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      {children ? (
        <>{React.cloneElement(children, { onClick: handleClick })}</>
      ) : null}
      <IconButton
        disableRipple
        onClick={handleClick}
        sx={{
          p: 0,
          width: 'fit-content',
          borderRadius: '50%',
          border: `1px solid ${theme.palette.background_border}`,
        }}
      >
        {btnImg ? (
          <img src={btnImg} alt='click me' height={24} width={24} />
        ) : (
          icon ?? (
            <img
              src={icons(theme).WhiteMenuIcon}
              alt='click me'
              height={24}
              width={24}
            />
          )
        )}
      </IconButton>
      <StyledMenu
        elevation={0}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'center',
          }
        }
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options?.map((item, index) => (
          <MenuItem
            key={`Menu-${index}`}
            value={item?.value}
            disableRipple
            onClick={(e) => {
              onSelectOption(item?.value);
              handleClose(e);
            }}
          >
            {item?.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

CommonPopover.propTypes = {
  icons: PropTypes.node,
  btnImg: PropTypes.string,
  children: PropTypes.node,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  onSelectOption: PropTypes.func,
};

export default CommonPopover;
