import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import useStyles from './styles';
import {
  EditorFormats,
  EditorModules,
  manageNotesHref,
  quillData,
} from '../../utils/appUtils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function ReactQuillTextbox(props) {
  const classes = useStyles();
  const {
    label,
    onChange,
    value,
    placeholder,
    error,
    helperText,
    containerClassName,
    className,
    viewMode,
    id,
  } = props;

  useEffect(() => {
    if (value) manageNotesHref(id ?? 'quillTextBox');
  }, [value]);

  return (
    <Stack
      spacing={0.9}
      className={classNames(classes.container, {
        [containerClassName]: containerClassName,
      })}
    >
      <Typography className={classes.msgLabel}> {label} </Typography>
      <ReactQuill
        id={id ?? 'quillTextBox'}
        theme={quillData.theme}
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? 'Type here'}
        error={error}
        helperText={helperText}
        modules={!viewMode ? EditorModules : { toolbar: false }}
        formats={EditorFormats}
        className={classNames(classes.reactQuillStyle, {
          [classes.reactQuillWithoutBorder]: viewMode,
          [className]: className,
        })}
        readOnly={viewMode}
      />
    </Stack>
  );
}

ReactQuillTextbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default ReactQuillTextbox;
