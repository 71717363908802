import React, { useEffect, useRef } from 'react';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../components/Textbox';
import Dropdown from '../../../components/Dropdown';
import { useTheme } from '@mui/material/styles';
import { addUserArray, getErrorMessage } from '../../../utils/appUtils';
import CountryCodePicker from '../../../components/CountryCodePicker';
import classNames from 'classnames';
import Button from '../../../components/Button';
import { AddUserSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createUser } from '../../../redux/actions/adminActions/addUser';
import { updateUser } from '../../../redux/actions/adminActions/updateUser';
import NewFormHeader from '../../../components/NewFormHeader';
import { icons } from '../../../utils/icons';
import { useAlert } from 'react-alert';
import { HexColorPicker } from 'react-colorful';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ImageCropper from '../../../components/ImageCropper';

const NewUser = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const inputFile = useRef(null);
  const alert = useAlert();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCropper, setShowCropper] = React.useState(false);
  const [cropData, setCropData] = React.useState('');

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.isEdit) {
      const {
        // name,
        firstName,
        lastName,
        email,
        phoneNumber,
        profilePicture,
        role,
        countryCode,
        color,
        jobTitle,
        bioLink,
      } = props.data;
      // setFieldValue('fullName', name);
      setFieldValue('firstName', firstName);
      setFieldValue('lastName', lastName);
      setFieldValue('emailAddress', email);
      setFieldValue('phoneNumber', phoneNumber);
      setFieldValue('image', profilePicture);
      setFieldValue('role', role);
      setFieldValue('color', color || '');
      setFieldValue('jobTitle', jobTitle || '');
      setFieldValue('countryCode', countryCode.substring(1));
      setFieldValue('isEdit', true);
      setFieldValue('bioLink', bioLink || '');
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      // fullName: '',
      firstName: '',
      lastName: '',
      role: '0',
      emailAddress: '',
      phoneNumber: '',
      countryCode: '1',
      image: '',
      file: null,
      // sendPassword: false,
      jobTitle: '',
      bioLink: '',
      password: '',
      isEdit: false,
      color: '',
    },
    validationSchema: AddUserSchema,

    onSubmit: (values) => {
      addNewUser(values);
    },
  });
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };
  const addNewUser = (values) => {
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      phoneNumber: values.phoneNumber,
      countryCode: '+' + values.countryCode,
      color: values.color,
      role: values.role,
    };
    data.bioLink = values?.bioLink || '';
    if (values.password) data.password = values.password;
    if (values?.jobTitle) data.jobTitle = values.jobTitle;
    if (values.file) {
      data.profilePicture = values.file;
    }
    if (props.isEdit) {
      props.updateUser(
        props.data._id,
        data,
        (err) => onError(err),
        props.onNewClose
      );
    } else {
      // data.role = values.role;
      // data.sendPassword = values.sendPassword;
      props.createUser(data, (err) => onError(err), props.onNewClose);
    }
  };
  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const handleFileUpload = (files) => {
    const image = URL.createObjectURL(files.target.files[0]);
    setFieldValue('image', image);
    setFieldValue('file', files.target.files[0]);
    setShowCropper(true);
  };

  const validateImageAfterCropped = async () => {
    setFieldValue('image', cropData);
    fetch(cropData)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], values.file.name, {
          type: values.file.type,
        });
        setFieldValue('file', file);
      });
  };

  useEffect(() => {
    if (cropData) {
      validateImageAfterCropped();
    }
  }, [cropData]);

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props.isEdit ? Strings.EDIT_USER : Strings.NEW_USER}
          onNewClose={props.onNewClose}
        />
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.profile_upload_container}>
              <div className={classes.profile_border}>
                <input
                  style={{ display: 'none' }}
                  ref={inputFile}
                  onChange={(event) => handleFileUpload(event)}
                  type='file'
                  accept='image/*'
                />
                {values.image && (
                  <img
                    className={classes.profile_image}
                    src={values.image}
                    alt=''
                  />
                )}

                <div
                  className={classes.upload_icon_container}
                  onClick={() => inputFile.current.click()}
                >
                  <img
                    className={classes.upload_icon}
                    src={icons(theme).cameraIcon}
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className={classes.form_details}>
              <Textbox
                name='firstName'
                onChange={handleChange('firstName')}
                value={values.firstName}
                label={Strings.FIRST_NAME}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                placeholder={Strings.ENTER_FIRST_NAME}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
              />
              <Textbox
                name='lastName'
                onChange={handleChange('lastName')}
                value={values.lastName}
                label={Strings.LAST_NAME}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                placeholder={Strings.ENTER_LAST_NAME}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
              />
              <Dropdown
                name='role'
                onChange={handleChange('role')}
                value={values.role}
                label={Strings.ROLE}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
                title={Strings.SELECT_AN_OPTION}
                labelClassName={classes.form_label}
                containerClassName={classes.form_input_container}
                optionArray={addUserArray()}
                // disabled={props.isEdit}
              />
              <Textbox
                type='email'
                name='emailAddress'
                onChange={handleChange('emailAddress')}
                value={values.emailAddress}
                label={Strings.EMAIL_ADDRESS}
                error={Boolean(touched.emailAddress && errors.emailAddress)}
                helperText={touched.emailAddress && errors.emailAddress}
                placeholder={`${Strings.ENTER_EMAIL} address`}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
              />
              <div className={classes.phoneno_container}>
                <CountryCodePicker
                  className={classes.form_input}
                  containerClassName={classNames(
                    classes.form_input_container,
                    classes.countryCodeContainer
                  )}
                  value={values.countryCode}
                  onChange={handleChange('countryCode')}
                  label={Strings.PHONE_NUMBER}
                />
                <Textbox
                  name='phoneNumber'
                  onChange={handleChange('phoneNumber')}
                  value={values.phoneNumber}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  placeholder={Strings.ENTER_PHONE_NUMBER}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classNames(
                    classes.form_input_container,
                    classes.phoneno_margin
                  )}
                />
              </div>

              <Textbox
                type='text'
                name='color'
                onClick={handleClick}
                value={values.color}
                label={'Color'}
                error={Boolean(touched.color && errors.color)}
                helperText={touched.color && errors.color}
                placeholder={`Select Color`}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
                readOnly={true}
              />

              {anchorEl !== null && (
                <Menu
                  anchorEl={anchorEl}
                  id='account-menu'
                  open={open}
                  onClose={handleClose}
                  // onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {anchorEl !== null && (
                    <MenuItem>
                      <div style={{ marginTop: 10, display: 'flex' }}>
                        <HexColorPicker
                          color={values.color || '#aabbcc'}
                          onChange={(value) => {
                            setFieldValue('color', value);
                          }}
                        />
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              )}

              <Textbox
                name='jobTitle'
                onChange={handleChange('jobTitle')}
                value={values.jobTitle}
                label={Strings.JOB_TITLE}
                error={Boolean(touched.jobTitle && errors.jobTitle)}
                helperText={touched.jobTitle && errors.jobTitle}
                placeholder={Strings.TYPE_HERE}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
              />

              <Textbox
                name='bioLink'
                onChange={handleChange('bioLink')}
                value={values.bioLink}
                label={Strings.BIO_LINK}
                error={Boolean(touched.bioLink && errors.bioLink)}
                helperText={touched.bioLink && errors.bioLink}
                placeholder={Strings.TYPE_HERE}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
              />

              <Textbox
                name='password'
                onChange={handleChange('password')}
                value={values.password}
                label={Strings.PASSWORD}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                placeholder={Strings.ENTER_PASSWORD}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
                errorClassName={classes.errorClassName}
              />
              {/* {!props.isEdit && (
                <FormSwitch
                  label={Strings.SEND_PASSWORD}
                  value={values.sendPassword}
                  containerClassName={classes.form_input_container}
                  onChange={() =>
                    setFieldValue('sendPassword', !values.sendPassword)
                  }
                />
              )} */}
              <Button
                type='submit'
                text={props.isEdit ? Strings.UPDATE_USER : Strings.ADD_NEW_USER}
                loading={
                  props.isEdit ? props.updateUserLoading : props.addUserLoading
                }
                disabled={
                  props.isEdit ? props.updateUserLoading : props.addUserLoading
                }
                containerClassName={classes.add_button_container}
                className={classes.add_button}
                loaderColor={theme.palette.background_button_text}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
      {showCropper && (
        <ImageCropper
          url={values.image}
          onClose={() => {
            setShowCropper(false);
          }}
          setCropData={setCropData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addUserData: state.addUserData.data,
  addUserLoading: state.addUserData.loading,

  updateUserLoading: state.updateUserData.loading,
});

export default connect(mapStateToProps, {
  createUser,
  updateUser,
})(NewUser);
