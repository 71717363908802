import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import setAuthToken from './utils/setAuthToken';
import config from './config';
import store from './redux/store';
import { errorLogin, setLogin } from './redux/actions/authActions/login';
import { ModeProvider } from './context/ThemeContext';
import moment from 'moment-timezone';

const { dispatch } = store;
axios.defaults.baseURL = config.BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Timezone'] = moment.tz.guess();
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      dispatch(errorLogin({}));
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userName');
      localStorage.removeItem('profilePicture');
      localStorage.removeItem('CalendarsFilter');
      localStorage.removeItem('ClientsFilter');
      localStorage.removeItem('IssuesFilter');
      localStorage.removeItem('JobsFilter');
      setAuthToken(false);
      dispatch(setLogin({}));
      window.location.replace('/login');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModeProvider>
        <App />
      </ModeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
