import axios from 'axios';
import { UPDATE_JOB_SERIES } from '../../../constants';

export const updateJobSeries = (data, onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateJobSeries());
  axios
    .put(`/jobs/updateJobs`, data, config)
    .then((res) => {
      dispatch(setUpdateJobSeries(res));
      callback?.();
    })
    .catch((err) => {
      dispatch(errorUpdateJobSeries(err.response));
      onError(err.response);
    });
};

export const loadUpdateJobSeries = () => ({
  type: UPDATE_JOB_SERIES.LOAD,
});

export const setUpdateJobSeries = (data) => ({
  type: UPDATE_JOB_SERIES.SUCCESS,
  payload: data,
});

export const errorUpdateJobSeries = (error) => ({
  type: UPDATE_JOB_SERIES.FAIL,
  payload: error,
});
