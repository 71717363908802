import axios from 'axios';
import { DELETE_JOB } from '../../../constants';

export const deleteJob = (jID, closeDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteJob());
  axios
    .put(`/jobs/deleteJob?jobId=${jID}`, {}, config)
    .then((res) => {
      dispatch(setDeleteJob(res));
      closeDialog();
    })
    .catch((err) => {
      dispatch(errorDeleteJob(err.response));
      onError(err.response);
    });
};

export const loadDeleteJob = () => ({
  type: DELETE_JOB.LOAD,
});

export const setDeleteJob = (data) => ({
  type: DELETE_JOB.SUCCESS,
  payload: data,
});

export const errorDeleteJob = (error) => ({
  type: DELETE_JOB.FAIL,
  payload: error,
});
