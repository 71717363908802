import axios from 'axios';
import { SEND_USER_PASSWORD } from '../../constants';

export const sendUserPassword = (uID, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadSendUserPassword());
  axios
    .post(`/admin/sendUserPassword?userId=${uID}`, {}, config)
    .then((res) => {
      dispatch(setSendUserPassword(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorSendUserPassword(err.response));
      onError(err.response);
    });
};

export const loadSendUserPassword = () => ({
  type: SEND_USER_PASSWORD.LOAD,
});

export const setSendUserPassword = (data) => ({
  type: SEND_USER_PASSWORD.SUCCESS,
  payload: data.data,
});

export const errorSendUserPassword = (error) => ({
  type: SEND_USER_PASSWORD.FAIL,
  payload: error,
});
