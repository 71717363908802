import axios from 'axios';
import { SEND_FEEDBACK } from '../../constants';

export const sendFeedback = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadSendFeedback());
  axios
    .post(`/feedback/send`, data, config)
    .then((res) => {
      onSuccess();
      dispatch(setSendFeedback(res));
    })
    .catch((err) => {
      dispatch(errorSendFeedback(err.response));
      onError(err.response);
    });
};

export const loadSendFeedback = () => ({
  type: SEND_FEEDBACK.LOAD,
});

export const setSendFeedback = (data) => ({
  type: SEND_FEEDBACK.SUCCESS,
  payload: data.data,
});

export const errorSendFeedback = (error) => ({
  type: SEND_FEEDBACK.FAIL,
  payload: error,
});
