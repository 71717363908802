import { combineReducers } from 'redux';
import login from './authReducer/login';
import addUser from './adminReducer/addUser';
import addVendor from './adminReducer/addVendor';
import updateVendor from './adminReducer/updateVendor';
import deleteVendor from './adminReducer/deleteVendor';
import listTeamUser from './adminReducer/teamUserList';
import listVendor from './adminReducer/vendorList';
import listClient from './clientReducer/clientList';
import getUserByType from './adminReducer/getUserListByType';
import addProgram from './clientReducer/addProgramType';
import listProgram from './clientReducer/programTypeList';
import addClient from './clientReducer/addClient';
import deleteClient from './clientReducer/deleteClient';
import updateClient from './clientReducer/updateClient';
import addProperty from './clientReducer/addProperty';
import getProperty from './clientReducer/getPropertyById';
import getClient from './clientReducer/getClientById';
import getJobForm from './jobsReducer/jobReducer/getJobFormById';
import listProperties from './clientReducer/propertiesList';
import deleteProperty from './clientReducer/deleteProperty';
import updateProperty from './clientReducer/updateProperty';
import uploadFile from './adminReducer/uploadFile';
import deleteFile from './adminReducer/deleteFile';
import addAnnouncement from './adminReducer/addAnnouncement';
import announcementList from './adminReducer/announcementList';
import addNotes from './clientReducer/addNotes';
import listNotes from './clientReducer/notesList';
import jobFormsList from './jobsReducer/jobReducer/jobFormsList';
import listCategory from './jobsReducer/categoryReducer/categoryTypeList';
import addCategory from './jobsReducer/categoryReducer/addCategoryType';
import addJobForm from './jobsReducer/jobReducer/addJobForm';
import listJob from './jobsReducer/jobReducer/jobList';
import addJob from './jobsReducer/jobReducer/addJob';
import dropdownListJob from './jobsReducer/jobReducer/dropdownListJob';
import addIssue from './jobsReducer/issuesReducer/addIssue';
import issueList from './jobsReducer/issuesReducer/issueList';
import deleteJob from './jobsReducer/jobReducer/deleteJob';
import getJob from './jobsReducer/jobReducer/getJobById';
import updateJob from './jobsReducer/jobReducer/updateJob';
import getIssueById from './jobsReducer/issuesReducer/getIssueById';
import deleteIssue from './jobsReducer/issuesReducer/deleteIssue';
import cancelRecurrence from './jobsReducer/jobReducer/cancelRecurrence';
import updateRecurrence from './jobsReducer/jobReducer/updateRecurrence';
import updateIssue from './jobsReducer/issuesReducer/updateIssue';
import getIssuesByProperty from './clientReducer/getIssuesByProperty';
import getJobsByProperty from './clientReducer/getJobsByProperty';
import getClientAllProperty from './clientReducer/getClientAllProperty';
import updateIssueByPerformAction from './jobsReducer/issuesReducer/updateIssueByPerformAction';
import addIssueNote from './clientReducer/addIssueNote';
import addJuncture from './clientReducer/addJuncture';
import listJunctures from './clientReducer/juncturesList';
import juncturesListByProperty from './clientReducer/juncturesListByProperty';
import sendReport from './jobsReducer/reportReducer/sendReport';
import downloadReport from './jobsReducer/reportReducer/downloadReport';
import uploadFileForReport from './jobsReducer/reportReducer/uploadFileForReport';
import listSuppliesNeeded from './adminReducer/suppliesNeeded';
import downloadSupplies from './adminReducer/downloadSupplies';
import teamDiscussion from './adminReducer/teamDiscussion';
import getReport from './jobsReducer/reportReducer/getReport';
import updateReport from './jobsReducer/reportReducer/updateReport';
import listDashboardIssue from './adminReducer/dashboardIssueList';
import updateJuncture from './clientReducer/updateJuncture';
import getClientsAvailability from './adminReducer/getClientsAvailability';
import latestReportList from './clientReducer/latestReportList';
import dashboardGetAdmin from './clientReducer/dashboardGetAdmin';
import dashboardCount from './commonReducer/dashboardCount';
import superAdminDashboardIssueList from './jobsReducer/issuesReducer/superAdminDashboardIssueList';
import adminJuncturesList from './adminReducer/adminJuncturesList';
import listMedia from './mediaReducer/mediaList';
import getMediaByProperty from './clientReducer/getMediaByProperty';
import roomList from './chatReducer/roomList';
import createRoom from './chatReducer/createRoom';
import getRoomData from './chatReducer/getRoomData';
import createMessage from './chatReducer/createMessage';
import clientFeedbackList from './adminReducer/clientFeedbackList';
import calendarData from './calendarReducer/calendarData';
import exportJunctures from './commonReducer/exportJunctures';
import exportIssues from './commonReducer/exportIssues';
import sendUserPassword from './adminReducer/sendUserPassword';
import forgotPassword from './authReducer/forgotPassword';
import sendFeedback from './clientReducer/sendFeedback';
import addJobNote from './jobsReducer/jobReducer/addJobNote';
import calendarJunctures from './adminReducer/calendarJunctures';
import deleteUser from './adminReducer/deleteUser';
import updateUser from './adminReducer/updateUser';
import deleteCategoryType from './jobsReducer/categoryReducer/deleteCategoryType';
import updateCategoryType from './jobsReducer/categoryReducer/updateCategoryType';
import updateJobForm from './jobsReducer/jobReducer/updateJobForm';
import unreadNotificationCount from './commonReducer/unreadNotificationCount';
import notificationList from './commonReducer/notificationList';
import confirmIssue from './jobsReducer/reportReducer/confirmIssue';
import markAsCompleteJob from './jobsReducer/reportReducer/markAsCompleteJob';
import vendorDropDownList from './adminReducer/vendorDropDownList';
import updateProgramType from './clientReducer/updateProgramType';
import deleteProgramType from './clientReducer/deleteProgramType';
import visibleIssue from './jobsReducer/issuesReducer/visibleIssue';
import tagsList from './mediaReducer/tagsList';
import createTag from './mediaReducer/createTag';
import deleteTag from './mediaReducer/deleteTag';
import updateJobNote from './jobsReducer/jobReducer/updateJobNote';
import deleteJobNote from './jobsReducer/jobReducer/deleteJobNote';
import updateIssueNote from './jobsReducer/issuesReducer/updateIssueNote';
import deleteIssueNote from './jobsReducer/issuesReducer/deleteIssueNote';
import clearNotifications from './commonReducer/clearNotifications';
import clientListName from './commonReducer/clientListName';
import clientJobs from './commonReducer/clientJobs';
import sendUpdate from './jobsReducer/issuesReducer/sendUpdate';
import uploadPropertyMedia from './clientReducer/uploadPropertyMedia';
import getMediaById from './mediaReducer/getMediaById';
import updateFileDetails from './mediaReducer/updateFileDetails';
import mediaListFolder from './mediaReducer/mediaListFolder';
import getMediaFolderContent from './mediaReducer/getMediaFolderContent';
import updateFolderName from './mediaReducer/updateFolderName';
import addFilter from './filterReducer/addFilter';
import listFilter from './filterReducer/listFilter';
import getFilterById from './filterReducer/getFilterById';
import updateFilter from './filterReducer/updateFilter';
import deleteFilter from './filterReducer/deleteFilter';
import createFolder from './mediaReducer/createFolder';
import uploadFolderMedia from './mediaReducer/uploadFolderMedia';
import deleteJuncture from './clientReducer/deleteJuncture';
import markAllJobNotesRead from './jobsReducer/jobReducer/markAllJobNotesRead';
import markAllIssuesNotesRead from './jobsReducer/issuesReducer/markAllIssuesNotesRead';
import updateAppointment from './jobsReducer/jobReducer/updateAppointment';
import dropdownIssueList from './jobsReducer/issuesReducer/dropdownIssueList';
import deleteNotification from './commonReducer/deleteNotification';
import updateEBlast from './adminReducer/updateEBlast';
import getEBlast from './adminReducer/getEBlast';
import copyMedia from './mediaReducer/copyMedia';
import getForecastWeather from './clientReducer/getForecastWeather';
import getCurrentWeather from './clientReducer/getCurrentWeather';
import searchMedia from './mediaReducer/searchMedia';
import dropdownPropertyList from './commonReducer/dropdownPropertyList';
import addMyNote from './adminReducer/addMyNote';
import jobSeriesList from './jobsReducer/jobReducer/jobSeriesList';
import deleteJobSeries from './jobsReducer/jobReducer/deleteJobSeries';
import deleteJobForm from './jobsReducer/jobReducer/deleteJobForm';
import updateReportAction from './jobsReducer/reportReducer/updateReportAction';
import clearClientFeedback from './adminReducer/clearClientFeedback';
import clearAllClientFeedback from './adminReducer/clearAllClientFeedback';
import deleteAnnouncement from './adminReducer/deleteAnnouncement';
import deletePropertyMedia from './clientReducer/deletePropertyMedia';
import deleteFolder from './mediaReducer/deleteFolder';
import deleteMedia from './mediaReducer/deleteMedia';
import updateJobSeries from './jobsReducer/jobReducer/updateJobSeries';
import archiveUnarchiveClient from './clientReducer/archiveUnarchiveClient';

export default combineReducers({
  loginData: login,
  forgotPasswordData: forgotPassword,
  //team
  addUserData: addUser,
  updateUserData: updateUser,
  teamUserListData: listTeamUser,

  //vendor
  addVendorData: addVendor,
  updateVendorData: updateVendor,
  deleteVendorData: deleteVendor,
  UserListByTypeData: getUserByType,
  vendorListData: listVendor,
  deleteCategoryData: deleteCategoryType,
  updateCategoryData: updateCategoryType,
  vendorDropDownListData: vendorDropDownList,

  //client
  addProgramData: addProgram,
  programListData: listProgram,
  updateProgramTypeData: updateProgramType,
  deleteProgramTypeData: deleteProgramType,
  getForecastWeatherData: getForecastWeather,
  getCurrentWeatherData: getCurrentWeather,

  addClientData: addClient,
  archiveUnarchiveClientData: archiveUnarchiveClient,
  clientListData: listClient,
  deleteClientData: deleteClient,
  updateClientData: updateClient,
  addPropertyData: addProperty,
  propertyData: getProperty,
  propertiesListData: listProperties,
  deletePropertyData: deleteProperty,

  uploadFileData: uploadFile,
  deleteFileData: deleteFile,
  updatePropertyData: updateProperty,
  addNotesData: addNotes,
  notesListData: listNotes,
  propertyIssues: getIssuesByProperty,
  propertyJobs: getJobsByProperty,
  propertyMedia: getMediaByProperty,
  addIssueNoteData: addIssueNote,
  sendFeedbackData: sendFeedback,
  sendFeedbackData: sendFeedback,
  deletePropertyMediaData: deletePropertyMedia,

  clientAllPropertyData: getClientAllProperty,
  juncturesListByProperty: juncturesListByProperty,
  clientListNameData: clientListName,
  clientJobsData: clientJobs,
  uploadPropertyMediaData: uploadPropertyMedia,
  dropdownPropertyListData: dropdownPropertyList,

  //announcement
  addAnnouncementData: addAnnouncement,
  announcementListData: announcementList,
  jobFormsListData: jobFormsList,
  deleteAnnouncementData: deleteAnnouncement,

  //job
  categoryListData: listCategory,
  addCategoryData: addCategory,
  addJobFormData: addJobForm,
  jobListData: listJob,
  addJobData: addJob,
  dropdownListJobData: dropdownListJob,
  clientData: getClient,
  jobFormData: getJobForm,
  deleteJobData: deleteJob,
  jobData: getJob,
  updateJobData: updateJob,
  updateJobFormData: updateJobForm,
  cancelRecurrence: cancelRecurrence,
  updateRecurrence: updateRecurrence,
  addJobNoteData: addJobNote,
  updateJobNoteData: updateJobNote,
  deleteJobNoteData: deleteJobNote,
  markAllJobsNotesReadData: markAllJobNotesRead,
  updateAppointmentData: updateAppointment,
  jobSeriesListData: jobSeriesList,
  deleteJobSeriesData: deleteJobSeries,
  deleteJobFormData: deleteJobForm,
  updateJobSeriesData: updateJobSeries,

  // report
  sendReportData: sendReport,
  confirmIssueData: confirmIssue,
  getReportData: getReport,
  updateReportData: updateReport,
  uploadFileForReportData: uploadFileForReport,
  downloadReportData: downloadReport,
  markAsCompleteJobData: markAsCompleteJob,
  updateReportActionData: updateReportAction,

  // issue
  addIssueData: addIssue,
  issueListData: issueList,
  issueData: getIssueById,
  deleteIssueData: deleteIssue,
  updateIssueData: updateIssue,
  updateIssueByActionData: updateIssueByPerformAction,
  visibleIssueData: visibleIssue,
  updateIssueNoteData: updateIssueNote,
  deleteIssueNoteData: deleteIssueNote,
  sendUpdateData: sendUpdate,
  markAllIssuesNotesReadData: markAllIssuesNotesRead,
  exportIssuesData: exportIssues,
  dropdownIssueListData: dropdownIssueList,

  // dashboard
  addJunctureData: addJuncture,
  deleteJunctureData: deleteJuncture,
  juncturesListData: listJunctures,
  suppliesNeededData: listSuppliesNeeded,
  teamDiscussionData: teamDiscussion,
  dashboardIssueListData: listDashboardIssue,
  updateJunctureData: updateJuncture,
  dashboardCountData: dashboardCount,
  notificationCountData: unreadNotificationCount,
  notificationListData: notificationList,
  superAdminDashboardIssueListData: superAdminDashboardIssueList,
  adminJuncturesListData: adminJuncturesList,
  clearNotificationsData: clearNotifications,
  deleteNotificationData: deleteNotification,
  downloadSuppliesData: downloadSupplies,

  clientsAvailabilityListData: getClientsAvailability,
  latestReportData: latestReportList,
  clientAdminData: dashboardGetAdmin,
  clientFeedbackListData: clientFeedbackList,

  exportJuncturesData: exportJunctures,

  calendarJuncturesData: calendarJunctures,

  deleteUserData: deleteUser,

  updateEBlastData: updateEBlast,
  getEBlastData: getEBlast,
  addMyNoteData: addMyNote,
  clearAllClientFeedbackData: clearAllClientFeedback,
  clearClientFeedbackData: clearClientFeedback,

  // media
  mediaListData: listMedia,
  tagsListData: tagsList,
  createTagData: createTag,
  deleteTagData: deleteTag,
  mediaData: getMediaById,
  updateFileDetailsData: updateFileDetails,
  mediaListFolderData: mediaListFolder,
  mediaFolderContentData: getMediaFolderContent,
  updateFolderNameData: updateFolderName,
  createFolderData: createFolder,
  uploadFolderMediaData: uploadFolderMedia,
  copyMediaData: copyMedia,
  searchMediaData: searchMedia,
  deleteFolderData: deleteFolder,
  deleteMediaData: deleteMedia,

  // chat
  roomListData: roomList,
  createRoomData: createRoom,
  getRoomData: getRoomData,
  createMessageData: createMessage,

  //calendar
  calendarData: calendarData,

  //userPassword
  sendUserPasswordData: sendUserPassword,

  // filter
  addFilterData: addFilter,
  listFilterData: listFilter,
  getFilterData: getFilterById,
  updateFilterData: updateFilter,
  deleteFilterData: deleteFilter,
});
