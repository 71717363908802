import axios from 'axios';
import { UPLOAD_FILE } from '../../constants';

export const uploadFile = (data, onFileUpload, onError) => (dispatch) => {
  const bodyFormData = new FormData();
  for (let i = 0; i < data.files.length; i++) {
    bodyFormData.append('files', data.files[i]);
  }
  const token = localStorage.jwtToken;
  dispatch(addUploadFile());
  axios({
    method: 'post',
    url: `/admin/uploadFile`,
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if(onFileUpload !== ''){
        onFileUpload(res.data.data);
      }
      dispatch(setUploadFile(res));
    })
    .catch((err) => {
      dispatch(errorUploadFile(err.response));
      onError(err.response);
    });
};

export const addUploadFile = () => ({
  type: UPLOAD_FILE.LOAD,
});

export const setUploadFile = (data) => ({
  type: UPLOAD_FILE.SUCCESS,
  payload: { file: data.data.data },
});

export const errorUploadFile = (error) => ({
  type: UPLOAD_FILE.FAIL,
  payload: error,
});
