import { GET_ROOMS_LIST } from '../../constants';

export const updateRoomsList = (data) => (dispatch) => {
  dispatch(setRoomsList(data));
};

export const loadRoomsList = () => ({
  type: GET_ROOMS_LIST.LOAD,
});

export const setRoomsList = (countData) => ({
  type: GET_ROOMS_LIST.SUCCESS,
  payload: countData,
});

export const errorRoomsList = (error) => ({
  type: GET_ROOMS_LIST.FAIL,
  payload: error,
});
