import React, { useEffect, useRef, useState } from 'react';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../components/Textbox';
import Dropdown from '../../../components/Dropdown';
import { useTheme } from '@mui/material/styles';
import { getErrorMessage, vendorCategoryArray } from '../../../utils/appUtils';
import CountryCodePicker from '../../../components/CountryCodePicker';
import classNames from 'classnames';
import Button from '../../../components/Button';
import { AddVendorSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createVendor } from '../../../redux/actions/adminActions/addVendor';
// import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import { CircularProgress } from '@material-ui/core';
import NewFormHeader from '../../../components/NewFormHeader';
import { useAlert } from 'react-alert';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import useGoogleMapsApi from '../../../hooks/useGoogleMapsApi';
import { updateVendor } from '../../../redux/actions/adminActions/updateVendor';
import FormSwitch from '../../../components/Switch';

const NewVendor = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  // const [technicianList, setTechnicianList] = useState([]);
  const [vendorCategoryList, setVendorCategoryList] = useState([]);
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();

  useEffect(() => {
    if (props.isEdit) {
      const {
        fullName,
        email,
        phoneNo,
        company,
        notes,
        category,
        address,
        preferredTechnician,
        techPhone,
        countryCode,
        techCountryCode,
        preferredVendorProgram,
        websiteLink,
      } = props.data;
      const phoneCountryCode = countryCode ? countryCode.substring(1) : '1';
      const techPhoneCountryCode = techCountryCode
        ? techCountryCode.substring(1)
        : '1';
      setFieldValue('fullName', fullName || '');
      setFieldValue('company', company || '');
      setFieldValue(
        'category',
        vendorCategoryList.find((item) => item.title === category.categoryName)
          ?.value || ''
      );
      setFieldValue('emailAddress', email || '');
      setFieldValue('phoneNumber', phoneNo || '');
      setFieldValue('techPhone', techPhone || '');
      setFieldValue('preferredTechnician', preferredTechnician || '');
      setFieldValue('notes', notes || '');
      setFieldValue('address', address || '');
      setFieldValue('phoneCountryCode', phoneCountryCode);
      setFieldValue('techPhoneCountryCode', techPhoneCountryCode);
      setFieldValue('preferredVendorProgram', preferredVendorProgram);
      setFieldValue('websiteLink', websiteLink);
    }
  }, [vendorCategoryList]);

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(
        inputRef.current
        // { types: ['(address)'] }
      );
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        setFieldValue('address', place.formatted_address);
        // Do something with the resolved place here (ie store in redux state)
      });
    }
  }, [googleMapsApi, props.categoryListData]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchCategoryList('vendor', (err) => onError(err));
    // props.fetchUserListByType('technician', (err) => onError(err));
  }, []);

  // useEffect(() => {
  //   const data = props.UserListByTypeData ? props.UserListByTypeData : {};
  //   const newData =
  //     data?.data?.map((item) => ({ title: item.name, value: item._id })) || [];
  //   setTechnicianList(newData);
  // }, [props.UserListByTypeData]);

  useEffect(() => {
    const data = props.categoryListData ? props.categoryListData : [];
    const newData = data.map((item) => ({
      title: item.categoryName,
      value: item._id,
    }));
    setVendorCategoryList(newData);
  }, [props.categoryListData]);

  const formik = useFormik({
    initialValues: {
      company: '',
      category: '',
      fullName: '',
      emailAddress: '',
      phoneNumber: '',
      techPhone: '',
      preferredTechnician: '',
      notes: '',
      phoneCountryCode: '1',
      techPhoneCountryCode: '1',
      address: '',
      websiteLink: '',
      preferredVendorProgram: false,
    },
    validationSchema: AddVendorSchema,

    onSubmit: (values) => {
      addNewUser(values);
    },
  });
  const addNewUser = (values) => {
    const data = {
      company: values.company,
      category: values.category,
      fullName: values.fullName,
      // email: values.emailAddress,
      countryCode: '+' + values.phoneCountryCode,
      phoneNo: values.phoneNumber,
      preferredTechnician: values.preferredTechnician,
      notes: values.notes,
      address: values.address,
      preferredVendorProgram: values.preferredVendorProgram,
    };
    if (values.emailAddress) {
      data.email = values.emailAddress;
    }
    if (values.websiteLink) {
      data.websiteLink = values.websiteLink;
    }
    if (values.techPhone && values.techPhoneCountryCode) {
      data.techPhone = values.techPhone;
      data.techCountryCode = '+' + values.techPhoneCountryCode;
    }
    if (props.isEdit) {
      props.updateVendor(
        props.data._id,
        data,
        (err) => onError(err),
        props.onNewClose
      );
    } else {
      props.createVendor(data, (err) => onError(err), props.onNewClose);
    }
  };
  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props.isEdit ? Strings.EDIT_VENDOR : Strings.NEW_VENDOR}
          onNewClose={props.onNewClose}
        />
        {props.categoryListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='company'
                  onChange={handleChange('company')}
                  value={values.company}
                  label={Strings.COMPANY}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                  placeholder={Strings.ENTER_COMPANY_NAME}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                />
                <Dropdown
                  name='category'
                  onChange={handleChange('category')}
                  value={values.category}
                  label={Strings.CATEGORY}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                  title={Strings.TYPE_OR_SELECT}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={vendorCategoryList}
                  searchable
                />
                <Textbox
                  name='fullName'
                  onChange={handleChange('fullName')}
                  value={values.fullName}
                  label={Strings.FULL_NAME}
                  error={Boolean(touched.fullName && errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  placeholder={Strings.ENTER_COMPANY_FULL_NAME}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <Textbox
                  type='email'
                  name='emailAddress'
                  onChange={handleChange('emailAddress')}
                  value={values.emailAddress}
                  label={Strings.EMAIL_ADDRESS}
                  error={Boolean(touched.emailAddress && errors.emailAddress)}
                  helperText={touched.emailAddress && errors.emailAddress}
                  placeholder={Strings.ENTER_COMPANY_EMAIL}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <div className={classes.phoneno_container}>
                  <CountryCodePicker
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.countryCodeContainer
                    )}
                    value={values.phoneCountryCode}
                    onChange={handleChange('phoneCountryCode')}
                    label={Strings.PHONE}
                  />
                  <Textbox
                    name='phoneNumber'
                    onChange={handleChange('phoneNumber')}
                    value={values.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    placeholder={Strings.ENTER_PHONE_NUMBER}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.phoneno_margin
                    )}
                  />
                </div>
                <Textbox
                  name='address'
                  onChange={handleChange('address')}
                  value={values.address}
                  label={Strings.ADDRESS}
                  // error={Boolean(touched.address && errors.address)}
                  // helperText={touched.address && errors.address}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  inputRef={inputRef}
                />
                <div className={classes.phoneno_container}>
                  <CountryCodePicker
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.countryCodeContainer
                    )}
                    value={values.techPhoneCountryCode}
                    onChange={handleChange('techPhoneCountryCode')}
                    label={Strings.TECH_PHONE}
                  />
                  <Textbox
                    name='techPhone'
                    onChange={handleChange('techPhone')}
                    value={values.techPhone}
                    error={Boolean(touched.techPhone && errors.techPhone)}
                    helperText={touched.techPhone && errors.techPhone}
                    placeholder={Strings.ENTER_TECHNICIAN_PHONE}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.phoneno_margin
                    )}
                  />
                </div>
                <Textbox
                  name='preferredTechnician'
                  onChange={handleChange('preferredTechnician')}
                  value={values.preferredTechnician}
                  label={Strings.PREFERRED_TECHNICIAN}
                  error={Boolean(
                    touched.preferredTechnician && errors.preferredTechnician
                  )}
                  helperText={
                    touched.preferredTechnician && errors.preferredTechnician
                  }
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <Textbox
                  name='websiteLink'
                  onChange={handleChange('websiteLink')}
                  value={values.websiteLink}
                  label={Strings.WEBSITE_URL}
                  error={Boolean(touched.websiteLink && errors.websiteLink)}
                  helperText={touched.websiteLink && errors.websiteLink}
                  placeholder={Strings.ENTER_URL}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                {/* <Dropdown
                  name='preferredTechnician'
                  onChange={handleChange('preferredTechnician')}
                  value={values.preferredTechnician}
                  label={Strings.PREFERRED_TECHNICIAN}
                  error={Boolean(
                    touched.preferredTechnician && errors.preferredTechnician
                  )}
                  helperText={
                    touched.preferredTechnician && errors.preferredTechnician
                  }
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                  searchable
                /> */}
                <Textbox
                  name='notes'
                  onChange={handleChange('notes')}
                  value={values.notes}
                  label={Strings.INTERNAL_NOTES}
                  // error={Boolean(touched.fullName && errors.fullName)}
                  // helperText={touched.fullName && errors.fullName}
                  placeholder={Strings.TYPE_YOUR_NOTES}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  multiline
                />
                <FormSwitch
                  label={Strings.PREFERRED_VENDOR_PROGRAM}
                  value={values.preferredVendorProgram}
                  containerClassName={classes.form_input_container}
                  onChange={() =>
                    setFieldValue(
                      'preferredVendorProgram',
                      !values.preferredVendorProgram
                    )
                  }
                />
                <Button
                  type='submit'
                  text={
                    props.isEdit
                      ? Strings.UPDATE_VENDOR
                      : Strings.ADD_NEW_VENDOR
                  }
                  loading={props.addVendorLoading || props.updateVendorLoading}
                  disabled={props.addVendorLoading || props.updateVendorLoading}
                  containerClassName={classes.add_button_container}
                  className={classes.add_button}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  addVendorData: state.addVendorData.data,
  addVendorLoading: state.addVendorData.loading,

  // UserListByTypeData: state.UserListByTypeData.data,
  // UserListByTypeLoading: state.UserListByTypeData.loading,

  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,
  updateVendorLoading: state.updateVendorData.loading,
});

export default connect(mapStateToProps, {
  createVendor,
  updateVendor,
  // fetchUserListByType,
  fetchCategoryList,
})(NewVendor);
