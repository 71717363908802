import React, { useEffect, useState } from 'react';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../components/Textbox';
import CountryCodePicker from '../../../components/CountryCodePicker';
import classNames from 'classnames';
import Dropdown from '../../../components/Dropdown';
import Button from '../../../components/Button';
import { useTheme } from '@mui/material/styles';
import { AddClientSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import { getErrorMessage } from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { createClient } from '../../../redux/actions/clientActions/addClient';
import { updateClient } from '../../../redux/actions/clientActions/updateClient';
import { fetchProgramList } from '../../../redux/actions/clientActions/programTypeList';
import { fetchPropertiesList } from '../../../redux/actions/clientActions/propertiesList';
import { useAlert } from 'react-alert';
import FormSwitch from '../../../components/Switch';
import TagsInput from '../../../components/TagsInput';

const NewClient = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [adminList, setAdminList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [primaryAddressList, setPrimaryAddressList] = useState([]);
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      props.fetchPropertiesList(props.data._id, (err) => onError(err));
      setFieldValue('isEdit', true);
    }
    props.fetchUserListByType('admin', (err) => onError(err));
    // props.fetchProgramList((err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => ({ title: item.name, value: item._id })) || [];
    setAdminList(newData);
  }, [props.UserListByTypeData]);

  // useEffect(() => {
  //   const data = props.programListData ? props.programListData : [];
  //   const newData = data.map((item) => ({
  //     title: item.title,
  //     value: item._id,
  //   }));
  //   setProgramList(newData);
  // }, [props.programListData]);

  useEffect(() => {
    if (props.isEdit) {
      const data = props.propertiesListData ? props.propertiesListData : [];
      const newData = data.map((item) => ({
        title: item.address,
        value: item._id,
      }));
      setPrimaryAddressList(newData);
    }
  }, [props.propertiesListData]);

  useEffect(() => {
    if (props.isEdit) {
      const {
        // name,
        firstName,
        lastName,
        email,
        ccEmails,
        phoneNumber,
        adminAssigned,
        countryCode,
        // programType,
        primaryAddress,
      } = props.data;
      // setFieldValue('fullName', name);
      setFieldValue('firstName', firstName);
      setFieldValue('lastName', lastName);
      setFieldValue('emailAddress', email);
      setFieldValue('ccEmails', ccEmails || []);
      setFieldValue('phoneNumber', phoneNumber);
      setFieldValue(
        'admin',
        adminList.find((item) => item.value === adminAssigned?._id)?.value
      );
      if (primaryAddress) setFieldValue('primaryAddress', primaryAddress._id);
      // setFieldValue(
      //   'programType',
      //   programList.find((item) => item.title === programType.title)?.value
      // );
      setFieldValue('countryCode', countryCode.substring(1));
    }
  }, [primaryAddressList, programList, adminList]);

  const formik = useFormik({
    initialValues: {
      // fullName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      admin: '',
      // programType: '',
      countryCode: '1',
      // sendPassword: false,
      password: '',
      isEdit: false,
      ccEmails: [],
    },
    validationSchema: AddClientSchema,

    onSubmit: (values) => {
      if (props.isEdit) editClient(values);
      else addNewClient(values);
    },
  });

  const addNewClient = (values) => {
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      ccEmails: values.ccEmails,
      countryCode: '+' + values.countryCode,
      phoneNumber: values.phoneNumber,
      adminAssigned: values.admin,
      // programType: values.programType,
      // sendPassword: values.sendPassword,
      password: values.password,
    };
    props.createClient(data, (err) => onError(err), props.onNewClose);
  };

  const editClient = (values) => {
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      ccEmails: values.ccEmails,
      countryCode: '+' + values.countryCode,
      phoneNumber: values.phoneNumber,
      primaryAddress: values.primaryAddress,
      adminAssigned: values.admin,
      // programType: values.programType,
    };
    if (values.password) data.password = values.password;
    props.updateClient(props.data._id, data, props.onNewClose, (err) =>
      onError(err)
    );
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props.isEdit ? Strings.EDIT_CLIENT : Strings.NEW_CLIENT}
          onNewClose={props.onNewClose}
        />
        {props.UserListByTypeLoading ||
        (props.isEdit && props.propertiesListLoading) ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='firstName'
                  onChange={handleChange('firstName')}
                  value={values.firstName}
                  label={Strings.FIRST_NAME}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  placeholder={Strings.ENTER_FIRST_NAME}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <Textbox
                  name='lastName'
                  onChange={handleChange('lastName')}
                  value={values.lastName}
                  label={Strings.LAST_NAME}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  placeholder={Strings.ENTER_LAST_NAME}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <Textbox
                  type='email'
                  name='emailAddress'
                  onChange={handleChange('emailAddress')}
                  value={values.emailAddress}
                  label={Strings.EMAIL_ADDRESS}
                  error={Boolean(touched.emailAddress && errors.emailAddress)}
                  helperText={touched.emailAddress && errors.emailAddress}
                  placeholder={Strings.ENTER_COMPANY_EMAIL}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <div style={{ marginTop: 12 }}>
                  <label
                    className={classes.form_label}
                    style={{ marginBottom: 7, display: 'inline-block' }}
                  >{`Add cc's`}</label>
                  <TagsInput
                    selectedTags={(value) => setFieldValue('ccEmails', value)}
                    placeholder={`Add cc's`}
                    tags={values.ccEmails || []}
                    renderTag={renderTags}
                  />
                </div>
                {/* <Textbox
                  name='primaryAddress'
                  onChange={handleChange('primaryAddress')}
                  value={values.primaryAddress}
                  label={Strings.PRIMARY_ADDRESS}
                  error={Boolean(
                    touched.primaryAddress && errors.primaryAddress
                  )}
                  helperText={touched.primaryAddress && errors.primaryAddress}
                  placeholder={Strings.TYPE_ADDRESS}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                /> */}
                {props.isEdit && (
                  <Dropdown
                    name='primaryAddress'
                    onChange={(value) => setFieldValue('primaryAddress', value)}
                    value={values.primaryAddress}
                    label={Strings.PRIMARY_ADDRESS}
                    error={Boolean(
                      touched.primaryAddress && errors.primaryAddress
                    )}
                    helperText={touched.primaryAddress && errors.primaryAddress}
                    title={Strings.TYPE_ADDRESS}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={primaryAddressList}
                    searchable
                  />
                )}
                <div className={classes.phoneno_container}>
                  <CountryCodePicker
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.countryCodeContainer
                    )}
                    value={values.countryCode}
                    onChange={handleChange('countryCode')}
                    label={Strings.PHONE_NUMBER}
                  />
                  <Textbox
                    name='phoneNumber'
                    onChange={handleChange('phoneNumber')}
                    value={values.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    placeholder={Strings.ENTER_PHONE_NUMBER}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.phoneno_margin
                    )}
                  />
                </div>
                <Dropdown
                  name='admin'
                  onChange={(value) => setFieldValue('admin', value)}
                  value={values.admin}
                  label={Strings.ADMIN_ASSIGNED}
                  error={Boolean(touched.admin && errors.admin)}
                  helperText={touched.admin && errors.admin}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={adminList}
                  searchable
                />
                {/* <Dropdown
                  name='programType'
                  onChange={(value) => setFieldValue('programType', value)}
                  value={values.programType}
                  label={Strings.PROGRAM_TYPE}
                  error={Boolean(touched.programType && errors.programType)}
                  helperText={touched.programType && errors.programType}
                  title={Strings.TYPE_OR_SELECT}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={programList}
                  searchable
                /> */}
                <Textbox
                  name='password'
                  onChange={handleChange('password')}
                  value={values.password}
                  label={Strings.PASSWORD}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  placeholder={Strings.ENTER_PASSWORD}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  errorClassName={classes.errorClassName}
                />
                {/* {!props.isEdit && (
                  <FormSwitch
                    label={Strings.SEND_PASSWORD}
                    value={values.sendPassword}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('sendPassword', !values.sendPassword)
                    }
                  />
                )} */}
                <Button
                  type='submit'
                  text={
                    props.isEdit
                      ? Strings.UPDATE_CLIENT
                      : Strings.ADD_NEW_CLIENT
                  }
                  loading={
                    props.isEdit
                      ? props.updateClientLoading
                      : props.addClientLoading
                  }
                  disabled={
                    props.isEdit
                      ? props.updateClientLoading
                      : props.addClientLoading
                  }
                  containerClassName={classes.add_button_container}
                  className={classes.add_button}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  addClientData: state.addClientData.data,
  addClientLoading: state.addClientData.loading,

  updateClientData: state.updateClientData.data,
  updateClientLoading: state.updateClientData.loading,

  programListData: state.programListData.data,
  programListLoading: state.programListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  propertiesListData: state.propertiesListData.data,
  propertiesListLoading: state.propertiesListData.loading,
});

export default connect(mapStateToProps, {
  fetchProgramList,
  createClient,
  fetchUserListByType,
  updateClient,
  fetchPropertiesList,
})(NewClient);
