import axios from 'axios';
import { UPDATE_USER } from '../../constants';

export const updateUser = (uId, data, onError, onNewClose) => (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('name', data.name);
  bodyFormData.append('firstName', data.firstName);
  bodyFormData.append('lastName', data.lastName);
  bodyFormData.append('email', data.email);
  bodyFormData.append('phoneNumber', data.phoneNumber);
  bodyFormData.append('countryCode', data.countryCode);
  bodyFormData.append('color', data.color);
  bodyFormData.append('bioLink', data.bioLink || '');
  if (data?.role) bodyFormData.append('role', data.role);
  if (data.jobTitle) bodyFormData.append('jobTitle', data.jobTitle);
  if (data.password) {
    bodyFormData.append('password', data.password);
  }
  if (data.profilePicture)
    bodyFormData.append('profilePicture', data.profilePicture);
  const token = localStorage.jwtToken;
  dispatch(loadAddUser());
  axios({
    method: 'put',
    url: `/admin/updateUser?userId=${uId}`,
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      onNewClose();
      dispatch(setUpdateUser(res));
    })
    .catch((err) => {
      dispatch(errorUpdateUser(err.response));
      onError(err.response);
    });
};

export const loadAddUser = () => ({
  type: UPDATE_USER.LOAD,
});

export const setUpdateUser = (data) => ({
  type: UPDATE_USER.SUCCESS,
  payload: data.data,
});

export const errorUpdateUser = (error) => ({
  type: UPDATE_USER.FAIL,
  payload: error,
});
