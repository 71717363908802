import { UPDATE_REPORT } from '../../../constants';
import axios from 'axios';

export const updateReport =
  (reportId, data, onError, callback) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateReport());
    axios
      .put(`/report/update/${reportId}`, data, config)
      .then((res) => {
        callback();
        dispatch(setUpdateReport(res));
      })
      .catch((err) => {
        dispatch(errorUpdateReport(err.response));
        onError(err.response);
      });
  };

export const loadUpdateReport = () => ({
  type: UPDATE_REPORT.LOAD,
});

export const setUpdateReport = (reportData) => ({
  type: UPDATE_REPORT.SUCCESS,
  payload: reportData?.data?.data || null,
});

export const errorUpdateReport = (error) => ({
  type: UPDATE_REPORT.FAIL,
  payload: error,
});
