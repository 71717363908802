import axios from 'axios';
import { CLEAR_ALL_CLIENT_FEEDBACK } from '../../constants';

export const clearAllClientFeedback = (onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAllClientFeedback());
  axios
    .delete(`/feedback/clearAll`, config)
    .then((res) => {
      dispatch(setAllClientFeedback(res.data));
      onSuccess?.(res.data);
    })
    .catch((err) => {
      dispatch(errorAllClientFeedback(err.response));
      onError(err.response);
    });
};

export const loadAllClientFeedback = () => ({
  type: CLEAR_ALL_CLIENT_FEEDBACK.LOAD,
});

export const setAllClientFeedback = (data) => ({
  type: CLEAR_ALL_CLIENT_FEEDBACK.SUCCESS,
  payload: data,
});

export const errorAllClientFeedback = (error) => ({
  type: CLEAR_ALL_CLIENT_FEEDBACK.FAIL,
  payload: error,
});
