import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  title_container: {
    display: 'none',
    backgroundColor: theme.palette.background,
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      // flexDirection: 'column',
      padding: '0px 18px',
      width: 'calc(100% - 36px)',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      // flexDirection: 'column',
      padding: '0px 14px',
      width: 'calc(100% - 28px)',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    color: theme.palette.title_text,
  },
  search_container: {
    width: '100%',
    marginTop: 15,
  },
}));
