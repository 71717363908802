import Button from '../../../components/Button';
import useStyles from './styles';
import classNames from 'classnames';

const ActionButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      text={props.text}
      containerClassName={classes.action_button_container}
      className={classNames(classes.action_button)}
      onClick={props.onClick}
    />
  );
};

export default ActionButton;
