import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  card_container: {
    // width: '100%',
    borderRadius: 16,
    padding: '16px 20px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.NEVY_BLUE,
    maxWidth: '100%',
  },
  text_view_list_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text_view_list: {
    fontSize: 16,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    cursor: 'pointer',
  },
  text_progress: {
    fontSize: 44,
    fontWeight: 700,
    color: Colors.WHITE,
  },
  text_subTitle: {
    fontSize: 18,
    fontWeight: 400,
    color: Colors.WHITE,
    marginTop: 10,
  },
  flex_view: {
    display: 'flex',
  },
  date_day_text: {
    fontWeight: 400,
    fontSize: 14,
    color: Colors.WHITE,
  },
  percentage_text: {
    color: Colors.LIGHT_GREEN,
    fontSize: 12,
    fontWeight: 600,
  },
  percentage_upArrow_icon: {
    height: 14,
    width: 14,
    marginLeft: 5,
  },
}));
