import axios from 'axios';
import { USER_LIST_BY_TYPE } from '../../constants';

export const fetchUserListByType =
  (type, onError, search, roles) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUserListByType());

    const url =
      !search && !roles
        ? `/admin/getUserByType?userType=${type}`
        : roles
        ? `/admin/getUserByType?roles=${roles}&searchString=${search}`
        : `/admin/getUserByType?userType=${type}&searchString=${search}`;

    axios
      .get(url, config)
      .then((res) => dispatch(setUserListByType(res, type)))
      .catch((err) => {
        dispatch(errorUserListByType(err.response));
        onError(err.response);
      });
  };

export const loadUserListByType = () => ({
  type: USER_LIST_BY_TYPE.LOAD,
});

export const setUserListByType = (userList, type) => ({
  type: USER_LIST_BY_TYPE.SUCCESS,
  payload: { data: userList.data.data, type },
});

export const errorUserListByType = (error) => ({
  type: USER_LIST_BY_TYPE.FAIL,
  payload: error,
});
