import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';

export default makeStyles((theme) => ({
  dropdown_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  dropdown_label_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
  },
  dropdown_label: {
    // marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  dropdown_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },

  title_color: {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.input_border}`,
      width: '100%',
      borderRadius: 8,
      "&.Mui-focused": {
        borderColor: theme.palette.background_button,
      }
    },
    '& .MuiSelect-select.MuiSelect-select': {
      color: theme.palette.input_placeholder,
      padding: 13,
      width: '100%',
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      background: `transparent url(${
        icons(theme).downArrow
      }) no-repeat right 0.8rem center/1rem 4rem`,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  formControl: {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.input_border}`,
      borderRadius: 8,
      width: '100%',
      maxHeight: '45px',
      "&.Mui-focused": {
        border: `1px solid ${theme.palette.background_button}`,
      }
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: 13,
      width: '100%',
      fontFamily: "'Open Sans', sans-serif",
      color: theme.palette.profile_title,
      fontWeight: 400,
      fontSize: 14,
      background: `transparent url(${
        icons(theme).downArrow
      }) no-repeat right 0.8rem center/1rem 4rem`,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  select: {
    width: '100%',
    fontSize: '12px',
    appearance: 'none',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    background: `transparent url(${
      icons(theme).downArrow
    }) no-repeat right 0.8rem center/1rem 4rem`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    display: 'none',
  },
  paper: {
    borderRadius: 8,
    marginTop: 4,
    maxHeight: 200,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontFamily: "'Open Sans', sans-serif",
      color: Colors.DARK_GRAY,
      fontWeight: 400,
      fontSize: 14,
      // maxWidth: 250,
    },
    '& li:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected': {
      color: Colors.DARK_GRAY,
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
  },
  list1: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontFamily: "'Open Sans', sans-serif",
      color: Colors.DARK_GRAY,
      fontWeight: 400,
      fontSize: 14,
      maxWidth: 250,
      [theme.breakpoints.up('xl')]: {
        maxWidth: 350,
      },
    },
    '& li:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected': {
      color: Colors.DARK_GRAY,
      background: Colors.SIDEBAR_BLUE,
    },
    '& li.Mui-selected:hover': {
      background: Colors.SIDEBAR_BLUE,
    },
  },
  isDisabled: {
    '& .MuiInputBase-root': {
      backgroundColor: `${theme.palette.disable_color} !important`,
    },
  },
  edit_icon: {
    height: 20,
    width: 20,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  dropdown_option_label: {
    display: 'flex',
    overflow: 'auto',
    marginRight: 10,
  },
  dropDown_label_title: {
    display: 'inline-block',
    width: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  flex_row_item_center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  color_item: {
    height: 17,
    width: 17,
    borderRadius: 100,
    marginRight: 10,
  },
}));
