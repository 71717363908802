import { makeStyles } from '@mui/styles';
import { Colors } from '../../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 3,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 'calc(100% - 50px)',
      height: 'calc(100% - 50px)',
    },
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 12,
  },
  add_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  secondary_button: {
    backgroundColor: 'transparent',
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  attribute_title_text: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
    marginTop: 20,
  },
  remove_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.RED}`,
    color: Colors.RED,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
}));
