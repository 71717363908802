import axios from 'axios';
import { EXPORT_JUNCTURES } from '../../constants';
import moment from 'moment-timezone';

export const exportJunctures = (onResponse, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const data = {
    currentDate: moment().format('YYYY-MM-DD'),
    timeZone: moment.tz.guess(),
  };
  const url = `/admin/exportJunctures`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    method: 'post',
    url: url,
    data,
    responseType: 'blob',
  };
  dispatch(loadExportJunctures());
  axios(config)
    .then((res) => {
      dispatch(setExportJunctures(res));
      onResponse(res?.data);
    })
    .catch((err) => {
      dispatch(errorExportJunctures(err.response));
      onError(err.response);
    });
};

export const loadExportJunctures = () => ({
  type: EXPORT_JUNCTURES.LOAD,
});

export const setExportJunctures = (data) => ({
  type: EXPORT_JUNCTURES.SUCCESS,
  payload: data.data,
});

export const errorExportJunctures = (error) => ({
  type: EXPORT_JUNCTURES.FAIL,
  payload: error,
});
