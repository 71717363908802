import React from 'react';
import { Strings } from '../../utils/strings';
import Button from '../Button';
import Searchbox from '../Searchbox';
import useStyles from './styles';
import DateSelector from '../DateSelector';
import { isClient, reportActionDropdownArray } from '../../utils/appUtils';
import Dropdown from '../Dropdown';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { Stack, useMediaQuery } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import FormSwitch from '../Switch';
import MultiSelectDropdown from '../MultiSelectDropdown';
import CustomizedTooltips from '../CustomizedTooltips';
import ActionMenu from '../../pages/Issues/ActionMenu';

const TextImageBox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.download_container} style={props.style}>
      <label className={classes.download_label} onClick={props.onClick}>
        {props.text}
      </label>
      {props.isLoader ? (
        <CircularProgress
          style={{
            color: theme.palette.background_button,
            marginLeft: 15,
          }}
          size={20}
        />
      ) : (
        <img
          src={props.icon}
          alt=''
          className={classes.filter_icon}
          onClick={props.onClick}
        />
      )}
    </div>
  );
};

const TableToolbox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      className={classNames(
        classes.toolbox_container,
        props.toolboxContainerClassName && props.toolboxContainerClassName
      )}
    >
      {props.isBack && (
        <img
          src={icons(theme).backIcon}
          alt=''
          className={classes.back_icon}
          onClick={props.onClickBack}
        />
      )}
      {props.isLabel && (
        <label
          htmlFor='label'
          className={classNames(
            props.labelClassName ? props.labelClassName : classes.label
          )}
        >
          {props.labelText}
        </label>
      )}
      {props.isSearch && (
        <Searchbox
          value={props.search}
          containerClassName={classNames(
            classes.searchbox_container,
            props.searchBoxContainerClassName
          )}
          onChange={props.onChangeSearch}
          searchPlaceholder={props?.searchPlaceholder}
        />
      )}
      {props.isSearchSelection && (
        <MultiSelectDropdown
          name={props.searchSelectionName}
          onChange={props.onChangeSearchSelection}
          value={props.searchSelectionValue}
          title={props.searchSelectionTitle}
          labelClassName={props.searchSelectionLabel}
          labelContainerClassName={props.searchSelectionLabelContainer}
          optionArray={props.searchSelectionsOptions}
          hideAvatar={props.searchSelectionsHideAvatar}
          containerClassName={classNames(
            classes.searchSelection_container,
            props.searchSelectionContainerClassName
          )}
        />
      )}
      {(props.isDate || props.isEndDate) && (
        <div
          className={classNames(
            classes.date_container_class,
            props.dateContainerClass
          )}
        >
          {props.isDate && (
            <DateSelector
              value={props.date}
              containerClassName={classNames(
                classes.dateSelector_container,
                props.startDateContainerClassName &&
                  props.startDateContainerClassName
              )}
              onChange={props.onChangeDate}
              placeholder={props.datePlaceholder || 'Start date'}
              placeholderColor={theme.palette.search_text}
              format={'MMM dd, yyyy'}
            />
          )}
          {props.isEndDate && (
            <DateSelector
              value={props.endDate}
              containerClassName={classes.dateSelector_container}
              onChange={props.onChangeEndDate}
              placeholder='End date'
              placeholderColor={theme.palette.search_text}
              format={'MMM dd, yyyy'}
            />
          )}
        </div>
      )}
      {props.isFilterDropdown && (
        <Dropdown
          onChange={props.onChangeFilterDropdown}
          value={props.filterDropdownValue}
          title={Strings.SELECT_SAVED_FILTER}
          className={classes.form_input}
          containerClassName={classes.dropdown_saved_container}
          optionArray={props.filterDropdownList}
          placeholder={Strings.SELECT_SAVED_FILTER}
          isEditIcon
          onEditIconClick={props.onEditClick}
          isFixedWidth
          searchable
        />
      )}
      {props.isFilter && (
        <div className={classes.filter_main_container}>
          <CustomizedTooltips title={Strings.FILTERS}>
            <div className={classes.filter_container}>
              <label htmlFor='filters'>{Strings.FILTERS}</label>
              <img
                className={classes.filter_icon}
                src={icons(theme).filterIcon}
                alt=''
                onClick={props.filterButtonClick}
              />
            </div>
          </CustomizedTooltips>
          <CustomizedTooltips title={Strings.REFRESH}>
            <img
              className={classes.refresh_icon}
              src={icons(theme).refreshIcon}
              alt=''
              onClick={props.onClickRefresh}
            />
          </CustomizedTooltips>
          <CustomizedTooltips title={Strings.CLEAR_FILTERS}>
            <img
              className={classes.refresh_icon}
              src={icons(theme).clearFilterIcon}
              alt=''
              onClick={props.onClickClearFilter}
            />
          </CustomizedTooltips>
          {props.isSort ? (
            <CustomizedTooltips title={props?.sortTitle}>
              <img
                className={classes.refresh_icon}
                src={
                  props.isSortAsc
                    ? icons(theme).sortUpper
                    : icons(theme).sortDown
                }
                alt='sort'
                onClick={props.onSortClick}
              />
            </CustomizedTooltips>
          ) : null}
        </div>
      )}
      {(props.isSecondaryButton ||
        props.isPrimaryButton ||
        props.isPrint ||
        props.isDownloadCsv) &&
        !isClient() && (
          <div className={classes.right_container}>
            {props.isToggle && (
              <FormSwitch
                label={props.toggleLabel}
                value={props.toggleValue}
                containerClassName={classes.toggle_btn}
                onChange={props.onChangeToggle}
              />
            )}
            {props.isPrint && (
              <TextImageBox
                style={{ marginRight: 30 }}
                text={Strings.PRINT}
                icon={icons(theme).printIcon}
                onClick={props.onPrintClick}
              />
            )}
            {props.isDownloadCsv && (
              <TextImageBox
                style={{ marginRight: 30 }}
                text={Strings.DOWNLOAD_CSV}
                icon={icons(theme).downloadIcon}
                onClick={props.onDownloadCsvClick}
                isLoader={props.downloadCsvLoader}
              />
            )}
            {props.isSecondaryButton && (
              <Button
                text={props.secondaryButtonText}
                containerClassName={classes.secondary_btn_container}
                className={classes.secondary_button}
                onClick={props.secondaryButtonClick}
              />
            )}
            {props.isPrimaryButton && (
              <Button
                text={
                  props.primaryButtonText
                    ? props.primaryButtonText
                    : Strings.ADD_NEW
                }
                containerClassName={classes.add_new_container}
                className={classes.add_new_button}
                onClick={props.onNewClick}
              />
            )}
          </div>
        )}
      {(props.isEdit ||
        props.isDownloadPdf ||
        // props.isDownloadCsv ||
        props.markAsComplete) && (
        <div className={classes.right_container_report_top}>
          {props.showAddNote && (
            <Stack direction='row' alignItems='center' justifyContent='end'>
              <CustomizedTooltips title={Strings.ADD_NOTE}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={icons(theme).addNote}
                  alt=''
                  onClick={props.onAddNoteClick}
                />
              </CustomizedTooltips>
            </Stack>
          )}
          {props?.showActionButton && (
            <Stack>
              {props?.markAsCompleteLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: theme.palette.background_button }}
                />
              ) : (
                <ActionMenu
                  isReport
                  onSelect={props.onSelect}
                  actionButtonContainer={classes.actionBtnStyle}
                />
              )}
            </Stack>
          )}
          {props.isEdit && !props.editing && !isClient() && (
            <TextImageBox
              // style={{ marginRight: 30 }}
              text={Strings.EDIT}
              icon={icons(theme).editIcon}
              onClick={props.onEditClick}
            />
          )}
          {props.isDownloadPdf && (
            <TextImageBox
              // style={{ marginRight: !isClient() && !isMobileView && 30 }}
              text={Strings.DOWNLOAD_PDF}
              icon={icons(theme).downloadIcon}
              onClick={props.onDownloadPdfClick}
              isLoader={props.downloadPdfLoader}
            />
          )}
          {/* {props.isDownloadCsv && !isClient() && (
              <TextImageBox
                text={Strings.DOWNLOAD_CSV}
                icon={icons(theme).downloadIcon}
                onClick={props.onDownloadCsvClick}
                isLoader={props.downloadCsvLoader}
              />
            )} */}
          {props.isEdit && props.editing && (
            <Button
              text={'Cancel'}
              // containerClassName={classes.secondary_btn_container}
              className={classes.secondary_button}
              onClick={props.onClickCancel}
            />
          )}
          {props.isEdit && props.editing && (
            <Button
              text={'Save Updates'}
              containerClassName={classes.add_new_container}
              className={classes.add_new_button}
              onClick={props.onClickSaveUpdates}
              loading={props.updateReportLoading}
            />
          )}
        </div>
      )}
      {(props.isDropdownDateSelector ||
        props.isManageTags ||
        props.isAddFolder) && (
        <div className={classes.right_container}>
          {props.isDropdownDateSelector && (
            <>
              <Dropdown
                onChange={props.onChangeDropDown}
                value={props.dropdownValue}
                title={Strings.SELECT_THE_CLIENT}
                className={classes.form_input}
                containerClassName={classes.dateSelector_container}
                optionArray={props.dropdownList}
                searchable
                // name={props.dropdownName}
                // label={props.dropdownTitle}
                // error={props.dropDownError}
                // helperText={props.dropDownHelperText}
                // labelClassName={classes.form_label}
              />
              <DateSelector
                // name={props.filterDateName}
                value={props.filterDateValue}
                containerClassName={classes.dateSelector_container}
                onChange={props.onChangeFilterDate}
                placeholder='Select the date'
                placeholderColor={theme.palette.search_text}
                format={'MM/dd/yyyy'}
              />
            </>
          )}
          {props?.selectedIds?.length > 0 && (
            <div className={classes.selected_count_container}>
              <span className={classes.selected_count_txt}>
                {`${props?.selectedIds?.length} Items Selected`}
              </span>
              <img
                src={icons(theme).removeIcon}
                className={classes.clearIcon}
                onClick={props.onClickClearIds}
              />
            </div>
          )}
          {props.isPasteBtn && (
            <Button
              text={Strings.PASTE}
              containerClassName={classes.add_new_container}
              className={classes.add_new_button}
              onClick={props.onClickPaste}
              loading={props.pasteLoading}
              disabled={props.pasteLoading}
            />
          )}
          {props.isAddFolder && (
            <Button
              text={Strings.CREATE_FOLDER}
              containerClassName={classes.manage_tags_container}
              className={classes.manage_tags_button}
              onClick={props.onAddFolderClick}
              loading={props.addFolderLoading}
              disabled={props.addFolderLoading}
              loaderColor={theme.palette.background_button}
            />
          )}
          {props.isManageTags && (
            <Button
              text={Strings.MANAGE_TAGS}
              containerClassName={classes.manage_tags_container}
              className={classes.manage_tags_button}
              onClick={props.onManageTagsClick}
            />
          )}
        </div>
      )}
      {props.isViewAll && (
        <div className={classes.right_container}>
          <label
            className={classes.view_all_text}
            onClick={props.onViewAllClick}
          >
            {Strings.VIEW_ALL}
          </label>
        </div>
      )}
    </div>
  );
};

export default TableToolbox;
