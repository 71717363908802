import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  recurring_modal_container: {
    position: 'absolute',
    top: 130,
    right: 25,
    left: 25,
    zIndex: 5,
    padding: 16,
    borderRadius: 8,
    boxShadow: `0px 0px 15px ${theme.palette.modal_shadow}`,
    backgroundColor: theme.palette.dialog_background,
  },
  modal_triangle: {
    position: 'absolute',
    top: -9,
    left: 40,
  },
  dialog_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  header_label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  edit_icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 15,
  },
  cancel_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  update_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
  },
}));
