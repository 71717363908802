import axios from 'axios';
import { isAdmin, isSuperAdmin } from '../../../utils/appUtils';
import { CREATE_CHAT_ROOM } from '../../constants';

export const createRoom = (id, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const data = { participant: id };
  dispatch(loadCreateRoom());
  let url = 'room/create';
  axios
    .post(url, data, config)
    .then((res) => dispatch(setCreateRoom(res.data.data)))
    .catch((err) => {
      dispatch(errorCreateRoom(err.response));
      onError(err.response);
    });
};

export const loadCreateRoom = () => ({
  type: CREATE_CHAT_ROOM.LOAD,
});

export const setCreateRoom = (countData) => ({
  type: CREATE_CHAT_ROOM.SUCCESS,
  payload: countData,
});

export const errorCreateRoom = (error) => ({
  type: CREATE_CHAT_ROOM.FAIL,
  payload: error,
});
