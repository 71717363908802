import axios from 'axios';
import { CLEAR_CLIENT_FEEDBACK } from '../../constants';

export const clearClientFeedback = (id, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientFeedback());
  axios
    .delete(`/feedback/${id}`, config)
    .then((res) => {
      dispatch(setClientFeedback(res.data));
      onSuccess?.(res.data);
    })
    .catch((err) => {
      dispatch(errorClientFeedback(err.response));
      onError(err.response);
    });
};

export const loadClientFeedback = () => ({
  type: CLEAR_CLIENT_FEEDBACK.LOAD,
});

export const setClientFeedback = (data) => ({
  type: CLEAR_CLIENT_FEEDBACK.SUCCESS,
  payload: data,
});

export const errorClientFeedback = (error) => ({
  type: CLEAR_CLIENT_FEEDBACK.FAIL,
  payload: error,
});
