import React, { useState, useEffect } from 'react';
import TableToolbox from '../../../../components/TableToolbox';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EnhancedTableHead from '../../../../components/DataTables/TableHead';
import {
  applySortFilter,
  getComparator,
  getErrorMessage,
  tableCell,
  PAGE_SIZE,
  getLocaleStorageVal,
  setLocaleStorageVal,
} from '../../../../utils/appUtils';
import { useTheme } from '@mui/material/styles';
import NewCategoryType from './NewCategoryType';
import { connect, useDispatch } from 'react-redux';
import {
  fetchCategoryList,
  setCategoryList,
} from '../../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { CircularProgress } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { icons } from '../../../../utils/icons';
import CommonDialog from '../../../../components/CommonDialog';
import CustomizedTooltips from '../../../../components/CustomizedTooltips';
import { deleteCategory } from '../../../../redux/actions/jobActions/categoryActions/deleteCetegoryType';
import CustomPagination from '../../../../components/CustomPagination';

const categoryTypeDataStore = 'CategoryData';

// ======== Table Head =========
const headCells = [
  { id: 'categoryName', label: 'TITLE', isSort: true },
  { id: 'status', label: 'STATUS', isSort: true },
  { id: '' },
];

const CategoryTypes = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const existingCategoryData = getLocaleStorageVal(categoryTypeDataStore);
  const pageFilter = existingCategoryData?.pageFilter;
  const [newDialog, setNewDialog] = useState(false);
  // const [categoryListData, setCategoryListData] = useState([]);
  const categoryListData = props.categoryListData?.categories || [];
  const [deleteCategorytDialog, setDeleteCategoryDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(
    existingCategoryData?.sortBy
      ? existingCategoryData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(
    existingCategoryData?.sortBy?.key || ''
  );
  const [pageCount, setPageCount] = useState(pageFilter?.pageNumber || 1);
  const [pageSize, setPageSize] = useState(pageFilter?.pageSize || PAGE_SIZE);

  const theme = useTheme();
  const alert = useAlert();
  const { state } = useLocation();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getCategoryList = (type, pageNum, sortBy) => {
    const existingData = getLocaleStorageVal(categoryTypeDataStore);
    const sort = existingData?.sortBy;
    let newData = {
      page_number: pageNum || pageFilter?.pageNumber || pageCount,
      page_size: pageFilter?.pageSize || pageSize,
    };
    if (sortBy) newData.sortBy = sortBy;
    else if (!sortBy && sort) newData.sortBy = { [sort?.key]: sort?.value };

    props.fetchCategoryList(
      type ? type : 'form',
      (err) => onError(err),
      '',
      newData
    );
    setPageCount(pageNum || pageCount);
  };

  useEffect(() => {
    getCategoryList(state.type, pageCount);
    return () => dispatch(setCategoryList([]));
  }, [
    props.addCategoryData,
    props.deleteCategoryData,
    props.updateCategoryData,
    pageSize,
  ]);

  // useEffect(() => {
  //   const data = props.categoryListData ? props.categoryListData : [];
  //   setCategoryListData(data);
  // }, [props.categoryListData]);

  const onDeleteData = () => {
    props.deleteCategory(
      state && state.type ? state.type : 'form',
      selectedCategory._id,
      () => setDeleteCategoryDialog(false),
      (err) => onError(err)
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getCategoryList(state.type, pageCount, sortBy);
    setLocaleStorageVal(categoryTypeDataStore, {
      ...pageFilter,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredCategories = applySortFilter(
  //   categoryListData,
  //   getComparator(order, orderBy)
  // );

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    if (pageCount !== page) {
      getCategoryList(state.type, page);
    }
    setLocaleStorageVal(categoryTypeDataStore, { pageNumber: page, pageSize });
  };

  // ===== call when page size change =====
  const handlePageSize = (val) => {
    setPageSize(val);
    setPageCount(1);
    setLocaleStorageVal(categoryTypeDataStore, {
      pageNumber: 1,
      pageSize: val,
    });
  };

  return (
    <Stack className={classes.container}>
      <TableToolbox
        isBack
        isLabel
        isPrimaryButton
        labelText={Strings.CATEGORY_TYPES}
        onClickBack={() => navigate(-1)}
        onNewClick={() => setNewDialog(true)}
      />
      <div className={classes.list_container}>
        {props.categoryListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {categoryListData.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {categoryListData &&
                      categoryListData?.map((data, index) => {
                        const { categoryName, status } = data;
                        return (
                          <TableRow key={data._id} sx={{ width: '100%' }}>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {categoryName}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {status}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='end'
                              >
                                <CustomizedTooltips
                                  title={Strings.EDIT_CATEGORY_TYPE}
                                >
                                  <img
                                    className={classes.action_icons}
                                    style={{ marginRight: 15 }}
                                    src={icons(theme).editIcon}
                                    alt=''
                                    onClick={(e) => {
                                      setIsEdit(true);
                                      setSelectedCategory(data);
                                      setNewDialog(true);
                                    }}
                                  />
                                </CustomizedTooltips>
                                <CustomizedTooltips
                                  title={Strings.DELETE_CATEGORY_TYPE}
                                >
                                  <img
                                    className={classes.action_icons}
                                    src={icons(theme).deleteIcon}
                                    alt=''
                                    onClick={(e) => {
                                      setSelectedCategory(data);
                                      setDeleteCategoryDialog(true);
                                    }}
                                  />
                                </CustomizedTooltips>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>
      {props?.categoryListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.categoryListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSize}
          />
        </Stack>
      ) : null}
      {deleteCategorytDialog && (
        <CommonDialog
          title={Strings.CATEGORY}
          message={Strings.DELETE_CATEGORY}
          onSuccess={() => onDeleteData()}
          onClose={() => {
            setDeleteCategoryDialog(false);
          }}
          loading={props.deleteCategoryLoading}
        />
      )}
      {newDialog && (
        <NewCategoryType
          data={selectedCategory}
          onNewClose={() => {
            setIsEdit(false);
            setNewDialog(false);
          }}
          type={state && state.type ? state.type : 'form'}
          isEdit={isEdit}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  addCategoryData: state.addCategoryData.data,
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,
  deleteCategoryLoading: state.deleteCategoryData.loading,
  deleteCategoryData: state.deleteCategoryData.data,
  updateCategoryData: state.updateCategoryData.data,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  deleteCategory,
})(CategoryTypes);
