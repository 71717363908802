import React, { useState, useEffect, useCallback, useRef } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../utils/strings';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormField from './FormField';
import GroupFormField from './GroupFormField';
import { makeUniqId, reorderList } from '../../utils/appUtils';
import { debounce } from 'lodash';
import NewField from '../NewField';
import JobFormTools from '../../pages/Jobs/JobForms/NewJobForm/ToolsModal';
import SkeletonFormField from './SkeletonFormField';

const fetchFieldTitle = {
  Group: Strings.GROUP,
  Dropdown: Strings.DROPDOWN,
  Radio: Strings.RADIO,
  'Text Input': Strings.TEXT_INPUT,
  'Check List': Strings.CHECKLIST,
};

const AdditionalFormField = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { additionalFields, manageFormData, setIsShowBtn } = props;
  const formFieldDataRef = useRef(additionalFields);

  const [formFieldData, setFormFieldData] = useState(additionalFields || []);
  const [showToolBox, setShowToolBox] = useState(false);
  const [loading, setLoading] = useState(props?.isEdit ? true : false);

  useEffect(() => {
    if (additionalFields?.length) {
      setTimeout(() => {
        setLoading(false);
        setIsShowBtn(true);
      }, 1000);
    }
  }, [additionalFields]);

  const addNewField = async (fieldType) => {
    let newField = '';
    if (fieldType === 'Text Input') {
      newField = {
        elementType: fieldType,
        title: Strings.SELECTED_DROPDOWN,
        required: false,
        visible: true,
      };
    } else if (fieldType === 'Group') {
      newField = {
        elementType: fieldType,
        title: Strings.FOCUS_WEEK_PLACEHOLDER,
        required: false,
        visible: true,
      };
    } else {
      let uniqID = await makeUniqId(22);
      newField = {
        elementType: fieldType,
        title: Strings.SELECTED_DROPDOWN,
        required: false,
        visible: true,
        options:
          fieldType === 'Check List'
            ? [{ option: Strings.NA, color: '#ED4242', id: uniqID }]
            : [
                {
                  option: Strings.ISSUES_FOUND,
                  color: '#ED4242',
                  id: `issue${uniqID}`,
                },
                {
                  option: Strings.NO_ISSUES_FOUND,
                  color: '#ED4242',
                  id: `no_issue${uniqID}`,
                },
                { option: Strings.NA, color: '#ED4242', id: `na${uniqID}` },
              ],
      };
    }
    let _id = await makeUniqId(22);
    newField.id = _id;
    // let items = [...formFieldData, newField];
    let items = [...formFieldDataRef.current, newField];
    formFieldDataRef.current = items;
    // setFormFieldData(items);
    setShowToolBox(false);
    manageFormData(items);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    // const copyData = structuredClone(formFieldData);
    const copyData = structuredClone(formFieldDataRef.current);
    const items = reorderList(
      copyData,
      result.source.index,
      result.destination.index
    );
    formFieldDataRef.current = items;
    // setFormFieldData(items);
    manageFormData(items);
  };

  const handleCopy = async (index) => {
    // let copyData = structuredClone(formFieldData) || [];
    let copyData = structuredClone(formFieldDataRef.current) || [];
    let newField = structuredClone(copyData[index]) || {};
    let _id = await makeUniqId();
    newField.id = _id;
    delete newField?._id;
    // copyData.push(newField);
    copyData.splice(index + 1, 0, newField);
    formFieldDataRef.current = copyData;
    // setFormFieldData(copyData);
    manageFormData(copyData);
  };

  const handleDelete = (index) => {
    // let copyData = structuredClone(formFieldData) || {};
    let copyData = structuredClone(formFieldDataRef.current) || {};
    copyData.splice(index, 1);
    formFieldDataRef.current = copyData;
    // setFormFieldData(copyData);
    manageFormData(copyData);
  };

  const setFormData = useCallback(
    debounce((index, data) => {
      let newData = structuredClone(formFieldDataRef.current) || {};
      // let newData = structuredClone(formFieldData) || {};
      newData[index] = data;
      formFieldDataRef.current = newData;
      // setFormFieldData(newData);
      manageFormData(newData);
    }, 300),
    [formFieldDataRef.current]
  );

  if (loading) {
    return <SkeletonFormField />;
  }

  return (
    <>
      <DragDropContext ref={props?.ref} onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formFieldDataRef.current?.length
                ? formFieldDataRef.current?.map((item, index) => (
                    <Draggable
                      key={`key-${index}-${item._id || item.id}`}
                      draggableId={`dragId-${index}-${item._id || item.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {item?.elementType !== 'Group' ? (
                            <FormField
                              dragProvided={provided}
                              fieldTitle={fetchFieldTitle[item?.elementType]}
                              fieldData={item}
                              elemIndex={index}
                              setFormData={(_index, data) =>
                                setFormData(index, data)
                              }
                              onChangeCopy={() => handleCopy(index)}
                              onClickDelete={() => handleDelete(index)}
                            />
                          ) : (
                            <GroupFormField
                              dragProvided={provided}
                              fieldData={item}
                              elemIndex={index}
                              setFormData={(_index, data) =>
                                setFormData(index, data)
                              }
                              onChangeCopy={() => handleCopy(index)}
                              onClickDelete={() => handleDelete(index)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))
                : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <NewField
        containerClassName={classes.form_input_container}
        onClick={() => setShowToolBox(!showToolBox)}
      />
      {showToolBox && (
        <JobFormTools
          onDropDownClick={() => addNewField('Dropdown')}
          onTextInputClick={() => addNewField('Text Input')}
          onRadioClick={() => addNewField('Radio')}
          onCheckListClick={() => addNewField('Check List')}
          onNewGroupClick={() => addNewField('Group')}
          isFromGroupField={false}
        />
      )}
    </>
  );
};

export default React.memo(AdditionalFormField);
