import axios from 'axios';
import { DROPDOWN_JOB_LIST } from '../../../constants';

export const fetchDropdownJobList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDropdownJobList());
  axios
    .get('/jobs/listJobsDropDown', config)
    .then((res) => dispatch(setDropdownJobList(res)))
    .catch((error) => {
      dispatch(errorDropdownJobList(error.response));
      onError(error.response);
    });
};

export const loadDropdownJobList = () => ({
  type: DROPDOWN_JOB_LIST.LOAD,
});

export const setDropdownJobList = (jobList) => ({
  type: DROPDOWN_JOB_LIST.SUCCESS,
  payload: jobList.data.data,
});

export const errorDropdownJobList = (error) => ({
  type: DROPDOWN_JOB_LIST.FAIL,
  payload: error,
});
