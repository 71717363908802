import React, { useEffect, useState } from 'react';
import { Strings } from '../../utils/strings';
import useStyles from './styles';
import Searchbox from '../../components/Searchbox';
import { Avatar, Fade, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../utils/color';
import store from '../../redux/store';
import { logout } from '../../redux/actions/authActions/login';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import classNames from 'classnames';
import CommonDialog from '../../components/CommonDialog';
import { icons } from '../../utils/icons';
import { useThemeDispatch, toggleTheme } from '../../context/ThemeContext';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { ProfileBox } from '../../components/ProfileBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import {
  fetchNotificationCount,
  setNotificationCount,
} from '../../redux/actions/commonActions/unreadNotificationCount';
import { fetchNotificationList } from '../../redux/actions/commonActions/notificationList';
import { useAlert } from 'react-alert';
import {
  getErrorMessage,
  isClient,
  setLocaleStorageVal,
} from '../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import OneSignal from 'react-onesignal';
import { clearNotifications } from '../../redux/actions/commonActions/clearNotifications';
import { deleteNotification } from '../../redux/actions/commonActions/deleteNotification';
import Button from '../../components/Button';

const NavbarTitle = (props) => {
  const { classes } = props;
  return <label className={classes.navbar_title}>{props.title}</label>;
};

const SingleNotification = (props) => {
  const classes = useStyles();
  const { message, createdAt, sender } = props.data;
  return (
    <div className={classes.notification_data_container}>
      <Avatar
        sx={{ width: 40, height: 40 }}
        alt=''
        src={sender?.profilePicture}
      />
      <div className={classes.message_container}>
        <div className={classes.message_title}>
          <span className={classes.message}>{message}</span>
          <img
            src={icons().removeIcon}
            className={classes.removeIcon}
            alt=''
            onClick={(e) => props.onClickDelete(e, props.data._id)}
          />
        </div>
        <div className={classes.message_time_container}>
          <span className={classes.from}>from {sender?.name}</span>
          <span className={classes.from}>{moment(createdAt).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};

const NotificationDialog = (props) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (Boolean(props.anchorEl)) getNotifications();
  }, [props.anchorEl]);

  const onMessageClick = (type, data) => {
    const metaData = data?.metadata || {};
    if (type === 'NEW_JUNCTURE') {
      navigate(isClient() ? '/app/dashboard' : '/app/clients');
    } else if (type === 'New_ANNOUNCEMENT' || type === 'MANAGEMENT_FEEDBACK') {
      navigate('/app/dashboard');
    } else if (type === 'ISSUE_STATUS_UPDATE') {
      navigate('/app/issues');
    } else if (type === 'JOB_COMPLETED') {
      navigate('/app/jobs');
    } else if (type === 'JOB') {
      let jobsData = {
        pageFilter: { pageNumber: 1, pageSize: 15 },
        firstTimeRender: true,
      };
      setLocaleStorageVal('JobsData', jobsData);
      let state = metaData?.jobId
        ? { selectedJob: metaData?.jobId, selectedTab: '' }
        : '';
      if (pathname === '/app/jobs') state.shouldStateIncludeJob = true;
      navigate('/app/jobs', { state: state });
    } else if (type === 'JOB_INTERNAL_NOTES' || type === 'JOB_NOTES') {
      const selectedTab = type === 'JOB_NOTES' ? 'notes' : 'internalNotes';
      let jobsData = {
        pageFilter: { pageNumber: 1, pageSize: 15 },
        firstTimeRender: true,
      };
      setLocaleStorageVal('JobsData', jobsData);
      let state = metaData?.jobId
        ? { selectedJob: metaData?.jobId, selectedTab: selectedTab }
        : '';
      if (pathname === '/app/jobs') state.shouldStateIncludeJob = true;
      navigate('/app/jobs', { state: state });
    } else if (type === 'ISSUE') {
      let issuesData = { pageFilter: { pageNumber: 1, pageSize: 15 } };
      setLocaleStorageVal('IssuesData', issuesData);
      let state = metaData?.issueId
        ? { selectedIssue: metaData?.issueId, selectedTab: '' }
        : '';
      if (pathname === '/app/issues') state.shouldStateInclude = true;
      navigate('/app/issues', { state: state });
    } else if (type === 'ISSUE_INTERNAL_NOTES' || type === 'ISSUE_NOTES') {
      let issuesData = { pageFilter: { pageNumber: 1, pageSize: 15 } };
      setLocaleStorageVal('IssuesData', issuesData);
      const selectedTab = type === 'ISSUE_NOTES' ? 'notes' : 'internal-notes';
      let state = metaData?.issueId
        ? { selectedIssue: metaData?.issueId, selectedTab: selectedTab }
        : '';
      if (pathname === '/app/issues') state.shouldStateInclude = true;
      navigate('/app/issues', { state: state });
    } else if (type === 'Arrivals and Departures') {
      navigate('/app/clients/view/property', {
        state: {
          programType: metaData?.programType,
          properties: metaData?.properties,
          selectedProperty: metaData?.propertyId,
          activeTab: 'arrivals-departures',
        },
      });
    }
    props.handleClose();
  };

  const onClickDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    props?.deleteNotification(id, onError, getNotifications);
  };

  const getNotifications = () => {
    props.fetchNotificationList((err) => onError(err));
  };

  const onClickClear = (e) => {
    e.preventDefault();
    props?.clearNotifications(onError, getNotifications);
  };

  return (
    <Menu
      id='basic-menu'
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={5}
      keepMounted
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          marginTop: 10,
        },
      }}
      classes={{
        paper: classes.root_dialog_paper_class,
        list: classes.dialog_list,
      }}
    >
      {props.notificationListLoading ||
      props.clearNotificationLoading ||
      props.deleteNotificationLoading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
            size={20}
          />
        </div>
      ) : props.notificationListData.length === 0 ? (
        <div className={classes.flexView}>
          <label
            className={classes.no_notification_txt}
            htmlFor='Notifications'
          >
            {Strings.NO_NOTIFICATION}
          </label>
        </div>
      ) : (
        <div className={classes.notification_container}>
          <div className={classes.notification_label_container}>
            <label
              className={classes.notification_label}
              htmlFor='Notifications'
            >
              {Strings.NOTIFICATION + 's'}
            </label>
            <label
              className={classes.clear_label}
              htmlFor='Clear'
              onClick={onClickClear}
            >
              {Strings.CLEAR}
            </label>
          </div>
          <div className={classes.notification_list_container}>
            {props.notificationListData &&
              props.notificationListData.length > 0 &&
              props.notificationListData.map((item) => (
                <MenuItem
                  style={{ padding: '8px 10px', whiteSpace: 'normal' }}
                  onClick={() => onMessageClick(item?.type, item)}
                >
                  <SingleNotification
                    data={item}
                    onClickDelete={onClickDelete}
                  />
                </MenuItem>
              ))}
          </div>
        </div>
      )}
    </Menu>
  );
};

const NotificationBadge = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    store.dispatch(setNotificationCount({ count: 0 }));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.bell_icon_container} onClick={handleClick}>
        <img
          src={icons().bellIcon}
          className={classes.bell_icon}
          alt=''
          style={{ cursor: 'pointer' }}
        />
        {props.count > 0 && (
          <div style={{ cursor: 'pointer' }} className={classes.bel_count}>
            {props.count > 9 ? '9+' : props.count}
          </div>
        )}
      </div>
      <NotificationDialog
        anchorEl={anchorEl}
        handleClose={() => handleClose()}
        fetchNotificationList={props.fetchNotificationList}
        notificationListData={props.notificationListData}
        notificationListLoading={props.notificationListLoading}
        clearNotifications={props?.clearNotifications}
        clearNotificationLoading={props?.clearNotificationLoading}
        deleteNotification={props?.deleteNotification}
        deleteNotificationLoading={props?.deleteNotificationLoading}
      />
    </>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 25,
  height: 13,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: `0 2px 4px 0 ${Colors.SWITCH_THUMB}`,
    width: 9,
    height: 9,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: Colors.WHITE,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.switch_background,
    boxSizing: 'border-box',
    boxShadow:
      '0px 0px 4px rgba(59, 104, 255, 0.1), inset 0px 0px 3px rgba(59, 104, 255, 0.38)',
  },
}));

const ThemeButton = (props) => {
  const { classes, value, onChange } = props;
  return (
    <div className={classes.theme_box}>
      <label className={classes.theme_label}>
        {!value ? Strings.DARK_MODE : Strings.LIGHT_MODE}
      </label>
      <AntSwitch
        checked={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'ant design' }}
      />
    </div>
  );
};

function Navbar(props) {
  var themeDispatch = useThemeDispatch();

  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [logoutDialog, setLogoutDialog] = useState(false);
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let { userId, jobId, paramJobId } = useParams();
  const userName = localStorage.getItem('userName');
  const lastName = localStorage.getItem('lastName');
  const userProfile = localStorage.getItem('profilePicture');
  const decoded = jwtDecode(localStorage.getItem('jwtToken'));
  // const splitNavbarTitle = pathname?.split('app/')[1]?.split('/')[0];
  const splitNavbar = pathname?.substr(pathname?.lastIndexOf('/') + 1);
  const email = localStorage.getItem('clientAdminEmail');

  const alert = useAlert();
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getTitle = () => {
    if (pathname === '/app/clients/programs') return Strings.CLIENTS;
    else if (
      pathname === '/app/jobs/forms' ||
      pathname === '/app/jobs/forms/categories'
    )
      return Strings.JOBS;
    else if (
      pathname === '/app/clients/add/property' ||
      pathname === '/app/clients/view/property' ||
      pathname === '/app/clients/edit/property'
    ) {
      return Strings.CLIENT_PROPERTY;
    } else if (pathname === '/app/property') return Strings.PROPERTY_DETAILS;
    else if (pathname === '/app/chat' || pathname === `/app/chat/${userId}`)
      return Strings.CHAT;
    else if (pathname === '/app/dashboard/arrivals-departures')
      return Strings.ARRIVALS_AND_DEPARTURES;
    else if (pathname === '/app/vendor/categories') return Strings.VENDOR;
    else if (paramJobId && pathname === `/app/report/${paramJobId}`)
      return Strings.REPORT;
    else if (pathname === `/app/jobs/job-series`) return Strings.JOB_SERIES;
    else if (pathname === `/app/issues`) return Strings.ISSUES;
    else {
      const title =
        splitNavbar === 'dashboard' || !splitNavbar
          ? `${Strings.WELCOME_BACK} ${userName}`
          : splitNavbar?.charAt(0).toUpperCase() + splitNavbar?.slice(1);

      return title;
    }
  };

  const navbarTitle = getTitle();

  // const OneSignal = window.OneSignal;

  useEffect(() => {
    props.fetchNotificationCount((err) => onError(err));
    OneSignal.on('notificationDisplay', function (event) {
      props.fetchNotificationCount((err) => onError(err));
    });
  }, []);

  return (
    <div className={classes.navbar_container}>
      <Hidden mdUp>
        <img src={icons().logo} alt='' className={classes.navbar_logo} />
        <div style={{ display: 'flex' }}>
          {isClient() && pathname !== '/app/dashboard' && (
            <div className={classes.emailBtnContainer}>
              <Button
                text={Strings.EMAIL_MY_ADMIN}
                onClick={() => {
                  window.location.href = `mailto:${email}`;
                }}
              />
            </div>
          )}
          <NotificationBadge
            classes={classes}
            fetchNotificationList={props.fetchNotificationList}
            count={props.notificationCountData?.count || 0}
            notificationListData={props.notificationListData}
            notificationListLoading={props.notificationListLoading}
            clearNotifications={props?.clearNotifications}
            clearNotificationLoading={props?.clearNotificationLoading}
            deleteNotification={props?.deleteNotification}
            deleteNotificationLoading={props?.deleteNotificationLoading}
          />
          <img
            onClick={() => toggleSidebar(layoutDispatch)}
            src={icons().menuIcon}
            alt=''
            className={classes.menu_logo}
          />
        </div>
      </Hidden>
      <Hidden mdDown>
        <div
          className={classNames(
            classes.navbar_left_container,
            splitNavbar !== 'dashboard' && classes.navbar_left_other_container
          )}
        >
          <div className={classes.navbar_left_inner_container}>
            <NavbarTitle classes={classes} title={navbarTitle} />
            {/* {splitNavbar === 'dashboard' && (
              <Searchbox
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )} */}
          </div>
        </div>
        <div
          className={classNames(
            classes.navbar_right_container,
            splitNavbar !== 'dashboard' && classes.navbar_other_right_container
          )}
        >
          <div className={classes.navbar_right_inner_container}>
            {isClient() && pathname !== '/app/dashboard' && (
              <div className={classes.emailBtnContainer}>
                <Button
                  className={classes.email_button}
                  text={Strings.EMAIL_MY_ADMIN}
                  onClick={() => {
                    window.location.href = `mailto:${email}`;
                  }}
                />
              </div>
            )}
            <ThemeButton
              classes={classes}
              value={theme.palette.mode === 'light'}
              onChange={() => toggleTheme(themeDispatch)}
            />
            <ProfileBox
              name={lastName}
              role={decoded.role}
              profileImage={userProfile}
            />
            <NotificationBadge
              count={props.notificationCountData?.count || 0}
              classes={classes}
              fetchNotificationList={props.fetchNotificationList}
              notificationListData={props.notificationListData}
              notificationListLoading={props.notificationListLoading}
              clearNotifications={props?.clearNotifications}
              clearNotificationLoading={props?.clearNotificationLoading}
              deleteNotification={props?.deleteNotification}
              deleteNotificationLoading={props?.deleteNotificationLoading}
            />
            <img
              src={icons(theme).logoutIcon}
              alt=''
              className={classes.logout_icon}
              onClick={() => setLogoutDialog(true)}
            />
          </div>
        </div>
      </Hidden>
      {logoutDialog && (
        <CommonDialog
          title={Strings.LOGOUT}
          message={Strings.SURE_LOGOUT}
          onSuccess={() => store.dispatch(logout(navigate))}
          onClose={() => setLogoutDialog(false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  notificationCountData: state.notificationCountData.data,
  notificationListData: state.notificationListData.data,
  notificationListLoading: state.notificationListData.loading,
  clearNotificationLoading: state.clearNotificationsData.loading,
  deleteNotificationLoading: state.deleteNotificationData.loading,
});

export default connect(mapStateToProps, {
  fetchNotificationCount,
  fetchNotificationList,
  clearNotifications,
  deleteNotification,
})(Navbar);
