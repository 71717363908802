import axios from 'axios';
import { UPLOAD_FOLDER_MEDIA } from '../../constants';

export const uploadFolderMedia =
  (data, onFileUpload, onError) => (dispatch) => {
    const bodyFormData = new FormData();
    for (let i = 0; i < data.files.length; i++) {
      bodyFormData.append('attachment', data.files[i]);
    }
    bodyFormData.append('folderId', data.folderId);
    const token = localStorage.jwtToken;
    dispatch(addUploadFolderMedia());
    axios({
      method: 'post',
      url: `/media/folder/upload`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (onFileUpload !== '') {
          onFileUpload(res.data.data);
        }
        dispatch(setUploadFolderMedia(res));
      })
      .catch((err) => {
        dispatch(errorUploadFolderMedia(err.response));
        onError(err.response);
      });
  };

export const addUploadFolderMedia = () => ({
  type: UPLOAD_FOLDER_MEDIA.LOAD,
});

export const setUploadFolderMedia = (data) => ({
  type: UPLOAD_FOLDER_MEDIA.SUCCESS,
  payload: { file: data.data.data },
});

export const errorUploadFolderMedia = (error) => ({
  type: UPLOAD_FOLDER_MEDIA.FAIL,
  payload: error,
});
