import React, { useEffect, useState, useRef } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import NewFormHeader from '../NewFormHeader';
import { Strings } from '../../utils/strings';
import Textbox from '../Textbox';
import Button from '../Button';
import { UploadFileWithTagsSchema } from '../../utils/validationSchemas';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import { getErrorMessage } from '../../utils/appUtils';
import { CircularProgress } from '@mui/material';
import { icons } from '../../utils/icons';
import { fetchTagsList } from '../../redux/actions/mediaActions/tagsList';
import { createTag } from '../../redux/actions/mediaActions/createTag';
import { deleteTag } from '../../redux/actions/mediaActions/deleteTag';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { uploadFileForReport } from '../../redux/actions/jobActions/reportActions/uploadFileForReport';

const UploadFileWithTagsDialog = (props) => {
  const classes = useStyles();
  const inputFile = useRef(null);

  const theme = useTheme();
  const alert = useAlert();
  const [tagsList, setTagsList] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props?.selectedIndexDataForMedia?.isReplace) {
      getTagList();
    }
  }, []);

  const getTagList = () => {
    props.fetchTagsList(onError);
  };

  useEffect(() => {
    if (props?.tagsListData.length > 0) {
      const newList = props?.tagsListData?.map((item) => {
        const tempData = {
          title: item.name,
          value: item._id,
        };
        return tempData;
      });
      setTagsList(newList);
    }
  }, [props.tagsListData]);

  const handleFileUpload = (files) => {
    if (props?.selectedIndexDataForMedia?.isReplace) {
      setSelectedFile([...files.target.files]);
    } else {
      let arr = [...files.target.files];
      setSelectedFile([...selectedFile, ...arr]);
    }
    document.getElementById('fileInput').value = '';
  };

  const onClickUpload = async () => {
    let tags;
    if (props?.selectedIndexDataForMedia?.isReplace) {
      tags = props?.selectedIndexDataForMedia?.tags || [];
    } else {
      tags = values.selectedTags.map((item) => item.title) || [];
    }
    const data = {
      files: selectedFile,
      reportId: props.reportId,
      tags: JSON.stringify(tags),
    };
    await props.uploadFileForReport(
      data,
      props.setSelectedNewMedia,
      props.onClose,
      onError
    );
  };

  const onClickRemoveItem = (index) => {
    let newArr = selectedFile.filter((item, ind) => ind !== index);
    setSelectedFile(newArr);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedTags: [],
    },
    validationSchema: UploadFileWithTagsSchema,
    onSubmit: (values) => {
      onClickUpload(values);
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={
              props?.selectedIndexDataForMedia?.isReplace
                ? Strings.REPLACE_MEDIA
                : Strings.NEW_MEDIA
            }
            onNewClose={() => {
              props.onClose();
            }}
          />
          {props.tagsListLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{
                  color: theme.palette.background_button,
                }}
              />
            </div>
          ) : (
            <div className={classes.form_container}>
              <FormikProvider
                value={formik}
                style={{ display: 'flex', height: '100%' }}
              >
                <Form
                  noValidate
                  autoComplete='off'
                  onSubmit={handleSubmit}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {!props?.selectedIndexDataForMedia?.isReplace && (
                    <MultiSelectDropdown
                      name='selectedTags'
                      onChange={(value) => {
                        setError(false);
                        setFieldValue('selectedTags', value);
                      }}
                      value={values.selectedTags}
                      error={Boolean(
                        touched.selectedTags && errors.selectedTags
                      )}
                      helperText={touched.selectedTags && errors.selectedTags}
                      title={Strings.SEARCH_AND_SELECT_TAGS}
                      labelClassName={classes.form_label}
                      optionArray={tagsList}
                      hideAvatar={true}
                    />
                  )}
                  {error && (
                    <span style={{ color: 'red', fontSize: 12 }}>
                      {'Please add tags'}
                    </span>
                  )}
                  <div
                    className={classes.add_new_media}
                    onClick={() => {
                      // if (
                      //   values.selectedTags.length > 0 ||
                      //   props?.selectedIndexDataForMedia?.isReplace
                      // ) {
                      //   setError(false);
                      //   inputFile.current.click();
                      // } else {
                      //   setError(true);
                      // }
                      inputFile.current.click();
                    }}
                  >
                    <input
                      id='fileInput'
                      style={{ display: 'none' }}
                      ref={inputFile}
                      onChange={(event) => handleFileUpload(event)}
                      type='file'
                      accept='*'
                      multiple={!props?.selectedIndexDataForMedia?.isReplace}
                    />
                    <img
                      src={icons(theme).plusIcon}
                      alt=''
                      className={classes.plus_icon}
                      crossOrigin='Anonymous'
                    />
                    <span>
                      {props?.selectedIndexDataForMedia?.isReplace
                        ? 'Change Media'
                        : 'Add Media'}
                    </span>
                  </div>
                  {selectedFile.length > 0 && (
                    <div className={classes.media_data}>
                      {selectedFile.map((item, index) => (
                        <div className={classes.flex_row}>
                          <Textbox
                            value={item.name}
                            readOnly={true}
                            disabled={true}
                            containerClassName={classes.marginBottom}
                          />
                          {!props?.selectedIndexDataForMedia?.isReplace && (
                            <img
                              className={classes.close_icon}
                              src={icons(theme).closeIcon}
                              alt=''
                              onClick={() => onClickRemoveItem(index)}
                            />
                          )}
                        </div>
                      ))}

                      <Button
                        type='submit'
                        text={Strings.UPLOAD}
                        containerClassName={classes.add_button_container}
                        className={classes.add_button}
                        loaderColor={theme.palette.background_button_text}
                        loading={props.uploadFileForReportLoading}
                      />
                    </div>
                  )}
                </Form>
              </FormikProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tagsListData: state.tagsListData.data,
  tagsListLoading: state.tagsListData.loading,
  createTagData: state.createTagData.data,
  createTagLoading: state.createTagData.loading,
  deleteTagLoading: state.deleteTagData.loading,
  uploadFileForReportLoading: state.uploadFileForReportData.loading,
});

export default connect(mapStateToProps, {
  fetchTagsList,
  createTag,
  deleteTag,
  uploadFileForReport,
})(UploadFileWithTagsDialog);
