export const Colors = {
  BLACK: '#000000',
  LIGHT_GRAY: '#E5E5E5',
  DARK_GRAY: '#58585A',
  NEVY_BLUE: '#2D3D95',
  LIGHT_BLACK: '#242424',
  RED: '#EA4335',
  WHITE: '#FFFFFF',
  BLUE: '#36417a',
  LIGHT_BLUE: '#0088CC',
  THIN_BLUE: 'rgba(217, 228, 251, 0.12)',
  THIN_BORDER: '#D9E4FB',
  SIDEBAR_BLUE: '#F8FAFF',
  TOMATO_RED: '#ED4242',
  DARK: '#171923',
  THEME_BLACK: '#0D0D0D',
  GRAY: '#8E8F91',
  THEME_DARK: '#343434',
  EXTRA_GRAY: 'rgba(0, 0, 0, 0.2)',
  PLACEHOLDER_COLOR: '#9B9B9C',
  SECONDARY_PLACEHOLDER_COLOR: 'rgba(0, 136, 204, 0.5)',
  TABLE_HEAD_BLUE: '#F2F6FF',
  TABLE_HEAD_BLACK: '#242425',
  DARK_PLACEHOLDER: '#A9ABAE',
  TABLE_ROW_BLACK: 'rgba(248, 250, 255, 0.12)',
  THEME_SEARCH_BORDER: 'rgba(248, 250, 255, 0.5)',
  LIGHT_GREEN: '#35B891',
  EXTRA_LIGHT_NEVY_BLUE: '#C0C5DF',
  SECONDARY_BORDER: '#C0C0C0',
  MODAL_SHADOW_BLUE: 'rgba(2, 83, 168, 0.16)',
  MODAL_SHADOW_WHITE: 'rgba(255, 255, 255, 0.2)',
  DROP_IMAGE_PURPLE: '#E8EDF9',
  LIGHT_ORANGE: '#FB7B04',
  GROUP_FORM_BLUE: '#EDF2F9',
  TAG_BLUE: 'rgba(59, 104, 255, 0.35)',
  THIN_GRAY: '#98A9BC',
  THIN_WHITE: '#d4d5d6',
  CHAT_THIN_BORDER: '#F2F4F6',
  CHAT_FILE_LIGHT_BACKGROUND: 'rgba(40, 40, 40,0.03)',
  CHAT_FILE_DARK_BACKGROUND: 'rgba(255, 255, 255,0.03)',
  CHAT_PROFILE_BACKGROUND:
    'linear-gradient(91.11deg, #F8FAFF 2.89%, rgba(248, 250, 255, 0) 79.1%)',
  STATUS_GRAY: '#C4C4C4',
  STATUS_GREEN: '#34A885',
  STATUS_YELLOW: '#FBBC04',
  TEXT_PURPLE: '#2F2BFF',
  EVENT_GRAY: '#6D6D6D',
  NOT_STARTED_EVENT:
    'linear-gradient(0deg, rgba(109, 109, 109, 0.08), rgba(109, 109, 109, 0.08)), #FFFFFF',
  PENDING_EVENT:
    'linear-gradient(0deg, rgba(255, 171, 43, 0.1), rgba(255, 171, 43, 0.1)), #FFFFFF',
  COMPLETE_EVENT:
    'linear-gradient(0deg, rgba(77, 124, 254, 0.08), rgba(77, 124, 254, 0.08)), #FFFFFF',
  INPROGRESS_EVENT:
    'linear-gradient(0deg, rgba(77, 124, 254, 0.08), rgba(77, 124, 254, 0.08)), #FFFFFF',
  OTHER_EVENT:
    'linear-gradient(0deg, rgba(47, 43, 255, 0.1), rgba(47, 43, 255, 0.1)), #FFFFFF',
  SIDEBAR_SHADOW: '8px 12px 36px rgba(35, 52, 90, 0.25)',
  SIDEBAR_BORDER: '#D8D8D8',
  SECONDARY_TITLE: '#A8A9AC',
  EXTRA_DARK: '#272833',
  SEND_REPORT_COLOR: 'rgba(0, 136, 204, 0.04)',
  DARK_INPUT_BORDER: 'rgba(229, 229, 229, 0.5)',
  SWITCH_THUMB: 'rgb(0, 35, 11, 0.2)',
  TRANSPARENT: 'rgb(0,0,0,0.7)',
  STATUS_PINK: '#FFC0CB',
  STATUS_PURPLE: '#A020F0',
  STATUS_GREENISH_BLUE: '#0D98BA',
  STATUS_LIGHT_PURPLE: '#CA8DFD',
  ARRIVAL_EVENT:
    'linear-gradient(0deg, rgba(0, 136, 204, 0.1), rgba(0, 136, 204, 0.1)), #FFFFFF',
  DEPARTURE_EVENT:
    'linear-gradient(0deg, rgba(237, 66, 66, 0.1), rgba(237, 66, 66, 0.1)), #FFFFFF',
  ORANGE: '#ffa837',
  GREEN: '#49663f',
  DARK_RED: '#a01720',
  DARK_ORANGE: '#FF4D00',
  DARK_GRAY: '#666666',
  BABY_BLUE: '#89CFF0',
};
