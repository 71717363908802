import axios from 'axios';
import { DELETE_JOB_FORM } from '../../../constants';

export const deleteJobForm = (fID, callback, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteJobForm());
  axios
    .delete(`/jobs/form/delete?formId=${fID}`, config)
    .then((res) => {
      dispatch(setDeleteJobForm(res));
      callback?.();
    })
    .catch((err) => {
      dispatch(errorDeleteJobForm(err.response));
      onError(err.response);
    });
};

export const loadDeleteJobForm = () => ({
  type: DELETE_JOB_FORM.LOAD,
});

export const setDeleteJobForm = (data) => ({
  type: DELETE_JOB_FORM.SUCCESS,
  payload: data,
});

export const errorDeleteJobForm = (error) => ({
  type: DELETE_JOB_FORM.FAIL,
  payload: error,
});
