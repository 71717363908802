import axios from 'axios';
import { DOWNLOAD_SUPPLIES } from '../../constants';
import moment from 'moment';

export const downloadSupplies = (onResponse, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  };
  dispatch(loadDownloadSupplies());
  axios
    .get(
      `/admin/downloadSupplies?currentDate=${moment().format(
        'YYYY-MM-DD'
      )}&timeZone=${moment.tz.guess()}`,
      config
    )
    .then((res) => {
      onResponse(res?.data);
      dispatch(setDownloadSupplies(res));
    })
    .catch((err) => {
      dispatch(errorDownloadSupplies(err.response));
      onError(err.response);
    });
};

export const loadDownloadSupplies = () => ({
  type: DOWNLOAD_SUPPLIES.LOAD,
});

export const setDownloadSupplies = (reportData) => ({
  type: DOWNLOAD_SUPPLIES.SUCCESS,
  payload: reportData.data.data,
});

export const errorDownloadSupplies = (error) => ({
  type: DOWNLOAD_SUPPLIES.FAIL,
  payload: error,
});
