import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  calendar_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 115px);',
    display: 'flex',
    overflow: 'auto',
    padding: '20px 18px',
    backgroundColor: theme.palette.background,
  },
  header_container: {
    width: '100%',
  },
  header_top_container: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    // marginBottom: 17,
    gap: 10,
  },
  time_period_container: {
    display: 'flex',
  },
  time_period_button_container: {
    marginRight: 12,
  },
  time_period_button: {
    border: `1px solid transparent`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    fontWeight: 700,
    fontSize: 12,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '8px 12px',
    height: 30,
  },
  selected_time_period_button: {
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
  },
  date_action_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 275,
  },
  event_container: {
    height: '100%',
    padding: '5px 5px 5px 10px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  notStarted_event: {
    background: Colors.NOT_STARTED_EVENT,
    borderLeft: `2px solid ${Colors.EVENT_GRAY}`,
    color: Colors.EVENT_GRAY,
  },
  arrival_event: {
    background: Colors.ARRIVAL_EVENT,
    borderLeft: `2px solid ${Colors.LIGHT_BLUE}`,
    color: Colors.LIGHT_BLUE,
  },
  departure_event: {
    background: Colors.DEPARTURE_EVENT,
    borderLeft: `2px solid ${Colors.RED}`,
    color: Colors.RED,
  },
  event_subTitle_text: {
    fontSize: 12,
    fontWeight: 400,
  },
  event_text_overflow: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  event_title_text: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
  },
  common_event_icon: {
    height: 16,
    width: 16,
  },
  new_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '8px 12px',
    height: 30,
    width: 90,
  },
}));
