import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  job_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      width: '100%',
      height: 'calc(100% - 95px)',
    },
  },
  job_container_clientLogin: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '0px 18px 10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      width: '100%',
      height: 'calc(100% - 95px)',
    },
  },
  add_job_btn_container: {
    marginLeft: 16,
    width: 108,
  },
  add_job_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  list_container: {
    width: '100%',
    margin: '15px 0px 30px 0',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit',
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'fit-content',
      margin: '15px 0px',
    },
  },
  list_container_client_job: {
    width: '100%',
    margin: '30px 0px',
  },
  name_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 600,
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  // toggle_arrow: {
  //   width: 24,
  //   height: 24,
  //   marginRight: 15,
  //   cursor: 'pointer',
  // },
  action_icons: {
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  status_container: {
    backgroundColor: Colors.NEVY_BLUE,
    padding: '3px 12px;',
    borderRadius: 40,
    color: Colors.WHITE,
    width: 'max-content',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  action_visible: {
    display: 'none',
  },
  row_style: {
    '&:hover': {
      '& $action_visible': {
        display: 'block',
      },
    },
  },
  title_container: {
    marginTop: 10,
    marginBottom: 15,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 14px',
    },
  },
  toolbox_container: {
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '0px 14px',
      width: 'auto !important',
    },
  },
  toolbox_container_clientLogin: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '0px 14px',
      width: 'auto !important',
      flex: 0,
    },
  },
  dateContainerClass: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      width: '100%',
      marginLeft: 0,
    },
  },
  start_date_container: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  list_table: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 930,
    },
  },
  tab_container: {
    paddingLeft: 14,
  },
  report_action_stack: {
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    marginRight: 15,
    cursor: 'pointer',
  },
  job_details_container: {
    marginTop: 0,
  },
  job_details_tab: {
    color: theme.palette.background_button,
  },
  note_data: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.profile_title,
    padding: '10px 0px 0px 0px',
    marginTop: 5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    marginBottom: 0,
    '& a': {
      color: theme.palette.background_button,
    },
  },
  note_details_container: {
    display: 'flex',
    // flexDirection: 'column',
    marginTop: 10,
    borderBottom: `1px solid ${theme.palette.background_border}`,
    gap: 15,
  },
  report_button: {
    fontSize: 12,
    height: 25,
    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  note_text_container: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBoxContainer: {
    flex: 1,
    background: 'red',
  },
  redDot: {
    minHeight: 10,
    minWidth: 10,
    borderRadius: '100%',
    background: Colors.RED,
  },
  filteredTitleText: {
    color: theme.palette.background_button,
    fontSize: 12,
    fontWeight: 600,
    margin: '10px 0 0 0',
    textTransform: 'uppercase'
  }
}));
