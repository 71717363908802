import axios from 'axios';
import { GET_CLIENT_BY_ID } from '../../constants';

export const fetchClientById = (cId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientById());

  axios
    .get(`/client/getClientById/${cId}`, config)
    .then((res) => dispatch(setClientById(res)))
    .catch((err) => {
      dispatch(errorClientById(err.response));
      onError(err.response);
    });
};

export const loadClientById = () => ({
  type: GET_CLIENT_BY_ID.LOAD,
});

export const setClientById = (clientList) => ({
  type: GET_CLIENT_BY_ID.SUCCESS,
  payload: clientList.data.data,
});

export const errorClientById = (error) => ({
  type: GET_CLIENT_BY_ID.FAIL,
  payload: error,
});
