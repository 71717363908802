import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  calendar_container: {
    width: '100%',
    height: 'calc(100% - 81px);',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.background,
  },
  calendar_left_container: {
    width: 'calc(80% - 36px)',
    height: 'calc(100% - 40px)',
    padding: '20px 18px',
    backgroundColor: theme.palette.background,
    overflow: 'auto',
    transition: 'width 1.5s ease-in-out',
  },
  calendar_left_container_full: {
    width: '100%',
    height: 'calc(100% - 40px)',
    padding: '20px 18px',
    backgroundColor: theme.palette.background,
    overflow: 'auto',
    transition: 'width 0.8s ease-in-out',
  },
  calendar_right_container: {
    width: 'calc(20% - 40px)',
    height: 'calc(100% - 40px)',
    padding: '20px 12px',
    border:
      theme.palette.mode === 'dark'
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    transition:
      'opacity 2s ease-in, width 1.5s ease-in-out, padding 1.5s linear',
    opacity: 1,
  },
  calendar_right_container_hide: {
    transition: 'width 1.5s ease-in-out',
    height: 'calc(100% - 40px)',
    padding: '20px 12px',
    border:
      theme.palette.mode === 'dark'
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    width: 0,
    padding: 0,
  },
  technician: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  filter_label: {
    fontSize: 12,
    marginTop: 8,
  },
  searchbox_container: {
    width: '100%',
    marginTop: 15,
  },
  searchbox: {
    backgroundColor: theme.palette.background,
  },
  technician_list_container: {
    marginTop: 20,
  },
  checkbox_container: {
    minWidth: 22,
    minHeight: 22,
    maxWidth: 22,
    maxHeight: 22,
    backgroundColor: theme.palette.background,
    border: `1px solid ${theme.palette.checkbox_border}`,
  },
  labelClassName: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.profile_title,
    marginLeft: 10,
  },
  checkbox_margin: {
    marginTop: 10,
  },
  header_container: {
    width: '100%',
    marginBottom: 10,
  },
  header_top_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  time_period_container: {
    display: 'flex',
  },
  time_period_button: {
    border: `1px solid transparent`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    fontWeight: 700,
    fontSize: 12,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '8px 12px',
    height: 30,
  },
  selected_time_period_button: {
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
  },
  time_period_button_container: {
    // marginRight: 12,
  },
  new_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '8px 12px',
    height: 30,
    width: 90,
  },
  header_bottom_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 17,
    marginBottom: 17,
  },
  filter_container: {
    // marginLeft: 25,
    display: 'flex',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.search_text,
    alignItems: 'center',
  },
  filter_main_container: {
    display: 'flex',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.search_text,
    alignItems: 'center',
    marginLeft: 12,
  },
  filter_icon: {
    marginLeft: 10,
    cursor: 'pointer',
    height: 24,
    width: 24,
  },
  refresh_icon: {
    marginLeft: 10,
    cursor: 'pointer',
    height: 26,
    width: 26,
  },
  status_container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.search_text,
  },
  status_indicator: {
    width: 13,
    height: 13,
    borderRadius: '50%',
    marginRight: 5,
  },
  date_action_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 275,
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
    },
  },
  date_text: {
    fontSize: 14,
    fontWeight: 400,
  },
  month_day_text: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.profile_title,
    cursor: 'pointer',
  },
  event_outer_container: {
    height: '100%',
    display: 'grid',
    padding: '5px 5px 5px 10px',
    overflow: 'hidden',
    alignContent: 'stretch',
    color: theme.palette.title_text,
  },
  event_container: {
    height: '100%',
    // padding: '5px 5px 5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // overflow: 'auto',
    color: theme.palette.title_text,
  },
  notStarted_event: {
    background: Colors.NOT_STARTED_EVENT,
    borderLeft: `2px solid ${Colors.EVENT_GRAY}`,
    color: Colors.EVENT_GRAY,
  },
  inProgress_event: {
    background: Colors.INPROGRESS_EVENT,
    borderLeft: `2px solid ${Colors.NEVY_BLUE}`,
    color: Colors.NEVY_BLUE,
  },
  complete_event: {
    background: Colors.COMPLETE_EVENT,
    borderLeft: `2px solid ${Colors.STATUS_GREEN}`,
    color: Colors.STATUS_GREEN,
  },
  other_event: {
    background: Colors.OTHER_EVENT,
    borderLeft: `2px solid ${Colors.TEXT_PURPLE}`,
    color: Colors.TEXT_PURPLE,
  },
  // pending_event: {
  //   background: Colors.PENDING_EVENT,
  //   borderLeft: `2px solid ${Colors.STATUS_YELLOW}`,
  //   color: Colors.STATUS_YELLOW,
  // },
  event_title_text: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
  },
  event_subTitle_text: {
    fontSize: 12,
    fontWeight: 400,
  },
  event_text_overflow: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  userProfile_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 15,
    gap: 8,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  checked_checkbox: {
    backgroundColor: `${theme.palette.background_button} !important`,
  },
  toggle_btn: {
    marginBottom: 12,
  },
  today_newJob_btncontainer: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'end',
    gridAutoFlow: 'column',
    columnGap: 15,
    [theme.breakpoints.down('lg')]: {
      columnGap: 10,
    },
  },
}));
