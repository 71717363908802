import axios from 'axios';
import { UPLOAD_PROPERTY_MEDIA } from '../../constants';

export const uploadPropertyMedia = (data, onError) => (dispatch) => {
  const bodyFormData = new FormData();
  for (let i = 0; i < data.files.length; i++) {
    bodyFormData.append('attachments', data.files[i]);
  }
  bodyFormData.append('client', data.client);
  bodyFormData.append('property', data.property);
  const token = localStorage.jwtToken;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(loadUploadPropertyMedia());
  axios
    .post(`/client/property/saveMedia`, bodyFormData, config)
    .then((res) => {
      dispatch(setUploadPropertyMedia(res.data.data));
    })
    .catch((err) => {
      dispatch(errorUploadPropertyMedia(err.response));
      onError(err.response);
    });
};

export const loadUploadPropertyMedia = () => ({
  type: UPLOAD_PROPERTY_MEDIA.LOAD,
});

export const setUploadPropertyMedia = (data) => ({
  type: UPLOAD_PROPERTY_MEDIA.SUCCESS,
  payload: data,
});

export const errorUploadPropertyMedia = (error) => ({
  type: UPLOAD_PROPERTY_MEDIA.FAIL,
  payload: error,
});
