import axios from 'axios';
import { DELETE_MEDIA } from '../../constants';

export const deleteMedia =
  (fileId, folderId, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteMedia());

    axios
      .delete(`/media/delete?folderId=${folderId}&fileId=${fileId}`, config)
      .then((res) => {
        onSuccess?.();
        dispatch(setDeleteMedia(res.data.data));
      })
      .catch((err) => {
        dispatch(errorDeleteMedia(err.response));
        onError(err.response);
      });
  };

export const loadDeleteMedia = () => ({
  type: DELETE_MEDIA.LOAD,
});

export const setDeleteMedia = (data) => ({
  type: DELETE_MEDIA.SUCCESS,
  payload: data,
});

export const errorDeleteMedia = (error) => ({
  type: DELETE_MEDIA.FAIL,
  payload: error,
});
