import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { Colors } from '../../utils/color';

export default function Button(props) {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        !props.noContainerClass && classes.button_container,
        props.containerClassName && props.containerClassName
      )}
      style={props.style}
    >
      <button
        type={props.type ? props.type : 'button'}
        className={classNames(
          !props.noClass && classes.custom_button,
          props.className && props.className
        )}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.loading ? (
          <div style={{ placeSelf: 'center'}}> 
          <CircularProgress
            style={{
              color: props.loaderColor ? props.loaderColor : Colors.WHITE,
              margin: '0 auto !important',
            }}
            size={props.loaderSize ? props.loaderSize : 20}
          />
          </div>
        ) : (
          <div className={classes.button_text_container}>
            {props.leftIcon && (
              <img
                className={classNames(
                  classes.leftIconStyle,
                  props.iconClassName && props.iconClassName
                )}
                src={props.leftIcon}
                alt=''
              />
            )}
            {props.text}
            {props.icon && (
              <img
                className={classNames(
                  props.iconClassName && props.iconClassName
                )}
                src={props.icon}
                alt=''
              />
            )}
          </div>
        )}
      </button>
    </div>
  );
}
