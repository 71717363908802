import axios from 'axios';
import { ADD_PROGRAM } from '../../constants';

export const createProgram = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addProgram());
  axios
    .post(`/client/createProgram`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAddProgram(res));
    })
    .catch((err) => {
      dispatch(errorAddProgram(err.response));
      onError(err.response);
    });
};

export const addProgram = () => ({
  type: ADD_PROGRAM.LOAD,
});

export const setAddProgram = (data) => ({
  type: ADD_PROGRAM.SUCCESS,
  payload: data.data,
});

export const errorAddProgram = (error) => ({
  type: ADD_PROGRAM.FAIL,
  payload: error,
});
