import { CLIENT_GET_ADMIN } from '../../constants';

const initialState = {
  data: null,
  loading: false,
  error: {},
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
    case CLIENT_GET_ADMIN.LOAD:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_GET_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case CLIENT_GET_ADMIN.FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
