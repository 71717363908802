import axios from 'axios';
import { DELETE_PROPERTY_MEDIA } from '../../constants';

export const deletePropertyMedia =
  (data, closeDialog, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeletePropertyMedia());
    axios
      .put(`/property/media`, data, config)
      .then((res) => {
        dispatch(setDeletePropertyMedia(res.data));
        closeDialog();
      })
      .catch((err) => {
        dispatch(errorDeletePropertyMedia(err.response));
        onError(err.response);
      });
  };

export const loadDeletePropertyMedia = () => ({
  type: DELETE_PROPERTY_MEDIA.LOAD,
});

export const setDeletePropertyMedia = (data) => ({
  type: DELETE_PROPERTY_MEDIA.SUCCESS,
  payload: data,
});

export const errorDeletePropertyMedia = (error) => ({
  type: DELETE_PROPERTY_MEDIA.FAIL,
  payload: error,
});
