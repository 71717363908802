import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  sidebar_container: {
    minWidth: 100,
    maxWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border:
      theme.palette.mode === 'dark'
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      minWidth: 280,
      zIndex: 1000,
      backgroundColor: theme.palette.sidebar_background,
      border:
        theme.palette.mode === 'dark'
          ? 'none'
          : `1px solid ${theme.palette.sidebar_background}`,
      boxShadow: theme.palette.sidebar_shadow,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 270,
    },
  },
  mobile_sidebar_container: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  sidebar_header: {
    width: '100%',
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebar_top: {
    display: 'flex',
    width: 'calc(100% - 36px)',
    justifyContent: 'flex-end',
    padding: '18px 18px 0px 18px',
  },
  close_icon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  sidebar_logo: {
    width: 40,
    height: 50,
  },
  sidebar_body: {
    marginTop: 20,
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 25,
      height: '100%',
      marginBottom: 10,
    },
  },
  menu_box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  menu_item: {
    textDecoration: 'none',
    marginBottom: 15,
    fontWeight: 400,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginLeft: 25,
      flexDirection: 'row',
      marginBottom: 18,
    },
  },
  menu_icon: {
    width: 32,
    height: 32,
  },
  menu_label: {
    marginTop: 5,
    color: theme.palette.menu_indicator,
    fontFamily: "'Open Sans', sans-serif",
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginLeft: 10,
    },
  },
  active_indicator: {
    width: 3,
    height: '100%',
    backgroundColor: theme.palette.menu_indicator,
    position: 'absolute',
    right: 2,
  },
  sidebar_bottom: {
    padding: '25px 18px',
    width: 'calc(100% - 36px)',
    borderTop: `1px solid ${theme.palette.sidebar_border}`,
    display: 'flex',
    flexDirection: 'column',
  },
  dialog_mask: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 5,
  },
  logout_icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  profile_container: {
    display: 'flex',
    alignItems: 'center',
  },
  welcome_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.secondary_profile_title,
    marginBottom: 8,
  },
}));
