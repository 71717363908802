import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    height: 'calc(100% - 81px)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      height: 'calc(100% - 75px)',
    },
  },
  form_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  left_container: {
    width: 'calc(60% - 36px)',
    height: 'calc(100% - 24px)',
    padding: '20px 18px 4px 18px',
    backgroundColor: theme.palette.background,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '20px 14px',
      width: 'calc(60% - 28px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 14px',
      paddingBottom: 4,
      width: 'calc(100% - 28px)',
      height: 'auto',
      overflow: 'visible',
    },
  },
  right_container: {
    width: 'calc(40% - 2px)',
    // height: 'calc(100% - 2px)',
    padding: '20px 30px 4px 30px',
    border: `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '20px 14px 4px 14px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 14px 4px 14px',
      width: 'calc(100% - 28px)',
      borderLeft: 'none',
      borderRight: 'none',
      overflow: 'visible',
    },
  },
  top_container: {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column',
    // },
  },
  back_icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginTop: 4,
  },
  residence_input: {
    '&::placeholder': {
      color: theme.palette.secondary_input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.background_button,
    border: 'none',
    borderBottom: `1px solid ${theme.palette.secondary_input_border}`,
    padding: 0,
    paddingBottom: 13,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 20,
    borderRadius: 0,
    fontWeight: 500,
    '&:focus': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.secondary_input_border}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  residence_input_container: {
    marginLeft: 12,
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: 0,
    // },
  },
  add_new_container: {
    marginLeft: 10,
    minWidth: 138,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: 10,
    },
  },
  edit_button_container: {
    minWidth: 75,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  add_new_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bottom_container: {
    width: 'calc(100% - 10px)',
    marginLeft: 10,
    height: 'calc(100% - 127px)',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'visible',
      width: '100%',
      marginLeft: 0,
    },
  },
  center_container: {
    width: 'calc(100% - 10px)',
    marginLeft: 10,
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 20,
      marginLeft: 0,
    },
  },
  client_details: {
    paddingBottom: 25,
    borderBottom: `1px solid ${theme.palette.background_border}`,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
      borderBottom: 'none',
    },
  },
  client_name_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  client_name_label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.background_button,
  },
  client_name: {
    color: theme.palette.profile_title,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 14,
    marginTop: 5,
  },
  program_container: {
    border: `1px solid ${theme.palette.form_switch_background}`,
    borderRadius: 32,
    padding: '0px 24px',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.form_switch_background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 33,
    marginRight: '25%',
    textAlign: 'center',
  },
  information_container: {
    // padding: '16px 0px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  label_container: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0px',
    cursor: 'pointer',
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  information_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    // cursor: 'pointer',
    marginLeft: 10,
  },
  information_data_container: {
    width: 'calc(100% - 33px)',
    marginLeft: 33,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  data_label: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.profile_title,
  },
  information_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    border: `1px solid ${theme.palette.input_border}`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
    width: 'calc(100% - 26px)',
  },
  information_input_container: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  note_input: {
    paddingTop: 8,
    paddingBottom: 0,
    fontWeight: 600,
    border: 'none',
    '&:focus': {
      border: 'none',
    },
  },
  phoneno_container: {
    display: 'flex',
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  countryCodeContainer: {
    marginRight: 5,
    minWidth: 100,
    maxWidth: 150,
  },
  add_image_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    maxHeight: 200,
    backgroundColor: theme.palette.drop_image_background,
    border: `1px dashed ${theme.palette.background_button}`,
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
  },
  add_image_drop_effect: {
    backgroundColor: theme.palette.background_border,
    borderColor: theme.palette.form_switch_background,
  },
  image_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary_background,
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginBottom: 12,
    cursor: 'pointer',
  },
  gallery_icon: {
    height: 27,
    width: 27,
  },
  common_label: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
  input_container: {
    marginTop: 15,
  },
  address: {
    fontSize: 16,
    margin: 0,
    marginLeft: 10,
  },
  address_container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  address_icon: {
    width: 33,
    height: 33,
  },
  property_tab_container: {
    marginTop: 0,
    marginLeft: 12,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  property_tab: {
    paddingTop: 0,
    paddingBottom: 5,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
  },
  image_tab_container: {
    marginTop: 20,
    minHeight: 43,
  },
  tabs_data_container: {
    width: '100%',
    height: 'calc(100% - 315px)',
    marginTop: 20,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      overflow: 'visible',
      height: 'auto',
    },
  },
  notes_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  note_input_container: {
    backgroundColor: theme.palette.background,
    width: '100%',
    height: 115,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  note_action_container: {
    display: 'flex',
    padding: '2px 15px 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  note_send_button: {
    backgroundColor: theme.palette.form_switch_background,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.form_switch_background,
    },
  },
  note_send_button_container: {
    width: 45,
    height: 45,
  },
  note_input_text_container: {
    marginTop: 5,
  },
  browse_button: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginRight: 15,
  },
  note_preview_image_container: {
    width: 45,
    height: 45,
    marginRight: 10,
    position: 'relative',
  },
  note_preview_image: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    border: `1px solid ${theme.palette.profile_title}`,
  },
  close_icon_container: {
    width: 12,
    height: 12,
    backgroundColor: Colors.RED,
    borderRadius: '50%',
    position: 'absolute',
    right: -6,
    top: -6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  close_icon: {
    width: 20,
    height: 20,
  },
  note_button_disable: {
    cursor: 'not-allowed',
  },
  notes_data_container: {
    // marginTop: 15,
  },
  notes_data: {
    padding: '14px 16px',
    backgroundColor: theme.palette.background,
    borderRadius: 7,
    marginTop: 15,
    marginBottom: 15,
  },
  note_title: {
    margin: 0,
  },
  note_header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  more_icon: {
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
  note_image: {
    width: '100%',
    height: 180,
    marginTop: 10,
    borderRadius: 8,
    objectFit: 'cover',
  },
  note_footer: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  clock_icon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  data_container: {
    marginBottom: 15,
  },
  title_container: {
    marginBottom: 16,
    padding: 0,
  },
  property_data_container: {
    padding: '0px 14px',
    width: 'calc(100% - 28px)',
  },
  data_grid: {
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      marginBottom: 25,
    },
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  flex_row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  add_vendor: {
    height: 22,
    width: 22,
    alignSelf: 'flex-end',
    marginRight: 15,
  },
  upcoming_data_container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 15,
    // alignItems: 'centre',
    padding: '15px 0',
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  flex_view: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
  },
  flex_column_view: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  text_bold: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.search_text,
  },
  text_blue: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    marginLeft: 2,
  },
  text_red: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: Colors.TOMATO_RED,
  },
  text_blue: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: Colors.NEVY_BLUE,
    cursor: 'pointer',
  },
  action_icons: {
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  icon_container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
  },
  add_new_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 900,
    color: Colors.LIGHT_BLUE,
    marginLeft: 2,
    border: `1px solid ${Colors.LIGHT_BLUE}`,
    padding: 10,
    borderRadius: 5,
    width: 'auto',
    textAlign: 'center',
  },
  allFiles_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.media_title_text,
  },
  addAttachment_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  addAttachment_text_container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  addAttachment_button_container: {
    marginLeft: 10,
    minWidth: 'auto',
  },
  add_attachment_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '7px 24px',
    height: 'auto',
  },
  download_icon: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  tags_input: {
    width: 'calc(100% - 40px)',
    border: '1px solid #E5E5E5',
    borderRadius: 8,
    padding: 7.5,
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: '#58585A',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  view_more_container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  reactquill_width: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  reactquill_bottom: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      width: '100%',
    },
  },
  dropdown_width: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  customQuillContainer: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    '& .ql-container.ql-snow': {
      border: 'none !important',
      padding: '0 !important',
    },
    '& .ql-editor': {
      padding: '0 !important',
    },
  },
  customRbcEventContent: {
    '& .rbc-event-content': {
      width: '100% !important',
    },
  },
  view_tags_input: {
    border: 'none',
    '& .react-tagsinput-input': {
      display: 'none !important',
    },
  },
  arrivalSubText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: '14px !important',
    marginTop: 5,
  },
  arrialDepartureTextBold: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: theme.palette.search_text,
  },
  backGround_image: {
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));
