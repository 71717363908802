import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: Colors.EXTRA_GRAY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_container: {
    backgroundColor: theme.palette.dialog_background,
    width: 430,
    minHeight: '40%',
    maxHeight: '75%',
    padding: 25,
    zIndex: 3,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 'calc(100% - 50px)',
      height: 'calc(100% - 50px)',
    },
  },
  iconStyle: {
    width: '42px',
    height: '42px',
    borderRadius: '50%', 
    objectFit: 'cover',
    cursor: 'pointer',
  },
}));
