import axios from 'axios';
import { GET_PROPERTY_BY_ID } from '../../constants';

export const fetchPropertyById = (pId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadPropertyById());

  axios
    .get(`/client/getPropertyById/${pId}`, config)
    .then((res) => dispatch(setPropertyById(res.data.data)))
    .catch((err) => {
      dispatch(errorPropertyById(err.response));
      onError(err.response);
    });
};

export const loadPropertyById = () => ({
  type: GET_PROPERTY_BY_ID.LOAD,
});

export const setPropertyById = (data) => ({
  type: GET_PROPERTY_BY_ID.SUCCESS,
  payload: data,
});

export const errorPropertyById = (error) => ({
  type: GET_PROPERTY_BY_ID.FAIL,
  payload: error,
});
