import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  media_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 28px)',
      padding: '10px 14px',
      height: 'calc(100% - 95px)',
    },
  },
  media_view_container: {
    width: '100%',
    padding: '30px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 0px',
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  title_container: {
    marginTop: 10,
    marginBottom: 15,
    padding: 0,
  },
  start_date_container: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  date_container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 16,
    },
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
  media_card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    // backgroundColor: theme.palette.dialog_background,
    cursor: 'pointer',
    // boxShadow: `0px 2px 4px ${theme.palette.media_box_shadow}`,
  },
  image_container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
  },
  folder_image: {
    width: '100%',
  },
  image_text_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  text_title: {
    fontSize: 11,
    // fontWeight: 600,
    color: theme.palette.media_title_text,
    // overflowWrap: 'anywhere',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text14_bold: {
    fontSize: 14,
    // fontWeight: 600,
    color: theme.palette.media_title_text,
    // overflowWrap: 'anywhere',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bread_crumb_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 5,
    borderBottom: `1px solid ${Colors.LIGHT_GRAY}`,
    gap: 15,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    // overflow:'scroll',
  },
  media_path: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
    flex: 1,
  },
  media_path_2: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  folder_input: {
    border: 'none',
    padding: '2px 0',
    borderRadius: 0,
    fontSize: 11,
    color: theme.palette.media_title_text,
    borderBottom: `1px solid ${theme.palette.background_button}`,
    backgroundColor: 'transparent',
    '&:focus': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.background_button}`,
    },
  },
  folderPath_container: {
    overflow: 'auto',
    alignItems: 'center',
  },
  addAttachment_container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  common_label: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  backIcon: {
    height: 32,
    width: 32,
    marginRight: 10,
    cursor: 'pointer',
  },
  searchSelectionLabelContainer: {
    marginBottom: '0px !important',
  },
  action_btn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));
