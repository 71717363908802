import axios from 'axios';
import { GET_MEDIA_FOLDER_CONTENT } from '../../constants';

export const fetchMediaFolderContent = (fID, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadMediaFolderContent());

  axios
    .get(`/media/folder/${fID}`, config)
    .then((res) => {
        dispatch(setMediaFolderContent(res));
        onSuccess(res.data.data);
    })
    .catch((err) => {
      dispatch(errorMediaFolderContent(err.response));
      onError(err.response);
    });
};

export const loadMediaFolderContent = () => ({
  type: GET_MEDIA_FOLDER_CONTENT.LOAD,
});

export const setMediaFolderContent = (folderContent) => ({
  type: GET_MEDIA_FOLDER_CONTENT.SUCCESS,
  payload: folderContent.data.data,
});

export const errorMediaFolderContent = (error) => ({
  type: GET_MEDIA_FOLDER_CONTENT.FAIL,
  payload: error,
});
