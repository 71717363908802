import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  sort_icon: {
    width: 12,
    marginLeft: 2,
  },
  header_container: {
    width: '100%',
    height: '100%',
    // display: 'flex',
    cursor: 'pointer',
  },
  sort_down: {
    transform: 'rotate(180deg)',
  },
}));
