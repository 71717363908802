import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  action_button_container: {
    marginTop: 10,
    width: '100%',
  },
  action_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
}));
