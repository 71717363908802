import axios from 'axios';
import { SUPPLIES_NEEDED } from '../../constants';
import moment from 'moment-timezone';

export const fetchSuppliesNeeded = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadSuppliesNeeded());

  axios
    .get(
      `/admin/suppliesNeeded?currentDate=${moment().format(
        'YYYY-MM-DD'
      )}&timeZone=${moment.tz.guess()}`,
      config
    )
    .then((res) => dispatch(setSuppliesNeeded(res)))
    .catch((err) => {
      dispatch(errorSuppliesNeeded(err.response));
      onError(err.response);
    });
};

export const loadSuppliesNeeded = () => ({
  type: SUPPLIES_NEEDED.LOAD,
});

export const setSuppliesNeeded = (userList) => ({
  type: SUPPLIES_NEEDED.SUCCESS,
  payload: userList.data.data,
});

export const errorSuppliesNeeded = (error) => ({
  type: SUPPLIES_NEEDED.FAIL,
  payload: error,
});
