import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { countries } from '../../utils/appUtils';
import { FormControl, Select, MenuItem } from '@material-ui/core';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export default function CountryCodePicker(props) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  return (
    <div
      className={classNames(
        classes.dropdown_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classNames(
            classes.dropdown_label,
            props.labelClassName && props.labelClassName
          )}
          htmlFor={props.label}
        >
          {props.label}
        </label>
      )}

      <FormControl className={classes.formControl}>
        <Select
          value={props.value ? props.value : '1'}
          onChange={props.onChange}
          disableUnderline
          MenuProps={menuProps}
          classes={{
            select: classes.select,
            icon: classes.selectIcon,
          }}
        >
          {countries.map((option, index) => (
            <MenuItem key={`country${index}`} value={option.phone}>
              {`${countryToFlag(option.code)} +${option.phone}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {props.error && (
        <p className={classes.dropdown_error}>{props.helperText}</p>
      )}
    </div>
  );
}
