import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import useStyles from './styles';
import DateSelector from '../../../../components/DateSelector';
import DateTimeContainer from '../../../../components/DateTimeContainer';
import Dropdown from '../../../../components/Dropdown';
import Textbox from '../../../../components/Textbox';
import { Strings } from '../../../../utils/strings';
import { useTheme } from '@mui/material/styles';
import Button from '../../../../components/Button';
import {
  frequencyArray,
  getErrorMessage,
  isClient,
  numberRegex,
  workingDaysArray,
} from '../../../../utils/appUtils';
import moment from 'moment';
import { SetRecurringSchema } from '../../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { cancelRecurrence } from '../../../../redux/actions/jobActions/jobActions/cancelRecurrence';
import { updateRecurrence } from '../../../../redux/actions/jobActions/jobActions/updateRecurrence';
import { icons } from '../../../../utils/icons';
import { useAlert } from 'react-alert';

const ModalHeader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.dialog_header}>
      {props.label && (
        <label className={classes.header_label}>{props.label}</label>
      )}
      {props.image && (
        <img
          src={props.image}
          alt=''
          className={classes.edit_icon}
          onClick={props.onClick}
        />
      )}
    </div>
  );
};

const RecurringModal = (props) => {
  const { jobId, data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [toEditData, setToEditData] = useState(false);
  const [minRecurringStartDate, setMinRecurringStartDate] = useState(new Date());

  useEffect(() => {
    if (data) {
      // setFieldValue('frequency', data?.frequency);
      setFieldValue('count', data?.count);
      setFieldValue('timePeriod', data?.timePeriod);
      setFieldValue('recurringStartDate', data?.startDate);
      setFieldValue('recurringEndDate', data?.endDate);
      data?.startDate
        ? new Date(data?.startDate) > new Date()
          ? setMinRecurringStartDate(new Date())
          : setMinRecurringStartDate(new Date(data?.startDate))
        : setMinRecurringStartDate(new Date());
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      // frequency: '0',
      count: '1',
      timePeriod: '0',
      recurringStartDate: '',
      recurringEndDate: '',
    },
    validationSchema: SetRecurringSchema,

    onSubmit: (values) => {
      updateRecurringJob(values);
    },
  });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const updateRecurringJob = (values) => {
    const recurringStartDate = moment(values?.recurringStartDate).format();
    const recurringEndDate = moment(values?.recurringEndDate).format();
    const newData = {
      // frequency: values.frequency,
      repeat: {
        count: values.count,
        timePeriod: values.timePeriod,
      },
      recurringStartDate: recurringStartDate,
      recurringEndDate: recurringEndDate,
    };
    props.updateRecurrence(jobId, newData, props.onClose, (err) =>
      onError(err)
    );
  };

  const cancelRecurrence = () => {
    props.cancelRecurrence(jobId, props.onClose, (err) => onError(err));
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <div className={classes.recurring_modal_container}>
      {!toEditData ? (
        <ModalHeader
          label={`${Strings.RECURRING.toUpperCase()} ${Strings.DETAILS.toUpperCase()}`}
          image={!isClient() && icons(theme).editIcon}
          onClick={() => setToEditData(true)}
        />
      ) : null}
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.form_details}>
            {/* <Dropdown
              name='frequency'
              onChange={handleChange('frequency')}
              value={values.frequency}
              label={Strings.FREQUENCY}
              error={Boolean(touched.frequency && errors.frequency)}
              helperText={touched.frequency && errors.frequency}
              // title={Strings.SELECT_AN_OPTION}
              labelClassName={classes.form_label}
              containerClassName={!toEditData && classes.form_input_container}
              // className={classes.form_input}
              optionArray={frequencyArray}
              disabled={!toEditData}
            /> */}
            <DateTimeContainer
              label={Strings.REPEAT_EVERY}
              containerClassName={classes.form_input_container}
            >
              <Textbox
                name='count'
                type='number'
                onChange={handleChange('count')}
                value={values.count}
                error={Boolean(touched.count && errors.count)}
                helperText={touched.count && errors.count}
                placeholder='Eg: 2'
                labelClassName={classes.form_label}
                className={classes.form_input}
                disabled={!toEditData}
                onKeyDown={(e)=> numberRegex(e)}
                min={1}
              />
              <Dropdown
                name='timePeriod'
                onChange={handleChange('timePeriod')}
                value={values.timePeriod}
                error={Boolean(touched.timePeriod && errors.timePeriod)}
                helperText={touched.timePeriod && errors.timePeriod}
                // title={Strings.WORK_DAYS}
                labelClassName={classes.form_label}
                // className={classes.form_input}
                optionArray={workingDaysArray}
                disabled={!toEditData}
              />
            </DateTimeContainer>
            <DateTimeContainer
              containerClassName={classes.form_input_container}
            >
              <DateSelector
                name='recurringStartDate'
                onChange={(value) => setFieldValue('recurringStartDate', value)}
                value={values.recurringStartDate}
                error={Boolean(
                  touched.recurringStartDate && errors.recurringStartDate
                )}
                helperText={
                  touched.recurringStartDate && errors.recurringStartDate
                }
                label={Strings.START_DATE}
                labelClassName={classes.form_label}
                format='MMM dd, yyyy'
                placeholder={Strings.SAMPLE_DATE}
                disabled={!toEditData}
                // minDate={minRecurringStartDate}
              />
              <DateSelector
                name='recurringEndDate'
                onChange={(value) => setFieldValue('recurringEndDate', value)}
                value={values.recurringEndDate}
                error={Boolean(
                  touched.recurringEndDate && errors.recurringEndDate
                )}
                helperText={touched.recurringEndDate && errors.recurringEndDate}
                label={Strings.END_DATE}
                labelClassName={classes.form_label}
                format='MMM dd, yyyy'
                placeholder={Strings.SAMPLE_DATE}
                disabled={!toEditData}
                // minDate={minRecurringStartDate}
              />
            </DateTimeContainer>
            {toEditData && (
              <>
                <Button
                  text={Strings.CANCEL_RECURRENCE}
                  containerClassName={classes.form_input_container}
                  className={classes.cancel_button}
                  onClick={cancelRecurrence}
                  loading={props.cancelRecurrenceLoading}
                  disabled={
                    props.cancelRecurrenceLoading ||
                    props.updateRecurrenceLoading
                  }
                  loaderColor={theme.palette.background_button}
                />
                <Button
                  type='submit'
                  text={Strings.UPDATE}
                  containerClassName={classes.form_input_container}
                  className={classes.update_button}
                  loading={props.updateRecurrenceLoading}
                  disabled={
                    props.updateRecurrenceLoading ||
                    props.cancelRecurrenceLoading
                  }
                  loaderColor={theme.palette.background_button_text}
                />
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
      <img
        src={icons(theme).modalArrow}
        alt=''
        className={classes.modal_triangle}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  cancelRecurrenceLoading: state.cancelRecurrence.loading,
  updateRecurrenceLoading: state.updateRecurrence.loading,
});

export default connect(mapStateToProps, {
  cancelRecurrence,
  updateRecurrence,
})(RecurringModal);
