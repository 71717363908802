import React, { useEffect } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { FormControl, Select, MenuItem, useTheme } from '@material-ui/core';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';
import { Strings } from '../../utils/strings';
import CustomizedTooltips from '../CustomizedTooltips';
import { isClient } from '../../utils/appUtils';

const Input = styled('input')(({ theme, disabled }) => ({
  padding: 13,
  paddingRight: 32,
  width: '100%',
  fontFamily: "'Open Sans', sans-serif",
  color: theme.palette.profile_title,
  fontWeight: 400,
  fontSize: 14,
  border: `1px solid ${theme.palette.input_border}`,
  '&:focus': {
    border: `1px solid ${theme.palette.background_button}`,
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.input_placeholder,
  },
  borderRadius: 8,
  background: `${disabled ? theme.palette.disable_color : 'transparent'} url(${
    icons(theme).downArrow
  }) no-repeat right 0.8rem center/1rem 4rem`,
}));

const Listbox = styled('ul')(({ theme }) => ({
  borderRadius: 8,
  width: '100%',
  margin: 0,
  marginTop: 4,
  padding: 0,
  zIndex: 3,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: Colors.WHITE,
  overflow: 'auto',
  maxHeight: 200,
  // display: 'flex',
  flexDirection: 'column',
  '& li': {
    fontFamily: "'Open Sans', sans-serif",
    color: Colors.DARK_GRAY,
    fontWeight: 400,
    fontSize: 14,
    padding: '13px',
    display: 'flex',
  },
  [`& li.${autocompleteClasses.focused}`]: {
    background: Colors.SIDEBAR_BLUE,
    cursor: 'pointer',
  },
  '& li:active': {
    background: Colors.SIDEBAR_BLUE,
  },
  boxShadow:
    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}));

export default function Dropdown(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (isClient() && props?.optionArray?.length === 1) {
      props.onChange(props?.optionArray[0]?.value);
    }
  }, [props.optionArray]);

  const getSelectedItem = () => {
    const item =
      props.optionArray &&
      props.optionArray.find((opt) => {
        if (opt.value === props.value) return opt;
      });
    if (props.value) return item || {};
    else return null;
  };
  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: props.optionArray ? props.optionArray : [],
    onChange: (event, value) => {
      props.onChange(value?.value || '');
      setOpen(false);
    },
    getOptionLabel: (option) => option.title || '',
    value: getSelectedItem(),
    blurOnSelect: true,
  });

  const menuProps = {
    classes: {
      list: props?.isFixedWidth ? classes.list1 : classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  return (
    <div
      className={classNames(
        classes.dropdown_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {(props.label || props.addOption) && (
        <div className={classes.dropdown_label_container}>
          {props.label && (
            <label
              className={classNames(
                classes.dropdown_label,
                props.labelClassName && props.labelClassName
              )}
              htmlFor={props.label}
            >
              {props.label}
            </label>
          )}
          {props.addOption && (
            <CustomizedTooltips
              title={props.tooltipTitle ?? Strings.NEW_VENDOR}
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                  cursor: 'pointer',
                  marginLeft: 'auto',
                }}
                onClick={() => props?.onAddClick()}
                src={icons(theme).addIcon}
              />
            </CustomizedTooltips>
          )}
        </div>
      )}
      <FormControl
        className={classNames(
          props.value === '0' ? classes.title_color : classes.formControl,
          props.inputContainerClass && props.inputContainerClass,
          props.disabled && classes.isDisabled
        )}
      >
        {props.searchable ? (
          <div>
            <div style={{ width: '100%', display: 'flex' }} {...getRootProps()}>
              <Input
                disabled={props.disabled}
                placeholder={props.title}
                {...getInputProps()}
              />
            </div>
            {groupedOptions.length > 0 ? (
              <Listbox {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })}>
                    {option.title}
                    {props.isEditIcon && (
                      <img
                        src={icons(theme).editIcon}
                        alt=''
                        className={classes.edit_icon}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.onEditIconClick(option);
                          setOpen(false);
                        }}
                      />
                    )}
                  </li>
                ))}
              </Listbox>
            ) : null}
          </div>
        ) : (
          <Select
            value={props.value}
            onChange={props.onChange}
            disableUnderline
            MenuProps={menuProps}
            disabled={props.disabled}
            className={props.selectClassName}
            classes={{
              select: classes.select,
              icon: classes.selectIcon,
            }}
            renderValue={(value) => {
              if (value !== '0' && props.optionArray?.length) {
                let item = props.optionArray?.find(
                  (option) => option.value === value
                );
                return props?.isColorSelect ? (
                  <div className={classes.flex_row_item_center}>
                    <div
                      className={classes.color_item}
                      style={{ backgroundColor: value }}
                    />
                    <label className={classes.dropDown_label_title}>
                      {item?.title}
                    </label>
                  </div>
                ) : (
                  <label className={classes.dropDown_label_title}>
                    {item?.title}
                  </label>
                );
              }
              return props.title;
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {props.title && (
              <MenuItem disabled value={'0'}>
                {props.title}
              </MenuItem>
            )}
            {props.optionArray &&
              props.optionArray.map((item, index) => (
                <MenuItem key={'option' + index} value={item.value}>
                  {props?.isColorSelect && (
                    <div
                      className={classes.color_item}
                      style={{ backgroundColor: item.value }}
                    />
                  )}
                  <label className={classes.dropdown_option_label}>
                    {item.title}
                  </label>
                  {props.isEditIcon && (
                    <img
                      src={icons(theme).editIcon}
                      alt=''
                      className={classes.edit_icon}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onEditIconClick(item);
                        setOpen(false);
                      }}
                    />
                  )}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
      {props.error && (
        <p className={classes.dropdown_error}>{props.helperText}</p>
      )}
    </div>
  );
}
