import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';

const NewField = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classNames(
        classes.new_field_container,
        props.containerClassName && props.containerClassName
      )}
    >
      <img
        src={icons(theme).Plus}
        alt=''
        className={classNames(
          classes.plus_icon,
          props.iconClassName && props.iconClassName
        )}
        onClick={props.onClick}
      />
      <label
        className={classNames(
          classes.common_text,
          props.textClassName && props.textClassName
        )}
        onClick={props.onClick}
      >
        {props.text ? props.text : 'New Field'}
      </label>
    </div>
  );
};

export default NewField;
