import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: Colors.EXTRA_GRAY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_container: {
    backgroundColor: theme.palette.dialog_background,
    width: 430,
    height: 'auto',
    padding: 25,
    zIndex: 3,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 'calc(100% - 50px)',
      height: 'calc(100% - 50px)',
    },
  },
  form_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
    height: '100%',
  },
  form_input_container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
  add_button_container: {
    marginTop: 17,
    marginBottom: 10,
  },
  add_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  switch_container: {
    marginTop: 12,
  },
  commentsDialogStyle: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
}));
