import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  input_label: {
    color: theme.palette.title_text,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  attachment_view: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 15,
  },
  image_view: {
    width: 124,
    height: 86,
    position: 'relative',
    borderRadius: 8,
    marginRight: 20,
  },
  images: {
    width: 124,
    height: 86,
    objectFit: 'fill',
    borderRadius: 8,
  },
  img_title_view: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  img_desc: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 5,
  },
  desc: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    color: theme.palette.title_text,
  },
  delete_icon: {
    height: 35,
    width: 30,
    cursor: 'pointer',
  },
  font_weight_700: {
    fontWeight: 700,
  },
  icon_image: {
    height: '16px',
    width: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 14,
      width: 14,
    },
  },
  preview_icon: {
    position: 'absolute',
    left: 10,
    bottom: 5,
    borderRadius: 5,
    height: 30,
    width: 28,
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: `0px 0px 2px 0.5px ${theme.palette.media_box_shadow}`
  },
}));
