import axios from 'axios';
import { NOTES_LIST } from '../../constants';

export const fetchNotesList = (pId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadPropertyNotesList());

  axios
    .get(`/client/listNotes?propertyId=${pId}`, config)
    .then((res) => dispatch(setPropertyNotesList(res.data.data)))
    .catch((err) => {
      dispatch(errorPropertyNotesList(err.response));
      onError(err.response);
    });
};

export const loadPropertyNotesList = () => ({
  type: NOTES_LIST.LOAD,
});

export const setPropertyNotesList = (data) => ({
  type: NOTES_LIST.SUCCESS,
  payload: data,
});

export const errorPropertyNotesList = (error) => ({
  type: NOTES_LIST.FAIL,
  payload: error,
});
