import { Colors } from '../utils/color';

export default {
  background: Colors.THEME_BLACK,
  secondary_background: Colors.LIGHT_BLACK,
  background_border: Colors.THIN_BLUE,
  title_text: Colors.WHITE,
  notification_badge: Colors.TOMATO_RED,
  profile_title: Colors.SIDEBAR_BLUE,
  secondary_profile_title: Colors.GRAY,
  menu_indicator: Colors.WHITE,
  background_button: Colors.LIGHT_BLUE,
  switch_background: Colors.DARK,
  form_switch_background: Colors.LIGHT_BLUE,
  search_border: Colors.THEME_SEARCH_BORDER,
  search_text: Colors.WHITE,
  background_button_text: Colors.WHITE,
  dialog_background: Colors.THEME_DARK,
  modal_shadow: Colors.MODAL_SHADOW_WHITE,
  form_label: Colors.SIDEBAR_BLUE,
  input_placeholder: Colors.DARK_PLACEHOLDER,
  input_border: Colors.DARK_INPUT_BORDER,
  table_head_background: Colors.TABLE_HEAD_BLACK,
  table_row_background: Colors.TABLE_ROW_BLACK,
  secondary_input_placeholder: Colors.SECONDARY_PLACEHOLDER_COLOR,
  secondary_input_border: Colors.SECONDARY_BORDER,
  drop_image_background: Colors.THEME_BLACK,
  group_form_background: Colors.TABLE_HEAD_BLACK,
  dropdown_tag_background: Colors.TAG_BLUE,
  form_input_background: 'transparent',
  chat_time_text: Colors.THIN_WHITE,
  chat_border: Colors.THIN_BLUE,
  chat_profile_background: Colors.LIGHT_BLACK,
  checkbox_border: Colors.THEME_SEARCH_BORDER,
  sidebar_background: Colors.LIGHT_BLACK,
  sidebar_shadow: Colors.SIDEBAR_SHADOW,
  sidebar_border: Colors.SIDEBAR_BORDER,
  disable_color: Colors.GRAY,
  media_title_text: Colors.SIDEBAR_BLUE,
  media_box_shadow: Colors.MODAL_SHADOW_WHITE,
  avatar_background: Colors.TABLE_HEAD_BLACK,
};
