import axios from 'axios';
import { ADD_ANNOUNCEMENT } from '../../constants';

export const createAnnouncement = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddAnnouncement());
  axios
    .post(`/admin/createAnnouncement`, data, config)
    .then((res) => {
      dispatch(setAddAnnouncement(res));
      onNewClose();
    })
    .catch((err) => {
      dispatch(errorAddAnnouncement(err.response));
      onError(err.response);
    });
};

export const loadAddAnnouncement = () => ({
  type: ADD_ANNOUNCEMENT.LOAD,
});

export const setAddAnnouncement = (data) => ({
  type: ADD_ANNOUNCEMENT.SUCCESS,
  payload: data.data,
});

export const errorAddAnnouncement = (error) => ({
  type: ADD_ANNOUNCEMENT.FAIL,
  payload: error,
});
