import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  profile_container: {
    display: 'flex',
    marginRight: 25,
    alignItems: 'center',
    cursor: 'pointer',
  },
  profile_name_container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    justifyContent: 'center',
  },
  profile_name: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    cursor: 'pointer',
  },
  role: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.secondary_profile_title,
    cursor: 'pointer',
  },
  down_arrow: {
    width: 24,
    height: 24,
    marginLeft: 13,
  },
}));
