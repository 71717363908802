import React from 'react';
import { Strings } from '../../utils/strings';
import Textbox from '../Textbox';
import useStyles from './styles';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';

export default function Searchbox(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Textbox
      name='search'
      onChange={props.onChange}
      value={props.value}
      placeholder={props.searchPlaceholder ? props.searchPlaceholder : Strings.TYPE_TO_SEARCH}
      containerClassName={classNames(
        classes.searchbar_container,
        props.containerClassName && props.containerClassName
      )}
      rightIcon={icons(theme).searchIcon}
      className={classNames(
        classes.search_box,
        props.className && props.className
      )}
    />
  );
}
