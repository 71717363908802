import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  toolbox_modal: {
    // position: 'absolute',
    // top: 395,
    // right: 25,
    // left: 25,
    // zIndex: 5,
    padding: 16,
    borderRadius: 8,
    boxShadow: `0 0 15px ${theme.palette.modal_shadow}`,
    backgroundColor: theme.palette.dialog_background,
  },
  toolbox_container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
  },
  tools_container: {
    display: 'flex',
    alignItems: 'center',
  },
  tools_icon: {
    height: 24,
    width: 24,
    marginRight: 8,
    cursor: 'pointer',
  },
  tools_text: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.form_label,
    cursor: 'pointer',
  },
}));
