import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';

const DateTimeContainer = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.date_time_box,
        props.containerClassName && props.containerClassName,
      )}>
      {props.label && (
        <label
          className={classNames(
            classes.date_time_label,
            props.labelClassName && props.labelClassName,
          )}>
          {props.label}
        </label>
      )}
      {props.children && (
        <div className={classes.date_time_container}>{props.children}</div>
      )}
    </div>
  );
};

export default DateTimeContainer;
