import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  modal_mask_container: {
    backgroundColor: 'rgba(0, 0, 0, 0.71)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 15,
    top: 15,
    right: 15,
  },
  crop_btn: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
    height: 35,
    position: 'absolute',
    bottom: 15,
    right: '48%',
    width: 'fit-content'
  },
}));
