import axios from 'axios';
import { UPDATE_PROPERTY } from '../../constants';

export const updateProperty =
  (pId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateProperty());
    axios
      .put(`client/updateProperty?propertyId=${pId}`, data, config)
      .then((res) => {
        dispatch(setUpdateProperty(res));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateProperty(err.response));
        onError(err.response);
      });
  };

export const loadUpdateProperty = () => ({
  type: UPDATE_PROPERTY.LOAD,
});

export const setUpdateProperty = (data) => ({
  type: UPDATE_PROPERTY.SUCCESS,
  payload: data.data,
});

export const errorUpdateProperty = (error) => ({
  type: UPDATE_PROPERTY.FAIL,
  payload: error,
});
