import axios from 'axios';
import { SUPER_ADMIN_DASHBOARD_ISSUES_LIST } from '../../constants';
import moment from 'moment-timezone';

export const fetchSuperAdminDashboardIssueList =
  (data, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    data.timeZone = moment.tz.guess();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'post',
      url: 'admin/issueList',
      data,
    };
    dispatch(loadSuperAdminDashboardIssueList());
    axios(config)
      .then((res) => dispatch(setSuperAdminDashboardIssueList(res)))
      .catch((err) => {
        dispatch(errorSuperAdminDashboardIssueList(err.response));
        onError(err.response);
      });
  };

export const loadSuperAdminDashboardIssueList = () => ({
  type: SUPER_ADMIN_DASHBOARD_ISSUES_LIST.LOAD,
});

export const setSuperAdminDashboardIssueList = (issueList) => ({
  type: SUPER_ADMIN_DASHBOARD_ISSUES_LIST.SUCCESS,
  payload: issueList.data.data,
});

export const errorSuperAdminDashboardIssueList = (error) => ({
  type: SUPER_ADMIN_DASHBOARD_ISSUES_LIST.FAIL,
  payload: error,
});
