import { UPDATE_REPORT_ACTION } from '../../../constants';
import axios from 'axios';

export const updateReportAction = (data, onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateReportAction());
  axios
    .put(`/jobs/actions`, data, config)
    .then((res) => {
      callback?.(res.data);
      dispatch(setUpdateReportAction(res));
    })
    .catch((err) => {
      dispatch(errorUpdateReportAction(err.response));
      onError(err.response);
    });
};

export const loadUpdateReportAction = () => ({
  type: UPDATE_REPORT_ACTION.LOAD,
});

export const setUpdateReportAction = (reportData) => ({
  type: UPDATE_REPORT_ACTION.SUCCESS,
  payload: reportData?.data?.data || null,
});

export const errorUpdateReportAction = (error) => ({
  type: UPDATE_REPORT_ACTION.FAIL,
  payload: error,
});
