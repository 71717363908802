import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import { SendManagementFeedbackSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { getErrorMessage, isClient } from '../../../utils/appUtils';
import { useAlert } from 'react-alert';
import { sendFeedback } from '../../../redux/actions/clientActions/sendFeedback';
import Textbox from '../../../components/Textbox';
import classNames from 'classnames';
import { Colors } from '../../../utils/color';
import { useNavigate } from 'react-router-dom';

const SendManagementFeedback = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: SendManagementFeedbackSchema,
    onSubmit: (values) => {
      const data = {
        title: values.title,
        description: values.description,
      };
      props.sendFeedback(
        data,
        () => {
          onSuccess(
            'Your feedback to management has been successfully submitted'
          );
          props.onNewClose();
        },
        (err) => onError(err)
      );
    },
  });

  const { errors, touched, values, handleChange, setFieldValue, handleSubmit } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={Strings.SEND_MANAGEMENT_FEEDBACK}
            onNewClose={props.onNewClose}
          />
          <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
              <div className={classes.form_container}>
                <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  placeholder={Strings.TITLE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classNames(
                    classes.form_input_container,
                    classes.margin
                  )}
                />
                <Textbox
                  name='description'
                  onChange={handleChange('description')}
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  placeholder={Strings.DESCRIPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classNames(
                    classes.form_input_container,
                    classes.margin
                  )}
                  multiline
                  rows={4}
                />
                <label className={classes.note_text}>
                  {Strings.SEND_FEEDBACK_NOTE1}
                  {/* <span
                    style={{
                      color: Colors.LIGHT_BLUE,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => navigate('/app/chat')}
                  >
                    chat
                  </span>
                  {Strings.SEND_FEEDBACK_NOTE2} */}
                </label>
                <Button
                  type='submit'
                  text={Strings.SEND}
                  loading={props.sendFeedbackLoading}
                  disabled={props.sendFeedbackLoading}
                  containerClassName={classes.add_button_container}
                  className={classes.add_button}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sendFeedbackData: state.sendFeedbackData.data,
  sendFeedbackLoading: state.sendFeedbackData.loading,
});

export default connect(mapStateToProps, {
  sendFeedback,
})(SendManagementFeedback);
