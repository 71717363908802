import axios from 'axios';
import { ADD_JOB_FORM } from '../../../constants';

export const createJobForm = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addJobForm());
  axios
    .post(`/jobs/createForm`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAddForm(res));
    })
    .catch((err) => {
      dispatch(errorAddForm(err.response));
      onError(err.response);
    });
};

export const addJobForm = () => ({
  type: ADD_JOB_FORM.LOAD,
});

export const setAddForm = (data) => ({
  type: ADD_JOB_FORM.SUCCESS,
  payload: data.data,
});

export const errorAddForm = (error) => ({
  type: ADD_JOB_FORM.FAIL,
  payload: error,
});
