import axios from 'axios';
import { CREATE_TAG } from '../../constants';

export const createTag = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadCreateTag());

  axios
    .post(`/media/tag/create`, data, config)
    .then((res) => {
        dispatch(setCreateTag(res));
        onSuccess()
    })
    .catch((err) => {
      dispatch(errorCreateTag(err.response));
      onError(err.response);
    });
};

export const loadCreateTag = () => ({
  type: CREATE_TAG.LOAD,
});

export const setCreateTag = (mediaList) => ({
  type: CREATE_TAG.SUCCESS,
  payload: mediaList.data.data,
});

export const errorCreateTag = (error) => ({
  type: CREATE_TAG.FAIL,
  payload: error,
});
  