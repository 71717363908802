import axios from 'axios';
import { MEDIA_LIST } from '../../constants';
import moment from 'moment-timezone';

export const fetchMediaList = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  data.timeZone = moment.tz.guess();

  dispatch(loadMediaList());

  axios
    .post(`/media/list`, data, config)
    .then((res) => dispatch(setMediaList(res)))
    .catch((err) => {
      dispatch(errorMediaList(err.response));
      onError(err.response);
    });
};

export const loadMediaList = () => ({
  type: MEDIA_LIST.LOAD,
});

export const setMediaList = (mediaList) => ({
  type: MEDIA_LIST.SUCCESS,
  payload: mediaList.data.data,
});

export const errorMediaList = (error) => ({
  type: MEDIA_LIST.FAIL,
  payload: error,
});
