import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { Stack } from '@mui/material';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import NewFormHeader from '../NewFormHeader';
import { Strings } from '../../utils/strings';
import { Helmet } from 'react-helmet';
import { useAlert } from 'react-alert';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/styles';

const fileTypeArray = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ShareMediaDialog = (props) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const { fileInfo } = props;
  const { url, type, fileName } = fileInfo;

  const title = 'Check out this file!';
  const metaDescription = fileName || 'This is a shared file.';
  let ogType = 'article';

  if (type?.startsWith('image')) ogType = 'image';
  else if (type?.startsWith('video')) ogType = 'video';
  else if (type?.startsWith('audio')) ogType = 'audio';
  else ogType = 'video';

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert.success('Link copied to clipboard successfully');
    } catch (err) {
      alert.error('Failed to copy the link');
    }
  };

  return (
    <Stack>
      <Helmet>
        {/* <title>{title}</title> */}
        <meta name='description' content={metaDescription} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={metaDescription} />
        <meta property='og:type' content={ogType} />
        {/* <meta property='og:url' content={url} /> */}
        {fileInfo?.type.startsWith('image') && (
          <meta property='og:image' content={url} />
        )}
        {fileInfo?.type.startsWith('video') && (
          <meta property='og:video' content={url} />
        )}
        {fileInfo?.type.startsWith('audio') && (
          <meta property='og:audio' content={url} />
        )}
        {fileTypeArray?.includes(fileInfo?.type) && (
          <meta property='og:file' content={url} />
        )}

        {/* Twitter Card tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={metaDescription} />
        <meta name='twitter:image' content={url} />
      </Helmet>
      <Stack
        alignItems='center'
        justifyContent='center'
        className={classes.dialog_mask}
      >
        <Stack className={classes.dialog_container}>
          <NewFormHeader
            title={Strings.SHARE_FILE}
            onNewClose={props?.onClose}
          />
          <Stack
            flex={1}
            alignItems='center'
            justifyContent='center'
            flexDirection='row'
            flexWrap='wrap'
            gap={2.3}
          >
            <img
              src={icons(theme).copyLink}
              alt='copy link'
              onClick={handleCopyLink}
              className={classes.iconStyle}
            />
            <EmailShareButton url={url} className={classes.iconStyle}>
              <EmailIcon size={42} round />
            </EmailShareButton>
            <TwitterShareButton url={url} className={classes.iconStyle}>
              <XIcon size={42} round />
            </TwitterShareButton>
            <FacebookShareButton url={url} className={classes.iconStyle}>
              <FacebookIcon size={42} round />
            </FacebookShareButton>
            <WhatsappShareButton url={url} className={classes.iconStyle}>
              <WhatsappIcon size={42} round />
            </WhatsappShareButton>
            <FacebookMessengerShareButton
              url={url}
              className={classes.iconStyle}
            >
              <FacebookMessengerIcon size={42} round />
            </FacebookMessengerShareButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

ShareMediaDialog.propTypes = {
  onClose: PropTypes.func,
  fileInfo: PropTypes.object,
};

export default ShareMediaDialog;
