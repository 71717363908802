import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import Checkbox from '../../../components/Checkbox';
import { icons } from '../../../utils/icons';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import {
  getDuration,
  isClient,
  jobStatusArray,
  jobsDetailTabArray,
  statusColor,
  userId,
} from '../../../utils/appUtils';
import { Strings } from '../../../utils/strings';
import CustomizedTooltips from '../../../components/CustomizedTooltips';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import Tabs from '../../../components/Tabs';
import { CircularProgress } from '@material-ui/core';
import MediaImageContainer from '../../../components/MediaImageContainer';
import { Colors } from '../../../utils/color';
import Dropdown from '../../../components/Dropdown';
import Button from '../../../components/Button';

// ===== view dropdown icon =====
const RenderUpDownIcon = (props) => {
  const theme = useTheme();

  return (
    <img
      alt='toggle'
      src={props?.isOpen ? icons(theme).downArrow : icons(theme).rightArrow}
    />
  );
};

// ===== view/manage jobSummary, notes, internalNotes =====
const NotesDataList = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      style={{
        borderBottom: props.isBottomBorder
          ? `1px solid ${theme.palette.background_border}`
          : 'none',
        ...props.style,
      }}
    >
      <div className={classes.note_view_container}>
        <div className={classes.note_text_container}>
          <p
            className={classes.note_data}
            style={{ border: 0 }}
            dangerouslySetInnerHTML={{ __html: props?.data?.note }}
          />
          {props?.data?.createdBy?._id === userId() && (
            <div
              className={classes.note_text_container}
              style={{ marginLeft: 'auto' }}
            >
              <CustomizedTooltips title={Strings.EDIT_NOTE}>
                <img
                  className={classes.action_icons}
                  src={icons(theme).editIcon}
                  alt=''
                  onClick={() => props.onEditClick()}
                />
              </CustomizedTooltips>
              {props?.deleteLoader ? (
                <CircularProgress
                  style={{
                    color: theme.palette.background_button,
                    marginLeft: 15,
                  }}
                  size={20}
                />
              ) : (
                <CustomizedTooltips title={Strings.DELETE_NOTE}>
                  <img
                    className={classes.action_icons}
                    src={icons(theme).deleteIcon}
                    alt=''
                    onClick={() => props.onDeleteClick()}
                    style={{ marginLeft: 15 }}
                  />
                </CustomizedTooltips>
              )}
            </div>
          )}
        </div>
      </div>
      {props?.data?.createdBy && (
        <div className={classes.note_details_container}>
          <p
            className={classes.note_data}
            style={{ paddingBottom: 0, marginBottom: 0 }}
          >
            <span style={{ fontWeight: 600 }}>{Strings.CREATED_BY}: </span>
            {`${props?.data?.createdBy?.name} ( ${
              moment(props?.data?.createdAt).format('ll') + // LL - Jul 14, 2023
              ' ' +
              moment(props?.data?.createdAt).format('LT')
            } )`}
          </p>
        </div>
      )}
    </div>
  );
};

// ===== table row =====
const Rows = (props) => {
  const { data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const cursor = !isClient() ? 'pointer' : 'auto';
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const laptopView = useMediaQuery(theme.breakpoints.down('lg'));

  const [openRow, setOpenRow] = useState(false);
  const [activeTab, setActiveTab] = useState('details');

  const getJobTabsList = (elData) => {
    if (elData) {
      let internalNotes =
        elData?.jobNotes?.filter((item) => item?.internalNotes) || [];
      let notes =
        elData?.jobNotes?.filter((item) => !item?.internalNotes) || [];
      return isClient()
        ? [
            { id: 'details', name: 'Job Summary', hideDataCount: true },
            {
              id: 'notes',
              name: 'Comments and History',
              length: notes?.length || 0,
            },
            { id: 'media', name: 'Media', hideDataCount: true },
          ]
        : [
            { id: 'details', name: 'Job Summary', hideDataCount: true },
            // {
            //   id: 'notes',
            //   name: 'Comments and History',
            //   length: notes?.length || 0,
            // },
            {
              id: 'internalNotes',
              name: 'Internal Notes',
              length: internalNotes?.length || 0,
            },
            { id: 'media', name: 'Media', hideDataCount: true },
          ];
    }
  };

  const cellStyle = (hideBorder, hideBgColor) => {
    return {
      padding: '12px 16px',
      borderBottom: hideBorder
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
      background:
        data?.userAvailability === 'Here'
          ? Colors.DEPARTURE_EVENT
          : hideBgColor
          ? 'transparent'
          : theme.palette.table_row_background,
    };
  };

  const showTabNotification = (type) => {
    let isShow = data?.jobNotes
      ?.filter((el) =>
        type === 'notes' ? !el?.internalNotes : el?.internalNotes
      )
      ?.some((el) => !el?.isRead);
    return isShow;
  };

  return (
    <React.Fragment>
      <TableRow onClick={props?.onRowClick} style={{ cursor: cursor }}>
        <TableCell
          width={16}
          style={{ ...cellStyle(openRow, !openRow), paddingRight: 0 }}
        >
          {!props?.isMainJob ? (
            <Checkbox
              isTableCheckbox
              value={props?.isJobSelected}
              checked={props?.isJobSelected}
              onChange={props?.onSelectJob}
            />
          ) : null}
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <IconButton
              disableRipple
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenRow(!openRow);
              }}
            >
              <RenderUpDownIcon isOpen={openRow} />
            </IconButton>
            <label className={classes.name_cell} style={{ cursor: cursor }}>
              {data?.title || '-'}
            </label>
          </Stack>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <label className={classes.common_cell} style={{ cursor: cursor }}>
            {data?.property?.name || '-'}
          </label>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <label className={classes.common_cell} style={{ cursor: cursor }}>
            {data?.startDate
              ? moment(data?.startDate)?.format('DD MMM, YYYY')
              : '-'}
          </label>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <label className={classes.common_cell} style={{ cursor: cursor }}>
            {getDuration(data?.startDate, data?.endDate) || '-'}
          </label>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <label className={classes.common_cell} style={{ cursor: cursor }}>
            {data?.jobForm?.formName || '-'}
          </label>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <label className={classes.common_cell} style={{ cursor: cursor }}>
            {data?.assignedMember?.name || '-'}
          </label>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <Stack direction='column' alignItems='flex-start'>
            <label
              className={classNames(
                classes.common_cell,
                classes.status_container
              )}
              style={{
                cursor: cursor,
                backgroundColor: statusColor(data?.jobStatus),
              }}
            >
              {data?.jobStatus}
            </label>
          </Stack>
        </TableCell>
        <TableCell style={{ ...cellStyle(openRow, !openRow) }}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={1}
          >
            <CustomizedTooltips title={Strings.VIEW_REPORT}>
              <img
                className={classes.action_icons}
                src={
                  data?.report && data?.reportStatus
                    ? icons(theme).chartIcon
                    : icons(theme).chartIconDisable
                }
                alt=''
                onClick={(e) => {
                  e.stopPropagation();
                  data?.report && data?.reportStatus && props?.onReportClick(e);
                }}
              />
            </CustomizedTooltips>
            <CustomizedTooltips title={Strings.ADD_INTERNAL_NOTE}>
              <img
                className={classes.action_icons}
                style={{ marginLeft: 15 }}
                src={icons(theme).addNote}
                alt=''
                onClick={props?.onAddNoteClick}
              />
            </CustomizedTooltips>
            <CustomizedTooltips title={Strings.EDIT_JOB}>
              <img
                className={classes.action_icons}
                src={icons(theme).editIcon}
                alt=''
                onClick={props?.onEditClick}
              />
            </CustomizedTooltips>
            {props?.isMainJob ? (
              <CustomizedTooltips title={Strings.DELETEJOB}>
                <img
                  className={classes.action_icons}
                  src={icons(theme).deleteIcon}
                  alt=''
                  onClick={props?.onDeleteClick}
                />
              </CustomizedTooltips>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={9}
          style={{ ...cellStyle(!openRow), padding: 0 }}
          className={classNames({
            [classes.formFieldsRow]: openRow,
          })}
        >
          <Collapse in={openRow} timeout='auto' unmountOnExit>
            <Stack flex={1} p={2}>
              <Tabs
                underlineTabs
                // noDataLength
                containerClassName={classes.job_details_container}
                tabClassName={classes.job_details_tab}
                dataList={getJobTabsList(data)}
                activeTab={activeTab}
                onChange={(value) => {
                  setActiveTab(value);
                  props?.onTabClick(data?._id, value);
                }}
                notesNotification={showTabNotification('notes')}
                internalNotesNotification={showTabNotification('internalNotes')}
                tabIndex={props?.index}
              />
              {activeTab === 'details' ? (
                <>
                  {data?.details ? (
                    <NotesDataList data={{ note: data?.details }} />
                  ) : null}
                  {data?.createdBy && data?.createdBy?.name ? (
                    <NotesDataList
                      data={`${Strings.REPORTED_BY} ${data?.createdBy?.name}`}
                      style={{ marginTop: 15 }}
                    />
                  ) : null}
                </>
              ) : (activeTab === 'notes' || activeTab === 'internalNotes') &&
                data?.jobNotes?.length ? (
                <>
                  {data?.jobNotes
                    ?.filter(
                      (el) =>
                        (activeTab === 'notes' && !el?.internalNotes) ||
                        (activeTab === 'internalNotes' && el?.internalNotes)
                    )
                    ?.map((el, i) => {
                      let newData = structuredClone(data?.jobNotes);
                      newData = newData?.filter(
                        (el) =>
                          (activeTab === 'notes' && !el?.internalNotes) ||
                          (activeTab === 'internalNotes' && el?.internalNotes)
                      );
                      const isBottomBorder = newData?.length !== i + 1;
                      return (
                        <NotesDataList
                          key={i}
                          data={el}
                          isBottomBorder={isBottomBorder}
                          onEditClick={() => {
                            let newData = {
                              jobId: data?._id,
                              noteId: el?._id,
                              note: el?.note,
                              internalNotes: el?.internalNotes,
                            };
                            props?.onEditNoteClick(newData);
                          }}
                          deleteLoader={
                            props?.selectedNoteId === el?._id &&
                            props?.deleteJobNoteLoading
                          }
                          onDeleteClick={() => {
                            props?.setSelectedNoteId(el?._id);
                            props?.onDeleteNoteClick(data?._id, el?._id);
                          }}
                        />
                      );
                    })}
                </>
              ) : activeTab === 'media' ? (
                data?.attachments &&
                data?.attachments.length > 0 && (
                  <Stack mt={1}>
                    <MediaImageContainer
                      imageArray={data?.attachments}
                      itemsPerRow={
                        mobileView ? 2 : tabletView ? 3 : laptopView ? 4 : 5
                      }
                    />
                  </Stack>
                )
              ) : null}
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const JobTableBody = (props) => {
  const { listData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [showCompletedJobs, setShowCompletedJobs] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [jobStatus, setJobStatus] = useState('New');

  useEffect(() => {
    if (listData?.length) {
      setJobList(listData);
    }
  }, [listData]);

  // ===== call for select all jobs =====
  const selectAllJob = () => {
    if (!selectedJobs?.length && jobList?.length) {
      let data = structuredClone(jobList);
      data = data?.map((el) => el?._id);
      data = data?.filter((val, i) => data.indexOf(val) === i);
      setSelectedJobs(data);
    } else {
      setSelectedJobs([]);
    }
  };

  // ===== call when select particular job =====
  const onSelectJob = (jID) => {
    if (jID) {
      let data = structuredClone(selectedJobs);
      let fIndex = selectedJobs?.findIndex((el) => el === jID);
      if (fIndex === -1) {
        data = [...data, jID];
      } else {
        data.splice(fIndex, 1);
      }
      setSelectedJobs(data);
    }
  };

  // ===== call when jobs any action call =====
  const onActionClick = (e, data, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'viewReport') {
      navigate('/app/report', {
        state: { jobId: data?._id },
      });
    } else if (type === 'addNote') {
      props?.onAddNoteClick(data?._id);
    } else if (type === 'editJob') {
      props?.onEditClick(data);
    } else if (type === 'deleteJob') {
      props?.onDeleteClick(data?._id);
    } else props?.onRowClick(data?._id);
  };

  // ===== call when click show completed jobs =====
  useEffect(() => {
    const copyJobs = structuredClone(listData);
    if (showCompletedJobs) {
      const data = copyJobs?.filter((el) => el?.jobStatus === 'Completed');
      setJobList(data);
    } else setJobList(listData);
  }, [showCompletedJobs]);

  return (
    <>
      {props?.isShowSectionRow ? (
        <TableRow>
          <TableCell
            style={{ padding: 0, paddingTop: 16, border: 'none' }}
            colSpan={9}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              style={{
                borderBottom: `1px solid ${
                  props?.isPrimarySection
                    ? theme.palette.background_button
                    : theme.palette.background_border
                }`,
              }}
            >
              <Stack
                py={1}
                px={2}
                spacing={1.25}
                direction='row'
                alignItems='center'
                className={classes.title_box}
                style={{
                  backgroundColor:
                    props?.isPrimarySection && theme.palette.background_button,
                }}
                width={
                  !props?.isMainJob && selectedJobs?.length ? '100%' : 'auto'
                }
              >
                {!props?.isMainJob ? (
                  <Checkbox
                    isTableCheckbox
                    value={selectedJobs?.length ? true : false}
                    checked={selectedJobs?.length ? true : false}
                    onChange={() => selectAllJob()}
                    checkedIcon={icons(theme).minusWhiteIcon}
                  />
                ) : null}
                <label
                  className={classes.title_text}
                  style={{ color: props?.isPrimarySection && Colors.WHITE }}
                >
                  {props?.sectionTitle}
                </label>
                {!props?.isMainJob && selectedJobs?.length ? (
                  <Stack
                    direction='row'
                    alignItems='center'
                    ml={1.25}
                    pl={1.25}
                    borderLeft={`1px solid ${Colors.WHITE}`}
                    flex={1}
                  >
                    <label
                      className={classes.title_text}
                    >{`${selectedJobs?.length} Job Selected`}</label>
                    <Stack
                      ml='auto'
                      direction='row'
                      alignItems='center'
                      spacing={1.25}
                    >
                      <Dropdown
                        name='jobStatus'
                        onChange={(e) => setJobStatus(e.target.value)}
                        value={jobStatus}
                        title={Strings.SELECT_AN_OPTION}
                        containerClassName={classes.form_input_container}
                        optionArray={jobStatusArray}
                      />
                      <Button
                        text={Strings.UPDATE_STATUS}
                        loading={props?.updateLoading}
                        disabled={props?.updateLoading}
                        onClick={() =>
                          props?.onUpdateStatusClick?.(jobStatus, selectedJobs)
                        }
                        className={classes.add_button}
                        loaderColor={theme.palette.background_button_text}
                      />
                      <IconButton
                        disableRipple
                        className={classes.section_delete_button}
                        onClick={() => props?.onSeriesDeleteClick(selectedJobs)}
                      >
                        <img
                          src={icons(theme).deleteIcon}
                          className={classes.action_icons}
                        />
                        {Strings.DELETE}
                      </IconButton>
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
              {!props?.isMainJob && !selectedJobs?.length ? (
                <Stack
                  py={1}
                  direction='row'
                  alignItems='center'
                  spacing={1.25}
                >
                  <Checkbox
                    isTableCheckbox
                    value={showCompletedJobs}
                    checked={showCompletedJobs}
                    onChange={(val) => setShowCompletedJobs(!val)}
                  />
                  <label className={classes.title_text}>
                    {Strings.SHOW_COMPLETED_JOBS?.toUpperCase()}
                  </label>
                </Stack>
              ) : null}
            </Stack>
          </TableCell>
        </TableRow>
      ) : null}
      {jobList?.map((el, index) => {
        const isSelected = selectedJobs?.some((item) => item === el?._id);
        return (
          <Rows
            key={index}
            data={el}
            index={index}
            isJobSelected={isSelected}
            isMainJob={props?.isMainJob}
            onSelectJob={() => onSelectJob(el?._id)}
            onRowClick={(e) => onActionClick(e, el, 'viewDetails')}
            onEditClick={(e) => onActionClick(e, el, 'editJob')}
            onDeleteClick={(e) => onActionClick(e, el, 'deleteJob')}
            onAddNoteClick={(e) => onActionClick(e, el, 'addNote')}
            onReportClick={(e) => onActionClick(e, el, 'viewReport')}
            onEditNoteClick={(data) => props?.onEditNoteClick(el?._id, data)}
            selectedNoteId={props?.selectedNoteId}
            setSelectedNoteId={props?.setSelectedNoteId}
            onDeleteNoteClick={props?.onDeleteNoteClick}
            deleteJobNoteLoading={props?.deleteJobNoteLoading}
            onTabClick={props?.onTabClick}
          />
        );
      })}
    </>
  );
};

JobTableBody.propTypes = {
  listData: PropTypes.array,
  onRowClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAddNoteClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onEditNoteClick: PropTypes.func,
  onDeleteNoteClick: PropTypes.func,
  onTabClick: PropTypes.func,
  onSeriesDeleteClick: PropTypes.func,
  sectionTitle: PropTypes.string,
  isShowSectionRow: PropTypes.bool,
  isPrimarySection: PropTypes.bool,
  isMainJob: PropTypes.bool,
  onUpdateStatusClick: PropTypes.func,
  updateLoading: PropTypes.bool,
};

export default JobTableBody;
