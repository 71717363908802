import React, { useState, useEffect, useCallback } from 'react';
import TableToolbox from '../../components/TableToolbox';
import NewUser from './NewUser';
import useStyles from './styles';
import {
  Avatar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EnhancedTableHead from '../../components/DataTables/TableHead';
import { connect } from 'react-redux';
import { fetchTeamUserList } from '../../redux/actions/adminActions/teamUserList';
import {
  PAGE_SIZE,
  applySortFilter,
  getComparator,
  getErrorMessage,
  getLocaleStorageVal,
  phoneFormatUS,
  setLocaleStorageVal,
  tableCell,
} from '../../utils/appUtils';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from '@material-ui/core';
import { Strings } from '../../utils/strings';
import { useAlert } from 'react-alert';
import { sendUserPassword } from '../../redux/actions/adminActions/sendUserPassword';
import { icons } from '../../utils/icons';
import CommonDialog from '../../components/CommonDialog';
import { deleteUser } from '../../redux/actions/adminActions/deleteUser';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import debounce from 'lodash/debounce';
import CustomPagination from '../../components/CustomPagination';

const teamDataStore = 'TeamData';

// ======== Table Head =========
const headCells = [
  { id: 'name', label: 'NAME', isSort: true },
  { id: 'email', label: 'EMAIL', isSort: true },
  { id: 'phoneNumber', label: 'PHONE' },
  { id: 'role', label: 'ROLE', isSort: true },
  { id: 'createdAt', label: 'CREATED ON', isSort: true },
  { id: '' },
];

const Team = (props) => {
  const classes = useStyles();
  const existingTeamData = getLocaleStorageVal(teamDataStore);
  const pageFilter = existingTeamData?.pageFilter;
  const [newDialog, setNewDialog] = useState(false);
  const alert = useAlert();
  // const [teamUserListData, setTeamUserListData] = useState([]);
  const teamUserListData = props.teamUserListData?.users || [];
  const [search, setSearch] = useState(existingTeamData?.searchString || '');
  const theme = useTheme();
  const [sendPasswordUser, setSendPasswordUser] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(
    existingTeamData?.sortBy
      ? existingTeamData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingTeamData?.sortBy?.key || '');
  const [pageCount, setPageCount] = useState(pageFilter?.pageNumber || 1);
  const [pageSize, setPageSize] = useState(pageFilter?.pageSize || PAGE_SIZE);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getTeamData = (data) => {
    const existData = getLocaleStorageVal(teamDataStore);
    let newData = structuredClone(data) || {};
    let existSortBy = existData?.sortBy;
    if (!newData?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (!newData?.page_size)
      newData.page_size = pageFilter?.pageSize || pageSize;
    if (!newData?.sortBy && existSortBy)
      newData.sortBy = { [existSortBy?.key]: existSortBy?.value };
    props?.fetchTeamUserList(newData, onError);
  };

  const delayedSearch = useCallback(
    debounce((q) => getTeamData({ searchString: q, page_number: 1 }), 600),
    [pageSize]
  );

  useEffect(() => {
    getTeamData({ searchString: search });
  }, [props.addUserData, props.deleteUserData, props.updateUserData]);

  // useEffect(() => {
  //   const data = props.teamUserListData ? props.teamUserListData : [];
  //   setTeamUserListData(data);
  // }, [props.teamUserListData]);

  // const handleSendPassword = (_id) => {
  //   setSendPasswordUser(_id);
  //   props.sendUserPassword(
  //     _id,
  //     () => {
  //       setSendPasswordUser('');
  //       props.fetchTeamUserList(search, (err) => onError(err));
  //     },
  //     (err) => {
  //       setSendPasswordUser('');
  //       onError(err);
  //     }
  //   );
  // };

  const onDeleteData = () => {
    props.deleteUser(
      selectedUser._id,
      () => setDeleteUserDialog(false),
      (err) => onError(err)
    );
  };

  // const descendingComparator = (a, b, orderBy) => {
  //   let asceding = b[orderBy] < a[orderBy];
  //   let descding = b[orderBy] > a[orderBy];
  //   if (orderBy === 'name') {
  //     asceding = b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase();
  //     descding = b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase();
  //   } else if (orderBy === 'createdAt') {
  //     asceding = moment(b[orderBy]) < moment(a[orderBy]);
  //     descding = moment(b[orderBy]) > moment(a[orderBy]);
  //   } else {
  //     asceding = b[orderBy] < a[orderBy];
  //     descding = b[orderBy] > a[orderBy];
  //   }
  //   if (asceding) return -1;
  //   if (descding) return 1;
  //   return 0;
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getTeamData({ searchString: search, sortBy });
    setLocaleStorageVal(teamDataStore, {
      ...existingTeamData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredTeams = applySortFilter(
  //   teamUserListData,
  //   getComparator(order, orderBy, descendingComparator)
  // );

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    if (page !== pageCount) {
      let data = { searchString: search, page_number: page };
      setPageCount(page);
      getTeamData(data);
      setLocaleStorageVal(teamDataStore, {
        ...existingTeamData,
        pageFilter: { pageNumber: page, pageSize },
      });
    }
  };

  // ===== call when page size change =====
  const handlePageSize = (val) => {
    if (pageSize !== val) {
      let data = { searchString: search, page_size: val };
      setPageSize(val);
      setPageCount(1);
      getTeamData(data);
      setLocaleStorageVal(teamDataStore, {
        ...existingTeamData,
        pageFilter: { pageNumber: 1, pageSize: val },
      });
    }
  };

  return (
    <Stack className={classes.team_container}>
      <TableToolbox
        isSearch
        isPrimaryButton
        search={search}
        onChangeSearch={(e) => {
          delayedSearch(e.target.value);
          setSearch(e.target.value);
          setPageCount(1);
          setLocaleStorageVal(teamDataStore, {
            ...existingTeamData,
            searchString: e.target.value,
          });
        }}
        onNewClick={() => setNewDialog(true)}
      />
      <div className={classes.list_container}>
        {props.teamUserListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {teamUserListData.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {teamUserListData &&
                      teamUserListData?.map((data, index) => {
                        const {
                          name,
                          email,
                          createdAt,
                          phoneNumber,
                          role,
                          profilePicture,
                          _id,
                          // passwordSent,
                        } = data;
                        return (
                          <TableRow key={data._id} sx={{ width: '100%' }}>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack
                                spacing={2}
                                direction='row'
                                alignItems='center'
                              >
                                <Avatar
                                  alt=''
                                  src={profilePicture}
                                  sx={{ width: 32, height: 32 }}
                                />
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.name_cell}>
                                    {name}
                                  </label>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {email}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {phoneFormatUS(phoneNumber)}
                                  {/* {phoneNumber} */}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {role}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {moment(createdAt).format('MMM D, YYYY')}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              {role !== 'superadmin' ? (
                                <Stack
                                  direction='row'
                                  justifyContent='end'
                                  alignItems='flex-start'
                                >
                                  {/* {!passwordSent && (
                                <>
                                  {sendPasswordUser === _id ? (
                                    <CircularProgress
                                      size={24}
                                      style={{
                                        color: theme.palette.background_button,
                                        marginRight: 15,
                                      }}
                                    />
                                  ) : (
                                    <CustomizedTooltips
                                      title={Strings.SEND_PASSWORD}
                                    >
                                      <img
                                        className={classes.action_icons}
                                        src={icons(theme).reportSendIcon}
                                        alt=''
                                        style={{ marginRight: 15 }}
                                        onClick={(e) => {
                                          handleSendPassword(_id);
                                        }}
                                      />
                                    </CustomizedTooltips>
                                  )}
                                </>
                              )} */}
                                  <CustomizedTooltips
                                    title={Strings.EDIT_MEMBER}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).editIcon}
                                      style={{ marginRight: 15 }}
                                      alt=''
                                      onClick={(e) => {
                                        setIsEdit(true);
                                        setSelectedUser(data);
                                        setNewDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                  <CustomizedTooltips
                                    title={Strings.DELETE_MEMBER}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).deleteIcon}
                                      alt=''
                                      onClick={(e) => {
                                        setSelectedUser(data);
                                        setDeleteUserDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                </Stack>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>

      {props?.teamUserListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.teamUserListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSize}
          />
        </Stack>
      ) : null}

      {deleteUserDialog && (
        <CommonDialog
          title={Strings.TEAM}
          message={Strings.DELETE_TEAM_MEMBER}
          onSuccess={() => onDeleteData()}
          onClose={() => {
            setDeleteUserDialog(false);
          }}
          loading={props.deleteUserLoading}
        />
      )}

      {newDialog && (
        <NewUser
          data={selectedUser}
          isEdit={isEdit}
          onNewClose={() => {
            setIsEdit(false);
            setNewDialog(false);
          }}
        />
      )}
    </Stack>
  );
};
const mapStateToProps = (state) => ({
  addUserData: state.addUserData.data,
  teamUserListData: state.teamUserListData.data,
  teamUserListLoading: state.teamUserListData.loading,

  updateUserData: state.updateUserData.data,

  deleteUserData: state.deleteUserData.data,
  deleteUserLoading: state.deleteUserData.loading,

  sendUserPasswordData: state.sendUserPasswordData.data,
  sendUserPasswordLoading: state.sendUserPasswordData.loading,
});

export default connect(mapStateToProps, {
  fetchTeamUserList,
  sendUserPassword,
  deleteUser,
})(Team);
