import React from 'react';
import useStyles from './styles';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Colors } from '../../utils/color';
import classNames from 'classnames';

export const CommonSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 19,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '3px 4px',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.form_switch_background,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'dark' ? 1 : 0.2,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: `0 2px 4px 0 ${Colors.SWITCH_THUMB}`,
    width: 13,
    height: 13,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: Colors.WHITE,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: 'rgba(88, 88, 90, 0.5)',
    boxSizing: 'border-box',
    boxShadow:
      '0px 0px 4px rgba(59, 104, 255, 0.1), inset 0px 0px 3px rgba(59, 104, 255, 0.38)',
  },
}));

const FormSwitch = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.switch_container,
        props.containerClassName && props.containerClassName,
      )}>
      {props.label && (
        <label
          className={classNames(
            classes.switch_label,
            props.textClassName && props.textClassName,
          )}>
          {props.label}
        </label>
      )}
      <CommonSwitch
        checked={props.value}
        onChange={props.onChange}
        inputProps={{ 'aria-label': 'ant design' }}
      />
    </div>
  );
};

export default FormSwitch;
