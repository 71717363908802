import { GET_ROOMS_LIST } from '../../constants';

const initialState = {
  rooms: [],
  loading: false,
  error: {},
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
    case GET_ROOMS_LIST.LOAD:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOMS_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: payload,
      };
    case GET_ROOMS_LIST.FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
