import DownArrowWhite from '../assets/images/down-arrow-white.svg';
import DownArrow from '../assets/images/down-arrow.svg';
import RightArrow from '../assets/images/right-arrow.svg';
import RightArrowBlue from '../assets/images/right-arrow-blue.svg';
import DeleteIcon from '../assets/images/delete-icon.svg';
import DeleteIconBlue from '../assets/images/delete-icon-blue.svg';
import ListIconGray from '../assets/images/list-icon.svg';
import RightIcon from '../assets/images/right-icon.svg';
import CloseIcon from '../assets/images/close.svg';
import CloseIconWhite from '../assets/images/close-white.svg';
import CalendarIcon from '../assets/images/calendar-icon.svg';
import ImageIcon from '../assets/images/img-file-icon.svg';
import Plus from '../assets/images/plus-dark-blue.svg';
import PlusBlue from '../assets/images/plus-light-blue.svg';
import LeftArrow from '../assets/images/left-arrow.svg';
import LeftArrowBlue from '../assets/images/left-arrow-blue.svg';
import SearchIcon from '../assets/images/search-icon.svg';
import SearchIconWhite from '../assets/images/search-icon-white.svg';
import FilterIcon from '../assets/images/filter-icon.svg';
import ClearFilterIcon from '../assets/images/clear-filter-icon.svg';
import BackIcon from '../assets/images/back-icon.svg';
import DownloadIcon from '../assets/images/download.svg';
import DownloadIconBlue from '../assets/images/downloadBlue.svg';
import Clock from '../assets/images/clock-icon.svg';
import LogoutIcon from '../assets/images/logout-icon.svg';
import LogoutIconWhite from '../assets/images/logout-icon-white.svg';
import BellIcon from '../assets/images/bell-icon.svg';
import logo from '../assets/images/sidebar-logo.svg';
import Gallery from '../assets/images/gallery-icon.svg';
import LocationIcon from '../assets/images/location-icon.svg';
import SendIcon from '../assets/images/send_icon.svg';
import BrowseIcon from '../assets/images/browse-icon.svg';
import ClockIcon from '../assets/images/note-clock.svg';
import AddIcon from '../assets/images/add-icon.svg';
import EditIcon from '../assets/images/edit-icon.svg';
import EditIconBlue from '../assets/images/edit-icon-blue.svg';
import ArrivalIcon from '../assets/images/arrival-icon.svg';
import DeparturesIcon from '../assets/images/departures-icon.svg';
import ChartIcon from '../assets/images/chart-icon.svg';
import ChartIconDisable from '../assets/images/chart-icon-disable.svg';
import ReportSendIcon from '../assets/images/send-icon.svg';
import RecurringIcon from '../assets/images/recurring-icon.svg';
import Location from '../assets/images/location.svg';
import Calender from '../assets/menu-icons/calendar.svg';
import CalenderActive from '../assets/menu-icons/calendar-active.svg';
import Client from '../assets/menu-icons/clients.svg';
import ClientWhite from '../assets/menu-icons/clients-dark.svg';
import ModalArrow from '../assets/images/modal-arrow.svg';
import ModalArrowBlack from '../assets/images/modal-arrow-black.svg';
import DropDownIcon from '../assets/images/dropdown-icon.svg';
import TextInputIcon from '../assets/images/textinput-icon.svg';
import RadioIcon from '../assets/images/radio-icon.svg';
import UploadFileIcon from '../assets/images/uploadfile-icon.svg';
import CheckListIcon from '../assets/images/checklist-icon.svg';
import NewGroupIcon from '../assets/images/newgroup-icon.svg';
import Login_Image from '../assets/images/login-image.png';
import Login_Logo from '../assets/images/login-logo.svg';
import Gray_Login_Logo from '../assets/images/gray-login-logo.svg';
import Login_Text from '../assets/images/login-text.svg';
import CameraIcon from '../assets/images/camera-icon.svg';
import Dashboard from '../assets/menu-icons/dashboard.svg';
import DashboardDark from '../assets/menu-icons/dashboard-dark.svg';
import DashboardActive from '../assets/menu-icons/dashboard-active.svg';
import DashboardActiveDark from '../assets/menu-icons/dashboard-active-dark.svg';
import ClientsActive from '../assets/menu-icons/clients-active.svg';
import ClientsActiveDark from '../assets/menu-icons/clients-active-dark.svg';
import Team from '../assets/menu-icons/team.svg';
import TeamActive from '../assets/menu-icons/team-active.svg';
import Vendor from '../assets/menu-icons/vendor.svg';
import VendorActive from '../assets/menu-icons/vendor-active.svg';
import Jobs from '../assets/menu-icons/jobs.svg';
import JobsActive from '../assets/menu-icons/jobs-active.svg';
import Issues from '../assets/menu-icons/issues.svg';
import IssuesActive from '../assets/menu-icons/issues-active.svg';
import Issues1 from '../assets/menu-icons/issues1.svg';
import Issues1Active from '../assets/menu-icons/issues1-active.svg';
import Reports from '../assets/menu-icons/reports.svg';
import ReportsActive from '../assets/menu-icons/reports-active.svg';
import Chat from '../assets/menu-icons/chat.svg';
import ChatActive from '../assets/menu-icons/chat-active.svg';
import Media from '../assets/menu-icons/media.svg';
import MediaActive from '../assets/menu-icons/media-active.svg';
import Property from '../assets/menu-icons/property.svg';
import PropertyActive from '../assets/menu-icons/property-active.svg';
import BrowseIconWhite from '../assets/images/browse-icon-white.svg';
import EyeIconWhite from '../assets/images/eye-icon-white.svg';
import EyeIconDark from '../assets/images/eye-icon-dark.svg';
import PrintIcon from '../assets/images/printer-icon.svg';
import GreenUpArrow from '../assets/images/green-uparrow.svg';
import MenuIcon from '../assets/images/menu-icon.svg';
import CalendarDark from '../assets/menu-icons/calendar-dark.svg';
import CalendarActiveDark from '../assets/menu-icons/calendar-active-dark.svg';
import JobsDark from '../assets/menu-icons/jobs-dark.svg';
import TeamDark from '../assets/menu-icons/team-dark.svg';
import TeamActiveDark from '../assets/menu-icons/team-active-dark.svg';
import VendorDark from '../assets/menu-icons/vendor-dark.svg';
import VendorActiveDark from '../assets/menu-icons/vendor-active-dark.svg';
import IssuesDark from '../assets/menu-icons/issues-dark.svg';
import IssuesActiveDark from '../assets/menu-icons/issues-active-dark.svg';
import MediaDark from '../assets/menu-icons/media-dark.svg';
import MediaActiveDark from '../assets/menu-icons/media-active-dark.svg';
import PropertyDark from '../assets/menu-icons/property-dark.svg';
import PropertyActiveDark from '../assets/menu-icons/property-active-dark.svg';
import ReportsDark from '../assets/menu-icons/report-dark.svg';
import ReportsActiveDark from '../assets/menu-icons/report-active-dark.svg';
import DummyFile from '../assets/images/dummyfile.svg';
import FileIcon from '../assets/images/doc-file-icon.svg';
import PlusIcon from '../assets/images/plus.svg';
import MinusIcon from '../assets/images/minus.svg';
import AddNote from '../assets/images/add-note.svg';
import AddNoteBlue from '../assets/images/add-note-blue.svg';
import SortIcon from '../assets/images/sort-icon.svg';
import EyeIcon from '../assets/images/eye-icon.svg';
import EyeIconBlue from '../assets/images/eye-icon-blue.svg';
import EyeOffIcon from '../assets/images/eyeoff-icon.svg';
import EyeOffIconBlue from '../assets/images/eyeoff-icon-blue.svg';
import FolderBlue from '../assets/images/folder-blue.png';
import WhiteMenuIcon from '../assets/images/white-menu.svg';
import attachmentIcon from '../assets/images/attachment.svg';
import attachmentIconBlue from '../assets/images/attachment-blue.svg';
import imageFileIcon from '../assets/file-type-icons/image-icon.svg';
import docFileIcon from '../assets/file-type-icons/doc-icon.svg';
import audioFileIcon from '../assets/file-type-icons/audio-icon.svg';
import videoFileIcon from '../assets/file-type-icons/video-icon.svg';
import excelFileIcon from '../assets/file-type-icons/excel-icon.svg';
import pptFileIcon from '../assets/file-type-icons/ppt-icon.svg';
import zipFileIcon from '../assets/file-type-icons/zip-icon.svg';
import pdfFileIcon from '../assets/file-type-icons/pdf-icon.svg';
import undefinedFileIcon from '../assets/file-type-icons/undefined-icon.svg';
import NewFolderImage from '../assets/images/folder-image.svg';
import copyIcon from '../assets/images/copy-icon.svg';
import copyIconBlue from '../assets/images/copy-icon-blue.svg';
import removeIcon from '../assets/images/remove-icon.svg';
import sunnyIcon from '../assets/images/sunny.svg';
import sunBeamIcon from '../assets/images/sun-beam.svg';
import rainyIcon from '../assets/images/rainy.svg';
import cloudyIcon from '../assets/images/cloudy.svg';
import gradientLineIcon from '../assets/images/gradient-line.svg';
import snowIcon from '../assets/images/snow.svg';
import sunRainIcon from '../assets/images/sun-rain.svg';
import thunderStormIcon from '../assets/images/thunder-storm.svg';
import mistIcon from '../assets/images/mist.svg';
import refreshIcon from '../assets/images/refresh-icon.svg';
import redFlagIcon from '../assets/images/red-flag.svg';
import collapseLeftIcon from '../assets/images/collapse-left.svg';
import collapseLeftBlueIcon from '../assets/images/collapse-left-blue.svg';
import collapseRightIcon from '../assets/images/collapse-right.svg';
import collapseRightBlueIcon from '../assets/images/collapse-right-blue.svg';
import MinusWhiteIcon from '../assets/images/minus-white.svg';
import sortUpperArrow from '../assets/images/sort-upper-arrow.svg';
import sortUpperArrowBlue from '../assets/images/sort-upper-arrow-blue.svg';
import sortDownArrow from '../assets/images/sort-down-arrow.svg';
import sortDownArrowBlue from '../assets/images/sort-down-arrow-blue.svg';
import share from '../assets/images/share.svg';
import shareBlue from '../assets/images/share-blue.svg';
import copyLink from '../assets/menu-icons/copy-link.svg';
import loading from '../assets/images/loading.gif';
import EyeOffIconRed from '../assets/images/eyeoff-icon-red.svg';
import archive from '../assets/images/inbox.svg';
import unarchive from '../assets/images/inbox-in.svg';

export const icons = (theme) => {
  const isDarkMode = theme && theme.palette.mode === 'dark';

  return {
    //login-images
    loginImage: Login_Image,
    grayLoginLogo: Gray_Login_Logo,
    loginLogo: Login_Logo,
    loginText: Login_Text,
    plusIcon: PlusIcon,
    minusIcon: MinusIcon,
    addNote: isDarkMode ? AddNoteBlue : AddNote,
    sortIcon: SortIcon,
    minusWhiteIcon: MinusWhiteIcon,

    logo: logo,
    downArrow: isDarkMode ? DownArrowWhite : DownArrow,
    downArrowWhite: DownArrowWhite,
    rightArrow: isDarkMode ? RightArrowBlue : RightArrow,
    deleteIcon: isDarkMode ? DeleteIconBlue : DeleteIcon,
    listIcon: isDarkMode ? ListIconGray : ListIconGray,
    rightIcon: RightIcon,
    closeIcon: isDarkMode ? CloseIconWhite : CloseIcon,
    calendarIcon: isDarkMode ? CalendarIcon : CalendarIcon,
    imageIcon: isDarkMode ? ImageIcon : ImageIcon,
    Plus: isDarkMode ? PlusBlue : Plus,
    leftArrow: isDarkMode ? LeftArrowBlue : LeftArrow,
    searchIcon: isDarkMode ? SearchIconWhite : SearchIcon,
    filterIcon: isDarkMode ? FilterIcon : FilterIcon,
    backIcon: isDarkMode ? BackIcon : BackIcon,
    downloadIcon: isDarkMode ? DownloadIconBlue : DownloadIcon,
    clockIcon: isDarkMode ? Clock : Clock,
    logoutIcon: isDarkMode ? LogoutIconWhite : LogoutIcon,
    bellIcon: isDarkMode ? BellIcon : BellIcon,
    gallery: isDarkMode ? Gallery : Gallery,
    locationIcon: isDarkMode ? LocationIcon : LocationIcon,
    sendIcon: isDarkMode ? SendIcon : SendIcon,
    browseIcon: isDarkMode ? BrowseIconWhite : BrowseIcon,
    noteClockIcon: isDarkMode ? ClockIcon : ClockIcon,
    addIcon: isDarkMode ? AddIcon : AddIcon,
    editIcon: isDarkMode ? EditIconBlue : EditIcon,
    arrivalIcon: isDarkMode ? ArrivalIcon : ArrivalIcon,
    departuresIcon: isDarkMode ? DeparturesIcon : DeparturesIcon,
    chartIcon: isDarkMode ? ReportsDark : ChartIcon,
    chartIconDisable: isDarkMode ? ChartIconDisable : ChartIconDisable,
    reportSendIcon: isDarkMode ? ReportSendIcon : ReportSendIcon,
    recurringIcon: isDarkMode ? RecurringIcon : RecurringIcon,
    location: isDarkMode ? Location : Location,
    modalArrow: isDarkMode ? ModalArrowBlack : ModalArrow,
    cameraIcon: isDarkMode ? CameraIcon : CameraIcon,
    browseIconWhite: isDarkMode ? BrowseIconWhite : BrowseIconWhite,
    eyeIcon: isDarkMode ? EyeIconWhite : EyeIconDark,
    printIcon: isDarkMode ? PrintIcon : PrintIcon,
    greenUpArrow: isDarkMode ? GreenUpArrow : GreenUpArrow,
    closeWhiteIcon: isDarkMode ? CloseIconWhite : CloseIconWhite,
    copyIcon: isDarkMode ? copyIconBlue : copyIcon,
    removeIcon: isDarkMode ? removeIcon : removeIcon,
    menuIcon: isDarkMode ? MenuIcon : MenuIcon,
    refreshIcon: isDarkMode ? refreshIcon : refreshIcon,
    clearFilterIcon: isDarkMode ? ClearFilterIcon : ClearFilterIcon,
    archive: isDarkMode ? archive : archive,
    unarchive: isDarkMode ? unarchive : unarchive,

    eyeIconBlue: isDarkMode ? EyeIconBlue : EyeIcon,
    eyeOffIcon: isDarkMode ? EyeOffIconBlue : EyeOffIcon,

    eyeOffIconRed: isDarkMode ? EyeOffIconRed : EyeOffIconRed,

    folderIcon: isDarkMode ? FolderBlue : FolderBlue,
    newFolderIcon: isDarkMode ? NewFolderImage : NewFolderImage,

    redFlagIcon: isDarkMode ? redFlagIcon : redFlagIcon,

    collapseLeftIcon: isDarkMode ? collapseLeftIcon : collapseLeftBlueIcon,
    collapseRightIcon: isDarkMode ? collapseRightIcon : collapseRightBlueIcon,
    sortUpper: isDarkMode ? sortUpperArrowBlue : sortUpperArrow,
    sortDown: isDarkMode ? sortDownArrowBlue : sortDownArrow,
    shareIcon: isDarkMode ? shareBlue : share,

    //form-tool-icons
    dropDownIcon: isDarkMode ? DropDownIcon : DropDownIcon,
    textInputIcon: isDarkMode ? TextInputIcon : TextInputIcon,
    radioIcon: isDarkMode ? RadioIcon : RadioIcon,
    uploadFileIcon: isDarkMode ? UploadFileIcon : UploadFileIcon,
    checkListIcon: isDarkMode ? CheckListIcon : CheckListIcon,
    newGroupIcon: isDarkMode ? NewGroupIcon : NewGroupIcon,
    attachmentIcon: isDarkMode ? attachmentIconBlue : attachmentIcon,

    //menu-icons
    calender: isDarkMode ? CalendarDark : Calender,
    calenderActive: isDarkMode ? CalendarActiveDark : CalenderActive,
    client: isDarkMode ? ClientWhite : Client,
    clientActive: isDarkMode ? ClientsActiveDark : ClientsActive,
    dashboard: isDarkMode ? DashboardDark : Dashboard,
    dashboardActive: isDarkMode ? DashboardActiveDark : DashboardActive,
    team: isDarkMode ? TeamDark : Team,
    teamActive: isDarkMode ? TeamActiveDark : TeamActive,
    vendor: isDarkMode ? VendorDark : Vendor,
    vendorActive: isDarkMode ? VendorActiveDark : VendorActive,
    jobs: isDarkMode ? JobsDark : Jobs,
    jobsActive: isDarkMode ? JobsActive : JobsActive,
    issues: isDarkMode ? IssuesDark : Issues,
    issuesActive: isDarkMode ? IssuesActiveDark : IssuesActive,
    issues1: isDarkMode ? Issues1 : Issues1,
    issues1Active: isDarkMode ? Issues1Active : Issues1Active,
    reports: isDarkMode ? ReportsDark : Reports,
    reportsActive: isDarkMode ? ReportsActiveDark : ReportsActive,
    chat: isDarkMode ? Chat : Chat,
    chatActive: isDarkMode ? ChatActive : ChatActive,
    media: isDarkMode ? MediaDark : Media,
    mediaActive: isDarkMode ? MediaActiveDark : MediaActive,
    property: isDarkMode ? PropertyDark : Property,
    propertyActive: isDarkMode ? PropertyActiveDark : PropertyActive,
    dummyFile: isDarkMode ? DummyFile : DummyFile,
    fileIcon: isDarkMode ? FileIcon : FileIcon,
    WhiteMenuIcon: isDarkMode ? WhiteMenuIcon : WhiteMenuIcon,

    // file-type-icons
    imageFileIcon: isDarkMode ? imageFileIcon : imageFileIcon,
    docFileIcon: isDarkMode ? docFileIcon : docFileIcon,
    audioFileIcon: isDarkMode ? audioFileIcon : audioFileIcon,
    videoFileIcon: isDarkMode ? videoFileIcon : videoFileIcon,
    excelFileIcon: isDarkMode ? excelFileIcon : excelFileIcon,
    pptFileIcon: isDarkMode ? pptFileIcon : pptFileIcon,
    zipFileIcon: isDarkMode ? zipFileIcon : zipFileIcon,
    pdfFileIcon: isDarkMode ? pdfFileIcon : pdfFileIcon,
    undefinedFileIcon: isDarkMode ? undefinedFileIcon : undefinedFileIcon,

    // weather icons
    sunnyIcon: isDarkMode ? sunnyIcon : sunnyIcon,
    sunBeamIcon: isDarkMode ? sunBeamIcon : sunBeamIcon,
    rainyIcon: isDarkMode ? rainyIcon : rainyIcon,
    cloudyIcon: isDarkMode ? cloudyIcon : cloudyIcon,
    gradientLineIcon: isDarkMode ? gradientLineIcon : gradientLineIcon,
    snowIcon: isDarkMode ? snowIcon : snowIcon,
    sunRainIcon: isDarkMode ? sunRainIcon : sunRainIcon,
    thunderStormIcon: isDarkMode ? thunderStormIcon : thunderStormIcon,
    mistIcon: isDarkMode ? mistIcon : mistIcon,
    copyLink: copyLink,

    loading: loading,
  };
};
