import axios from 'axios';
import { VISIBLE_ISSUE } from '../../../constants';

export const visibleIssue = (issueId, data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadVisibleIssue());
  axios
    .put(`/issue/visibleIssue/?issueId=${issueId}`, data, config)
    .then((res) => {
      dispatch(setVisibleIssue(res));
    })
    .catch((err) => {
      dispatch(errorVisibleIssue(err.response));
      onError(err.response);
    });
};

export const loadVisibleIssue = () => ({
  type: VISIBLE_ISSUE.LOAD,
});

export const setVisibleIssue = (data) => ({
  type: VISIBLE_ISSUE.SUCCESS,
  payload: data.data,
});

export const errorVisibleIssue = (error) => ({
  type: VISIBLE_ISSUE.FAIL,
  payload: error,
});
