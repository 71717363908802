import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableToolbox from '../../../components/TableToolbox';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import MediaImageContainer from '../../../components/MediaImageContainer';
import {
  useMediaQuery,
  CircularProgress,
  Grid,
  Stack,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { icons } from '../../../utils/icons';
import Button from '../../../components/Button';
import ReportActionMenu from '../ActionMenu';
import {
  getErrorMessage,
  isAdmin,
  isClient,
  IssueFoundDetailList,
  issueStatusArray,
  issueTypeArray,
  isSuperAdmin,
  manageNotesHref,
  setLocaleStorageVal,
  statusColor,
} from '../../../utils/appUtils';
import { connect } from 'react-redux';
import { getReport } from '../../../redux/actions/jobActions/reportActions/getReport';
import { confirmIssue } from '../../../redux/actions/jobActions/reportActions/confirmIssue';
import { useAlert } from 'react-alert';
import moment from 'moment';
import NewIssue from '../../Issues/NewIssue';
import CustomRadioButton from '../../../components/CustomRadioButton';
import Textbox from '../../../components/Textbox';
import Dropdown from '../../../components/Dropdown';
import { CommonSwitch } from '../../../components/Switch';
import {
  updateReport,
  setUpdateReport,
} from '../../../redux/actions/jobActions/reportActions/updateReport';
import UploadFileWithTagsDialog from '../../../components/UploadFileWithTagsDialog';
import CommonDialog from '../../../components/CommonDialog';
import { downloadReport } from '../../../redux/actions/jobActions/reportActions/downloadReport';
import { markAsCompleteJob } from '../../../redux/actions/jobActions/reportActions/markAsCompleteJob';
import SendReport from '../../Jobs/SendReport';
import Checkbox from '../../../components/Checkbox';
import { useDispatch } from 'react-redux';
import DateSelector from '../../../components/DateSelector';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import DateTimeSelector from '../../../components/DateTimeSelector';
import { Colors } from '../../../utils/color';
import AddNotes from '../../Issues/NotesDialog';
import { updateIssue } from '../../../redux/actions/jobActions/issuesActions/updateIssue';
import { updateReportAction } from '../../../redux/actions/jobActions/reportActions/updateReportAction';
import { debounce } from 'lodash';
import CustomizedTooltips from '../../../components/CustomizedTooltips';

const ReportPropertyData = (props) => {
  const { title, value, datePickerValue, manageReportData, isNote } = props;
  const classes = useStyles();
  const [auditTitle, setAuditTitle] = useState(value || '');
  const [conductedOnDate, setConductedOnDate] = useState(datePickerValue || '');

  useEffect(() => {
    if (props?.reportData) {
      setAuditTitle(props?.reportData?.auditTitle);
    }
  }, [props?.reportData]);

  const onChangeAuditTrial = (value) => {
    setAuditTitle(value);
    const newReportData = {
      ...props.reportData,
      auditTitle: value,
    };
    manageReportData(newReportData);
  };

  const onChangeConductedOn = (value) => {
    setConductedOnDate(value);
    const newReportData = {
      ...props.reportData,
      conductedOn: value,
    };
    manageReportData(newReportData);
  };

  return (
    <div
      className={
        props.classContainer
          ? props.classContainer
          : classes.report_property_container
      }
      style={{ marginRight: 0 }}
    >
      <label className={classes.text_dark_blue}>{title.toUpperCase()}</label>
      {!props.isEditReport ? (
        isNote ? (
          <p
            className={classes.text_common}
            dangerouslySetInnerHTML={{
              __html: value?.replaceAll('\n', '<br>'),
            }}
          />
        ) : (
          <label className={classes.text_common}>{value || ''}</label>
        )
      ) : title === Strings.CONDUCTED_ON ? (
        <DateTimeSelector
          name='conductedOn'
          onChange={(e) => {
            onChangeConductedOn(e);
          }}
          value={conductedOnDate}
          format='MMM dd, yyyy hh:mm a'
          placeholder={Strings.SAMPLE_DATE}
          containerClassName={classes.date_selector_container}
        />
      ) : (
        <Textbox
          onChange={(e) => onChangeAuditTrial(e.target.value)}
          value={auditTitle ?? ''}
          placeholder={'Type Here'}
          disabled={!props.isEditReport}
          className={classes.form_input}
          containerClassName={classes.date_selector_container}
        />
      )}
    </div>
  );
};

const InformationBox = (props) => {
  const { data, index } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));

  const [fieldData, setFieldData] = useState(data ?? {});

  useEffect(() => {
    if (data && Object.entries(data)?.length) {
      setFieldData(data || {});
    }
  }, [data]);

  const onClickNewMedia = (type, index, item) => {
    props.onClickOpenNewMediaDialog(
      type,
      index,
      item,
      props.reportElementsIndex
    );
  };

  const handleCustomRadioButton = (id) => {
    let copyData = structuredClone(fieldData) || {};
    copyData.options = copyData?.options.map((item) => {
      if (item._id === id) {
        // if (fieldData?.elementType === 'Check List')
        item.selected = !item.selected;
        // else item.selected = true;
      } else {
        if (fieldData?.elementType !== 'Check List') item.selected = false;
        // item.selected = item.selected;
      }
      return item;
    });
    setFieldData(copyData);
    props?.manageGroupReportElemData?.(index, copyData);
  };

  const handleChange = (e, fieldName) => {
    let copyData = structuredClone(fieldData) || {};
    copyData[fieldName] = e.target.value;
    setFieldData(copyData);
    props?.manageGroupReportElemData?.(index, copyData);
  };

  const handleChangeAddToTask = (value) => {
    let copyData = structuredClone(fieldData) || {};
    copyData.issueCount =
      value === true
        ? copyData.issueCount + 1
        : copyData.issueCount <= 0
        ? 0
        : copyData.issueCount - 1;
    copyData.issueFound = value;
    setFieldData(copyData);
    props?.manageGroupReportElemData?.(index, copyData);
  };

  const handleChangeSwitch = (fieldName) => {
    let copyData = structuredClone(fieldData) || {};
    copyData[fieldName] = !copyData[fieldName];
    setFieldData(copyData);
    props?.manageGroupReportElemData?.(index, copyData);
  };

  const onMediaActionClick = (action, imgId, mainIndex, tags) => {
    props.onClickReportMediaMenuClick(
      'innerData',
      action,
      imgId,
      mainIndex,
      props.reportElementsIndex,
      tags
    );
  };

  if (!isClient(props.paramJobId) && props.isEditReport) {
    return (
      <>
        <div className={classes.report_elements_container}>
          <div className={classes.information_data_title_container}>
            <label
              className={classNames(classes.information_common_title, {
                [classes.mainItemSubtitle]: props?.isEditReport,
              })}
            >
              {fieldData?.title
                ? `${fieldData?.title} ${fieldData?.required ? '*' : ''}`
                : ''}
            </label>
            <div
              className={classes.flex_view}
              style={{
                flexDirection: isClient(props.paramJobId) ? 'column' : 'row',
              }}
            >
              {fieldData.issueCount > 0 &&
                !fieldData.issueConfirmed &&
                !props.paramJobId && (
                  <ReportActionMenu
                    id={fieldData._id}
                    confirmed={fieldData.issueConfirmed}
                    onSelect={(action, id) =>
                      props.actionOnClick(action, id, fieldData._id)
                    }
                  />
                )}

              {fieldData?.issueCount > 0 && (
                <label
                  className={classNames(
                    classes.issue_container,
                    fieldData?.issueCount > 0 && classes.red_issue_container
                  )}
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                >
                  {`${
                    fieldData?.issueCount +
                    ' ' +
                    Strings.ISSUE +
                    (fieldData.issueConfirmed ? ' ' + Strings.APPROVED : '')
                  }`}
                </label>
              )}
            </div>
          </div>
        </div>
        {/*======== group issues ============*/}
        <Grid
          container
          xs={12}
          spacing={2}
          style={{ margin: 0, paddingRight: 20 }}
        >
          {fieldData?.options?.length !== 0 && (
            <>
              <Grid item xs={12} md={3}>
                {fieldData?.options?.length !== 0 && (
                  <Stack
                    className={classNames(
                      classes.issue_found_detail,
                      classes.report_notes_align
                    )}
                  >
                    <div>
                      <label className={classes.information_common_title}>
                        {'Reported'}
                      </label>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <Grid container spacing={2}>
                        {fieldData?.options?.map((item, ind) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              // sm={6}
                              key={item?.option || ind}
                              sx={{ minWidth: 'fit-content' }}
                            >
                              <CustomRadioButton
                                value={item?.option}
                                label={item?.option}
                                disabled={!props.isEditReport}
                                checked={item?.selected && item?.option}
                                style={{ marginLeft: 10 }}
                                onChange={(value) =>
                                  handleCustomRadioButton(item._id)
                                }
                                containerStyle={classes.radio_containerStyle}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                {fieldData?.elementType !== 'Group' && (
                  <>
                    {(fieldData.notes || props.isEditReport) && (
                      <Stack
                        spacing={1.5}
                        className={classNames(
                          classes.issue_found_detail,
                          classes.report_notes_align
                        )}
                      >
                        <div>
                          <label className={classes.information_common_title}>
                            {'Notes'}
                          </label>
                        </div>

                        <Textbox
                          onChange={(e) => handleChange(e, 'notes')}
                          name={'notes'}
                          value={fieldData.notes ? fieldData.notes : ''}
                          placeholder={'Type Here'}
                          disabled={!props.isEditReport}
                          className={classes.form_input}
                          multiline={true}
                          rows={7}
                        />
                      </Stack>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
          {/*==== if text field is present , then separate grid with 6  =====*/}
          {fieldData?.options?.length === 0 && (
            <Grid item xs={12} md={6}>
              <Stack>
                {fieldData?.options?.length === 0 &&
                  fieldData?.elementType === 'Text Input' &&
                  (fieldData?.text || props.isEditReport) && (
                    <Textbox
                      onChange={(e) => handleChange(e, 'text')}
                      value={fieldData?.text ? fieldData?.text : ''}
                      placeholder={'Type Here'}
                      disabled={!props.isEditReport}
                      className={classes.form_input}
                    />
                  )}

                {fieldData?.elementType !== 'Group' && (
                  <>
                    {(fieldData.notes || props.isEditReport) && (
                      <Stack
                        spacing={1.5}
                        className={classNames(
                          classes.issue_found_detail,
                          classes.report_notes_align
                        )}
                      >
                        <div>
                          <label className={classes.information_common_title}>
                            {'Notes'}
                          </label>
                        </div>

                        <Textbox
                          onChange={(e) => handleChange(e, 'notes')}
                          name={'notes'}
                          value={fieldData.notes ? fieldData.notes : ''}
                          placeholder={'Type Here'}
                          disabled={!props.isEditReport}
                          className={classNames(classes.form_input)}
                          multiline={true}
                          rows={6}
                        />
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          )}

          {/* ===== img section ======*/}
          <Grid item xs={12} md={6}>
            <Stack spacing={2} height={'100%'}>
              {props.isEditReport && (
                <div className={classes.new_media}>
                  <div
                    onClick={() => {
                      onClickNewMedia('innerData', index, fieldData);
                    }}
                    className={classes.new_media_container}
                  >
                    <img src={icons(theme).Plus} style={{ height: 20 }} />
                    <label className={classes.text_dark_blue}>
                      {Strings.NEW_MEDIA}
                    </label>
                  </div>
                </div>
              )}

              <Stack style={{ margin: 0 }} className={classes.reportImgSection}>
                {fieldData?.attachments.length > 0 ? (
                  <Box className={classes.mediaSectionBox}>
                    <MediaImageContainer
                      isEditReport={props.isEditReport}
                      imageArray={fieldData?.attachments}
                      imageData={props?.imageData}
                      itemsPerRow={2}
                      sm={3}
                      md={6}
                      lg={6}
                      xl={4}
                      handleMediaActionClick={(action, imgId, tags) => {
                        onMediaActionClick(action, imgId, index, tags);
                      }}
                      isReport={true}
                      paramJobId={props?.paramJobId}
                    />
                  </Box>
                ) : (
                  <Box className={classes.emptyMediaBox}>
                    <Stack justifyContent='center' spacing={1}>
                      <div className={classes.galleryiconContainer}>
                        <img
                          src={icons(theme).gallery}
                          alt=''
                          className={classes.galleryIcon}
                        />
                      </div>

                      <Typography className={classes.addMediaText}>
                        Add Media
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {fieldData?.elementType !== 'Group' &&
          (fieldData.issueFound || props.isEditReport) && (
            <div
              className={classes.checkBoxMainContainer}
              style={{
                paddingBottom: !fieldData.issueFound && 20,
                paddingLeft: 20,
                borderBottom:
                  !fieldData.issueFound &&
                  index !== props.data?.length - 1 &&
                  `1px solid ${theme.palette.background_border}`,
              }}
            >
              <Checkbox
                id='issueFound'
                name='Task Found'
                checked={fieldData?.issueFound}
                containerContainerClass={classes.checkboxContainer}
                checkboxContainerClass={classes.list_checkbox}
                checkedContainerClass={classes.checked_checkbox}
                onChange={(e) => {
                  if (!props.paramJobId && props.isEditReport) {
                    handleChangeAddToTask(!fieldData?.issueFound);
                  }
                }}
              />
              <label className={classes.information_common_title}>
                {'Add To Tasks'}
              </label>
            </div>
          )}

        {fieldData?.elementType !== 'Group' && fieldData.issueFound ? (
          <Grid
            container
            xs={12}
            spacing={2}
            style={{
              margin: 0,
              borderBottom:
                fieldData.issueFound &&
                index !== props.data?.length - 1 &&
                `1px solid ${theme.palette.background_border}`,
              paddingBottom: 20,
              paddingRight: 20,
            }}
          >
            {IssueFoundDetailList.map((item) => {
              return (
                (fieldData[item.relation] || props.isEditReport) && (
                  <Grid item xs={12} md={item.output === 'toggle' ? 3 : 6}>
                    <Stack
                      className={classNames(
                        classes.issue_found_detail,
                        classes.issue_found_detail_align
                      )}
                      flexDirection={item?.output === 'toggle' && 'row'}
                      height='100%'
                      alignItems={item?.output === 'toggle' && 'center'}
                    >
                      <div>
                        <label className={classes.information_common_title}>
                          {item.title}
                        </label>
                      </div>
                      <div className={classes.issue_found_desc}>
                        {item.output === 'selection' && (
                          <Dropdown
                            name={item.relation}
                            onChange={(e) => {
                              handleChange(e, item.relation);
                            }}
                            value={
                              fieldData[item.relation]
                                ? fieldData[item.relation]
                                : '0'
                            }
                            // label={Strings.TYPE}
                            title={Strings.SELECT_AN_OPTION}
                            optionArray={
                              item.relation === 'category'
                                ? props.vendorCategoryList
                                : issueTypeArray
                            }
                            disabled={!props.isEditReport}
                          />
                        )}
                        {(item.output === 'textbox' ||
                          item.output === 'textarea') && (
                          <Textbox
                            onChange={(e) => handleChange(e, item.relation)}
                            name={item.relation}
                            value={
                              fieldData[item?.relation]
                                ? fieldData[item?.relation]
                                : ''
                            }
                            placeholder={'Type Here'}
                            disabled={!props.isEditReport}
                            className={classes.form_input}
                            multiline={
                              item.output === 'textarea' ? true : false
                            }
                            rows={4}
                          />
                        )}
                        {item.output === 'toggle' && (
                          <CommonSwitch
                            checked={
                              fieldData[item.relation]
                                ? fieldData[item.relation]
                                : false
                            }
                            onChange={(e) => handleChangeSwitch(item.relation)}
                            inputProps={{
                              'aria-label': 'ant design',
                            }}
                            disabled={!props.isEditReport}
                          />
                        )}
                      </div>
                    </Stack>
                  </Grid>
                )
              );
            })}
          </Grid>
        ) : null}
      </>
    );
  }

  return (
    <>
      <div className={classes.client_report_elements_container}>
        {/*=== section group  title with flag added ====*/}
        <div
          className={classes.client_information_data_title_container}
          style={{
            borderTop:
              index !== 0 &&
              // (mainItem?.notes ||
              //   mainItem?.attachments.length > 0 ||
              //   mainItem?.issueConfirmed) &&
              `1px solid ${
                isMobileView ? Colors.WHITE : theme.palette.background_border
              }`,
            paddingBottom: fieldData?.attachments.length > 0 ? 15 : 0,
            paddingTop: 10,
          }}
        >
          <label
            className={classes.client_information_common_title}
            style={{
              marginRight: 15,
              fontWeight: 600,
              alignSelf: 'center',
              paddingTop: fieldData?.options?.length === 0 && 8,
              paddingBottom: fieldData?.options?.length === 0 && 8,
            }}
          >
            {fieldData?.title || ''}
          </label>
          <div
            className={classes.flex_view}
            style={{
              flexDirection: 'column',
            }}
          >
            {fieldData?.options?.length > 0 &&
              fieldData?.options
                ?.filter((i) => i.selected)
                .map((x, ind) => (
                  <label
                    key={ind}
                    className={classNames(classes.client_issue_container)}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      background: x.color || '#ED4242',
                      // maxWidth: 200,
                    }}
                  >
                    {x?.option}
                  </label>
                ))}
          </div>
        </div>
      </div>
      {fieldData?.elementType === 'Text Input' && (
        <label
          className={classes.text_common}
          style={{ paddingLeft: isMobileView ? 0 : 20 }}
        >
          {fieldData.text ? fieldData.text : ''}
        </label>
      )}
      {fieldData?.issueFound || fieldData?.issueConfirmed ? (
        <div className={classes.issue_confirmed_container}>
          <img src={icons().redFlagIcon} />
          <label className={classes.text_dark_blue} style={{ fontSize: 13 }}>
            {fieldData?.issueConfirmed
              ? !fieldData?.issue?.visible
                ? Strings.ITEM_NOT_VISIBLE_TO_CLIENT?.toUpperCase()
                : Strings?.ITEM_ADDED_TO_ISSUES_MODULE?.toUpperCase()
              : Strings?.TASK_FOUND?.toUpperCase()}
          </label>
        </div>
      ) : null}
      {(fieldData?.notes ||
        fieldData?.attachments.length > 0 ||
        fieldData?.issueConfirmed) && (
        <div
          className={classes.client_report_elements_container1}
          style={{
            paddingBottom: 15,
            paddingTop: 0,
            marginTop: 15,
          }}
        >
          <div
            className={classNames(
              classes.client_detail_section,
              classes.detail_width
            )}
            style={{
              paddingTop: 0,
            }}
          >
            {fieldData?.notes && (
              <div className={classes.client_notes_margin_btm}>
                <ReportPropertyData
                  isNote
                  title={Strings.NOTES}
                  value={fieldData?.notes}
                  classContainer={classes.client_report_property_container}
                />
              </div>
            )}
            <MediaImageContainer
              isEditReport={props.isEditReport}
              imageArray={fieldData?.attachments}
              imageData={props?.imageData}
              itemsPerRow={isMobileView ? 2 : isTabletView ? 3 : 5}
              lg={3}
              xl={1.5}
              handleMediaActionClick={(action, imgId, tags) => {
                onMediaActionClick(action, imgId, index, tags);
              }}
              paramJobId={props?.paramJobId}
            />
          </div>
        </div>
      )}
    </>
  );
};

const ReportInformation = (props) => {
  const {
    data,
    index,
    manageReportElementsData,
    reportData,
    isEditReport,
    onClickOpenNewMediaDialog,
    onClickReportMediaMenuClick,
    paramJobId,
    vendorCategoryList,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));

  const [fieldData, setFieldData] = useState(data ?? {});

  useEffect(() => {
    if (data && Object.entries(data)?.length) {
      setFieldData(data || {});
    }
  }, [data]);

  const handleCustomRadioButton = async (id) => {
    let copyData = structuredClone(fieldData) || {};
    copyData.options = copyData?.options.map((item) => {
      if (item._id === id) {
        // if (fieldData?.elementType === 'Check List')
        item.selected = !item.selected;
        // else item.selected = true;
      } else {
        if (fieldData?.elementType !== 'Check List') item.selected = false;
        // item.selected = item.selected;
      }
      return item;
    });
    setFieldData(copyData);
    manageReportElementsData(index, copyData);
  };

  const handleChange = (e, fieldName) => {
    let copyData = structuredClone(fieldData) || {};
    copyData[fieldName] = e.target.value;
    setFieldData(copyData);
    manageReportElementsData(index, copyData);
  };

  const handleChangeAddToTask = (value) => {
    let copyData = structuredClone(fieldData) || {};
    copyData.issueCount =
      value === true
        ? copyData.issueCount + 1
        : copyData.issueCount <= 0
        ? 0
        : copyData.issueCount - 1;
    copyData.issueFound = value;
    setFieldData(copyData);
    manageReportElementsData(index, copyData);
  };

  const handleChangeSwitch = (fieldName) => {
    let copyData = structuredClone(fieldData) || {};
    copyData[fieldName] = !copyData[fieldName];
    setFieldData(copyData);
    manageReportElementsData(index, copyData);
  };

  const handleOnAction = (
    action,
    id,
    elementId,
    subElementId,
    isSubElement
  ) => {
    const elementData =
      isSubElement && subElementId
        ? reportData?.reportElements
            ?.filter((el) => el?._id === elementId)[0]
            ?.elements?.filter((el) => el?._id === subElementId)[0]
        : reportData?.reportElements?.filter((el) => el?._id === elementId)[0];
    const newData = {
      issueTitle: elementData?.issueTitle,
      dueDate: reportData?.createdAt,
      client: reportData?.client,
      job: reportData?.job?._id,
      type: elementData?.type,
      description: elementData?.description,
      suppliesNeeded: elementData?.suppliesNeeded,
      discussWithAdmin: elementData?.discussWithAdmin || false,
      followUp: elementData?.followUp || false,
      property: { _id: reportData?.job?.property },
      category: elementData?.category,
      attachments: elementData?.attachments,
      elementId: elementData?._id,
      reportId: reportData?._id,
    };
    if (newData?.attachments?.length === 0) delete newData.attachments;
    if (action === 'Create an issue') {
      props.setOpenIssueDialog(true);
      props.setElementId(id);
      props.setSelectedIssueData(newData);
    } else if (action === 'Approve This Issue') {
      props.setIsApproveIssue(true);
      props.setOpenIssueDialog(true);
      let data = {
        ...newData,
        reportId: reportData?._id,
        elementId: subElementId ? subElementId : elementId,
      };
      if (subElementId) {
        data.elementType = 'Group';
        data.parentId = elementId;
      }
      props.setSelectedIssueData(data);
    }
  };

  const onClickNewMedia = (type, index, item) => {
    onClickOpenNewMediaDialog(type, index, item);
  };

  const onMediaActionClick = (action, imgId, mainIndex, tags) => {
    onClickReportMediaMenuClick(
      'outerData',
      action,
      imgId,
      mainIndex,
      '',
      tags
    );
  };

  const manageGroupReportElemData = useCallback(
    debounce((_ind, data) => {
      let copyData = structuredClone(fieldData.elements) || [];
      copyData[_ind] = data;
      let newData = { ...fieldData, elements: copyData };
      setFieldData(newData);
      manageReportElementsData(index, newData);
    }, 500),
    [fieldData]
  );

  return (
    <div
      className={classNames(classes.information_container, {
        [classes.editReportMainContainer]: isEditReport,
      })}
      style={{ borderTop: fieldData?.elementType === 'Group' && 'none' }}
    >
      {!isClient(paramJobId) && isEditReport && (
        <div
          className={classNames(classes.information_title_container, {
            [classes.information_title_container1]:
              !fieldData?.elements?.length,
          })}
          style={{
            background:
              isEditReport && fieldData?.elementType === 'Group'
                ? theme.palette.background_button
                : ' transparent',
          }}
        >
          <div
            className={classNames(classes.flex_view, {
              [classes.mainLabelContainer]:
                isEditReport && fieldData?.elementType === 'Group',
            })}
          >
            <label
              htmlFor='information'
              className={classNames(classes.information_title, {
                [classes.mainLabel]:
                  isEditReport && fieldData?.elementType === 'Group',
              })}
            >
              {fieldData?.title
                ? `${fieldData?.title} ${fieldData?.required ? '*' : ''}`
                : ''}
            </label>
          </div>
          <div
            className={classNames(
              classes.information_title_sub_content,
              fieldData?.elements?.length === 0 &&
                classes.information_title_sub_content1
            )}
          >
            {!fieldData?.issueConfirmed &&
              fieldData?.elements &&
              fieldData?.elements?.length === 0 &&
              fieldData?.issueCount > 0 &&
              !paramJobId && (
                <ReportActionMenu
                  id={''}
                  confirmed={fieldData.issueConfirmed}
                  onSelect={(action, id) => {
                    handleOnAction(action, id, fieldData._id);
                  }}
                />
              )}
            {fieldData?.issueCount > 0 &&
              fieldData?.elementType !== 'Group' && (
                <label
                  className={classNames(
                    classes.issue_container,
                    fieldData?.issueCount > 0 && classes.red_issue_container
                  )}
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                >
                  {`${
                    fieldData?.issueCount +
                    ' ' +
                    Strings.ISSUE +
                    (fieldData.issueConfirmed ? ' ' + Strings.APPROVED : '')
                  }`}
                </label>
              )}
          </div>
        </div>
      )}
      {(isClient(paramJobId) || !isEditReport) && (
        <>
          {fieldData?.elementType === 'Group' && (
            <div className={classes.client_info_title_containeer}>
              <label
                htmlFor='information'
                className={classes.client_information_title}
              >
                {fieldData?.title ? fieldData?.title : ''}
              </label>
            </div>
          )}

          <div className={classNames(classes.client_info_sub_content)}>
            {fieldData?.elementType !== 'Group' && (
              <>
                {/* ===== section main title with red flag ===== */}
                <div
                  className={classes.client_information_data_title_container}
                  style={{
                    paddingBottom:
                      fieldData?.notes ||
                      fieldData?.issueConfirmed ||
                      fieldData?.attachments.length > 0
                        ? 15
                        : 0,
                  }}
                >
                  <label
                    className={classNames(
                      classes.client_information_common_title,
                      classes.margin_left_20
                    )}
                    style={{
                      marginRight: 15,
                      fontWeight: 600,
                      alignSelf: 'center',
                      paddingTop: fieldData?.options?.length === 0 && 8,
                      paddingBottom: fieldData?.options?.length === 0 && 8,
                    }}
                  >
                    {fieldData?.title ?? ''}
                  </label>
                  <div
                    className={classes.flex_view}
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    {fieldData?.options?.length > 0 &&
                      fieldData?.options
                        ?.filter((i) => i.selected)
                        .map((x, ind) => (
                          <label
                            key={ind}
                            className={classNames(
                              classes.client_issue_container
                            )}
                            style={{
                              cursor: 'pointer',
                              textAlign: 'center',
                              background: x.color || '#ED4242',
                              // maxWidth: 200,
                            }}
                          >
                            {x?.option}
                          </label>
                        ))}
                  </div>
                </div>
                {fieldData?.elementType === 'Text Input' && (
                  <label
                    className={classNames(
                      classes.text_common,
                      classes.margin_left_20
                    )}
                    style={{ marginBottom: 10 }}
                  >
                    {fieldData.text ? fieldData.text : ''}
                  </label>
                )}
              </>
            )}
            {fieldData?.elementType === 'Group' &&
              fieldData?.options?.length > 0 &&
              fieldData?.options
                ?.filter((i) => i.selected)
                .map((x) => (
                  <label
                    className={classNames(classes.client_issue_container)}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      background: x.color || '#ED4242',
                      // maxWidth: 200,
                    }}
                  >
                    {x?.option}
                  </label>
                ))}
            {fieldData?.issueConfirmed || fieldData?.issueFound ? (
              <div className={classes.issue_confirmed_container}>
                <img src={icons().redFlagIcon} />
                <label
                  className={classes.text_dark_blue}
                  style={{ fontSize: 13 }}
                >
                  {fieldData?.issueConfirmed
                    ? !fieldData?.issue?.visible
                      ? Strings.ITEM_NOT_VISIBLE_TO_CLIENT?.toUpperCase()
                      : Strings?.ITEM_ADDED_TO_ISSUES_MODULE?.toUpperCase()
                    : Strings?.TASK_FOUND?.toUpperCase()}
                </label>
              </div>
            ) : null}
            <div
              className={classes.client_report_elements_container}
              style={{
                paddingBottom:
                  fieldData?.notes || fieldData?.attachments.length > 0
                    ? 15
                    : 0,
              }}
            >
              <div
                className={classNames(
                  classes.client_detail_section,
                  classes.detail_width
                )}
              >
                {fieldData?.notes && (
                  <div className={classes.client_notes_margin_btm}>
                    <ReportPropertyData
                      isNote
                      title={Strings.NOTES}
                      value={fieldData?.notes}
                      classContainer={classes.client_report_property_container}
                    />
                  </div>
                )}
                <MediaImageContainer
                  imageArray={fieldData?.attachments}
                  imageData={props?.imageData}
                  itemsPerRow={isMobileView ? 2 : isTabletView ? 3 : 5}
                  lg={3}
                  xl={1.5}
                  handleMediaActionClick={(action, imgId, tags) => {
                    onMediaActionClick(action, imgId, index, tags);
                  }}
                  isEditReport={isEditReport}
                  paramJobId={paramJobId}
                />
              </div>
            </div>
            {fieldData?.elements?.length
              ? fieldData?.elements?.map((el, _ind) => {
                  return (
                    <InformationBox
                      data={el}
                      index={_ind}
                      key={`key-${_ind}`}
                      imageData={props?.imageData}
                      actionOnClick={(action, id, subElementId) =>
                        handleOnAction(
                          action,
                          id,
                          fieldData?._id,
                          subElementId,
                          true
                        )
                      }
                      reportId={props.reportId}
                      reportElementsIndex={index}
                      isEditReport={isEditReport}
                      onClickOpenNewMediaDialog={onClickOpenNewMediaDialog}
                      onClickReportMediaMenuClick={onClickReportMediaMenuClick}
                      paramJobId={paramJobId}
                      vendorCategoryList={vendorCategoryList}
                      manageGroupReportElemData={manageGroupReportElemData}
                    />
                  );
                })
              : null}
          </div>
        </>
      )}
      {!isClient(paramJobId) && isEditReport && (
        <>
          {(fieldData?.title !== '' || isEditReport || paramJobId) &&
            !isClient(paramJobId) &&
            fieldData?.elementType !== 'Group' && (
              <Grid
                container
                xs={12}
                spacing={2}
                style={{ margin: 0, paddingRight: 20 }}
              >
                {/*====== not group with option list,  ===== */}
                {fieldData?.options?.length !== 0 && (
                  <>
                    <Grid item xs={12} md={3}>
                      {fieldData?.options?.length !== 0 &&
                        (fieldData?.elementType === 'Dropdown' ||
                          fieldData?.elementType === 'Radio' ||
                          fieldData?.elementType === 'Check List') && ( // data?.options.some((item) => item.selected)
                          <Stack className={classes.issue_found_detail}>
                            <div>
                              <label
                                className={classes.information_common_title}
                              >
                                {'Reported'}
                              </label>
                            </div>

                            <div style={{ marginTop: 10 }}>
                              <Grid container spacing={2}>
                                {fieldData?.options?.map((item, index) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      // sm={6}
                                      key={item?.option || index}
                                      sx={{ minWidth: 'fit-content' }}
                                    >
                                      <CustomRadioButton
                                        value={item?.option}
                                        label={item?.option}
                                        disabled={!isEditReport}
                                        checked={item?.selected && item?.option}
                                        style={{ marginLeft: 10 }}
                                        onChange={(value) => {
                                          handleCustomRadioButton(
                                            item._id,
                                            item.option
                                          );
                                        }}
                                        containerStyle={
                                          classes.radio_containerStyle
                                        }
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </div>
                          </Stack>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {fieldData?.elementType !== 'Group' && (
                        <>
                          {(fieldData.notes || isEditReport) && (
                            <Stack
                              spacing={1.5}
                              className={classNames(
                                classes.issue_found_detail,
                                classes.report_notes_align
                              )}
                              height={'100%'}
                            >
                              <div>
                                <label
                                  className={classes.information_common_title}
                                >
                                  {'Notes'}
                                </label>
                              </div>
                              <div className={classes.issue_found_desc}>
                                <Textbox
                                  onChange={(e) => handleChange(e, 'notes')}
                                  name={'notes'}
                                  value={fieldData?.notes ?? ''}
                                  placeholder={'Type Here'}
                                  disabled={!isEditReport}
                                  className={classes.form_input}
                                  multiline={true}
                                  rows={6}
                                />
                              </div>
                            </Stack>
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {/*==== if text field is present , then separate grid with 6  =====*/}
                {fieldData?.options?.length === 0 && (
                  <Grid item xs={12} md={6}>
                    <Stack>
                      {fieldData?.options?.length === 0 &&
                        fieldData?.elementType === 'Text Input' &&
                        (fieldData?.text || isEditReport) && (
                          <Textbox
                            onChange={(e) => handleChange(e, 'text')}
                            value={fieldData?.text ?? ''}
                            placeholder={'Type Here'}
                            disabled={!isEditReport}
                            className={classes.form_input}
                          />
                        )}
                      {fieldData?.elementType !== 'Group' && (
                        <>
                          {(fieldData.notes || isEditReport) && (
                            <Stack
                              spacing={1.5}
                              className={classNames(
                                classes.issue_found_detail,
                                classes.report_notes_align
                              )}
                            >
                              <div>
                                <label
                                  className={classes.information_common_title}
                                >
                                  {'Notes'}
                                </label>
                              </div>
                              <div className={classes.issue_found_desc}>
                                <Textbox
                                  onChange={(e) => handleChange(e, 'notes')}
                                  name={'notes'}
                                  value={fieldData?.notes ?? ''}
                                  placeholder={'Type Here'}
                                  disabled={!isEditReport}
                                  className={classes.form_input}
                                  multiline={true}
                                  rows={6}
                                />
                              </div>
                            </Stack>
                          )}
                        </>
                      )}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  {!isClient(paramJobId) && (
                    <Stack spacing={2} height={'100%'}>
                      {isEditReport && (
                        <div className={classes.new_media}>
                          <div
                            onClick={() => {
                              if (isEditReport) {
                                onClickNewMedia('outerData', index, fieldData);
                              }
                            }}
                            className={classes.new_media_container}
                          >
                            <img
                              src={icons(theme).Plus}
                              style={{ height: 20 }}
                            />
                            <label className={classes.text_dark_blue}>
                              {Strings.NEW_MEDIA}
                            </label>
                          </div>
                        </div>
                      )}
                      <Stack
                        style={{ margin: 0 }}
                        className={classes.reportImgSection}
                      >
                        {fieldData?.attachments.length > 0 ? (
                          <Box className={classes.mediaSectionBox}>
                            <MediaImageContainer
                              imageArray={fieldData?.attachments}
                              imageData={props?.imageData}
                              itemsPerRow={2}
                              sm={3}
                              md={6}
                              lg={6}
                              xl={4}
                              handleMediaActionClick={(action, imgId, tags) => {
                                onMediaActionClick(action, imgId, index, tags);
                              }}
                              isReport={true}
                              isEditReport={isEditReport}
                              paramJobId={paramJobId}
                            />
                          </Box>
                        ) : (
                          <Box className={classes.emptyMediaBox}>
                            <Stack justifyContent='center' spacing={1}>
                              <div className={classes.galleryiconContainer}>
                                <img
                                  src={icons(theme).gallery}
                                  alt=''
                                  className={classes.galleryIcon}
                                />
                              </div>

                              <Typography className={classes.addMediaText}>
                                Add Media
                              </Typography>
                            </Stack>
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Grid>

                {fieldData?.elementType !== 'Group' && (
                  <>
                    {(fieldData?.issueFound || isEditReport) && (
                      <div
                        className={classes.checkBoxMainContainer}
                        style={{
                          paddingTop: 20,
                          paddingLeft: 20,
                        }}
                      >
                        <Checkbox
                          id='issueFound'
                          name='Task Found'
                          checked={fieldData?.issueFound}
                          containerContainerClass={classes.checkboxContainer}
                          checkboxContainerClass={classes.list_checkbox}
                          checkedContainerClass={classes.checked_checkbox}
                          onChange={(e) => {
                            if (!paramJobId && isEditReport) {
                              handleChangeAddToTask(
                                !fieldData?.issueFound,
                                'issueFound'
                              );
                            }
                          }}
                        />
                        <label className={classes.information_common_title}>
                          {'Add To Tasks'}
                        </label>
                      </div>
                    )}
                  </>
                )}

                <Grid
                  container
                  xs={12}
                  spacing={2}
                  style={{
                    margin: 0,
                    paddingBottom: 20,
                  }}
                >
                  {fieldData?.elementType !== 'Group' &&
                    fieldData?.issueFound &&
                    IssueFoundDetailList.map((item) => {
                      return (
                        (fieldData[item.relation] || isEditReport) && (
                          <Grid
                            item
                            xs={12}
                            md={item.output === 'toggle' ? 3 : 6}
                          >
                            <Stack
                              className={classNames(
                                classes.issue_found_detail,
                                classes.issue_found_detail_align
                              )}
                              flexDirection={item?.output === 'toggle' && 'row'}
                              height='100%'
                              alignItems={item?.output === 'toggle' && 'center'}
                            >
                              <div>
                                <label
                                  className={classes.information_common_title}
                                >
                                  {item.title}
                                </label>
                              </div>
                              <div className={classes.issue_found_desc}>
                                {item.output === 'selection' && (
                                  <Dropdown
                                    name={item.relation}
                                    onChange={(e) => {
                                      handleChange(e, item.relation);
                                    }}
                                    value={
                                      fieldData[item.relation]
                                        ? fieldData[item.relation]
                                        : '0'
                                    }
                                    // label={Strings.TYPE}
                                    title={Strings.SELECT_AN_OPTION}
                                    optionArray={
                                      item.relation === 'category'
                                        ? vendorCategoryList
                                        : issueTypeArray
                                    }
                                    disabled={!isEditReport}
                                  />
                                )}
                                {(item.output === 'textbox' ||
                                  item.output === 'textarea') && (
                                  <Textbox
                                    onChange={(e) =>
                                      handleChange(e, item.relation)
                                    }
                                    name={item.relation}
                                    value={
                                      fieldData[item.relation]
                                        ? fieldData[item.relation]
                                        : ''
                                    }
                                    placeholder={'Type Here'}
                                    disabled={!isEditReport}
                                    className={classes.form_input}
                                    multiline={
                                      item.output === 'textarea' ? true : false
                                    }
                                    rows={4}
                                  />
                                )}
                                {item.output === 'toggle' && (
                                  <CommonSwitch
                                    checked={
                                      fieldData[item.relation]
                                        ? fieldData[item.relation]
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleChangeSwitch(item.relation)
                                    }
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    disabled={!isEditReport}
                                  />
                                )}
                              </div>
                            </Stack>
                          </Grid>
                        )
                      );
                    })}
                </Grid>
              </Grid>
            )}
          {fieldData?.elements?.length
            ? fieldData?.elements?.map((el, _ind) => {
                return (
                  <InformationBox
                    data={el}
                    index={_ind}
                    key={`key-${_ind}`}
                    imageData={props?.imageData}
                    actionOnClick={(action, id, subElementId) =>
                      handleOnAction(
                        action,
                        id,
                        fieldData?._id,
                        subElementId,
                        true
                      )
                    }
                    reportId={props.reportId}
                    reportElementsIndex={index}
                    isEditReport={isEditReport}
                    onClickOpenNewMediaDialog={onClickOpenNewMediaDialog}
                    onClickReportMediaMenuClick={onClickReportMediaMenuClick}
                    paramJobId={paramJobId}
                    vendorCategoryList={vendorCategoryList}
                    manageGroupReportElemData={manageGroupReportElemData}
                  />
                );
              })
            : null}
        </>
      )}
    </div>
  );
};

const ReportDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const alert = useAlert();
  let { paramJobId } = useParams();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));

  const [reportData, setReportData] = useState(null);
  const [copiedReportData, setCopiedReportData] = useState(null);
  const [openInformation, setOpenInformation] = useState([]);
  const [openIssueDialog, setOpenIssueDialog] = useState(false);
  const [openNewMediaDialog, setOpenNewMediaDialog] = useState(false);
  const [selectedNewMedia, setSelectedNewMedia] = useState({});
  const [selectedIndexDataForMedia, setSelectedIndexDataForMedia] = useState(
    {}
  );
  const [selectedElementId, setSelectedElementId] = useState('');
  const [isEditReport, setIsEditReport] = useState(false);
  const [showMediaDialog, setShowMediaDialog] = useState(false);
  const [selectedReportMediaMenuData, setSelectedReportMediaMenuData] =
    useState({});
  const [removeImgArr, setRemoveImgArr] = useState([]);
  const [selectedIssueData, setSelectedIssueData] = useState(null);
  const [isApproveIssue, setIsApproveIssue] = useState(false);
  const [sendReportDialog, setSendReportDialog] = useState(false);
  const [vendorCategoryList, setVendorCategoryList] = useState([]);
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [summaryTaskStatus, setSummaryTaskStatus] = useState(false);
  const [selectedTask, SetSelectedTask] = useState({});
  const [taskStatusDropdownData, setTaskStatusDropdownData] = useState('0');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState('');
  const [isAddIssueNote, setIsAddIssueNote] = useState(false);

  const is500 = useMediaQuery('(max-width: 500px)');
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const cursor = !isClient() ? 'pointer' : 'auto';
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchCategoryList('vendor', (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.categoryListData ? props.categoryListData : [];
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.categoryName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorCategoryList(newData);
  }, [props.categoryListData]);

  const updateData = (reportElements) => {
    const newReportData = {
      ...reportData,
      reportElements: reportElements,
    };
    setReportData(newReportData);
  };

  useEffect(() => {
    if (selectedNewMedia.length > 0) {
      if (selectedIndexDataForMedia.type === 'outerData') {
        const newReportElements = reportData?.reportElements?.map(
          (item, index) => {
            if (
              index === selectedIndexDataForMedia.index ||
              index === selectedIndexDataForMedia.mainIndex
            ) {
              if (selectedIndexDataForMedia?.isReplace) {
                let newImages = item.attachments.map((imgs) => {
                  if (imgs._id === selectedIndexDataForMedia.imgId) {
                    imgs.url = selectedNewMedia[0].url;
                    imgs._id = selectedNewMedia[0]._id;
                    imgs.type = selectedNewMedia[0].type;
                    imgs.size = selectedNewMedia[0].size;
                    imgs.fileName = selectedNewMedia[0].fileName;
                  }
                  return imgs;
                });
                item['attachments'] = newImages;
                setRemoveImgArr((oldArr) => [
                  ...oldArr,
                  selectedIndexDataForMedia.imgId,
                ]);
              } else {
                item?.attachments.push(...selectedNewMedia);
              }
            }
            return item;
          }
        );
        updateData(newReportElements);
      } else if (selectedIndexDataForMedia.type === 'innerData') {
        if (!selectedIndexDataForMedia?.isReplace) {
          selectedIndexDataForMedia?.item?.attachments.push(
            ...selectedNewMedia
          );
        }
        const newReportElements = reportData?.reportElements?.map(
          (item, index) => {
            if (index === selectedIndexDataForMedia.outerIndex) {
              if (selectedIndexDataForMedia?.isReplace) {
                let newElements = item.elements.map((innerItem, innerIndex) => {
                  if (
                    innerIndex === selectedIndexDataForMedia.index ||
                    innerIndex === selectedIndexDataForMedia.mainIndex
                  ) {
                    setRemoveImgArr((oldArr) => [
                      ...oldArr,
                      selectedIndexDataForMedia.imgId,
                    ]);
                    let newImages = innerItem.attachments.map((imgs) => {
                      if (imgs._id === selectedIndexDataForMedia.imgId) {
                        imgs.url = selectedNewMedia[0].url;
                        imgs._id = selectedNewMedia[0]._id;
                        imgs.type = selectedNewMedia[0].type;
                        imgs.size = selectedNewMedia[0].size;
                        imgs.fileName = selectedNewMedia[0].fileName;
                      }
                      return imgs;
                    });
                    innerItem['attachments'] = newImages;
                  }
                  return innerItem;
                });
                item = { ...item, elements: newElements };
              } else {
                let newElements = item.elements.map((innerItem, innerIndex) => {
                  if (innerIndex === selectedIndexDataForMedia.index) {
                    innerItem = selectedIndexDataForMedia.item;
                  }
                  return innerItem;
                });
                item = { ...item, elements: newElements };
              }
            }
            return item;
          }
        );
        updateData(newReportElements);
      }
    }
  }, [selectedNewMedia]);

  const fetchReportData = () => {
    props.getReport(
      state?.jobId || paramJobId,
      (err) => {
        onError(err);
        navigate('/app/jobs');
      },
      paramJobId ? true : false
    );
  };

  useEffect(() => {
    if ((state && state?.jobId) || paramJobId) {
      fetchReportData();
    } else {
      navigate(-1);
    }
  }, [
    state,
    paramJobId,
    // props.confirmIssueData,
    props.markAsCompleteJobData,
    props.updateIssueData,
  ]);

  useEffect(() => {
    if (props.updateReportData !== null) {
      fetchReportData();
      dispatch(setUpdateReport(null));
    }
  }, [props.updateReportData]);

  useEffect(() => {
    if (props.getReportData) {
      setReportData(JSON.parse(JSON.stringify(props.getReportData)));
      setCopiedReportData(JSON.parse(JSON.stringify(props.getReportData)));
    }
  }, [props.getReportData]);

  useEffect(() => {
    if (!isEditReport) {
      if (props.getReportData && Object.entries(props.getReportData)?.length) {
        const newData = structuredClone(props.getReportData);
        const newElementData = newData?.reportElements?.filter((el) => {
          if (el?.elementType === 'Text Input') {
            if (el?.text && el?.text !== undefined && el?.text !== null)
              return el;
            else if (el?.notes) return el;
          } else if (el?.options?.length) {
            const isAnyOptionSelect =
              el?.options?.some((o) => o?.selected === true) || false;
            if (isAnyOptionSelect) return el;
          } else if (el?.elements?.length) {
            const newChildElement = el?.elements?.filter((o) => {
              if (o?.elementType === 'Text Input') {
                if (o?.text && o?.text !== undefined && o?.text !== null)
                  return o;
                else if (o?.notes) return o;
              } else if (o?.options?.length) {
                const isAnyOptionSelect =
                  o?.options?.some((elm) => elm?.selected === true) || false;
                if (isAnyOptionSelect) return o;
              }
            });
            el.elements = newChildElement;
            return el;
          }
        });
        setReportData({
          ...props.getReportData,
          reportElements: newElementData,
        });
      }
    } else {
      let newData = structuredClone(props.getReportData);
      setReportData(newData);
    }
  }, [isEditReport, props.getReportData]);

  useEffect(() => {
    const newValue = issueStatusArray?.filter(
      (el) => el.value === selectedTask?.issue?.status
    );
    if (summaryTaskStatus && newValue)
      setTaskStatusDropdownData(newValue[0]?.value);
  }, [summaryTaskStatus]);

  const imageDetails = () => {
    return {
      jobId: reportData?.job?._id,
      title: reportData?.auditTitle,
      clientName: reportData?.client?.name,
      createdBy: reportData?.createdBy?.name,
      createdAt: reportData?.createdAt,
    };
  };

  const onClickDownloadReport = () => {
    let fullname = reportData?.client?.name;
    let clientName = fullname?.split(' ').pop();
    let currentDate = moment(new Date()).format('MM/DD/YYYY');
    let fileName = clientName + '-' + currentDate;
    props.downloadReport(reportData._id, fileName, paramJobId, onError);
  };

  // ===== check any required task pending or not =====
  const checkRequiredTaskExist = useCallback(async (data) => {
    const reportElements = structuredClone(data);
    let notCheckedOptionsIsRequiredList = [];

    const reportElementMapping = (array) => {
      array?.forEach((el) => {
        if (!el?.elements?.length) {
          if (el?.required && el?.elementType !== 'Text Input') {
            let isAnyOptionSelect = el?.options?.some((el) => el?.selected);
            if (!isAnyOptionSelect) notCheckedOptionsIsRequiredList.push(true);
          } else if (
            el?.required &&
            el?.elementType === 'Text Input' &&
            !el?.text
          ) {
            notCheckedOptionsIsRequiredList.push(true);
          }
        } else if (el?.elements?.length) reportElementMapping(el?.elements);
      });
    };

    await reportElementMapping(reportElements);
    if (notCheckedOptionsIsRequiredList?.length) {
      return notCheckedOptionsIsRequiredList?.includes(true);
    } else return false;
  }, []);

  // ===== handle update report =====
  const onClickUpdate = async () => {
    const data = {
      auditTitle: reportData.auditTitle,
      conductedOn: reportData.conductedOn,
      issueCount: reportData.issueCount,
      reportElements: reportData.reportElements,
      deleteFiles: removeImgArr,
    };
    const id = reportData._id;
    const throwValidationError = await checkRequiredTaskExist(
      data.reportElements
    );
    if (!throwValidationError) {
      props.updateReport(id, data, onError, () => {
        setIsEditReport(false);
        setRemoveImgArr([]);
        setSelectedNewMedia({});
        setSelectedIndexDataForMedia({});
      });
    } else alert.error('Please select all required fields');
  };

  const onClickOpenNewMediaDialog = (type, index, item, outerIndex) => {
    setOpenNewMediaDialog(true);
    const data = {
      type,
      index,
      item,
      outerIndex: outerIndex || null,
    };
    setSelectedIndexDataForMedia(data);
  };

  const onClickReportMediaMenuClick = (
    type,
    action,
    imgId,
    mainIndex,
    outerIndex,
    tags
  ) => {
    const data = {
      type,
      action,
      imgId,
      mainIndex,
      outerIndex,
      tags,
    };
    if (action === 'Replace') {
      data.isReplace = true;
      setOpenNewMediaDialog(true);
      setSelectedIndexDataForMedia(data);
    } else {
      setSelectedReportMediaMenuData(data);
      setShowMediaDialog(true);
    }
  };

  const deleteMediaFromReport = () => {
    if (selectedReportMediaMenuData.type === 'outerData') {
      const newReportElements = reportData?.reportElements?.map(
        (item, index) => {
          if (index === selectedReportMediaMenuData.mainIndex) {
            const newImages = item.attachments.filter(
              (imgs) => imgs._id !== selectedReportMediaMenuData.imgId
            );
            setRemoveImgArr((oldArr) => [
              ...oldArr,
              selectedReportMediaMenuData.imgId,
            ]);
            item['attachments'] = newImages;
          }
          return item;
        }
      );
      updateData(newReportElements);
    } else if (selectedReportMediaMenuData.type === 'innerData') {
      const newReportElements = reportData?.reportElements?.map(
        (item, index) => {
          if (index === selectedReportMediaMenuData.outerIndex) {
            let newElements = item.elements.map((innerItem, innerIndex) => {
              if (innerIndex === selectedReportMediaMenuData.mainIndex) {
                const newImages = innerItem.attachments.filter(
                  (imgs) => imgs._id !== selectedReportMediaMenuData.imgId
                );
                innerItem['attachments'] = newImages;
                setRemoveImgArr((oldArr) => [
                  ...oldArr,
                  selectedReportMediaMenuData.imgId,
                ]);
              }
              return innerItem;
            });
            item = { ...item, elements: newElements };
          }
          return item;
        }
      );
      updateData(newReportElements);
    }
    setShowMediaDialog(false);
  };

  // const onClickMarkAsComplete = () => {
  //   setSendReportDialog(true);
  //   // props.markAsCompleteJob(state?.jobId, onError);
  // };

  // ===== Check report issues exist / approved =====
  const checkIssues = (el) => {
    if (!el?.issueFound) return true;
    if (el?.issueFound && !el?.issueConfirmed) return false;
    if (el?.issueFound && el?.issueConfirmed) return true;
  };

  const isIssuesApproved = () => {
    const newData = structuredClone(reportData?.reportElements);
    return newData?.every((el) => {
      if (el?.elements?.length) {
        return el?.elements?.every((item) => checkIssues(item));
      }
      return checkIssues(el);
    });
  };

  const changeElementIssueStatus = (elementId, parentId, elementType) => {
    let newReportData = structuredClone(reportData);
    let newData = structuredClone(newReportData?.reportElements);
    if (elementType === 'Group') {
      let elementData = newData.find((el) => el._id === parentId);
      let fIndexElementData = newData.findIndex((el) => el._id === parentId);
      let fIndex = elementData?.elements?.findIndex(
        (el) => el._id === elementId
      );
      elementData.elements[fIndex].issueConfirmed = true;
      newData[fIndexElementData] = elementData;
    } else {
      let fIndex = newData.findIndex((el) => el._id === elementId);
      newData[fIndex].issueConfirmed = true;
    }
    newReportData.reportElements = newData;
    setReportData(newReportData);
  };

  //===== add notes for report =====
  const addNoteHandle = (e) => {
    setAddNoteDialog(true);
    setSelectedJobId(state?.jobId);
  };

  //===== handle Task Status Update =====
  const handleTaskStatusUpdate = async (e) => {
    let data = { status: taskStatusDropdownData };
    await props.updateIssue(
      selectedTask?.issue?._id,
      data,
      () => {
        setSummaryTaskStatus(false);
        SetSelectedTask({});
      },
      (err) => onError(err)
    );
  };

  //==== report action status based selection ===
  const handleOnAction = async (action) => {
    if (action === 'Send Report to client') {
      if (isIssuesApproved()) setSendReportDialog(true);
      else {
        setTimeout(() => {
          alert.error('Please approve all tasks to send report');
        }, 100);
      }
    } else {
      let data = {
        jobId: state?.jobId || paramJobId,
        status: action,
      };
      setActionLoading(true);
      props.updateReportAction(
        data,
        (err) => onError(err),
        (res) => {
          setActionLoading(false);
          alert.success(res.data);
        }
      );
    }
  };

  useEffect(() => {
    if (reportData && Object.entries(reportData)?.length) {
      manageNotesHref('reportIssueDescription');
      manageNotesHref('reportIssueNote');
      manageNotesHref('reportIssueInternalNote');
    }
  }, [reportData]);

  const sortData = (array) => {
    const newData = structuredClone(array);
    let data = newData?.sort((a, b) => {
      if (moment(a.createdAt) < moment(b.createdAt)) return 1;
      if (moment(a.createdAt) > moment(b.createdAt)) return -1;
      return 0;
    });
    return data || array;
  };

  const addIssueNotes = (res) => {
    let copyReportData = structuredClone(reportData);
    let copyLinkedIssue = structuredClone(copyReportData?.linkedIssues);

    copyLinkedIssue = copyLinkedIssue.map((el) => {
      if (el?.issue?._id === selectedIssueId) {
        if (res.internalNotes) el.issue.internalNotes.push(res);
        else el.issue.notes.unshift(res);
      }
      return el;
    });

    copyReportData.linkedIssues = copyLinkedIssue;
    setSelectedIssueId('');
    setReportData(copyReportData);
    dispatch(setReportData(copyReportData));
  };

  const manageReportData = useCallback(
    debounce((data) => setReportData(data), 100),
    [reportData]
  );

  const manageReportElementsData = useCallback(
    debounce((index, data) => {
      let copyData = structuredClone(reportData) || {};
      copyData.reportElements[index] = data;
      let count = 0;
      copyData.reportElements?.map((el) => {
        if (el?.issueFound) count += 1;
        if (el?.elements?.length) {
          el?.elements?.map((_el) => {
            if (_el?.issueFound) count += 1;
          });
        }
      });
      copyData.issueCount = count;
      setReportData(copyData);
    }, 500),
    [reportData]
  );

  const sortAttachments = (data) => {
    if (data && data?.length) {
      return data.slice().sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    } else {
      return [];
    }
  };

  return (
    <>
      <Container maxWidth='lg' className={classes.report_container} id='report'>
        {props.getReportLoading ? (
          <Stack className={classes.flexView} minHeight={200}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </Stack>
        ) : (
          <>
            {!reportData ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <>
                <Stack pb={3}>
                  {/* ================ REPORT TOP HEADER =====================*/}
                  <TableToolbox
                    isBack={paramJobId ? false : true}
                    isLabel
                    isEdit={paramJobId ? false : true}
                    editing={isEditReport}
                    isDownloadPdf={true}
                    showAddNote={!isClient(paramJobId)}
                    showActionButton={!isClient(paramJobId)}
                    markAsComplete={
                      !paramJobId && reportData?.job?.jobStatus !== 'Completed'
                        ? true
                        : false
                    }
                    markAsCompleteLoading={actionLoading}
                    markAsCompleteDisable={actionLoading}
                    onAddNoteClick={addNoteHandle}
                    labelText={!isMobileView && reportData?.job?.title}
                    onClickBack={() => navigate(-1)}
                    onEditClick={() => {
                      setIsEditReport(true);
                    }}
                    onDownloadPdfClick={() => onClickDownloadReport()}
                    downloadPdfLoader={props.downloadReportLoading}
                    onClickCancel={() => {
                      setIsEditReport(false);
                      setReportData(
                        JSON.parse(JSON.stringify(copiedReportData))
                      );
                      setRemoveImgArr([]);
                      setSelectedNewMedia({});
                      setSelectedIndexDataForMedia({});
                    }}
                    onClickSaveUpdates={onClickUpdate}
                    updateReportLoading={props.updateReportLoading}
                    onSelect={(action, id) => {
                      handleOnAction(action, id);
                      setDrawerOpen(false);
                    }}
                  />
                </Stack>
                <Stack
                  height='inherit'
                  style={{ overflow: mobileView ? 'auto' : 'unset' }}
                >
                  {isMobileView && (
                    <label className={classes.toolbar_title}>
                      {reportData?.job?.title}
                    </label>
                  )}
                  <div className={classes.properties_container}>
                    <div
                      className={classes.report_details}
                      style={{
                        borderBottom:
                          !isEditReport &&
                          `1px solid ${theme.palette.background_border}`,
                      }}
                    >
                      <ReportPropertyData
                        title={Strings.AUDIT_TITLE}
                        value={reportData?.auditTitle}
                        isEditReport={isEditReport}
                        reportData={reportData}
                        manageReportData={manageReportData}
                      />
                      <ReportPropertyData
                        title={Strings.CLIENT}
                        value={reportData?.client?.name}
                      />
                      <ReportPropertyData
                        title={Strings.PREPARED_BY}
                        value={reportData?.createdBy?.name}
                      />
                      <ReportPropertyData
                        title={Strings.CONDUCTED_ON}
                        value={
                          reportData?.conductedOn &&
                          moment(reportData?.conductedOn).format('MM/DD/YYYY')
                        }
                        datePickerValue={
                          reportData?.conductedOn &&
                          moment(reportData?.conductedOn).format('MMM DD, YYYY')
                        }
                        isEditReport={isEditReport}
                        reportData={reportData}
                        manageReportData={manageReportData}
                      />
                      <ReportPropertyData
                        title={Strings.NEW_TASKS}
                        value={
                          reportData?.issueCount
                            ? reportData?.issueCount
                            : Strings.NO_NEW_TASKS
                        }
                      />
                      {isSuperAdmin() && (
                        <ReportPropertyData
                          title={Strings.VIEWED_BY_CLIENT}
                          value={
                            reportData?.job?.ViewByClient
                              ? `Yes, on ${moment(
                                  reportData?.job?.ViewByClient
                                ).format('MMM DD, YYYY')}`
                              : `No`
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={classes.report_view_container}
                    style={{ overflow: mobileView ? 'unset' : 'auto' }}
                    id='report-child'
                  >
                    {reportData?.reportElements?.map((item, index) => {
                      const isShow =
                        (item.elementType === 'Group' &&
                          item?.elements?.length) ||
                        item.elementType !== 'Group';
                      if (isShow) {
                        return (
                          <ReportInformation
                            key={index}
                            index={index}
                            data={item}
                            paramJobId={paramJobId}
                            openInformation={openInformation}
                            setOpenInformation={setOpenInformation}
                            setOpenIssueDialog={setOpenIssueDialog}
                            setElementId={setSelectedElementId}
                            imageData={imageDetails()}
                            reportId={reportData._id}
                            confirmIssue={props.confirmIssue}
                            onError={onError}
                            reportData={reportData}
                            isEditReport={isEditReport}
                            onClickOpenNewMediaDialog={
                              onClickOpenNewMediaDialog
                            }
                            onClickReportMediaMenuClick={
                              onClickReportMediaMenuClick
                            }
                            setSelectedIssueData={setSelectedIssueData}
                            setIsApproveIssue={setIsApproveIssue}
                            vendorCategoryList={vendorCategoryList}
                            manageReportElementsData={manageReportElementsData}
                          />
                        );
                      }
                    })}
                    {reportData?.linkedIssues?.length > 0 && (
                      <>
                        <div
                          className={classNames(
                            classes.information_title_container,
                            classes.information_title_container1
                          )}
                        >
                          <div className={classes.flex_view}>
                            <label
                              htmlFor='information'
                              className={classes.information_title}
                              style={{ marginBottom: 10 }}
                            >
                              {'Summary of Tasks'}
                            </label>
                          </div>
                        </div>
                        <div className={classes.related_issue_container}>
                          {reportData?.linkedIssues?.map((item, index) => {
                            return (
                              <div
                                className={classes.related_issues}
                                key={`key-${index}-${item?._id}`}
                              >
                                <label
                                  className={classNames(
                                    classes.information_common_title,
                                    selectedTask._id === item._id &&
                                      classes.summaryTaskContainer,
                                    classes.summaryTaskContainerNoWrap
                                  )}
                                >
                                  <span
                                    className={classes.view_all_text}
                                    onClick={() => {
                                      const state = item?.issue?._id
                                        ? {
                                            selectedIssue: item?.issue?._id,
                                          }
                                        : {};
                                      navigate('/app/issues', { state });
                                    }}
                                  >
                                    {' '}
                                    {item?.issue?.issueTitle}{' '}
                                  </span>
                                  <Box
                                    className={classNames(
                                      classes.taskButtonContainerNoWrap
                                    )}
                                  >
                                    {!summaryTaskStatus ? (
                                      <>
                                        <span
                                          className={classNames(
                                            classes.status_container
                                          )}
                                          style={{
                                            backgroundColor: statusColor(
                                              item?.issue?.status
                                            ),
                                          }}
                                        >
                                          {item?.issue?.status}
                                        </span>
                                        {!isClient() ? (
                                          <img
                                            src={icons(theme).editIcon}
                                            alt=''
                                            className={classes.edit_icon}
                                            onClick={(e) => {
                                              setSummaryTaskStatus(true);
                                              SetSelectedTask(item);
                                            }}
                                          />
                                        ) : null}
                                        {isClient() && (
                                          <CustomizedTooltips
                                            title={'Add Comment'}
                                          >
                                            <img
                                              src={icons(theme).addNote}
                                              alt=''
                                              className={
                                                classes.plus_add_button
                                              }
                                              onClick={() => {
                                                setIsAddIssueNote(true);
                                                setSelectedIssueId(
                                                  item?.issue?._id
                                                );
                                                setAddNoteDialog(true);
                                              }}
                                            />
                                          </CustomizedTooltips>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {selectedTask._id === item._id ? (
                                          <>
                                            <Dropdown
                                              name='status'
                                              onChange={(e) =>
                                                setTaskStatusDropdownData(
                                                  e.target.value
                                                )
                                              }
                                              value={taskStatusDropdownData}
                                              title={Strings.SELECT_AN_OPTION}
                                              selectClassName={
                                                classes.selectContainer
                                              }
                                              containerClassName={
                                                classes.form_input_container
                                              }
                                              optionArray={issueStatusArray}
                                            />
                                            <Button
                                              text={'Cancel'}
                                              className={
                                                classes.secondary_button
                                              }
                                              onClick={() => {
                                                setSummaryTaskStatus(false);
                                              }}
                                            />
                                            <Button
                                              text={'Save Updates'}
                                              className={classes.add_new_button}
                                              onClick={handleTaskStatusUpdate}
                                              loading={props.updateIssueLoading}
                                              disabled={
                                                props.updateIssueLoading
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <span
                                              className={classNames(
                                                classes.status_container
                                              )}
                                              style={{
                                                cursor: cursor,
                                                backgroundColor: statusColor(
                                                  item?.issue?.status
                                                ),
                                              }}
                                            >
                                              {item?.issue?.status}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </label>
                                {item?.issue?.description && (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <label
                                        className={classes.text_dark_blue}
                                        style={{
                                          fontSize: 14,
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      >
                                        {Strings.ISSUE_SUMMARY}
                                      </label>
                                    </div>

                                    <div
                                      id='reportIssueDescription'
                                      className={classes.text_common}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.issue?.description || '-',
                                      }}
                                    />
                                  </>
                                )}

                                {item?.issue?.notes?.length ? (
                                  <>
                                    <label
                                      className={classes.text_dark_blue}
                                      style={{
                                        fontSize: 14,
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      {Strings.COMMENTS_AND_HISTORY}
                                    </label>
                                    <ul className={classes.unOrder_list}>
                                      {sortData(item?.issue?.notes)?.map(
                                        (_el, _in) => {
                                          let hideBorder =
                                            item?.issue?.notes?.length - 1 ===
                                            _in;
                                          return (
                                            <li
                                              key={_in}
                                              className={classNames({
                                                [classes.notes_border]:
                                                  !hideBorder,
                                              })}
                                            >
                                              <div
                                                id='reportIssueNote'
                                                className={classes.text_common}
                                                dangerouslySetInnerHTML={{
                                                  __html: _el?.note || '-',
                                                }}
                                              />
                                              <label
                                                className={classes.text_common}
                                              >
                                                <b>{`${Strings.CREATED_BY}: `}</b>
                                                {`${
                                                  _el?.createdBy?.name || ''
                                                } ( ${moment(
                                                  _el?.createdAt
                                                ).format(
                                                  'MMM DD, YYYY h:mm A'
                                                )} )`}
                                              </label>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : null}

                                {item?.issue?.internalNotes?.length &&
                                !paramJobId ? (
                                  <>
                                    <label
                                      className={classes.text_dark_blue}
                                      style={{
                                        fontSize: 14,
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      {Strings.INTERNAL_NOTES}
                                    </label>
                                    <ul className={classes.unOrder_list}>
                                      {sortData(
                                        item?.issue?.internalNotes
                                      )?.map((_el, _in) => {
                                        let hideBorder =
                                          item?.issue?.internalNotes?.length -
                                            1 ===
                                          _in;
                                        return (
                                          <li
                                            key={_in}
                                            className={classNames({
                                              [classes.notes_border]:
                                                !hideBorder,
                                            })}
                                          >
                                            <div
                                              id='reportIssueNote'
                                              className={classes.text_common}
                                              dangerouslySetInnerHTML={{
                                                __html: _el?.note || '-',
                                              }}
                                            />
                                            <label
                                              className={classes.text_common}
                                            >
                                              <b>{`${Strings.CREATED_BY}: `}</b>
                                              {`${
                                                _el?.createdBy?.name || ''
                                              } ( ${moment(
                                                _el?.createdAt
                                              ).format(
                                                'MMM DD, YYYY h:mm A'
                                              )} )`}
                                            </label>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                ) : null}

                                {item?.issue?.attachments?.length ? (
                                  <>
                                    <label
                                      className={classes.text_dark_blue}
                                      style={{
                                        fontSize: 14,
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      {Strings.MEDIA}
                                    </label>
                                    <MediaImageContainer
                                      showImageText={true}
                                      imageArray={sortAttachments(
                                        item?.issue?.attachments
                                      )}
                                      itemsPerRow={
                                        isMobileView ? 2 : isTabletView ? 3 : 5
                                      }
                                      lg={3}
                                      xl={2.4}
                                      isEditReport={isEditReport}
                                      paramJobId={paramJobId}
                                      isReports
                                    />
                                  </>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </Stack>
              </>
            )}
          </>
        )}
      </Container>
      {openIssueDialog && (
        <NewIssue
          onClose={() => {
            setOpenIssueDialog(false);
            setSelectedElementId('');
            setSelectedIssueData(null);
            setIsApproveIssue(false);
          }}
          isNewIssue={isApproveIssue ? false : true}
          isApproveIssue={isApproveIssue}
          fetchedIssueData={isApproveIssue ? selectedIssueData : {}}
          onSuccess={changeElementIssueStatus}
        />
      )}

      {openNewMediaDialog && (
        <UploadFileWithTagsDialog
          onClose={() => setOpenNewMediaDialog(false)}
          reportId={reportData._id}
          setSelectedNewMedia={setSelectedNewMedia}
          selectedIndexDataForMedia={selectedIndexDataForMedia}
        />
      )}

      {showMediaDialog && (
        <CommonDialog
          title={'Media'}
          message={'Are you sure you want to delete this media?'}
          onSuccess={() => deleteMediaFromReport()}
          onClose={() => {
            setShowMediaDialog(false);
          }}
        />
      )}

      {sendReportDialog && !isClient(paramJobId) && (
        <SendReport
          selectedJobId={state?.jobId || paramJobId}
          onNewClose={() => setSendReportDialog(false)}
          client={reportData?.client}
          isReviewReport={false}
          conductedOn={reportData.conductedOn}
          isReport
        />
      )}
      {addNoteDialog && (
        <AddNotes
          isJob={!isAddIssueNote}
          isIssue={isAddIssueNote}
          selectedId={isAddIssueNote ? selectedIssueId : selectedJobId}
          onNewClose={() => {
            setAddNoteDialog(false);
            setIsAddIssueNote(false);
            setSelectedJobId('');
          }}
          onSuccess={addIssueNotes}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getReportData: state.getReportData.data,
  getReportLoading: state.getReportData.loading,
  markAsCompleteJobLoading: state.markAsCompleteJobData.loading,
  markAsCompleteJobData: state.markAsCompleteJobData.data,

  confirmIssueData: state.confirmIssueData.data,
  updateReportData: state.updateReportData.data,
  updateReportLoading: state.updateReportData.loading,
  downloadReportLoading: state.downloadReportData.loading,

  categoryListData: state.categoryListData.data,

  updateIssueLoading: state.updateIssueData.loading,
  updateIssueData: state.updateIssueData.data,
});

export default connect(mapStateToProps, {
  getReport,
  confirmIssue,
  updateReport,
  downloadReport,
  markAsCompleteJob,
  fetchCategoryList,
  updateIssue,
  updateReportAction,
})(ReportDetails);
