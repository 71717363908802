import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import { ManageEBlastSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { getErrorMessage } from '../../../utils/appUtils';
import { useAlert } from 'react-alert';
import Textbox from '../../../components/Textbox';
import classNames from 'classnames';
import { fetchEBlast } from '../../../redux/actions/adminActions/getEBlast';
import { updateEBlast } from '../../../redux/actions/adminActions/updateEBlast';
import { CircularProgress } from '@mui/material';

const ManageEBlast = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [eblastId, setEblastId] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchEBlast(onError);
  }, []);

  useEffect(() => {
    if (Object.keys(props.getEBlastData)?.length > 0) {
      const { _id, buttonText, url } = props.getEBlastData;
      setFieldValue('buttonText', buttonText || '');
      setFieldValue('url', url || '');
      setEblastId(_id || '');
    }
  }, [props.getEBlastData]);

  const formik = useFormik({
    initialValues: {
      buttonText: '',
      url: '',
    },
    validationSchema: ManageEBlastSchema,
    onSubmit: (values) => {
      const data = {
        eblastId: eblastId,
        buttonText: values.buttonText,
        url: values.url,
      };
      props.updateEBlast(data, onError, () => props.onNewClose());
    },
  });

  const { errors, touched, values, handleChange, setFieldValue, handleSubmit } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={Strings.MANAGE_EBLAST}
            onNewClose={props.onNewClose}
          />
          {props.getEBlastLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{
                  color: theme.palette.background_button,
                }}
              />
            </div>
          ) : (
            <FormikProvider value={formik}>
              <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <div className={classes.form_container}>
                  <Textbox
                    name='buttonText'
                    onChange={handleChange('buttonText')}
                    value={values.buttonText}
                    error={Boolean(touched.buttonText && errors.buttonText)}
                    helperText={touched.buttonText && errors.buttonText}
                    placeholder={Strings.ENTER_BUTTON_TEXT}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.margin,
                    )}
                  />
                  <Textbox
                    name='url'
                    onChange={handleChange('url')}
                    value={values.url}
                    error={Boolean(touched.url && errors.url)}
                    helperText={touched.url && errors.url}
                    placeholder={Strings.ENTER_URL}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classNames(
                      classes.form_input_container,
                      classes.margin,
                    )}
                  />
                  <Button
                    type='submit'
                    text={Strings.SAVE}
                    loading={props.updateEBlastLoading}
                    disabled={props.updateEBlastLoading}
                    containerClassName={classes.add_button_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>
              </Form>
            </FormikProvider>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getEBlastLoading: state.getEBlastData.loading,
  getEBlastData: state.getEBlastData.data,

  updateEBlastLoading: state.updateEBlastData.loading,
});

export default connect(mapStateToProps, {
  fetchEBlast,
  updateEBlast,
})(ManageEBlast);
