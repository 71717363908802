import axios from 'axios';
import { CLIENT_FEEDBACK_LIST } from '../../constants';

export const fetchClientFeedbackList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientFeedbackList());

  axios
    .get(`/feedback/list`, config)
    .then((res) => dispatch(setClientFeedbackList(res)))
    .catch((err) => {
      dispatch(errorClientFeedbackList(err.response));
      onError(err.response);
    });
};

export const loadClientFeedbackList = () => ({
  type: CLIENT_FEEDBACK_LIST.LOAD,
});

export const setClientFeedbackList = (userList) => ({
  type: CLIENT_FEEDBACK_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorClientFeedbackList = (error) => ({
  type: CLIENT_FEEDBACK_LIST.FAIL,
  payload: error,
});
