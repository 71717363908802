import axios from 'axios';
import { DELETE_NOTIFICATION } from '../../constants';

export const deleteNotification = (id, onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteNotification());
  axios
    .delete(`/notification/${id}`, config)
    .then((res) => {
      callback();
      dispatch(setDeleteNotification(res.data.data));
    })
    .catch((err) => {
      dispatch(errorDeleteNotification(err.response));
      onError(err.response);
    });
};

export const loadDeleteNotification = () => ({
  type: DELETE_NOTIFICATION.LOAD,
});

export const setDeleteNotification = (data) => ({
  type: DELETE_NOTIFICATION.SUCCESS,
  payload: data,
});

export const errorDeleteNotification = (error) => ({
  type: DELETE_NOTIFICATION.FAIL,
  payload: error,
});
