import { GET_ROOM_DATA } from '../../constants';

export const updateRoomData = (data) => (dispatch) => {
  dispatch(setRoomData(data));
};

export const loadRoomData = () => ({
  type: GET_ROOM_DATA.LOAD,
});

export const setRoomData = (countData) => ({
  type: GET_ROOM_DATA.SUCCESS,
  payload: countData,
});

export const errorRoomData = (error) => ({
  type: GET_ROOM_DATA.FAIL,
  payload: error,
});
