import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  dialog_label: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 32,
    color: theme.palette.title_text,
    fontWeight: 300,
    order: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      fontSize: 24,
      marginTop: 5,
    },
  },
  dialog_header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    // alignItems: 'center',
  },
  close_icon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    order: 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      order: 1,
    },
  },
}));
