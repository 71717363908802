import {
  Avatar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  AvatarGroup,
  Hidden,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import EnhancedTableHead from '../../components/DataTables/TableHead';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import TableToolbox from '../../components/TableToolbox';
import classNames from 'classnames';
import { Strings } from '../../utils/strings';
import NewJob from './NewJob';
import {
  PAGE_SIZE,
  compare2Object,
  getDateRangeValue,
  getDuration,
  getErrorMessage,
  getFirstTwoLetter,
  getLocaleStorageVal,
  isAdmin,
  isClient,
  jobsDetailTabArray,
  manageNotesHref,
  secondaryTableCell,
  setLocaleStorageVal,
  statusColor,
  tableCell,
  userId,
} from '../../utils/appUtils';
import Tabs from '../../components/Tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import JobDetails from './JobDetails';
import JobFilter from './JobFilter';
import { connect } from 'react-redux';
import {
  fetchJobList,
  setJobList,
} from '../../redux/actions/jobActions/jobActions/jobList';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import SendReport from './SendReport';
import { deleteJob } from '../../redux/actions/jobActions/jobActions/deleteJob';
import CommonDialog from '../../components/CommonDialog';
import { icons } from '../../utils/icons';
import { updateRecurrence } from '../../redux/actions/jobActions/jobActions/updateRecurrence';
import { cancelRecurrence } from '../../redux/actions/jobActions/jobActions/cancelRecurrence';
import { useAlert } from 'react-alert';
import TitleContainer from '../../components/TitleContainer';
import AddNotes from '../Issues/NotesDialog';
import Button from '../../components/Button';
import { deleteJobNote } from '../../redux/actions/jobActions/jobActions/deleteJobNote';
import debounce from 'lodash/debounce';
import MediaImageContainer from '../../components/MediaImageContainer';
import { listFilter } from '../../redux/actions/filterActions/listFilter';
import JobNewFilter from './JobFilter/JobNewFilter';
import { markAllJobsNotesRead } from '../../redux/actions/jobActions/jobActions/markAllJobNotesRead';
import { useDispatch } from 'react-redux';
import { setAddJobNote } from '../../redux/actions/jobActions/jobActions/addJobNote';
import { setUpdateJobNote } from '../../redux/actions/jobActions/jobActions/updateJobNote';
import CustomPagination from '../../components/CustomPagination';
import { Colors } from '../../utils/color';
import { clearJobData } from '../../redux/actions/jobActions/jobActions/getJobById';

const jobsDataStore = 'JobsData';

// ======== Table Head =========
const headCells = () => {
  return !isClient()
    ? [
        { id: 'title', label: 'TITLE', isSort: true },
        { id: 'property', label: 'PROPERTY', isSort: true },
        { id: 'startDate', label: 'START DATE', isSort: true },
        { id: 'duration', label: 'DURATION', isSort: true },
        { id: 'form', label: 'FORM', isSort: true },
        { id: 'assignedMember', label: 'ASSIGNED', isSort: true },
        { id: 'status', label: 'STATUS', isSort: true },
        // { id: 'client', label: 'CLIENT', isSort: true },
        // { id: 'report', label: 'REPORT' },
        // { id: 'viewedByClient', label: 'VIEWED BY CLIENT' },
        { id: '' },
      ]
    : [
        { id: 'title', label: 'TITLE', isSort: true },
        // { id: 'startDate', label: 'START DATE', isSort: true },
        // { id: 'endDate', label: 'END DATE', isSort: true },
        // { id: 'jobForm', label: 'FORM', isSort: true },
        // { id: 'assigned', label: 'ASSIGNED' },
        { id: 'property', label: 'PROPERTY', isSort: true },
        { id: 'assignedMember', label: 'ASSIGNED MEMBER', isSort: false },
        { id: 'conductedOn', label: 'CONDUCTED ON', isSort: true },
        { id: 'status', label: 'STATUS', isSort: true },
        { id: 'report', label: 'REPORT' },
        // { id: '' },
      ];
};

const NotesDataList = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (props?.data?.note) {
      manageNotesHref('jobNoteData');
    }
  }, [props?.data]);

  return (
    <div
      style={{
        borderBottom: !props.isBottomBorder && 'none',
        ...props.style,
      }}
    >
      <div className={classes.note_view_container}>
        <div className={classes.note_text_container}>
          <div
            id='jobNoteData'
            className={classes.note_data}
            dangerouslySetInnerHTML={{ __html: props?.data?.note }}
          />
          {/* {props?.data?.createdBy?._id === userId() && ( */}
          {props?.isEditDelete ? (
            <div
              className={classes.note_text_container}
              style={{ marginLeft: 'auto' }}
            >
              <CustomizedTooltips title={Strings.EDIT_NOTE}>
                <img
                  className={classes.action_icons}
                  src={icons(theme).editIcon}
                  alt=''
                  onClick={() => props.onEditClick()}
                />
              </CustomizedTooltips>
              {props?.deleteLoader ? (
                <CircularProgress
                  style={{
                    color: theme.palette.background_button,
                    marginLeft: 15,
                  }}
                  size={20}
                />
              ) : (
                <CustomizedTooltips title={Strings.DELETE_NOTE}>
                  <img
                    className={classes.action_icons}
                    src={icons(theme).deleteIcon}
                    alt=''
                    onClick={() => props.onDeleteClick()}
                    style={{ marginLeft: 15 }}
                  />
                </CustomizedTooltips>
              )}
            </div>
          ) : null}
          {/* )} */}
        </div>
      </div>
      {props?.data?.createdBy && (
        <div className={classes.note_details_container}>
          <p
            className={classes.note_data}
            style={{ paddingBottom: 0, marginBottom: 0 }}
          >
            <span style={{ fontWeight: 600 }}>{Strings.CREATED_BY}: </span>
            {`${props?.data?.createdBy?.name} ( ${
              moment(props?.data?.createdAt).format('ll') + // LL - Jul 14, 2023
              ' ' +
              moment(props?.data?.createdAt).format('LT')
            } )`}
          </p>
          {props?.data?.updatedBy && (
            <p
              className={classes.note_data}
              style={{ paddingBottom: 0, marginBottom: 0 }}
            >
              <span style={{ fontWeight: 600 }}>{Strings.UPDATED_BY}: </span>
              {`${props?.data?.updatedBy?.name} ( ${
                moment(props?.data?.updatedAt).format('ll') + // LL - Jul 14, 2023
                ' ' +
                moment(props?.data?.updatedAt).format('LT')
              } )`}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const Jobs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  let { state } = useLocation();
  const dispatch = useDispatch();
  const existingJobsData = getLocaleStorageVal(jobsDataStore) ?? {};

  const [search, setSearch] = useState(existingJobsData?.searchStr || '');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [jobData, setJobData] = useState({});
  const jobData = props.jobListData || {};
  const [addJobDialog, setAddJobDialog] = useState(false);
  const [jobDetailDialog, setJobDetailDialog] = useState(false);
  const [jobFilterDialog, setJobFilterDialog] = useState(false);
  const [sendReportDialog, setSendReportDialog] = useState(false);
  const [activeTab, setActiveTab] = useState('Today');
  const alert = useAlert();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(
    isAdmin() ? { showAllJob: false } : null
  );
  const [deleteJobDialog, setDeleteJobDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [selectedJobClient, setSelectedJobClient] = useState('');
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [openRows, setOpenRows] = useState([]);
  const [detailsActiveTab, setDetailsActiveTab] = useState([]);
  const [order, setOrder] = useState(
    existingJobsData?.sortBy
      ? existingJobsData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingJobsData?.sortBy?.key || '');
  const [isEditNote, setIsEditNote] = useState(false);
  const [selectedNoteData, setSelectedNoteData] = useState(null);
  const [selectedJobNoteId, setSelectedJobNoteId] = useState(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const laptopView = useMediaQuery(theme.breakpoints.down('lg'));
  const below600 = useMediaQuery('(max-width: 700px)');

  const [filterDropdownValue, setFilterDropdownValue] = useState('');
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState();
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [shouldStateIncludeJob, setShouldStateIncludeJob] = useState(true);

  // ===== check is filter applied or not =====
  const isFilterApplied = useMemo(() => {
    if (filter && !isAdmin() && Object.keys(filter)?.length) {
      return true;
    } else if (filter && !compare2Object({ showAllJob: false }, filter)) {
      return true;
    } else return false;
  }, [filter]);

  // ===== Job details tabs list =====
  const getJobTabsList = (data) => {
    if (data) {
      let internalNotes =
        data?.jobNotes?.filter((item) => item?.internalNotes) || [];
      let notes = data?.jobNotes?.filter((item) => !item?.internalNotes) || [];
      return isClient()
        ? [
            { id: 'details', name: 'Job Summary', hideDataCount: true },
            // {
            //   id: 'notes',
            //   name: 'Comments and History',
            //   length: notes?.length || 0,
            // },
            { id: 'media', name: 'Media', hideDataCount: true },
          ]
        : [
            { id: 'details', name: 'Job Summary', hideDataCount: true },
            // {
            //   id: 'notes',
            //   name: 'Comments and History',
            //   length: notes?.length || 0,
            // },
            {
              id: 'internalNotes',
              name: 'Internal Notes',
              length: internalNotes?.length || 0,
            },
            { id: 'media', name: 'Media', hideDataCount: true },
          ];
    }
  };

  // === check any new note/internal notes create and unread or not ===
  const isUnReadData = useCallback(
    (data) => {
      if (data) {
        let isUnreadInternalNotes =
          data?.jobNotes
            ?.filter((item) => item?.internalNotes)
            ?.some((el) => el?.unReadBy?.includes(userId())) || false;
        let isUnreadNotes =
          data?.jobNotes
            ?.filter((item) => !item?.internalNotes)
            ?.some((el) => el?.unReadBy?.includes(userId())) || false;
        if (isUnreadInternalNotes || isUnreadNotes) return true;
        else return false;
      }
      return false;
    },
    [props.jobListData, state, shouldStateIncludeJob]
  );

  // === manage jobList data ===
  const manageJobsListData = (data) => {
    if (data && Object?.keys(data)?.length && data?.job) {
      let fetchedData = data?.job;
      let newData = structuredClone(jobData);
      let newJobsData = newData?.jobs?.map((el) => {
        if (el?._id === fetchedData?._id) {
          el.jobNotes = fetchedData?.jobNotes;
        }
        return el;
      });
      newData.jobs = newJobsData;
      dispatch(setJobList(newData));

      const fIndexOfJob = newData?.jobs?.findIndex(
        (el) => el?._id === selectedJobId
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfJob);
      if (fRowIndex === -1) onOpenRow(fIndexOfJob);
      const selectedTab = data?.internalNotes ? 'internalNotes' : 'notes';
      const tabNewData = [...detailsActiveTab];
      if (fIndexOfJob !== -1) {
        tabNewData[fIndexOfJob].key = selectedTab;
        const data = {
          type: selectedTab,
          jobId: selectedJobId,
        };
        props.markAllJobsNotesRead(data, onError);
      }
      setDetailsActiveTab(tabNewData);
      setSelectedJobId(null);
    }
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getJobList = (data, stateInclude) => {
    const newData = { ...data };
    const pageFilter = existingJobsData?.pageFilter;
    const sortBy = existingJobsData?.sortBy;
    if (!data?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (!data?.page_size) newData.page_size = pageFilter?.pageSize || pageSize;
    if (!data.sortBy && sortBy)
      newData.sortBy = { [sortBy?.key]: sortBy?.value };
    if (!data.searchString)
      newData.searchString = existingJobsData?.searchStr || search;
    if (stateInclude && state && state?.selectedJob) {
      newData.jobId = state?.selectedJob;
      newData.searchString = '';
      let newFilter = {};
      if (isAdmin()) newFilter = { showAllJob: false };
      newData.filter = newFilter;
      setSearch('');
      setFilter(newFilter);
      setFilterDropdownValue('');
    }
    props.fetchJobList(newData, (err) => onError(err), 'reportJobModule');
  };

  const delayedSearch = useCallback(
    debounce(
      (data, type) =>
        props?.fetchJobList(
          data,
          (err) => onError(err),
          isClient() ? 'reportJobModule' : ''
        ),
      600
    ),
    []
  );

  useEffect(() => {
    if (
      (startDate &&
        moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
        endDate &&
        moment(endDate).format('YYYY-MM-DD') !== 'Invalid date') ||
      !startDate ||
      !endDate
    ) {
      const storedFilter = existingJobsData?.filter ?? {};
      const storedPageFilter = existingJobsData?.pageFilter ?? {};
      let prevFilter =
        Object.keys(storedFilter).length > 0 ? existingJobsData : {};

      const data = {
        searchString: search,
      };

      if (prevFilter && (prevFilter?.filterId || prevFilter?.filter)) {
        if (prevFilter?.filterId) {
          setFilterDropdownValue(prevFilter?.filterId || '');
        }

        if (prevFilter?.filter) {
          if (prevFilter?.filter?.dateRange !== 'All Time') {
            let dateRange = getDateRangeValue(prevFilter?.filter?.dateRange);
            let filter = {
              ...prevFilter?.filter,
              startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
              endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            };
            prevFilter = { filter: { ...filter } };
          }
          data.filter = {
            ...prevFilter?.filter,
          };
          setFilter(prevFilter?.filter);
        }
      } else {
        if (filter) data.filter = filter;
        if (
          startDate &&
          moment(startDate).format('YYYY-MM-DD') !== 'Invalid date'
        )
          data.startDate = moment(startDate).format('YYYY-MM-DD');
        if (endDate && moment(endDate).format('YYYY-MM-DD') !== 'Invalid date')
          data.endDate = moment(endDate).format('YYYY-MM-DD');
      }
      setPageCount(storedPageFilter?.pageNumber || 1);
      setPageSize(storedPageFilter?.pageSize || PAGE_SIZE);
      if (state?.shouldStateIncludeJob) setShouldStateIncludeJob(true);
      getJobList(data, state?.shouldStateIncludeJob || shouldStateIncludeJob);
      if (state?.shouldStateIncludeJob) {
        state.shouldStateIncludeJob = false;
      }
    }
  }, [
    state,
    // activeTab,
    props.addJobData,
    props.deleteJobData,
    props.updateJobData,
    props.updateRecurrenceData,
    props.cancelRecurrenceData,
    props.sendReportData,
    props.updateFileDetailsData,
    // props.addJobNoteData,
    // props.updateJobNoteData,
  ]);

  const setFilterData = (data) => {
    // state = null;
    setShouldStateIncludeJob(false);
    if (JSON.stringify(data) === '{}' && filter) {
      // setSearch('');
      setStartDate(null);
      setEndDate(null);
      setFilter(isAdmin() ? { showAllJob: false } : null);
      getJobList({ page_number: 1, searchString: search || '' });
      setPageCount(1);
      setFilterDropdownValue('');
    } else if (JSON.stringify(data) !== '{}') {
      let newData = JSON.parse(JSON.stringify(data));
      // if (newData?.dateRange) delete newData.dateRange;
      // setSearch('');
      setStartDate(null);
      setEndDate(null);
      setFilter(data);
      getJobList({
        page_number: 1,
        filter: newData,
        searchString: search || '',
      });
      setPageCount(1);
      !compare2Object(existingJobsData.filter || {}, data || {}) &&
        setFilterDropdownValue('');
    }
    let filterId =
      JSON.stringify(data) !== '{}' &&
      compare2Object(existingJobsData.filter || {}, data || {})
        ? existingJobsData?.filterId
        : '';
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      filterId: filterId || '',
      filter: data,
      pageFilter: { pageNumber: 1, pageSize: pageSize },
    });
  };

  const onDeleteJob = () => {
    props.deleteJob(
      selectedJob._id,
      () => setDeleteJobDialog(false),
      (err) => onError(err)
    );
  };

  // const tabsArray = isClient()
  //   ? [
  //       { id: 'Today', name: 'TODAY', length: jobData?.today || 0 },
  //       { id: 'Upcoming', name: 'UPCOMING', length: jobData?.upcoming || 0 },
  //       {
  //         id: 'Ready for Admin Review',
  //         name: 'READY FOR ADMIN REVIEW',
  //         length: jobData?.readyForAdminReview || 0,
  //       },
  //       {
  //         id: 'Completed',
  //         name: 'COMPLETED',
  //         length: jobData?.completed || 0,
  //       },
  //       // {
  //       //   id: 'Cancelled',
  //       //   name: 'CANCELLED',
  //       //   length: jobData?.cancelled || 0,
  //       // },
  //     ]
  //   : [
  //       { id: 'Today', name: 'TODAY', length: jobData?.today || 0 },
  //       {
  //         id: 'Billing Follow Up',
  //         name: 'BILLING FOLLOW UP',
  //         length: jobData?.billingFollowUp || 0,
  //       },
  //       {
  //         id: 'In Progress',
  //         name: 'IN PROGRESS',
  //         length: jobData?.inProgress || 0,
  //       },
  //       {
  //         id: 'Ready for Admin Review',
  //         name: 'READY FOR ADMIN REVIEW',
  //         length: jobData?.readyForAdminReview || 0,
  //       },
  //       {
  //         id: 'Team Discussion Needed',
  //         name: 'Team Discussion Needed',
  //         length: jobData?.teamDiscussionNeeded || 0,
  //       },
  //       {
  //         id: 'Ready for Billing Review',
  //         name: 'READY FOR BILLING REVIEW',
  //         length: jobData?.readyForBillingReview || 0,
  //       },
  //       {
  //         id: 'Completed',
  //         name: 'COMPLETED',
  //         length: jobData?.completed || 0,
  //       },
  //       // {
  //       //   id: 'Cancelled',
  //       //   name: 'CANCELLED',
  //       //   length: jobData?.cancelled || 0,
  //       // },
  //     ];

  const setTabNotificationData = () => {
    const newData = jobData?.jobs?.map((el, ind) => {
      let internalNotes = el?.jobNotes?.filter((item) => item?.internalNotes);
      let notes = el?.jobNotes?.filter((item) => !item?.internalNotes);
      let isNotReadInternalNotes =
        internalNotes?.length > 0
          ? internalNotes?.some((elm) => elm?.unReadBy?.includes(userId()))
          : false;
      let isNotReadNote =
        notes?.length > 0
          ? notes?.some((elm) => elm?.unReadBy?.includes(userId()))
          : false;

      return {
        key: 'details', // isClient() ? 'notes' : 'details'
        notesNotification: isNotReadNote,
        internalNotesNotification: isNotReadInternalNotes,
      };
    });
    return newData;
  };

  useEffect(() => {
    if (jobData && jobData?.jobs?.length > 0) {
      const newData = setTabNotificationData();
      setDetailsActiveTab(newData);
    }
  }, [jobData]);

  const cursor = !isClient() ? 'pointer' : 'auto';

  const onOpenRow = (rowIndex) => {
    if (openRows.findIndex((item, index) => item === rowIndex) === -1) {
      setOpenRows([...openRows, rowIndex]);
    } else {
      setOpenRows(openRows.filter((item) => item !== rowIndex));
    }
  };

  // const applySortFilter = (order, orderBy, array, comparator) => {
  //   if (orderBy === 'jobStatus' && !isClient()) {
  //     const priorities = {
  //       New: 1,
  //       'In Progress': 2,
  //       'Ready for Admin Review': 3,
  //       'Ready for Billing Review': 4,
  //       Completed: 5,
  //       Cancelled: 6,
  //       'Team Discussion Needed': 7,
  //       'Billing Follow Up': 8,
  //     };
  //     let sorted = array.sort(function (a, b) {
  //       if (order === 'asc') {
  //         return priorities[b.jobStatus] - priorities[a.jobStatus];
  //       } else {
  //         return priorities[a.jobStatus] - priorities[b.jobStatus];
  //       }
  //     });
  //     return sorted;
  //   } else {
  //     const stabilizedThis = array?.map((el, index) => [el, index]);
  //     stabilizedThis?.sort((a, b) => {
  //       const order = comparator(a[0], b[0]);
  //       if (order !== 0) return order;
  //       return a[1] - b[1];
  //     });
  //     return stabilizedThis?.map((el) => el[0]);
  //   }
  // };

  // const getComparator = (order, orderBy) => {
  //   return order === 'desc'
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // };

  // const descendingComparator = (a, b, orderBy) => {
  //   let asceding = b[orderBy] < a[orderBy];
  //   let descding = b[orderBy] > a[orderBy];
  //   if (orderBy === 'title') {
  //     asceding = b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase();
  //     descding = b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase();
  //   } else if (orderBy === 'jobForm') {
  //     asceding =
  //       b[orderBy]?.formName.toLowerCase() < a[orderBy]?.formName.toLowerCase();
  //     descding =
  //       b[orderBy]?.formName.toLowerCase() > a[orderBy]?.formName.toLowerCase();
  //   } else if (orderBy === 'client') {
  //     asceding =
  //       b[orderBy]?.lastName.toLowerCase() < a[orderBy]?.lastName.toLowerCase();
  //     descding =
  //       b[orderBy]?.lastName.toLowerCase() > a[orderBy]?.lastName.toLowerCase();
  //   } else if (orderBy === 'property') {
  //     asceding =
  //       b[orderBy]?.name.toLowerCase() < a[orderBy]?.name.toLowerCase();
  //     descding =
  //       b[orderBy]?.name.toLowerCase() > a[orderBy]?.name.toLowerCase();
  //   } else if (orderBy === 'startDate' || orderBy === 'endDate') {
  //     asceding = moment(b[orderBy]) < moment(a[orderBy]);
  //     descding = moment(b[orderBy]) > moment(a[orderBy]);
  //   } else if (orderBy === 'report') {
  //     asceding =
  //       moment(b[orderBy]?.conductedOn) < moment(a[orderBy]?.conductedOn);
  //     descding =
  //       moment(b[orderBy]?.conductedOn) > moment(a[orderBy]?.conductedOn);
  //   }
  //   if (asceding) return -1;
  //   if (descding) return 1;
  //   return 0;
  // };

  const handleRequestSort = (event, property) => {
    // state = null;
    setShouldStateIncludeJob(false);
    setOpenRows([]);
    const isAsc = orderBy === property && order === 'asc';
    // const newData = setTabNotificationData();
    // setDetailsActiveTab(newData);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getJobList({ filter: filter || {}, sortBy });
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredJobs = applySortFilter(
  //   order,
  //   orderBy,
  //   jobData?.jobs,
  //   getComparator(order, orderBy, descendingComparator)
  // );

  const onDeleteNoteClick = (jId, nId) => {
    props.deleteJobNote(jId, nId, onError, () => {
      const newJobsData = props.jobListData;
      const jobs = (newJobsData && newJobsData?.jobs) || [];
      const newData = jobs?.map((item) => {
        if (item?._id === jId) {
          let newJobNote = item?.jobNotes?.filter((el) => el?._id !== nId);
          item.jobNotes = newJobNote;
        }
        return item;
      });
      newJobsData.jobs = newData;
      dispatch(setJobList(newJobsData));
      setSelectedJobNoteId(null);
    });
  };

  useEffect(() => {
    !isFilterChange && !isClient() && props.listFilter('JOB', onError);
  }, []);

  useEffect(() => {
    if (!isClient()) {
      if (isFilterChange) {
        // state = null;
        setShouldStateIncludeJob(false);
        // const storedFilter = existingJobsData?.filter ?? {};
        // let prevFilter =
        //   Object.keys(storedFilter).length > 0 ? JSON.parse(storedFilter) : {};
        if (existingJobsData?.filterId) {
          setLocaleStorageVal(jobsDataStore, {
            ...existingJobsData,
          });
        }
        props.listFilter('JOB', onError);
        filterDropdownValue &&
          getJobList(
            isAdmin() ? { page_number: 1, filter: { showAllJob: false } } : {}
          );
        setFilter(isAdmin() ? { showAllJob: false } : null);
        setFilterDropdownValue('');
        setIsFilterChange(false);
      }
    }
  }, [isFilterChange]);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  const handleFilterSelectionClick = (value) => {
    // state = null;
    setShouldStateIncludeJob(false);
    let filterData = filterDropdownList?.filter((el) => el.value === value)[0];
    let newFilter = filterData?.filter
      ? filterData?.filter
      : isAdmin()
      ? { showAllJob: false }
      : null;
    setFilter(newFilter);
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      pageFilter: { pageNumber: 1, pageSize },
      filter: newFilter ? { ...newFilter } : {},
      filterId: value,
    });
    if (filterData?.filter?.dateRange) {
      const dateRange = getDateRangeValue(filterData.filter.dateRange);
      if (filterData.filter.dateRange !== 'All Time') {
        filterData.filter.startDate = moment(dateRange.startDate).format(
          'YYYY-MM-DD'
        );
        filterData.filter.endDate = moment(dateRange.endDate).format(
          'YYYY-MM-DD'
        );
      }
    }
    const data = { page_number: 1 };
    if (filterData?.filter) data.filter = filterData?.filter;
    if (!filterData?.filter && isAdmin()) data.filter = { showAllJob: false };
    if (search) data.searchString = search;
    getJobList(data);
  };

  const onClickRefresh = () => {
    let data = {};
    if (filter) data.filter = filter;
    if (search) data.searchString = search;
    // state = null;
    setShouldStateIncludeJob(false);
    // setPageCount(1);
    getJobList(data);
  };

  const onClickClearFilter = async () => {
    // setSearch('');
    setFilterDropdownValue('');
    setPageCount(1);
    // state = null;
    setShouldStateIncludeJob(false);
    if (isAdmin()) {
      await setFilter({ showAllJob: false });
      await getJobList({
        page_number: 1,
        searchString: search,
        filter: { showAllJob: false },
      });
    } else {
      await setFilter(null);
      await getJobList({ page_number: 1, searchString: search });
    }
    setPageSize(PAGE_SIZE);
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      pageFilter: {},
    });
  };

  const assignedInitial = (assignedName) => {
    const initial =
      assignedName.split(' ').length > 1
        ? assignedName.split(' ')[0]?.charAt(0)?.toUpperCase() +
          assignedName.split(' ')[1]?.charAt(0)?.toUpperCase()
        : assignedName.split(' ')[0]?.charAt(0)?.toUpperCase();
    return initial;
  };

  // ===== notification related changes =====
  useEffect(() => {
    if (state && jobData?.jobs?.length > 0) {
      const copyJobsData = structuredClone(jobData?.jobs);
      const fIndexOfJob = copyJobsData?.findIndex(
        (el) => el?._id === state.selectedJob
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfJob);
      if (fRowIndex === -1) onOpenRow(fIndexOfJob);
      if (state?.selectedTab) {
        const newData = setTabNotificationData();
        if (fIndexOfJob !== -1) {
          newData[fIndexOfJob].key = state.selectedTab;
          let selectedTabKey =
            state?.selectedTab === 'internalNotes'
              ? 'internalNotesNotification'
              : 'notesNotification';
          newData[fIndexOfJob][selectedTabKey] = false;
          const data = {
            type: state.selectedTab,
            jobId: state.selectedJob,
          };
          props.markAllJobsNotesRead(data, onError);
        }
        setDetailsActiveTab(newData);
      }
      const elem = document.getElementById(state.selectedJob);
      if (elem) elem?.scroll({ left: 0, top: 0, behavior: 'smooth' });
      // state = null;
      if (!state?.shouldStateIncludeJob) setShouldStateIncludeJob(false);
    }
  }, [state, jobData]);

  // === clear jobList reducer data ===
  useEffect(() => {
    return () => {
      dispatch(setJobList({}));
    };
  }, []);

  // ==== when add/edit note open selectedJob and selectedTab ====
  const setAddEditNotes = async (data) => {
    if (data && Object?.keys(data)?.length > 0) {
      const newJobsData = jobData;
      const jobs = (newJobsData && newJobsData?.jobs) || [];
      const newData = jobs?.map((item) => {
        if (item?._id === selectedJobId) {
          if (selectedJobNoteId) {
            let newJobNote = item?.jobNotes?.map((el) => {
              if (el?._id === selectedJobNoteId) {
                el = data;
                // el.note = data?.note;
                // el.internalNotes = data.internalNotes;
              }
              return el;
            });
            item.jobNotes = newJobNote;
          } else {
            item.jobNotes = [data, ...item.jobNotes];
          }
        }
        return item;
      });
      newJobsData.jobs = newData;
      dispatch(setJobList(newJobsData));
      dispatch(setAddJobNote(null));
      dispatch(setUpdateJobNote(null));
      const fIndexOfJob = newJobsData?.jobs?.findIndex(
        (el) => el?._id === selectedJobId
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfJob);
      if (fRowIndex === -1) onOpenRow(fIndexOfJob);
      const selectedTab = data?.internalNotes ? 'internalNotes' : 'notes';
      const tabNewData = [...detailsActiveTab];
      if (fIndexOfJob !== -1) {
        tabNewData[fIndexOfJob].key = selectedTab;
        const data = {
          type: selectedTab,
          jobId: selectedJobId,
        };
        props.markAllJobsNotesRead(data, onError);
      }
      setDetailsActiveTab(tabNewData);
      setSelectedJobNoteId(null);
      setSelectedJobId(null);
    }
  };

  useEffect(() => {
    const data = selectedJobNoteId
      ? props.updateJobNoteData || {}
      : props.addJobNoteData || {};
    if (
      data &&
      selectedJobId &&
      jobData?.jobs?.length > 0 &&
      (props.addJobNoteData || props.updateJobNoteData)
    ) {
      setAddEditNotes(data);
    }
  }, [jobData, props.addJobNoteData, props.updateJobNoteData]);

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    const newData = {
      searchString: search,
      filter: filter || {},
      page_number: page,
    };
    // state = null;
    setShouldStateIncludeJob(false);
    if (isClient()) {
      if (startDate) newData.startDate = moment(startDate).format('YYYY-MM-DD');
      if (endDate) newData.endDate = moment(endDate).format('YYYY-MM-DD');
    }
    if (pageCount !== page) {
      getJobList(newData);
    }
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      pageFilter: { pageNumber: page, pageSize: pageSize },
    });
    setPageCount(page);
    setOpenRows([]);
  };

  // ===== call when pageSize change =====
  const handlePageSizeChange = (pSize) => {
    const newData = {
      searchString: search,
      filter: filter || {},
      page_number: 1,
      page_size: pSize,
    };
    // state = null;
    setShouldStateIncludeJob(false);
    if (isClient()) {
      if (startDate) newData.startDate = moment(startDate).format('YYYY-MM-DD');
      if (endDate) newData.endDate = moment(endDate).format('YYYY-MM-DD');
    }
    if (pageSize !== pSize) {
      getJobList(newData);
    }
    setLocaleStorageVal(jobsDataStore, {
      ...existingJobsData,
      pageFilter: { pageNumber: 1, pageSize: pSize },
    });
    setPageSize(pSize);
    setPageCount(1);
    setOpenRows([]);
  };

  // ===== sort data by date descending order =====
  const sortData = useCallback(
    (array) => {
      const newData = structuredClone(array);
      let data = newData?.sort((a, b) => {
        if (moment(a.createdAt) < moment(b.createdAt)) return 1;
        if (moment(a.createdAt) > moment(b.createdAt)) return -1;
        return 0;
      });
      return data || array;
    },
    [jobData]
  );

  return (
    <Stack
      className={
        !isClient() ? classes.job_container : classes.job_container_clientLogin
      }
    >
      <TitleContainer
        containerClassName={classes.title_container}
        title={isClient() ? Strings.REPORTS : Strings.JOBS}
      />
      <TableToolbox
        onClickRefresh={onClickRefresh}
        onClickClearFilter={onClickClearFilter}
        isFilter={!isClient()}
        isDate={isClient()}
        isEndDate={isClient()}
        isSearch
        search={search}
        date={startDate}
        endDate={endDate}
        isPrimaryButton
        isSecondaryButton
        onChangeSearch={(e) => {
          setSearch(e.target.value);
          // setFilterDropdownValue('');
          // setFilter(isAdmin() ? { showAllJob: false } : null);
          const data = {
            searchString: e.target.value,
            page_size: pageSize,
            page_number: 1,
          };
          let sortBy = existingJobsData?.sortBy;
          if (sortBy) data.sortBy = { [sortBy?.key]: sortBy?.value };
          if (
            startDate &&
            moment(startDate).format('YYYY-MM-DD') !== 'Invalid date'
          )
            data.startDate = moment(startDate).format('YYYY-MM-DD');
          if (
            endDate &&
            moment(endDate).format('YYYY-MM-DD') !== 'Invalid date'
          )
            data.endDate = moment(endDate).format('YYYY-MM-DD');
          if (filter && Object.entries(filter)?.length) {
            data.filter = filter;
          } else {
            setFilter(isAdmin() ? { showAllJob: false } : null);
            if (isAdmin()) data.filter = { showAllJob: false };
          }
          // getJobList(data);
          delayedSearch(data);
          setPageCount(1);
          setLocaleStorageVal(jobsDataStore, {
            ...existingJobsData,
            searchStr: e.target.value,
            pageFilter: { pageNumber: 1, pageSize },
          });
        }}
        onChangeDate={(e) => {
          setStartDate(e);
          if (
            (e && moment(e).format('YYYY-MM-DD') !== 'Invalid date') ||
            (e === null && startDate)
          ) {
            setFilter(isAdmin() ? { showAllJob: false } : null);
            let data = {
              searchString: search,
              page_number: 1,
            };
            if (e && moment(e).format('YYYY-MM-DD') !== 'Invalid date')
              data.startDate = moment(e).format('YYYY-MM-DD');
            if (
              endDate &&
              moment(endDate).format('YYYY-MM-DD') !== 'Invalid date'
            )
              data.endDate = moment(endDate).format('YYYY-MM-DD');
            getJobList(data);
            setPageCount(1);
          }
        }}
        onChangeEndDate={(e) => {
          setEndDate(e);
          if (
            (e && moment(e).format('YYYY-MM-DD') !== 'Invalid date') ||
            (e === null && endDate)
          ) {
            setFilter(isAdmin() ? { showAllJob: false } : null);
            let data = {
              searchString: search,
              page_number: 1,
            };
            if (e && moment(e).format('YYYY-MM-DD') !== 'Invalid date')
              data.endDate = moment(e).format('YYYY-MM-DD');
            if (
              startDate &&
              moment(startDate).format('YYYY-MM-DD') !== 'Invalid date'
            )
              data.startDate = moment(startDate).format('YYYY-MM-DD');
            getJobList(data);
            setPageCount(1);
          }
        }}
        secondaryButtonText={Strings.JOB_FORMS}
        primaryButtonText={Strings.NEW_JOB}
        onNewClick={() => setAddJobDialog(true)}
        secondaryButtonClick={() => navigate('/app/jobs/forms')}
        filterButtonClick={() => setJobFilterDialog(true)}
        toolboxContainerClassName={
          !isClient()
            ? classes.toolbox_container
            : classes.toolbox_container_clientLogin
        }
        startDateContainerClassName={classes.start_date_container}
        dateContainerClass={classes.dateContainerClass}
        isFilterDropdown={
          !isClient() && filterDropdownList.length ? true : false
        }
        filterDropdownValue={filterDropdownValue}
        filterDropdownList={filterDropdownList}
        onChangeFilterDropdown={(e) => {
          setPageCount(1);
          // setSearch('');
          setFilterDropdownValue(e);
          handleFilterSelectionClick(e);
          // setFilter({});
        }}
        onEditClick={(value) =>
          setOpenEditFilterDialog({ show: true, ...value })
        }
      />
      {/* {isClient() && (
        <Tabs
          dataList={tabsArray}
          activeTab={activeTab}
          onChange={(value) => {
            setSearch('');
            setFilter(isAdmin() ? { showAllJob: false } : null);
            setStartDate(null);
            setEndDate(null);
            setActiveTab(value);
            setFilterDropdownValue('0');
            if (activeTab !== value) setOpenRows([]);
          }}
          containerClassName={classes.tab_container}
        />
      )} */}
      {!isClient() && isFilterApplied ? (
        <p className={classes.filteredTitleText}>Filter Applied</p>
      ) : null}
      <div
        className={
          !isClient() && !mobileView
            ? classes.list_container
            : classes.list_container_client_job
        }
      >
        {props.jobListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {jobData?.jobs?.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              // <TableContainer className='dataTable'>
              <Table
                className={classes.list_table}
                aria-labelledby='tableTitle'
                stickyHeader={isClient()}
                // stickyHeader={isClient() && !below600}
              >
                <Hidden smDown>
                  <EnhancedTableHead
                    headCells={headCells()}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                </Hidden>
                <TableBody>
                  {jobData &&
                    jobData?.jobs?.map((data, index) => {
                      const {
                        _id,
                        date,
                        type,
                        assignedMember,
                        status,
                        title,
                        reportStatus,
                        client,
                        property,
                        // clientName,
                        // clientEmail,
                        report,
                        ViewByClient,
                        jobForm,
                        // jobType,
                        startDate,
                        endDate,
                        jobStatus,
                        jobNotes,
                        createdBy,
                        attachments,
                        details,
                        appointments,
                        userAvailability,
                      } = data;
                      return (
                        <>
                          <TableRow
                            id={data._id}
                            className={classes.row_style}
                            key={data._id + index}
                            sx={{ width: '100%' }}
                            onClick={() => {
                              if (isClient()) {
                                onOpenRow(index);
                              } else {
                                setSelectedJobId(_id);
                                !isClient() && setJobDetailDialog(true);
                              }
                            }}
                          >
                            <Hidden smUp>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.includes(index),
                                    userAvailability
                                  ),
                                  cursor: cursor,
                                  paddingLeft: 14,
                                  paddingRight: 0,
                                }}
                                component='td'
                                scope='row'
                                // onClick={() => {
                                //   setSelectedJobId(_id);
                                //   !isClient() && setJobDetailDialog(true);
                                // }}
                              >
                                <Stack direction='row' alignItems='center'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.includes(index)
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onOpenRow(index);
                                    }}
                                  />
                                  <Stack direction='column'>
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      gap={0.75}
                                    >
                                      {isUnReadData(data) ? (
                                        <div className={classes.redDot} />
                                      ) : null}
                                      <label
                                        className={classes.name_cell}
                                        style={{
                                          cursor: cursor,
                                          color:
                                            userAvailability === 'Here' &&
                                            Colors.DARK_GRAY,
                                        }}
                                      >
                                        {title}
                                      </label>
                                    </Stack>
                                    <Stack
                                      paddingTop={1.5}
                                      direction='row'
                                      alignItems='center'
                                    >
                                      <label
                                        className={classes.common_cell}
                                        style={{
                                          cursor: cursor,
                                          color:
                                            userAvailability === 'Here' &&
                                            Colors.DARK_GRAY,
                                        }}
                                      >
                                        {report?.conductedOn
                                          ? moment(report.conductedOn).format(
                                              'MMM DD, YYYY'
                                            )
                                          : '-'}
                                      </label>

                                      <label
                                        className={classNames(
                                          classes.common_cell,
                                          classes.status_container
                                        )}
                                        style={{
                                          cursor: cursor,
                                          backgroundColor:
                                            statusColor(jobStatus),
                                        }}
                                      >
                                        {jobStatus}
                                      </label>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </TableCell>
                            </Hidden>
                            <Hidden smDown>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.includes(index),
                                    userAvailability
                                  ),
                                  cursor: cursor,
                                }}
                                component='td'
                                scope='row'
                                // onClick={() => {
                                //   setSelectedJobId(_id);
                                //   !isClient() && setJobDetailDialog(true);
                                // }}
                              >
                                <Stack direction='row' alignItems='center'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.includes(index)
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onOpenRow(index);
                                    }}
                                  />
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    gap={0.75}
                                  >
                                    {isUnReadData(data) ? (
                                      <div className={classes.redDot} />
                                    ) : null}
                                    <label
                                      className={classes.name_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {title}
                                    </label>
                                  </Stack>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.includes(index),
                                    userAvailability
                                  ),
                                  cursor: cursor,
                                }}
                                component='td'
                                scope='row'
                                // onClick={() => {
                                //   setSelectedJobId(_id);
                                //   !isClient() && setJobDetailDialog(true);
                                // }}
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label
                                    className={classes.common_cell}
                                    style={{
                                      cursor: cursor,
                                      color:
                                        userAvailability === 'Here' &&
                                        Colors.DARK_GRAY,
                                    }}
                                  >
                                    {property?.name || '-'}
                                  </label>
                                </Stack>
                              </TableCell>
                              {!isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                  // onClick={() => {
                                  //   setSelectedJobId(_id);
                                  //   !isClient() && setJobDetailDialog(true);
                                  // }}
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                    style={{ minWidth: 80 }}
                                  >
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {moment(startDate).format('MMM DD, YYYY')}
                                    </label>
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {moment(startDate).format('hh:mm a')}
                                    </label>
                                  </Stack>
                                </TableCell>
                              )}
                              {!isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                    style={{ minWidth: 80 }}
                                  >
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {getDuration(startDate, endDate)}
                                    </label>
                                  </Stack>
                                </TableCell>
                              )}

                              {!isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                  // onClick={() => {
                                  //   setSelectedJobId(_id);
                                  //   !isClient() && setJobDetailDialog(true);
                                  // }}
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                  >
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {jobForm &&
                                        jobForm.formName &&
                                        jobForm.formName}
                                    </label>
                                  </Stack>
                                </TableCell>
                              )}
                              {!isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                  >
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {assignedMember?.name}
                                    </label>
                                    {/* <AvatarGroup
                                      sx={{
                                        '& .MuiAvatar-root': {
                                          width: 24,
                                          height: 24,
                                          fontFamily: "'Open Sans', sans-serif",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          border: `1px solid ${theme.palette.background_button_text}`,
                                          color: theme.palette.profile_title,
                                          backgroundColor:
                                            theme.palette.avatar_background,
                                        },
                                      }}
                                      spacing={5}
                                      max={3}
                                    >
                                      <Avatar
                                        className={classNames(
                                          classes.common_cell
                                        )}
                                        alt={assignedMember?.name}
                                        src=''
                                        style={{
                                          backgroundColor:
                                            theme.palette.avatar_background,
                                          padding: 2,
                                        }}
                                      >
                                        {getFirstTwoLetter(
                                          assignedMember?.name
                                        )}
                                      </Avatar>
                                    </AvatarGroup> */}
                                  </Stack>
                                </TableCell>
                              )}
                              {isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                  >
                                    <AvatarGroup
                                      sx={{
                                        '& .MuiAvatar-root': {
                                          width: 32,
                                          height: 32,
                                          fontFamily: "'Open Sans', sans-serif",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          border: `1px solid ${theme.palette.background_button_text}`,
                                          color: theme.palette.profile_title,
                                          backgroundColor:
                                            theme.palette.avatar_background,
                                        },
                                      }}
                                      spacing={5}
                                      max={3}
                                    >
                                      {assignedMember?.map((i, ind) => (
                                        <Avatar
                                          className={classNames(
                                            classes.common_cell
                                          )}
                                          alt={i.name}
                                          src=''
                                          style={{
                                            color:
                                              userAvailability === 'Here' &&
                                              Colors.DARK_GRAY,
                                            backgroundColor:
                                              theme.palette.avatar_background,
                                          }}
                                          key={i.name + ind}
                                        >
                                          {assignedInitial(i.name)}
                                        </Avatar>
                                      ))}
                                    </AvatarGroup>
                                  </Stack>
                                </TableCell>
                              )}

                              {isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                  >
                                    <label
                                      className={classes.common_cell}
                                      style={{
                                        cursor: cursor,
                                        color:
                                          userAvailability === 'Here' &&
                                          Colors.DARK_GRAY,
                                      }}
                                    >
                                      {report?.conductedOn
                                        ? moment(report.conductedOn).format(
                                            'MMM DD, YYYY'
                                          )
                                        : '-'}
                                    </label>
                                  </Stack>
                                </TableCell>
                              )}

                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.includes(index),
                                    userAvailability
                                  ),
                                  cursor: cursor,
                                }}
                                component='td'
                                scope='row'
                                // onClick={() => {
                                //   setSelectedJobId(_id);
                                //   !isClient() && setJobDetailDialog(true);
                                // }}
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label
                                    className={classNames(
                                      classes.common_cell,
                                      classes.status_container
                                    )}
                                    style={{
                                      cursor: cursor,
                                      backgroundColor: statusColor(jobStatus),
                                    }}
                                  >
                                    {jobStatus}
                                  </label>
                                </Stack>
                              </TableCell>

                              {/* {!isClient() && (
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index)
                                      ),
                                      cursor: cursor,
                                    }}
                                    component='td'
                                    scope='row'
                                    // onClick={() => {
                                    //   setSelectedJobId(_id);
                                    //   !isClient() && setJobDetailDialog(true);
                                    // }}
                                  >
                                    <Stack
                                      paddingTop={2.5}
                                      direction='column'
                                      alignItems='flex-start'
                                    >
                                      <label
                                        className={classes.common_cell}
                                        style={{ cursor: cursor }}
                                      >
                                        {client?.lastName || '-'}
                                      </label>
                                    </Stack>
                                  </TableCell>
                                )} */}
                            </Hidden>
                            {isClient() && (
                              <>
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                    cursor: cursor,
                                  }}
                                  component='td'
                                  scope='row'
                                  sx={{
                                    verticalAlign: {
                                      xs: 'bottom',
                                      sm: 'middle',
                                    },
                                  }}
                                  // onClick={() => {
                                  //   setSelectedJobId(_id);
                                  //   !isClient() && setJobDetailDialog(true);
                                  // }}
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                    minWidth='max-content'
                                  >
                                    <Button
                                      text={Strings.VIEW_REPORT}
                                      // containerClassName={
                                      //   classes.report_btn_container
                                      // }
                                      className={classes.report_button}
                                      disabled={!report}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        report &&
                                          navigate('/app/report', {
                                            state: {
                                              jobId: _id,
                                            },
                                          });
                                      }}
                                    />
                                  </Stack>
                                </TableCell>
                                {/* <Hidden smDown>
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index),
                                        userAvailability
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                    align='right'
                                  >
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      justifyContent='end'
                                    >
                                      <CustomizedTooltips
                                        title={Strings.ADD_NOTE}
                                      >
                                        <img
                                          className={classes.action_icons}
                                          src={icons(theme).addNote}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedJobId(_id);
                                            setAddNoteDialog(true);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                    </Stack>
                                  </TableCell>
                                </Hidden>
                                <Hidden smUp>
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index),
                                        userAvailability
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                    align='right'
                                  >
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      justifyContent='end'
                                    >
                                      <CustomizedTooltips
                                        title={Strings.ADD_NOTE}
                                      >
                                        <img
                                          className={classes.action_icons}
                                          src={icons(theme).addNote}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedJobId(_id);
                                            setAddNoteDialog(true);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                    </Stack>
                                  </TableCell>
                                </Hidden> */}
                              </>
                            )}
                            {!isClient() && (
                              <>
                                {/* <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index),
                                      ),
                                      cursor: cursor,
                                    }}
                                    component='td'
                                    scope='row'
                                    // onClick={() => {
                                    //   setSelectedJobId(_id);
                                    //   !isClient() && setJobDetailDialog(true);
                                    // }}
                                  >
                                    <Stack
                                      paddingTop={2.5}
                                      direction='column'
                                      alignItems='flex-start'
                                    >
                                      <label
                                        className={classes.common_cell}
                                        style={{ cursor: cursor }}
                                      >
                                        {ViewByClient
                                          ? `on ${moment(ViewByClient).format(
                                              'DD MMM YYYY',
                                            )}`
                                          : '-'}
                                      </label>
                                    </Stack>
                                  </TableCell> */}
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index),
                                      userAvailability
                                    ),
                                  }}
                                  component='td'
                                  scope='row'
                                  align='right'
                                >
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='end'
                                    style={{ minWidth: 142 }}
                                  >
                                    <CustomizedTooltips
                                      title={Strings.EDIT_JOB}
                                    >
                                      <img
                                        className={classNames(
                                          classes.action_icons
                                          // classes.action_visible
                                        )}
                                        src={icons(theme).editIcon}
                                        alt=''
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setIsEdit(true);
                                          setSelectedJob(data);
                                          setAddJobDialog(true);
                                          dispatch(clearJobData());
                                        }}
                                      />
                                    </CustomizedTooltips>
                                    <CustomizedTooltips
                                      title={Strings.DELETEJOB}
                                    >
                                      <img
                                        className={classNames(
                                          classes.action_icons
                                          // classes.action_visible
                                        )}
                                        style={{ marginLeft: 15 }}
                                        src={icons(theme).deleteIcon}
                                        alt=''
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setSelectedJob(data);
                                          setDeleteJobDialog(true);
                                        }}
                                      />
                                    </CustomizedTooltips>
                                    <CustomizedTooltips
                                      title={report ? Strings.VIEW_REPORT : ''}
                                    >
                                      <img
                                        className={classes.action_icons}
                                        style={{ marginLeft: 15 }}
                                        src={
                                          report && reportStatus
                                            ? icons(theme).chartIcon
                                            : icons(theme).chartIconDisable
                                        }
                                        alt=''
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          report &&
                                            navigate('/app/report', {
                                              state: {
                                                jobId: _id,
                                              },
                                            });
                                        }}
                                      />
                                    </CustomizedTooltips>
                                    <CustomizedTooltips
                                      title={Strings.ADD_INTERNAL_NOTE}
                                    >
                                      <img
                                        className={classes.action_icons}
                                        style={{ marginLeft: 15 }}
                                        src={icons(theme).addNote}
                                        alt=''
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setSelectedJobId(_id);
                                          setAddNoteDialog(true);
                                        }}
                                      />
                                    </CustomizedTooltips>
                                  </Stack>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                          {openRows.includes(index) && (
                            <>
                              {/* {!isClient() && (
                                  <TableRow
                                    key={data._id}
                                    sx={{ width: '100%' }}
                                  >
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(theme, false),
                                      }}
                                      component='td'
                                      scope='row'
                                      colSpan={9}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          marginBottom: 10,
                                          alignItens: 'center',
                                          // justifyContent: 'space-between'
                                        }}
                                      >
                                        <label
                                          className={classes.common_cell}
                                          style={{
                                            cursor: cursor,
                                            width: '25%',
                                            fontFamily:
                                              "'Open Sans', sans-serif",
                                            fontWeight: 600,
                                            fontSize: 12,
                                            color: theme.palette.menu_indicator,
                                          }}
                                        >
                                          {Strings.START_DATE_TIME.toUpperCase()}
                                        </label>
                                        <label
                                          className={classes.common_cell}
                                          style={{
                                            cursor: cursor,
                                            width: '25%',
                                            fontFamily:
                                              "'Open Sans', sans-serif",
                                            fontWeight: 600,
                                            fontSize: 12,
                                            color: theme.palette.menu_indicator,
                                          }}
                                        >
                                          {Strings.END_DATE_TIME.toUpperCase()}
                                        </label>
                                        <label
                                          className={classes.common_cell}
                                          style={{
                                            cursor: cursor,
                                            width: '25%',
                                            fontFamily:
                                              "'Open Sans', sans-serif",
                                            fontWeight: 600,
                                            fontSize: 12,
                                            color: theme.palette.menu_indicator,
                                          }}
                                        >
                                          {'ASSIGNED'}
                                        </label>
                                      </div>

                                      {appointments?.map((item, ind) => {
                                        return (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              marginBottom: 10,
                                              alignItens: 'center',
                                              // justifyContent: 'space-between'
                                            }}
                                            key={item.title + ind}
                                          >
                                            <label
                                              className={classes.common_cell}
                                              style={{
                                                cursor: cursor,
                                                width: '25%',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {moment(item.startDate).format(
                                                ' MMM D, YYYY hh:mm a'
                                              )}
                                            </label>

                                            <label
                                              className={classes.common_cell}
                                              style={{
                                                cursor: cursor,
                                                width: '25%',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {moment(item.endDate).format(
                                                'MMM D, YYYY hh:mm a'
                                              )}
                                            </label>

                                            <AvatarGroup
                                              sx={{
                                                '& .MuiAvatar-root': {
                                                  width: 32,
                                                  height: 32,
                                                  fontFamily:
                                                    "'Open Sans', sans-serif",
                                                  fontSize: 14,
                                                  fontWeight: 400,
                                                  border: `1px solid ${theme.palette.background_button_text}`,
                                                  color:
                                                    theme.palette.profile_title,
                                                  backgroundColor:
                                                    theme.palette
                                                      .avatar_background,
                                                },
                                              }}
                                              spacing={5}
                                              max={3}
                                            >
                                              {item.assignedMember.map(
                                                (i, ind) => (
                                                  <Avatar
                                                    className={classNames(
                                                      classes.common_cell
                                                    )}
                                                    alt={i.name}
                                                    // src={item.profilePicture}
                                                    src=''
                                                    style={{
                                                      backgroundColor:
                                                        theme.palette
                                                          .avatar_background,
                                                    }}
                                                    key={i.name + ind}
                                                  >
                                                    {assignedInitial(i.name)}
                                                  </Avatar>
                                                )
                                              )}
                                            </AvatarGroup>
                                          </div>
                                        );
                                      })}
                                    </TableCell>
                                  </TableRow>
                                )} */}

                              <TableRow key={data._id} sx={{ width: '100%' }}>
                                <TableCell
                                  style={{
                                    ...secondaryTableCell(
                                      theme,
                                      true,
                                      userAvailability
                                    ),
                                  }}
                                  component='td'
                                  scope='row'
                                  colSpan={9}
                                >
                                  <Stack flexDirection='column'>
                                    <Tabs
                                      underlineTabs
                                      // noDataLength
                                      containerClassName={
                                        classes.job_details_container
                                      }
                                      tabClassName={classes.job_details_tab}
                                      dataList={getJobTabsList(data)}
                                      activeTab={
                                        detailsActiveTab
                                          ? detailsActiveTab[index]?.key
                                          : 'details'
                                      }
                                      notesNotification={
                                        detailsActiveTab
                                          ? detailsActiveTab[index]
                                              ?.notesNotification
                                          : false
                                      }
                                      internalNotesNotification={
                                        detailsActiveTab
                                          ? detailsActiveTab[index]
                                              ?.internalNotesNotification
                                          : false
                                      }
                                      onChange={(value) => {
                                        let newTab = [...detailsActiveTab];
                                        const data = {
                                          type: value,
                                          jobId: _id,
                                        };
                                        if (
                                          !isClient() &&
                                          ((value === 'notes' &&
                                            newTab[index].notesNotification) ||
                                            (value === 'internalNotes' &&
                                              newTab[index]
                                                .internalNotesNotification))
                                        ) {
                                          setSelectedJobId(_id);
                                          props.markAllJobsNotesRead(
                                            data,
                                            onError,
                                            manageJobsListData
                                          );
                                        }
                                        newTab[index].key = value;
                                        if (value === 'notes')
                                          newTab[
                                            index
                                          ].notesNotification = false;
                                        if (value === 'internalNotes')
                                          newTab[
                                            index
                                          ].internalNotesNotification = false;
                                        setDetailsActiveTab(newTab);
                                      }}
                                      tabIndex={index}
                                    />
                                    {detailsActiveTab[index]?.key ===
                                      'details' && (
                                      <>
                                        {details && (
                                          <NotesDataList
                                            data={{ note: details }}
                                          />
                                        )}
                                        {createdBy && createdBy.name && (
                                          <NotesDataList
                                            data={`${Strings.REPORTED_BY} ${createdBy?.name}`}
                                            style={{ marginTop: 15 }}
                                          />
                                        )}
                                      </>
                                    )}
                                    {detailsActiveTab[index]?.key ===
                                      'notes' && (
                                      <>
                                        {jobNotes?.length > 0 &&
                                          sortData(jobNotes)
                                            ?.filter(
                                              (item) => !item.internalNotes
                                            )
                                            .map((item, i) => (
                                              <NotesDataList
                                                isEditDelete
                                                data={item}
                                                isBottomBorder={
                                                  jobNotes?.length !== i + 1
                                                }
                                                onEditClick={() => {
                                                  setAddNoteDialog(true);
                                                  setIsEditNote(true);
                                                  setSelectedNoteData({
                                                    jobId: _id,
                                                    noteId: item?._id,
                                                    note: item?.note,
                                                    internalNotes:
                                                      item?.internalNotes,
                                                  });
                                                  setSelectedJobNoteId(
                                                    item?._id
                                                  );
                                                  setSelectedJobId(_id);
                                                }}
                                                deleteLoader={
                                                  selectedJobNoteId ===
                                                    item?._id &&
                                                  props?.deleteJobNoteLoading
                                                }
                                                onDeleteClick={() => {
                                                  setSelectedJobNoteId(
                                                    item?._id
                                                  );
                                                  onDeleteNoteClick(
                                                    _id,
                                                    item?._id
                                                  );
                                                }}
                                              />
                                            ))}
                                      </>
                                    )}
                                    {detailsActiveTab[index]?.key ===
                                      'internalNotes' && (
                                      <>
                                        {sortData(jobNotes)
                                          ?.filter((item) => item.internalNotes)
                                          .map((item, index) => (
                                            <NotesDataList
                                              isEditDelete
                                              data={item}
                                              isBottomBorder={
                                                jobNotes?.length !== index + 1
                                              }
                                              onEditClick={() => {
                                                setAddNoteDialog(true);
                                                setIsEditNote(true);
                                                setSelectedNoteData({
                                                  jobId: _id,
                                                  noteId: item?._id,
                                                  note: item?.note,
                                                  internalNotes:
                                                    item?.internalNotes,
                                                });
                                                setSelectedJobNoteId(item?._id);
                                                setSelectedJobId(_id);
                                              }}
                                              deleteLoader={
                                                selectedJobNoteId ===
                                                  item?._id &&
                                                props?.deleteJobNoteLoading
                                              }
                                              onDeleteClick={() => {
                                                setSelectedJobNoteId(item?._id);
                                                onDeleteNoteClick(
                                                  _id,
                                                  item?._id
                                                );
                                              }}
                                            />
                                          ))}
                                      </>
                                    )}
                                    {detailsActiveTab[index]?.key ===
                                      'media' && (
                                      <>
                                        {attachments &&
                                          attachments.length > 0 && (
                                            <div>
                                              <MediaImageContainer
                                                imageArray={attachments?.map(
                                                  (item) => {
                                                    return {
                                                      ...item,
                                                      title: item.fileName,
                                                    };
                                                  }
                                                )}
                                                itemsPerRow={
                                                  mobileView
                                                    ? 2
                                                    : tabletView
                                                    ? 3
                                                    : laptopView
                                                    ? 4
                                                    : 5
                                                }
                                                showImageText
                                                showTitleOnly
                                              />
                                            </div>
                                          )}
                                      </>
                                    )}

                                    {/* {detailsActiveTab[index] ===
                                        'details' && (
                                        <>
                                          <NotesDataList data={description} />
                                          {images && images.length > 0 && (
                                            <div>
                                              <MediaImageContainer
                                                imageArray={images}
                                                // imageData={props?.imageData}
                                                itemsPerRow={
                                                  mobileView
                                                    ? 2
                                                    : tabletView
                                                    ? 3
                                                    : laptopView
                                                    ? 4
                                                    : 5
                                                }
                                              />
                                            </div>
                                          )}
                                          {createdBy && createdBy.name && (
                                            <NotesDataList
                                              data={`${Strings.REPORTED_BY} ${createdBy?.name}`}
                                              style={{ marginTop: 15 }}
                                            />
                                          )}
                                        </>
                                      )} */}
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              // </TableContainer>
            )}
          </>
        )}
      </div>
      <Stack flex={1} pb={2} alignItems='center'>
        {props?.jobListData?.jobs?.length ? (
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.jobListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSizeChange}
          />
        ) : null}
      </Stack>

      {addJobDialog && !isClient() && (
        <NewJob
          isEdit={isEdit}
          data={selectedJob}
          onNewClose={() => {
            setIsEdit(false);
            setAddJobDialog(false);
            setSelectedJob(null);
          }}
        />
      )}
      {jobDetailDialog && !isClient() && (
        <JobDetails
          onNewClose={() => setJobDetailDialog(false)}
          fetchJobId={selectedJobId}
        />
      )}
      {jobFilterDialog && !isClient() && (
        <JobFilter
          setFilterData={setFilterData}
          filterData={filter}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setJobFilterDialog(false)}
          // isActiveTabToday={activeTab === 'Today'}
        />
      )}
      {/* {sendReportDialog && !isClient() && (
        <SendReport
          selectedJobId={selectedJobId}
          onNewClose={() => setSendReportDialog(false)}
          client={selectedJobClient}
          isReviewReport={true}
        />
      )} */}
      {deleteJobDialog && !isClient() && (
        <CommonDialog
          title={Strings.JOB}
          message={Strings.DELETE_JOB}
          onSuccess={() => onDeleteJob()}
          onClose={() => setDeleteJobDialog(false)}
          loading={props.deleteJobLoading}
        />
      )}
      {addNoteDialog && (
        <AddNotes
          isJob
          selectedId={selectedJobId}
          onNewClose={() => {
            setAddNoteDialog(false);
            setIsEditNote(false);
            setSelectedNoteData(null);
            setSelectedJobNoteId(null);
          }}
          isEditJobNote={isEditNote}
          selectedNoteData={selectedNoteData}
        />
      )}
      {openEditFilterDialog?.show && (
        <JobNewFilter
          isEdit={true}
          // isActiveTabToday={activeTab === 'Today'}
          selectedId={openEditFilterDialog?.value}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setOpenEditFilterDialog(false)}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  jobListData: state.jobListData.data,
  jobListLoading: state.jobListData.loading,

  addJobData: state.addJobData.data,

  deleteJobLoading: state.deleteJobData.loading,
  deleteJobData: state.deleteJobData.data,

  updateJobData: state.updateJobData.data,

  updateRecurrenceData: state.updateRecurrence.data,
  cancelRecurrenceData: state.cancelRecurrence.data,

  sendReportData: state.sendReportData.data,

  addJobNoteData: state.addJobNoteData.data,

  updateJobNoteData: state.updateJobNoteData.data,

  deleteJobNoteLoading: state.deleteJobNoteData.loading,

  listFilterData: state.listFilterData.data,
  listFilterLoading: state.listFilterData.loading,

  addFilterData: state.addFilterData.data,
  updateFilterData: state.updateFilterData.data,
  deleteFilterData: state.deleteFilterData.data,

  updateFileDetailsData: state.updateFileDetailsData.data,
});

export default connect(mapStateToProps, {
  fetchJobList,
  deleteJob,
  updateRecurrence,
  cancelRecurrence,
  deleteJobNote,
  listFilter,
  markAllJobsNotesRead,
})(Jobs);
