import axios from 'axios';
import { CLIENT_GET_ADMIN } from '../../constants';

export const fetchClientAdmin = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUser());
  axios
    .get('/client/getUser', config)
    .then((res) => dispatch(setUser(res)))
    .catch((err) => {
      dispatch(errorUser(err.response));
      onError(err.response);
    });
};

export const loadUser = () => ({
  type: CLIENT_GET_ADMIN.LOAD,
});

export const setUser = (userData) => ({
  type: CLIENT_GET_ADMIN.SUCCESS,
  payload: userData.data.data,
});

export const errorUser = (error) => ({
  type: CLIENT_GET_ADMIN.FAIL,
  payload: error,
});
