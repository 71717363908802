import React, { useEffect, useState } from 'react';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { Avatar, AvatarGroup, Stack } from '@mui/material';
import classNames from 'classnames';
import RecurringModal from './RecurringModal';
import { connect, useDispatch } from 'react-redux';
import {
  clearJobData,
  fetchJobById,
} from '../../../redux/actions/jobActions/jobActions/getJobById';
import { updateRecurrence } from '../../../redux/actions/jobActions/jobActions/updateRecurrence';
import { cancelRecurrence } from '../../../redux/actions/jobActions/jobActions/cancelRecurrence';
import {
  getErrorMessage,
  isClient,
  manageNotesHref,
} from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import moment from 'moment';
import { icons } from '../../../utils/icons';
import { useAlert } from 'react-alert';
import Attachment from '../../../components/Attachment';
import MediaDetails from '../../Media/MediaDetails';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CommonContainer = (props) => {
  const {
    containerClassName,
    containerOnClick,
    icon,
    iconClassName,
    profile,
    text,
    textClassName,
    textOnClick,
    textStyle,
    children,
  } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.common_container,
        containerClassName && containerClassName
      )}
      onClick={containerOnClick}
    >
      {icon &&
        (props?.isPropertyImg ? (
          <LazyLoadImage
            effect='opacity'
            placeholderSrc={icons().loading}
            src={icon}
            className={iconClassName}
            alt=''
            wrapperClassName={`
              ${props?.isPropertyImg ? '100%' : classes.custom_wrapper} 
              ${classes.backGround_image}`}
          />
        ) : (
          <img src={icon} className={iconClassName && iconClassName} alt='' />
        ))}
      {profile && <Avatar src={profile} alt='' />}
      {text && (
        <span
          className={classNames(
            classes.text_common,
            textClassName && textClassName
          )}
          onClick={textOnClick && textOnClick}
          style={textStyle && textStyle}
        >
          {text}
        </span>
      )}
      {children && children}
    </div>
  );
};

const SectionContainer = (props) => {
  const {
    containerClassName,
    sectionHead,
    profile,
    profileName,
    profileArray,
    text,
    children,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (text) {
      manageNotesHref('jobNoteText');
    }
  }, [text]);

  return (
    <div
      className={classNames(
        classes.section_container,
        containerClassName && containerClassName
      )}
    >
      {sectionHead && (
        <span className={classes.text_section_header}>{sectionHead}</span>
      )}
      {profile || profileName ? (
        <div className={classes.common_container}>
          <Avatar src={profile} alt='' style={{ height: 32, width: 32 }} />
          <span className={classes.text_common}>{profileName}</span>
        </div>
      ) : profileArray && profileArray.length > 0 ? (
        <div className={classes.assigned_to_container}>
          {profileArray.map((data, index) => (
            <div className={classes.common_container} key={index}>
              <Avatar
                src={data.profilePicture}
                alt=''
                style={{ height: 32, width: 32 }}
              />
              <span className={classes.text_common}>{data.name}</span>
            </div>
          ))}
        </div>
      ) : null}
      {text && (
        <span
          id='jobNoteText'
          className={classes.text_common}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {children && children}
    </div>
  );
};

const JobDetails = (props) => {
  const { fetchJobId, isJobSeriesPage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [recurringData, setRecurringData] = useState({});
  const [showImageDetailsDialog, setShowImageDetailsDialog] = useState(false);
  const [fileDetails, setFileDetails] = useState('');
  const navigate = useNavigate();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchJobById(fetchJobId, (err) => onError(err));
  }, [fetchJobId, props.updateRecurrenceData, props.cancelRecurrenceData]);

  useEffect(() => {
    const data = props.jobData || {};
    if (data?.recurring) {
      const newData = {
        // frequency: data?.frequency,
        count: data?.repeat?.count,
        timePeriod: data?.repeat?.timePeriod,
        startDate: data?.recurringStartDate,
        endDate: data?.recurringEndDate,
      };
      setRecurringData(newData);
    }
    setJobDetails(data);
  }, [props.jobData]);

  const openGoogleMap = (address) => {
    window.open(`https://www.google.com/maps/search/${address}`);
  };

  const assignedInitial = (assignedName) => {
    if (assignedName) {
      const initial =
        assignedName?.split(' ')?.length > 1
          ? assignedName.split(' ')[0]?.charAt(0)?.toUpperCase() +
            assignedName.split(' ')[1]?.charAt(0)?.toUpperCase()
          : assignedName.split(' ')[0]?.charAt(0)?.toUpperCase();
      return initial;
    } else return '';
  };

  return (
    <>
      <div className={classes.dialog_mask} />
      <Stack className={classes.dialog_container}>
        {props.jobDataLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            <NewFormHeader
              title={jobDetails?.title}
              onNewClose={() => {
                dispatch(clearJobData());
                props.onNewClose();
              }}
            />
            <div className={classes.job_detail_container}>
              <CommonContainer
                icon={jobDetails?.recurring && icons(theme).recurringIcon}
                text={jobDetails?.recurring && Strings.RECURRING + ` Job`}
                iconClassName={classes.icon_small}
                containerClassName={classes.margin_bottom_15}
                textStyle={{ cursor: 'pointer' }}
                // textOnClick={() => setShowRecurringModal(!showRecurringModal)}
              >
                {/* <span className={classes.job_type_container}>
                  {jobDetails?.jobType?.categoryName}
                </span> */}
                <span className={classes.job_status_container}>
                  {jobDetails?.jobStatus}
                </span>
              </CommonContainer>
              <CommonContainer
                icon={icons(theme).client}
                iconClassName={classes.icons}
                text={jobDetails?.property?.name}
                textClassName={classes.text_light_blue}
                containerClassName={classes.margin_bottom_15}
                textStyle={{ cursor: 'pointer' }}
                textOnClick={() => {
                  navigate('/app/clients/view/property', {
                    state: {
                      programType: jobDetails?.client?.programType,
                      properties: jobDetails?.client?.properties,
                      selectedProperty: jobDetails?.property?._id,
                    },
                  });
                }}
              />
              <CommonContainer
                isPropertyImg
                icon={jobDetails?.property?.image?.url}
                iconClassName={classes.house_image}
                containerClassName={classes.margin_bottom_15}
              />
              <CommonContainer
                icon={icons(theme).location}
                iconClassName={classes.icons}
                text={jobDetails?.property?.address}
                textClassName={classes.text_light_blue}
                containerClassName={classes.address_container}
                containerOnClick={() =>
                  openGoogleMap(jobDetails?.property?.address)
                }
              />
              <SectionContainer
                sectionHead={Strings.APPOINTMENTS.toUpperCase()}
                containerClassName={classes.summary_data}
              >
                <div
                  className={classes.job_detail_container}
                  style={{ gap: 10 }}
                >
                  {jobDetails?.appointments?.map((el, index) => {
                    const fromDate = moment(el.startDate).format(
                      'MMM DD, YYYY'
                    );
                    const fromTime = moment(el.startDate).format('hh:mm a');
                    const toDate = moment(el.endDate).format('MMM DD, YYYY');
                    const toTime = moment(el.endDate).format('hh:mm a');
                    return (
                      <div
                        key={index}
                        className={classes.appointment_container}
                      >
                        <div style={{ display: 'flex' }}>
                          <AvatarGroup
                            sx={{
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                fontFamily: "'Open Sans', sans-serif",
                                fontSize: 14,
                                fontWeight: 400,
                                border: `1px solid ${theme.palette.background_button}`,
                                backgroundColor:
                                  theme.palette.dialog_background,
                                color: theme.palette.background_button,
                              },
                            }}
                            spacing={5}
                            max={5}
                          >
                            {el?.assignedMember?.map((item, ind) => (
                              <Avatar
                                key={ind}
                                className={classes.common_cell}
                                alt={item?.name}
                                src=''
                                // src={item?.profilePicture}
                                style={{
                                  backgroundColor:
                                    theme.palette.dialog_background,
                                }}
                              >
                                {assignedInitial(item?.name)}
                              </Avatar>
                            ))}
                          </AvatarGroup>
                        </div>
                        <CommonContainer
                          icon={icons(theme).calender}
                          iconClassName={classes.icons}
                        >
                          <div
                            className={classes.assigned_to_container}
                            style={{ gap: 0 }}
                          >
                            <span className={classes.text_common}>
                              From
                              <span className={classes.text_light_blue}>
                                {` ${fromDate} at ${fromTime} `}
                              </span>
                            </span>
                            <span className={classes.text_common}>
                              To
                              <span className={classes.text_light_blue}>
                                {` ${toDate} at ${toTime}`}
                              </span>
                            </span>
                          </div>
                        </CommonContainer>
                      </div>
                    );
                  })}
                </div>
              </SectionContainer>
              <SectionContainer
                sectionHead={Strings.ADMIN.toUpperCase()}
                profile={jobDetails?.admin?.profilePicture}
                profileName={jobDetails?.admin?.name}
              />
              {/* <SectionContainer
                sectionHead={Strings.ASSIGNED_TO.toUpperCase()}
                profileArray={jobDetails?.assignedMember}
              /> */}
              <SectionContainer
                sectionHead={Strings.JOB_FORM.toUpperCase()}
                text={jobDetails?.jobForm?.formName}
              />
              <SectionContainer
                sectionHead={Strings.JOB_SUMMARY.toUpperCase()}
                text={jobDetails?.details}
              />
              {/* <SectionContainer
                sectionHead={Strings.SUPPLIES_NEEDED.toUpperCase()}
                text={jobDetails?.suppliesNeeded}
              /> */}
              <SectionContainer
                sectionHead={Strings.REPORT_HISTORY.toUpperCase()}
                containerClassName={classes.summary_data}
              >
                {jobDetails?.history?.map((el, ind) => (
                  <>
                    <div
                      className={classes.reportHistory_container}
                      style={{
                        marginTop: ind === 0 && 15,
                        marginBottom:
                          el?.details?.reportSentTo?.length === 0 && 15,
                      }}
                    >
                      <span
                        className={classNames(
                          classes.reportHistory_field_container,
                          classes.text_common
                        )}
                      >
                        {el?.action}
                      </span>
                      <span
                        className={classNames(
                          classes.reportHistory_value_container,
                          classes.text_common
                        )}
                      >
                        {el?.createdAt
                          ? moment(el?.createdAt)?.format(
                              'MMM DD, YYYY hh:mm a'
                            )
                          : '-'}
                      </span>
                    </div>

                    {el?.details?.reportSentTo?.length > 0 && (
                      <>
                        <span
                          className={classes.text_section_header}
                          style={{ marginTop: 10 }}
                        >
                          {'REPORT SENT TO'}
                        </span>
                        <div className={classes.email_list}>
                          {/* {Array(5).fill('jemish@yopmail.com').join(', ')} */}
                          {el?.details?.reportSentTo.join(', ')}
                        </div>
                      </>
                    )}
                  </>
                ))}
              </SectionContainer>
              <Attachment
                showPreviewIcon
                hideDeleteIcon
                title='ATTACHMENTS'
                titleClassName={classes.text_section_header}
                attachments={jobDetails?.attachments || []}
                onClickPreview={(item) => {
                  setFileDetails(item);
                  setShowImageDetailsDialog(true);
                }}
              />
            </div>
            {(jobDetails?.recurring ||
              jobDetails?.jobType === 'reoccurring' ||
              jobDetails?.report) &&
            !isClient() ? (
              <Stack
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-end'
                gap={2.5}
                pt={2}
              >
                {jobDetails?.report && (
                  <Button
                    text={Strings.VIEW_REPORT}
                    className={classes.add_new_button}
                    onClick={() =>
                      navigate(`/app/report`, {
                        state: { jobId: fetchJobId },
                      })
                    }
                  />
                )}
                {!isJobSeriesPage &&
                  (jobDetails?.recurring ||
                    jobDetails?.jobType === 'reoccurring') && (
                    <Button
                      text={Strings.VIEW_JOB_SERIES}
                      className={classes.add_new_button}
                      onClick={() =>
                        navigate(`/app/jobs/job-series`, {
                          state: { jobId: fetchJobId },
                        })
                      }
                    />
                  )}
              </Stack>
            ) : null}
          </>
        )}
        {showRecurringModal && (
          <RecurringModal
            onClose={() => setShowRecurringModal(false)}
            jobId={fetchJobId}
            data={recurringData}
          />
        )}
      </Stack>
      {showImageDetailsDialog && (
        <MediaDetails
          fileId={fileDetails?._id}
          onNewClose={() => {
            setShowImageDetailsDialog(false);
            setFileDetails('');
          }}
          isAddTagsRequired={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  jobData: state.jobData.data,
  jobDataLoading: state.jobData.loading,

  updateRecurrenceData: state.updateRecurrence.data,
  cancelRecurrenceData: state.cancelRecurrence.data,
});

export default connect(mapStateToProps, {
  fetchJobById,
  updateRecurrence,
  cancelRecurrence,
})(JobDetails);
