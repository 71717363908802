import axios from 'axios';
import { LIST_FILTER } from '../../constants';

export const listFilter = (type, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadListFilter());
  axios
    .get(`/user/filters?type=${type}`, config)
    .then((res) => {
      dispatch(setListFilter(res.data.data));
    })
    .catch((err) => {
      dispatch(errorListFilter(err.response));
      onError(err.response);
    });
};

export const loadListFilter = () => ({
  type: LIST_FILTER.LOAD,
});

export const setListFilter = (data) => ({
  type: LIST_FILTER.SUCCESS,
  payload: data,
});

export const errorListFilter = (error) => ({
  type: LIST_FILTER.FAIL,
  payload: error,
});
