import axios from 'axios';
import { DELETE_PROPERTY } from '../../constants';

export const deleteProperty = (pID, closeDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteProperty());
  axios
    .put(`/client/deleteProperty?propertyId=${pID}`, {}, config)
    .then((res) => {
      dispatch(setDeleteProperty(res));
      closeDialog();
    })
    .catch((err) => {
      dispatch(errorDeleteProperty(err.response));
      onError(err.response);
    });
};

export const loadDeleteProperty = () => ({
  type: DELETE_PROPERTY.LOAD,
});

export const setDeleteProperty = (data) => ({
  type: DELETE_PROPERTY.SUCCESS,
  payload: data,
});

export const errorDeleteProperty = (error) => ({
  type: DELETE_PROPERTY.FAIL,
  payload: error,
});
