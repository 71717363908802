import axios from 'axios';
import { DROPDOWN_PROPERTY_LIST } from '../../constants';

export const fetchDropdownPropertyList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDropdownPropertyList());
  axios
    .get('/property/dropdown', config)
    .then((res) => dispatch(setDropdownPropertyList(res.data.data)))
    .catch((err) => {
      dispatch(errorDropdownPropertyList(err.response));
      onError(err.response);
    });
};

export const loadDropdownPropertyList = () => ({
  type: DROPDOWN_PROPERTY_LIST.LOAD,
});

export const setDropdownPropertyList = (data) => ({
  type: DROPDOWN_PROPERTY_LIST.SUCCESS,
  payload: data,
});

export const errorDropdownPropertyList = (error) => ({
  type: DROPDOWN_PROPERTY_LIST.FAIL,
  payload: error,
});
