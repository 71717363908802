import { GET_CURRENT_WEATHER } from '../../constants';
import { weatherAxios } from '../../../utils/appUtils';

export const getCurrentWeather = (coords, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const key = '2e877edcead39c72b4140144c4a01a9c';
  dispatch(loadGetCurrentWeather());
  const config = {
    params: { lat: '26.242099', lon: '-81.762694', appid: key, units: 'imperial' },
  };
  weatherAxios
    .get(`weather`, config)
    .then((res) => {
      dispatch(setGetCurrentWeather(res));
    })
    .catch((err) => {
      dispatch(errorGetCurrentWeather(err.response));
      onError(err.response);
    });
};

export const loadGetCurrentWeather = () => ({
  type: GET_CURRENT_WEATHER.LOAD,
});

export const setGetCurrentWeather = (data) => ({
  type: GET_CURRENT_WEATHER.SUCCESS,
  payload: data.data,
});

export const errorGetCurrentWeather = (error) => ({
  type: GET_CURRENT_WEATHER.FAIL,
  payload: error,
});
