import axios from 'axios';
import { UPDATE_FILTER } from '../../constants';

export const updateFilter = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateFilter());
  axios
    .put(`/user/filter`, data, config)
    .then((res) => {
      dispatch(setUpdateFilter(res));
      onNewClose();
    })
    .catch((err) => {
      dispatch(errorUpdateFilter(err.response));
      onError(err.response);
    });
};

export const loadUpdateFilter = () => ({
  type: UPDATE_FILTER.LOAD,
});

export const setUpdateFilter = (data) => ({
  type: UPDATE_FILTER.SUCCESS,
  payload: data.data,
});

export const errorUpdateFilter = (error) => ({
  type: UPDATE_FILTER.FAIL,
  payload: error,
});
