import axios from 'axios';
import { UPDATE_CATEGORY } from '../../../constants';

export const updateCategory =
  (cId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateCategory());
    axios
      .put(`jobs/updateCategory?categoryId=${cId}`, data, config)
      .then((res) => {
        dispatch(setUpdateCategory(res));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateCategory(err.response));
        onError(err.response);
      });
  };

export const loadUpdateCategory = () => ({
  type: UPDATE_CATEGORY.LOAD,
});

export const setUpdateCategory = (data) => ({
  type: UPDATE_CATEGORY.SUCCESS,
  payload: data.data,
});

export const errorUpdateCategory = (error) => ({
  type: UPDATE_CATEGORY.FAIL,
  payload: error,
});
