import axios from 'axios';
import { GET_FILTER_BY_ID } from '../../constants';

export const fetchFilterById = (fId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadFilterById());

  axios
    .get(`/user/filter/${fId}`, config)
    .then((res) => dispatch(setFilterById(res.data.data)))
    .catch((err) => {
      dispatch(errorFilterById(err.response));
      onError(err.response);
    });
};

export const loadFilterById = () => ({
  type: GET_FILTER_BY_ID.LOAD,
});

export const setFilterById = (clientList) => ({
  type: GET_FILTER_BY_ID.SUCCESS,
  payload: clientList,
});

export const errorFilterById = (error) => ({
  type: GET_FILTER_BY_ID.FAIL,
  payload: error,
});
