import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import NewFormHeader from '../../../../components/NewFormHeader';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../../redux/actions/adminActions/getUserListByType';
import {
  getErrorMessage,
  hereAwayDropdown,
  isAdmin,
  isSuperAdmin,
} from '../../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { fetchProgramList } from '../../../../redux/actions/clientActions/programTypeList';
import FormSwitch from '../../../../components/Switch';
import { useAlert } from 'react-alert';
import MultiSelectDropdown from '../../../../components/MultiSelectDropdown';
import Textbox from '../../../../components/Textbox';
import { createFilter } from '../../../../redux/actions/filterActions/addFilter';
import { fetchFilterById } from '../../../../redux/actions/filterActions/getFilterById';
import { updateFilter } from '../../../../redux/actions/filterActions/updateFilter';
import { deleteFilter } from '../../../../redux/actions/filterActions/deleteFilter';
import { Colors } from '../../../../utils/color';
import Dropdown from '../../../../components/Dropdown';

const NewClientFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [adminList, setAdminList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    // not needed here as we already get all list data in previous filter dialog - but we needed in edit case
    if (props.isEdit) {
      props.fetchProgramList((err) => onError(err));
      if (isSuperAdmin())
        props.fetchUserListByType('admin', (err) => onError(err));
    }
  }, []);

  useEffect(() => {
    const data = props.programListData ? props.programListData : [];
    const newData = data.map((item) => ({
      title: item.title,
      value: item._id,
    }));
    setProgramList(newData);
  }, [props.programListData]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title: item.name,
          value: item._id,
          image: item?.profilePicture,
        };
        return tempData;
      }) || [];
    if (data?.type === 'admin') setAdminList(newData);
  }, [props.UserListByTypeData]);

  useEffect(() => {
    if (props.isEdit && props.selectedId) {
      props.fetchFilterById(props?.selectedId, onError);
    }
  }, [props.isEdit, props.selectedId]);

  useEffect(() => {
    if (props?.isEdit && props?.getFilterData) {
      let data = props.getFilterData.filter;
      const newProgramType = programList?.filter((item) =>
        data?.programType?.some((el) => el === item?.value)
      );
      const newAdmins = adminList?.filter((item) =>
        data?.adminAssigned?.some((el) => el === item?.value)
      );
      const newHereAwayData = hereAwayDropdown.filter(
        (el) => el.value === data?.userAvailability
      );
      setFieldValue('filterName', props.getFilterData?.filterName || '');
      setFieldValue('programType', newProgramType || []);
      setFieldValue('adminAssigned', newAdmins || []);
      setFieldValue('issues', data?.issues || false);
      setFieldValue('showArchived', data?.showArchived || false);
      setFieldValue('showAllClient', data?.showAllClient || false);
      setFieldValue(
        'userAvailability',
        (newHereAwayData && newHereAwayData[0]?.value) || '0'
      );
    }
  }, [props.getFilterData, programList, adminList]);

  useEffect(() => {
    if (props?.selectedValues) {
      const newProgramType = programList?.filter((item) =>
        props?.selectedValues?.programType?.some(
          (el) => el?.value === item?.value
        )
      );
      const newAdmins = adminList?.filter((item) =>
        props?.selectedValues?.adminAssigned?.some(
          (el) => el?.value === item?.value
        )
      );
      const newHereAwayData = hereAwayDropdown.filter(
        (el) => el.value === props?.selectedValues?.userAvailability
      );
      setFieldValue('programType', newProgramType);
      setFieldValue('adminAssigned', newAdmins);
      setFieldValue('issues', props?.selectedValues?.issues);
      setFieldValue(
        'showArchived',
        props?.selectedValues?.showArchived || false
      );
      setFieldValue('showAllClient', props?.selectedValues?.showAllClient);
      setFieldValue('userAvailability', newHereAwayData[0]?.value || '0');
    }
  }, [props?.selectedValues, programList, adminList]);

  const formik = useFormik({
    initialValues: {
      filterName: '',
      programType: [],
      adminAssigned: [],
      issues: false,
      showArchived: false,
      showAllClient: false,
      userAvailability: '0',
    },
    validationSchema: Yup.object().shape({
      filterName: Yup.string().required('Filtername is required.'),
    }),

    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = (values) => {
    const data = {
      type: 'CLIENT_LIST',
      filterName: values.filterName,
    };
    let filter = {};
    filter.issues = values.issues;
    filter.showArchived = values.showArchived;
    if (values.programType?.length > 0)
      filter.programType = values.programType?.map((el) => el.value);
    if (isAdmin()) filter.showAllClient = values.showAllClient;
    if (values.adminAssigned?.length > 0)
      filter.adminAssigned = values.adminAssigned?.map((el) => el.value);
    if (values.userAvailability !== '0')
      filter.userAvailability = values.userAvailability;
    if (Object.keys(filter).length) data.filter = filter;

    if (props?.isEdit) {
      data.filterId = props?.selectedId;
      props.updateFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    } else {
      props.createFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props?.isEdit ? Strings.EDIT_FILTER : Strings.NEW_FILTER}
          onNewClose={props.onClose}
        />
        {props.UserListByTypeLoading || props.programListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='filterName'
                  onChange={handleChange('filterName')}
                  value={values.filterName}
                  label={Strings.FILTER_NAME}
                  error={Boolean(touched.filterName && errors.filterName)}
                  helperText={touched.filterName && errors.filterName}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <label className={classes.filter_attribute_text}>
                  {Strings.FILTER_ATTRIBUTES.toUpperCase()}
                </label>
                <MultiSelectDropdown
                  name='programType'
                  onChange={(value) => setFieldValue('programType', value)}
                  value={values.programType}
                  label={Strings.PROGRAM_TYPE}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={programList}
                  searchable
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='adminAssigned'
                  onChange={(value) => setFieldValue('adminAssigned', value)}
                  value={values.adminAssigned}
                  label={Strings.ADMIN_ASSIGNED}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={adminList}
                  searchable
                />
                <Dropdown
                  name='userAvailability'
                  onChange={handleChange('userAvailability')}
                  value={values.userAvailability}
                  label={Strings.HERE_AWAY}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={hereAwayDropdown}
                  // searchable
                />
                <FormSwitch
                  label={Strings.CLIENTS_WITH_ISSUES}
                  value={values.issues}
                  containerClassName={classes.form_input_container}
                  onChange={() => setFieldValue('issues', !values.issues)}
                />
                <FormSwitch
                  label={Strings.SHOW_ARCHIVE_CLIENT_LIST}
                  value={values.showArchived}
                  containerClassName={classes.form_input_container}
                  onChange={() =>
                    setFieldValue('showArchived', !values.showArchived)
                  }
                />
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_CLIENTS}
                    value={values.showAllClient}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllClient', !values.showAllClient)
                    }
                  />
                )}
                <Button
                  type='submit'
                  text={props.isEdit ? Strings.UPDATE : Strings.SAVE}
                  loading={props.addFilterLoading || props.updateFilterLoading}
                  disabled={props.addFilterLoading || props.updateFilterLoading}
                  className={classes.add_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
                {props.isEdit && (
                  <Button
                    type='button'
                    text={Strings.REMOVE}
                    loading={props.deleteFilterLoading}
                    disabled={props.deleteFilterLoading}
                    className={classes.secondary_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={Colors.RED}
                    onClick={() => {
                      props.deleteFilter(props.selectedId, onError, () => {
                        props?.setIsFilterChange(true);
                        props.onClose();
                        props.filterDialogOnClose();
                      });
                    }}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  programListData: state.programListData.data,
  programListLoading: state.programListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  addFilterLoading: state.addFilterData.loading,

  getFilterData: state.getFilterData.data,
  getFilterLoading: state.getFilterData.loading,

  updateFilterLoading: state.updateFilterData.loading,

  deleteFilterLoading: state.deleteFilterData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchProgramList,
  createFilter,
  fetchFilterById,
  updateFilter,
  deleteFilter,
})(NewClientFilter);
