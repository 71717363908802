import { MARK_AS_COMPLETE_JOB } from '../../../constants';
import axios from 'axios';

export const markAsCompleteJob = (jobId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(loadMarkAsCompleteJob());
  axios
    .post(`/jobs/${jobId}/markAsComplete`, {}, config)
    .then((res) => {
      dispatch(setMarkAsCompleteJob(res.data));
    })
    .catch((err) => {
      dispatch(failMarkAsCompleteJob(err.response));
      onError(err.response);
    });
};

export const loadMarkAsCompleteJob = () => ({
  type: MARK_AS_COMPLETE_JOB.LOAD,
});

export const setMarkAsCompleteJob = (data) => ({
  type: MARK_AS_COMPLETE_JOB.SUCCESS,
  payload: data,
});

export const failMarkAsCompleteJob = (error) => ({
  type: MARK_AS_COMPLETE_JOB.FAIL,
  payload: error,
});
