import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import {
  getErrorMessage,
  hereAwayDropdown,
  isAdmin,
  isSuperAdmin,
} from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { fetchProgramList } from '../../../redux/actions/clientActions/programTypeList';
import FormSwitch from '../../../components/Switch';
import { useAlert } from 'react-alert';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import NewClientFilter from './NewClientFilter';

const ClientFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [adminList, setAdminList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [openNewClientFilterDialog, setOpenNewClientFilterDialog] =
    useState(false);
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchProgramList((err) => onError(err));
    props.fetchUserListByType('admin', (err) => onError(err));
  }, []);

  useEffect(() => {
    if (props.filterData) {
      const {
        programType,
        adminAssigned,
        issues,
        showArchived,
        showAllClient,
        userAvailability,
      } = props.filterData;
      if (isAdmin() && showAllClient)
        setFieldValue('showAllClient', showAllClient || false);
      if (issues) setFieldValue('issues', issues || false);
      if (showArchived) setFieldValue('showArchived', showArchived || false);
      const newProgramType = programList?.filter((item) =>
        programType?.some((el) => el === item?.value)
      );
      const newAdminAssigned = adminList?.filter((item) =>
        adminAssigned?.some((el) => el === item?.value)
      );
      const newHereAwayData = hereAwayDropdown.filter(
        (el) => el.value === userAvailability
      );
      if (programType) setFieldValue('programType', newProgramType);
      if (adminAssigned) setFieldValue('adminAssigned', newAdminAssigned);
      if (userAvailability)
        setFieldValue('userAvailability', newHereAwayData[0]?.value || '0');
    }
  }, [props.filterData, adminList, programList]);

  useEffect(() => {
    const data = props.programListData ? props.programListData : [];
    const newData = data.map((item) => ({
      title: item.title,
      value: item._id,
    }));
    setProgramList(newData);
  }, [props.programListData]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title: item.name,
          value: item._id,
          image: item?.profilePicture,
        };
        return tempData;
      }) || [];
    if (data?.type === 'admin') setAdminList(newData);
  }, [props.UserListByTypeData]);

  const formik = useFormik({
    initialValues: {
      programType: [],
      adminAssigned: [],
      issues: false,
      showArchived: false,
      showAllClient: false,
      userAvailability: '0',
    },
    // validationSchema: AddJobSchema,

    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = (values) => {
    const data = {};
    // if ((props.filterData && props.filterData.issues) || values.issues)
    data.issues = values.issues;
    data.showArchived = values.showArchived;
    if (values.userAvailability !== '0')
      data.userAvailability = values.userAvailability;
    if (values.programType?.length)
      data.programType = values.programType?.map((el) => el.value) || [];
    if (isAdmin()) data.showAllClient = values.showAllClient;
    if (values.adminAssigned?.length)
      data.adminAssigned = values.adminAssigned?.map((el) => el.value) || [];
    props.setFilterData(data);
    props.onClose();
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader title={Strings.FILTER_BY} onNewClose={props.onClose} />
        {props.UserListByTypeLoading || props.programListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <MultiSelectDropdown
                  name='programType'
                  onChange={(value) => setFieldValue('programType', value)}
                  value={values.programType}
                  label={Strings.PROGRAM_TYPE}
                  // error={Boolean(touched.programType && errors.programType)}
                  // helperText={touched.programType && errors.programType}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={programList}
                  searchable
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='adminAssigned'
                  onChange={(value) => setFieldValue('adminAssigned', value)}
                  value={values.adminAssigned}
                  label={Strings.ADMIN_ASSIGNED}
                  // error={Boolean(touched.admin && errors.admin)}
                  // helperText={touched.admin && errors.admin}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={adminList}
                  searchable
                />
                <Dropdown
                  name='userAvailability'
                  onChange={handleChange('userAvailability')}
                  value={values.userAvailability}
                  label={Strings.HERE_AWAY}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={hereAwayDropdown}
                  // searchable
                />
                <FormSwitch
                  label={Strings.CLIENTS_WITH_ISSUES}
                  value={values.issues}
                  containerClassName={classes.form_input_container}
                  onChange={() => setFieldValue('issues', !values.issues)}
                />
                <FormSwitch
                  label={Strings.SHOW_ARCHIVE_CLIENT_LIST}
                  value={values.showArchived}
                  containerClassName={classes.form_input_container}
                  onChange={() =>
                    setFieldValue('showArchived', !values.showArchived)
                  }
                />
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_CLIENTS}
                    value={values.showAllClient}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllClient', !values.showAllClient)
                    }
                  />
                )}
                <div
                  className={classes.flexView}
                  style={{ marginTop: 0, gap: 15 }}
                >
                  <Button
                    type='button'
                    text={Strings.SAVE_FILTER}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.save_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={theme.palette.background_button_text}
                    onClick={() => setOpenNewClientFilterDialog(true)}
                  />
                  <Button
                    type='submit'
                    text={Strings.FILTER_NOW}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.add_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>
                <Button
                  type='button'
                  text={Strings.CLEAR_FILTERS}
                  onClick={() => {
                    resetForm();
                    props.setFilterData(
                      isAdmin() ? { showAllClient: false } : {}
                    );
                    props.onClose();
                  }}
                  // loading={props.addVendorLoading}
                  // disabled={props.addVendorLoading}
                  className={classes.secondary_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
      {openNewClientFilterDialog && (
        <NewClientFilter
          setIsFilterChange={props?.setIsFilterChange}
          filterDialogOnClose={() => props.onClose()}
          onClose={() => setOpenNewClientFilterDialog(false)}
          selectedValues={values}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  programListData: state.programListData.data,
  programListLoading: state.programListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchProgramList,
})(ClientFilter);
