import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  check_container: {
    width: 22,
    height: 22,
    minWidth: 22,
    minhHeight: 22,
    border: `1px solid ${theme.palette.background_border}`,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkedContainer: {
    backgroundColor: theme.palette.background_button,
  },
  checked_icon: {
    width: '60%',
    height: '88%',
    userDrag: 'none',
    userSelect: 'none',
    mozUserSelect: 'none',
    webkitUserDrag: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  check_label: {
    marginLeft: 8,
    color: theme.palette.profile_title,
    fontSize: 16,
    fontWeight: 400,
  },
}));
