import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  job_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      width: '100%',
      height: 'calc(100% - 95px)',
    },
  },
  list_container: {
    width: '100%',
    margin: '30px 0px',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit',
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0px',
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  toolbox_container: {
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '0px 14px',
      width: 'auto !important',
    },
  },
  name_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 600,
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  status_container: {
    padding: '3px 12px;',
    borderRadius: 40,
    color: Colors.WHITE,
    width: 'max-content',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  action_icons: {
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  job_details_container: {
    marginTop: 0,
  },
  job_details_tab: {
    color: theme.palette.background_button,
  },
  note_text_container: {
    display: 'flex',
    alignItems: 'center',
  },
  note_data: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.profile_title,
    padding: '10px 0px 0px 0px',
    marginTop: 5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    marginBottom: 0,
  },
  title_row: {
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  title_box: {
    background: theme.palette.background_border,
    borderRadius: '8px 8px 0 0',
  },
  title_text: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.menu_indicator,
    fontFamily: "'Open Sans', sans-serif",
  },
  section_delete_button: {
    gap: 10,
    padding: '0 !important',
    fontSize: `12px !important`,
    fontWeight: 600,
    color: `${theme.palette.menu_indicator} !important`,
    fontFamily: "'Open Sans', sans-serif",
  },
  dialog_msg: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 32,
    fontWeight: 300,
    lineHeight: 'normal',
    color: theme.palette.title_text,
    textAlign: 'center',
  },
  dialog_note_msg: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.profile_title,
    textAlign: 'center',
  },
  dialog_close_icon: {
    top: 24,
    right: 24,
    height: 32,
    width: 32,
  },
  dialog_body: {
    width: 'calc(100% - 20px)',
    padding: '15px 10px',
  },
  dialog_btn_container: {
    minWidth: 140,
    display: 'flex',
  },
  dialog_approve_btn: {
    color: Colors.WHITE,
    backgroundColor: theme.palette.notification_badge,
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: theme.palette.notification_badge,
    },
  },
  form_input_container: {
    width: 200,
    background: theme.palette.background,
    borderRadius: 8,
  },
  add_button: {
    minWidth: 105,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
}));
