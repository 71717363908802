import { CALENDAR_DATA } from '../../constants';

const initialState = {
  data: {},
  loading: true,
  error: {},
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
    case CALENDAR_DATA.LOAD:
      return {
        ...state,
        loading: true,
      };
    case CALENDAR_DATA.SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case CALENDAR_DATA.FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
