import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  chat_container: {
    width: '100%',
    height: 'calc(100% - 81px)',
    display: 'flex',
    overflow: 'hidden',
    // justifyContent: 'center'
  },
  chat_left_container: {
    width: 'calc(75% - 0px)',
    height: 'calc(100% - 44px)',
    padding: '22px 0',
    backgroundColor: theme.palette.background,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 30px)',
      padding: '15px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100% - 20px)',
      padding: '10px 0px',
    },
  },
  container_width: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  chat_right_container: {
    width: 'calc(25% - 44px)',
    height: 'calc(100% - 44px)',
    padding: '22px',
    background: theme.palette.chat_profile_background,
    overflow: 'auto',
  },
  header_container: {
    width: 'calc(100% - 22px)',
    paddingLeft: 22,
    height: '55px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      padding: '0 10px',
    },
  },
  left_header_container: {
    width: 'calc(30% - 30px)',
    height: '100%',
    padding: '0 15px',
    borderBottom: `1px solid ${theme.palette.chat_border}`,
    borderRight: `1px solid ${theme.palette.chat_border}`,
    [theme.breakpoints.down('md')]: {
      width: 'calc(40% - 30px)',
    },
  },
  right_header_container: {
    width: 'calc(70% - 37px)',
    height: '100%',
    paddingLeft: 15,
    // borderLeft: `1px solid ${theme.palette.chat_border}`,
    borderBottom: `1px solid ${theme.palette.chat_border}`,
    [theme.breakpoints.down('md')]: {
      width: 'calc(60% - 30px)',
    },
  },
  content_container: {
    width: 'calc(100% - 22px)',
    height: 'calc(100% - 56px)',
    paddingLeft: 22,
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      padding: '0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      padding: 0,
    },
  },
  left_content_container: {
    width: 'calc(30% - 20px)',
    height: 'calc(100% - 10px)',
    padding: '10px 10px 0 10px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.chat_border}`,
    [theme.breakpoints.down('md')]: {
      width: 'calc(40% - 15px)',
      padding: '10px 15px 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100% - 50px)',
      padding: 0,
    },
  },
  right_content_container: {
    width: '70%',
    height: '100%',
    // borderLeft: `1px solid ${theme.palette.chat_border}`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    [theme.breakpoints.down('md')]: {
      width: '60%',
      height: 'calc(100% - 10px)',
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      padding: '10px 15px 0 15px',
      borderLeft: 0,
    },
  },
  no_selection_right_style: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(70% - 22px)',
    },
  },
  message_search_box: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      margin: '0 15px 0 15px',
    },
  },
  message_search_input: {
    padding: '13px 13px 13px 35px',
    backgroundColor: theme.palette.background,
    color: theme.palette.search_text,
    border: `1px solid ${theme.palette.search_border}`,
    '&:focus': {
      border: `1px solid ${theme.palette.search_border}`,
    },
  },
  user_list_container: {
    height: '100%',
    overflow: 'auto',
    marginTop: 5,
  },
  user_message_box: {
    width: '100%',
    minHeight: '90px',
    borderBottom: `1px solid ${
      theme.palette.mode === 'dark'
        ? Colors.THIN_BLUE
        : 'rgba(45, 61, 149, 0.1)'
    }`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  user_message_box_container: {
    height: 'calc(100% - 30px)',
    padding: '15px 0',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
      padding: '15px 15px 15px 5px',
    },
    display: 'flex',
    width: '100%',
  },
  active_dot: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    marginRight: 5,
  },
  profile_detail_box: {
    padding: 20,
    borderBottom: `1px solid ${
      theme.palette.mode === 'dark'
        ? Colors.THIN_BLUE
        : 'rgba(45, 61, 149, 0.1)'
    }`,
  },
  profile_image_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  search_icon: {
    left: 0,
  },
  chat_search_input: {
    border: 'none',
    padding: '13px 13px 13px 25px',
    backgroundColor: theme.palette.background,
    color: theme.palette.search_text,
    '&:focus': {
      border: 'none',
    },
  },
  chat_message_view_container: {
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 20px)',
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      padding: '0',
    },
    position: 'relative',
  },
  chat_message_type_container: {
    backgroundColor: theme.palette.secondary_background,
    width: 'calc(100% - 1px)',
    minHeight: 90,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 110,
      borderRadius: 8,
      border: `1px solid ${theme.palette.background_border}`,
      flexDirection: 'column',
    },
  },
  information_input: {
    '&::placeholder': {
      color: theme.palette.profile_title,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    '&:focus': {
      border: `1px solid ${theme.palette.input_border}`,
    },
  },
  message_input: {
    height: '100%',
    fontWeight: 600,
    padding: '12px 0 0 12px',
    border: 'none',
    '&:focus': {
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 12,
    },
  },
  message_action_container: {
    display: 'flex',
    padding: '0 20px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px 8px 8px',
    },
  },
  selected_files_container: {
    height: 60,
    width: 60,
    position: 'relative',
    marginRight: 15,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected_files: {
    height: 60,
    width: 60,
    // border: '0.5px solid black',
    borderRadius: 8,
  },
  close_icon: {
    width: 16,
    height: 16,
    position: 'absolute',
    right: -6,
    top: -6,
    backgroundColor: Colors.RED,
    borderRadius: '50%',
  },
  close_icon_left: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 0,
    top: -6,
    backgroundColor: Colors.RED,
    borderRadius: '50%',
  },
  file_type: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: '600',
    color: Colors.BLUE,
  },
  browse_button: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginRight: 15,
  },
  message_send_button_container: {
    width: 45,
    height: 45,
  },
  message_send_button: {
    backgroundColor: theme.palette.form_switch_background,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.form_switch_background,
    },
  },
  chat_message_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chat_message_sent_container: {
    alignItems: 'flex-end',
    // alignSelf: 'end',
  },
  chat_message_receive_container: {
    alignItems: 'flex-start',
    // alignSelf: 'start',
  },
  daylist: {
    position: 'sticky',
    top: 0,
    width: 120,
    height: 24,
    backgroundColor: theme.palette.secondary_background,
    borderRadius: 5,
    color: theme.palette.background_button,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  receive_message: {
    padding: '7px 15px',
    borderRadius: '18px 18px 18px 0',
    backgroundColor: theme.palette.secondary_background,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.profile_title,
    maxWidth: '70%',
    // overflowWrap: 'break-word',
  },
  sent_message: {
    padding: '7px 15px',
    borderRadius: '18px 18px 0 18px',
    backgroundColor: theme.palette.background_button,
    fontSize: 14,
    fontWeight: 400,
    color: Colors.WHITE,
    maxWidth: '70%',
    // overflowWrap: 'break-word',
  },
  receive_file_container: {
    display: 'flex',
    padding: '7px 15px',
    borderRadius: '18px 18px 18px 0',
    backgroundColor: theme.palette.secondary_background,
    position: 'relative',
    maxWidth: '70%',
  },
  sent_file_container: {
    display: 'flex',
    padding: '7px 15px',
    borderRadius: '18px 18px 0 18px',
    backgroundColor: theme.palette.background_button,
    position: 'relative',
    maxWidth: '70%',
  },
  file_icon_container: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  receive_file_icon_background: {
    background:
      theme.palette.mode === 'dark'
        ? Colors.CHAT_FILE_DARK_BACKGROUND
        : Colors.CHAT_FILE_LIGHT_BACKGROUND,
  },
  sent_file_icon_background: {
    background: 'rgba(255,255,255, 0.2)',
  },
  file_icon: {
    height: 24,
    width: 24,
  },
  flex_view: {
    display: 'flex',
    alignItems: 'center',
  },
  flex_column_view: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  assigned_task_container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    gap: 15,
  },
  profile_name_text: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.profile_title,
  },
  text_light: {
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  text_bold: {
    fontWeight: 600,
    color: theme.palette.profile_title,
  },
  text_small_light: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  text_small_bold: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.profile_title,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text_small_white: {
    fontSize: 14,
    fontWeight: 400,
    color: Colors.WHITE,
  },
  text_small_blue: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  messages_sub_text: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.profile_title,
    lineHeight: '18px',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // width: '95%',
    display: 'flex',
    alignItems: 'center',
  },
  time_text: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.chat_time_text,
    marginBottom: 7,
  },
  title_container: {
    marginTop: 10,
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 30px)',
      padding: '0px 15px',
    },
  },
  right_content_toolbar: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    paddingLeft: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0',
    },
  },
  back_icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  toolbar_label: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.title_text,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      fontWeight: 300,
      marginBottom: 10,
    },
  },
  searchbox_container: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  flexView: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background,
  },
  no_selection_style: {
    backgroundColor: theme.palette.secondary_background,
    border: 'none',
  },
  no_selection_text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: theme.palette.profile_title,
  },
  selected_box_style: {
    backgroundColor: theme.palette.secondary_background,
  },
  download_bcgrnd: {
    backgroundColor: theme.palette.secondary_background,
    height: 35,
    width: 35,
    borderRadius: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: 5,
    cursor: 'pointer',
  },
  downloadIcon: {
    height: 18,
    width: 18,
    marginTop: -2,
  },
}));
