import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    background: Colors.EXTRA_GRAY,
  },
  dialog_container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
    width: 400,
    maxHeight: '90%',
    padding: 25,
    overflow: 'auto',
    background: theme.palette.dialog_background,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  send_report_container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  form_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  text_small_light: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
    height: '100%',
  },
  form_input_container: {
    marginTop: 20,
    height: '100%',
  },
  add_button_container: {
    marginTop: 20,
  },
  add_button: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  secondary_button_container: {
    marginTop: 10,
  },
  selectFiles_btn: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
    width: 'fit-content',
    height: 30,
    marginTop: 10,
  },
  img_Container: {
    height: 60,
    width: 60,
    display: 'flex',
    position: 'relative',
  },
  cancel_img: {
    height: 13,
    width: 13,
    background: theme.palette.background_button,
    border: `1px solid ${theme.palette.background_button}`,
    borderRadius: 100,
    position: 'absolute',
    right: -7,
    top: -7,
    padding: 1,
    cursor: 'pointer',
  },
  image_view: {
    height: '100%',
    width: '100%',
    borderRadius: 5,
  },
  file_view: {
    height: '100%',
    width: '100%',
    borderRadius: 5,
    border: `1px solid ${theme.palette.background_button}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.background_button,
    wordBreak: 'break-word',
    paddingLeft: 3,
    paddingRight: 3,
  },
  secondary_button: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: 'transparent',
    color: theme.palette.background_button,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  selectFiles: {
    display: 'none',
  },
  review_report_button_container: {
    marginTop: 30,
  },
  review_report_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  input_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    marginTop: 0,
  },
  note_input: {
    paddingTop: 8,
    paddingBottom: 0,
    fontSize: 13,
    fontWeight: 400,
    border: 'none',
    '&:focus': {
      border: 'none',
    },
  },
  information_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    border: `1px solid #ccc`,
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // '&:focus': {
    //   border: `1px solid #ccc`,
    // },
    width: 'calc(100% - 22px)',
    padding: 10,
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'sans-serif',
  },
}));
