import axios from 'axios';
import { CLIENT_JOBS } from '../../constants';

export const fetchClientJobs = (clientId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientJobs());
  axios
    .get(`/jobs/clientJobs?clientId=${clientId}`, config)
    .then((res) => dispatch(setClientJobs(res.data.data)))
    .catch((err) => {
      dispatch(errorClientJobs(err.response));
      onError(err.response);
    });
};

export const loadClientJobs = () => ({
  type: CLIENT_JOBS.LOAD,
});

export const setClientJobs = (data) => ({
  type: CLIENT_JOBS.SUCCESS,
  payload: data,
});

export const errorClientJobs = (error) => ({
  type: CLIENT_JOBS.FAIL,
  payload: error,
});
