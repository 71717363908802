import axios from 'axios';
import { DROPDOWN_ISSUE_LIST } from '../../../constants';

export const dropdownIssueList = (propertyId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDropdownIssueList());
  axios
    .get(`/issue/dropdown?property=${propertyId}`, config)
    .then((res) => dispatch(setDropdownIssueList(res.data.data)))
    .catch((err) => {
      dispatch(errorDropdownIssueList(err.response));
      onError(err.response);
    });
};

export const loadDropdownIssueList = () => ({
  type: DROPDOWN_ISSUE_LIST.LOAD,
});

export const setDropdownIssueList = (data) => ({
  type: DROPDOWN_ISSUE_LIST.SUCCESS,
  payload: data,
});

export const errorDropdownIssueList = (error) => ({
  type: DROPDOWN_ISSUE_LIST.FAIL,
  payload: error,
});
