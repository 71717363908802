import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { reportMediaArray } from '../../../utils/appUtils';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../../utils/color';
import { icons } from '../../../utils/icons';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    minWidth: 190,
    marginLeft: -8,
    backgroundColor: theme.palette.background,
    color: theme.palette.background_button,
    boxShadow:
      theme.palette.mode === 'dark'
        ? `8px 12px 36px ${Colors.MODAL_SHADOW_WHITE}`
        : '8px 12px 36px rgba(35, 52, 90, 0.25)',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

const ReportMediaMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <>
      <img
        src={icons(theme).WhiteMenuIcon}
        alt=''
        className={classes.White_menu}
        onClick={handleClick}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // value={selectedOption}
      >
        {reportMediaArray.map((item, index) => (
          <MenuItem
            key={`Menu${index}`}
            value={item.value}
            onClick={(e) => {
              props.onSelect(item.value);
              handleClose(e);
            }}
            disableRipple
          >
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default ReportMediaMenu;
