import axios from 'axios';
import { PROPERTIES_LIST } from '../../constants';

export const fetchPropertiesList = (cId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadPropertiesList());

  axios
    .get(`/client/listProperties?clientId=${cId}`, config)
    .then((res) => dispatch(setPropertiesList(res)))
    .catch((err) => {
      dispatch(errorPropertiesList(err.response));
      onError(err.response);
    });
};

export const loadPropertiesList = () => ({
  type: PROPERTIES_LIST.LOAD,
});

export const setPropertiesList = (userList) => ({
  type: PROPERTIES_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorPropertiesList = (error) => ({
  type: PROPERTIES_LIST.FAIL,
  payload: error,
});
