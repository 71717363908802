// import { io } from 'socket.io-client';
import config from '../../src/config';

export default function useConnectSocket() {
  function connectSocket() {
    let token = localStorage.jwtToken;
    // let socket = io(config.BASE_URL, {
    //   transports: ['websocket'],
    //   query: `token=${token}`,
    // });
    // global.socket = socket;
    // socket.on('connect_error', () => {
    //   console.log('connection error');
    // });
    // socket.on('disconnect', () => {
    //   console.log('disconnect', socket.connected); // false
    // });
    // socket.on('connect', () => {
    //   console.log('socket connected', socket.connected); // true
    // });
  }
  return { connectSocket };
}
