import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { Skeleton, Stack } from '@mui/material';

const CommonSkeleton = (props) => {
  return (
    <Skeleton
      variant={props?.variant || 'rectangular'}
      width={props?.width || '100%'}
      height={props?.height || '100%'}
      animation={props?.animation || 'wave'}
      className={props?.className}
      style={{ ...props?.style }}
    />
  );
};

CommonSkeleton.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animation: PropTypes.string,
  style: PropTypes.string,
  className: PropTypes.string,
};

const SkeletonFormField = () => {
  const classes = useStyles();

  return (
    <div className={classes.form_group}>
      <Stack direction='row' alignItems='center' gap={3} mt={1} mb={0.5}>
        <CommonSkeleton width={70} height={18} />
        <CommonSkeleton height={18} />
      </Stack>
      <Stack mt={1.5}>
        <CommonSkeleton height={42} />
      </Stack>
      <Stack direction='row' alignItems='center' gap={3} mt={2.25} mb={0.5}>
        <CommonSkeleton width={70} height={18} />
        <CommonSkeleton height={18} />
      </Stack>
      <Stack mt={1.5}>
        <CommonSkeleton height={42} />
      </Stack>
      <Stack mt={1} gap={1.5}>
        {Array.from({ length: 3 }, (_, _ind) => {
          return (
            <Stack
              key={_ind}
              direction='row'
              gap={1.5}
              alignItems='center'
              width='100%'
              height={42}
            >
              <CommonSkeleton height={24} width={24} />
              <CommonSkeleton width={183} />
              <CommonSkeleton width={129} />
              <CommonSkeleton height={24} width={24} />
            </Stack>
          );
        })}
        <CommonSkeleton height={18} />
      </Stack>
    </div>
  );
};

export default SkeletonFormField;
