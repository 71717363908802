import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import MediaDetails from '../../pages/Media/MediaDetails';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { Strings } from '../../utils/strings';
import { CircularProgress } from '@material-ui/core';
import ReportMediaMenu from '../../pages/Report/MediaMenu';
import { downloadFile, isClient } from '../../utils/appUtils';
import Checkbox from '../Checkbox';
import moment from 'moment';
import ShareMediaDialog from '../ShareMediaDialog';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MediaImageContainer = (props) => {
  const {
    imageArray,
    showImageText,
    itemsPerRow,
    imageData,
    loading,
    isEditReport,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const is1281 = useMediaQuery('(min-width:1281px)');
  const is1836 = useMediaQuery('(min-width:1836px)');
  const is2336 = useMediaQuery('(min-width:2336px)');
  const is2536 = useMediaQuery('(min-width:2536px)');
  const [openImageInfoDialog, setOpenImageInfoDialog] = useState(false);
  const [fetchedFileId, setFetchedFileId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [selectedFileInfo, setSelectedFileInfo] = useState(null);

  const fetchFileIcon = (type) => {
    if (type === 'image') return icons(theme).imageIcon;
    else if (type === 'doc' || type === 'word' || type === 'docx')
      return icons(theme).docFileIcon;
    else if (type === 'audio') return icons(theme).audioFileIcon;
    else if (type === 'video') return icons(theme).videoFileIcon;
    else if (type === 'xlsx' || type === 'xls' || type === 'csv')
      return icons(theme).excelFileIcon;
    else if (type === 'ppt') return icons(theme).pptFileIcon;
    else if (type === 'zip' || type === 'rar') return icons(theme).zipFileIcon;
    else if (type === 'pdf') return icons(theme).pdfFileIcon;
    else return icons(theme).undefinedFileIcon;
  };

  useEffect(() => {
    if (!loader) {
      setSelectedFile('');
    }
  }, [loader]);

  return (
    <>
      {loading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : (
        //  : imageArray?.length === 0 ? (
        //   <div className={classes.flexView}>
        //     <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
        //   </div>
        // )
        <Grid
          container
          spacing={mobileView ? 1.9 : 2.5}
          style={{ marginTop: mobileView ? 0 : -15 }}
          className={props.imgContainerClassName}
        >
          {imageArray?.map((data, index) => {
            let fileData = {};
            if (imageData) {
              fileData = { ...props?.imageData, ...data };
            } else if (!imageData) {
              fileData = { ...data };
            }
            const fileExtension = fileData?.fileName?.split('.').pop();
            return (
              <Grid
                item
                xs={itemsPerRow ? 12 / itemsPerRow : 12}
                sm={props.sm}
                md={props.md}
                lg={props.lg && props.lg}
                xl={
                  props.xl
                    ? props.xl
                    : props.isReport
                    ? 4
                    : props.isAddProperty
                    ? is2536
                      ? 3
                      : 4
                    : 2
                }
                key={index}
              >
                <div
                  className={classes.media_card}
                  style={
                    props.isLongPress
                      ? { cursor: 'pointer', border: `2px solid ${'#d9e4fb'}` }
                      : {
                          cursor: 'pointer',
                          border: `2px solid transparent`,
                          maxHeight: !showImageText && 160,
                        }
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (fileData._id && !props.isLongPress) {
                      setOpenImageInfoDialog(true);
                      setFetchedFileId(fileData?._id);
                    } else if (props.isLongPress) {
                      if (props.selectedIds.includes(fileData?._id)) {
                        let newIds = props.selectedIds.filter(
                          (i) => i !== fileData?._id
                        );
                        props.setSelectedIds(newIds);
                      } else {
                        props.setSelectedIds([
                          ...props.selectedIds,
                          fileData?._id,
                        ]);
                      }
                    }
                  }}
                  {...props}
                >
                  <div
                    className={
                      showImageText
                        ? classes.image_container
                        : classes.noDetails_image_container
                    }
                    style={{
                      justifyContent: data?.type !== 'image' && 'center',
                      alignItems: data?.type !== 'image' && 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {props.isLongPress && (
                      <div className={classes.selected_Item} style={{zIndex: 1,}}>
                        <Checkbox
                          checkboxContainerClass={classes.checkbox_container}
                          labelClassName={classes.labelClassName}
                          checkedContainerClass={classes.checked_checkbox}
                          checked={props.selectedIds.includes(fileData?._id)}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (props.selectedIds.includes(fileData?._id)) {
                              let newIds = props.selectedIds.filter(
                                (i) => i !== fileData?._id
                              );
                              props.setSelectedIds(newIds);
                            } else {
                              props.setSelectedIds([
                                ...props.selectedIds,
                                fileData?._id,
                              ]);
                            }
                          }}
                        />
                      </div>
                    )}
                    <LazyLoadImage
                      effect='blur'
                      // placeholderSrc={icons().loading}
                      src={
                        data?.type === 'image'
                          ? `${fileData?.url}?not-from-cache-please`
                          : fetchFileIcon(
                              fileData?.type === 'file'
                                ? fileExtension
                                : fileData?.type
                            )
                      }
                      alt=''
                      className={
                        showImageText
                          ? classes.media_page_image
                          : classes.report_page_image
                      }
                      //crossOrigin='Anonymous'
                      style={{
                        width: data?.type !== 'image' ? '45%' : '100%',
                        objectFit: data?.type === 'image' ? 'cover' : 'contain',
                        height: '100%',
                      }}
                    />

                    <div
                      className={classes.file_icon_container}
                      // style={{ cursor: fileData?.jobId ? 'pointer' : 'auto' }}
                    >
                      <div
                        className={classes.icons_container}
                        onClick={(e) => {
                          if (fileData._id) {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenImageInfoDialog(true);
                            setFetchedFileId(fileData?._id);
                          }
                        }}
                      >
                        <img
                          src={fetchFileIcon(
                            fileData?.type === 'file'
                              ? fileExtension
                              : fileData?.type
                          )}
                          alt=''
                          className={classes.icon_image}
                        />
                      </div>
                      <div
                        className={classes.icons_container}
                        onClick={async (e) => {
                          if (!loader) {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedFile(fileData?._id);
                            await downloadFile(
                              `${fileData.url}`,
                              fileData?.type,
                              fileData?.fileName,
                              setLoader
                            );
                          }
                        }}
                      >
                        {selectedFile === fileData?._id && loader ? (
                          <CircularProgress
                            style={{
                              color: theme.palette.background_button,
                            }}
                            size={15}
                          />
                        ) : (
                          <img
                            src={icons(theme).downloadIcon}
                            alt=''
                            className={classes.icon_image}
                          />
                        )}
                      </div>
                      {/*===== DELETE IMAGE =======*/}
                      {(props.isMediaSection &&
                        data?.canDeleteFromMedia &&
                        !isClient()) ||
                      props.isImgDelete ? (
                        <div
                          className={classes.icons_container}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onClickDeleteMedia(e, fileData);
                          }}
                        >
                          <img
                            src={icons(theme).deleteIcon}
                            alt=''
                            className={classes.icon_image}
                          />
                        </div>
                      ) : null}

                      {/* ===== Share File ===== */}
                      <div
                        className={classes.icons_container}
                        style={{ borderRight: 'none' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenShareDialog(true);
                          setSelectedFileInfo(data);
                        }}
                      >
                        <img
                          src={icons().shareIcon}
                          alt='share'
                          className={classes.icon_image}
                        />
                      </div>
                    </div>
                    {isEditReport && (
                      <ReportMediaMenu
                        onSelect={(action) => {
                          props.handleMediaActionClick(
                            action,
                            data._id,
                            data.tags
                          );
                        }}
                      />
                    )}
                  </div>
                  {showImageText && (
                    <div className={classes.media_text_container}>
                      <label
                        className={classNames(
                          classes.media_text_overflow,
                          classes.text_title
                        )}
                      >
                        {fileData?.title}
                      </label>
                      {!props.showTitleOnly && fileData?.createdBy && (
                        <label
                          className={classNames(
                            classes.media_text_overflow,
                            classes.text_subTitle
                          )}
                        >
                          {Strings.ADDED_BY + ' ' + fileData?.createdBy}
                        </label>
                      )}

                      {!props.showTitleOnly && props.isReports && (
                        <label
                          className={classNames(
                            classes.media_text_overflow,
                            classes.text_subTitle
                          )}
                        >
                          <b>{`${Strings.ADDED_ON_DATE}: `}</b>
                          {` ${moment(fileData?.createdAt).format(
                            'MMM DD, YYYY h:mm A'
                          )}`}
                        </label>
                      )}

                      {data?.tags?.length > 0 && !props.isReports && (
                        <div className={classes.tags_container}>
                          {data?.tags?.map((item) => (
                            <div
                              // style={{ marginRight: 0 }}
                              className={classes.tags}
                              onClick={() => {
                                if (fileData._id) {
                                  setOpenImageInfoDialog(true);
                                  setFetchedFileId(fileData?._id);
                                }
                              }}
                            >
                              <label style={{ cursor: 'pointer' }}>
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            );
          })}
          {openImageInfoDialog && (
            <MediaDetails
              // fileInfo={fetchedFileId}
              fileId={fetchedFileId}
              onNewClose={() => {
                setOpenImageInfoDialog(false);
                setFetchedFileId(null);
              }}
              mediaList={imageArray}
              isAddTagsRequired={props?.paramJobId ? false : true}
            />
          )}
        </Grid>
      )}
      {openShareDialog ? (
        <ShareMediaDialog
          onClose={() => {
            setOpenShareDialog(false);
            setSelectedFileInfo(null);
          }}
          fileInfo={selectedFileInfo}
        />
      ) : null}
    </>
  );
};

export default MediaImageContainer;
