import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  announcement_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '10px 18px',
    overflow: 'auto',
  },
  list_container: {
    width: '100%',
    margin: '30px 0px',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit',
      overflow: 'auto',
    },
  },
  name_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    color: theme.palette.profile_title,
    fontWeight: 600,
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  status_container: {
    backgroundColor: Colors.LIGHT_GREEN,
    padding: '3px 12px',
    borderRadius: 40,
    color: Colors.WHITE,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
}));
