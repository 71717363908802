import axios from 'axios';

import moment from 'moment-timezone';
import { EXPORT_ISSUES } from '../../constants';
import { DownloadCSVorPDF } from '../../../utils/appUtils';

export const exportIssues = (issueData, fileName, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const data = {
    ...issueData,
    currentDate: moment().format('YYYY-MM-DD'),
    timeZone: moment.tz.guess(),
  };
  const url = `/issue/export`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    method: 'post',
    url: url,
    data,
    responseType: 'blob',
  };
  dispatch(loadExportIssues());
  axios(config)
    .then((res) => {
      dispatch(setExportIssues(res));
      DownloadCSVorPDF(res.data, `${fileName}.xlsx`);
    })
    .catch((err) => {
      dispatch(errorExportIssues(err.response));
      onError(err.response);
    });
};

export const loadExportIssues = () => ({
  type: EXPORT_ISSUES.LOAD,
});

export const setExportIssues = (data) => ({
  type: EXPORT_ISSUES.SUCCESS,
  payload: data.data,
});

export const errorExportIssues = (error) => ({
  type: EXPORT_ISSUES.FAIL,
  payload: error,
});
