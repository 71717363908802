import axios from 'axios';
import { CLIENT_LIST_NAME } from '../../constants';

export const fetchClientListName = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadClientListName());
  axios
    .get('/client/listClients', config)
    .then((res) => dispatch(setClientListName(res.data.data)))
    .catch((err) => {
      dispatch(errorClientListName(err.response));
      onError(err.response);
    });
};

export const loadClientListName = () => ({
  type: CLIENT_LIST_NAME.LOAD,
});

export const setClientListName = (data) => ({
  type: CLIENT_LIST_NAME.SUCCESS,
  payload: data,
});

export const errorClientListName = (error) => ({
  type: CLIENT_LIST_NAME.FAIL,
  payload: error,
});
