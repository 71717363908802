import { makeStyles } from '@mui/styles';
import { Colors } from '../../.././utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 2,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    '&:focus': {
      border: `1px solid ${theme.palette.background_button}`,
    },
  },
  form_input_container: {
    marginTop: 12,
    width:'100%',
  },
  add_button: {
    fontWeight: 600,
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },

  save_button:{
    backgroundColor: Colors.WHITE,
    width: '100%',
    height: 45,
    color: Colors.NEVY_BLUE,
    borderRadius: 8,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
    border: `1px solid ${Colors.NEVY_BLUE}`,
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: Colors.WHITE,
    },
  },
  secondary_button: {
    backgroundColor: 'transparent',
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
}));
