import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  check_container: {
    width: 14,
    height: 14,
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background,
  },
  checkedContainer: {
    backgroundColor: Colors.NEVY_BLUE,
  },
  checked_icon: {
    width: '60%',
    height: '88%',
    userDrag: 'none',
    userSelect: 'none',
    mozUserSelect: 'none',
    webkitUserDrag: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  check_label: {
    marginLeft: 8,
    color: Colors.DARK_GRAY,
    fontSize: 12,
    fontWeight: 400,
  },
  profileBadge: {
    '& .MuiBadge-badge': {
      boxShadow: `0 0 0 2px ${theme.palette.background}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: `''`,
      },
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
