import React from 'react';
import { Strings } from '../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { ForgotPasswordSchema } from '../../utils/validationSchemas';
import Textbox from '../../components/Textbox';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { forgotPassword } from '../../redux/actions/authActions/forgotPassword';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../utils/appUtils';
import { icons } from '../../utils/icons';
import { useAlert } from 'react-alert';

const ForgotPasswordForm = (props) => {
  const { classes, handleOnSubmit, loading } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,

    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <div className={classes.login_form_container}>
      <label htmlFor={Strings.WELCOME_BACK} className={classes.welcome_text}>
        {Strings.RESET_PASSWORD}
      </label>
      <FormikProvider value={formik}>
        <Form
          className={classes.login_form}
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Textbox
            type='email'
            name='email'
            onChange={handleChange('email')}
            value={values.email}
            label={Strings.EMAIL_ADDRESS}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            placeholder={Strings.ENTER_EMAIL}
          />
          <Button
            type='submit'
            text={Strings.SUBMIT}
            loading={loading}
            disabled={loading}
            containerClassName={classes.inputMargin}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};

const ForgotPassword = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const alert = useAlert();
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const handleOnSubmit = (values) => {
    const data = {
      email: values.email,
    };
    props.forgotPassword(
      data,
      navigate,
      (message) => {
        alert.success(message);
      },
      (err) => onError(err),
      values.remember
    );
  };
  return (
    <div className={classes.login_container}>
      <div className={classes.main_container}>
        <div className={classes.left_container}>
          <div className={classes.login_mask} />
          <img className={classes.logo} src={icons().loginLogo} alt='' />
          <img className={classes.login_text} src={icons().loginText} alt='' />
        </div>
        <div className={classes.right_container}>
          <img
            className={classes.gray_logo}
            src={icons().grayLoginLogo}
            alt=''
          />
          <ForgotPasswordForm
            classes={classes}
            handleOnSubmit={handleOnSubmit}
            loading={props.forgotPasswordLoading}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  forgotPasswordData: state.forgotPasswordData.data,
  forgotPasswordLoading: state.forgotPasswordData.loading,
});

export default connect(mapStateToProps, {
  forgotPassword,
})(ForgotPassword);
