import { Colors } from '../utils/color';

export default {
  background: Colors.WHITE,
  secondary_background: Colors.SIDEBAR_BLUE,
  background_border: Colors.THIN_BORDER,
  title_text: Colors.BLACK,
  notification_badge: Colors.TOMATO_RED,
  profile_title: Colors.DARK_GRAY,
  secondary_profile_title: Colors.SECONDARY_TITLE,
  menu_indicator: Colors.NEVY_BLUE,
  background_button: Colors.NEVY_BLUE,
  switch_background: Colors.LIGHT_BLUE,
  form_switch_background: Colors.LIGHT_BLUE,
  search_border: Colors.LIGHT_GRAY,
  search_text: Colors.DARK_GRAY,
  background_button_text: Colors.WHITE,
  dialog_background: Colors.WHITE,
  modal_shadow: Colors.MODAL_SHADOW_BLUE,
  form_label: Colors.LIGHT_BLACK,
  input_placeholder: Colors.PLACEHOLDER_COLOR,
  input_border: Colors.LIGHT_GRAY,
  table_head_background: Colors.TABLE_HEAD_BLUE,
  table_row_background: Colors.SIDEBAR_BLUE,
  secondary_input_placeholder: Colors.EXTRA_LIGHT_NEVY_BLUE,
  secondary_input_border: Colors.SECONDARY_BORDER,
  drop_image_background: Colors.DROP_IMAGE_PURPLE,
  group_form_background: Colors.GROUP_FORM_BLUE,
  dropdown_tag_background: Colors.TAG_BLUE,
  form_input_background: Colors.WHITE,
  chat_time_text: Colors.THIN_GRAY,
  chat_border: Colors.CHAT_THIN_BORDER,
  chat_profile_background: Colors.CHAT_PROFILE_BACKGROUND,
  checkbox_border: Colors.THIN_BORDER,
  sidebar_background: Colors.WHITE,
  sidebar_shadow: Colors.SIDEBAR_SHADOW,
  sidebar_border: Colors.SIDEBAR_BORDER,
  disable_color: Colors.LIGHT_GRAY,
  media_title_text: Colors.EXTRA_DARK,
  media_box_shadow: Colors.EXTRA_GRAY,
  avatar_background: Colors.DARK_PLACEHOLDER,
};
