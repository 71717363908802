import axios from 'axios';
import { UPDATE_EBLAST } from '../../constants';

export const updateEBlast = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateEBlast());
  axios
    .put(`/admin/eblast`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setUpdateEBlast(res.data.data));
    })
    .catch((err) => {
      dispatch(errorUpdateEBlast(err.response));
      onError(err.response);
    });
};

export const loadUpdateEBlast = () => ({
  type: UPDATE_EBLAST.LOAD,
});

export const setUpdateEBlast = (data) => ({
  type: UPDATE_EBLAST.SUCCESS,
  payload: data,
});

export const errorUpdateEBlast = (error) => ({
  type: UPDATE_EBLAST.FAIL,
  payload: error,
});
