import axios from 'axios';
import { MARK_ALL_JOB_NOTES_READ } from '../../../constants';

export const markAllJobsNotesRead =
  (data, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadAllJobNotesRead());
    axios
      .put(`/jobs/markAllRead`, data, config)
      .then((res) => {
        dispatch(setAllJobNotesRead(res));
        onSuccess?.(res.data.data);
      })
      .catch((err) => {
        dispatch(errorAllJobNotesRead(err.response));
        onError(err.response);
      });
  };

export const loadAllJobNotesRead = () => ({
  type: MARK_ALL_JOB_NOTES_READ.LOAD,
});

export const setAllJobNotesRead = (data) => ({
  type: MARK_ALL_JOB_NOTES_READ.SUCCESS,
  payload: data.data,
});

export const errorAllJobNotesRead = (error) => ({
  type: MARK_ALL_JOB_NOTES_READ.FAIL,
  payload: error,
});
