import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
// layouts
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import setAuthToken from './utils/setAuthToken';
import jwtDecode from 'jwt-decode';
import store from './redux/store';
import { logout, setLogin } from './redux/actions/authActions/login';
import Team from './pages/Team';
import Vendor from './pages/Vendor';
import Clients from './pages/Clients';
import Jobs from './pages/Jobs';
import ProgramTypes from './pages/Clients/ProgramTypes';
import JobForm from './pages/Jobs/JobForms';
import AddProperty from './pages/AddProperty';
import Issues from './pages/Issues';
import { isAdmin, isClient } from './utils/appUtils';
import Report from './pages/Report';
import ReportDetails from './pages/Report/ReportDetails';
import Media from './pages/Media';
import CategoryTypes from './pages/Jobs/JobForms/CategoryTypes';
import Chat from './pages/Chat';
import CalendarScreen from './pages/Calendar';
import AnnouncementList from './pages/Dashboard/AnnouncementList';
import ForgotPassword from './pages/ForgotPassword';
import Junctures from './pages/Dashboard/Junctures';
import JobSeries from './pages/Jobs/JobSeries';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  store.dispatch(setLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = '/login';
  }
}

export default function Router() {
  return useRoutes([
    localStorage.jwtToken
      ? {
          path: '/',
          element: <MainLayout />,
          children: isClient()
            ? [
                { path: '/', element: <Navigate to='/app/dashboard' /> },
                { path: '/app', element: <Navigate to='/app/dashboard' /> },
                {
                  path: '/app/dashboard',
                  element: <Dashboard />,
                  text: 'Dashboard',
                },
                // {
                //   path: '/app/reports',
                //   element: <Report />,
                //   text: 'Reports',
                // },
                {
                  path: '/app/report',
                  element: <ReportDetails />,
                  text: 'Reports',
                },
                {
                  path: '/app/report/:paramJobId',
                  element: <ReportDetails />,
                  text: 'Report',
                },
                {
                  path: '/app/reports',
                  element: <Jobs />,
                  text: 'Reports',
                },
                {
                  path: '/app/issues',
                  element: <Issues />,
                  text: 'Tasks',
                },
                // {
                //   path: '/app/chat',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                // {
                //   path: '/app/chat/:userId',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                {
                  path: '/app/media',
                  element: <Media />,
                  text: 'Media',
                },
                {
                  path: '/app/property',
                  element: <AddProperty />,
                  text: 'Property Details',
                },
                // {
                //   path: '/app/property/edit',
                //   element: <AddProperty />,
                //   text: 'EditProperty',
                // },
              ]
            : isAdmin()
            ? [
                { path: '/', element: <Navigate to='/app/dashboard' /> },
                { path: '/app', element: <Navigate to='/app/dashboard' /> },
                {
                  path: '/app/dashboard',
                  element: <Dashboard />,
                  text: 'Dashboard',
                },
                {
                  path: '/app/dashboard/announcements',
                  element: <AnnouncementList />,
                  text: 'Announcements',
                },
                {
                  path: '/app/dashboard/arrivals-departures',
                  element: <Junctures />,
                  text: 'Arrivals and Departures',
                },
                {
                  path: '/app/clients',
                  element: <Clients />,
                  text: 'Clients',
                },
                {
                  path: '/app/clients/programs',
                  element: <ProgramTypes />,
                  text: 'ProgramTypes',
                },
                {
                  path: '/app/clients/add/property',
                  element: <AddProperty />,
                  text: 'AddProperty',
                },
                {
                  path: '/app/clients/view/property',
                  element: <AddProperty />,
                  text: 'ViewProperty',
                },
                {
                  path: '/app/clients/edit/property',
                  element: <AddProperty />,
                  text: 'EditProperty',
                },
                // {
                //   path: '/app/team',
                //   element: <Team />,
                //   text: 'Team',
                // },
                {
                  path: '/app/calendar',
                  element: <CalendarScreen />,
                  text: 'Calendar',
                },
                {
                  path: '/app/vendor',
                  element: <Vendor />,
                  text: 'Vendor',
                },
                {
                  path: '/app/vendor/categories',
                  element: <CategoryTypes />,
                  text: 'CategoryTypes',
                },
                {
                  path: '/app/jobs',
                  element: <Jobs />,
                  text: 'Jobs',
                },
                {
                  path: '/app/jobs/job-series',
                  element: <JobSeries />,
                  text: 'Jobs',
                },
                {
                  path: '/app/jobs/forms',
                  element: <JobForm />,
                  text: 'JobForms',
                },
                {
                  path: '/app/jobs/forms/categories',
                  element: <CategoryTypes />,
                  text: 'CategoryTypes',
                },
                {
                  path: '/app/issues',
                  element: <Issues />,
                  text: 'Tasks',
                },
                // {
                //   path: '/app/reports',
                //   element: <Report />,
                //   text: 'Reports',
                // },
                {
                  path: '/app/report',
                  element: <ReportDetails />,
                  text: 'Reports',
                },
                // {
                //   path: '/app/chat',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                // {
                //   path: '/app/chat/:userId',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                {
                  path: '/app/media',
                  element: <Media />,
                  text: 'Media',
                },
              ]
            : [
                { path: '/', element: <Navigate to='/app/dashboard' /> },
                { path: '/app', element: <Navigate to='/app/dashboard' /> },
                {
                  path: '/app/dashboard',
                  element: <Dashboard />,
                  text: 'Dashboard',
                },
                {
                  path: '/app/dashboard/announcements',
                  element: <AnnouncementList />,
                  text: 'Announcements',
                },
                {
                  path: '/app/dashboard/arrivals-departures',
                  element: <Junctures />,
                  text: 'Arrivals and Departures',
                },
                {
                  path: '/app/clients',
                  element: <Clients />,
                  text: 'Clients',
                },
                {
                  path: '/app/clients/programs',
                  element: <ProgramTypes />,
                  text: 'ProgramTypes',
                },
                {
                  path: '/app/clients/add/property',
                  element: <AddProperty />,
                  text: 'AddProperty',
                },
                {
                  path: '/app/clients/view/property',
                  element: <AddProperty />,
                  text: 'ViewProperty',
                },
                {
                  path: '/app/clients/edit/property',
                  element: <AddProperty />,
                  text: 'EditProperty',
                },
                {
                  path: '/app/team',
                  element: <Team />,
                  text: 'Team',
                },
                {
                  path: '/app/calendar',
                  element: <CalendarScreen />,
                  text: 'Calendar',
                },
                {
                  path: '/app/vendor',
                  element: <Vendor />,
                  text: 'Vendor',
                },
                {
                  path: '/app/vendor/categories',
                  element: <CategoryTypes />,
                  text: 'CategoryTypes',
                },
                {
                  path: '/app/jobs',
                  element: <Jobs />,
                  text: 'Jobs',
                },
                {
                  path: '/app/jobs/job-series',
                  element: <JobSeries />,
                  text: 'Jobs',
                },
                {
                  path: '/app/jobs/forms',
                  element: <JobForm />,
                  text: 'JobForms',
                },
                {
                  path: '/app/jobs/forms/categories',
                  element: <CategoryTypes />,
                  text: 'CategoryTypes',
                },
                {
                  path: '/app/issues',
                  element: <Issues />,
                  text: 'Tasks',
                },
                // {
                //   path: '/app/reports',
                //   element: <Report />,
                //   text: 'Reports',
                // },
                {
                  path: '/app/report',
                  element: <ReportDetails />,
                  text: 'Reports',
                },
                // {
                //   path: '/app/chat',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                // {
                //   path: '/app/chat/:userId',
                //   element: <Chat />,
                //   text: 'Chat',
                // },
                {
                  path: '/app/media',
                  element: <Media />,
                  text: 'Media',
                },
              ],
        }
      : {
          path: '/',
          children: [
            { path: '/', element: <Navigate to='/login' /> },
            { path: '/login', element: <Login /> },
            { path: '/forgotpassword', element: <ForgotPassword /> },
            {
              path: '/app/report/:paramJobId',
              element: <ReportDetails />,
            },
          ],
        },
    { path: '*', element: <Navigate to='/' replace /> },
  ]);
}
