import axios from 'axios';
import { UPDATE_FILE_DETAILS } from '../../constants';

export const updateFileInfo = (mId, data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadFileDetail());

  axios
    .put(`/media/${mId}`, data, config)
    .then((res) => {
      dispatch(setFileDetail(res.data.data));
      onSuccess?.();
    })
    .catch((err) => {
      dispatch(errorFileDetail(err.response));
      onError(err.response);
    });
};

export const loadFileDetail = () => ({
  type: UPDATE_FILE_DETAILS.LOAD,
});

export const setFileDetail = (data) => ({
  type: UPDATE_FILE_DETAILS.SUCCESS,
  payload: data,
});

export const errorFileDetail = (error) => ({
  type: UPDATE_FILE_DETAILS.FAIL,
  payload: error,
});
