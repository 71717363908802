import axios from 'axios';
import { ADD_NOTES } from '../../constants';

export const createNotes = (pId, data, onSuccess, onError) => (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('message', data.message);
  if (data.attachment) bodyFormData.append('attachment', data.attachment);
  const token = localStorage.jwtToken;
  dispatch(addNotes());
  axios({
    method: 'post',
    url: `/client/createNotes?propertyId=${pId}`,
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      onSuccess(res);
      dispatch(setAddNotes(res));
    })
    .catch((err) => {
      dispatch(errorAddNotes(err.response));
      onError(err.response);
    });
};

export const addNotes = () => ({
  type: ADD_NOTES.LOAD,
});

export const setAddNotes = (data) => ({
  type: ADD_NOTES.SUCCESS,
  payload: data.data,
});

export const errorAddNotes = (error) => ({
  type: ADD_NOTES.FAIL,
  payload: error,
});
