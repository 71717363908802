import axios from 'axios';
import { COPY_MEDIA } from '../../constants';

export const copyMedia = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadCopyMedia());

  axios
    .post(`/media/copy`, data, config)
    .then((res) => {
      dispatch(setCopyMedia(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorCopyMedia(err.response));
      onError(err.response);
    });
};

export const loadCopyMedia = () => ({
  type: COPY_MEDIA.LOAD,
});

export const setCopyMedia = (folderData) => ({
  type: COPY_MEDIA.SUCCESS,
  payload: folderData.data.data,
});

export const errorCopyMedia = (error) => ({
  type: COPY_MEDIA.FAIL,
  payload: error,
});
