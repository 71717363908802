import React, { useEffect } from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
// import { useTheme } from '@mui/material/styles';
// import { icons } from '../../utils/icons';
import moment from 'moment';
import { Strings } from '../../utils/strings';
import {
  isAdmin,
  isClient,
  setLocaleStorageVal,
  statusColor,
} from '../../utils/appUtils';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const JobsContainer = (props) => {
  const classes = useStyles();
  const [listData, setListData] = useState([]);
  // const theme = useTheme();
  // const [openIssues, setOpenIssues] = useState([]);

  // const handleOpenIssues = (index) => {
  //   if (openIssues.findIndex((item, i) => item === index) === -1) {
  //     setOpenIssues([...openIssues, index]);
  //   } else {
  //     setOpenIssues(openIssues.filter((item) => item !== index));
  //   }
  // };

  useEffect(() => {
    if (props?.data && props?.data?.length) {
      if (props?.limit) {
        let limit =
          props?.limit > props?.data?.length
            ? props?.data?.length
            : props?.limit;
        let newData = structuredClone(props?.data) || [];
        newData = newData?.slice(0, limit);
        setListData(newData);
      } else setListData(props?.data);
    }
  }, [props?.data]);

  return (
    <>
      {listData?.length === 0 ? (
        <div className={classes.flexView}>
          <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
        </div>
      ) : (
        listData?.map((item, index) => (
          <div
            key={index}
            className={classes.container}
            onClick={() => props?.handleJobClick(item?._id)}
          >
            <div className={classes.issues_header}>
              {/* <img
              className={classes.toggle_arrow}
              src={
                openIssues.findIndex((item) => item === index) !== -1
                  ? icons(theme).downArrow
                  : icons(theme).rightArrow
              }
              alt=''
              onClick={() => handleOpenIssues(index)}
            /> */}
              <label htmlFor='date' className={classes.issues_title}>
                {item?.title}
              </label>
              <label htmlFor='Issues' className={classes.date_label}>
                {item.endDate && moment(item.endDate).format('DD MMM, YYYY')}
              </label>
            </div>
            <div className={classes.issues_footer}>
              <div
                className={classes.issue_status}
                style={{ backgroundColor: statusColor(item.jobStatus) }}
              >
                {item.jobStatus}
              </div>
            </div>
          </div>
        ))
      )}
      {props?.limit && props?.limit < props?.data?.length ? (
        <Stack alignItems='center' py={1.25}>
          <Link
            to={isClient() ? '/app/reports' : '/app/jobs'}
            className={classes.link_txt}
            onClick={() => {
              let data = {
                filter: {
                  property: [props?.propertyId],
                  dateRange: 'All Time',
                },
              };
              if (isAdmin()) data.filter.showAllJob = false;
              setLocaleStorageVal('JobsData', data);
            }}
          >
            {Strings.SEE_MORE}
          </Link>
        </Stack>
      ) : null}
    </>
  );
};

JobsContainer.propTypes = {
  limit: PropTypes.number,
};

export default JobsContainer;
