import axios from 'axios';
import { CANCEL_RECURRENCE } from '../../../constants';

export const cancelRecurrence = (jobId, dialogClose, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadCancelRecurrence());
  axios
    .put(`/jobs/cancelRecurrence?jobId=${jobId}`, {}, config)
    .then((res) => {
      dispatch(setCancelRecurrence(res.data));
      dialogClose();
    })
    .catch((err) => {
      dispatch(errorCancelRecurrence(err.response));
      onError(err.response);
    });
};

export const loadCancelRecurrence = () => ({
  type: CANCEL_RECURRENCE.LOAD,
});

export const setCancelRecurrence = (data) => ({
  type: CANCEL_RECURRENCE.SUCCESS,
  payload: data,
});

export const errorCancelRecurrence = (error) => ({
  type: CANCEL_RECURRENCE.FAIL,
  payload: error,
});
