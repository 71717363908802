import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import DateSelector from '../../../components/DateSelector';
import Dropdown from '../../../components/Dropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import Textbox from '../../../components/Textbox';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import {
  getDateRangeValue,
  getErrorMessage,
  isAdmin,
  isClient,
  issuePriorityArray,
  issueStatusArray,
  issueTypeArray,
} from '../../../utils/appUtils';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { CircularProgress } from '@material-ui/core';
import { fetchDropdownJobList } from '../../../redux/actions/jobActions/jobActions/dropdownJobList';
import moment from 'moment';
import { useAlert } from 'react-alert';
import FormSwitch from '../../../components/Switch';
import IssuesNewFilter from './IssuesNewFilter';
import { fetchVendorDropDownList } from '../../../redux/actions/adminActions/vendorDropdownList';
import { dateRangeArray } from '../../../utils/appUtils';
import DateTimeContainer from '../../../components/DateTimeContainer';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { fetchDropdownPropertyList } from '../../../redux/actions/commonActions/dropdownPropertyList';
import { Stack } from '@mui/material';
import ReactQuillTextbox from '../../../components/ReactQuillTextbox';
import { fetchClientAllProperty } from '../../../redux/actions/clientActions/getClientAllProperty';

const IssuesFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [technicianList, setTechnicianList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [vendorCategoryList, setVendorCategoryList] = useState([]);
  const [dropdownPropertyList, setDropdownPropertyList] = useState([]);
  const [openNewIssuesFilterDialog, setOpenNewIssuesFilterDialog] =
    useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props.filterData) {
      const {
        issueTitle,
        dueDate,
        startDate,
        endDate,
        type,
        priority,
        status,
        client,
        job,
        description,
        suppliesNeeded,
        adminAssigned,
        showAllIssue,
      } = props.filterData;
      if (isAdmin()) setFieldValue('showAllIssue', showAllIssue || false);
      setFieldValue('issueTitle', issueTitle || '');
      // setFieldValue('dueDate', dueDate || null);
      setFieldValue('startDate', startDate || null);
      setFieldValue('endDate', endDate || null);
      // setFieldValue('type', type || []);
      // setFieldValue('priority', priority || []);
      // setFieldValue('status', status || []);
      // setFieldValue('client', client || []);
      setFieldValue('adminAssigned', adminAssigned || []);
      // setFieldValue('job', job || []);
      setFieldValue('description', description || '');
      setFieldValue('suppliesNeeded', suppliesNeeded || '');
    }
    props.fetchDropdownJobList((err) => onError(err));
    if (!isClient()) {
      props.fetchUserListByType('client', (err) => onError(err));
      props.fetchUserListByType('admin', (err) => onError(err));
      // props.fetchUserListByType('technician', (err) => onError(err));
      props.fetchDropdownPropertyList(onError);
    }
    if (isClient()) props.fetchClientAllProperty((err) => onError(err));
    props.fetchVendorDropDownList((err) => onError(err));
    props.fetchCategoryList('vendor', (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.categoryListData ? props.categoryListData : [];
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.categoryName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorCategoryList(newData);
  }, [props.categoryListData]);

  useEffect(() => {
    if (isClient() && props?.clientAllPropertyData) {
      const data = props.clientAllPropertyData
        ? props.clientAllPropertyData
        : [];
      const newData = data.map((item) => ({
        title: item.name,
        value: item._id,
      }));
      if (newData && newData?.length === 1)
        setFieldValue('property', [newData[0]]);
      setDropdownPropertyList(newData);
    } else {
      const data = props.dropdownPropertyListData
        ? props.dropdownPropertyListData
        : [];
      const newData = data.map((item) => ({
        title: item.name,
        value: item._id,
      }));
      setDropdownPropertyList(newData);
    }
  }, [props.dropdownPropertyListData, props.clientAllPropertyData]);

  // useEffect(() => {
  //   if (props.filterData) {
  //     const { assignedTo } = props.filterData;
  //     if (assignedTo)
  //       setFieldValue(
  //         'assignedTo',
  //         technicianList?.filter((item) =>
  //           assignedTo?.some((x) => x === item.value)
  //         ) || []
  //       );
  //   }
  // }, [technicianList]);

  useEffect(() => {
    if (!isClient()) {
      const data = props.UserListByTypeData ? props.UserListByTypeData : {};
      const newData =
        data?.data?.map((item) => {
          const tempData = {
            title:
              data?.type === 'client'
                ? item.lastName + ', ' + item.firstName
                : item.name,
            value: item._id,
            image: item?.profilePicture,
          };
          // if (data?.type === 'technician')
          //   tempData.image = item?.profilePicture;
          return tempData;
        }) || [];
      if (data?.type === 'admin') setAdminList(newData);
      if (data?.type === 'technician') setTechnicianList(newData);
      if (data?.type === 'client') setClientList(newData);
    }
  }, [props.UserListByTypeData]);

  useEffect(() => {
    const data = props.JobListData ? props.JobListData : [];
    const newData =
      data?.map((item) => ({
        value: item._id,
        title: item?.title,
      })) || [];
    setJobList(newData);
  }, [props.JobListData]);

  useEffect(() => {
    const data = props.vendorDropDownListData
      ? props.vendorDropDownListData
      : [];
    const newData =
      data.map((item) => {
        const tempData = {
          title: item.fullName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorList(newData);
  }, [props.vendorDropDownListData]);

  useEffect(() => {
    if (props.filterData) {
      const { vendor } = props.filterData;
      if (vendor) setFieldValue('vendor', vendor || '');
    }
  }, [vendorList]);

  useEffect(() => {
    if (props.filterData) {
      const {
        type,
        priority,
        status,
        client,
        vendor,
        job,
        dateRange,
        category,
        property,
        adminAssigned,
      } = props.filterData;
      const newType = issueTypeArray?.filter((item) =>
        type?.some((x) => x === item.value)
      );
      const newPriority = issuePriorityArray?.filter((item) =>
        priority?.some((x) => x === item.value)
      );
      const newStatus = issueStatusArray?.filter((item) =>
        status?.some((x) => x === item.value)
      );
      const newClient = clientList?.filter((item) =>
        client?.some((x) => x === item.value)
      );
      const newProperties = dropdownPropertyList?.filter((item) =>
        property?.some((x) => x === item.value)
      );
      const newVendor = vendorList?.filter((item) =>
        vendor?.some((x) => x === item.value)
      );
      const newJob = jobList?.filter((item) =>
        job?.some((x) => x === item.value)
      );
      const newCategory = vendorCategoryList?.filter((item) =>
        category?.some((x) => x === item.value)
      );
      const newAdmins = adminList?.filter((item) =>
        adminAssigned?.some((x) => x === item.value)
      );
      if (type?.length) setFieldValue('type', newType);
      if (priority?.length) setFieldValue('priority', newPriority);
      if (status?.length) setFieldValue('status', newStatus);
      if (client?.length) setFieldValue('client', newClient);
      if (property?.length) setFieldValue('property', newProperties || []);
      if (vendor?.length) setFieldValue('vendor', newVendor);
      if (category?.length) setFieldValue('category', newCategory);
      if (job?.length) setFieldValue('job', newJob);
      if (dateRange)
        setFieldValue('selectedDateRange', dateRange || 'All Time');
      if (adminAssigned) setFieldValue('adminAssigned', newAdmins || []);
    }
  }, [
    adminList,
    clientList,
    vendorList,
    jobList,
    vendorCategoryList,
    dropdownPropertyList,
  ]);

  const formik = useFormik({
    initialValues: {
      issueTitle: '',
      // dueDate: null,
      selectedDateRange: 'All Time',
      startDate: null,
      endDate: null,
      type: [],
      priority: [],
      status: [],
      // assignedTo: [],
      client: [],
      property: [],
      vendor: [],
      job: [],
      description: '',
      suppliesNeeded: '',
      category: [],
      adminAssigned: [],
      showAllIssue: false,
    },
    // validationSchema: AddJobSchema,

    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const handleFilter = async (values) => {
    const data = {};
    if (values.issueTitle) data.issueTitle = values.issueTitle;
    // if (values.dueDate)
    //   data.dueDate = moment(values.dueDate).format('YYYY-MM-DD');
    if (values.type?.length > 0) data.type = values.type?.map((el) => el.value);
    if (values.category?.length > 0)
      data.category = values.category?.map((el) => el.value);
    if (values.status?.length > 0)
      data.status = values.status?.map((el) => el.value);
    if (values.priority?.length > 0)
      data.priority = values.priority?.map((el) => el.value);
    if (values.adminAssigned?.length > 0)
      data.adminAssigned = values.adminAssigned?.map((el) => el.value);
    // if (values.assignedTo.length > 0)
    //   data.assignedTo = values.assignedTo.map((item) => item.value);
    if (values.client?.length > 0)
      data.client = values.client?.map((el) => el.value);
    if (values.property?.length > 0)
      data.property = values?.property?.map((el) => el.value);
    if (values.vendor?.length > 0)
      data.vendor = values.vendor?.map((el) => el.value);
    if (values.job?.length > 0) data.job = values.job?.map((el) => el.value);
    if (values.description) data.description = values.description;
    if (values.suppliesNeeded) data.suppliesNeeded = values.suppliesNeeded;
    if (isAdmin()) data.showAllIssue = values.showAllIssue;
    if (values.selectedDateRange !== 'All Time') {
      const dateRange = await getDateRangeValue(values.selectedDateRange);
      data.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
      data.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    } else {
      if (values.startDate)
        data.startDate = moment(values.startDate).format('YYYY-MM-DD');
      if (values.endDate)
        data.endDate = moment(values.endDate).format('YYYY-MM-DD');
    }
    data.dateRange = values?.selectedDateRange;
    props.setFilterData(data);
    props.onClose();
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader title={Strings.FILTER_BY} onNewClose={props.onClose} />
        {props.UserListByTypeLoading ||
        props.JobListLoading ||
        props.categoryListLoading ||
        props.dropdownPropertyListDataLoading ||
        props.clientAllPropertyLoading ||
        props.vendorDropDownListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='issueTitle'
                  onChange={handleChange('issueTitle')}
                  value={values.issueTitle}
                  label={Strings.ISSUE_TITLE}
                  // error={Boolean(touched.issueTitle && errors.issueTitle)}
                  // helperText={touched.issueTitle && errors.issueTitle}
                  placeholder={Strings.ENTER_TITLE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                {!isClient() && (
                  <Dropdown
                    name='selectedDateRange'
                    onChange={(e) => {
                      if (e.target.value === 'All Time') {
                        setFieldValue('startDate', null);
                        setFieldValue('endDate', null);
                      }
                      setFieldValue('selectedDateRange', e.target.value);
                    }}
                    value={values.selectedDateRange}
                    label={Strings.SELECT_DATE_RANGE}
                    title={Strings.SELECT_DATE_RANGE}
                    labelClassName={classes.form_label}
                    containerClassName={classes.form_input_container}
                    optionArray={dateRangeArray}
                    placeholder={Strings.SELECT_DATE_RANGE}
                  />
                )}
                {!isClient() && values.selectedDateRange === 'All Time' && (
                  <DateTimeContainer
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='startDate'
                      onChange={(value) => setFieldValue('startDate', value)}
                      value={values.startDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.START_DATE}
                    />
                    <DateSelector
                      name='endDate'
                      onChange={(value) => setFieldValue('endDate', value)}
                      value={values.endDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      label={Strings.END_DATE}
                    />
                  </DateTimeContainer>
                )}
                {/* {!isClient() && <DateSelector
                  name='dueDate'
                  label={Strings.DUE_DATE}
                  onChange={(value) => setFieldValue('dueDate', value)}
                  // onChange={handleChange('dueDate')}
                  value={values.dueDate}
                  // error={Boolean(touched.dueDate && errors.dueDate)}
                  // helperText={touched.dueDate && errors.dueDate}
                  containerClassName={classes.form_input_container}
                  format='MMM dd, yyyy'
                  placeholder={Strings.SAMPLE_DATE}
                />} */}
                <MultiSelectDropdown
                  name='type'
                  onChange={(value) => setFieldValue('type', value)}
                  value={values.type}
                  label={Strings.TYPE}
                  // error={Boolean(touched.type && errors.type)}
                  // helperText={touched.type && errors.type}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueTypeArray}
                  hideAvatar
                />

                <MultiSelectDropdown
                  name='category'
                  onChange={(value) => setFieldValue('category', value)}
                  value={values.category}
                  label={Strings.CATEGORY}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={vendorCategoryList}
                  hideAvatar
                />

                <MultiSelectDropdown
                  name='priority'
                  onChange={(value) => setFieldValue('priority', value)}
                  value={values.priority}
                  label={Strings.PRIORITY}
                  // error={Boolean(touched.priority && errors.priority)}
                  // helperText={touched.priority && errors.priority}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issuePriorityArray}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='status'
                  onChange={(value) => setFieldValue('status', value)}
                  value={values.status}
                  label={Strings.STATUS}
                  // error={Boolean(touched.status && errors.status)}
                  // helperText={touched.status && errors.status}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={issueStatusArray}
                  hideAvatar
                />
                {!isClient() && (
                  <>
                    {!isAdmin() && (
                      <MultiSelectDropdown
                        name='adminAssigned'
                        onChange={(value) =>
                          setFieldValue('adminAssigned', value)
                        }
                        value={values.adminAssigned}
                        label={Strings.ADMIN_ASSIGNED}
                        // error={Boolean(touched.admin && errors.admin)}
                        // helperText={touched.admin && errors.admin}
                        title={Strings.TYPE_TO_SEARCH}
                        labelClassName={classes.form_label}
                        // className={classes.form_input}
                        containerClassName={classes.form_input_container}
                        optionArray={adminList}
                        searchable
                      />
                    )}
                    {/* <MultiSelectDropdown
                      name='assignedTo'
                      onChange={(value) => setFieldValue('assignedTo', value)}
                      value={values.assignedTo}
                      label={Strings.ASSIGN_TO}
                      title={Strings.TYPE_TO_SEARCH}
                      // error={Boolean(touched.receipient && errors.receipient)}
                      // helperText={touched.receipient && errors.receipient}
                      placeholder={Strings.TYPE_TO_SEARCH}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      optionArray={technicianList}
                    /> */}
                    <MultiSelectDropdown
                      name='client'
                      onChange={(value) => setFieldValue('client', value)}
                      value={values.client}
                      label={Strings.CLIENT}
                      // error={Boolean(touched.client && errors.client)}
                      // helperText={touched.client && errors.client}
                      title={Strings.TYPE_TO_SEARCH_CLIENT}
                      labelClassName={classes.form_label}
                      // className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      //   optionArray={technicianList}
                      searchable
                      optionArray={clientList}
                    />
                  </>
                )}

                <MultiSelectDropdown
                  name='property'
                  onChange={(value) => setFieldValue('property', value)}
                  value={values.property}
                  label={Strings.PROPERTY}
                  // error={Boolean(touched.client && errors.client)}
                  // helperText={touched.client && errors.client}
                  title={Strings.TYPE_TO_SEARCH + ' the property'}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={dropdownPropertyList}
                  searchable
                />

                <MultiSelectDropdown
                  name='vendor'
                  onChange={(value) => setFieldValue('vendor', value)}
                  value={values.vendor}
                  label={Strings.VENDOR}
                  // error={Boolean(touched.vendor && errors.vendor)}
                  // helperText={touched.vendor && errors.vendor}
                  title={Strings.TYPE_TO_SEARCH_VENDOR}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  searchable
                  optionArray={vendorList}
                  hideAvatar
                />
                <MultiSelectDropdown
                  name='job'
                  onChange={(value) => setFieldValue('job', value)}
                  value={values.job}
                  label={Strings.JOB}
                  // error={Boolean(touched.job && errors.job)}
                  // helperText={touched.job && errors.job}
                  title={Strings.TYPE_TO_SEARCH_JOB}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobList}
                  searchable
                  hideAvatar
                />
                {!isClient() && (
                  <>
                    {/* <Textbox
                      name='description'
                      onChange={handleChange('description')}
                      value={values.description}
                      label={Strings.ISSUE_SUMMARY}
                      // error={Boolean(touched.description && errors.description)}
                      // helperText={touched.description && errors.description}
                      placeholder={Strings.TYPE_HERE}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                    /> */}
                    <Stack mt={1}>
                      <ReactQuillTextbox
                        id='issueSummary'
                        onChange={handleChange('description')}
                        value={values.description}
                        label={Strings.ISSUE_SUMMARY}
                      />
                    </Stack>
                    <Textbox
                      name='suppliesNeeded'
                      onChange={handleChange('suppliesNeeded')}
                      value={values.suppliesNeeded}
                      label={Strings.SUPPLIES_NEEDED}
                      // error={Boolean(touched.suppliesNeeded && errors.suppliesNeeded)}
                      // helperText={touched.suppliesNeeded && errors.suppliesNeeded}
                      placeholder={Strings.TYPE_HERE}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                    />
                    {/* <Stack mt={1}>
                      <ReactQuillTextbox
                        name='suppliesNeeded'
                        onChange={handleChange('suppliesNeeded')}
                        value={values.suppliesNeeded}
                        label={Strings.SUPPLIES_NEEDED}
                      />
                    </Stack> */}
                  </>
                )}
                {isAdmin() && (
                  <FormSwitch
                    label={Strings.SHOW_ALL_ISSUE}
                    value={values.showAllIssue}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('showAllIssue', !values.showAllIssue)
                    }
                  />
                )}
                <div
                  className={classes.flexView}
                  style={{ marginTop: 0, gap: 15 }}
                >
                  <Button
                    type='button'
                    text={Strings.SAVE_FILTER}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.save_filter_button}
                    containerClassName={classes.button_container}
                    loaderColor={theme.palette.background_button_text}
                    onClick={() => setOpenNewIssuesFilterDialog(true)}
                  />
                  <Button
                    type='submit'
                    text={Strings.FILTER_NOW}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.add_button}
                    containerClassName={classes.button_container}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>
                <Button
                  type='button'
                  text={Strings.CLEAR_FILTERS}
                  onClick={() => {
                    resetForm();
                    props.setFilterData(
                      isAdmin() ? { showAllIssue: false } : {}
                    );
                    props.onClose();
                  }}
                  // loading={props.addVendorLoading}
                  // disabled={props.addVendorLoading}
                  className={classes.secondary_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
      {openNewIssuesFilterDialog && (
        <IssuesNewFilter
          setIsFilterChange={props?.setIsFilterChange}
          filterDialogOnClose={() => props.onClose()}
          onClose={() => setOpenNewIssuesFilterDialog(false)}
          dashboardFilter={props.dashboardFilter}
          selectedData={values}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  JobListData: state.dropdownListJobData.data,
  JobListLoading: state.dropdownListJobData.loading,

  vendorDropDownListData: state.vendorDropDownListData.data,
  vendorDropDownListLoading: state.vendorDropDownListData.loading,

  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  dropdownPropertyListData: state.dropdownPropertyListData.data,
  dropdownPropertyListDataLoading: state.dropdownPropertyListData.loading,

  clientAllPropertyData: state.clientAllPropertyData.data,
  clientAllPropertyLoading: state.clientAllPropertyData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchDropdownJobList,
  fetchVendorDropDownList,
  fetchCategoryList,
  fetchDropdownPropertyList,
  fetchClientAllProperty,
})(IssuesFilter);
