import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Grid, Stack, useMediaQuery } from '@mui/material';
import TableToolbox from '../../components/TableToolbox';
import MediaImageContainer from '../../components/MediaImageContainer';
import {
  getClientUserId,
  getErrorMessage,
  isClient,
} from '../../utils/appUtils';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../redux/actions/adminActions/getUserListByType';
import { useAlert } from 'react-alert';
import { Strings } from '../../utils/strings';
import TitleContainer from '../../components/TitleContainer';
import { fetchMediaList } from '../../redux/actions/mediaActions/mediaList';
import moment from 'moment';
import ManageTagsDialog from '../../components/ManageTagsDialog';
import { icons } from '../../utils/icons';
import { fetchMediaListFolder } from '../../redux/actions/mediaActions/mediaListFolder';
import { fetchMediaFolderContent } from '../../redux/actions/mediaActions/getMediaFolderContent';
import { updateFolderName } from '../../redux/actions/mediaActions/updateFolderName';
import Textbox from '../../components/Textbox';
import { createFolder } from '../../redux/actions/mediaActions/createFolder';
import { uploadFolderMedia } from '../../redux/actions/mediaActions/uploadFolderMedia';
import { useLongPress } from 'use-long-press';
import { copyMedia } from '../../redux/actions/mediaActions/copyMedia';
import debounce from 'lodash/debounce';
import { fetchTagsList } from '../../redux/actions/mediaActions/tagsList';
import {
  searchMedia,
  clearSearchMedia,
} from '../../redux/actions/mediaActions/searchMedia';
import { useDispatch } from 'react-redux';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import CommonDialog from '../../components/CommonDialog';
import CommonPopover from '../../components/CommonPopover';
import { deleteFolder } from '../../redux/actions/mediaActions/deleteFolder';
import { deleteMedia } from '../../redux/actions/mediaActions/deleteMedia';

const Media = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const laptopView = useMediaQuery(theme.breakpoints.down('lg'));
  const [search, setSearch] = useState('');
  // const [date, setDate] = useState(null);
  // const [client, setClient] = useState('all');
  const [showManageTags, setShowManageTags] = useState(false);
  // const [mediaData, setMediaData] = useState([]);
  // const [filterDate, setFilterDate] = useState('');
  const [selectedImageArray, setSelectedImageArray] = useState([]);
  // const [selectedImageArrayFiltered, setSelectedImageArrayFiltered] = useState(
  //   []
  // );
  const [mediaListFolder, setMediaListFolder] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [mediaCrumbList, setMediaCrumbList] = useState([{ name: 'Media' }]);
  const [allFilesData, setAllFilesData] = useState([]);
  const [hideFolder, setHideFolder] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [folderName, setFolderName] = useState('');
  const [isShowAddFolder, setIsShowAddFolder] = useState(false);
  const [isPasteItems, setIsPasteItems] = useState(false);
  const [isShowUploadAttachment, setIsShowUploadAttachment] = useState(false);
  const folderNameInputRef = useRef(null);
  const addAttachmentRef = useRef(null);
  const [enabled, setEnabled] = useState(true);
  const [isLongPress, setIsLongPress] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [folderListByClientName, setFolderListByClientName] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectDeleteFolderId, setSelectDeleteFolderId] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState('');

  const callback = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isLongPress && !isClient()) {
      setIsLongPress(true);
    }
  }, []);

  const bind = useLongPress(enabled ? callback : null, {
    onStart: (event) => {
      // event.stopPropagation();
      // console.log('Press started');
    },
    onFinish: (event) => {
      // event.preventDefault();
      // event.stopPropagation();
      // console.log('Long press finished');
    },
    onCancel: (event) => {
      // event.preventDefault();
      // event.stopPropagation();
      // console.log('Press cancelled');
    },
    onMove: (event) => {
      // event.preventDefault();
      // event.stopPropagation();
      // window.alert('Detected mouse or touch movement');
    },
    filterEvents: (event) => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 700, // In milliseconds
    captureEvent: true, // Event won't get cleared after React finish processing it
    cancelOnMovement: false, // 25 Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
    detect: 'pointer', // Default option
  });

  const clearSelectedIds = async () => {
    await setIsLongPress(false);
    await setSelectedIds([]);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    return () => {
      setMediaListFolder([]);
      setImageArray([]);
      dispatch(clearSearchMedia([]));
    };
  }, []);

  useEffect(() => {
    if (selectedIds.length === 0) {
      setIsLongPress(false);
    }
  }, [selectedIds]);

  const getMediaListFolder = () => {
    props.fetchMediaListFolder(onError);
  };

  const getFolderContent = (folderId, setData) => {
    props.fetchMediaFolderContent(folderId, onError, setData);
    setFolderListByClientName(false);
  };

  useEffect(() => {
    if (currentFolderId) getFolderContent(currentFolderId);
  }, [props.updateFileDetailsData]);

  const getMediaList = (data) => {
    props.fetchMediaList(data, (err) => onError(err));
  };

  const changeFolderName = (fID, data) => {
    props.updateFolderName(fID, data, onError);
  };

  const getTags = () => {
    props.fetchTagsList(onError);
  };

  useEffect(() => {
    if (props?.tagsListData.length > 0) {
      const newList = props?.tagsListData?.map((item) => {
        const tempData = {
          title: item.name,
          value: item._id,
        };
        return tempData;
      });
      setTagsList(newList);
    }
  }, [props.tagsListData]);

  const getClientMediaList = () => {
    const clientUserId = getClientUserId();
    if (clientUserId !== '') {
      const data = { clientId: clientUserId };
      getMediaList(data);
    }
  };

  const clearFields = () => {
    setMediaListFolder([]);
    setSelectedImageArray([]);
    setSelectedTags([]);
    // setSelectedImageArrayFiltered([]);
    setIsShowAddFolder(false);
    setIsShowUploadAttachment(false);
    setIsPasteItems(false);
  };

  useEffect(() => {
    getMediaListFolder();
    getTags();
  }, []);

  useEffect(() => {
    let data = props.mediaListFolderData || [];
    if (data?.length > 0) {
      setMediaListFolder(data);
    }
  }, [props.mediaListFolderData]);

  const setImageArray = (data) => {
    setSelectedImageArray(data);
    // setSelectedImageArrayFiltered(data);
  };
  useEffect(() => {
    let data = props.mediaFolderContentData || {};
    const lastElement = mediaCrumbList[mediaCrumbList?.length - 1];
    if (Object?.keys(data)?.length > 0 && lastElement?.name !== 'Media') {
      setMediaListFolder(data.childrens);
      setImageArray(data.files);
      setIsShowAddFolder(data?.canCreateFolder || false);
      setIsShowUploadAttachment(data?.canUpload || false);
      setIsPasteItems(data?.canPaste || false);
    }
  }, [props.mediaFolderContentData]);

  useEffect(() => {
    if (
      Object?.keys(props.searchMediaData)?.length > 0 &&
      (selectedTags.length > 0 || search !== '')
    ) {
      setImageArray(props.searchMediaData);
      // setMediaListFolder([]);
      // setIsShowAddFolder(false);
      // setIsShowUploadAttachment(false);
      // setIsPasteItems(false);
    }
  }, [props.searchMediaData]);

  const folderReName = async (event, type, folderId, oldValue, value) => {
    if ((event.key === 'Enter' && type === 'keyPress') || type === 'onBlur') {
      let data = mediaListFolder?.map((elm) => {
        if (elm?._id === folderId && value !== oldValue) {
          elm.name = value;
        }
        return elm;
      });
      if (oldValue !== value) {
        changeFolderName(folderId, { name: value });
      }
      await setMediaListFolder(data);
      await setSelectedFolderId('');
      await setFolderName('');
    }
  };

  const createFolder = () => {
    if (selectedFolder) {
      let data = {
        name: 'New Folder',
        parentId: selectedFolder,
      };
      props.createFolder(data, onError, (data) => {
        setHideFolder(false);
        setMediaListFolder([...mediaListFolder, data]);
      });
    }
  };

  const uploadAttachmentFiles = (files) => {
    let newFiles = files.target.files;
    const data = {
      folderId: selectedFolder,
      files: newFiles ? [...newFiles] : [],
    };
    if (newFiles?.length > 0) {
      props.uploadFolderMedia(
        data,
        (value) => {
          setImageArray([...selectedImageArray, ...value]);
        },
        onError
      );
    }
  };

  const onBreadCrumbClick = (objData) => {
    const el = objData;
    const lastElement = mediaCrumbList[mediaCrumbList?.length - 1];
    const fIndex = mediaCrumbList?.findIndex((ele) => ele.id === objData.id);
    mediaCrumbList.length = fIndex + 1;
    setMediaCrumbList(mediaCrumbList);
    setSelectedFolder(el?.name === 'Media' ? '' : el?.id);
    if (el?.name === 'Media' && el?.name !== lastElement?.name) {
      setFolderListByClientName(true);
      setAllFilesData([]);
      setIsShowAddFolder(false);
      setIsShowUploadAttachment(false);
      getMediaListFolder();
      clearFields();
      setCurrentFolderId('');
    } else if (el?.name !== 'Media' && lastElement?.id !== el.id) {
      setCurrentFolderId(el?.id);
      getFolderContent(el?.id);
      clearFields();
    }
  };

  const onBackClick = () => {
    clearFields();
    let data = [...mediaCrumbList];
    data.pop();
    setMediaCrumbList(data);
    if (data.length === 1) {
      getMediaListFolder();
      setCurrentFolderId('');
      setFolderListByClientName(true);
    } else {
      const lastElement = data[data?.length - 1];
      setCurrentFolderId(lastElement?.id);
      getFolderContent(lastElement?.id);
    }
  };

  const onClickPaste = () => {
    let lastBreadcrumb = mediaCrumbList[mediaCrumbList?.length - 1];
    const data = {
      folderId: lastBreadcrumb?.id,
      media: selectedIds,
    };
    props.copyMedia(data, onError, () => {
      clearSelectedIds();
      getFolderContent(lastBreadcrumb?.id);
    });
  };

  const delayedSearch = useCallback(
    debounce(async (search, tags, id) => {
      if (tags.length > 0 || search !== '') {
        const data = {
          // tags: tags,
          searchString: search,
        };
        if (tags.length > 0) data.tags = tags;
        if (id) data.folderId = id;
        props.searchMedia(data, onError);
      } else {
        if (id) {
          setTimeout(() => {
            getFolderContent(id);
          }, 500);
        } else {
          setTimeout(() => {
            getMediaListFolder();
          }, 500);
        }
      }
      dispatch(clearSearchMedia([]));
      setImageArray([]);
    }, 500),
    []
  );

  const delayedSearchSelection = useCallback(
    debounce(async (tags, id, search) => {
      if (tags.length > 0 || search !== '') {
        const data = {
          tags: tags,
        };
        if (search) data.searchString = search;
        if (id) data.folderId = id;
        props.searchMedia(data, onError);
      } else {
        if (id) {
          setTimeout(() => {
            getFolderContent(id);
          }, 500);
        } else {
          setTimeout(() => {
            getMediaListFolder();
          }, 500);
        }
      }
      dispatch(clearSearchMedia([]));
      setImageArray([]);
    }, 700),
    []
  );

  const handleDblClick = async (el) => {
    clearFields();
    let data = {
      id: el?._id,
      name: el?.name || el?.client?.lastName,
    };
    setMediaCrumbList([...mediaCrumbList, data]);
    setCurrentFolderId(el?._id);
    await setSelectedFolder(el?._id);
    await getFolderContent(el?._id);
    setSearch('');
  };

  const sortArrayByClientLastName = useMemo(() => {
    let data = JSON.parse(JSON.stringify(mediaListFolder));
    if (folderListByClientName && !isClient()) {
      data.sort((a, b) => {
        const lastNameA = a?.client?.lastName?.toLowerCase();
        const lastNameB = b?.client?.lastName?.toLowerCase();
        if (lastNameA < lastNameB) return -1;
        if (lastNameA > lastNameB) return 1;
        return 0;
      });
    }
    return data;
  }, [folderListByClientName, mediaListFolder]);

  const handleFolderAction = (type, folderId) => {
    if (type === 'delete') {
      setSelectDeleteFolderId(folderId);
      setOpenConfirmDialog(true);
    }
  };

  const handleDelete = (type) => {
    if (type && type === 'media') {
      props?.deleteMedia(
        selectedFile,
        currentFolderId,
        () => {
          setSelectDeleteFolderId('');
          setOpenConfirmDialog(false);
          getFolderContent(currentFolderId);
        },
        onError
      );
    } else {
      props?.deleteFolder(
        selectDeleteFolderId,
        () => {
          setSelectDeleteFolderId('');
          setOpenConfirmDialog(false);
          getFolderContent(currentFolderId);
        },
        onError
      );
    }
  };

  return (
    <div className={classes.media_container}>
      <TitleContainer
        containerClassName={classes.title_container}
        title={Strings.MEDIA}
      />
      <TableToolbox
        isSearch
        search={search}
        onChangeSearch={(e) => {
          const lastElement = mediaCrumbList[mediaCrumbList?.length - 1];
          let tags = selectedTags.map((item) => item.title);
          delayedSearch(e.target.value, tags, lastElement.id);
          setSearch(e.target.value);
        }}
        isSearchSelection={tagsList?.length > 0}
        searchSelectionTitle={Strings.SEARCH_BY_TAGS}
        searchSelectionsOptions={tagsList}
        searchSelectionLabelContainer={classes.searchSelectionLabelContainer}
        onChangeSearchSelection={(e) => {
          setSelectedTags(e);
          const lastElement = mediaCrumbList[mediaCrumbList?.length - 1];
          let tags = e.map((item) => item.title);
          delayedSearchSelection(tags, lastElement.id, search);

          // if (e.length > 0) {
          // } else {
          //   const lastElement = mediaCrumbList[mediaCrumbList?.length - 1];
          //   if (lastElement?.id) {
          //     getFolderContent(lastElement?.id);
          //   } else {
          //     getMediaListFolder();
          //   }
          // }
        }}
        searchSelectionValue={selectedTags}
        isManageTags={!isClient()}
        onManageTagsClick={() => setShowManageTags(true)}
        isAddFolder={isShowAddFolder && !isClient()}
        onAddFolderClick={() => createFolder()}
        addFolderLoading={props.createFolderLoading}
        isPasteBtn={isPasteItems && selectedIds.length > 0}
        selectedIds={selectedIds}
        onClickClearIds={clearSelectedIds}
        pasteLoading={props.copyMediaLoading}
        onClickPaste={onClickPaste}
      />
      <div className={classes.bread_crumb_container}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {mediaCrumbList?.length > 1 && (
            <img
              src={icons(theme).backIcon}
              className={classes.backIcon}
              onClick={onBackClick}
            />
          )}
          <div className={classes.folderPath_container}>
            {mediaCrumbList?.map((el, index) => (
              <label
                key={index}
                className={classes.media_path_2}
                onClick={() => onBreadCrumbClick(el)}
              >
                {`${el?.name} ${
                  mediaCrumbList?.length - 1 !== index ? '/ ' : ''
                }`}
              </label>
            ))}
          </div>
        </div>
        {isShowUploadAttachment && !isClient() && (
          <div
            className={classes.addAttachment_container}
            onClick={() =>
              !props.uploadFolderMediaLoading &&
              addAttachmentRef.current.click()
            }
          >
            <input
              style={{ display: 'none' }}
              ref={addAttachmentRef}
              onChange={(e) => uploadAttachmentFiles(e)}
              type='file'
              accept='*'
              multiple
              disabled={false}
            />
            {props?.uploadFolderMediaLoading ? (
              <CircularProgress
                style={{ color: theme.palette.background_button }}
                size={20}
              />
            ) : (
              <>
                <img src={icons(theme).attachmentIcon} />
                <label className={classes.common_label}>
                  {Strings.ADD_ATTACHMENTS}
                </label>
              </>
            )}
          </div>
        )}
      </div>
      {props.mediaListFolderLoading ||
      props.searchMediaLoading ||
      props.mediaFolderContentLoading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : selectedFolder &&
        mediaListFolder?.length === 0 &&
        selectedImageArray?.length === 0 ? (
        <div className={classes.flexView}>
          <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
        </div>
      ) : (
        <div className={classes.media_view_container}>
          <Grid
            container
            spacing={mobileView ? 1.9 : 2.5}
            style={{
              marginTop: mobileView ? 0 : -15,
              marginBottom: selectedImageArray ? 15 : 0,
            }}
          >
            {folderListByClientName && !isClient() ? (
              <Grid item xs={12}>
                <Stack width='100%' gap={2.5}>
                  {sortArrayByClientLastName?.map((el, _ind) => (
                    <CustomizedTooltips
                      title={el?.name || el?.client?.lastName}
                      key={_ind}
                    >
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap={2}
                        style={{ cursor: 'pointer' }}
                        width='fit-content'
                        onDoubleClick={(e) => handleDblClick(el)}
                      >
                        <img
                          src={icons(theme).newFolderIcon}
                          alt='Media folder'
                          crossOrigin='Anonymous'
                          width={25}
                        />
                        <span className={classes.text14_bold}>
                          {el?.client?.lastName}
                        </span>
                      </Stack>
                    </CustomizedTooltips>
                  ))}
                </Stack>
              </Grid>
            ) : (
              sortArrayByClientLastName?.map((item, index) => (
                <Grid item xs={4} sm={2} md={1.5} lg={1} key={index}>
                  <CustomizedTooltips
                    title={item?.name || item?.client?.lastName}
                  >
                    <div className={classes.media_card}>
                      <div
                        className={classes.image_container}
                        onDoubleClick={(e) => handleDblClick(item)}
                      >
                        <img
                          src={icons(theme).newFolderIcon}
                          alt=''
                          className={classes.folder_image}
                          crossOrigin='Anonymous'
                        />
                        {item?.canDelete && !isClient() ? (
                          <div className={classes.action_btn}>
                            <CommonPopover
                              options={[{ label: 'Delete', value: 'delete' }]}
                              onSelectOption={(val) =>
                                handleFolderAction(val, item?._id)
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className={classes.image_text_container}>
                        {selectedFolderId === item?._id ? (
                          <Textbox
                            inputRef={folderNameInputRef}
                            name='details'
                            onChange={(e) => setFolderName(e.target.value)}
                            value={folderName}
                            className={classes.folder_input}
                            onKeyDown={(e) =>
                              folderReName(
                                e,
                                'keyPress',
                                item?._id,
                                item?.name,
                                folderName
                              )
                            }
                            onBlur={(e) =>
                              folderReName(
                                e,
                                'onBlur',
                                item?._id,
                                item?.name,
                                folderName
                              )
                            }
                          />
                        ) : (
                          <span
                            className={classes.text_title}
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (item?.canEdit) {
                                await setSelectedFolderId(item?._id);
                                await setFolderName(item?.name);
                                await folderNameInputRef.current.focus();
                              }
                            }}
                          >
                            {item?.name || item?.client?.lastName}
                          </span>
                        )}
                      </div>
                    </div>
                  </CustomizedTooltips>
                </Grid>
              ))
            )}
          </Grid>
          {selectedImageArray?.length > 0 && (
            <MediaImageContainer
              imageArray={
                selectedImageArray?.map((item) => {
                  return {
                    ...item,
                    title: item?.fileName,
                    createdBy: item?.addedBy?.name,
                    url: item?.url,
                    tags: item?.tags,
                  };
                }) || []
              }
              itemsPerRow={mobileView ? 2 : tabletView ? 3 : laptopView ? 4 : 5}
              showImageText
              {...bind()}
              isLongPress={isLongPress}
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
              setIsLongPress={setIsLongPress}
              isMediaSection={true}
              onClickDeleteMedia={(e, val) => {
                setOpenConfirmDialog(true);
                setSelectedFile(val?._id);
              }}
            />
          )}
        </div>
      )}
      {showManageTags ? (
        <ManageTagsDialog onClose={() => setShowManageTags(false)} />
      ) : null}
      {openConfirmDialog ? (
        <CommonDialog
          title={selectDeleteFolderId ? 'Folder' : 'Media'}
          message={`Are you sure you want to delete this ${
            selectDeleteFolderId ? 'folder' : 'media'
          }?`}
          onSuccess={() =>
            handleDelete(selectDeleteFolderId ? 'folder' : 'media')
          }
          onClose={() => {
            setSelectedFile('');
            setSelectDeleteFolderId('');
            setOpenConfirmDialog(false);
          }}
          loading={props.deleteFolderLoading || props?.deleteMediaLoading}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  mediaListData: state.mediaListData.data,
  mediaListLoading: state.mediaListData.loading,

  mediaListFolderData: state.mediaListFolderData.data,
  mediaListFolderLoading: state.mediaListFolderData.loading,

  mediaFolderContentData: state.mediaFolderContentData.data,
  mediaFolderContentLoading: state.mediaFolderContentData.loading,

  createFolderData: state.createFolderData.data,
  createFolderLoading: state.createFolderData.loading,

  uploadFolderMediaData: state.uploadFolderMediaData.data,
  uploadFolderMediaLoading: state.uploadFolderMediaData.loading,

  copyMediaLoading: state.copyMediaData.loading,

  updateFileDetailsData: state.updateFileDetailsData.data,

  tagsListData: state.tagsListData.data,
  tagsListLoading: state.tagsListData.loading,

  searchMediaData: state.searchMediaData.data,
  searchMediaLoading: state.searchMediaData.loading,

  deleteFolderLoading: state.deleteFolderData.loading,

  deleteMediaLoading: state.deleteMediaData.loading,
});

export default connect(mapStateToProps, {
  fetchUserListByType,
  fetchMediaList,
  fetchMediaListFolder,
  fetchMediaFolderContent,
  updateFolderName,
  createFolder,
  uploadFolderMedia,
  copyMedia,
  fetchTagsList,
  searchMedia,
  deleteFolder,
  deleteMedia,
})(Media);
