import axios from 'axios';
import { TEAM_DISCUSSION } from '../../constants';
import moment from 'moment-timezone';

export const fetchTeamDiscussion = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadTeamDiscussion());

  axios
    .get(
      `/admin/TeamDiscussion?currentDate=${moment().format(
        'YYYY-MM-DD'
      )}&timeZone=${moment.tz.guess()}`,
      config
    )
    .then((res) => dispatch(setTeamDiscussion(res)))
    .catch((err) => {
      dispatch(errorTeamDiscussion(err.response));
      onError(err.response);
    });
};

export const loadTeamDiscussion = () => ({
  type: TEAM_DISCUSSION.LOAD,
});

export const setTeamDiscussion = (userList) => ({
  type: TEAM_DISCUSSION.SUCCESS,
  payload: userList.data.data,
});

export const errorTeamDiscussion = (error) => ({
  type: TEAM_DISCUSSION.FAIL,
  payload: error,
});
