import axios from 'axios';
import setAuthToken from '../../../utils/setAuthToken';
import { LOGIN } from '../../constants';
import OneSignal from 'react-onesignal';

export const login =
  (loginData, onSuccess, onError, rememberMe) => (dispatch) => {
    dispatch(loadLogin());
    axios
      .post('auth/login', loginData)
      .then((res) => {
        const { token, name, profilePicture, lastName } = res.data.data;
        setAuthToken(token);
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userName', name);
        if (lastName) localStorage.setItem('lastName', lastName);
        if (profilePicture)
          localStorage.setItem('profilePicture', profilePicture);
        if (rememberMe) {
          localStorage.setItem('rememberMe', true);
          localStorage.setItem('email', loginData.email);
          localStorage.setItem('password', loginData.password);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        onSuccess();
        dispatch(setLogin(res.data.data));
      })
      .catch((err) => {
        dispatch(errorLogin(err.response));
        onError(err.response);
      });
  };

export const loadLogin = () => ({
  type: LOGIN.LOAD,
});

export const setLogin = (data) => ({
  type: LOGIN.SUCCESS,
  payload: data,
});

export const errorLogin = (error) => ({
  type: LOGIN.FAIL,
  payload: error,
});

export const logout = (navigate) => (dispatch) => {
  dispatch(errorLogin({}));
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('profilePicture');
  localStorage.removeItem('CalendarsFilter');
  localStorage.removeItem('ClientsData');
  localStorage.removeItem('IssuesData');
  localStorage.removeItem('JobsData');
  localStorage.removeItem('AnnouncementsData');
  localStorage.removeItem('TeamData');
  localStorage.removeItem('VendorData');
  localStorage.removeItem('DashboardIssuesFilter');
  localStorage.removeItem('AnnouncementsData');
  localStorage.removeItem('JobFormsData');
  localStorage.removeItem('CategoryData');
  localStorage.removeItem('ProgramTypeData');
  if (global?.socket?.connected) {
    global?.socket?.removeAllListeners('message.new');
    global?.socket?.removeAllListeners('room.new');
    global?.socket?.disconnect();
  }
  setAuthToken(false);
  // const OneSignal = window.OneSignal;
  OneSignal.setSubscription(false);
  dispatch(setLogin({}));
  if (navigate) navigate('/login');
};
