import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  issues_container: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      width: '100%',
      height: 'calc(100% - 95px)',
    },
  },
  issues_container_clientLogin: {
    width: 'calc(100% - 36px)',
    height: 'calc(100% - 100px)',
    backgroundColor: theme.palette.background,
    padding: '0px 18px 10px 18px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      width: '100%',
      height: 'calc(100% - 95px)',
    },
  },
  list_container: {
    width: '100%',
    margin: '30px 0px',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit',
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0px',
    },
  },
  list_container_client_job: {
    width: '100%',
    margin: '30px 0px',
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  name_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 600,
  },
  common_cell: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    color: theme.palette.profile_title,
    fontWeight: 400,
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    marginRight: 15,
    cursor: 'pointer',
  },
  action_icons: {
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  status_container: {
    backgroundColor: Colors.NEVY_BLUE,
    padding: '3px 12px;',
    borderRadius: 40,
    color: Colors.WHITE,
    textAlign: 'center',
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  status_container1: {
    backgroundColor: Colors.NEVY_BLUE,
    padding: '3px 12px;',
    borderRadius: 40,
    color: Colors.WHITE,
    textAlign: 'center',
    width: 'max-content',
  },
  status_active: {
    backgroundColor: Colors.LIGHT_GREEN,
  },
  status_inactive: {
    backgroundColor: Colors.DARK_GRAY,
  },
  priority_critical: {
    backgroundColor: Colors.TOMATO_RED,
  },
  priority_high: {
    backgroundColor: Colors.LIGHT_ORANGE,
  },
  action_visible: {
    display: 'none',
  },
  action: {
    display: 'block',
  },
  row_style: {
    '&:hover': {
      '& $action_visible': {
        display: 'block',
      },
    },
  },
  issue_details_container: {
    marginTop: 0,
  },
  issue_details_tab: {
    color: theme.palette.background_button,
  },
  note_data: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.profile_title,
    padding: '10px 0px 0px 0px',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    marginTop: 5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '& a': {
      color: theme.palette.background_button,
    }
  },
  note_details_container: {
    display: 'flex',
    // flexDirection: 'column',
    marginTop: 10,
    borderBottom: `1px solid ${theme.palette.background_border}`,
    gap: 15,
  },
  home_owner_directive: {
    whiteSpace: 'nowrap',
    width: 110,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title_container: {
    marginTop: 10,
    marginBottom: 15,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 14px',
    },
  },
  toolbox_container: {
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '0px 14px',
      width: 'auto !important',
      justifyContent: 'space-between',
      gap: 12,
    },
  },
  toolbox_container_clientLogin: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '0px 14px',
      width: 'auto !important',
      justifyContent: 'space-between',
      gap: 12,
      flex: 0,
    },
  },
  list_table: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 930,
    },
  },
  tab_container: {
    paddingLeft: 14,
    marginTop: 15,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchbox_container_1: {
    [theme.breakpoints.down('sm')]: {
      flex: '0  0 48% !important',
    },
  },
  relatedJob_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // borderBottom: '1px solid #E5E5E5'
  },
  button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    height: 28,
    fontSize: 12,
    minWidth: 'max-content'
  },
  redDot: {
    minHeight: 10,
    minWidth: 10,
    borderRadius: '100%',
    background: Colors.RED,
  },
  filteredTitleText: {
    color: theme.palette.background_button,
    fontSize: 12,
    fontWeight: 600,
    margin: '10px 0 0 0',
    textTransform: 'uppercase'
  }
}));
