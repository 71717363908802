import axios from 'axios';
import { VENDOR_DROP_DOWN_LIST } from '../../constants';

export const fetchVendorDropDownList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadVendorDropDownList());

  axios
    .get(`/vendor/dropdownList`, config)
    .then((res) => dispatch(setVendorDropDownList(res)))
    .catch((err) => {
      dispatch(errorVendorDropDownList(err.response));
      onError(err.response);
    });
};

export const loadVendorDropDownList = () => ({
  type: VENDOR_DROP_DOWN_LIST.LOAD,
});

export const setVendorDropDownList = (userList) => ({
  type: VENDOR_DROP_DOWN_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorVendorDropDownList = (error) => ({
  type: VENDOR_DROP_DOWN_LIST.FAIL,
  payload: error,
});
