import axios from 'axios';
import { MARK_ALL_ISSUE_NOTES_READ } from '../../../constants';

export const markAllIssuesNotesRead =
  (data, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadAllIssueNotesRead());
    axios
      .put(`/issue/markAllRead`, data, config)
      .then((res) => {
        dispatch(setAllIssueNotesRead(res));
        onSuccess?.(res.data.data);
      })
      .catch((err) => {
        dispatch(errorAllIssueNotesRead(err.response));
        onError(err.response);
      });
  };

export const loadAllIssueNotesRead = () => ({
  type: MARK_ALL_ISSUE_NOTES_READ.LOAD,
});

export const setAllIssueNotesRead = (data) => ({
  type: MARK_ALL_ISSUE_NOTES_READ.SUCCESS,
  payload: data.data,
});

export const errorAllIssueNotesRead = (error) => ({
  type: MARK_ALL_ISSUE_NOTES_READ.FAIL,
  payload: error,
});
