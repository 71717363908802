import React from 'react';

var ThemeStateContext = React.createContext();
var ThemeDispatchContext = React.createContext();

function ThemeReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_THEME':
      localStorage.setItem('mode', state.mode === 'light' ? 'dark' : 'light');
      return { ...state, mode: state.mode === 'light' ? 'dark' : 'light' };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ModeProvider({ children }) {
  var [state, dispatch] = React.useReducer(ThemeReducer, {
    mode: localStorage.getItem('mode') || 'light',
  });
  return (
    <ThemeStateContext.Provider value={state}>
      <ThemeDispatchContext.Provider value={dispatch}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
}

function useThemeState() {
  var context = React.useContext(ThemeStateContext);
  if (context === undefined) {
    throw new Error('useThemeState must be used within a ModeProvider');
  }
  return context;
}

function useThemeDispatch() {
  var context = React.useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error('useThemeDispatch must be used within a ModeProvider');
  }
  return context;
}

const toggleTheme = (dispatch) => {
  dispatch({
    type: 'TOGGLE_THEME',
  });
};

export {
  ModeProvider,
  useThemeState,
  useThemeDispatch,
  toggleTheme,
  ThemeStateContext,
};
