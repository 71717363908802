import { CircularProgress, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import './pdf.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Colors } from '../../utils/color';

const PdfViewer = (props) => {
  const { file, url, scale } = props;
  // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    // setPageNumber(1);
  };

  // const changePage = (offset) => {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // };

  // const previousPage = () => {
  //   changePage(-1);
  // };

  // const nextPage = () => {
  //   changePage(1);
  // };

  return (
    <Stack
      height={'100%'}
      sx={{
        overflowY: 'auto',
        overflowX: { xs: 'auto', md: 'hidden' },
      }}
    >
      <Document
        file={url || file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <CircularProgress
            style={{
              color: Colors.LIGHT_BLUE,
            }}
          />
        }
      >
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale ? scale : 1}
            loading={<div></div>}
          />
        ))}
      </Document>
      {/* <div>
        <div className='pagec'>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </div>
        <div className='buttonc'>
          <button
            type='button'
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className='Pre'
          >
            Previous
          </button>
          <button
            type='button'
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div> */}
    </Stack>
  );
};

export default PdfViewer;
