import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  textbox_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  textbox: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    padding: 13,
    fontSize: 14,
    borderRadius: 8,
    color: Colors.DARK_GRAY,
    fontFamily: "'Open Sans', sans-serif",
    outline: 'none',
    fontWeight: 400,
    '&:focus': {
      border: `1px solid ${theme.palette.background_button}`,
    },
    resize: 'none',
  },
  input_label: {
    marginBottom: 7,
    color: Colors.LIGHT_BLACK,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  input_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },
  right_icon: {
    width: 18,
    height: 18,
    position: 'absolute',
    right: 13,
  },
  left_icon: {
    width: 18,
    height: 18,
    position: 'absolute',
    left: 13,
  },
}));
