import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import EnhancedTableHead from '../../../components/DataTables/TableHead';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { Strings } from '../../../utils/strings';
import {
  PAGE_SIZE,
  applySortFilter,
  getComparator,
  getErrorMessage,
  getLocaleStorageVal,
  secondaryTableCell,
  setLocaleStorageVal,
  tableCell,
} from '../../../utils/appUtils';
import TableToolbox from '../../../components/TableToolbox';
import CustomizedTooltips from '../../../components/CustomizedTooltips';
import { useNavigate } from 'react-router-dom';
import NewJobForm from './NewJobForm';
import { connect, useDispatch } from 'react-redux';
import {
  fetchJobFormsList,
  setJobFormsList,
} from '../../../redux/actions/jobActions/jobActions/jobFormsList';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { icons } from '../../../utils/icons';
import CustomPagination from '../../../components/CustomPagination';
import CommonDialog from '../../../components/CommonDialog';
import { deleteJobForm } from '../../../redux/actions/jobActions/jobActions/deleteJobForm';

const jobFormDataStore = 'JobFormsData';

// ======== Table Head =========
const headCells = [
  { id: 'formName', label: 'TITLE', isSort: true },
  { id: 'createdAt', label: 'DATE', isSort: true },
  // { id: 'formCategory', label: 'TYPE', isSort: true },
  { id: 'formStatus', label: 'STATUS', isSort: true },
  { id: '' },
];

const JobForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const existingFormsData = getLocaleStorageVal(jobFormDataStore);
  const pageFilter = existingFormsData?.pageFilter;
  const [jobFormDialog, setJobFormDialog] = useState(false);
  // const [jobForms, setJobForms] = useState([]);
  const jobForms = props.jobFormsListData?.forms || [];
  const [isEdit, setIsEdit] = useState(false);
  const [isCopyForm, setIsCopyForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState({});
  const [order, setOrder] = useState(
    existingFormsData?.sortBy
      ? existingFormsData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingFormsData?.sortBy?.key || '');
  const [pageCount, setPageCount] = useState(pageFilter?.pageNumber || 1);
  const [pageSize, setPageSize] = useState(pageFilter?.pageSize || PAGE_SIZE);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getJobForms = (data) => {
    const existData = getLocaleStorageVal(jobFormDataStore);
    let newData = structuredClone(data) || {};
    let sortBy = existData?.sortBy;
    if (!newData?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (!newData?.page_size)
      newData.page_size = pageFilter?.pageSize || pageSize;
    if (!newData?.sortBy && sortBy)
      newData.sortBy = { [sortBy?.key]: sortBy?.value };
    props.fetchJobFormsList(onError, newData);
  };

  useEffect(() => {
    getJobForms();
    return () => dispatch(setJobFormsList([]));
  }, [props.addJobFormData, props.updateJobFormData, props?.deleteJobFormData]);

  // useEffect(() => {
  //   const data = props.jobFormsListData ? props.jobFormsListData : [];
  //   setJobForms(data);
  // }, [props.jobFormsListData]);

  // const descendingComparator = (a, b, orderBy) => {
  //   let asceding = b[orderBy] < a[orderBy];
  //   let descding = b[orderBy] > a[orderBy];
  //   if (orderBy === 'formCategory') {
  //     asceding =
  //       b[orderBy]?.categoryName.toLowerCase() <
  //       a[orderBy]?.categoryName.toLowerCase();
  //     descding =
  //       b[orderBy]?.categoryName.toLowerCase() >
  //       a[orderBy]?.categoryName.toLowerCase();
  //   }
  //   if (asceding) return -1;
  //   if (descding) return 1;
  //   return 0;
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getJobForms({ sortBy });
    setLocaleStorageVal(jobFormDataStore, {
      ...existingFormsData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredForms = applySortFilter(
  //   jobForms,
  //   getComparator(order, orderBy, descendingComparator)
  // );

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    if (pageCount !== page) {
      setPageCount(page);
      let data = { page_number: page };
      getJobForms(data);
      setLocaleStorageVal(jobFormDataStore, {
        ...existingFormsData,
        pageFilter: { pageNumber: page, pageSize },
      });
    }
  };

  // ===== call when page size change =====
  const handlePageSize = (val) => {
    if (pageSize !== val) {
      setPageSize(val);
      setPageCount(1);
      let data = { page_number: 1, page_size: val };
      getJobForms(data);
      setLocaleStorageVal(jobFormDataStore, {
        ...existingFormsData,
        pageFilter: { pageNumber: 1, pageSize: val },
      });
    }
  };

  // ===== handle delete form =====
  const handleDeleteForm = () => {
    props?.deleteJobForm(
      selectedForm?._id,
      () => {
        setOpenDeleteDialog(false);
        setSelectedForm(null);
      },
      onError
    );
  };

  return (
    <Stack className={classes.job_container}>
      <TableToolbox
        isBack
        isLabel
        isPrimaryButton
        labelText={Strings.FORM + 's'}
        primaryButtonText={Strings.NEW_FORM}
        onClickBack={() => navigate(-1)}
        onNewClick={() => setJobFormDialog(true)}
        // isSecondaryButton
        // secondaryButtonText={Strings.CATEGORY_TYPES}
        // secondaryButtonClick={() => navigate('/app/jobs/forms/categories')}
      />
      <div className={classes.list_container}>
        {props.jobFormsListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : jobForms.length === 0 ? (
          <div className={classes.flexView}>
            <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
          </div>
        ) : (
          <TableContainer className='dataTable'>
            <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                headCells={headCells}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {jobForms &&
                  jobForms?.map((data, index) => {
                    const { formName, createdAt, formCategory, formStatus } =
                      data;
                    return (
                      <TableRow key={data._id} sx={{ width: '100%' }}>
                        <TableCell
                          style={{
                            ...tableCell(theme),
                          }}
                          component='td'
                          scope='row'
                        >
                          <Stack direction='row' alignItems='center'>
                            <label className={classes.name_cell}>
                              {formName}
                            </label>
                          </Stack>
                        </TableCell>
                        <TableCell
                          style={{
                            ...tableCell(theme),
                          }}
                          component='td'
                          scope='row'
                        >
                          <Stack direction='column' alignItems='flex-start'>
                            <label className={classes.common_cell}>
                              {moment(createdAt).format('MMM D, YYYY')}
                            </label>
                          </Stack>
                        </TableCell>
                        {/* <TableCell
                          style={{
                            ...tableCell(theme),
                          }}
                          component='td'
                          scope='row'
                        >
                          <Stack
                            paddingTop={2.5}
                            direction='column'
                            alignItems='flex-start'
                          >
                            <label className={classes.common_cell}>
                              {formCategory?.categoryName}
                            </label>
                          </Stack>
                        </TableCell> */}
                        <TableCell
                          style={{
                            ...tableCell(theme),
                          }}
                          component='td'
                          scope='row'
                        >
                          <Stack direction='column' alignItems='flex-start'>
                            <label
                              className={classNames(
                                classes.common_cell,
                                classes.status_container,
                                formStatus === 'Active'
                                  ? classes.status_active
                                  : classes.status_inActive
                              )}
                            >
                              {formStatus}
                            </label>
                          </Stack>
                        </TableCell>
                        <TableCell
                          style={{ ...tableCell(theme) }}
                          component='td'
                          scope='row'
                        >
                          <Stack
                            direction='row'
                            justifyContent='end'
                            alignItems='flex-start'
                          >
                            <CustomizedTooltips title={Strings.EDIT_JOB_FORM}>
                              <img
                                className={classes.action_icons}
                                src={icons(theme).editIcon}
                                alt=''
                                onClick={(e) => {
                                  setIsEdit(true);
                                  setSelectedForm(data);
                                  setJobFormDialog(true);
                                }}
                              />
                            </CustomizedTooltips>

                            <CustomizedTooltips title={Strings.DELETE_JOB_FORM}>
                              <img
                                className={classes.action_icons}
                                style={{ marginLeft: 15 }}
                                src={icons(theme).deleteIcon}
                                alt='delete'
                                onClick={(e) => {
                                  setSelectedForm(data);
                                  setOpenDeleteDialog(true);
                                }}
                              />
                            </CustomizedTooltips>

                            <CustomizedTooltips title={Strings.COPY_FORM}>
                              <img
                                className={classes.action_icons}
                                style={{
                                  marginLeft: 15,
                                }}
                                src={icons(theme).copyIcon}
                                alt=''
                                onClick={(e) => {
                                  setIsCopyForm(true);
                                  setIsEdit(true); // we are using same edit form for copy form using isEdit & isCopyForm flag
                                  setSelectedForm(data);
                                  setJobFormDialog(true);
                                }}
                              />
                            </CustomizedTooltips>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {props?.jobFormsListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.jobFormsListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSize}
          />
        </Stack>
      ) : null}

      {jobFormDialog && (
        <NewJobForm
          isEdit={isEdit}
          isCopyForm={isCopyForm}
          data={selectedForm}
          onClose={() => {
            setIsEdit(false);
            setIsCopyForm(false);
            setJobFormDialog(false);
          }}
        />
      )}

      {openDeleteDialog ? (
        <CommonDialog
          title={Strings.JOB_FORM}
          message={Strings.DELETE_JOB_FORM_NOTE}
          onSuccess={() => handleDeleteForm()}
          onClose={() => {
            setOpenDeleteDialog(false);
            setSelectedForm(null);
          }}
          loading={props.deleteJobFormLoading}
        />
      ) : null}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  jobFormsListData: state.jobFormsListData.data,
  jobFormsListLoading: state.jobFormsListData.loading,

  addJobFormData: state.addJobFormData.data,

  updateJobFormData: state.updateJobFormData.data,

  deleteJobFormLoading: state.deleteJobFormData.loading,
  deleteJobFormData: state.deleteJobFormData.data,
});

export default connect(mapStateToProps, {
  fetchJobFormsList,
  deleteJobForm,
})(JobForm);
