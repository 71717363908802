import axios from 'axios';
import { DELETE_MEDIA_FOLDER } from '../../constants';

export const deleteFolder = (fId, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteFolder());

  axios
    .delete(`/media/folder?folderId=${fId}`, config)
    .then((res) => {
      onSuccess?.();
      dispatch(setDeleteFolder(res.data.data));
    })
    .catch((err) => {
      dispatch(errorDeleteFolder(err.response));
      onError(err.response);
    });
};

export const loadDeleteFolder = () => ({
  type: DELETE_MEDIA_FOLDER.LOAD,
});

export const setDeleteFolder = (data) => ({
  type: DELETE_MEDIA_FOLDER.SUCCESS,
  payload: data,
});

export const errorDeleteFolder = (error) => ({
  type: DELETE_MEDIA_FOLDER.FAIL,
  payload: error,
});
