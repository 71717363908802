import axios from 'axios';
import { GET_CLIENT_ALL_PROPERTY } from '../../constants';

export const fetchClientAllProperty = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetClientAllProperty());

  axios
    .get(`/client/getProperty`, config)
    .then((res) => dispatch(setGetClientAllProperty(res)))
    .catch((err) => {
      dispatch(errorGetClientAllProperty(err.response));
      onError(err.response);
    });
};

export const loadGetClientAllProperty = () => ({
  type: GET_CLIENT_ALL_PROPERTY.LOAD,
});

export const setGetClientAllProperty = (propertyList) => ({
  type: GET_CLIENT_ALL_PROPERTY.SUCCESS,
  payload: propertyList.data.data,
});

export const errorGetClientAllProperty = (error) => ({
  type: GET_CLIENT_ALL_PROPERTY.FAIL,
  payload: error,
});
