import { SEND_REPORT } from '../../../constants';
import axios from 'axios';

export const sendReport =
  (jobId, data, onError, onSuccess, onDialogClose) => (dispatch) => {
    const bodyFormData = new FormData();
    if (data.to.length > 0)
      bodyFormData.append('to', JSON.stringify(data.to) || []);
    if (data.cc.length > 0)
      bodyFormData.append('cc', JSON.stringify(data.cc) || []);
    if (data.bcc.length > 0)
      bodyFormData.append('bcc', JSON.stringify(data.bcc) || []);
    if (data.subject) bodyFormData.append('subject', data.subject || '');
    if (data.files.length > 0) {
      // for (let i = 0; i < data.files.length; i++) {
      //   bodyFormData.append(`files`, data.files[i], data.files[i].name);
      // }
      data.files.forEach((file, i) => {
        bodyFormData.append(`files`, file, file.name);
      });
    }
    if (data.description) bodyFormData.append('description', data.description);

    const token = localStorage.jwtToken;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(loadSendReport());
    axios
      .post(`/report/send?jobId=${jobId}`, bodyFormData, config)
      .then((res) => {
        dispatch(setSendReport(res.data));
        onDialogClose();
        onSuccess('Report sent successfully.');
      })
      .catch((err) => {
        dispatch(failSendReport(err.response));
        onError(err.response);
      });
  };

export const loadSendReport = () => ({
  type: SEND_REPORT.LOAD,
});

export const setSendReport = (data) => ({
  type: SEND_REPORT.SUCCESS,
  payload: data,
});

export const failSendReport = (error) => ({
  type: SEND_REPORT.FAIL,
  payload: error,
});
