import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import EnhancedTableHead from '../../components/DataTables/TableHead';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import TableToolbox from '../../components/TableToolbox';
import NewClient from './NewClient';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../utils/strings';
import { useNavigate } from 'react-router-dom';
import {
  tableCell,
  secondaryTableCell,
  getErrorMessage,
  applySortFilter,
  getComparator,
  isAdmin,
  PAGE_SIZE,
  getLocaleStorageVal,
  setLocaleStorageVal,
  compare2Object,
  phoneFormatUS,
} from '../../utils/appUtils';
import { connect } from 'react-redux';
import { fetchClientList } from '../../redux/actions/clientActions/clientList';
import { CircularProgress } from '@material-ui/core';
import CommonDialog from '../../components/CommonDialog';
import { deleteClient } from '../../redux/actions/clientActions/deleteClient';
import { deleteProperty } from '../../redux/actions/clientActions/deleteProperty';
import { icons } from '../../utils/icons';
import { Colors } from '../../utils/color';
import ClientFilter from './ClientFilter';
import { useAlert } from 'react-alert';
import { sendUserPassword } from '../../redux/actions/adminActions/sendUserPassword';
import debounce from 'lodash/debounce';
import { listFilter } from '../../redux/actions/filterActions/listFilter';
import NewClientFilter from './ClientFilter/NewClientFilter';
import CustomPagination from '../../components/CustomPagination';
import moment from 'moment';
import { archiveUnarchive } from '../../redux/actions/clientActions/archiveUnarchiveClient';

// ======== Table Head =========
const headCells = [
  { id: 'name', label: 'COMPANY/NAME', isSort: true },
  { id: 'properties', label: 'PROPERTY', isSort: true },
  // { id: 'programType', label: 'PROGRAM TYPE', isSort: true },
  { id: 'email', label: 'EMAIL', isSort: true },
  { id: 'phone', label: 'PHONE' },
  { id: 'adminAssigned', label: 'ADMIN ASSIGNED', isSort: true },
  { id: 'lastActivity', label: 'LAST ACTIVITY', isSort: true },
  { id: '' },
];

const filterStore = 'ClientsData';

const Clients = (props) => {
  const classes = useStyles();
  const existingClientData = getLocaleStorageVal(filterStore) || {};
  const [search, setSearch] = useState(existingClientData?.searchString || '');
  const [newDialog, setNewDialog] = useState(false);
  const [openRows, setOpenRows] = useState([]);
  // const [clientListData, setClientListData] = useState([]);
  const clientListData = props.clientListData || [];
  const [deleteClientDialog, setDeleteClientDialog] = useState(false);
  const [archiveUnarchiveDialog, setArchiveUnarchiveDialog] = useState(false);
  const [isArchive, setIsArchive] = useState();
  const [deletePropertyDialog, setDeletePropertyDialog] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [clientFilterDialog, setClientFilterDialog] = useState(false);
  const [filter, setFilter] = useState(
    isAdmin() ? { showAllClient: false } : null
  );
  const alert = useAlert();
  const [sendPasswordUser, setSendPasswordUser] = useState('');
  const [order, setOrder] = useState(
    existingClientData?.sortBy
      ? existingClientData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingClientData?.sortBy?.key || '');
  const [filterDropdownValue, setFilterDropdownValue] = useState('');
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState();
  const [isFilterChange, setIsFilterChange] = useState(false);
  // const [pageCount, setPageCount] = useState(1);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getClientList = (data) => {
    const newData = { ...data }; // page_size: PAGE_SIZE
    // if (!data?.page_number) newData.page_number = pageCount;
    props.fetchClientList(newData, (err) => onError(err));
    // setPageCount(data?.page_number || pageCount);
  };

  const delayedSearch = useCallback(
    debounce((search, filter) => {
      const data = { searchString: search }; // page_number: 1
      if (filter && Object.keys(filter)?.length > 0) data.filter = filter;
      getClientList(data);
    }, 600),
    []
  );

  useEffect(() => {
    let prevFilter =
      Object.keys(existingClientData)?.length > 0 ? existingClientData : {};
    if (prevFilter?.filterId) {
      setFilterDropdownValue(prevFilter?.filterId || '');
    }
    setFilter(prevFilter?.filter);
    const data = {
      searchString: search,
    };
    if (prevFilter && Object.entries(prevFilter)?.length) {
      data.filter = { ...prevFilter?.filter };
    } else if (filter && Object.entries(filter)?.length) {
      data.filter = filter;
    }
    getClientList(data);
  }, [
    props.addClientData,
    props.archiveUnarchiveClientData,
    props.deleteClientData,
    props.updateClientData,
    props.deletePropertyData,
  ]);

  // useEffect(() => {
  //   const data = props.clientListData ? props.clientListData : [];
  //   setClientListData(data);
  // }, [props.clientListData]);

  const onOpenRow = (rowIndex) => {
    if (openRows.findIndex((item, index) => item === rowIndex) === -1) {
      setOpenRows([...openRows, rowIndex]);
    } else {
      setOpenRows(openRows.filter((item) => item !== rowIndex));
    }
  };

  const onDeleteData = () => {
    if (deletePropertyDialog) {
      props.deleteProperty(
        selectedProperty._id,
        () => setDeletePropertyDialog(false),
        (err) => onError(err)
      );
    } else {
      props.deleteClient(
        selectedClient._id,
        () => setDeleteClientDialog(false),
        (err) => onError(err)
      );
    }
  };

  const onArchiveUnarchiveData = () => {
    const data = {
      client: selectedClient._id,
      archive: isArchive,
    };
    props.archiveUnarchive(data, onError, () =>
      setArchiveUnarchiveDialog(false)
    );
  };

  const setFilterData = (data) => {
    if (!Object.entries(data)?.length && filter) {
      getClientList({ searchString: search || '' }); // page_number: 1
    } else if (JSON.stringify(data) !== '{}') {
      setFilter(data);
      getClientList({
        searchString: search || '',
        // page_number: 1,
        filter: data,
      });
      !compare2Object(existingClientData?.filter || {}, data || {}) &&
        setFilterDropdownValue('');
    }
    let filterId =
      JSON.stringify(data) !== '{}' &&
      compare2Object(existingClientData?.filter || {}, data || {})
        ? existingClientData?.filterId
        : '';
    setLocaleStorageVal(filterStore, {
      ...existingClientData,
      filter: data,
      filterId: filterId,
      searchString: search,
    });
  };

  const handleSendPassword = (_id) => {
    setSendPasswordUser(_id);
    props.sendUserPassword(
      _id,
      () => {
        const data = {
          searchString: search,
        };
        setSendPasswordUser('');
        if (filter) data.filter = filter;
        getClientList(data);
      },
      (err) => {
        setSendPasswordUser('');
        onError(err);
      }
    );
  };

  const descendingComparator = (a, b, orderBy) => {
    let asceding = b[orderBy] < a[orderBy];
    let descding = b[orderBy] > a[orderBy];
    if (orderBy === 'name') {
      asceding =
        `${b?.lastName?.toLowerCase()} ${b?.firstName?.toLowerCase()}` <
        `${a?.lastName?.toLowerCase()} ${a?.firstName?.toLowerCase()}`;
      descding =
        `${b?.lastName?.toLowerCase()} ${b?.firstName?.toLowerCase()}` >
        `${a?.lastName?.toLowerCase()} ${a?.firstName?.toLowerCase()}`;
    } else if (orderBy === 'programType') {
      asceding =
        b[orderBy]?.title.toLowerCase() < a[orderBy]?.title.toLowerCase();
      descding =
        b[orderBy]?.title.toLowerCase() > a[orderBy]?.title.toLowerCase();
    } else if (orderBy === 'adminAssigned') {
      asceding =
        b[orderBy]?.name.toLowerCase() < a[orderBy]?.name.toLowerCase();
      descding =
        b[orderBy]?.name.toLowerCase() > a[orderBy]?.name.toLowerCase();
    }
    if (asceding) return -1;
    if (descding) return 1;
    return 0;
  };

  const onClickRefresh = () => {
    const data = {}; // page_number: 1
    if (filterDropdownValue) {
      let filterData = filterDropdownList?.filter(
        (el) => el.value === filterDropdownValue
      )[0];
      if (search) data.searchString = search;
      if (filterData?.filter) data.filter = filterData?.filter;
      getClientList(data);
    } else if (filter && Object.keys(filter).length > 0) {
      if (search) data.searchString = search;
      data.filter = filter;
      getClientList(data);
    } else {
      data.searchString = search;
      getClientList(data);
      setFilterDropdownValue('');
    }
  };

  const onClickClearFilter = async () => {
    await setFilterDropdownValue('');
    // await setSearch('');
    const data = { searchString: search }; // page_number: 1
    if (isAdmin()) {
      data.filter = { showAllClient: false };
      await setFilter({ showAllClient: false });
      await getClientList(data);
    } else {
      await setFilter(null);
      await getClientList(data);
    }
    setLocaleStorageVal(filterStore, {
      ...existingClientData,
      filter: isAdmin() ? { showAllClient: false } : {},
      filterId: '',
    });
  };

  const handleRequestSort = (event, property) => {
    setOpenRows([]);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    setLocaleStorageVal(filterStore, {
      ...existingClientData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  const filteredClients = applySortFilter(
    clientListData,
    getComparator(order, orderBy, descendingComparator)
  );

  useEffect(() => {
    !isFilterChange && props.listFilter('CLIENT_LIST', onError);
  }, []);

  useEffect(() => {
    if (isFilterChange) {
      let prevFilter =
        Object.keys(existingClientData).length > 0 ? existingClientData : {};
      if (prevFilter?.filterId) {
        localStorage.removeItem(filterStore);
      }
      props.listFilter('CLIENT_LIST', onError);
      filterDropdownValue &&
        getClientList(
          isAdmin()
            ? { filter: { showAllClient: false } } // page_number: 1
            : { searchString: '' } // page_number: 1
        );
      if (isAdmin()) {
        setFilter({ showAllClient: false });
      } else {
        setFilter(null);
      }
      setFilterDropdownValue('');
      setIsFilterChange(false);
    }
  }, [isFilterChange]);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  const handleFilterSelectionClick = async (value) => {
    let filterData = filterDropdownList?.filter((el) => el.value === value)[0];
    let newFilter = filterData?.filter
      ? filterData?.filter
      : isAdmin()
      ? { showAllClient: false }
      : null;
    setFilter(newFilter);
    setLocaleStorageVal(filterStore, {
      ...existingClientData,
      filter: newFilter ? { ...newFilter } : {},
      filterId: value,
      searchString: search,
    });
    const data = { searchString: search }; // page_number: 1
    if (filterData?.filter) data.filter = filterData?.filter;
    if (!filterData?.filter && isAdmin())
      data.filter = { showAllClient: false };
    await getClientList(data);
  };

  // ===== handle page change =====
  // const handlePageChange = (e, page) => {
  //   const newData = {
  //     searchString: search,
  //     filter: filter || {},
  //     page_number: page,
  //   };
  //   if (pageCount !== page) {
  //     getClientList(newData);
  //   }
  //   setOpenRows([]);
  // };

  return (
    <Stack className={classes.client_container}>
      <TableToolbox
        isSearch
        search={search}
        isFilter
        onChangeSearch={(e) => {
          let newFilter =
            filter && Object.entries(filter)?.length
              ? filter
              : isAdmin()
              ? { showAllClient: false }
              : {};
          delayedSearch(e.target.value, newFilter);
          setSearch(e.target.value);
          setLocaleStorageVal(filterStore, {
            ...existingClientData,
            filter: newFilter,
            searchString: e.target.value,
          });
        }}
        onNewClick={() => setNewDialog(true)}
        isPrimaryButton
        isSecondaryButton
        secondaryButtonText={Strings.PROGRAM_TYPE + 's'}
        secondaryButtonClick={() => navigate('/app/clients/programs')}
        filterButtonClick={() => setClientFilterDialog(true)}
        isFilterDropdown={filterDropdownList.length ? true : false}
        filterDropdownValue={filterDropdownValue}
        filterDropdownList={filterDropdownList}
        onChangeFilterDropdown={async (e) => {
          await setFilterDropdownValue(e);
          await handleFilterSelectionClick(e);
        }}
        onEditClick={(value) =>
          setOpenEditFilterDialog({ show: true, ...value })
        }
        onClickRefresh={onClickRefresh}
        onClickClearFilter={onClickClearFilter}
      />
      <div className={classes.list_container}>
        {props.clientListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {clientListData.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {clientListData &&
                      filteredClients?.map((data, index) => {
                        const {
                          name,
                          properties,
                          // programType,
                          email,
                          phoneNumber,
                          adminAssigned,
                          issues,
                          _id,
                          isArchived,
                          countryCode,
                          firstName,
                          lastName,
                          lastActivity,
                          // passwordSent,
                        } = data;
                        return (
                          <>
                            <TableRow
                              key={data._id}
                              sx={{ width: '100%' }}
                              onClick={() => onOpenRow(index)}
                            >
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack direction='row' alignItems='center'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.findIndex(
                                        (item) => item === index
                                      ) !== -1
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                    // onClick={() => onOpenRow(index)}
                                  />
                                  <label className={classes.name_cell}>
                                    {`${lastName || ''}, ${firstName || ''}`}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack direction='row' alignItems='center'>
                                  <label className={classes.common_cell}>
                                    {properties?.length || 0}
                                  </label>
                                  {properties[0]?.userAvailability ? (
                                    <div
                                      style={{
                                        backgroundColor:
                                          properties[0]?.userAvailability ===
                                          'Here'
                                            ? Colors.TOMATO_RED
                                            : Colors.LIGHT_GREEN,
                                      }}
                                      className={classes.issue_container}
                                    >
                                      {properties[0]?.userAvailability}
                                    </div>
                                  ) : null}
                                </Stack>
                              </TableCell>
                              {/* <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {programType?.title}
                                  </label>
                                </Stack>
                              </TableCell> */}
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {email}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {phoneFormatUS(phoneNumber)}
                                    {/* {countryCode + phoneNumber} */}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {adminAssigned?.name || '-'}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {lastActivity
                                      ? moment(lastActivity).format(
                                          'M/DD/YY h:mmA'
                                        )
                                      : '-'}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(
                                    theme,
                                    openRows.findIndex(
                                      (item) => item === index
                                    ) !== -1
                                  ),
                                }}
                                component='td'
                                scope='row'
                                align='right'
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  justifyContent='end'
                                >
                                  {/* {!passwordSent && (
                                  <>
                                    {sendPasswordUser === _id ? (
                                      <CircularProgress
                                        size={24}
                                        style={{
                                          color:
                                            theme.palette.background_button,
                                          marginRight: 15,
                                        }}
                                      />
                                    ) : (
                                      <CustomizedTooltips
                                        title={Strings.SEND_PASSWORD}
                                      >
                                        <img
                                          className={classes.action_icons}
                                          style={{ marginRight: 15 }}
                                          src={icons(theme).reportSendIcon}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleSendPassword(_id);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                    )}
                                  </>
                                )} */}
                                  <CustomizedTooltips
                                    title={
                                      isArchived
                                        ? Strings.UNARCHIVE_CLIENT
                                        : Strings.ARCHIVE_CLIEN
                                    }
                                  >
                                    <img
                                      className={classes.action_icons}
                                      style={{ marginRight: 15 }}
                                      src={
                                        isArchived
                                          ? icons(theme).unarchive
                                          : icons(theme).archive
                                      }
                                      alt=''
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedClient(data);
                                        setArchiveUnarchiveDialog(true);
                                        setIsArchive(!isArchived);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                  <CustomizedTooltips
                                    title={Strings.ADD_PROPERTY}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      style={{ marginRight: 15 }}
                                      src={icons(theme).addIcon}
                                      alt=''
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate('/app/clients/add/property', {
                                          state: {
                                            _id,
                                            name,
                                            // programType,
                                          },
                                        });
                                      }}
                                    />
                                  </CustomizedTooltips>
                                  <CustomizedTooltips
                                    title={Strings.EDIT_CLIENT}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).editIcon}
                                      style={{ marginRight: 15 }}
                                      alt=''
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsEdit(true);
                                        setSelectedClient(data);
                                        setNewDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                  <CustomizedTooltips
                                    title={Strings.DELETECLIENT}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).deleteIcon}
                                      alt=''
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedClient(data);
                                        setDeleteClientDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                </Stack>
                              </TableCell>
                            </TableRow>
                            {openRows.findIndex((item) => item === index) !==
                              -1 &&
                              properties &&
                              properties.map((item, index) => {
                                const {
                                  name,
                                  userAvailability,
                                  address,
                                  programType,
                                } = item;
                                return (
                                  <TableRow
                                    key={item._id}
                                    sx={{ width: '100%' }}
                                    onClick={() =>
                                      navigate('/app/clients/view/property', {
                                        state: {
                                          // programType,
                                          properties,
                                          selectedProperty: item._id,
                                        },
                                      })
                                    }
                                  >
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(
                                          theme,
                                          index + 1 === properties.length
                                        ),
                                        cursor: 'pointer',
                                      }}
                                      component='td'
                                      scope='row'
                                      // onClick={() =>
                                      //   navigate('/app/clients/view/property', {
                                      //     state: {
                                      //       programType,
                                      //       properties,
                                      //       selectedProperty: item._id,
                                      //     },
                                      //   })
                                      // }
                                    >
                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                      >
                                        <label
                                          style={{
                                            marginLeft: 39,
                                            cursor: 'pointer',
                                          }}
                                          className={classes.name_cell}
                                        >
                                          {name}
                                        </label>
                                      </Stack>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(
                                          theme,
                                          index + 1 === properties.length
                                        ),
                                        cursor: 'pointer',
                                      }}
                                      component='td'
                                      scope='row'
                                    >
                                      <label
                                        className={classes.name_cell}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {userAvailability ? (
                                          <div
                                            className={classes.issue_container}
                                            style={{
                                              marginLeft: 0,
                                              backgroundColor:
                                                userAvailability === 'Here'
                                                  ? Colors.TOMATO_RED
                                                  : Colors.LIGHT_GREEN,
                                            }}
                                          >
                                            {userAvailability}
                                          </div>
                                        ) : null}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(
                                          theme,
                                          index + 1 === properties.length
                                        ),
                                        cursor: 'pointer',
                                      }}
                                      component='td'
                                      scope='row'
                                    >
                                      <label
                                        className={classes.common_cell}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {programType?.title}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(
                                          theme,
                                          index + 1 === properties.length
                                        ),
                                        cursor: 'pointer',
                                      }}
                                      component='td'
                                      scope='row'
                                      colSpan={2}
                                      // onClick={() =>
                                      //   navigate('/app/clients/view/property', {
                                      //     state: {
                                      //       programType,
                                      //       properties,
                                      //       selectedProperty: item._id,
                                      //     },
                                      //   })
                                      // }
                                    >
                                      <Stack
                                        direction='row'
                                        alignItems='flex-start'
                                      >
                                        <label
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          className={classes.common_cell}
                                        >
                                          {address}
                                        </label>
                                      </Stack>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        ...secondaryTableCell(
                                          theme,
                                          index + 1 === properties.length
                                        ),
                                      }}
                                      component='td'
                                      scope='row'
                                      align='right'
                                      colSpan={2}
                                    >
                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='end'
                                      >
                                        <CustomizedTooltips
                                          title={Strings.DELETEPROPERTY}
                                        >
                                          <img
                                            className={classes.action_icons}
                                            src={icons(theme).deleteIcon}
                                            alt=''
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setSelectedProperty(item);
                                              setDeletePropertyDialog(true);
                                            }}
                                          />
                                        </CustomizedTooltips>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>

      {/* {props?.clientListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            totalRecords={props?.clientListData?.totalRecords || 0}
            handleChange={handlePageChange}
          />
        </Stack>
      ) : null} */}

      {newDialog && (
        <NewClient
          data={selectedClient}
          isEdit={isEdit}
          onNewClose={() => {
            setIsEdit(false);
            setNewDialog(false);
          }}
        />
      )}
      {(deleteClientDialog ||
        deletePropertyDialog ||
        archiveUnarchiveDialog) && (
        <CommonDialog
          title={Strings.CLIENT}
          message={
            deletePropertyDialog
              ? Strings.DELETE_PROPERTY
              : deleteClientDialog
              ? Strings.DELETE_CLIENT
              : isArchive
              ? Strings.ARCHIVE_CLIENT_NOTE
              : Strings.UNARCHIVE_CLIENT_NOTE
          }
          onSuccess={
            archiveUnarchiveDialog ? onArchiveUnarchiveData : onDeleteData
          }
          onClose={() => {
            setDeletePropertyDialog(false);
            setDeleteClientDialog(false);
            setArchiveUnarchiveDialog(false);
          }}
          loading={
            deletePropertyDialog
              ? props.deletePropertyLoading
              : deleteClientDialog
              ? props.deleteClientLoading
              : props.archiveUnarchiveClientLoading
          }
        />
      )}
      {clientFilterDialog && (
        <ClientFilter
          setFilterData={setFilterData}
          filterData={filter}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setClientFilterDialog(false)}
        />
      )}
      {openEditFilterDialog?.show && (
        <NewClientFilter
          isEdit={true}
          selectedId={openEditFilterDialog?.value}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setOpenEditFilterDialog()}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  addClientData: state.addClientData.data,

  archiveUnarchiveClientData: state.archiveUnarchiveClientData.data,
  archiveUnarchiveClientLoading: state.archiveUnarchiveClientData.loading,

  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,

  updateClientData: state.updateClientData.data,

  deleteClientData: state.deleteClientData.data,
  deleteClientLoading: state.deleteClientData.loading,

  deletePropertyData: state.deletePropertyData.data,
  deletePropertyLoading: state.deletePropertyData.loading,

  sendUserPasswordData: state.sendUserPasswordData.data,
  sendUserPasswordLoading: state.sendUserPasswordData.loading,

  listFilterData: state.listFilterData.data,
  listFilterLoading: state.listFilterData.loading,

  addFilterData: state.addFilterData.data,
  updateFilterData: state.updateFilterData.data,
  deleteFilterData: state.deleteFilterData.data,
});

export default connect(mapStateToProps, {
  fetchClientList,
  deleteClient,
  deleteProperty,
  sendUserPassword,
  listFilter,
  archiveUnarchive,
})(Clients);
