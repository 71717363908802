import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from '@material-ui/core';
import { Strings } from '../../../utils/strings';
import { Stack, Table, TableBody, TableContainer } from '@mui/material';
import EnhancedTableHead from '../../../components/DataTables/TableHead';
import JobTableBody from './JobTableBody';
import TableToolbox from '../../../components/TableToolbox';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  PAGE_SIZE,
  getDateRangeValue,
  getErrorMessage,
} from '../../../utils/appUtils';
import { debounce } from 'lodash';
import {
  fetchJobSeriesList,
  setJobSeriesList,
} from '../../../redux/actions/jobActions/jobActions/jobSeriesList';
import { listFilter } from '../../../redux/actions/filterActions/listFilter';
import moment from 'moment';
import JobFilter from '../JobFilter';
import { useLocation } from 'react-router-dom';
import JobNewFilter from '../JobFilter/JobNewFilter';
import JobDetails from '../JobDetails';
import NewJob from '../NewJob';
import CommonDialog from '../../../components/CommonDialog';
import Button from '../../../components/Button';
import AddNotes from '../../Issues/NotesDialog';
import { useDispatch } from 'react-redux';
import { deleteJob } from '../../../redux/actions/jobActions/jobActions/deleteJob';
import { deleteJobNote } from '../../../redux/actions/jobActions/jobActions/deleteJobNote';
import { markAllJobsNotesRead } from '../../../redux/actions/jobActions/jobActions/markAllJobNotesRead';
import { deleteJobSeries } from '../../../redux/actions/jobActions/jobActions/deleteJobSeries';
import CustomPagination from '../../../components/CustomPagination';
import { updateJobSeries } from '../../../redux/actions/jobActions/jobActions/updateJobSeries';

const filterStore = 'JobsFilter';

// ===== table header =====
const headCells = [
  { id: '', disablePadding: true },
  { id: 'title', label: 'TITLE' },
  { id: 'property', label: 'PROPERTY' },
  { id: 'startDate', label: 'START DATE', isSort: true },
  { id: 'endDate', label: 'DURATION' },
  { id: 'jobForm', label: 'FORM' },
  { id: 'assigned', label: 'ASSIGNED' },
  { id: 'jobStatus', label: 'STATUS' },
  { id: '' },
];

const JobSeries = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [jobSeriesData, setJobSeriesData] = useState({});
  const [filterDialog, setFilterDialog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [openJobDetailDialog, setOpenJobDetailDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [openEditJobDialog, setOpenEditJobDialog] = useState(false);
  const [openDeleteJobDialog, setOpenDeleteJobDialog] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState('');
  const [selectedNoteData, setSelectedNoteData] = useState(null);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [seriesSelectedJob, setSeriesSelectedJob] = useState([]);
  const [openDeleteSeriesDialog, setOpenDeleteSeriesDialog] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [jobNoteType, setJobNoteType] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortBy, setSortBy] = useState({});

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getSeriesListData = (data) => {
    const newData = { jobId: state?.jobId, ...data };
    if (!data?.page_number) newData.page_number = pageCount;
    if (!data?.page_size) newData.page_size = pageSize;
    if (!data?.sortBy && sortBy && Object.keys(sortBy)?.length)
      newData.sortBy = sortBy;
    props.fetchJobSeriesList(newData, onError);
  };

  const delayedSearch = useCallback(
    debounce((data, type) => getSeriesListData(data), 600),
    [sortBy]
  );

  // ===== get/set filter dropdown list =====
  useEffect(() => {
    !isFilterChange && props.listFilter('JOB', onError);
    return () => dispatch(setJobSeriesList({}));
  }, []);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  // ===== fetch jobSeries list data =====
  useEffect(() => {
    if (state && state?.jobId) {
      const storedFilter =
        JSON.parse(localStorage.getItem(filterStore)) || null;
      const data = {};
      if (search) data.searchString = search;
      if (storedFilter && (storedFilter?.filter || storedFilter?.filterId)) {
        if (storedFilter?.filterId) setSelectedFilter(storedFilter?.filterId);
        if (storedFilter?.filter && Object.keys(storedFilter?.filter)?.length) {
          if (storedFilter?.filter?.dateRange !== 'All Time') {
            let dateRange = getDateRangeValue(storedFilter?.filter?.dateRange);
            let newFilter = {
              ...storedFilter?.filter,
              startDate: moment(dateRange?.startDate).format('YYYY-MM-DD'),
              endDate: moment(dateRange?.endDate).format('YYYY-MM-DD'),
            };
            storedFilter.filter = newFilter;
          }
          data.filter = storedFilter.filter;
          setFilter(storedFilter.filter);
        }
      } else {
        if (filter) data.filter = filter;
      }
      getSeriesListData(data);
    }
  }, [
    state,
    props?.updateJobData,
    props?.deleteJobData,
    props?.deleteJobSeriesData,
    props?.updateJobSeriesData,
  ]);

  useEffect(() => {
    if (
      props?.jobSeriesListData &&
      Object.entries(props?.jobSeriesListData)?.length
    ) {
      setJobSeriesData(props?.jobSeriesListData);
    }
  }, [props?.jobSeriesListData]);

  // ===== call when searching job =====
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const data = {
      page_number: 1,
      page_size: pageSize,
      searchString: e.target.value,
    };
    if (filter) data.filter = filter;
    delayedSearch(data);
    setPageCount(1);
  };

  // ===== call when filter dropdown item selected =====
  const handleFilterSelection = (value) => {
    setSelectedFilter(value);
    let filterData = filterDropdownList?.find((el) => el.value === value);
    setFilter(filterData?.filter || {});
    localStorage.setItem(
      filterStore,
      JSON.stringify({
        filter: { ...filterData?.filter },
        filterId: value,
      })
    );
    if (filterData?.filter?.dateRange) {
      const dateRange = getDateRangeValue(filterData.filter.dateRange);
      if (filterData.filter.dateRange !== 'All Time') {
        filterData.filter.startDate = moment(dateRange.startDate).format(
          'YYYY-MM-DD'
        );
        filterData.filter.endDate = moment(dateRange.endDate).format(
          'YYYY-MM-DD'
        );
      }
    }
    const newFilterData = { page_number: 1 };
    if (search) newFilterData.searchString = search;
    if (filterData?.filter) newFilterData.filter = filterData?.filter;
    getSeriesListData(newFilterData);
    setPageCount(1);
  };

  // ===== call when clear filter =====
  const handleClearFilter = async () => {
    // await setSearch('');
    await setFilter(null);
    await setSelectedFilter('');
    await localStorage.removeItem(filterStore);
    await getSeriesListData({ searchString: search });
    setPageCount(1);
  };

  // ===== call when refresh =====
  const handleRefresh = () => {
    const data = { page_number: 1, page_size: pageSize };
    if (filter) data.filter = filter;
    if (search) data.searchString = search;
    getSeriesListData(data);
    setPageCount(1);
  };

  // ===== call when create/filter jobs (set filter data) =====
  const setFilterData = (data) => {
    localStorage.setItem(filterStore, JSON.stringify({ filter: data }));
    if (!Object.entries(data)?.length && filter) {
      // setSearch('');
      setFilter(null);
      getSeriesListData({ page_number: 1 });
      setSelectedFilter('');
    } else if (Object.entries(data)?.length) {
      let newData = JSON.parse(JSON.stringify(data));
      // setSearch('');
      setFilter(data);
      getSeriesListData({
        page_number: 1,
        filter: newData,
        searchString: search,
      });
      setSelectedFilter('');
    }
  };

  // ===== call when if filter data change/update =====
  useEffect(() => {
    if (isFilterChange) {
      const storedFilter = JSON.parse(localStorage.getItem(filterStore)) || '';
      if (storedFilter?.filterId) localStorage.removeItem(filterStore);
      props.listFilter('JOB', onError);
      getSeriesListData({ page_number: 1, searchString: search });
      setFilter(null);
      setSelectedFilter('');
      setIsFilterChange(false);
      setPageCount(1);
    }
  }, [isFilterChange]);

  // ===== call when delete dialog success clicked =====
  const handleDeleteJob = () => {
    props.deleteJob(
      selectedJobId,
      () => {
        setOpenDeleteJobDialog(false);
        setSelectedJobId('');
      },
      onError
    );
  };

  // ===== job actions =====
  const handleRowClick = (jID) => {
    setSelectedJobId(jID);
    setOpenJobDetailDialog(true);
  };

  const handleAddNoteClick = (jID, type) => {
    setSelectedJobId(jID);
    setOpenNoteDialog(true);
    setJobNoteType(type);
  };

  const handleEditClick = (job) => {
    setSelectedJob(job);
    setOpenEditJobDialog(true);
  };

  const handleDeleteClick = (jID) => {
    setSelectedJobId(jID);
    setOpenDeleteJobDialog(true);
  };

  const handleEditNoteClick = (jID, data, type) => {
    setSelectedJobId(jID);
    setSelectedNoteData(data);
    setIsEditNote(true);
    setOpenNoteDialog(true);
    setJobNoteType(type);
  };

  // ===== call when delete note =====
  const handleDeleteNoteClick = (jId, nId, isMainJob) => {
    props.deleteJobNote(jId, nId, onError, () => {
      const newJobsData = structuredClone(jobSeriesData);
      const jobs =
        structuredClone(
          isMainJob ? jobSeriesData?.mainJob : jobSeriesData?.series
        ) || [];
      const newData = jobs?.map((item) => {
        if (item?._id === jId) {
          let newJobNote = item?.jobNotes?.filter((el) => el?._id !== nId);
          item.jobNotes = newJobNote;
        }
        return item;
      });
      if (isMainJob) newJobsData.mainJob = newData;
      else newJobsData.series = newData;
      dispatch(setJobSeriesList(newJobsData));
      setSelectedNoteId('');
    });
  };

  // ===== call when tab changes =====
  const handleSelectedTab = (jID, selectedTab, isMainJob) => {
    const isNote = selectedTab === 'notes';
    const isInternalNote = selectedTab === 'internalNotes';
    if (isNote || isInternalNote) {
      const copyData = structuredClone(jobSeriesData);
      let newData = isMainJob ? copyData?.mainJob : copyData?.series;
      let toCallApi = false;
      newData = newData?.map((el) => {
        if (el?._id === jID) {
          let copyNotes = structuredClone(el?.jobNotes);
          toCallApi = copyNotes
            ?.filter((ob) => (isNote ? !ob.internalNotes : ob.internalNotes))
            ?.some((ob) => !ob?.isRead);
          copyNotes = el?.jobNotes?.map((ob) => {
            if (
              (isNote && !ob?.internalNotes) ||
              (isInternalNote && ob?.internalNotes)
            ) {
              ob.isRead = true;
            }
            return ob;
          });
          el.jobNotes = copyNotes;
        }
        return el;
      });
      if (isMainJob) copyData.mainJob = newData;
      else copyData.series = newData;
      dispatch(setJobSeriesList(copyData));
      const data = {
        type: selectedTab,
        jobId: jID,
      };
      if (toCallApi) props.markAllJobsNotesRead(data, onError);
    }
  };

  // ===== call when delete series call =====
  const handleDeleteSeries = (data) => {
    setOpenDeleteSeriesDialog(true);
    setSeriesSelectedJob(data);
  };

  const onDeleteSeriesJobs = () => {
    const newData = { jobs: seriesSelectedJob };
    props?.deleteJobSeries(newData, onError, () => {
      setOpenDeleteSeriesDialog(false);
      setSeriesSelectedJob([]);
    });
  };

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    const newData = {
      searchString: search,
      filter: filter || {},
      page_number: page,
    };
    if (pageCount !== page) {
      getSeriesListData(newData);
    }
    setPageCount(page);
  };

  // ===== call when page change =====
  const handlePageSizeChange = (pSize) => {
    const newData = {
      searchString: search,
      filter: filter || {},
      page_number: 1,
      page_size: pSize,
    };
    if (pageSize !== pSize) {
      getSeriesListData(newData);
    }
    setPageSize(pSize);
  };

  // ==== call when add/edit note =====
  const setAddEditNotes = async (data) => {
    if (data && Object.entries(data)?.length) {
      const copyJobs = structuredClone(jobSeriesData);
      let listData =
        jobNoteType === 'main' ? copyJobs?.mainJob : copyJobs?.series;

      listData = listData?.map((el) => {
        if (el?._id === selectedJobId) {
          if (isEditNote) {
            let newJobNote = el?.jobNotes?.map((_o) => {
              if (_o?._id === selectedNoteData?.noteId) {
                _o.note = data?.note;
                _o.internalNotes = data?.internalNotes;
              }
              return _o;
            });
            el.jobNotes = newJobNote;
          } else {
            el.jobNotes = [data, ...el?.jobNotes];
          }
        }
        return el;
      });
      if (jobNoteType === 'main') copyJobs.main = listData;
      else copyJobs.series = listData;
      dispatch(setJobSeriesList(copyJobs));
      setSelectedJobId('');
      setIsEditNote(false);
      setSelectedNoteData(null);
    }
  };

  useEffect(() => {
    const noteData = structuredClone(
      isEditNote ? props?.updateJobNoteData : props?.addJobNoteData
    );
    setAddEditNotes(noteData);
  }, [props?.addJobNoteData, props?.updateJobNoteData]);

  // ===== table sorting func =====
  const handleRequestSort = (e, field) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
    const newData = {
      searchString: search,
      filter: filter || {},
      sortBy: { [field]: isAsc ? -1 : 1 },
    };
    setSortBy({ [field]: isAsc ? -1 : 1 });
    getSeriesListData(newData);
  };

  // ===== call when update selected jobs status =====
  const handleUpdateStatus = (status, jobs) => {
    let data = { jobs, jobStatus: status };
    props?.updateJobSeries(data, onError);
  };

  return (
    <>
      <Stack className={classes.job_container}>
        <TableToolbox
          isFilter
          isSearch
          isFilterDropdown
          search={search}
          filterDropdownValue={selectedFilter}
          filterDropdownList={filterDropdownList}
          onClickRefresh={handleRefresh}
          onClickClearFilter={handleClearFilter}
          onChangeSearch={handleSearch}
          filterButtonClick={() => setFilterDialog(true)}
          onChangeFilterDropdown={handleFilterSelection}
          onEditClick={(value) =>
            setOpenEditFilterDialog({ show: true, ...value })
          }
          toolboxContainerClassName={classes.toolbox_container}
        />
        <div className={classes.list_container}>
          {props?.jobSeriesListLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{ color: theme.palette.background_button }}
              />
            </div>
          ) : !jobSeriesData?.mainJob?.length &&
            !jobSeriesData?.series?.length ? (
            <div className={classes.flexView}>
              <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
            </div>
          ) : (
            <TableContainer className='dataTable'>
              <Table
                className={classes.list_table}
                aria-labelledby='tableTitle'
              >
                <EnhancedTableHead
                  headCells={headCells}
                  handleRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {jobSeriesData?.mainJob?.length ? (
                    <JobTableBody
                      isMainJob
                      isPrimarySection
                      selectedNoteId={selectedNoteId}
                      setSelectedNoteId={setSelectedNoteId}
                      sectionTitle={Strings.MAIN_JOB?.toUpperCase()}
                      isShowSectionRow={jobSeriesData?.mainJob?.length}
                      listData={jobSeriesData?.mainJob || []}
                      deleteJobNoteLoading={props?.deleteJobNoteLoading}
                      onRowClick={handleRowClick}
                      onAddNoteClick={(data) =>
                        handleAddNoteClick(data, 'main')
                      }
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                      onEditNoteClick={(jId, data) =>
                        handleEditNoteClick(jId, data, 'main')
                      }
                      onDeleteNoteClick={(jId, nId) =>
                        handleDeleteNoteClick(jId, nId, true)
                      }
                      onTabClick={(jID, sTab) => {
                        handleSelectedTab(jID, sTab, true);
                      }}
                    />
                  ) : null}
                  {jobSeriesData?.series?.length ? (
                    <JobTableBody
                      selectedNoteId={selectedNoteId}
                      setSelectedNoteId={setSelectedNoteId}
                      sectionTitle={Strings.JOB_SERIES?.toUpperCase()}
                      isShowSectionRow={jobSeriesData?.series?.length}
                      listData={jobSeriesData?.series || []}
                      deleteJobNoteLoading={props?.deleteJobNoteLoading}
                      updateLoading={props?.updateJobSeriesLoading || false}
                      onRowClick={handleRowClick}
                      onAddNoteClick={(data) =>
                        handleAddNoteClick(data, 'series')
                      }
                      onEditClick={handleEditClick}
                      onEditNoteClick={(jId, data) =>
                        handleEditNoteClick(jId, data, 'series')
                      }
                      onDeleteNoteClick={handleDeleteNoteClick}
                      onTabClick={handleSelectedTab}
                      onSeriesDeleteClick={handleDeleteSeries}
                      onUpdateStatusClick={handleUpdateStatus}
                    />
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {jobSeriesData?.totalRecords ? (
          <Stack flex={1} pb={2} alignItems='center'>
            <CustomPagination
              pageCount={pageCount}
              pageSize={pageSize}
              totalRecords={jobSeriesData?.totalRecords || 0}
              handleChange={handlePageChange}
              handlePageSize={handlePageSizeChange}
            />
          </Stack>
        ) : null}
      </Stack>

      {filterDialog ? (
        <JobFilter
          setFilterData={setFilterData}
          filterData={filter}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setFilterDialog(false)}
        />
      ) : null}

      {openEditFilterDialog?.show ? (
        <JobNewFilter
          isEdit={true}
          selectedId={openEditFilterDialog?.value}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setOpenEditFilterDialog(null)}
        />
      ) : null}

      {openJobDetailDialog ? (
        <JobDetails
          onNewClose={() => setOpenJobDetailDialog(false)}
          fetchJobId={selectedJobId}
          isJobSeriesPage
        />
      ) : null}

      {openEditJobDialog ? (
        <NewJob
          isEdit={true}
          data={selectedJob}
          onNewClose={() => {
            setSelectedJob(null);
            setOpenEditJobDialog(false);
          }}
        />
      ) : null}

      {openNoteDialog ? (
        <AddNotes
          isJob
          isEditJobNote={isEditNote}
          selectedId={selectedJobId}
          selectedNoteData={selectedNoteData}
          onNewClose={() => {
            setOpenNoteDialog(false);
            setIsEditNote(false);
            setSelectedNoteData(null);
          }}
        />
      ) : null}

      {openDeleteJobDialog ? (
        <CommonDialog
          hideHeader
          hideFooter
          closeIconClassName={classes.dialog_close_icon}
          dialogBodyClassName={classes.dialog_body}
          onClose={() => setOpenDeleteJobDialog(false)}
        >
          <Stack flex={1} spacing={2} alignItems='center'>
            <span className={classes.dialog_msg}>
              {Strings.ARE_YOU_SURE_DELETE_MAIN_JOB}
            </span>
            <span className={classes.dialog_note_msg}>
              Deleting the main job{' '}
              <b>will also delete all recurring job series.</b>This action
              cannot be reverted.
            </span>
            <Stack
              py={1}
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              gap={2}
            >
              <Button
                text={Strings.NO_KEEP_IT}
                disabled={props.deleteJobLoading}
                containerClassName={classes.dialog_btn_container}
                onClick={() => setOpenDeleteJobDialog(false)}
              />
              <Button
                text={Strings.YES_DELETE}
                containerClassName={classes.dialog_btn_container}
                className={classes.dialog_approve_btn}
                disabled={props.deleteJobLoading}
                loading={props.deleteJobLoading}
                onClick={() => handleDeleteJob()}
              />
            </Stack>
          </Stack>
        </CommonDialog>
      ) : null}

      {openDeleteSeriesDialog ? (
        <CommonDialog
          title={Strings.JOB_SERIES}
          message={Strings.DELETE_JOB_SERIES}
          onSuccess={() => onDeleteSeriesJobs()}
          onClose={() => setOpenDeleteSeriesDialog(false)}
          loading={props.deleteJobSeriesLoading}
        />
      ) : null}
    </>
  );
};

JobSeries.propTypes = {
  listUser: PropTypes.array,
};

const mapStateToProps = (state) => ({
  jobSeriesListData: state?.jobSeriesListData?.data,
  jobSeriesListLoading: state?.jobSeriesListData?.loading,

  updateJobData: state?.updateJobData?.data,

  deleteJobLoading: state?.deleteJobData?.loading,
  deleteJobData: state?.deleteJobData?.data,

  addJobNoteData: state?.addJobNoteData?.data,
  updateJobNoteData: state?.updateJobNoteData?.data,
  deleteJobNoteLoading: state?.deleteJobNoteData?.loading,

  listFilterData: state?.listFilterData?.data,
  listFilterLoading: state?.listFilterData?.loading,

  deleteJobSeriesData: state?.deleteJobSeriesData?.data,
  deleteJobSeriesLoading: state?.deleteJobSeriesData?.loading,

  updateJobSeriesData: state?.updateJobSeriesData?.data,
  updateJobSeriesLoading: state?.updateJobSeriesData?.loading,
});

export default connect(mapStateToProps, {
  fetchJobSeriesList,
  listFilter,
  deleteJob,
  deleteJobNote,
  markAllJobsNotesRead,
  deleteJobSeries,
  updateJobSeries,
})(JobSeries);
