import axios from 'axios';
import { GET_EBLAST } from '../../constants';

export const fetchEBlast = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadEBlast());

  axios
    .get(`/user/eblast`, config)
    .then((res) => dispatch(setEBlast(res)))
    .catch((err) => {
      dispatch(errorEBlast(err.response));
      onError(err.response);
    });
};

export const loadEBlast = () => ({
  type: GET_EBLAST.LOAD,
});

export const setEBlast = (clientList) => ({
  type: GET_EBLAST.SUCCESS,
  payload: clientList.data.data,
});

export const errorEBlast = (error) => ({
  type: GET_EBLAST.FAIL,
  payload: error,
});
