import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
// import Calendar from '../../assets/images/calendar-icon.svg';
// import DownArrowWhite from '../../assets/images/down-arrow-white.svg';

export default makeStyles((theme) => ({
  date_container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  // date: {
  //   padding: 12,
  //   fontSize: 14,
  //   borderRadius: 8,
  //   fontFamily: "'Open Sans', sans-serif",
  //   outline: 'none',
  //   fontWeight: 400,
  //   backgroundColor: 'transparent',
  //   border: `1px solid ${theme.palette.input_border}`,
  //   color: theme.palette.profile_title,
  //   appearance: 'none',
  //   webkitAppearance: 'none',
  //   mozAppearance: 'none',
  //   // background: `transparent url(${
  //   //   theme.palette.mode === 'dark' ? DownArrowWhite : DownArrow
  //   // }) no-repeat right 1rem center/1rem 4rem`,
  //   '&::-webkit-calendar-picker-indicator': {
  //     background: `transparent url(${Calendar})`,
  //     // width: 16,
  //     // height: 16,
  //     backgroundRepeat: 'no-repeat',
  //   },
  // },
  title_color: {
    color: theme.palette.input_placeholder,
  },
  date_label: {
    marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  date_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    position: 'absolute',
    bottom: -25,
    left: 5,
  },
}));
