import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  modal_mask_container: {
    backgroundColor: 'rgba(0, 0, 0, 0.71)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 11,
    top: 15,
    right: 15,
  },
  image_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  image: {
    maxHeight: '90%',
    maxWidth: '94%',
    // objectFit: 'contain',
  },
}));
