import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    PopperProps={{ style: { pointerEvents: 'none' } }}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.title_text,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.title_text,
    color: theme.palette.background,
    maxWidth: 145,
  },
}));

export default function CustomizedTooltips(props) {
  return (
    <BootstrapTooltip title={props.title}>{props.children}</BootstrapTooltip>
  );
}
