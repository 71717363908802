import axios from 'axios';
import { isClient } from '../../../../utils/appUtils';
import { ISSUES_LIST } from '../../../constants';
import moment from 'moment-timezone';

export const fetchIssueList =
  (dataFor, search, onError, category, data = {}) =>
  (dispatch) => {
    const token = localStorage.jwtToken;
    if (!isClient()) {
      data.currentDate = moment().format('YYYY-MM-DD');
      data.timeZone = moment.tz.guess();
    }
    const url = isClient()
      ? `/client/listIssue?searchString=${search}`
      : `/jobs/listIssue?searchString=${search}&category=${category}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'post',
      url: url,
      data,
    };
    dispatch(loadIssueList());
    axios(config)
      .then((res) => dispatch(setIssueList(res.data.data)))
      .catch((err) => {
        dispatch(errorIssueList(err.response));
        onError(err.response);
      });
  };

export const loadIssueList = () => ({
  type: ISSUES_LIST.LOAD,
});

export const setIssueList = (issueList) => ({
  type: ISSUES_LIST.SUCCESS,
  payload: issueList,
});

export const errorIssueList = (error) => ({
  type: ISSUES_LIST.FAIL,
  payload: error,
});
