import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import Button from '../Button';
import { Strings } from '../../utils/strings';
import classNames from 'classnames';
import { icons } from '../../utils/icons';

export default function CommonDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          {!props?.hideHeader ? (
            <div className={classes.dialog_header}>
              <label htmlFor='title'>{props.title}</label>
            </div>
          ) : null}
          <img
            className={classNames(classes.close_icon, {
              [props.closeIconClassName]: props?.closeIconClassName,
            })}
            src={icons(theme).closeIcon}
            alt=''
            onClick={props.onClose}
          />
          <div
            className={classNames(classes.dialog_body, {
              [props.dialogBodyClassName]: props?.dialogBodyClassName,
            })}
          >
            {props?.message ? (
              <p className={classes.dialog_message}>{props.message}</p>
            ) : null}
            {props?.children}
          </div>
          {!props.hideFooter ? (
            <div className={classes.dialog_footer}>
              <Button
                type='button'
                text={
                  props.negativeBtnText ? props.negativeBtnText : Strings.CANCEL
                }
                className={classes.dialog_action_button}
                containerClassName={classNames(
                  classes.dialog_action_button_container,
                  classes.action_margin
                )}
                onClick={props.onClose}
                noClass
                noContainerClass
              />
              <Button
                type='button'
                text={
                  props.positiveBtnText ? props.positiveBtnText : Strings.OK
                }
                loading={props.loading}
                disabled={props.loading}
                containerClassName={classes.dialog_action_button_container}
                className={classes.dialog_action_button}
                loaderColor={theme.palette.background_button_text}
                loaderSize={15}
                onClick={props.onSuccess}
                noClass
                noContainerClass
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
