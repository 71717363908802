import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { icons } from '../../utils/icons';
import { Strings } from '../../utils/strings';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import Button from '../Button';
import { viewRadarLink } from '../../utils/appUtils';

const CommonTempView = (props) => {
  const { time, icon, temp } = props;
  const classes = useStyles();
  return (
    <div className={classes.gridRow}>
      <span className={classes.smallLightText}>{time || ''}</span>
      <img
        alt=''
        src={icon}
        // className={classes.commonIcon}
      />
      <span className={classes.smallLightText}>{`${temp}°F` || ''}</span>
    </div>
  );
};

const CommonForecastView = (props) => {
  const { dayName, icon, minTemp, maxTemp } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.gridColumn}>
      <span className={classes.normalBoldText} style={{ width: 80 }}>
        {dayName || ''}
      </span>
      <div style={{ display: 'flex', width: 35, justifyContent: 'center' }}>
        <img
          src={icon}
          alt=''
          // className={classes.commonIcon}
        />
      </div>
      <div className={classes.gridColumn} style={{ columnGap: 10 }}>
        <span className={classes.smallLightText} style={{ width: 40 }}>
          {`${minTemp}°F` || ''}
        </span>
        <img src={icons(theme).gradientLineIcon} alt='' />
        <span className={classes.smallLightText} style={{ width: 40 }}>
          {`${maxTemp}°F` || ''}
        </span>
      </div>
    </div>
  );
};

const WeatherWidget = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const typeOfWeatherIcon = {
    '01n': icons(theme).sunnyIcon,
    '01d': icons(theme).sunnyIcon,
    '02d': icons(theme).sunBeamIcon,
    '02n': icons(theme).sunBeamIcon,
    '03n': icons(theme).cloudyIcon,
    '03d': icons(theme).cloudyIcon,
    '04n': icons(theme).cloudyIcon,
    '04d': icons(theme).cloudyIcon,
    '09d': icons(theme).sunRainIcon,
    '09n': icons(theme).sunRainIcon,
    '10n': icons(theme).rainyIcon,
    '10n': icons(theme).rainyIcon,
    '11d': icons(theme).thunderStormIcon,
    '11n': icons(theme).thunderStormIcon,
    '13d': icons(theme).snowIcon,
    '13n': icons(theme).snowIcon,
    '50n': icons(theme).mistIcon,
    '50d': icons(theme).mistIcon,
  };

  const typeOfWeatherName = {
    '01n': 'Sunny',
    '01d': 'Sunny',
    '02d': 'Sun Shower',
    '02n': 'Sun Shower',
    '03n': 'Cloudy',
    '03d': 'Cloudy',
    '04n': 'Cloudy',
    '04d': 'Cloudy',
    '09d': 'Rainy',
    '09n': 'Rainy',
    '10n': 'Rainy',
    '10n': 'Rainy',
    '11d': 'Thunder',
    '11n': 'Thunder',
    '13d': 'Snow',
    '13n': 'Snow',
    '50n': 'Mist',
    '50d': 'Mist',
  };

  const { weatherData, currentWeatherData } = props;
  let TodaysWeather = [];
  let Last5DaysWeather = [];
  let firstItems = [];
  let previousItem = {};
  const start = moment(new Date()).startOf('day');
  const end = moment(new Date()).endOf('day');
  const startOfDay = new Date(start).getTime();
  const endOfDay = new Date(end).getTime();

  weatherData?.list?.map((item, index) => {
    let dataTimestamp = item.dt * 1000;
    if (startOfDay <= dataTimestamp && endOfDay >= dataTimestamp) {
      TodaysWeather.push(item);
    }
  });

  weatherData?.list?.map((item, index) => {
    let dataTimestamp = item.dt * 1000;
    if (startOfDay <= dataTimestamp) {
      let today = moment(new Date()).format('dddd');
      let day = moment(dataTimestamp).format('dddd');
      day = day === today ? 'Today' : day;
      if (!firstItems.includes(day)) {
        previousItem = item;
        firstItems.push(day);
        Last5DaysWeather.push({ day: day, data: item });
      } else {
        item.main.temp_min =
          previousItem?.main?.temp_min > item?.main?.temp_min
            ? item?.main?.temp_min
            : previousItem?.main?.temp_min;
        item.main.temp_max =
          previousItem?.main?.temp_max < item?.main?.temp_max
            ? item?.main?.temp_max
            : previousItem?.main?.temp_max;
        previousItem = item;
        if (Last5DaysWeather.length > 0) {
          Last5DaysWeather.map((i) => {
            if (i.day === day) {
              i.data = item;
            }
            return i;
          });
        }
      }
    }
  });
  return (
    <div
      className={classNames(
        classes.mainBox,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.loading && (
        <div className={classes.noDataView}>
          <CircularProgress
            style={{
              color: 'white',
            }}
          />
        </div>
      )}
      {/* {!props.loading && Object.keys(props.coords).length === 0 && (
        <div className={classes.noDataView}>
          <span className={classes.smallLightText}>
            {`Please allow location permission to fetch weather data.`}
          </span>
          {props.notificationType !== 'denied' && (
            <Button
              text={'Grant Permission'}
              className={classes.primary_button}
              onClick={() => props.grantPermission()}
            />
          )}
        </div>
      )} */}
      {!props.loading && Object.keys(currentWeatherData).length > 0 && (
        <div className={classes.innerBox}>
          <a
            target='_blank'
            href={viewRadarLink}
            className={classes.viewRadarText}
          >
            {Strings?.VIEW_RADAR?.toUpperCase()}
          </a>
          <div
            className={classes.justifyCenterContainer}
            style={{ justifyItems: 'center' }}
          >
            <span className={classes.headerText}>
              {/* {weatherData?.city?.name?.toUpperCase()} */}
              {'Naples'.toUpperCase()}
            </span>
          </div>
          <div
            className={classes.justifyCenterContainer}
            style={{ justifyItems: 'center' }}
          >
            <span className={classes.tempText}>{`${parseFloat(
              currentWeatherData?.main?.temp?.toFixed(1)
            )}°F`}</span>
            <div className={classes.gridColumn} style={{ columnGap: 10 }}>
              <img
                alt=''
                // src={typeOfWeatherIcon[currentWeatherData?.weather[0]?.icon] ? typeOfWeatherIcon[currentWeatherData?.weather[0]?.icon]: icons(theme).cloudyIcon}
                src={`http://openweathermap.org/img/wn/${currentWeatherData?.weather[0]?.icon}.png`}
                // className={classes.bigIcon}
              />
              <span className={classes.normalBoldText}>
                {currentWeatherData?.weather[0]?.main}
                {/* {typeOfWeatherName[currentWeatherData?.weather[0]?.icon]} */}
              </span>
            </div>
          </div>
          <div
            className={classNames(
              classes.hourTempContainer,
              classes.gridColumn
            )}
          >
            {TodaysWeather.length > 0 &&
              TodaysWeather.map((item) => {
                return (
                  <CommonTempView
                    time={moment(item.dt * 1000).format('LT')}
                    // icon={typeOfWeatherIcon[item?.weather[0]?.icon] ? typeOfWeatherIcon[item?.weather[0]?.icon] : icons(theme).cloudyIcon}
                    icon={`http://openweathermap.org/img/wn/${item?.weather[0]?.icon}.png`}
                    temp={parseFloat(item?.main?.temp?.toFixed(1))}
                  />
                );
              })}
          </div>
          <div className={classes.forCastContainer}>
            <span className={classes.smallLightText1}>
              {Strings.ONE_WEEK_FORECAST.toUpperCase()}
            </span>
            <div
              className={classNames(classes.hourTempContainer, classes.gridRow)}
              style={{ justifyItems: 'inherit' }}
            >
              {Last5DaysWeather.length > 0 &&
                Last5DaysWeather.map((item) => {
                  return (
                    <CommonForecastView
                      dayName={item?.day}
                      // icon={typeOfWeatherIcon[item?.data?.weather[0]?.icon]? typeOfWeatherIcon[item?.data?.weather[0]?.icon] :icons(theme).cloudyIcon}
                      icon={`http://openweathermap.org/img/wn/${item?.data?.weather[0]?.icon}.png`}
                      minTemp={parseFloat(
                        item?.data?.main?.temp_min?.toFixed(1)
                      )}
                      maxTemp={parseFloat(
                        item?.data?.main?.temp_max?.toFixed(1)
                      )}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeatherWidget;
