import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Attachment(props) {
  const classes = useStyles();
  const theme = useTheme();

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const fetchFileIcon = (type) => {
    if (type === 'image') return icons(theme).imageIcon;
    else if (type === 'doc' || type === 'word' || type === 'docx')
      return icons(theme).docFileIcon;
    else if (type === 'audio') return icons(theme).audioFileIcon;
    else if (type === 'video') return icons(theme).videoFileIcon;
    else if (type === 'xlsx' || type === 'xls' || type === 'csv')
      return icons(theme).excelFileIcon;
    else if (type === 'ppt') return icons(theme).pptFileIcon;
    else if (type === 'zip' || type === 'rar') return icons(theme).zipFileIcon;
    else if (type === 'pdf') return icons(theme).pdfFileIcon;
    else return icons(theme).undefinedFileIcon;
  };

  return (
    <>
      {props?.attachments?.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <label
            className={classNames(classes.input_label, props.titleClassName)}
            htmlFor={'Attachments'}
          >
            {`${props?.title ? props.title : 'Attachments'}  (${
              props?.attachments?.length
            })`}
          </label>
          {props?.attachments.map((item, index) => {
            const fileExtension = item?.fileName?.split('.').pop() || '';
            return (
              <div className={classes.attachment_view} key={index}>
                <div className={classes.image_view}>
                  <LazyLoadImage
                    effect='opacity'
                    placeholderSrc={icons().loading}
                    src={
                      item?.type === 'image'
                        ? item.url
                        : fetchFileIcon(
                            item?.type === 'file' ? fileExtension : item?.type
                          )
                    }
                    alt='not found'
                    className={classes.images}
                    //crossOrigin='Anonymous'
                  />
                  {props.showPreviewIcon && (
                    <div
                      className={classes.preview_icon}
                      onClick={() => props.onClickPreview(item, index)}
                    >
                      <img
                        src={fetchFileIcon(
                          item?.type === 'file' ? fileExtension : item?.type
                        )}
                        alt={'sample'}
                        className={classes.icon_image}
                        crossOrigin='Anonymous'
                      />
                    </div>
                  )}
                </div>
                <div className={classes.img_title_view}>
                  <div className={classes.img_desc}>
                    <div
                      className={classNames(
                        classes.input_label,
                        classes.font_weight_700
                      )}
                      htmlFor={item.fileName}
                    >
                      {item.fileName}
                    </div>
                    <label className={classes.desc}>
                      {`${item.type} • ${formatBytes(item.size)}`}
                    </label>
                  </div>
                  {!props.hideDeleteIcon && (
                    <img
                      src={icons(theme).deleteIcon}
                      alt={'delete_icon'}
                      className={classes.delete_icon}
                      onClick={() => props.onClickRemove(item, index)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
