import axios from 'axios';
import { DELETE_ISSUE } from '../../../constants';

export const deleteIssue = (issueId, closeDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteIssue());

  axios
    .put(`/jobs/deleteIssue?issueId=${issueId}`, {}, config)
    .then((res) => {
      dispatch(setDeleteIssue(res));
      closeDialog();
    })
    .catch((err) => {
      dispatch(errorDeleteIssue(err.response));
      onError(err.response);
    });
};

export const loadDeleteIssue = () => ({
  type: DELETE_ISSUE.LOAD,
});

export const setDeleteIssue = (data) => ({
  type: DELETE_ISSUE.SUCCESS,
  payload: data,
});

export const errorDeleteIssue = (error) => ({
  type: DELETE_ISSUE.FAIL,
  payload: error,
});
