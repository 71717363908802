import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import TableToolbox from '../../../components/TableToolbox';
import NewAnnouncement from '../NewAnnouncement';
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EnhancedTableHead from '../../../components/DataTables/TableHead';
import {
  PAGE_SIZE,
  applySortFilter,
  getComparator,
  getErrorMessage,
  getLocaleStorageVal,
  pageSizeList,
  setLocaleStorageVal,
  statusColor,
  tableCell,
} from '../../../utils/appUtils';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { fetchAnnouncementList } from '../../../redux/actions/adminActions/announcementList';
import { Strings } from '../../../utils/strings';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { debounce } from 'lodash';
import CustomPagination from '../../../components/CustomPagination';
import CustomizedTooltips from '../../../components/CustomizedTooltips';
import { icons } from '../../../utils/icons';
import CommonDialog from '../../../components/CommonDialog';
import { deleteAnnouncement } from '../../../redux/actions/adminActions/deleteAnnouncement';

const announcementDataStore = 'AnnouncementsData';

// ======== Table Head =========
const headCells = [
  { id: 'title', label: 'TITLE', isSort: true },
  { id: 'createdBy', label: 'ADMIN', isSort: false },
  { id: 'visibleTo', label: 'VISIBLE TO', isSort: false },
  { id: 'startDate', label: 'START ON', isSort: true },
  { id: 'endDate', label: 'END ON', isSort: true },
  { id: 'status', label: 'STATUS', isSort: true },
  { id: '' },
];

const AnnouncementList = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const existingAnnData = getLocaleStorageVal(announcementDataStore);
  const pageFilter = existingAnnData?.pageFilter;
  const [search, setSearch] = useState(existingAnnData?.searchStr || '');
  const [newDialog, setNewDialog] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [order, setOrder] = useState(
    existingAnnData?.sortBy
      ? existingAnnData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingAnnData?.sortBy?.key || '');
  const [pageCount, setPageCount] = useState(pageFilter?.pageNumber || 1);
  const [pageSize, setPageSize] = useState(pageFilter?.pageSize || PAGE_SIZE);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [deleteAnnouncementDialog, setDeleteAnnouncementDialog] =
    useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getAnnouncements = (data) => {
    const existData = getLocaleStorageVal(announcementDataStore);
    const newData = structuredClone(data);
    let sortBy = existData?.sortBy;
    if (!newData?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (!newData?.page_size)
      newData.page_size = pageFilter?.pageSize || pageSize;
    if (!newData?.sortBy && sortBy)
      newData.sortBy = { [sortBy?.key]: sortBy?.value };
    props.fetchAnnouncementList('', newData, onError);
  };

  const delayedSearch = useCallback(
    debounce((data) => getAnnouncements(data), 600),
    [pageSize]
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const data = { page_number: 1, searchString: e.target.value };
    delayedSearch(data);
    setLocaleStorageVal(announcementDataStore, {
      ...existingAnnData,
      searchStr: e.target.value,
      pageFilter: { pageNumber: 1, pageSize },
    });
  };

  useEffect(() => {
    getAnnouncements({ searchString: search });
  }, [props.addAnnouncementData, props.deleteAnnouncementData]);

  useEffect(() => {
    setAnnouncementList(props?.announcementListData?.announcements || []);
  }, [props.announcementListData]);

  //===== show Announcement status =====
  const handleStatus = (endDate) => {
    let status;
    if (moment(new Date()) > moment(endDate)) {
      status = 'In Active';
    } else {
      status = 'Active';
    }
    return status;
  };

  // const descendingComparator = (a, b, orderBy) => {
  //   let asceding = b[orderBy] < a[orderBy];
  //   let descding = b[orderBy] > a[orderBy];
  //   if (orderBy === 'title') {
  //     asceding = b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase();
  //     descding = b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase();
  //   } else if (orderBy === 'status') {
  //     let aVal = handleStatus(a?.endDate);
  //     let bVal = handleStatus(b?.endDate);
  //     asceding = bVal?.toLowerCase() < aVal?.toLowerCase();
  //     descding = bVal?.toLowerCase() > aVal?.toLowerCase();
  //   } else if (orderBy === 'visibleTo') {
  //     let aVal = `all ${a[orderBy]?.toString()}`;
  //     let bVal = `all ${b[orderBy]?.toString()}`;
  //     asceding = bVal?.toLowerCase() < aVal?.toLowerCase();
  //     descding = bVal?.toLowerCase() > aVal?.toLowerCase();
  //   } else if (orderBy === 'startDate' || orderBy === 'endDate') {
  //     asceding = moment(b[orderBy]) < moment(a[orderBy]);
  //     descding = moment(b[orderBy]) > moment(a[orderBy]);
  //   }
  //   if (asceding) return -1;
  //   if (descding) return 1;
  //   return 0;
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getAnnouncements({ searchString: search, sortBy });
    setLocaleStorageVal(announcementDataStore, {
      ...existingAnnData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredAnnouncements = applySortFilter(
  //   announcementList,
  //   getComparator(order, orderBy, descendingComparator)
  // );

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    const newData = { searchString: search, page_number: page };
    setPageCount(page);
    if (pageCount !== page) {
      getAnnouncements(newData);
      setLocaleStorageVal(announcementDataStore, {
        ...existingAnnData,
        pageFilter: { pageNumber: page, pageSize },
      });
    }
  };

  // ===== call when pageSize change =====
  const handlePageSize = (val) => {
    const newData = { searchString: search, page_size: val };
    setPageSize(val);
    setPageCount(1);
    getAnnouncements(newData);
    setLocaleStorageVal(announcementDataStore, {
      ...existingAnnData,
      pageFilter: { pageNumber: 1, pageSize: val },
    });
  };

  //===== delete Announcement =====
  const onDeleteData = () => {
    props.deleteAnnouncement(
      {
        announcementId: selectedAnnouncement._id,
      },
      () => setDeleteAnnouncementDialog(false),
      (err) => onError(err)
    );
  };

  return (
    <Stack className={classes.announcement_container}>
      <TableToolbox
        isSearch
        isPrimaryButton
        search={search}
        onChangeSearch={handleSearch}
        onNewClick={() => setNewDialog(true)}
      />
      <div className={classes.list_container}>
        {props.announcementListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {announcementList.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {announcementList.length > 0 &&
                      announcementList?.map((data) => {
                        const {
                          title,
                          visibleTo,
                          startDate,
                          endDate,
                          createdBy,
                        } = data;
                        return (
                          <TableRow key={data._id} sx={{ width: '100%' }}>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {title}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {createdBy?.name || '-'}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {`All ${visibleTo}`}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {`${moment(startDate).format(
                                    'MMM DD, YYYY'
                                  )} at ${moment(startDate).format('hh a')}`}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {`${moment(endDate).format(
                                    'MMM DD, YYYY'
                                  )} at ${moment(endDate).format('hh a')}`}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label
                                  className={classNames(
                                    classes.common_cell,
                                    classes.status_container
                                  )}
                                  style={{
                                    cursor: 'pointer',
                                    backgroundColor: statusColor(
                                      handleStatus(endDate)
                                    ),
                                  }}
                                >
                                  {handleStatus(endDate)}
                                  {/* {moment(new Date()) > moment(endDate)
                                    ? 'In Active'
                                    : 'Active'} */}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack
                                direction='row'
                                justifyContent='end'
                                alignItems='flex-start'
                              >
                                <CustomizedTooltips
                                  title={Strings.DELETE_ANNOUNCEMENT}
                                >
                                  <img
                                    className={classes.action_icons}
                                    src={icons(theme).deleteIcon}
                                    alt=''
                                    onClick={(e) => {
                                      setSelectedAnnouncement(data);
                                      setDeleteAnnouncementDialog(true);
                                    }}
                                  />
                                </CustomizedTooltips>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>

      {props?.announcementListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageSize={pageSize}
            pageCount={pageCount}
            pageSizeList={pageSizeList}
            totalRecords={props?.announcementListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSize}
          />
        </Stack>
      ) : null}

      {newDialog && <NewAnnouncement onNewClose={() => setNewDialog(false)} />}

      {deleteAnnouncementDialog && (
        <CommonDialog
          title={Strings.ANNOUNCEMENT}
          message={Strings.DELETE_ANNOUNCEMENT_QUE}
          onSuccess={() => onDeleteData()}
          onClose={() => {
            setDeleteAnnouncementDialog(false);
          }}
          loading={props.deleteAnnouncementLoading}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  addAnnouncementData: state.addAnnouncementData.data,
  announcementListData: state.announcementListData.data,
  announcementListLoading: state.announcementListData.loading,

  deleteAnnouncementData: state.deleteAnnouncementData.data,
  deleteAnnouncementLoading: state.deleteAnnouncementData.loading,
});

export default connect(mapStateToProps, {
  fetchAnnouncementList,
  deleteAnnouncement,
})(AnnouncementList);
