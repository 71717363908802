import axios from 'axios';
import { DELETE_ISSUE_NOTE } from '../../../constants';

export const deleteIssueNote =
  (iID, nID, nType, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteIssueNote());
    axios
      .put(
        `/issue/deleteIssueNotes?issueId=${iID}&noteId=${nID}&noteType=${nType}`,
        {},
        config,
      )
      .then((res) => {
        dispatch(setDeleteIssueNote(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorDeleteIssueNote(err.response));
        onError(err.response);
      });
  };

export const loadDeleteIssueNote = () => ({
  type: DELETE_ISSUE_NOTE.LOAD,
});

export const setDeleteIssueNote = (data) => ({
  type: DELETE_ISSUE_NOTE.SUCCESS,
  payload: data.data,
});

export const errorDeleteIssueNote = (error) => ({
  type: DELETE_ISSUE_NOTE.FAIL,
  payload: error,
});
