import useStyles from './styles';
import classNames from 'classnames';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import DateSelector from '../DateSelector';

const NextPrevious = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classNames(
        classes.next_previous_container,
        props.containerClassName && props.containerClassName
      )}
      style={props.next_previous_style}
    >
      <img
        src={icons(theme).leftArrow}
        alt=''
        className={classes.next_previous_button}
        onClick={props.onPreviousClick}
      />
      {props.showDateView && (
        <div className={classes.date_view}>
          {props.showStartEndDate === false && props.date}
          {props.showStartEndDate === true && (
            <span>{' ' + props.startEndDate}</span>
          )}
          <span className={classes.date_year}>{' ' + props.year}</span>
        </div>
      )}
      {props.isDay && (
        <DateSelector
          views={['year', 'month', 'day']}
          containerClassName={classes.date_picker}
          onChange={props.onDateChange}
          value={props.value}
        />
      )}
      <img
        src={icons(theme).rightArrow}
        style={{ marginLeft: props.showDateView ? 0 : 8 }}
        alt=''
        className={classes.next_previous_button}
        onClick={props.onNextClick}
      />
    </div>
  );
};

export default NextPrevious;
