import axios from 'axios';
import { GET_LATEST_REPORT } from '../../constants';

export const fetchLatestReport = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadLatestReport());

  axios
    .get('/client/latestReport', config)
    .then((res) => dispatch(setLatestReport(res)))
    .catch((err) => {
      dispatch(errorLatestReport(err.response));
      onError(err.response);
    });
};

export const loadLatestReport = () => ({
  type: GET_LATEST_REPORT.LOAD,
});

export const setLatestReport = (reportData) => ({
  type: GET_LATEST_REPORT.SUCCESS,
  payload: reportData.data.data,
});
export const errorLatestReport = (error) => ({
  type: GET_LATEST_REPORT.FAIL,
  payload: error,
});
