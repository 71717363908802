import axios from 'axios';
import { ANNOUNCEMENT_LIST } from '../../constants';
import { isClient } from '../../../utils/appUtils';
import moment from 'moment-timezone';

export const fetchAnnouncementList =
  (visibleOn, data, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let payload = {
      ...data,
      currentDate: new Date(),
      timeZone: moment.tz.guess(),
    };
    if (!isClient()) payload.visibleOn = visibleOn;

    dispatch(loadAnnouncementList());

    axios
      .post('/admin/listAnnouncement', payload, config)
      .then((res) => dispatch(setAnnouncementList(res)))
      .catch((err) => {
        dispatch(errorAnnouncementList(err.response));
        onError(err.response);
      });
  };

export const loadAnnouncementList = () => ({
  type: ANNOUNCEMENT_LIST.LOAD,
});

export const setAnnouncementList = (announcementList) => ({
  type: ANNOUNCEMENT_LIST.SUCCESS,
  payload: announcementList.data.data,
});

export const errorAnnouncementList = (error) => ({
  type: ANNOUNCEMENT_LIST.FAIL,
  payload: error,
});
