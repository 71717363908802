import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: Colors.EXTRA_GRAY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_container: {
    backgroundColor: theme.palette.dialog_background,
    width: 430,
    height: '70%',
    maxHeight: '70%',
    padding: 25,
    zIndex: 3,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.down('sm')]: {
    //   position: 'fixed',
    //   top: 0,
    //   bottom: 0,
    //   left: 0,
    //   right: 0,
    //   width: 'calc(100% - 50px)',
    //   height: 'calc(100% - 50px)',
    // },
  },
  form_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
    height: '100%',
  },
  form_input_container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
  add_button_container: {
    marginTop: 17,
  },
  add_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  switch_container: {
    marginTop: 12,
  },
  tags_input: {
    marginBottom: 10,
  },
  add_new_media: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    color: Colors.NEVY_BLUE,
    cursor: 'pointer',
    width: 'max-content',
    marginTop: 15,
  },
  plus_icon: {
    height: 25,
    width: 25,
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  media_data: {
    // display: 'flex',
    marginTop: 25,
    gap: 20
  },
  marginBottom: {
    marginBottom: 10,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  close_icon: {
    marginLeft: 10,
    height: 20,
    width: 20,
    marginBottom: 10,
  }
}));
