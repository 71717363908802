import axios from 'axios';
import { SEARCH_MEDIA } from '../../constants';

export const searchMedia = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadSearchMedia());

  axios
    .post(`/media/search`, data, config)
    .then((res) => {
      dispatch(setSearchMedia(res));
    })
    .catch((err) => {
      dispatch(errorSearchMedia(err.response));
      onError(err.response);
    });
};

export const loadSearchMedia = () => ({
  type: SEARCH_MEDIA.LOAD,
});

export const setSearchMedia = (mediaList) => ({
  type: SEARCH_MEDIA.SUCCESS,
  payload: mediaList?.data?.data || [],
});

export const errorSearchMedia = (error) => ({
  type: SEARCH_MEDIA.FAIL,
  payload: error,
});

export const clearSearchMedia = (data) => ({
  type: SEARCH_MEDIA.CLEAR,
  payload: data || [],
});
