import axios from 'axios';
import { DELETE_CATEGORY } from '../../../constants';

export const deleteCategory =
  (type, cID, closeDialog, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteCategory());
    axios
      .delete(`/jobs/deleteCategory/?categoryId=${cID}&type=${type}`, config)
      .then((res) => {
        dispatch(setDeleteCategory(res));
        closeDialog();
      })
      .catch((err) => {
        dispatch(errorDeleteCategory(err.response));
        onError(err.response);
      });
  };

export const loadDeleteCategory = () => ({
  type: DELETE_CATEGORY.LOAD,
});

export const setDeleteCategory = (data) => ({
  type: DELETE_CATEGORY.SUCCESS,
  payload: data,
});

export const errorDeleteCategory = (error) => ({
  type: DELETE_CATEGORY.FAIL,
  payload: error,
});
