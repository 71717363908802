import { filter } from 'lodash';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import TableToolbox from '../../components/TableToolbox';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import { Strings } from '../../utils/strings';
import Tabs from '../../components/Tabs';
import {
  Avatar,
  AvatarGroup,
  Grid,
  Hidden,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import EnhancedTableHead from '../../components/DataTables/TableHead';
import {
  getDateRangeValue,
  getErrorMessage,
  isAdmin,
  isClient,
  isSuperAdmin,
  issuesDetailTabArray,
  issuesDetailTabArrayClient,
  secondaryTableCell,
  statusColor,
  tableCell,
  userId,
  PAGE_SIZE,
  manageNotesHref,
  getLocaleStorageVal,
  setLocaleStorageVal,
  compare2Object,
} from '../../utils/appUtils';
import classNames from 'classnames';
import ActionMenu from './ActionMenu';
import NewIssue from './NewIssue';
import IssuesFilter from './IssuesFilter';
import { connect } from 'react-redux';
import {
  fetchIssueList,
  setIssueList,
} from '../../redux/actions/jobActions/issuesActions/issueList';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import AssignUserDialog from './AssignUserDialog';
import { icons } from '../../utils/icons';
import CommonDialog from '../../components/CommonDialog';
import { deleteIssue } from '../../redux/actions/jobActions/issuesActions/deleteIssue';
import { updateIssueByPerformAction } from '../../redux/actions/jobActions/issuesActions/updateIssueByPerformAction';
import { sendUpdate } from '../../redux/actions/jobActions/issuesActions/sendUpdate';
import { useAlert } from 'react-alert';
import AddNotes from './NotesDialog';
import TitleContainer from '../../components/TitleContainer';
import Button from '../../components/Button';
import ActionDrawer from './ActionDrawer';
import ActionButton from './ActionButton';
import MediaImageContainer from '../../components/MediaImageContainer';
import NewJob from '../Jobs/NewJob';
import { visibleIssue } from '../../redux/actions/jobActions/issuesActions/visibleIssue';
import { deleteIssueNote } from '../../redux/actions/jobActions/issuesActions/deleteIssueNote';
import { clientDirectiveReminder } from '../../redux/actions/jobActions/issuesActions/clientDirectiveReminder';
import debounce from 'lodash/debounce';
import SendReport from '../Jobs/SendReport';
import { listFilter } from '../../redux/actions/filterActions/listFilter';
import IssuesNewFilter from './IssuesFilter/IssuesNewFilter';
import { markAllIssuesNotesRead } from '../../redux/actions/jobActions/issuesActions/markAllIssuesNotesRead';
import { exportIssues } from '../../redux/actions/commonActions/exportIssues';
import { useDispatch } from 'react-redux';
import { setAddIssueNote } from '../../redux/actions/clientActions/addIssueNote';
import { setUpdateIssueNote } from '../../redux/actions/jobActions/issuesActions/updateIssueNote';
import CustomPagination from '../../components/CustomPagination';

const issueDataStore = 'IssuesData';

// ======== Table Head =========
const headCells = () => {
  return !isClient()
    ? [
        { id: 'title', label: 'TASK TITLE', isSort: true },
        // { id: 'client', label: 'CLIENT', isSort: true },
        { id: 'property', label: 'PROPERTY', isSort: true },
        { id: 'type', label: 'TYPE', isSort: true },
        { id: 'category', label: 'CATEGORY', isSort: true },
        { id: 'dueDate', label: 'NEXT STEP DUE', isSort: true },
        { id: 'priority', label: 'PRIORITY', isSort: true },
        // { id: 'assigned', label: 'ASSIGNED' },
        { id: 'status', label: 'STATUS', isSort: true },
        { id: 'vendor', label: 'VENDOR' },
        // { id: 'homeOwnerDirective', label: 'HOME OWNER DIRECTIVE' },
        { id: '' },
      ]
    : [
        { id: 'title', label: 'TASK TITLE', isSort: true },
        { id: 'property', label: 'PROPERTY', isSort: true },
        { id: 'type', label: 'TYPE', isSort: true },
        { id: 'category', label: 'CATEGORY', isSort: true },
        // { id: 'dueDate', label: 'DUE DATE', isSort: true },
        { id: 'status', label: 'STATUS', isSort: true },
        { id: 'vendor', label: 'VENDOR' },
        { id: '' },
      ];
};

const NotesDataList = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (props.data) {
      manageNotesHref('issueNoteData');
    }
  }, [props.data]);

  return (
    <div
      style={{
        borderBottom: !props.isBottomBorder && 'none',
        ...props.style,
      }}
    >
      <div className={classes.flex_row}>
        {!props.isSuppliesNeeded ? (
          <div
            id='issueNoteData'
            className={classes.note_data}
            style={{ border: 0 }}
            dangerouslySetInnerHTML={{ __html: props?.data }}
          />
        ) : (
          <p className={classes.note_data} style={{ border: 0 }}>
            {props?.data}
          </p>
        )}

        {props?.isEditDelete ? (
          <div className={classes.flex_row} style={{ marginLeft: 'auto' }}>
            {/* props?.createdBy?._id === userId() */}
            {!isClient() ||
            (isClient() && props?.createdBy?._id === userId()) ? (
              <CustomizedTooltips title={Strings.EDIT_NOTE}>
                <img
                  className={classes.action_icons}
                  src={icons(theme).editIcon}
                  alt=''
                  onClick={() => props.onEditClick()}
                />
              </CustomizedTooltips>
            ) : null}
            {/* (props?.createdBy?._id === userId() || props?.isCommentHistory) */}
            {!isClient() ||
            (isClient() && props?.createdBy?._id === userId()) ? (
              <>
                {props?.showLoader ? (
                  <CircularProgress
                    style={{
                      color: theme.palette.background_button,
                      marginLeft: 15,
                    }}
                    size={20}
                  />
                ) : (
                  <CustomizedTooltips title={Strings.DELETE_NOTE}>
                    <img
                      className={classes.action_icons}
                      src={icons(theme).deleteIcon}
                      alt=''
                      onClick={() => props.onDeleteClick()}
                      style={{ marginLeft: 15 }}
                    />
                  </CustomizedTooltips>
                )}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      {props.createdBy && (
        <div className={classes.note_details_container}>
          <p
            className={classes.note_data}
            style={{ paddingBottom: 0, marginBottom: 0, borderBottom: 'none' }}
          >
            <span style={{ fontWeight: 600 }}>{Strings.CREATED_BY}: </span>
            {`${props.createdBy.name} ( ${
              moment(props.createdAt).format('ll') + // LL - Jul 14, 2023
              ' ' +
              moment(props.createdAt).format('LT')
            } )`}
          </p>
          {props?.updatedBy ? (
            <p
              className={classes.note_data}
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                borderBottom: 'none',
              }}
            >
              <span style={{ fontWeight: 600 }}>{Strings.UPDATED_BY}: </span>
              {`${props.updatedBy.name} ( ${
                moment(props.updatedAt).format('ll') + // LL - Jul 14, 2023
                ' ' +
                moment(props.updatedAt).format('LT')
              } )`}
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

// function descendingComparator(a, b, orderBy) {
//   let asceding = b[orderBy] < a[orderBy];
//   let descding = b[orderBy] > a[orderBy];
//   if (orderBy === 'issueTitle') {
//     asceding = b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase();
//     descding = b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase();
//   } else if (orderBy === 'client') {
//     asceding =
//       b[orderBy]?.lastName.toLowerCase() < a[orderBy]?.lastName.toLowerCase();
//     descding =
//       b[orderBy]?.lastName.toLowerCase() > a[orderBy]?.lastName.toLowerCase();
//   } else if (orderBy === 'property') {
//     asceding = b[orderBy]?.name.toLowerCase() < a[orderBy]?.name.toLowerCase();
//     descding = b[orderBy]?.name.toLowerCase() > a[orderBy]?.name.toLowerCase();
//   } else if (orderBy === 'dueDate') {
//     asceding = moment(b[orderBy]) < moment(a[orderBy]);
//     descding = moment(b[orderBy]) > moment(a[orderBy]);
//   } else {
//     asceding = b[orderBy] < a[orderBy];
//     descding = b[orderBy] > a[orderBy];
//   }

//   if (asceding) {
//     return -1;
//   }
//   if (descding) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(order, orderBy, array, comparator) {
//   if (!['priority', 'status'].includes(orderBy)) {
//     const stabilizedThis = array?.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//       const order = comparator(a[0], b[0]);
//       if (order !== 0) return order;
//       return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
//   } else if (orderBy === 'priority') {
//     const priorities = {
//       Low: 1,
//       Medium: 2,
//       High: 3,
//       Critical: 4,
//     };
//     let sorted = array.sort(function (a, b) {
//       if (order === 'asc') {
//         return priorities[b.priority] - priorities[a.priority];
//       } else {
//         return priorities[a.priority] - priorities[b.priority];
//       }
//     });
//     return sorted;
//   } else if (orderBy === 'status') {
//     const priorities = {
//       New: 1,
//       'In Progress': 2,
//       "Requested Client's Input": 3,
//       'Team Discussion Needed': 4,
//       'Waiting on Vendor': 5,
//       Completed: 6,
//       Cancelled: 7,
//     };
//     let sorted = array.sort(function (a, b) {
//       if (order === 'asc') {
//         return priorities[b.status] - priorities[a.status];
//       } else {
//         return priorities[a.status] - priorities[b.status];
//       }
//     });
//     return sorted;
//   }
// }

const Issues = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert();
  const existingIssuesData = getLocaleStorageVal(issueDataStore) ?? {};
  let { state } = useLocation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState(existingIssuesData?.searchString ?? '');
  // const [issueData, setIssueData] = useState([]);
  const issueData = props.issueListData;
  const exceptClientIssueData = (issueData && issueData.issues) || [];
  const clientIssueData = (issueData && issueData.issues) || [];
  const tempIssues = isClient() ? clientIssueData : exceptClientIssueData;
  const issues = tempIssues || [];
  const [openRows, setOpenRows] = useState([]);
  const [newIssueDialog, setNewIssueDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [assignUserDialog, setAssignUserDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(isClient() ? 'All' : 'all');
  const [deleteIssueDialog, setDeleteIssueDialog] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState({});
  const [selectedIssueId, setSelectedIssueId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState(
    isAdmin() ? { showAllIssue: false } : null
  );
  const [detailsActiveTab, setDetailsActiveTab] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const laptopView = useMediaQuery(theme.breakpoints.down('lg'));
  const [order, setOrder] = useState(
    existingIssuesData?.sortBy
      ? existingIssuesData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingIssuesData?.sortBy?.key || '');
  const [addJobDialog, setAddJobDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [isClientDirective, setIsClientDirective] = useState({});
  const [isEditIssueNote, setIsEditIssueNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [openSendReportDialog, setOpenSendReportDialog] = useState(false);
  const [selectedIssueClient, setSelectedIssueClient] = useState('');
  const [filterDropdownValue, setFilterDropdownValue] = useState('');
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState();
  const [filterDataList, setFilterDataList] = useState();
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [shouldStateInclude, setShouldStateInclude] = useState(true);

  const tabsArray = [
    { id: 'all', name: 'ALL', length: issueData?.all || 0 },
    {
      id: 'pastDue',
      name: 'PAST DUE',
      length: issueData?.pastDue || 0,
    },
    {
      id: 'dueToday',
      name: 'DUE TODAY',
      length: issueData?.dueToday || 0,
    },
    {
      id: 'dueWeek',
      name: 'DUE THIS WEEK',
      length: issueData?.dueWeek || 0,
    },
    {
      id: 'Critical',
      name: 'CRITICAL',
      length: issueData?.critical || 0,
    },
  ];

  const tabsArrayClient = [
    { id: 'Projects', name: 'PROJECTS', length: issueData?.projects || 0 },
    // {
    //   id: 'Spectrum Maintenance',
    //   name: 'SPECTRUM MAINTENANCE',
    //   length: issueData?.spectrumMaintenance || 0,
    // },
    // {
    //   id: '3rd Party Maintenance',
    //   name: '3RD PARTY MAINTENANCE',
    //   length: issueData?.thirdPartyMaintenance || 0,
    // },
    {
      id: 'Maintenance',
      name: 'MAINTENANCE',
      length: issueData?.maintenance || 0,
    },
    { id: 'Active', name: 'ACTIVE', length: issueData?.active || 0 },
    { id: 'All', name: 'ALL', length: issueData?.all || 0 },
    // { id: 'Open', name: 'OPEN', length: issueData?.open || 0 },
    { id: 'Completed', name: 'COMPLETED', length: issueData?.completed || 0 },
  ];

  // ===== check is filter applied or not =====
  const isFilterApplied = useMemo(() => {
    if (filter && !isAdmin() && Object.keys(filter)?.length) {
      return true;
    } else if (filter && !compare2Object({ showAllIssue: false }, filter)) {
      return true;
    } else return false;
  }, [filter]);

  // === Issues details tabs list ===
  const getIssueTabList = useCallback(
    (data) => {
      if (data) {
        return isClient()
          ? [
              { id: 'details', name: 'Task Summary', hideDataCount: true },
              {
                id: 'notes',
                name: 'Comments and History',
                length: data?.notes?.length || 0,
              },
              { id: 'related-jobs', name: 'Related Jobs', hideDataCount: true },
              { id: 'media', name: 'Media', hideDataCount: true },
            ]
          : [
              { id: 'details', name: 'Task Summary', hideDataCount: true },
              {
                id: 'notes',
                name: 'Comments and History',
                length: data?.notes?.length || 0,
              },
              {
                id: 'internal-notes',
                name: 'Internal Notes',
                length: data?.internalNotes?.length || 0,
              },
              { id: 'related-jobs', name: 'Related Jobs', hideDataCount: true },
              { id: 'supplies', name: 'Supplies Needed', hideDataCount: true },
              { id: 'media', name: 'Media', hideDataCount: true },
            ];
      }
    },
    [issueData]
  );

  // === check any new note/internal notes create and unread or not ===
  const isUnReadData = useCallback(
    (data) => {
      if (data) {
        let isUnreadInternalNotes =
          data?.internalNotes?.some((el) => el?.unReadBy?.includes(userId())) ||
          false;
        let isUnreadNotes =
          data?.notes?.some((el) => el?.unReadBy?.includes(userId())) || false;
        if (isUnreadInternalNotes || isUnreadNotes) return true;
        else return false;
      }
      return false;
    },
    [issueData, state, shouldStateInclude]
  );

  // === manage issueList data ===
  const manageIssueListData = (data) => {
    if (data && Object?.keys(data)?.length && data?.issue) {
      let fetchedData = data?.issue;
      let newData = structuredClone(issueData);
      let newIssues = newData?.issues?.map((el) => {
        if (el?._id === fetchedData?._id) {
          el.notes = fetchedData.notes;
          el.internalNotes = fetchedData.internalNotes;
        }
        return el;
      });
      newData.issues = newIssues;
      dispatch(setIssueList(newData));

      const fIndexOfIssue = newData?.issues?.findIndex(
        (el) => el?._id === selectedIssueId
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfIssue);
      if (fRowIndex === -1) onOpenRow(fIndexOfIssue);
      const selectedTab = data?.internalNotes ? 'internal-notes' : 'notes';
      const tabNewData = [...detailsActiveTab];
      if (fIndexOfIssue !== -1) {
        tabNewData[fIndexOfIssue].key = selectedTab;
        const data = {
          type:
            selectedTab === 'internal-notes' ? 'internalNotes' : selectedTab,
          issueId: selectedIssueId,
        };
        props.markAllIssuesNotesRead(data, onError);
      }
      setDetailsActiveTab(tabNewData);
      setSelectedIssueId('');
    }
  };

  const onOpenRow = (rowIndex) => {
    if (openRows?.findIndex((item, index) => item === rowIndex) === -1) {
      setOpenRows([...openRows, rowIndex]);
    } else {
      setOpenRows(openRows.filter((item) => item !== rowIndex));
    }
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  const getIssueList = (search, data, selectedTab, stateInclude) => {
    const newData = { ...data };
    const pageFilter = existingIssuesData?.pageFilter;
    const sortBy = existingIssuesData?.sortBy;
    if (!data?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (isClient()) newData.tab = selectedTab || activeTab;
    if (!data?.page_size) newData.page_size = pageFilter?.pageSize || pageSize;
    if (!data.sortBy && sortBy)
      newData.sortBy = { [sortBy?.key]: sortBy?.value };
    if (stateInclude && state && state?.selectedIssue) {
      newData.issueId = state?.selectedIssue;
      let newFilter = {};
      if (isAdmin()) newFilter = { showAllIssue: false };
      newData.filter = newFilter;
      setSearch('');
      setFilter(newFilter);
      setFilterDropdownValue('');
    }
    props.fetchIssueList(
      'issues',
      state && state?.selectedIssue && shouldStateInclude ? '' : search,
      (err) => onError(err),
      selectedTab || activeTab,
      newData
    );
  };

  const delayedSearch = useCallback(
    debounce((search, type, data) => {
      props?.fetchIssueList(
        'issues',
        search,
        (err) => onError(err),
        type,
        data
      );
    }, 600),
    []
  );

  // ===== fetch issues list data =====
  useEffect(() => {
    let data = {};
    const storedFilter = existingIssuesData || {};
    const storedPageFilter = existingIssuesData?.pageFilter || {};
    let prevFilter =
      Object.keys(storedFilter).length > 0 ? existingIssuesData : {};
    if (
      prevFilter &&
      (prevFilter?.filterId || prevFilter?.filter || prevFilter?.searchString)
    ) {
      if (prevFilter?.filterId) {
        setFilterDropdownValue(prevFilter?.filterId || '');
      }
      if (prevFilter?.searchString) {
        setSearch(prevFilter?.searchString || '');
      }

      if (prevFilter?.filter) {
        if (prevFilter?.filter?.dateRange !== 'All Time') {
          let dateRange = getDateRangeValue(prevFilter?.filter?.dateRange);
          let filter = {
            ...prevFilter?.filter,
            startDate:
              dateRange.startDate &&
              moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate:
              dateRange.endDate &&
              moment(dateRange.endDate).format('YYYY-MM-DD'),
          };
          prevFilter = { filter: { ...filter } };
        }
        data.filter = {
          ...prevFilter?.filter,
        };
        setFilter(prevFilter?.filter);
      }
    } else {
      if (filter) data.filter = filter;
    }
    setPageCount(storedPageFilter?.pageNumber || 1);
    setPageSize(storedPageFilter?.pageSize || PAGE_SIZE);
    if (state?.shouldStateInclude) setShouldStateInclude(true);
    getIssueList(
      search,
      data,
      activeTab,
      state?.shouldStateInclude || shouldStateInclude
    );
    if (state?.shouldStateInclude) {
      state.shouldStateInclude = false;
    }
  }, [
    state,
    // activeTab,
    props.addIssueData,
    props.updateIssueData,
    props.deleteIssueData,
    props.updateIssueByActionData,
    props.addJobData,
    // props.addIssueNoteData,
  ]);

  // useEffect(() => {
  //   const data = props.issueListData ? props.issueListData : [];
  //   setIssueData(data);
  // }, [props.issueListData]);

  const onDeleteIssue = () => {
    props.deleteIssue(
      selectedIssue._id,
      () => setDeleteIssueDialog(false),
      (err) => onError(err)
    );
  };

  const setFilterData = (data) => {
    // state = null;
    setShouldStateInclude(false);
    if (JSON.stringify(data) === '{}' && filter) {
      // setSearch('');
      setFilter(isAdmin() ? { showAllIssue: false } : null);
      getIssueList(search || '', { page_number: 1 });
      setPageCount(1);
      setFilterDropdownValue('');
      setFilterDataList('');
    } else if (JSON.stringify(data) !== '{}') {
      let newData = JSON.parse(JSON.stringify(data));
      // setSearch('');
      setFilter(data);
      getIssueList(search || '', {
        page_number: 1,
        filter: newData,
      });
      setPageCount(1);
      !compare2Object(existingIssuesData?.filter || {}, data || {}) &&
        setFilterDropdownValue('');
    }
    let filterId =
      JSON.stringify(data) !== '{}' &&
      compare2Object(existingIssuesData.filter || {}, data || {})
        ? existingIssuesData?.filterId
        : '';
    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      filterId: filterId || '',
      pageFilter: { pageNumber: 1, pageSize: pageSize },
    });
  };

  const handleOnAction = async (action, id, client, issueTitle, data) => {
    if (
      action === 'Mark as Completed' ||
      action === 'Proceed' ||
      action === 'Decline'
    ) {
      const data = {
        status:
          action === 'Proceed'
            ? 'In Progress'
            : action === 'Decline'
            ? 'Cancelled'
            : 'Completed',
      };
      props.updateIssueByPerformAction(id, data, (err) => onError(err));
    } else if (action === 'Send Update') {
      //api call for send update
      // props.sendUpdate(id, onSuccess, onError);
      await setSelectedIssueClient(client);
      await setOpenSendReportDialog(true);
      await setSelectedIssueId(id);
    }
    //  else if (action === `Request Client Directive`) {
    //   //open add note dialog
    //   setIsClientDirective({ id: id, action: `Requested Client's Input` });
    //   setAddNoteDialog(true);
    // } else if (action === `Reminder for client directive`) {
    //   props.clientDirectiveReminder(id, onSuccess, onError);
    // }
    else if (action === 'Assign to an user') {
      setSelectedIssueId(id);
      setAssignUserDialog(true);
    } else if (action === 'Notes') {
      setSelectedIssueId(id);
      setAddNoteDialog(true);
    }
    // else if (action === 'Message to admin') {
    //   navigate('/app/chat', {
    //     state: {
    //       admin: client?.adminAssigned?._id,
    //       issueTitle,
    //     },
    //   });
    // }
    else if (action === 'Create a job') {
      setSelectedJob(data);
      setAddJobDialog(true);
    }
  };

  const setTabNotificationData = () => {
    if (issueData?.issues?.length > 0) {
      const newData = issueData?.issues?.map((el, ind) => {
        let isNotReadInternalNotes =
          el?.internalNotes?.length > 0
            ? el?.internalNotes?.some((elm) =>
                elm?.unReadBy?.includes(userId())
              )
            : false;
        let isNotReadNote =
          el?.notes?.length > 0
            ? el?.notes?.some((elm) => elm?.unReadBy?.includes(userId()))
            : false;

        return {
          key: 'details',
          notesNotification: isNotReadNote,
          internalNotesNotification: isNotReadInternalNotes,
        };
      });
      return newData;
    }
  };

  const handleRequestSort = async (event, property) => {
    // state = null;
    setShouldStateInclude(false);
    setOpenRows([]);
    const newData = await setTabNotificationData();
    await setDetailsActiveTab(newData);
    // setDetailsActiveTab(new Array(issues?.length || 0).fill('details'));
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    let data = { sortBy };
    if (filter && Object.keys(filter)?.length) data.filter = filter;
    getIssueList(search || '', data);
    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredIssues = applySortFilter(
  //   order,
  //   orderBy,
  //   issues,
  //   getComparator(order, orderBy)
  // );

  useEffect(() => {
    // if (!isClient()) {
    // const newData = [];
    // for (let i = 0; i < issues?.length || 0; i++) {
    //   newData.push(detailsActiveTab[i] ? detailsActiveTab[i] : 'details');
    // }
    if (issues?.length > 0) {
      const newData = setTabNotificationData();
      setDetailsActiveTab(newData);
    }
    // setDetailsActiveTab(new Array(issues?.length || 0).fill('details'));
    // }
  }, [issueData]);

  const visibleToClient = (issueId, value) => {
    if (issueId) {
      const data = {
        visible: value,
      };
      props.visibleIssue(issueId, data, onError);

      const issueData = props.issueListData;
      const issues = (issueData && issueData.issues) || [];
      const newData = issues.map((item) => {
        if (item._id === issueId) {
          item.visible = value;
        }
        return item;
      });
      issueData.issues = newData;
      dispatch(setIssueList(issueData));
    }
  };

  const onDeleteIssueNoteClick = (iID, nID, nType) => {
    setSelectedNoteId(nID);
    props.deleteIssueNote(iID, nID, nType, onError, () => {
      const newIssueData = props.issueListData;
      const issues = isClient()
        ? newIssueData || []
        : (newIssueData && newIssueData.issues) || [];
      const newData = issues.map((item) => {
        if (item._id === iID) {
          let newIssueNote =
            nType === 'notes'
              ? item?.notes?.filter((el) => el?._id !== nID)
              : item?.internalNotes?.filter((el) => el?._id !== nID);
          item.notes = nType === 'notes' ? newIssueNote : item?.notes;
          item.internalNotes =
            nType === 'internalNotes' ? newIssueNote : item?.internalNotes;
        }
        return item;
      });
      newIssueData.issues = newData;
      dispatch(setIssueList(newIssueData));
      setSelectedNoteId(null);
    });
  };

  useEffect(() => {
    !isFilterChange &&
      props.listFilter(isClient() ? 'ISSUE_CLIENT' : 'ISSUE', onError);
  }, []);

  useEffect(() => {
    if (isFilterChange) {
      // state = null;
      setShouldStateInclude(false);
      const storedFilter = existingIssuesData?.filter ?? {};
      let prevFilter =
        Object.keys(storedFilter).length > 0 ? existingIssuesData : {};
      if (existingIssuesData?.filterId) {
        setLocaleStorageVal(issueDataStore, {
          ...existingIssuesData,
        });
      }
      props.listFilter(isClient() ? 'ISSUE_CLIENT' : 'ISSUE', onError);
      filterDropdownValue &&
        getIssueList(
          '',
          isAdmin() ? { page_number: 1, filter: { showAllIssue: false } } : {}
        );
      setFilter(isAdmin() ? { showAllIssue: false } : null);
      setFilterDropdownValue('');
      setIsFilterChange(false);
    }
  }, [isFilterChange]);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  const handleFilterSelectionClick = (value) => {
    // state = null;
    setShouldStateInclude(false);
    let filterData = filterDropdownList?.filter((el) => el.value === value)[0];
    let newFilter = filterData?.filter
      ? filterData?.filter
      : isAdmin()
        ? { showAllIssue: false }
        : null;
    setFilterDataList(newFilter);
    setFilter(newFilter);
    if (newFilter) existingIssuesData.filter = { ...newFilter }
    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      pageFilter: { pageNumber: 1, pageSize },
      filterId: value,
    });
    if (filterData?.filter?.dateRange) {
      const dateRange = getDateRangeValue(filterData.filter.dateRange);
      if (filterData.filter.dateRange !== 'All Time') {
        filterData.filter.startDate =
          dateRange.startDate &&
          moment(dateRange.startDate).format('YYYY-MM-DD');
        filterData.filter.endDate =
          dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DD');
      }
    }
    // setSearch('');
    let data = { page_number: 1 };
    if (filterData?.filter) data.filter = filterData?.filter;
    if (!filterData?.filter && isAdmin()) data.filter = { showAllIssue: false };
    getIssueList(search || '', data);
  };

  const onClickRefresh = () => {
    // state = null;
    setShouldStateInclude(false);
    let data = {};
    if (filter) data.filter = filter;
    // setPageCount(1);
    getIssueList(search, data);
  };

  const handleTabChange = (selectedTab) => {
    // state = null;
    setShouldStateInclude(false);
    let data = { page_number: 1 };
    if (isAdmin()) data.filter = { showAllIssue: false };
    getIssueList('', data, selectedTab);
    setPageCount(1);
    setSearch('');
    setFilter(isAdmin() ? { showAllIssue: false } : null);
    setActiveTab(selectedTab);
    setFilterDropdownValue('');
    localStorage.removeItem(issueDataStore);
    if (activeTab !== selectedTab) setOpenRows([]);
  };

  const onClickClearFilter = async () => {
    // state = null;
    setShouldStateInclude(false);
    // setSearch('');
    setFilterDropdownValue('');
    setPageCount(1);
    if (isAdmin()) {
      await setFilter({ showAllIssue: false });
      await getIssueList(search, {
        page_number: 1,
        filter: { showAllIssue: false },
      });
    } else {
      await setFilter(null);
      await getIssueList(search, { page_number: 1 });
    }
    setPageSize(PAGE_SIZE);
    setLocaleStorageVal(issueDataStore, {
      // searchString: existingIssuesData?.searchString,
      ...existingIssuesData,
      pageFilter: {},
      filter: isAdmin() ? { showAllIssue: false } : null,
      filterId: '',
    });
  };

  const handleExportIssues = () => {
    let fileName = 'Issue';
    let issueInfo = {};
    if (filter || filterDataList) {
      issueInfo = {
        searchString: search,
        category: activeTab,
        filter: filterDataList ? filterDataList : filter,
      };
    } else {
      issueInfo = {
        searchString: search,
        category: activeTab,
      };
    }
    props.exportIssues(issueInfo, fileName, onError);
  };

  // ===== notification related changes =====
  useEffect(() => {
    if (state && issueData?.issues?.length > 0) {
      const fIndexOfIssue = issueData?.issues?.findIndex(
        (el) => el?._id === state.selectedIssue
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfIssue);
      if (fRowIndex === -1) onOpenRow(fIndexOfIssue);
      if (state?.selectedTab) {
        const newData = setTabNotificationData();
        if (fIndexOfIssue !== -1) {
          newData[fIndexOfIssue].key = state.selectedTab;
          let selectedTabKey =
            state?.selectedTab === 'internal-notes'
              ? 'internalNotesNotification'
              : 'notesNotification';
          newData[fIndexOfIssue][selectedTabKey] = false;
          const data = {
            type:
              state.selectedTab === 'internal-notes'
                ? 'internalNotes'
                : state.selectedTab,
            issueId: state.selectedIssue,
          };
          props.markAllIssuesNotesRead(data, onError);
        }
        setDetailsActiveTab(newData);
      }
      const elem = document.getElementById(state.selectedIssue);
      if (elem) elem?.scroll({ left: 0, top: 0, behavior: 'smooth' });
      // state = null;
      if (!state?.shouldStateInclude) setShouldStateInclude(false);
    }
  }, [state, issueData]);

  // === clear issueList reducer data ===
  useEffect(() => {
    return () => {
      dispatch(setIssueList({}));
    };
  }, []);

  // ==== when add/edit note open selectedIssue and selectedTab ====
  const setAddEditNotes = async (data) => {
    if (data && Object?.keys(data)?.length > 0) {
      const newIssueData = issueData;
      const issues = newIssueData?.issues || [];
      const newData = issues?.map((item) => {
        if (item._id === selectedIssueId) {
          let newIssueNote = [];
          let selectedTabData = data?.internalNotes ? 'internalNotes' : 'notes';
          let itemRemoveArray = data?.internalNotes ? 'notes' : 'internalNotes';
          if (selectedNoteId) {
            let fIndex = item[itemRemoveArray]?.findIndex(
              (el) => el?._id === selectedNoteId
            );
            if (fIndex !== -1 && typeof fIndex === 'number') {
              let newNotes = item[itemRemoveArray]?.filter(
                (el) => el?._id !== selectedNoteId
              );
              item[itemRemoveArray] = newNotes;
              newIssueNote = [data, ...item[selectedTabData]];
            } else {
              newIssueNote = item[selectedTabData]?.map((el) => {
                if (el?._id === selectedNoteId) {
                  // el.note = data?.note;
                  el = data;
                }
                return el;
              });
            }
          } else {
            newIssueNote = [data, ...item[selectedTabData]];
          }
          item.notes = data?.internalNotes ? item?.notes : newIssueNote;
          item.internalNotes = data?.internalNotes
            ? newIssueNote
            : item?.internalNotes;
        }
        return item;
      });
      newIssueData.issues = newData;
      dispatch(setIssueList(newIssueData));
      dispatch(setAddIssueNote(null));
      dispatch(setUpdateIssueNote(null));
      const fIndexOfIssue = issueData?.findIndex(
        (el) => el?._id === selectedIssueId
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfIssue);
      if (fRowIndex === -1) onOpenRow(fIndexOfIssue);
      const selectedTab = data?.internalNotes ? 'internal-notes' : 'notes';
      const tabNewData = [...detailsActiveTab];
      if (fIndexOfIssue !== -1) {
        tabNewData[fIndexOfIssue].key = selectedTab;
        const data = {
          type:
            selectedTab === 'internal-notes' ? 'internalNotes' : selectedTab,
          issueId: selectedIssueId,
        };
        props.markAllIssuesNotesRead(data, onError);
      }
      setDetailsActiveTab(tabNewData);
      setSelectedNoteId(null);
      setSelectedIssueId('');
    }
  };

  useEffect(() => {
    const data = selectedNoteId
      ? props.updateIssueNoteData || {}
      : props.addIssueNoteData || {};
    if (
      data &&
      issueData?.issues?.length > 0 &&
      (props.addIssueNoteData || props.updateIssueNoteData)
    ) {
      setAddEditNotes(data);
    }
  }, [issueData, props.addIssueNoteData, props.updateIssueNoteData]);

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    if (pageCount !== page) {
      // state = null;
      setShouldStateInclude(false);
      setPageCount(page);
      const newData = { page_number: page };
      if (filter && Object.keys(filter)?.length) newData.filter = filter;
      getIssueList(search || '', newData);
      setOpenRows([]);

      setLocaleStorageVal(issueDataStore, {
        ...existingIssuesData,
        pageFilter: { pageNumber: page, pageSize: pageSize },
      });
    }
  };

  // ===== call when pageSize change =====
  const handlePageSizeChange = (pSize) => {
    // state = null;
    setShouldStateInclude(false);
    setPageCount(1);
    setPageSize(pSize);
    const newData = {
      page_number: 1,
      page_size: pSize,
    };
    if (filter && Object.keys(filter)?.length) newData.filter = filter;
    getIssueList(search || '', newData);
    setOpenRows([]);

    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      pageFilter: { pageNumber: 1, pageSize: pSize },
    });
  };

  // ===== sort data by date descending order =====
  const sortData = (array) => {
    const newData = structuredClone(array);
    let data = newData?.sort((a, b) => {
      if (moment(a.createdAt) < moment(b.createdAt)) return 1;
      if (moment(a.createdAt) > moment(b.createdAt)) return -1;
      return 0;
    });
    return data || array;
  };

  return (
    <Stack
      className={
        !isClient()
          ? classes.issues_container
          : classes.issues_container_clientLogin
      }
    >
      <TitleContainer
        containerClassName={classes.title_container}
        title={Strings.ISSUES}
      />
      <TableToolbox
        onClickRefresh={onClickRefresh}
        onClickClearFilter={onClickClearFilter}
        isFilter
        isSearch
        isDownloadCsv={isAdmin() || isSuperAdmin ? true : false}
        isPrimaryButton
        // isPrint={isAdmin() ? true : false}
        search={search}
        onChangeSearch={(e) => {
          const newFilter =
            filter && Object.entries(filter)?.length
              ? filter
              : isAdmin()
                ? { filter: { showAllIssue: false } }
                : null;
          let newData = {
            page_size: pageSize,
            page_number: 1,
          };
          let sortBy = existingIssuesData?.sortBy;
          if (sortBy) newData.sortBy = { [sortBy?.key]: sortBy?.value };
          if (newFilter) {
            existingIssuesData.filter = newFilter
            newData.filter = newFilter
          }
          delayedSearch(e.target.value, activeTab, newData);
          setLocaleStorageVal(issueDataStore, {
            ...existingIssuesData,
            pageFilter: { pageNumber: 1, pageSize },
            searchString: e.target.value,
          });
          // setFilter(isAdmin() ? { showAllIssue: false } : null);
          setSearch(e.target.value);
          // setFilterDropdownValue('');
          setPageCount(1);
          // getIssueList(e.target.value, {});
        }}
        searchBoxContainerClassName={classes.searchbox_container_1}
        onNewClick={() => setNewIssueDialog(true)}
        filterButtonClick={() => setFilterDialog(true)}
        onDownloadCsvClick={() =>
          !props.exportIssuesLoading && handleExportIssues()
        }
        downloadCsvLoader={props.exportIssuesLoading}
        toolboxContainerClassName={
          !isClient()
            ? classes.toolbox_container
            : classes.toolbox_container_clientLogin
        }
        isFilterDropdown={filterDropdownList.length ? true : false}
        filterDropdownValue={filterDropdownValue}
        filterDropdownList={filterDropdownList}
        onChangeFilterDropdown={(e) => {
          setFilterDropdownValue(e);
          handleFilterSelectionClick(e);
          // setFilter({});
        }}
        onEditClick={(value) =>
          setOpenEditFilterDialog({ show: true, ...value })
        }
      />
      {isFilterApplied ? (
        <p className={classes.filteredTitleText}>Filter Applied</p>
      ) : null}

      <Stack>
        <Tabs
          dataList={isClient() ? tabsArrayClient : tabsArray}
          activeTab={activeTab}
          onChange={handleTabChange}
          containerClassName={classes.tab_container}
          disabledBtn={props?.issueListLoading}
        />
      </Stack>
      <div
        className={
          !isClient() && !mobileView
            ? classes.list_container
            : classes.list_container_client_job
        }
      >
        {props.issueListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {issueData && issueData?.issues?.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              // <TableContainer className='dataTable'>
              <Table
                className={classes.list_table}
                aria-labelledby='tableTitle'
                stickyHeader={isClient()}
              >
                <Hidden smDown>
                  <EnhancedTableHead
                    handleRequestSort={handleRequestSort}
                    headCells={headCells()}
                    order={order}
                    orderBy={orderBy}
                  />
                </Hidden>
                <TableBody>
                  {issueData &&
                    issueData?.issues &&
                    issueData?.issues.map((data, index) => {
                      const {
                        issueTitle,
                        client,
                        property,
                        type,
                        dueDate,
                        priority,
                        assignedTo,
                        status,
                        vendor,
                        category,
                        // homeOwnerDirective,
                        // issue,
                        notes,
                        description,
                        attachments,
                        _id,
                        createdBy,
                        internalNotes,
                        visible,
                        linkedJobs,
                        suppliesNeeded,
                      } = data;
                      return (
                        <>
                          <TableRow
                            id={data._id}
                            key={data._id}
                            className={classes.row_style}
                            onClick={() => onOpenRow(index)}
                            onDoubleClick={(e) => {
                              e.preventDefault();
                              setIsEdit(true);
                              setSelectedIssue(data);
                              setNewIssueDialog(true);
                            }}
                          >
                            <Hidden smUp>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack direction='row' alignItems='center'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.includes(index)
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                    // onClick={() => onOpenRow(index)}
                                  />
                                  <Stack direction='column'>
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      gap={0.75}
                                    >
                                      {isUnReadData(data) ? (
                                        <div className={classes.redDot} />
                                      ) : null}
                                      <label className={classes.name_cell}>
                                        {issueTitle}
                                      </label>
                                    </Stack>
                                    <Stack
                                      paddingTop={1.5}
                                      direction='row'
                                      alignItems='center'
                                    >
                                      {!isClient() && (
                                        <label className={classes.common_cell}>
                                          {moment(dueDate).format(
                                            'MMM D, YYYY'
                                          )}
                                        </label>
                                      )}

                                      <label
                                        className={classNames(
                                          classes.common_cell,
                                          isClient()
                                            ? classes.status_container1
                                            : classes.status_container
                                        )}
                                        style={{
                                          backgroundColor: statusColor(status),
                                        }}
                                      >
                                        {status}
                                      </label>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </TableCell>
                            </Hidden>
                            <Hidden smDown>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack direction='row' alignItems='center'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.includes(index)
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                    // onClick={() => onOpenRow(index)}
                                  />
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    gap={0.75}
                                  >
                                    {isUnReadData(data) ? (
                                      <div className={classes.redDot} />
                                    ) : null}
                                    <label className={classes.name_cell}>
                                      {issueTitle}
                                    </label>
                                  </Stack>
                                </Stack>
                              </TableCell>
                              {/* {!isClient() && (
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index)
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                  >
                                    <Stack
                                      paddingTop={2.5}
                                      direction='column'
                                      alignItems='flex-start'
                                    >
                                      <label className={classes.common_cell}>
                                        {client?.lastName}
                                      </label>
                                    </Stack>
                                  </TableCell>
                                )} */}
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {property?.name}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {type}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {category?.categoryName
                                      ? category?.categoryName
                                      : '-'}
                                  </label>
                                </Stack>
                              </TableCell>

                              {!isClient() && (
                                <>
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index)
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                  >
                                    <Stack
                                      direction='column'
                                      alignItems='flex-start'
                                      style={{ minWidth: 120 }}
                                    >
                                      <label className={classes.common_cell}>
                                        {moment(dueDate).format('MMM D, YYYY')}
                                      </label>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index)
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                  >
                                    {priority && (
                                      <Stack
                                        direction='column'
                                        alignItems='flex-start'
                                      >
                                        <label
                                          className={classNames(
                                            classes.common_cell,
                                            classes.status_container,
                                            priority === 'Critical' &&
                                              classes.priority_critical,
                                            priority === 'High' &&
                                              classes.priority_high
                                          )}
                                        >
                                          {priority}
                                        </label>
                                      </Stack>
                                    )}
                                  </TableCell>
                                  {/* <TableCell
                                      style={{
                                        ...tableCell(
                                          theme,
                                          openRows.includes(index),
                                        ),
                                      }}
                                      component='td'
                                      scope='row'
                                    >
                                      <Stack
                                        paddingTop={2.5}
                                        direction='column'
                                        alignItems='flex-start'
                                      >
                                        <AvatarGroup
                                          sx={{
                                            '& .MuiAvatar-root': {
                                              width: 24,
                                              height: 24,
                                              fontFamily:
                                                "'Open Sans', sans-serif",
                                              fontSize: 14,
                                              fontWeight: 400,
                                              border: `1px solid ${theme.palette.background_button_text}`,
                                              color:
                                                theme.palette.profile_title,
                                              backgroundColor:
                                                theme.palette
                                                  .table_head_background,
                                            },
                                          }}
                                          spacing={5}
                                          max={3}
                                        >
                                          {assignedTo.map((item) => (
                                            <Avatar
                                              className={classNames(
                                                classes.common_cell,
                                              )}
                                              alt=''
                                              src={item.profilePicture}
                                            />
                                          ))}
                                        </AvatarGroup>
                                      </Stack>
                                    </TableCell> */}
                                </>
                              )}
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label
                                    className={classNames(
                                      classes.common_cell,
                                      classes.status_container
                                      // status === 'Active' &&
                                      //   classes.status_active,
                                      // status === 'InActive' &&
                                      //   classes.status_inactive,
                                    )}
                                    style={{
                                      backgroundColor: statusColor(status),
                                    }}
                                  >
                                    {status}
                                  </label>
                                </Stack>
                              </TableCell>

                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {vendor?.fullName ? vendor?.fullName : '-'}
                                  </label>
                                </Stack>
                              </TableCell>
                              {!isClient() && (
                                <>
                                  <TableCell
                                    style={{
                                      ...tableCell(
                                        theme,
                                        openRows.includes(index)
                                      ),
                                    }}
                                    component='td'
                                    scope='row'
                                  >
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      justifyContent='end'
                                      style={{ minWidth: 141 }}
                                    >
                                      <CustomizedTooltips
                                        title={Strings.EDIT_ISSUE}
                                      >
                                        <img
                                          className={classNames(
                                            classes.action_icons,
                                            // classes.action_visible,
                                            openRows.includes(index) &&
                                              classes.action
                                          )}
                                          src={icons(theme).editIcon}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsEdit(true);
                                            setSelectedIssue(data);
                                            setNewIssueDialog(true);
                                            setSelectedIssueId(_id);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                      <CustomizedTooltips
                                        title={
                                          visible
                                            ? Strings.CURRENTLE_VISIBLE_TO_CLIENT
                                            : Strings.CURRENTLE_NOT_VISIBLE_TO_CLIENT
                                        }
                                      >
                                        {props.visibleIssueLoading &&
                                        selectedIssue?._id === _id ? (
                                          <CircularProgress
                                            size={20}
                                            style={{
                                              color:
                                                theme.palette.background_button,
                                              marginLeft: 15,
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className={classNames(
                                              classes.action_icons,
                                              // classes.action_visible,
                                              openRows.includes(index) &&
                                                classes.action
                                            )}
                                            style={{
                                              marginLeft: 15,
                                            }}
                                            src={
                                              visible
                                                ? icons(theme).eyeIconBlue
                                                : icons(theme).eyeOffIconRed
                                            }
                                            alt=''
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setSelectedIssue(data);
                                              visibleToClient(
                                                _id,
                                                visible ? false : true
                                              );
                                            }}
                                          />
                                        )}
                                      </CustomizedTooltips>
                                      <CustomizedTooltips
                                        title={Strings.DELETEISSUE}
                                      >
                                        <img
                                          className={classNames(
                                            classes.action_icons,
                                            // classes.action_visible,
                                            openRows.includes(index) &&
                                              classes.action
                                          )}
                                          style={{
                                            marginLeft: 15,
                                          }}
                                          src={icons(theme).deleteIcon}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedIssue(data);
                                            setDeleteIssueDialog(true);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                      <CustomizedTooltips
                                        title={
                                          isClient()
                                            ? Strings.ADD_COMMENT
                                            : Strings.ADD_NOTE
                                        }
                                      >
                                        <img
                                          className={classes.action_icons}
                                          style={{ marginLeft: 15 }}
                                          src={icons(theme).addNote}
                                          alt=''
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedIssueId(_id);
                                            setAddNoteDialog(true);
                                          }}
                                        />
                                      </CustomizedTooltips>
                                    </Stack>
                                  </TableCell>
                                </>
                              )}
                              {isClient() && (
                                <TableCell
                                  style={{
                                    ...tableCell(
                                      theme,
                                      openRows.includes(index)
                                    ),
                                  }}
                                  component='td'
                                  scope='row'
                                >
                                  <Stack
                                    direction='column'
                                    alignItems='flex-start'
                                  >
                                    <CustomizedTooltips
                                      title={Strings.ADD_COMMENT}
                                    >
                                      <img
                                        className={classes.action_icons}
                                        style={{ marginLeft: 15 }}
                                        src={icons(theme).addNote}
                                        alt=''
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setSelectedIssueId(_id);
                                          setAddNoteDialog(true);
                                        }}
                                      />
                                    </CustomizedTooltips>
                                  </Stack>
                                </TableCell>
                              )}
                            </Hidden>
                            <Hidden smUp>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                                align='right'
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  justifyContent='end'
                                >
                                  <CustomizedTooltips
                                    title={
                                      isClient()
                                        ? Strings.ADD_COMMENT
                                        : Strings.ADD_NOTE
                                    }
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).addNote}
                                      alt=''
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedIssueId(_id);
                                        setAddNoteDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                </Stack>
                              </TableCell>
                            </Hidden>
                          </TableRow>
                          {openRows.includes(index) && (
                            <TableRow key={data._id}>
                              <TableCell
                                style={{
                                  ...secondaryTableCell(theme, true),
                                  maxWidth: mobileView ? 250 : 'auto',
                                }}
                                component='td'
                                scope='row'
                                colSpan={mobileView ? 2 : 10}
                              >
                                <Grid container>
                                  <Grid item xs={12} sm={10}>
                                    <Stack flexDirection='column'>
                                      <Tabs
                                        underlineTabs
                                        // noDataLength
                                        containerClassName={
                                          classes.issue_details_container
                                        }
                                        tabClassName={classes.issue_details_tab}
                                        dataList={getIssueTabList(data)}
                                        activeTab={detailsActiveTab[index]?.key}
                                        notesNotification={
                                          detailsActiveTab[index]
                                            ?.notesNotification
                                        }
                                        internalNotesNotification={
                                          detailsActiveTab[index]
                                            ?.internalNotesNotification
                                        }
                                        onChange={(value) => {
                                          let newTab = [...detailsActiveTab];
                                          const data = {
                                            type:
                                              value === 'internal-notes'
                                                ? 'internalNotes'
                                                : value,
                                            issueId: _id,
                                          };
                                          if (
                                            !isClient() &&
                                            ((value === 'notes' &&
                                              newTab[index]
                                                .notesNotification) ||
                                              (value === 'internal-notes' &&
                                                newTab[index]
                                                  .internalNotesNotification))
                                          ) {
                                            setSelectedIssueId(_id);
                                            props.markAllIssuesNotesRead(
                                              data,
                                              onError,
                                              manageIssueListData
                                            );
                                          }
                                          newTab[index].key = value;
                                          if (value === 'notes')
                                            newTab[
                                              index
                                            ].notesNotification = false;
                                          if (value === 'internal-notes')
                                            newTab[
                                              index
                                            ].internalNotesNotification = false;
                                          setDetailsActiveTab(newTab);
                                        }}
                                        tabIndex={index}
                                      />
                                      {detailsActiveTab[index]?.key ===
                                        'notes' && (
                                        <>
                                          {sortData(notes)?.map(
                                            (item, index) => (
                                              <NotesDataList
                                                isEditDelete
                                                isCommentHistory
                                                data={item.note}
                                                createdBy={item.createdBy}
                                                createdAt={item.createdAt}
                                                updatedBy={item.updatedBy}
                                                updatedAt={item.updatedAt}
                                                isBottomBorder={
                                                  notes?.length !== index + 1
                                                }
                                                onEditClick={() => {
                                                  setAddNoteDialog(true);
                                                  setSelectedNote({
                                                    ...item,
                                                    issueId: _id,
                                                    isInternalNote: false,
                                                  });
                                                  setIsEditIssueNote(true);
                                                  setSelectedNoteId(item?._id);
                                                  setSelectedIssueId(_id);
                                                }}
                                                onDeleteClick={() => {
                                                  onDeleteIssueNoteClick(
                                                    _id,
                                                    item?._id,
                                                    'notes'
                                                  );
                                                }}
                                                showLoader={
                                                  props?.deleteIssueNoteLoading &&
                                                  selectedNoteId === item?._id
                                                }
                                              />
                                            )
                                          )}
                                        </>
                                      )}
                                      {detailsActiveTab[index]?.key ===
                                        'details' && (
                                        <>
                                          <NotesDataList data={description} />
                                          {createdBy && createdBy.name && (
                                            <NotesDataList
                                              data={`${Strings.REPORTED_BY} ${createdBy?.name}`}
                                              style={{ marginTop: 15 }}
                                            />
                                          )}
                                        </>
                                      )}
                                      {detailsActiveTab[index]?.key ===
                                        'media' && (
                                        <>
                                          {attachments &&
                                            attachments.length > 0 && (
                                              <div>
                                                <MediaImageContainer
                                                  imageArray={sortData(
                                                    attachments
                                                  )}
                                                  itemsPerRow={
                                                    mobileView
                                                      ? 2
                                                      : tabletView
                                                      ? 3
                                                      : laptopView
                                                      ? 4
                                                      : 5
                                                  }
                                                  showImageText={true}
                                                  // showTitleOnly
                                                  isReports
                                                />
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {detailsActiveTab[index]?.key ===
                                        'internal-notes' &&
                                        (isAdmin() || isSuperAdmin()) && (
                                          <>
                                            {sortData(internalNotes)?.map(
                                              (item, index) => (
                                                <NotesDataList
                                                  isEditDelete
                                                  data={item.note}
                                                  createdBy={item.createdBy}
                                                  createdAt={item.createdAt}
                                                  updatedBy={item.updatedBy}
                                                  updatedAt={item.updatedAt}
                                                  isBottomBorder={
                                                    notes?.length !== index + 1
                                                  }
                                                  onEditClick={() => {
                                                    setAddNoteDialog(true);
                                                    setSelectedNote({
                                                      ...item,
                                                      issueId: _id,
                                                      isInternalNote: true,
                                                    });
                                                    setIsEditIssueNote(true);
                                                    setSelectedNoteId(
                                                      item?._id
                                                    );
                                                    setSelectedIssueId(_id);
                                                  }}
                                                  onDeleteClick={() => {
                                                    onDeleteIssueNoteClick(
                                                      _id,
                                                      item?._id,
                                                      'internalNotes'
                                                    );
                                                  }}
                                                  showLoader={
                                                    props?.deleteIssueNoteLoading &&
                                                    selectedNoteId === item?._id
                                                  }
                                                />
                                              )
                                            )}
                                          </>
                                        )}
                                      {detailsActiveTab[index]?.key ===
                                        'related-jobs' && (
                                        <>
                                          {linkedJobs?.map((item) => {
                                            return (
                                              <div
                                                className={
                                                  classes.relatedJob_container
                                                }
                                              >
                                                <p
                                                  className={classes.note_data}
                                                  style={{
                                                    border: 0,
                                                    width: mobileView
                                                      ? '100%'
                                                      : '30%',
                                                  }}
                                                >
                                                  {item.title}
                                                </p>
                                                {item?.report && (
                                                  <Button
                                                    text={Strings.VIEW_REPORT}
                                                    className={classes.button}
                                                    loaderColor={
                                                      theme.palette
                                                        .background_button_text
                                                    }
                                                    onClick={() => {
                                                      navigate('/app/report', {
                                                        state: {
                                                          jobId: item._id,
                                                        },
                                                      });
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}
                                      {detailsActiveTab[index]?.key ===
                                        'supplies' && (
                                        <>
                                          <NotesDataList
                                            data={suppliesNeeded}
                                            isSuppliesNeeded
                                          />
                                        </>
                                      )}
                                    </Stack>
                                    {!isClient() && (
                                      <Hidden smUp>
                                        <Stack
                                          paddingTop={0.5}
                                          direction='column'
                                          alignItems='flex-start'
                                        >
                                          <ActionButton
                                            text={Strings.ACTIONS}
                                            onClick={() => setDrawerOpen(true)}
                                          />
                                        </Stack>
                                        <ActionDrawer
                                          id={_id}
                                          onClose={() => setDrawerOpen(false)}
                                          open={drawerOpen}
                                          onSelect={(action, id) => {
                                            handleOnAction(
                                              action,
                                              id,
                                              client,
                                              issueTitle,
                                              data
                                            );
                                            setDrawerOpen(false);
                                          }}
                                          status={status}
                                        />
                                      </Hidden>
                                    )}
                                  </Grid>
                                  {!isClient() && (
                                    <Hidden smDown>
                                      <Grid item xs={2}>
                                        <Stack
                                          flexDirection='row'
                                          justifyContent='flex-end'
                                        >
                                          <ActionMenu
                                            id={_id}
                                            onSelect={(action, id) =>
                                              handleOnAction(
                                                action,
                                                id,
                                                client,
                                                issueTitle,
                                                data
                                              )
                                            }
                                            status={status}
                                          />
                                        </Stack>
                                      </Grid>
                                    </Hidden>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              // </TableContainer>
            )}
          </>
        )}
      </div>
      {props?.issueListData?.issues?.length ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.issueListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSizeChange}
          />
        </Stack>
      ) : null}

      {newIssueDialog && !isClient() && (
        <NewIssue
          isEdit={isEdit}
          data={selectedIssue}
          onClose={() => {
            setIsEdit(false);
            setNewIssueDialog(false);
          }}
        />
      )}
      {filterDialog && (
        <IssuesFilter
          setFilterData={setFilterData}
          filterData={filter}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setFilterDialog(false)}
        />
      )}
      {assignUserDialog && !isClient() && (
        <AssignUserDialog
          selectedIssueId={selectedIssueId}
          onNewClose={() => setAssignUserDialog(false)}
        />
      )}
      {deleteIssueDialog && !isClient() && (
        <CommonDialog
          title={Strings.ISSUES}
          message={Strings.DELETE_ISSUE}
          onSuccess={() => onDeleteIssue()}
          onClose={() => setDeleteIssueDialog(false)}
          loading={props.deleteIssueLoading}
        />
      )}
      {addNoteDialog && (
        <AddNotes
          selectedId={selectedIssueId}
          onNewClose={() => {
            setAddNoteDialog(false);
            setIsClientDirective({});
            setSelectedNote(null);
            setIsEditIssueNote(false);
            setSelectedNoteId(null);
          }}
          isEditIssueNote={isEditIssueNote}
          selectedNote={selectedNote}
          isClientDirective={
            Object.keys(isClientDirective).length > 0
              ? isClientDirective
              : false
          } // used for request client directive action
          updateIssueByPerformAction={props.updateIssueByPerformAction}
        />
      )}
      {addJobDialog && !isClient() && (
        <NewJob
          issue
          data={selectedJob}
          onNewClose={() => setAddJobDialog(false)}
        />
      )}
      {openSendReportDialog && (
        <SendReport
          onNewClose={() => {
            setOpenSendReportDialog(false);
            setSelectedIssueClient(null);
          }}
          isIssueUpdate
          selectedIssueId={selectedIssueId}
          client={selectedIssueClient}
        />
      )}
      {openEditFilterDialog?.show && (
        <IssuesNewFilter
          isEdit={true}
          setIsFilterChange={setIsFilterChange}
          selectedId={openEditFilterDialog?.value}
          onClose={() => setOpenEditFilterDialog()}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  issueListData: state.issueListData.data,
  issueListLoading: state.issueListData.loading,

  addIssueData: state.addIssueData.data,
  addJobData: state.addJobData.data,

  deleteIssueLoading: state.deleteIssueData.loading,
  deleteIssueData: state.deleteIssueData.data,

  updateIssueData: state.updateIssueData.data,

  updateIssueByActionData: state.updateIssueByActionData.data,

  addIssueNoteData: state.addIssueNoteData.data,

  visibleIssueData: state.visibleIssueData.data,
  visibleIssueLoading: state.visibleIssueData.loading,

  updateIssueNoteData: state.updateIssueNoteData.data,

  deleteIssueNoteLoading: state.deleteIssueNoteData.loading,

  listFilterData: state.listFilterData.data,
  listFilterLoading: state.listFilterData.loading,

  addFilterData: state.addFilterData.data,
  updateFilterData: state.updateFilterData.data,
  deleteFilterData: state.deleteFilterData.data,

  exportIssuesLoading: state.exportIssuesData.loading,
});

export default connect(mapStateToProps, {
  fetchIssueList,
  deleteIssue,
  updateIssueByPerformAction,
  visibleIssue,
  sendUpdate,
  deleteIssueNote,
  clientDirectiveReminder,
  listFilter,
  markAllIssuesNotesRead,
  exportIssues,
})(Issues);
