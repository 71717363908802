import * as Yup from 'yup';
import { digitsRegex, passwordRegex, webUrlRegex } from './appUtils';
import moment from 'moment';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
});

export const AddUserSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Email address must be a valid email address')
    .required('Email address is required'),
  // fullName: Yup.string().required('Full name is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  role: Yup.string()
    .required('Role is required')
    .test('Role is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Role is required`,
        });
      }
      return true;
    }),
  color: Yup.string().required('Color is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('Enter valid phone number', '', function (value) {
      if (value && !value.match(digitsRegex)) {
        return this.createError({
          message: `Only digits allow in phone number`,
        });
      }
      if (value && value.match(digitsRegex) && value.length !== 10) {
        return this.createError({
          message: `Only 10 digits allow in phone number`,
        });
      }
      return true;
    }),
  bioLink: Yup.string().trim().url('Must be valid link'),
  password: Yup.string().test(
    'Password is required',
    '',
    function (value, ctx) {
      if (!value && !ctx.parent.isEdit) {
        return this.createError({
          message: `Password is required`,
        });
      } else if (value && !passwordRegex.test(value)) {
        return this.createError({
          message: `Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character`,
        });
      }
      return true;
    }
  ),
});

export const AddProgramTypeSchema = Yup.object().shape({
  name: Yup.string().required('Program name is required'),
});

export const AddCategoryTypeSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
});

export const AddVendorSchema = Yup.object().shape({
  company: Yup.string().required('Company is required'),
  emailAddress: Yup.string().email(
    'Email address must be a valid email address'
  ),
  // .required('Email address is required'),
  // fullName: Yup.string().required('Full name is required'),
  category: Yup.string()
    .required('Category is required')
    .test('Category is required', '', function (value) {
      if (!value) {
        return this.createError({
          message: `Category is required`,
        });
      }
      return true;
    }),
  websiteLink: Yup.string().matches(webUrlRegex, 'Enter valid url'),
  // preferredTechnician: Yup.string().required(
  //   'Preferred technician is required'
  // ),
  // .test('Preferre technician is required', '', function (value) {
  //   if (!value) {
  //     return this.createError({
  //       message: `Preferre technician is required`,
  //     });
  //   }
  //   return true;
  // }),
  // phoneNumber: Yup.string()
  //   .required('Phone number is required')
  //   .test('Enter valid phone number', '', function (value) {
  //     if (value && !value.match(digitsRegex)) {
  //       return this.createError({
  //         message: `Only digits allow in phone number`,
  //       });
  //     }
  //     if (value && value.match(digitsRegex) && value.length !== 10) {
  //       return this.createError({
  //         message: `Only 10 digits allow in phone number`,
  //       });
  //     }
  //     return true;
  //   }),
  // techPhone: Yup.string().test(
  //   'Enter valid phone number',
  //   '',
  //   function (value) {
  //     if (value && !value.match(digitsRegex)) {
  //       return this.createError({
  //         message: `Only digits allow in phone number`,
  //       });
  //     }
  //     if (value && value.match(digitsRegex) && value.length !== 10) {
  //       return this.createError({
  //         message: `Only 10 digits allow in phone number`,
  //       });
  //     }
  //     return true;
  //   }
  // ),
});

export const AddClientSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Email address must be a valid email address')
    .required('Email address is required'),
  // fullName: Yup.string().required('Full name is required'),
  // primaryAddress: Yup.string().required('Primary address is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('Enter valid phone number', '', function (value) {
      if (value && !value.match(digitsRegex)) {
        return this.createError({
          message: `Only digits allow in phone number`,
        });
      }
      if (value && value.match(digitsRegex) && value.length !== 10) {
        return this.createError({
          message: `Only 10 digits allow in phone number`,
        });
      }
      return true;
    }),
  admin: Yup.string()
    .required('Admin is required')
    .test('Admin is required', '', function (value) {
      if (!value) {
        return this.createError({
          message: `Admin is required`,
        });
      }
      return true;
    }),
  // programType: Yup.string()
  //   .required('ProgramType is required')
  //   .test('ProgramType is required', '', function (value) {
  //     if (!value) {
  //       return this.createError({
  //         message: `ProgramType is required`,
  //       });
  //     }
  //     return true;
  //   }),
  password: Yup.string().test(
    'Password is required',
    '',
    function (value, ctx) {
      if (!value && !ctx.parent.isEdit) {
        return this.createError({
          message: `Password is required`,
        });
      } else if (value && !passwordRegex.test(value)) {
        return this.createError({
          message: `Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character`,
        });
      }
      return true;
    }
  ),
});

export const AddJobSchema = Yup.object().shape({
  title: Yup.string().required('job title is required'),
  // jobType: Yup.string()
  //   .required('Job Type is required')
  //   .test('Job Type is required', '', function (value) {
  //     if (value === '0') {
  //       return this.createError({
  //         message: `Job Type is required`,
  //       });
  //     }
  //     return true;
  //   }),
  // jobForm: Yup.string()
  //   .required('Job Form is required')
  //   .test('Job Form is required', '', function (value) {
  //     if (value === '0') {
  //       return this.createError({
  //         message: `Job Form is required`,
  //       });
  //     }
  //     return true;
  //   }),
  jobStatus: Yup.string()
    .required('Job Status is required')
    .test('Job Status is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Job Status is required`,
        });
      }
      return true;
    }),
  client: Yup.string().required('Client is required'),
  property: Yup.string().required('Property is required'),
  admin: Yup.string().test('Admin is required', '', function (value, ctx) {
    if (!value && ctx.parent.isEdit) {
      return this.createError({
        message: `Admin is required`,
      });
    }
    return true;
  }),
  appointments: Yup.array().of(
    Yup.object().shape({
      assignedMember: Yup.array().min(1, 'assignedMember is Required'),
      startDate: Yup.string().nullable().required('startDate is Required'),
      endDate: Yup.string().nullable().required('endDate is Required'),
      startTime: Yup.string().nullable().required('startTime is Required'),
      endTime: Yup.string().nullable().required('endTime is Required'),
    })
  ),
  // appointments: Yup.array(
  //   Yup.object({
  //     assignedMember: Yup.array().required('assignedMember required'),
  //     startDate: Yup.string().required('startDate Required').nullable(),
  //     endDate: Yup.string().required('endDate Required').nullable(),
  //     startTime: Yup.string().required('startTime Required').nullable(),
  //     endTime: Yup.string().required('endTime Required').nullable(),
  //   }).test(
  //     'assignedMember test',
  //     // The error message that should appears if test failed
  //     'at least one appoinment should be filled',
  //     // Function that does the custom validation to this array
  //     validateAgainstPrevious
  //   )
  // ),
  // assignedTeamMember: Yup.array()
  //   .required('Team Member is required')
  //   .test('Team Member is required', '', function (value) {
  //     if (value?.length === 0) {
  //       return this.createError({
  //         message: `Team Member is required`,
  //       });
  //     }
  //     return true;
  //   }),
  // startDate: Yup.string().required('Date is required').nullable(),
  // startTime: Yup.string()
  //   .required('Time is required')
  //   .test('Time is required', '', function (value) {
  //     if (value.toString() === 'Invalid date') {
  //       return this.createError({
  //         message: 'Time is required',
  //       });
  //     }
  //     return true;
  //   })
  //   .nullable(),
  // endDate: Yup.string().required('Date is required').nullable(),
  // endTime: Yup.string()
  //   .required('Time is required')
  //   .test('Time is required', '', function (value) {
  //     if (value.toString() === 'Invalid date') {
  //       return this.createError({
  //         message: 'Time is required',
  //       });
  //     }
  //     return true;
  //   })
  //   .nullable(),
  // details: Yup.string().required('Details is required'),
  // frequency: Yup.string().test(
  //   'Frequency is required',
  //   '',
  //   function (value, ctx) {
  //     if (value === '0' && ctx.parent.recurring) {
  //       return this.createError({
  //         message: `Frequency is required`,
  //       });
  //     }
  //     return true;
  //   }
  // ),
  recurringStartDate: Yup.string()
    .test('Start date is required', '', function (value, ctx) {
      if (!value && ctx.parent.recurring) {
        return this.createError({
          message: `Start date is required`,
        });
      }
      return true;
    })
    .nullable(),
  recurringEndDate: Yup.string()
    .test('End date is required', '', function (value, ctx) {
      if (!value && ctx.parent.recurring) {
        return this.createError({
          message: `End date is required`,
        });
      }
      return true;
    })
    .nullable(),
  count: Yup.number()
    .min(1, `Minimum 1 Day is required`)
    .test(`Day's count is required`, '', function (value, ctx) {
      if (!value && ctx.parent.recurring) {
        return this.createError({
          message: `Day's count is required`,
        });
      }
      return true;
    }),
  timePeriod: Yup.string().test(
    `Time period is required`,
    '',
    function (value, ctx) {
      if (value === '0' && ctx.parent.recurring) {
        return this.createError({
          message: `Time period is required`,
        });
      }
      return true;
    }
  ),
});

export const AddJobFormSchema = Yup.object().shape({
  formName: Yup.string().required('Form name is required'),
  // category: Yup.string()
  //   .required('Category is required')
  //   .test('Category is required', '', function (value) {
  //     if (value === '0') {
  //       return this.createError({
  //         message: `Category is required`,
  //       });
  //     }
  //     return true;
  //   }),
});

export const AddPropertySchema = Yup.object().shape({
  residenceName: Yup.string().required('Residence name is required'),
  address: Yup.string().required('Address is required'),
  programType: Yup.string().required('Program type is required'),
  securityInfo: Yup.object().shape({
    alarmCompanyPhone: Yup.string().test(
      'Enter valid phone number',
      '',
      function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits allow in phone number`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 10) {
          return this.createError({
            message: `Only 10 digits allow in phone number`,
          });
        }
        return true;
      }
    ),
  }),
  propertyInfo: Yup.object().shape({
    securityGateContact: Yup.string().test(
      'Enter valid contact number',
      '',
      function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits allow in contact number`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 10) {
          return this.createError({
            message: `Only 10 digits allow in contact number`,
          });
        }
        return true;
      }
    ),
  }),
  contactInfo: Yup.object().shape({
    contactPhone: Yup.string().test(
      'Enter valid contact number',
      '',
      function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits allow in contact number`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 10) {
          return this.createError({
            message: `Only 10 digits allow in contact number`,
          });
        }
        return true;
      }
    ),
    spousePhone: Yup.string().test(
      'Enter valid phone number',
      '',
      function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits allow in phone number`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 10) {
          return this.createError({
            message: `Only 10 digits allow in phone number`,
          });
        }
        return true;
      }
    ),
    // contactEmail: Yup.string().email('Enter valid email address'),
    spouseEmail: Yup.string().email('Enter valid email address'),
  }),
  HOAInfo: Yup.object().shape({
    managerEmail: Yup.string().email('Enter valid email address'),
    managerContact: Yup.string().test(
      'Enter valid phone number',
      '',
      function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits allow in phone number`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 10) {
          return this.createError({
            message: `Only 10 digits allow in phone number`,
          });
        }
        return true;
      }
    ),
  }),
});

export const AddAnnouncementSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  message: Yup.string().required('Message is required'),
  visibleTo: Yup.array()
    .required('Visible to is required')
    .test('Visible to is required', '', function (value) {
      if (value?.length === 0) {
        return this.createError({
          message: `Visible to is required`,
        });
      }
      return true;
    }),
  startDate: Yup.string().required('Start Date is required').nullable(),
  startTime: Yup.string()
    .required('Start Time is required')
    .test('Start Time is required', '', function (value) {
      if (value?.toString() === 'Invalid date') {
        return this.createError({
          message: 'Start Time is required',
        });
      }
      return true;
    })
    .nullable(),
  endDate: Yup.string().required('End Date is required').nullable(),
  endTime: Yup.string()
    .required('End Time is required')
    .test('End Time is required', '', function (value) {
      if (value?.toString() === 'Invalid date') {
        return this.createError({
          message: 'End Time is required',
        });
      }
      return true;
    })
    .nullable(),
});

export const AddIssueSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  dueDate: Yup.string().required('Date is required').nullable(),
  type: Yup.string()
    .required('Type is required')
    .test('Type is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Type is required`,
        });
      }
      return true;
    }),
  // assignedTo: Yup.array()
  //   .required('Receipient is required')
  //   .test('Receipient is required', '', function (value) {
  //     if (value.length === 0) {
  //       return this.createError({
  //         message: `Receipient is required`,
  //       });
  //     }
  //     return true;
  //   }),
  client: Yup.string().required('Client is required'),
  property: Yup.string().required('Property is required'),
  // vendor: Yup.string().required('Vendor is required'),
  job: Yup.string(),
  priority: Yup.string()
    .required('Priority is required')
    .test('Priority is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Priority is required`,
        });
      }
      return true;
    }),
  // description: Yup.string().required('Description is required'),
  // suppliesNeeded: Yup.string().required('Supplies Needed is required'),
  notificationDate: Yup.string()
    .test('Date is required', '', function (value, ctx) {
      if (!value && ctx.parent.notification) {
        return this.createError({
          message: `Date is required`,
        });
      }
      return true;
    })
    .nullable(),
  notificationTime: Yup.string()
    .test('Time is required', '', function (value, ctx) {
      if (ctx.parent.notification) {
        if (!value || value.toString() === 'Invalid date') {
          return this.createError({
            message: `Time is required`,
          });
        } else {
          if (moment(ctx.parent.notificationDate).isSame(moment(), 'day')) {
            const minTime = moment().clone().add(10, 'minutes');
            if (minTime.isBefore(moment().startOf('day'))) {
              minTime.add(1, 'day');
            }
            let is10MinAhead = moment(value).isSameOrAfter(minTime);
            if (!is10MinAhead) {
              return this.createError({
                message: `Notification Time must be at least 10 minutes ahead`,
              });
            }
          }
        }
      }
      return true;
    })
    .nullable(),
});

export const SendReportSchema = Yup.object().shape({
  // reportMsg: Yup.string().required('Description is required'),
  tags: Yup.array()
    .required('Email is required')
    .test('Email is required', '', function (value) {
      if (value.length === 0) {
        return this.createError({
          message: `Email is required`,
        });
      }
      return true;
    }),
});

export const AssignUserSchema = Yup.object().shape({
  assignedTo: Yup.array()
    .required('Assign to is required')
    .test('Assign to is required', '', function (value) {
      if (value.length === 0) {
        return this.createError({
          message: `Assign to is required`,
        });
      }
      return true;
    }),
});

export const SetRecurringSchema = Yup.object().shape({
  // frequency: Yup.string().test('Frequency is required', '', function (value) {
  //   if (value === '0') {
  //     return this.createError({
  //       message: `Frequency is required`,
  //     });
  //   }
  //   return true;
  // }),
  recurringStartDate: Yup.string()
    .required('Start date is required')
    .nullable(),
  recurringEndDate: Yup.string().required('End date is required').nullable(),
  count: Yup.string().required(`Day's count is required`),
  timePeriod: Yup.string().test(
    `Time period is required`,
    '',
    function (value) {
      if (value === '0') {
        return this.createError({
          message: `Time period is required`,
        });
      }
      return true;
    }
  ),
});

export const AddAdminsDashboardArrivalDepartureSchema = Yup.object().shape(
  {
    client: Yup.string()
      .required('Client is required')
      .test('Client is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Client is required`,
          });
        }
        return true;
      }),
    property: Yup.string()
      .required('Property is required')
      .test('Property is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Property is required`,
          });
        }
        return true;
      }),
    arrivalDate: Yup.string().when('departureDate', {
      is: (departureDate) => {
        return departureDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // arrivalTime: Yup.string().when('arrivalDate', {
    //   is: (arrivalDate) => (arrivalDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
    departureDate: Yup.string().when('arrivalDate', {
      is: (arrivalDate) => {
        return arrivalDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // departureTime: Yup.string().when('departureDate', {
    //   is: (departureDate) => (departureDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
  },
  [['arrivalDate', 'departureDate']]
);

export const AddArrivalDepartureSchema = Yup.object().shape(
  {
    property: Yup.string()
      .required('Property is required')
      .test('Property is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Property is required`,
          });
        }
        return true;
      }),
    arrivalDate: Yup.string().when('departureDate', {
      is: (departureDate) => {
        return departureDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // arrivalTime: Yup.string().when('arrivalDate', {
    //   is: (arrivalDate) => (arrivalDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
    departureDate: Yup.string().when('arrivalDate', {
      is: (arrivalDate) => {
        return arrivalDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // departureTime: Yup.string().when('departureDate', {
    //   is: (departureDate) => (departureDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
  },
  [['arrivalDate', 'departureDate']]
);

export const AddArrivalDepartureForAdminSchema = Yup.object().shape(
  {
    arrivalDate: Yup.string().when('departureDate', {
      is: (departureDate) => {
        return departureDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // arrivalTime: Yup.string().when('arrivalDate', {
    //   is: (arrivalDate) => (arrivalDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
    departureDate: Yup.string().when('arrivalDate', {
      is: (arrivalDate) => {
        return arrivalDate ? true : false;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Date is required'),
    }),
    // departureTime: Yup.string().when('departureDate', {
    //   is: (departureDate) => (departureDate ? true : false),
    //   otherwise: Yup.string().nullable(),
    //   then: Yup.string()
    //     .required('Time is required')
    //     .test('Time is required', '', function (value) {
    //       if (value?.toString() === 'Invalid date') {
    //         return this.createError({
    //           message: 'Time is required',
    //         });
    //       }
    //       return true;
    //     })
    //     .nullable(),
    // }),
  },
  [['arrivalDate', 'departureDate']]
);

export const AddNotesSchema = Yup.object().shape({
  notes: Yup.string().required('Note is required'),
});

export const UploadFileWithTagsSchema = Yup.object().shape({
  selectedTags: Yup.array().required('Tag is required'),
});

export const SendManagementFeedbackSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
});

export const ManageEBlastSchema = Yup.object().shape({
  buttonText: Yup.string()
    .max(25, 'Maximum 25 texts are valid')
    .required('ButtonText is required'),
  url: Yup.string().url('Enter valid url').required('URL is required'),
});
