import axios from 'axios';
import { UPDATE_APPOINTMENT } from '../../../constants';

export const updateAppointment = (data, callback, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateAppointment());
  axios
    .put(`jobs/appointment`, data, config)
    .then((res) => {
      dispatch(setUpdateAppointment(res));
      callback();
    })
    .catch((err) => {
      dispatch(errorUpdateAppointment(err.response));
      onError(err.response);
    });
};

export const loadUpdateAppointment = () => ({
  type: UPDATE_APPOINTMENT.LOAD,
});

export const setUpdateAppointment = (data) => ({
  type: UPDATE_APPOINTMENT.SUCCESS,
  payload: data.data,
});

export const errorUpdateAppointment = (error) => ({
  type: UPDATE_APPOINTMENT.FAIL,
  payload: error,
});
