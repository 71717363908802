import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  switch_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  switch_label: {
    marginRight: 10,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
}))