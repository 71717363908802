import axios from 'axios';
import { SEND_UPDATE } from '../../../constants';

export const sendUpdate =
  (issueId, data, onSuccess, onError, onDialogClose) => (dispatch) => {
    const bodyFormData = new FormData();
    if (data.to.length > 0)
      bodyFormData.append('to', JSON.stringify(data.to) || []);
    if (data.cc.length > 0)
      bodyFormData.append('cc', JSON.stringify(data.cc) || []);
    if (data.bcc.length > 0)
      bodyFormData.append('bcc', JSON.stringify(data.bcc) || []);
    if (data.subject) bodyFormData.append('subject', data.subject || '');
    if (data.files.length > 0) {
      data.files.forEach((file, i) => {
        bodyFormData.append(`files`, file, file.name);
      });
    }
    if (data.description) bodyFormData.append('detail', data.description);

    const token = localStorage.jwtToken;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(loadSendUpdate());
    axios
      .post(`/issue/sendUpdate?issueId=${issueId}`, bodyFormData, config)
      .then((res) => {
        onSuccess('Successfully sent update');
        dispatch(setSendUpdate(res.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorSendUpdate(err.response));
        onError(err.response);
      });
  };

export const loadSendUpdate = () => ({
  type: SEND_UPDATE.LOAD,
});

export const setSendUpdate = (data) => ({
  type: SEND_UPDATE.SUCCESS,
  payload: data,
});

export const errorSendUpdate = (error) => ({
  type: SEND_UPDATE.FAIL,
  payload: error,
});
