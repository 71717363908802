import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../utils/strings';
import { Stack } from '@mui/material';
import FormSwitch from '../Switch';
import { icons } from '../../utils/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Textbox from '../Textbox';
import Dropdown from '../Dropdown';
import { colorListArray, makeUniqId, reorderList } from '../../utils/appUtils';
import classNames from 'classnames';

const FormField = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    fieldTitle,
    fieldData,
    dragProvided,
    containerClassName,
    containerStyle,
    onChangeCopy,
    onClickDelete,
    elemIndex,
    setFormData,
  } = props;

  const [data, setData] = useState(fieldData || {});

  const onChangeVisible = () => {
    let newData = { ...data, visible: !data.visible };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onChangeRequired = () => {
    let newData = { ...data, required: !data.required };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onChangeTitle = (e) => {
    let newData = { ...data, title: e.target.value };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onChangeOptionFields = (e, index, type) => {
    const copyData = structuredClone(data?.options) || [];
    if (type === 'textfield') copyData[index].option = e.target.value;
    else if (type === 'dropdown') copyData[index].color = e.target.value;
    let newData = { ...data, options: copyData || [] };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onClickDeleteOption = (index) => {
    const copyData = structuredClone(data?.options) || [];
    copyData.splice(index, 1);
    let newData = { ...data, options: copyData };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onDragEndOptions = (result) => {
    if (!result.destination) return;
    const copyData = structuredClone(data?.options) || [];
    const items = reorderList(
      copyData,
      result.source.index,
      result.destination.index
    );
    let newData = { ...data, options: items };
    setFormData(elemIndex, newData);
    setData(newData);
  };

  const onClickAddOption = async () => {
    const copyData = structuredClone(data?.options) || [];
    let uniqID = await makeUniqId();
    copyData.push({ option: '', color: '#ED4242', id: uniqID });
    let newData = { ...data, options: copyData };
    setFormData(elemIndex, newData);
    setData(newData);
  };

  return (
    <div
      className={classNames(classes.form_group, {
        [containerClassName]: containerClassName,
      })}
      style={{ ...containerStyle }}
    >
      <div className={classes.group_form_field_container}>
        <label
          {...dragProvided?.dragHandleProps}
          className={classes.group_label}
        >
          {fieldTitle}
        </label>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <FormSwitch
            label={Strings.VISIBLE_TO_CLIENT}
            value={data?.visible || false}
            containerClassName={classes.form_input_switch_container}
            textClassName={classes.form_switch_label}
            onChange={onChangeVisible}
          />
          <FormSwitch
            label={Strings.REQUIRED}
            value={data?.required || false}
            containerClassName={classes.form_input_switch_container}
            textClassName={classes.form_switch_label}
            onChange={onChangeRequired}
          />
          <img
            className={classes.action_icons}
            src={icons(theme).copyIcon}
            alt='copy'
            onClick={onChangeCopy}
          />
          <img
            className={classes.action_icons}
            src={icons(theme).deleteIcon}
            alt='delete'
            onClick={onClickDelete}
          />
        </Stack>
      </div>
      <Textbox
        onChange={onChangeTitle}
        value={data?.title || ''}
        placeholder='Title'
        labelClassName={classes.form_label}
        className={classes.group_form_input}
        containerClassName={classes.form_input_container}
      />
      {data.elementType !== 'Text Input' ? (
        <>
          <DragDropContext onDragEnd={onDragEndOptions}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data?.options?.map((field, i) => (
                    <Draggable
                      key={`key-${i}-${field._id || field.id}`}
                      draggableId={`dropId-${i}-${field._id || field.id}`}
                      index={i}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Stack
                            direction='row'
                            alignItems='center'
                            paddingTop={1}
                          >
                            <img
                              className={classes.action_icons}
                              src={icons(theme).listIcon}
                              alt=''
                              {...provided.dragHandleProps}
                            />
                            <Textbox
                              onChange={(e) =>
                                onChangeOptionFields(e, i, 'textfield')
                              }
                              value={field.option}
                              placeholder='option'
                              className={classes.form_input}
                              containerClassName={
                                classes.form_field_input_container
                              }
                              maxlength={80}
                            />
                            <Dropdown
                              placeholder={Strings.SELECT_AN_OPTION}
                              isColorSelect
                              name='formStatus'
                              value={field.color}
                              containerClassName={classes.dropdown}
                              optionArray={colorListArray}
                              onChange={(e) =>
                                onChangeOptionFields(e, i, 'dropdown')
                              }
                            />
                            <img
                              className={classes.action_icons}
                              src={icons(theme).deleteIcon}
                              alt=''
                              onClick={() => onClickDeleteOption(i)}
                            />
                          </Stack>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={classes.add_option_contain}>
            <label
              className={classes.add_option_label}
              onClick={onClickAddOption}
            >
              {Strings.Add_OPTION}
            </label>
          </div>
        </>
      ) : null}
    </div>
  );
};

FormField.propTypes = {
  fieldTitle: PropTypes.string,
  fieldData: PropTypes.object,
  dragProvided: PropTypes.object,
  elemIndex: PropTypes.number,
  setFormData: PropTypes.object,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.string,
  onChangeCopy: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default React.memo(FormField);
