import { Button, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { issuesActionsArray } from '../../../utils/appUtils';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../../utils/color';
import { Strings } from '../../../utils/strings';
import { icons } from '../../../utils/icons';
import classNames from 'classnames';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: -7,
    minWidth: 190,
    backgroundColor: theme.palette.background,
    color: theme.palette.background_button,
    boxShadow:
      theme.palette.mode === 'dark'
        ? `8px 12px 36px ${Colors.MODAL_SHADOW_WHITE}`
        : '8px 12px 36px rgba(35, 52, 90, 0.25)',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

const ActionMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedOption, setSelectedOption] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        disableElevation
        className={classNames(classes.action_button,{
          [props.actionButtonContainer]: props.actionButtonContainer
        })}
        onClick={props.handleClick? props.handleClick : handleClick }
      >
        {Strings.ACTIONS}
        <img
          src={icons(theme).downArrowWhite}
          alt=''
          className={classes.action_button_arrow}
        />
      </button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // value={selectedOption}
      >
        {issuesActionsArray(props.isReport) &&
          issuesActionsArray(props.isReport)
            .filter((item) => {
              if (props?.status === 'Completed' && !props.isReport) {
                return item.value !== 'Create a job';
              } else {
                return item;
              }
            })
            .map((item, index) => (
              <MenuItem
                key={`Menu${index}`}
                value={item.value}
                onClick={() => {
                  props.onSelect(item.value, props.id);
                  handleClose();
                  // setSelectedOption(item.value);
                }}
                disableRipple
              >
                {item.title}
              </MenuItem>
            ))}
      </StyledMenu>
    </>
  );
};

export default ActionMenu;
