import axios from 'axios';
import { ADMIN_JUNCTURE_LIST } from '../../constants';
import moment from 'moment-timezone';

export const fetchAdminJunctureList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJunctureList());

  axios
    .get(
      `/admin/listJunctures?currentDate=${moment().format(
        'YYYY-MM-DD'
      )}&timeZone=${moment.tz.guess()}`,
      config
    )
    .then((res) => dispatch(setJunctureList(res)))
    .catch((err) => {
      dispatch(errorJunctureList(err.response));
      onError(err.response);
    });
};

export const loadJunctureList = () => ({
  type: ADMIN_JUNCTURE_LIST.LOAD,
});

export const setJunctureList = (junctureData) => ({
  type: ADMIN_JUNCTURE_LIST.SUCCESS,
  payload: junctureData.data.data,
});

export const errorJunctureList = (error) => ({
  type: ADMIN_JUNCTURE_LIST.FAIL,
  payload: error,
});
