import { Strings } from './strings';
import { isAdmin, isClient } from './appUtils';
import { icons } from './icons';

const menuList = (theme) => {
  return isClient()
    ? [
        {
          icon: icons(theme).dashboard,
          activeIcon: icons(theme).dashboardActive,
          title: Strings.DASHBOARD,
          path: '/app/dashboard',
        },
        // {
        //   icon: icons(theme).vendor,
        //   activeIcon: icons(theme).vendorActive,
        //   title: Strings.REPORTS,
        //   path: '/app/reports',
        // },
        {
          icon: icons(theme).issues,
          activeIcon: icons(theme).issuesActive,
          title: Strings.REPORTS,
          path: '/app/reports',
        },
        {
          icon: icons(theme).issues1,
          activeIcon: icons(theme).issues1Active,
          title: Strings.ISSUES,
          path: '/app/issues',
        },
        // {
        //   icon: icons(theme).chat,
        //   activeIcon: icons(theme).chatActive,
        //   title: Strings.CHAT,
        //   path: '/app/chat',
        // },
        {
          icon: icons(theme).media,
          activeIcon: icons(theme).mediaActive,
          title: Strings.MEDIA,
          path: '/app/media',
        },
        {
          icon: icons(theme).property,
          activeIcon: icons(theme).propertyActive,
          title: Strings.PROPERTY,
          path: '/app/property',
        },
      ]
    : isAdmin()
    ? [
        {
          icon: icons(theme).dashboard,
          activeIcon: icons(theme).dashboardActive,
          title: Strings.DASHBOARD,
          path: '/app/dashboard',
        },
        {
          icon: icons(theme).client,
          activeIcon: icons(theme).clientActive,
          title: Strings.CLIENTS,
          path: '/app/clients',
        },
        // {
        //   icon: icons(theme).team,
        //   activeIcon: icons(theme).teamActive,
        //   title: Strings.TEAM,
        //   path: '/app/team',
        // },
        {
          icon: icons(theme).calender,
          activeIcon: icons(theme).calenderActive,
          title: Strings.CALENDAR,
          path: '/app/calendar',
        },
        {
          icon: icons(theme).issues,
          activeIcon: icons(theme).issuesActive,
          title: Strings.JOBS,
          path: '/app/jobs',
        },
        {
          icon: icons(theme).issues1,
          activeIcon: icons(theme).issues1Active,
          title: Strings.ISSUES,
          path: '/app/issues',
        },
        {
          icon: icons(theme).media,
          activeIcon: icons(theme).mediaActive,
          title: Strings.MEDIA,
          path: '/app/media',
        },
        {
          icon: icons(theme).vendor,
          activeIcon: icons(theme).vendorActive,
          title: Strings.VENDOR,
          path: '/app/vendor',
        },
        // {
        //   icon: icons(theme).reports,
        //   activeIcon: icons(theme).reportsActive,
        //   title: Strings.REPORTS,
        //   path: '/app/reports',
        // },
        // {
        //   icon: icons(theme).chat,
        //   activeIcon: icons(theme).chatActive,
        //   title: Strings.CHAT,
        //   path: '/app/chat',
        // },
      ]
    : [
        {
          icon: icons(theme).dashboard,
          activeIcon: icons(theme).dashboardActive,
          title: Strings.DASHBOARD,
          path: '/app/dashboard',
        },
        {
          icon: icons(theme).client,
          activeIcon: icons(theme).clientActive,
          title: Strings.CLIENTS,
          path: '/app/clients',
        },
        {
          icon: icons(theme).calender,
          activeIcon: icons(theme).calenderActive,
          title: Strings.CALENDAR,
          path: '/app/calendar',
        },
        {
          icon: icons(theme).issues,
          activeIcon: icons(theme).issuesActive,
          title: Strings.JOBS,
          path: '/app/jobs',
        },
        {
          icon: icons(theme).issues1,
          activeIcon: icons(theme).issues1Active,
          title: Strings.ISSUES,
          path: '/app/issues',
        },
        {
          icon: icons(theme).media,
          activeIcon: icons(theme).mediaActive,
          title: Strings.MEDIA,
          path: '/app/media',
        },
        {
          icon: icons(theme).team,
          activeIcon: icons(theme).teamActive,
          title: Strings.TEAM,
          path: '/app/team',
        },
        {
          icon: icons(theme).vendor,
          activeIcon: icons(theme).vendorActive,
          title: Strings.VENDOR,
          path: '/app/vendor',
        },
        // {
        //   icon: icons(theme).reports,
        //   activeIcon: icons(theme).reportsActive,
        //   title: Strings.REPORTS,
        //   path: '/app/reports',
        // },
        // {
        //   icon: icons(theme).chat,
        //   activeIcon: icons(theme).chatActive,
        //   title: Strings.CHAT,
        //   path: '/app/chat',
        // },
      ];
};

export { menuList };
