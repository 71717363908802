import axios from 'axios';
import { GET_JOB_BY_ID } from '../../../constants';

export const fetchJobById = (jId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJobById());

  axios
    .get(`/jobs/getJobById/${jId}`, config)
    .then((res) => dispatch(setJobById(res)))
    .catch((err) => {
      dispatch(errorJobById(err.response));
      onError(err.response);
    });
};

export const loadJobById = () => ({
  type: GET_JOB_BY_ID.LOAD,
});

export const setJobById = (jobList) => ({
  type: GET_JOB_BY_ID.SUCCESS,
  payload: jobList.data.data,
});

export const errorJobById = (error) => ({
  type: GET_JOB_BY_ID.FAIL,
  payload: error,
});

export const clearJobData = () => ({
  type: GET_JOB_BY_ID.CLEAR,
});
