import axios from 'axios';
import { ADD_CLIENT } from '../../constants';

export const createClient = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addClient());
  axios
    .post(`/client/createClient`, data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAddClient(res));
    })
    .catch((err) => {
      dispatch(errorAddClient(err.response));
      onError(err.response);
    });
};

export const addClient = () => ({
  type: ADD_CLIENT.LOAD,
});

export const setAddClient = (data) => ({
  type: ADD_CLIENT.SUCCESS,
  payload: data.data,
});

export const errorAddClient = (error) => ({
  type: ADD_CLIENT.FAIL,
  payload: error,
});
