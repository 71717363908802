import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  date_time_box: {
    display: 'flex',
    flexDirection: 'column',
  },
  date_time_label: {
    marginBottom: 7,
    color: theme.palette.form_label,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  date_time_container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'baseline',
  },
}));
