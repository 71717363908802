import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../utils/strings';
import { Stack } from '@mui/material';
import { icons } from '../../utils/icons';
import FormSwitch from '../Switch';
import Textbox from '../Textbox';
import FormField from './FormField';
import JobFormTools from '../../pages/Jobs/JobForms/NewJobForm/ToolsModal';
import { makeUniqId, reorderList } from '../../utils/appUtils';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import NewField from '../NewField';
import { useCallback } from 'react';
import { debounce } from 'lodash';

const fetchFieldTitle = {
  Group: Strings.GROUP,
  Dropdown: Strings.DROPDOWN,
  Radio: Strings.RADIO,
  'Text Input': Strings.TEXT_INPUT,
  'Check List': Strings.CHECKLIST,
};

const GroupFormField = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    dragProvided,
    fieldData,
    elemIndex,
    setFormData,
    onChangeCopy,
    onClickDelete,
  } = props;

  // const titleRef = React.useRef(fieldData?.title ?? null);
  const [data, setData] = useState(fieldData || {});
  const [showGroupFields, setShowGroupFields] = useState(true);
  const [showToolBox, setShowToolBox] = useState(false);

  // useEffect(() => {
  //   if (titleRef.current) titleRef.current.value = fieldData?.title;
  // },[titleRef.current]);

  const onChangeVisible = () => {
    let newData = { ...data, visible: !data.visible };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onChangeRequired = () => {
    let newData = { ...data, required: !data.required };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const onChangeTitle = (e) => {
    // titleRef.current.value = e.target.value;
    let newData = { ...data, title: e.target.value };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const addNewField = async (fieldType) => {
    let array = structuredClone(data?.groupElements) || [];
    let newField = {};
    if (fieldType.elementType === 'Text Input') {
      newField = {
        elementType: fieldType.elementType,
        title: Strings.SELECTED_DROPDOWN,
        required: false,
        visible: true,
      };
    } else {
      let uniqID = await makeUniqId();
      newField = {
        elementType: fieldType.elementType,
        title: Strings.SELECTED_DROPDOWN,
        required: false,
        visible: true,
        options:
          fieldType.elementType === 'Check List'
            ? [{ option: Strings.NA, color: '#ED4242', id: uniqID }]
            : [
                {
                  option: Strings.ISSUES_FOUND,
                  color: '#ED4242',
                  id: `iss${uniqID}`,
                },
                {
                  option: Strings.NO_ISSUES_FOUND,
                  color: '#ED4242',
                  id: `noiss${uniqID}`,
                },
                { option: Strings.NA, color: '#ED4242', id: `na${uniqID}` },
              ],
      };
    }
    let _id = await makeUniqId();
    newField.id = _id;

    array.push(newField);
    let newData = { ...data, groupElements: array };
    setData(newData);
    setFormData(elemIndex, newData);
    setShowToolBox(false);
  };

  const onDragEnd = (result) => {
    if (!result?.destination) return;
    let copyData = structuredClone(data?.groupElements);
    let items = reorderList(
      copyData,
      result.source.index,
      result.destination.index
    );
    let newData = { ...data, groupElements: items };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const handleCopy = async (index) => {
    let copyData = structuredClone(data?.groupElements) || [];
    let newField = structuredClone(copyData[index]) || {};
    let _id = await makeUniqId();
    newField.id = _id;
    delete newField?._id;
    // copyData.push(newField);
    copyData.splice(index + 1, 0, newField);
    let newData = { ...data, groupElements: copyData };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const handleDelete = (index) => {
    let copyData = structuredClone(data?.groupElements);
    copyData.splice(index, 1);
    let newData = { ...data, groupElements: copyData };
    setData(newData);
    setFormData(elemIndex, newData);
  };

  const setFormFieldData = useCallback(
    debounce((index, fetchedData) => {
      let newData = structuredClone(data);
      newData.groupElements[index] = fetchedData;
      setData(newData);
      setFormData(elemIndex, newData);
    }, 400),
    [data]
  );

  return (
    <div className={classes.form_group}>
      <div className={classes.group_form_field_container}>
        <Stack direction='row' alignItems='center'>
          <img
            className={classes.toggle_arrow}
            src={
              !showGroupFields
                ? icons(theme).rightArrow
                : icons(theme).downArrow
            }
            alt='arrow'
            onClick={() => setShowGroupFields(!showGroupFields)}
          />
          <label
            {...dragProvided?.dragHandleProps}
            className={classes.group_label}
          >
            {Strings.GROUP}
          </label>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <FormSwitch
            label={Strings.VISIBLE_TO_CLIENT}
            value={data?.visible || false}
            textClassName={classes.form_switch_label}
            containerClassName={classes.form_input_switch_container}
            onChange={onChangeVisible}
          />

          <FormSwitch
            label={Strings.REQUIRED}
            value={data?.required || false}
            textClassName={classes.form_switch_label}
            containerClassName={classes.form_input_switch_container}
            onChange={onChangeRequired}
          />
          <img
            className={classes.action_icons}
            src={icons(theme).copyIcon}
            alt='copy'
            onClick={() => onChangeCopy()}
          />
          <img
            className={classes.action_icons}
            src={icons(theme).deleteIcon}
            alt='delete'
            onClick={() => onClickDelete()}
          />
        </Stack>
      </div>
      {showGroupFields && (
        <>
          <Textbox
            // inputRef={titleRef}
            onChange={onChangeTitle}
            value={data?.title || ''}
            placeholder='Title'
            labelClassName={classes.form_label}
            className={classes.group_form_input}
            containerClassName={classes.form_input_container}
          />
          {data?.groupElements?.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {data?.groupElements?.map((item, i) => (
                      <Draggable
                        key={`key-${i}-${item._id || item.id}`}
                        draggableId={`dragId-${i}-${item._id || item.id}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <FormField
                              elemIndex={i}
                              dragProvided={provided}
                              fieldTitle={fetchFieldTitle[item?.elementType]}
                              fieldData={item}
                              containerClassName={classes.form_group_field}
                              containerStyle={
                                snapshot.isDragging && {
                                  padding: '6px 10px 12px 10px',
                                }
                              }
                              onChangeCopy={() => handleCopy(i)}
                              onClickDelete={() => handleDelete(i)}
                              setFormData={setFormFieldData}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
          <NewField
            containerClassName={classes.form_input_container}
            onClick={() => setShowToolBox(!showToolBox)}
          />
          {showToolBox && (
            <JobFormTools
              onDropDownClick={() => addNewField({ elementType: 'Dropdown' })}
              onTextInputClick={() =>
                addNewField({ elementType: 'Text Input' })
              }
              onRadioClick={() => addNewField({ elementType: 'Radio' })}
              onCheckListClick={() =>
                addNewField({ elementType: 'Check List' })
              }
              isFromGroupField={true}
            />
          )}
        </>
      )}
    </div>
  );
};

GroupFormField.propTypes = {
  fieldData: PropTypes.object,
  dragProvided: PropTypes.object,
  elemIndex: PropTypes.number,
  setFormData: PropTypes.object,
  onChangeCopy: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default React.memo(GroupFormField);
