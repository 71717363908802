import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../../utils/icons';
import { CircularProgress, Skeleton, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage, isClient } from '../../../utils/appUtils';
import { connect } from 'react-redux';
import {
  fetchMediaById,
  setMediaById,
} from '../../../redux/actions/mediaActions/getMediaById';
import { useAlert } from 'react-alert';
import { Colors } from '../../../utils/color';
import Textbox from '../../../components/Textbox';
import Button from '../../../components/Button';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { fetchTagsList } from '../../../redux/actions/mediaActions/tagsList';
import { updateFileInfo } from '../../../redux/actions/mediaActions/updateFileDetails';
import { useDispatch } from 'react-redux';
import PdfViewer from '../../../components/PdfViewer';
import classNames from 'classnames';
import CustomizedTooltips from '../../../components/CustomizedTooltips';

const IconButton = (props) => {
  const classes = useStyles();
  return (
    <CustomizedTooltips title={props?.prevBtn ? 'Previous' : 'Next'}>
      <img
        src={props?.prevBtn ? icons().leftArrow : icons().rightArrow}
        alt={props?.prevBtn ? 'prev' : 'next'}
        className={classNames(classes.icon_btn, {
          [classes.btn_disabled]: props?.disabled,
          [props.className]: props?.className,
        })}
        style={{
          left: props?.prevBtn && '20px',
          right: !props?.prevBtn && '20px',
        }}
        onClick={!props?.disabled && props?.onClick}
      />
    </CustomizedTooltips>
  );
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  prevBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const FileDetails = (props) => {
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const { classes, theme, fileData, onReportClick, mobileView, onClientClick } =
    props;
  const date =
    fileData?.createdAt && moment(fileData.createdAt).format('MMM DD, YYYY');
  const time =
    fileData?.createdAt && moment(fileData.createdAt).format('h:mma');

  return (
    <div className={classes.file_details}>
      <label className={classes.text_small_dark}>
        {fileData?.property?.name || ''}
      </label>
      <div className={classes.flex_column_view}>
        {/* {fileData.size && (
          <label className={classes.text_light}>
            {fileData.size}
          </label>
        )} */}
        {fileData?.addedBy?.name && (
          <label className={classes.text_light}>
            {Strings.ADDED_BY + ' ' + fileData?.addedBy?.name}
          </label>
        )}
        {!mobileView && date && time && (
          <label className={classes.text_light}>
            {'on ' + date + ' at ' + time}
          </label>
        )}
      </div>
      <label className={classes.tag_title_text}>
        {Strings.TAGS.toUpperCase()}
      </label>
      <div className={classes.tags_container}>
        {props?.selectedTags?.map((item) => (
          <div style={{ marginRight: 0 }} className={classes.tags}>
            <label>{item?.title}</label>
            <img
              src={icons(theme).closeIcon}
              className={classes.tag_close_icon}
              onClick={() => props.onTagRemoveClick(item?.value)}
            />
          </div>
        ))}
        {props?.isAddTagsRequired && (
          <img
            src={icons(theme).Plus}
            style={{ height: 18, width: 18, cursor: 'pointer' }}
            onClick={() => setShowTagsDropdown(!showTagsDropdown)}
          />
        )}
      </div>
      {showTagsDropdown && (
        <MultiSelectDropdown
          name='selectedTags'
          title={Strings.SEARCH_AND_SELECT_TAGS}
          onChange={(value) => props.onSelectTags(value)}
          value={props.selectedTags || []}
          optionArray={props.tagsList || []}
          hideAvatar={true}
          containerClassName={classes.form_input_container}
        />
      )}
      {!mobileView && fileData?.client?.name && (
        <div className={classes.flex_view}>
          <img
            src={icons(theme).client}
            alt=''
            className={classes.icon_image}
            onClick={onClientClick}
          />
          <label className={classes.text_blue} onClick={onClientClick}>
            {fileData?.client?.name}
          </label>
        </div>
      )}
      {/* {!props.isOnlyMediaPreview && (
        <div className={classes.flex_view}>
          <img
            src={mobileView ? icons().reportDarkIcon : icons(theme).report}
            alt=''
            className={classes.icon_image}
            onClick={onReportClick}
          />
          <label className={classes.text_blue} onClick={onReportClick}>
            {'Report'}
          </label>
        </div>
      )} */}
    </div>
  );
};

const MediaDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [mediaInfo, setMediaInfo] = useState(null);
  const [fileName, setFileName] = useState('');
  const [tagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [tempLoader, setTempLoader] = useState(true);
  const [mediaId, setMediaId] = useState(props?.fileId || '');

  const fileExtension = mediaInfo?.fileName?.split('.').pop() || '';
  const mediaFileName =
    mediaInfo?.fileName?.substr(0, mediaInfo?.fileName?.lastIndexOf('.')) || '';

  const clearState = () => {
    setMediaInfo(null);
    setFileName('');
    setTagsList([]);
    setSelectedTags([]);
  };

  const fetchFileIcon = (type) => {
    if (type === 'image') return icons(theme).imageIcon;
    else if (type === 'doc' || type === 'word' || type === 'docx')
      return icons(theme).docFileIcon;
    else if (type === 'audio') return icons(theme).audioFileIcon;
    else if (type === 'video') return icons(theme).videoFileIcon;
    else if (type === 'xlsx' || type === 'xls' || type === 'csv')
      return icons(theme).excelFileIcon;
    else if (type === 'ppt') return icons(theme).pptFileIcon;
    else if (type === 'zip' || type === 'rar') return icons(theme).zipFileIcon;
    else if (type === 'pdf') return icons(theme).pdfFileIcon;
    else return icons(theme).undefinedFileIcon;
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (mediaId) {
      props.fetchMediaById(mediaId, onError);
      // props.fetchTagsList(onError);
    }
  }, [mediaId]);

  useEffect(() => {
    if (Object.keys(props.mediaData)?.length > 0) {
      let data = props.mediaData || {};
      setMediaInfo(data);
      setFileName(data?.fileName?.substr(0, data?.fileName?.lastIndexOf('.')));
      setTags(data?.tags);
      // setSelectedTags(
      //   data?.tags?.map((el) => ({
      //     title: el,
      //     value: el,
      //   })),
      // );
    }
  }, [props.mediaData]);

  useEffect(() => {
    if (props?.tagsListData.length > 0) {
      const newList = props?.tagsListData?.map((item) => {
        const tempData = {
          title: item.name,
          value: item.name,
        };
        return tempData;
      });
      setTagsList(newList);
    } else {
      props.fetchTagsList(onError);
    }
  }, [props.tagsListData]);

  const onRemoveTag = (val) => {
    let fIndex = selectedTags?.findIndex((el) => el.value === val);
    let newData = [...selectedTags];
    newData?.splice(fIndex, 1);
    setSelectedTags(newData);
    setTags(newData);
  };

  const updateFileInfo = () => {
    let data = {
      fileName: `${fileName}.${fileExtension}`,
      tags: selectedTags?.map((el) => el.value),
    };

    props.updateFileInfo(mediaInfo?._id, data, onError, () =>
      props.onNewClose()
    );
  };

  useEffect(() => {
    if (mediaInfo && tagsList?.length > 0) {
      const data = tagsList?.filter((item) =>
        mediaInfo?.tags?.some((el) => el === item?.value)
      );
      setSelectedTags(data);
    }
  }, [mediaInfo, tagsList]);

  const getFileType = (url) => {
    let type = '';
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.onload = () => {
      var contentType = xhr.getResponseHeader('Content-Type');
      type = contentType;
      return true;
    };
    xhr.send();
    return type;
  };

  const handleNext_Prev = (type) => {
    if (props?.mediaList?.length) {
      const newData = structuredClone(props?.mediaList);
      const fIndex = newData?.findIndex((el) => el._id === mediaId);
      if (type === 'next') setMediaId(newData[fIndex + 1]?._id);
      else setMediaId(newData[fIndex - 1]?._id);
    }
  };

  const disableBtn = useCallback(
    (type) => {
      if (mediaId) {
        const newData = structuredClone(props?.mediaList);
        const fIndex = newData?.findIndex((el) => el._id === mediaId);
        if (fIndex === 0 && type === 'prev') return true;
        else if (fIndex === newData?.length - 1 && type === 'next') return true;
        else return false;
      }
    },
    [mediaId]
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (mediaInfo) {
      const img = new Image();
      img.src = mediaInfo?.url;
      img.onload = () => {
        setIsLoading(false);
      };
    }
  }, [mediaInfo]);

  return (
    <div className={classes.dialog_mask}>
      {mediaInfo?.type === 'pdf' && !mobileView && (
        <div className={classes.pdf_viewer_box}>
          {props.mediaLoading || props.tagsListLoading || tempLoader ? (
            <CircularProgress
              style={{
                color: Colors.LIGHT_BLUE,
              }}
            />
          ) : (
            <>
              <IconButton
                prevBtn
                disabled={disableBtn('prev')}
                onClick={() => handleNext_Prev('prev')}
              />
              <PdfViewer url={mediaInfo?.url} scale={1} />
              <IconButton
                disabled={disableBtn('next')}
                onClick={() => handleNext_Prev('next')}
              />
            </>
          )}
        </div>
      )}
      {!mobileView && mediaInfo?.type !== 'pdf' && (
        <div className={classes.dialog_image_box}>
          {props.mediaLoading || props.tagsListLoading || tempLoader ? (
            <CircularProgress
              style={{
                color: Colors.LIGHT_BLUE,
              }}
            />
          ) : (
            <div className={classNames(classes.dialog_image_container)}>
              <IconButton
                prevBtn
                disabled={disableBtn('prev')}
                onClick={() => handleNext_Prev('prev')}
              />
              {mediaInfo?.type === 'video' ? (
                <video
                  controls
                  autoPlay
                  style={{ width: '100%', height: '100%' }}
                  src={mediaInfo?.url}
                />
              ) : mediaInfo?.type === 'audio' ? (
                <audio controls autoPlay src={mediaInfo?.url} />
              ) : (
                <>
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width={'100%'}
                        height={'100%'}
                        sx={{ bgcolor: '#9b9b9b' }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          mediaInfo?.type === 'image'
                            ? mediaInfo?.url
                            : fetchFileIcon(
                                mediaInfo?.type === 'file'
                                  ? fileExtension
                                  : mediaInfo?.type
                              )
                        }
                        alt=''
                        className={classes.file_image}
                        style={{
                          width: mediaInfo?.type !== 'image' && '40%',
                          cursor: mediaInfo?.type === 'pdf' && 'pointer',
                        }}
                        onClick={() => {
                          if (mediaInfo?.type === 'pdf') {
                            window.open(mediaInfo?.url, '_blank', 'noreferrer');
                          }
                        }}
                      />
                    </>
                  )}
                </>
              )}
              <IconButton
                disabled={disableBtn('next')}
                onClick={() => handleNext_Prev('next')}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.dialog_information_container}>
        <NewFormHeader
          title={!mobileView && Strings.FILE_INFORMATION}
          closeIcon={mobileView && icons(theme).closeWhiteIcon}
          onNewClose={() => {
            props.onNewClose();
            clearState();
            dispatch(setMediaById({}));
          }}
          containerClassName={classes.header_container}
        />
        {props.mediaLoading || props.tagsListLoading || tempLoader ? (
          <div className={classes.no_data_container} style={{ marginTop: 40 }}>
            <CircularProgress
              style={{
                color: !mobileView
                  ? theme.palette.background_button
                  : Colors.LIGHT_BLUE,
              }}
              size={20}
            />
          </div>
        ) : !mediaInfo ? (
          <div className={classes.no_data_container}>
            <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
          </div>
        ) : (
          <>
            {mobileView && mediaInfo?.type === 'pdf' && (
              <div className={classes.mobile_pdf_viewer_box}>
                {props.mediaLoading || props.tagsListLoading || tempLoader ? (
                  <CircularProgress
                    style={{
                      color: Colors.LIGHT_BLUE,
                    }}
                  />
                ) : (
                  <>
                    <IconButton
                      prevBtn
                      onClick={() => handleNext_Prev('prev')}
                    />
                    <PdfViewer url={mediaInfo?.url} scale={0.6} />
                    <IconButton onClick={() => handleNext_Prev('next')} />
                  </>
                )}
              </div>
            )}
            {mobileView && mediaInfo?.type !== 'pdf' && (
              <div className={classes.mobile_view_image_container}>
                <IconButton prevBtn onClick={() => handleNext_Prev('prev')} />
                {mediaInfo?.type === 'video' ? (
                  <video
                    controls
                    autoPlay
                    style={{ width: '100%', height: '100%' }}
                    src={mediaInfo?.url}
                  />
                ) : mediaInfo?.type === 'audio' ? (
                  <audio controls autoPlay src={mediaInfo?.url} />
                ) : (
                  <img
                    src={
                      mediaInfo?.type === 'image'
                        ? mediaInfo?.url
                        : fetchFileIcon(
                            mediaInfo?.type === 'file'
                              ? fileExtension
                              : mediaInfo?.type
                          )
                    }
                    alt=''
                    className={classes.fileImage_mobile}
                    style={{ width: mediaInfo?.type !== 'image' && '40%' }}
                  />
                )}
                <IconButton onClick={() => handleNext_Prev('next')} />
              </div>
            )}
            <Textbox
              name='title'
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              placeholder=''
              className={classes.form_input}
              containerClassName={classes.form_input_container}
              disabled={isClient()}
            />
            <FileDetails
              classes={classes}
              theme={theme}
              fileData={mediaInfo}
              mobileView={mobileView}
              // onReportClick={() =>
              //   props?.fileInfo?.jobId &&
              //   navigate('/app/report', {
              //     state: {
              //       jobId: props?.fileInfo?.jobId,
              //     },
              //   })
              // }
              isAddTagsRequired={props?.isAddTagsRequired}
              onClientClick={() => !isClient() && navigate('/app/clients')}
              isOnlyMediaPreview={props.isOnlyMediaPreview}
              tagsList={tagsList}
              selectedTags={selectedTags}
              onSelectTags={(values) => {
                setSelectedTags(values);
                setTags(selectedTags?.map((el) => el.value));
              }}
              onTagRemoveClick={(val) => onRemoveTag(val)}
            />
            {(fileName !== mediaFileName || tags !== mediaInfo?.tags) && (
              <div className={classes.submit_button_outer_container}>
                <Button
                  text={Strings.UPDATE_INFORMATION}
                  containerClassName={classes.submit_button_container}
                  className={classes.submit_button}
                  loading={props.updateFileDetailsLoading}
                  disabled={props.updateFileDetailsLoading}
                  onClick={() => updateFileInfo()}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
// {
//   [classes.pdfViewDialog]: mediaInfo?.type === 'pdf',
// }
const mapStateToProps = (state) => ({
  mediaData: state.mediaData.data,
  mediaLoading: state.mediaData.loading,
  tagsListData: state.tagsListData.data,
  tagsListLoading: state.tagsListData.loading,
  updateFileDetailsData: state.updateFileDetailsData.data,
  updateFileDetailsLoading: state.updateFileDetailsData.loading,
});

export default connect(mapStateToProps, {
  fetchMediaById,
  fetchTagsList,
  updateFileInfo,
})(MediaDetails);
