import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    paddingTop: 10,
    paddingBottom: 10,
  },
  feedback_header: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  toggle_arrow: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  feedback_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
    marginLeft: 10,
    cursor: 'pointer',
    flex: 1,
  },
  feedback_footer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  text_small_dark: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.profile_title,
  },
  text_small_light: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.profile_title,
  },
  feedback_container: {
    marginTop: 10,
    marginBottom: 5,
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  clear_icon: {
    cursor: 'pointer',
    height: 20,
    width: 20,
  },
}));
