import axios from 'axios';
import { isAdmin, isSuperAdmin } from '../../../utils/appUtils';
import { GET_ROOM_DATA } from '../../constants';

export const fetchRoomData = (id, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(setRoomData([]));
  dispatch(loadRoomData());
  let url = `/room/${id}`;
  axios
    .get(url, config)
    .then((res) => dispatch(setRoomData(res.data.data)))
    .catch((err) => {
      dispatch(errorRoomData(err.response));
      onError(err.response);
    });
};

export const loadRoomData = () => ({
  type: GET_ROOM_DATA.LOAD,
});

export const setRoomData = (countData) => ({
  type: GET_ROOM_DATA.SUCCESS,
  payload: countData,
});

export const errorRoomData = (error) => ({
  type: GET_ROOM_DATA.FAIL,
  payload: error,
});
