import jwtDecode from 'jwt-decode';
import { Colors } from './color';
import moment from 'moment';
import axios from 'axios';

export const PAGE_SIZE = 15;

export const quillData = { theme: 'snow' };

export const webUrlRegex =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([\/\w\.-]*)*\/?$/;

export const weatherAxios = axios.create({
  baseURL: 'https://api.openweathermap.org/data/2.5/',
});

export const viewRadarLink =
  'https://www.windy.com/-Webcams/United-States/Texas/Naples/webcams/1249044893?rain,26.028,-82.570,9';

export const getLastMessageTime = (time) => {
  var startTime = moment(time);
  var endTime = moment(new Date());
  let seconds = endTime.diff(startTime, 'seconds');
  let minutes = endTime.diff(startTime, 'minutes');
  let hours = endTime.diff(startTime, 'hours');
  let days = endTime.diff(startTime, 'days');
  if (seconds <= 60) {
    return (seconds < 0 ? 0 : seconds) + ' s';
  } else if (minutes <= 60) {
    return minutes + ' m';
  } else if (hours <= 24) {
    return hours + ' h';
  } else {
    return days + ' d';
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const downloadFile = async (file, fileType, fileName, setLoader) => {
  if (setLoader) {
    await setLoader(true);
  }
  fetch(file, {
    method: 'GET',
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  })
    .then((res) => res.blob())
    .then(async (blob) => {
      var url = window.URL.createObjectURL(
        new Blob([blob], {
          type: fileType,
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (setLoader) {
        await setLoader(false);
      }
    })
    .catch(async (err) => await setLoader(false));
};

export const phoneFormatUS = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  // Format the remaining digits
  const formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
    3,
    6
  )} - ${digitsOnly.slice(6)}`;
  return formattedNumber;
};

export const formatingPhoneNumber = (value, isWithoutBracket) => {
  if (value)
    return value
      .replace(/\D+/g, '')
      .replace(
        /([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi,
        isWithoutBracket ? '$1-$2-$3' : '($1) $2-$3'
      );
  else return '';
};

export const getErrorMessage = (error, isSendObj) => {
  if (isSendObj) {
    return error && error.data;
  } else {
    return error && error.data && error.data.message;
  }
};
export const userRole = () => {
  return localStorage.jwtToken && jwtDecode(localStorage.jwtToken).role;
};

export const userId = () => {
  return localStorage.jwtToken && jwtDecode(localStorage.jwtToken).userId;
};

export const isSuperAdmin = () => {
  return userRole() === 'superadmin';
};
export const isAdmin = () => {
  return userRole() === 'admin';
};
export const isClient = (isParamJobId) => {
  return isParamJobId ? true : userRole() === 'client';
};

export const getClientUserId = () => {
  if (userRole() === 'client') {
    return localStorage.jwtToken && jwtDecode(localStorage.jwtToken).userId;
  }
  return '';
};

export const addUserArray = () => {
  if (isSuperAdmin())
    return [
      { title: 'Admin', value: 'admin' },
      { title: 'Technician', value: 'technician' },
    ];
  else if (isAdmin()) return [{ title: 'Technician', value: 'technician' }];
};

export const programTypeArray = [
  { title: 'Active', value: 'Active' },
  { title: 'Inactive', value: 'InActive' },
];

export const issueStatusArray = [
  { title: 'New', value: 'New' },
  { title: 'In Progress', value: 'In Progress' },
  { title: `Requested Client's Input`, value: `Requested Client's Input` },
  { title: 'Completed', value: 'Completed' },
  { title: 'Cancelled', value: 'Cancelled' },
  { title: 'Team Discussion Needed', value: 'Team Discussion Needed' },
  { title: 'Waiting on Vendor', value: 'Waiting on Vendor' },
  { title: 'Scheduled', value: 'Scheduled' },
  { title: 'Declined', value: 'Declined' },
  { title: 'Completed - Recurring', value: 'Completed - Recurring' },
  { title: 'Need to Schedule', value: 'Need to Schedule' },
  { title: 'On Hold', value: 'On Hold' },
  { title: 'Monitoring', value: 'Monitoring' },
];

export const vendorCategoryArray = [
  { title: 'Plumbing', value: 'Plumbing' },
  { title: 'HVAC', value: 'HVAC' },
  { title: 'Electrical', value: 'Electrical' },
  { title: 'Pest Control', value: 'Pest Control' },
];

export const announcementVisibleArray = () => {
  if (isSuperAdmin() || isAdmin())
    return [
      { title: 'Admin', value: 'admin' },
      { title: isAdmin() ? 'My Clients' : 'All Clients', value: 'client' },
      { title: 'Technician', value: 'technician' },
    ];
  // else if (isAdmin())
  //   return [
  //     { title: 'Client', value: 'client' },
  //     { title: 'Technician', value: 'technician' },
  //   ];
};

export const issuesActionsArray = (isReport) => {
  if (isReport) {
    return [
      { title: 'Send Report to client', value: 'Send Report to client' },
      { title: 'Change Status to Completed', value: 'Completed' },
      {
        title: 'Change Status  to Billing Follow-up',
        value: 'Billing Follow Up',
      },
      {
        title: 'Change Status to Team Discussion Needed',
        value: 'Team Discussion Needed',
      },
      {
        title: 'Change Status to Ready for Billing Review',
        value: 'Ready for Billing Review',
      },
    ];
  }
  if (isClient()) {
    return [
      { title: 'Proceed', value: 'Proceed' },
      { title: 'Decline', value: 'Decline' },
      // { title: 'Message to admin', value: 'Message to admin' },
      // { title: 'Add Note', value: 'Notes' },
    ];
  } else {
    return [
      // { title: 'Assign to an user', value: 'Assign to an user' },
      { title: 'Mark as Completed', value: 'Mark as Completed' },
      // { title: 'Request Client Directive', value: 'Request Client Directive' },
      // {
      //   title: 'Reminder for client directive',
      //   value: 'Reminder for client directive',
      // },
      { title: 'Send Update', value: 'Send Update' },
      { title: 'Create a job', value: 'Create a job' },
    ];
  }
};

export const reportActionArray = (confirm, action) => {
  return confirm
    ? [
        // { title: 'Follow up', value: 'Follow up' },
        // { title: 'Create an issue', value: 'Create an issue' },
        { title: 'Approve This Task', value: 'Approve This Issue' },
      ]
    : action
    ? [
        { title: 'Send Report to client', value: 'Send Report to client' },
        { title: 'Completed', value: 'Completed' },
        { title: 'Billing Follow-up', value: 'Billing Follow-up' },
        { title: 'Team Discussion Needed', value: 'Team Discussion Needed' },
      ]
    : [
        // { title: 'Follow up', value: 'Follow up' },
        // { title: 'Create an issue', value: 'Create an issue' },
        { title: 'Approve This Task', value: 'Approve This Issue' },
      ];
};

export const reportMediaArray = [
  { title: 'Delete', value: 'Delete' },
  { title: 'Replace', value: 'Replace' },
];

export const issuePriorityArray = [
  { title: 'Low', value: 'Low' },
  { title: 'Medium', value: 'Medium' },
  { title: 'High', value: 'High' },
  { title: 'Critical', value: 'Critical' },
];

export const issueTypeArray = [
  { title: '3rd Party Maintenance', value: '3rd Party Maintenance' },
  { title: 'Arrival / Departure Tasks', value: 'Arrival / Departure Tasks' },
  { title: 'Project / Repair', value: 'Project / Repair' },
  { title: 'Spectrum Maintenance', value: 'Spectrum Maintenance' },
];

export const jobStatusArray = [
  { title: 'New', value: 'New' },
  { title: 'In Progress', value: 'In Progress' },
  { title: 'Ready for Admin Review', value: 'Ready for Admin Review' },
  { title: 'Ready for Billing Review', value: 'Ready for Billing Review' },
  { title: 'Completed', value: 'Completed' },
  { title: 'Cancelled', value: 'Cancelled' },
  { title: 'Team Discussion Needed', value: 'Team Discussion Needed' },
  { title: 'Billing Follow Up', value: 'Billing Follow Up' },
  { title: 'Activity', value: 'Activity' },
  { title: 'Scheduled', value: 'Scheduled' },
];

export const reportActionDropdownArray = [
  { title: 'Send Report to client', value: 'Send Report to client' },
  { title: 'Completed', value: 'Completed' },
  { title: 'Billing Follow-up', value: 'Billing Follow-up' },
  { title: 'Team Discussion Needed', value: 'Team Discussion Needed' },
];

export const frequencyArray = [
  { title: 'Daily', value: 'Daily' },
  { title: 'Weekly', value: 'Weekly' },
  { title: 'Monthly', value: 'Monthly' },
];

export const workingDaysArray = [
  // { title: 'Working days', value: 'Working days' },
  { title: 'Days', value: 'Days' },
  { title: 'Weeks', value: 'Weeks' },
  { title: 'Months', value: 'Months' },
  { title: 'Years', value: 'Years' },
];

export const digitsRegex = /^[0-9]+$/g;

export const tableCell = (theme, row, userAvailability) => {
  return {
    padding: '12px 16px',
    borderBottom: row ? 'none' : `1px solid ${theme.palette.background_border}`,
    background:
      userAvailability === 'Here'
        ? Colors.DEPARTURE_EVENT
        : row
        ? theme.palette.table_row_background
        : 'transparent',
  };
};

export const secondaryTableCell = (theme, isLastRow, userAvailability) => {
  return {
    borderBottom: isLastRow
      ? `1px solid ${theme.palette.background_border}`
      : 'none',
    background:
      userAvailability === 'Here'
        ? Colors.DEPARTURE_EVENT
        : theme.palette.table_row_background,
  };
};

export const dateRangeArray = [
  { title: 'Today', value: 'Today' },
  { title: 'Yesterday', value: 'Yesterday' },
  { title: 'Tomorrow', value: 'Tomorrow' },
  { title: 'This Week', value: 'This Week' },
  { title: 'Last Week', value: 'Last Week' },
  { title: 'Next Week', value: 'Next Week' },
  { title: 'This Month', value: 'This Month' },
  { title: 'Last Month', value: 'Last Month' },
  { title: 'Next Month', value: 'Next Month' },
  { title: 'This Year', value: 'This Year' },
  { title: 'Last Year', value: 'Last Year' },
  { title: 'Next Year', value: 'Next Year' },
  { title: 'All Time', value: 'All Time' },
];

export const getDateRangeValue = (type) => {
  let data = {};
  switch (type) {
    case 'Today':
      data.startDate = moment();
      data.endDate = moment();
      return data;
    case 'Yesterday':
      data.startDate = moment().subtract(1, 'days');
      data.endDate = moment().subtract(1, 'days');
      return data;
    case 'Tomorrow':
      data.startDate = moment().add(1, 'days');
      data.endDate = moment().add(1, 'days');
      return data;
    case 'This Week':
      data.startDate = moment().startOf('week');
      data.endDate = moment().endOf('week');
      return data;
    case 'Last Week':
      data.startDate = moment().subtract(1, 'weeks').startOf('week');
      data.endDate = moment().subtract(1, 'weeks').endOf('week');
      return data;
    case 'Next Week':
      data.startDate = moment().add(1, 'weeks').startOf('week');
      data.endDate = moment().add(1, 'weeks').endOf('week');
      return data;
    case 'This Month':
      data.startDate = moment().startOf('month');
      data.endDate = moment().endOf('month');
      return data;
    case 'Last Month':
      data.startDate = moment().subtract(1, 'month').startOf('month');
      data.endDate = moment().subtract(1, 'month').endOf('month');
      return data;
    case 'Next Month':
      data.startDate = moment().add(1, 'month').startOf('month');
      data.endDate = moment().add(1, 'month').endOf('month');
      return data;
    case 'This Year':
      data.startDate = moment().startOf('year');
      data.endDate = moment().endOf('year');
      return data;
    case 'Last Year':
      data.startDate = moment().subtract(1, 'year').startOf('year');
      data.endDate = moment().subtract(1, 'year').endOf('year');
      return data;
    case 'Next Year':
      data.startDate = moment().add(1, 'year').startOf('year');
      data.endDate = moment().add(1, 'year').endOf('year');
      return data;
    default:
      return data;
  }
};

export const pageSizeList = [15, 25, 50, 75, 100];

export const getLocaleStorageVal = (fieldName) => {
  return JSON.parse(localStorage.getItem(fieldName));
};

export const setLocaleStorageVal = (fieldName, data) => {
  localStorage.setItem(fieldName, JSON.stringify(data));
};

export const compare2Object = (obj1 = {}, obj2 = {}) => {
  return (
    JSON.stringify(obj1, Object.keys(obj1).sort()) ===
    JSON.stringify(obj2, Object.keys(obj2).sort())
  );
};

export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61' },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49' },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33' },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81' },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1' },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const numberRegex = (e) => {
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  exceptThisSymbols.includes(e.key) && e.preventDefault();
};

export const issuesDetailTabArray = [
  { id: 'details', name: 'Task Summary', length: 0 },
  { id: 'notes', name: 'Comments and History', length: 0 },
  { id: 'internal-notes', name: 'Internal Notes', length: 0 },
  { id: 'related-jobs', name: 'Related Jobs', length: 0 },
  { id: 'supplies', name: 'Supplies Needed', length: 0 },
  { id: 'media', name: 'Media', length: 0 },
];

// export const issuesDetailTabArray1 = [
//   { id: 'details', name: 'Details', length: 0 },
//   { id: 'notes', name: 'Comments and History', length: 0 },
// ]

export const issuesDetailTabArrayClient = [
  { id: 'details', name: 'Task Summary', length: 0 },
  { id: 'notes', name: 'Comments and History', length: 0 },
  { id: 'related-jobs', name: 'Related Jobs', length: 0 },
  { id: 'media', name: 'Media', length: 0 },
];

export const issuesDetailTabArrayDashboard = [
  { id: 'details', name: 'Task Summary', length: 0 },
  { id: 'notes', name: 'Comments and History', length: 0 },
];

export const jobsDetailTabArray = (isClient) =>
  isClient
    ? [
        { id: 'details', name: 'Job Summary', length: 0 },
        { id: 'notes', name: 'Comments and History', length: 0 },
        { id: 'media', name: 'Media', length: 0 },
      ]
    : [
        { id: 'details', name: 'Job Summary', length: 0 },
        { id: 'notes', name: 'Comments and History', length: 0 },
        { id: 'internalNotes', name: 'Internal Notes', length: 0 },
        { id: 'media', name: 'Media', length: 0 },
      ];

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDgslUkiu26Av2qFl2mcRvEXpppk126ruo';

export const ONESIGNAL_APP_ID = '2aaa96e5-e355-4561-90b1-a7a901b9bc88';
export const ONESIGNAL_SAFARI_WEB_ID =
  'web.onesignal.auto.105cd246-aae6-4c14-8684-2fd8214524d1';

// ======== Table Sorting Common Function =======

export const applySortFilter = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

export const getComparator = (order, orderBy, descComparator) => {
  const descendingComparator = (a, b, orderBy) => {
    let asceding = b[orderBy] < a[orderBy];
    let descding = b[orderBy] > a[orderBy];
    if (asceding) return -1;
    if (descding) return 1;
    return 0;
  };
  return order === 'desc'
    ? (a, b) =>
        descComparator
          ? descComparator(a, b, orderBy)
          : descendingComparator(a, b, orderBy)
    : (a, b) =>
        descComparator
          ? -descComparator(a, b, orderBy)
          : -descendingComparator(a, b, orderBy);
};

export const vendorTempObj = {
  preTech: 'Preferred Technician',
  techPhone: 'Technician Phone Number',
  address: 'Address',
  websiteLink: 'Website Link',
  notes: 'Notes',
};

export const DownloadCSVorPDF = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const defaultMediaType = {
  'image/jpeg': 'JPG Image',
  'image/png': 'PNG Image',
};

export const statusColor = (status) => {
  switch (status) {
    case 'New':
      return Colors.NEVY_BLUE;
    case 'In Progress':
      return Colors.STATUS_YELLOW;
    case 'Ready for Admin Review':
      return Colors.RED;
    case 'Ready for Billing Review':
      return Colors.LIGHT_ORANGE;
    case 'Team Discussion Needed':
      return Colors.STATUS_PINK;
    case 'Billing Follow Up':
      return Colors.STATUS_PURPLE;
    case 'Completed':
      return Colors.LIGHT_GREEN;
    case 'Cancelled':
      return Colors.GRAY;
    case `Requested Client's Input`:
      return Colors.STATUS_GREENISH_BLUE;
    case `Declined`:
      return Colors.DARK_RED;
    case `Scheduled`:
      return Colors.ORANGE;
    case `Completed - Recurring`:
      return Colors.GREEN;
    case 'Waiting on Vendor':
      return Colors.STATUS_LIGHT_PURPLE;
    case 'Opened':
      return Colors.LIGHT_GREEN;
    case 'Unopened':
      return Colors.RED;
    case 'In Active':
      return Colors.RED;
    case 'Active':
      return Colors.LIGHT_GREEN;
    case 'Need to Schedule':
      return Colors.DARK_ORANGE;
    case 'On Hold':
      return Colors.DARK_GRAY;
    case 'Monitoring':
      return Colors.BABY_BLUE;
    default:
      return Colors.NEVY_BLUE;
  }
};

export function hexToRgbaGradient(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', 0.15)'
    );
  }
  throw new Error('Bad Hex');
}

export const getFirstTwoLetter = (name) => {
  if (!name) return '';
  let splitted = name.split(' ');
  let char = '';
  for (let a of splitted) {
    char = char + a.charAt(0)?.toUpperCase();
  }
  return char?.toUpperCase();
};

export const getDuration = (start, end) => {
  const startDate = moment(start);
  const timeEnd = moment(end);
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  // console.log('Total Duration in millis:', diffDuration.asMilliseconds());
  // console.log('Seconds:', diffDuration.seconds());
  let days = '',
    hours = '',
    minutes = '';
  if (diffDuration.days() > 0) {
    days =
      diffDuration.days() > 1
        ? diffDuration.days() + ' days '
        : diffDuration.days() + ' day ';
  }
  if (diffDuration.hours() > 0) {
    hours =
      diffDuration.hours() > 1
        ? diffDuration.hours() + ' hours '
        : diffDuration.hours() + ' hour ';
  }
  if (diffDuration.minutes() > 0) {
    minutes =
      diffDuration.minutes() > 1
        ? diffDuration.minutes() + ' minutes '
        : diffDuration.minutes() + ' minute ';
  }

  if (
    diffDuration.days() > 0 ||
    diffDuration.hours() > 0 ||
    diffDuration.minutes() > 0
  ) {
    return diffDuration.days() > 0 ? days + hours : hours + minutes;
  }
};

export const IssueFoundDetailList = [
  { title: 'Task Title', relation: 'issueTitle', output: 'textbox' },
  { title: 'Task Type', relation: 'type', output: 'selection' },
  { title: 'Task Category', relation: 'category', output: 'selection' },
  {
    title: 'Follow Up',
    relation: 'followUp',
    output: 'toggle',
  },
  {
    title: 'Discuss with admin',
    relation: 'discussWithAdmin',
    output: 'toggle',
  },
  {
    title: 'Please explain the issue, along with solution',
    relation: 'description',
    output: 'textarea',
  },
  {
    title: 'If to be conducted by us, inform what supplies will be needed',
    relation: 'suppliesNeeded',
    output: 'textarea',
  },
  // {
  //   title: 'Notes',
  //   relation: 'notes',
  //   output: 'textarea',
  // },
];

export const hereAwayDropdown = [
  { title: 'Here', value: 'Here' },
  { title: 'Away', value: 'Away' },
];

export const EditorModules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'], // 'blockquote'
    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const EditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const manageNotesHref = (tagId) => {
  const tagElement = document.getElementById(tagId);
  if (tagElement) {
    const anchorTags = tagElement.getElementsByTagName('a');
    for (let i = 0; i < anchorTags.length; i++) {
      const href = anchorTags[i].getAttribute('href');
      if (href && !href.startsWith('https://')) {
        anchorTags[i].setAttribute('href', 'https://' + href);
      }
    }
  }
};

export const colorListArray = [
  { title: 'Red', value: '#ED4242' },
  { title: 'Green', value: '#34A885' },
  { title: 'Yellow', value: '#FBBC04' },
  { title: 'Gray', value: '#979797' },
  { title: 'Light Blue', value: '#B6D0E2' },
];

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const makeUniqId = (length = 22) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
