import axios from 'axios';
import { CREATE_FOLDER } from '../../constants';

export const createFolder = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadCreateFolder());

  axios
    .post(`/media/folder`, data, config)
    .then((res) => {
      dispatch(setCreateFolder(res));
      onSuccess(res.data.data);
    })
    .catch((err) => {
      dispatch(errorCreateFolder(err.response));
      onError(err.response);
    });
};

export const loadCreateFolder = () => ({
  type: CREATE_FOLDER.LOAD,
});

export const setCreateFolder = (folderData) => ({
  type: CREATE_FOLDER.SUCCESS,
  payload: folderData.data.data,
});

export const errorCreateFolder = (error) => ({
  type: CREATE_FOLDER.FAIL,
  payload: error,
});
