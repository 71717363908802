import axios from 'axios';
import { UPDATE_RECURRENCE } from '../../../constants';

export const updateRecurrence =
  (jobId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateRecurrence());
    axios
      .put(`/jobs/updateRecurrence?jobId=${jobId}`, data, config)
      .then((res) => {
        dispatch(setUpdateRecurrence(res.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateRecurrence(err.response));
        onError(err.response);
      });
  };

export const loadUpdateRecurrence = () => ({
  type: UPDATE_RECURRENCE.LOAD,
});

export const setUpdateRecurrence = (data) => ({
  type: UPDATE_RECURRENCE.SUCCESS,
  payload: data,
});

export const errorUpdateRecurrence = (err) => ({
  type: UPDATE_RECURRENCE.FAIL,
  payload: err,
});
