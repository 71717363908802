import axios from 'axios';
import { DELETE_JOB_NOTE } from '../../../constants';

export const deleteJobNote =
  (jID, nID, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteJobNote());
    axios
      .put(`/jobs/deleteJobNote?jobId=${jID}&noteId=${nID}`, {}, config)
      .then((res) => {
        dispatch(setDeleteJobNote(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorDeleteJobNote(err.response));
        onError(err.response);
      });
  };

export const loadDeleteJobNote = () => ({
  type: DELETE_JOB_NOTE.LOAD,
});

export const setDeleteJobNote = (data) => ({
  type: DELETE_JOB_NOTE.SUCCESS,
  payload: data.data,
});

export const errorDeleteJobNote = (error) => ({
  type: DELETE_JOB_NOTE.FAIL,
  payload: error,
});
