import { useTheme } from '@mui/styles';
import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import Button from '../../../components/Button';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../components/Textbox';
import { SendReportSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { sendReport } from '../../../redux/actions/jobActions/reportActions/sendReport';
import { getErrorMessage, manageNotesHref } from '../../../utils/appUtils';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import TagsInput from '../../../components/TagsInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { icons } from '../../../utils/icons';
import { sendUpdate } from '../../../redux/actions/jobActions/issuesActions/sendUpdate';
import classNames from 'classnames';
import moment from 'moment';

const EditorModules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'], // 'blockquote'
    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    ['link'], // 'image', 'video'
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const EditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const imageTypes = ['image/png', 'image/jpeg'];

const SendReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [jobId, setJobId] = useState(props.selectedJobId);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [quillData, setQuillData] = useState({ theme: 'snow' });
  const [issueId, setIssueId] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props?.selectedIssueId) {
      setIssueId(props?.selectedIssueId);
    }
  }, [props?.selectedIssueId]);

  const onSuccess = (message) => {
    if (message) alert.success(message);
  };

  const formik = useFormik({
    initialValues: {
      reportMsg: '',
      tags: [],
      files: [],
      ccTags: [],
      bccTags: [],
      subject: '',
    },
    validationSchema: SendReportSchema,
    onSubmit: (values) => {
      sendJobReport(values);
    },
  });

  useEffect(() => {
    if (props.isReport) {
      setFieldValue(
        'subject',
        `Your Home Visit Report - ${moment(props?.conductedOn).format(
          'MM/DD/YYYY'
        )}`
      );
      setFieldValue(
        'reportMsg',
        `<p>Hello ${
          props?.client?.firstName || ''
        },</p><p>Your home visit report is completed and ready for your review.</p>`
      );
    }
  }, [props.conductedOn, props?.client]);

  const sendJobReport = (values) => {
    // values.tags.shift();
    const data = {
      to: values.tags || [],
      cc: values.ccTags || [],
      bcc: values.bccTags || [],
      subject: values.subject || '',
      files: values.files || [],
    };
    if (values.reportMsg) {
      data.description = values.reportMsg;
    }
    if (props?.isIssueUpdate && issueId && !jobId) {
      props.sendUpdate(
        issueId,
        data,
        (msg) => onSuccess(msg),
        (err) => onError(err),
        props.onNewClose
      );
    } else {
      props.sendReport(
        jobId,
        data,
        (err) => onError(err),
        (msg) => onSuccess(msg),
        props.onNewClose
      );
    }
  };

  useEffect(() => {
    if (props.client?.email !== '') {
      handleSelectedTags([props.client?.email], 'tags');
    }
    if (props?.client?.ccEmails?.length) {
      handleSelectedTags(props?.client?.ccEmails, 'ccTags');
    }
  }, []);

  const handleSelectedTags = (tags, key) => {
    setFieldValue(key, tags);
  };

  const handleFileUpload = (file) => {
    let urlFiles = [...selectedFiles];
    let allFiles = [...values.files, ...file.target.files];
    for (let i of file.target.files) {
      let image = URL.createObjectURL(i);
      let data = { url: image, type: i.type, name: i.name };
      urlFiles.push(data);
    }
    setSelectedFiles(urlFiles);
    setFieldValue('files', allFiles);
  };

  const onClickCancel = (index) => {
    const newArr = selectedFiles.filter((i, ind) => ind !== index);
    setSelectedFiles(newArr);
    const newData = values.files.filter((i, ind) => ind !== index);
    setFieldValue('files', newData);
  };

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {/* {!disabled && key !== 0 && ( */}
        <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        {/* )} */}
      </span>
    );
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  useEffect(() => {
    if (values?.reportMsg) manageNotesHref('reportMessage');
  }, [values.reportMsg]);

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <div className={classes.send_report_container}>
          <NewFormHeader
            title={
              props?.isIssueUpdate ? Strings.SEND_UPDATE : Strings.SEND_REPORT
            }
            onNewClose={props.onNewClose}
          />
          <label className={classes.text_small_light} style={{ marginTop: 10 }}>
            {Strings.WHAT_YOU_SEND}
          </label>
          {!props?.isIssueUpdate && props.isReviewReport && (
            <Button
              text={Strings.REVIEW_REPORT}
              containerClassName={classes.review_report_button_container}
              className={classes.review_report_button}
              onClick={() =>
                navigate('/app/report', {
                  state: {
                    jobId: jobId,
                  },
                })
              }
            />
          )}
          <div style={{ marginTop: 20 }}>
            <TagsInput
              selectedTags={(value) => handleSelectedTags(value, 'tags')}
              placeholder='Add Email'
              tags={values.tags}
              renderTag={renderTags}
              error={Boolean(touched.tags && errors.tags)}
              helperText={touched.tags && errors.tags}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <TagsInput
              selectedTags={(value) => handleSelectedTags(value, 'ccTags')}
              placeholder={`Add cc's`}
              tags={values.ccTags}
              renderTag={renderTags}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <TagsInput
              selectedTags={(value) => handleSelectedTags(value, 'bccTags')}
              placeholder={`Add Bcc's`}
              tags={values.bccTags}
              renderTag={renderTags}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <Textbox
              name='subject'
              onChange={handleChange('subject')}
              value={values.subject}
              placeholder={Strings.SUBJECT_LINE}
              className={classes.information_input}
            />
          </div>
          <FormikProvider value={formik}>
            <Form
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
              className={classes.form_container}
            >
              <div className={classes.form_input_container}>
                <ReactQuill
                  id='reportMessage'
                  theme={quillData.theme}
                  onChange={handleChange('reportMsg')}
                  value={values.reportMsg}
                  modules={EditorModules}
                  formats={EditorFormats}
                  // bounds={'.app'}
                  placeholder={'Type here'}
                  style={{ width: '100%' }}
                  // readOnly={viewMode}
                />
                {touched.reportMsg && errors.reportMsg && (
                  <p className={classes.input_error}>
                    {touched.reportMsg && errors.reportMsg}
                  </p>
                )}
              </div>
              <div>
                <input
                  type='file'
                  ref={inputRef}
                  className={classes.selectFiles}
                  onChange={(event) => handleFileUpload(event)}
                  multiple
                  accept='application/pdf, image/png, image/jpeg, .doc,.docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                />
                <Button
                  text={Strings.SELECT_FILES}
                  containerClassName={classes.secondary_button_container}
                  className={classes.selectFiles_btn}
                  loaderColor={theme.palette.background_button_text}
                  onClick={() => inputRef.current.click()}
                />
              </div>
              <div>
                <Grid item xs={12} mt={2}>
                  <Grid container style={{ gap: 10 }} justifyContent={'center'}>
                    {selectedFiles?.map((file, index) => {
                      return (
                        <Grid
                          key={index}
                          item
                          className={classes.img_Container}
                        >
                          <img
                            src={icons().closeWhiteIcon}
                            className={classes.cancel_img}
                            onClick={() => onClickCancel(index)}
                          />
                          {imageTypes.includes(file?.type) ? (
                            <img
                              src={file?.url}
                              className={classes.image_view}
                            />
                          ) : (
                            <div className={classes.file_view}>
                              <div>{file?.name}</div>
                            </div>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
              <Button
                type='submit'
                text={Strings.SEND_THE_EMAIL}
                loading={props.sendReportLoading || props?.sendUpdateLoading}
                disabled={props.sendReportLoading || props?.sendUpdateLoading}
                containerClassName={classes.add_button_container}
                className={classes.add_button}
                loaderColor={theme.palette.background_button_text}
              />
              <Button
                text={Strings.CANCEL_TEXT}
                containerClassName={classes.secondary_button_container}
                className={classes.secondary_button}
                loaderColor={theme.palette.background_button_text}
                disabled={props.sendReportLoading || props?.sendUpdateLoading}
                onClick={props.onNewClose}
              />
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sendReportData: state.sendReportData.data,
  sendReportLoading: state.sendReportData.loading,

  sendUpdateData: state.sendUpdateData.data,
  sendUpdateLoading: state.sendUpdateData.loading,
});

export default connect(mapStateToProps, {
  sendReport,
  sendUpdate,
})(SendReport);
