import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  dashboard_container: {
    width: '100%',
    height: 'calc(100% - 81px);',
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 75px);',
    },
  },
  title_container: {
    paddingTop: '20px !important',
  },
  dashboard_left_container: {
    width: 'calc(65% - 36px)',
    height: 'calc(100% - 40px)',
    padding: '20px 18px',
    backgroundColor: theme.palette.background,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '20px 18px',
      width: 'calc(100% - 36px)',
      overflow: 'visible',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 14px',
      width: 'calc(100% - 28px)',
    },
  },
  dashboard_right_container: {
    width: 'calc(35% - 48px)',
    height: 'calc(100% - 22px)',
    padding: '20px 24px 2px 24px',
    borderLeft:
      theme.palette.mode === 'dark'
        ? 'none'
        : `1px solid ${theme.palette.background_border}`,
    backgroundColor: theme.palette.secondary_background,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '20px 18px',
      width: 'calc(100% - 36px)',
      overflow: 'visible',
      height: 'auto',
      borderLeft: 'none',
      borderTop:
        theme.palette.mode === 'dark'
          ? 'none'
          : `1px solid ${theme.palette.background_border}`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 14px',
      width: 'calc(100% - 28px)',
      borderLeft: 'none',
      borderTop:
        theme.palette.mode === 'dark'
          ? 'none'
          : `1px solid ${theme.palette.background_border}`,
    },
  },
  announcement_header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  next_prev_button: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  announcement_title: {
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.title_text,
    fontSize: 18,
    marginLeft: 8,
    fontWeight: 400,
  },
  add_new_container: {
    height: 45,
    marginLeft: 'auto',
  },
  add_new_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
  },
  common_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.search_text,
    '& a': {
      color: theme.palette.background_button,
    }
  },
  text_bold: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: theme.palette.search_text,
  },
  text_blue: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    marginLeft: 2,
    textDecoration: 'none',
  },
  text_red: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: Colors.TOMATO_RED,
  },
  common_header_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.title_text,
  },
  // arrival_departure_feedback_container: {
  //   display: 'flex',
  //   width: '100%',
  //   height: '100%',
  //   gap: 16,
  //   marginTop: 20,
  //   overflow: 'auto',
  // },
  list_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  upcoming_list_container: {
    maxHeight: '620px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
  // supplies_needed_container: {
  //   height: '60%',
  //   width: 'calc(100% - 2px)',
  //   [theme.breakpoints.down('md')]: {
  //     height: 'auto',
  //   },
  // },
  // properties_container: {
  //   height: 'calc(40% - 19px)',
  //   marginTop: 15,
  //   width: 'calc(100% - 2px)',
  //   [theme.breakpoints.down('md')]: {
  //     height: 'auto',
  //   },
  // },
  latest_report_container: {
    width: 'auto',
    // marginTop: 20,
    // height: '350px',
    maxHeight: 850,
    borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  common_box_for_client: {
    width: '50%',
  },
  box_header: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  upcoming_data_container: {
    display: 'flex',
    flex: 1,
    alignItems: 'centre',
    padding: '15px 0',
    // borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  flex_view: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: 7,
  },
  flex_column_view: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  arrival_departure_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
  },
  time_view_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
  },
  time_data_view: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 12,
  },
  clock_icon: {
    width: 16,
    height: 16,
  },
  primary_button: {
    backgroundColor: theme.palette.background_button,
    border: `1px solid ${theme.palette.background_button}`,
    color: Colors.WHITE,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
    padding: '13px 24px',
  },
  client_profile_box: {
    border: `1px solid ${theme.palette.background_border}`,
    borderRadius: 8,
    padding: 10,
    overflow: 'auto',
    // marginTop: 20,
    minHeight: 145,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  client_profile_content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 16,
  },
  issue_container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  form_label: {
    color: theme.palette.form_label,
  },
  // form_input: {
  //   '&::placeholder': {
  //     color: theme.palette.input_placeholder,
  //   },
  //   backgroundColor: 'transparent',
  //   color: theme.palette.profile_title,
  //   '&:focus': {
  //     border: `1px solid ${theme.palette.input_border}`,
  //   },
  // },
  form_input_container: {
    marginTop: 12,
    // backgroundColor: theme.palette.background,
  },
  selector: {
    backgroundColor: theme.palette.background,
    borderRadius: 8,
  },
  issues_list_view: {
    marginTop: 20,
    height: '100%',
    overflow: 'auto',
    paddingBottom: 10,
    [theme.breakpoints.down('md')]: {
      overflow: 'visible',
      paddingBottom: 0,
    },
  },
  cards_container: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  },
  bottom_card_container: {
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
  },
  cards_inner_container: {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: 20,
    [theme.breakpoints.down('sm')]: {
      minWidth: 785,
    },
  },
  report_box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  report_title_text: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.search_text,
    marginBottom: 5,
  },
  report_view_button_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  view_icon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  icon_text: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.search_text,
  },
  dashboard_tab_container: {
    marginTop: '0px !important',
  },
  underline_tab: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    paddingTop: '0px !important',
  },
  issue_tab_container: {
    marginTop: '20px !important',
    minHeight: 32,
  },

  issue_tab_content: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
  toolbox_label: {
    fontWeight: 300,
    fontSize: 24,
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.title_text,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  avatar_icon: {
    height: 75,
    width: 75,
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 50,
    },
  },
  flexView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: 25,
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
  },
  property_container: {
    width: 'calc(100% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.background_border}`,
    padding: 10,
  },
  property_title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.profile_title,
  },
  property_subText: {
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: 14,
    marginTop: 5,
  },
  commonBox_noBorder_container: {
    border: 'none',
  },
  clients_content_container: {
    height: '100%',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
  },
  admin_content_container: {
    height: 620,
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  message_to_admin: {
    width: '100%',
  },
  property_image: {
    borderRadius: 16,
    width: '100%',
    height: 200,
    objectFit: 'cover',
  },
  delete_icon: {
    cursor: 'pointer',
  },
  icons: {
    marginRight: 8,
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  client_profile_boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  gridView: {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 20,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'none',
      columnGap: 0,
      gridAutoFlow: 'row',
      rowGap: 20,
    },
  },
  gridColumnView: {
    display: 'grid',
    overflow: 'hidden',
    height: '100%',
  },
  adminGridRowView: {
    display: 'grid',
    gridAutoFlow: 'row',
    rowGap: 20,
    gridTemplateRows: 'calc(60% - 15px)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: 'auto auto',
    },
  },
  commonBorderBox: {
    display: 'grid',
    border: `1px solid ${theme.palette.background_border}`,
    borderRadius: 8,
    minHeight: 0,
    overflow: 'hidden',
    gridTemplateRows: 'min-content',
  },
  commonBoxHeader: {
    padding: 15,
    paddingBottom: 0,
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  commonBoxContent: {
    padding: '0 15px 10px 15px',
    overflow: 'auto',
  },
  client_announcement_box: {
    // marginTop: 20,
    // height: 165,
    overflow: 'auto',
    position: 'relative',
  },
  clientAnnouncement: {
    height: 165,
  },
  adminAnnouncement: {
    marginTop: 0,
    maxHeight: 145,
  },
  arrival_departure_list_container: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  gridRowView: {
    display: 'grid',
    minHeight: 0,
    rowGap: 20,
    // alignContent: 'space-between'
  },
  text_view_list: {
    fontSize: 14,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 8,
    left: 10,
  },
  quillHeader: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clear_txt: {
    fontSize: 15,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    cursor: 'pointer',
    marginTop: 8,
  },
  view_all_txt: {
    fontSize: 15,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    cursor: 'pointer',
  },
  borderBottomStyle: {
    borderBottom: `1px solid ${theme.palette.background_border}`,
  },
  arrivalSubText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: '14px !important',
    marginTop: 5,
    wordWrap: 'break-word',
  },
  customQuillContainer : {
    width: '100% !important',
    '& .ql-container.ql-snow': {
      border: 'none !important',
      padding: '0 !important',
    },
    '& .ql-editor': {
      padding: '0 !important',
    },
  }
}));
