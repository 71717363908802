import axios from 'axios';
import { FORGOT_PASSWORD } from '../../constants';

export const forgotPassword =
  (data, navigate, onSuccess, onError) => (dispatch) => {
    dispatch(loadForgotPassword());
    axios
      .post('auth/forgotPassword', data)
      .then((res) => {
        onSuccess(res.data.data);
        navigate('/');
        dispatch(setForgotPassword(res));
      })
      .catch((err) => {
        dispatch(errorForgotPassword(err.response));
        onError(err.response);
      });
  };

export const loadForgotPassword = () => ({
  type: FORGOT_PASSWORD.LOAD,
});

export const setForgotPassword = (data) => ({
  type: FORGOT_PASSWORD.SUCCESS,
  payload: data.data,
});

export const errorForgotPassword = (error) => ({
  type: FORGOT_PASSWORD.FAIL,
  payload: error,
});
