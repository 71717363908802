import axios from 'axios';
import { DELETE_ANNOUNCEMENT } from '../../constants';

export const deleteAnnouncement =
  (data, closeDialog, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteAnnouncement());
    axios
      .delete(`/admin/announcement`, { data, headers: config.headers })
      .then((res) => {
        dispatch(setDeleteAnnouncement(res));
        closeDialog();
      })
      .catch((err) => {
        dispatch(errorDeleteAnnouncement(err.response));
        onError(err.response);
      });
  };

export const loadDeleteAnnouncement = () => ({
  type: DELETE_ANNOUNCEMENT.LOAD,
});

export const setDeleteAnnouncement = (data) => ({
  type: DELETE_ANNOUNCEMENT.SUCCESS,
  payload: data,
});

export const errorDeleteAnnouncement = (error) => ({
  type: DELETE_ANNOUNCEMENT.FAIL,
  payload: error,
});
