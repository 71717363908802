import React, { useEffect } from 'react';
import NewFormHeader from '../../../../components/NewFormHeader';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../../components/Textbox';
import { AddProgramTypeSchema } from '../../../../utils/validationSchemas';
import Dropdown from '../../../../components/Dropdown';
import { getErrorMessage, programTypeArray } from '../../../../utils/appUtils';
import Button from '../../../../components/Button';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { createProgram } from '../../../../redux/actions/clientActions/addProgramType';
import { useAlert } from 'react-alert';
import { updateProgramType } from '../../../../redux/actions/clientActions/updateProgramType';

const NewProgramType = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();

  useEffect(() => {
    if (props.isEdit) {
      const { title, status } = props.data;
      setFieldValue('name', title);
      setFieldValue('status', status);
    }
  }, []);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      status: 'Active',
    },
    validationSchema: AddProgramTypeSchema,

    onSubmit: (values) => {
      addProgram(values);
    },
  });

  const addProgram = (values) => {
    const data = {
      title: values.name,
      status: values.status,
    };
    if (props.isEdit) {
      props.updateProgramType(props.data._id, data, props.onNewClose, (err) =>
        onError(err)
      );
    } else {
      props.createProgram(data, (err) => onError(err), props.onNewClose);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={
            props.isEdit ? Strings.EDIT_PROGRAM_TYPE : Strings.NEW_PROGRAM_TYPE
          }
          onNewClose={props.onNewClose}
        />
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.form_details}>
              <Textbox
                name='name'
                onChange={handleChange('name')}
                value={values.name}
                label={Strings.PROGRAM_NAME}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                placeholder={Strings.ENTER_PROGRAM_NAME}
                labelClassName={classes.form_label}
                className={classes.form_input}
              />
              <Dropdown
                name='status'
                onChange={handleChange('status')}
                value={values.status}
                label={Strings.STATUS}
                // error={Boolean(touched.category && errors.category)}
                // helperText={touched.category && errors.category}
                labelClassName={classes.form_label}
                // className={classes.form_input}
                containerClassName={classes.form_input_container}
                optionArray={programTypeArray}
              />
              <Button
                type='submit'
                text={
                  props.isEdit
                    ? Strings.UPDATE_PROGRAM
                    : Strings.ADD_NEW_PROGRAM
                }
                loading={
                  props.isEdit
                    ? props.updateProgramLoading
                    : props.addProgramLoading
                }
                disabled={
                  props.isEdit
                    ? props.updateProgramLoading
                    : props.addProgramLoading
                }
                containerClassName={classes.add_button_container}
                className={classes.add_button}
                loaderColor={theme.palette.background_button_text}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  addProgramData: state.addProgramData.data,
  addProgramLoading: state.addProgramData.loading,

  updateProgramData: state.updateProgramTypeData.data,
  updateProgramLoading: state.updateProgramTypeData.loading,
});

export default connect(mapStateToProps, {
  createProgram,
  updateProgramType,
})(NewProgramType);
