import axios from 'axios';
import { DELETE_PROGRAM_TYPE } from '../../constants';

export const deleteProgramType = (pID, closeDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteProgramType());
  axios
    .delete(`/client/deleteProgram/${pID}`, config)
    .then((res) => {
      dispatch(setDeleteProgramType(res));
      closeDialog();
    })
    .catch((err) => {
      dispatch(errorDeleteProgramType(err.response));
      onError(err.response);
    });
};

export const loadDeleteProgramType = () => ({
  type: DELETE_PROGRAM_TYPE.LOAD,
});

export const setDeleteProgramType = (data) => ({
  type: DELETE_PROGRAM_TYPE.SUCCESS,
  payload: data,
});

export const errorDeleteProgramType = (error) => ({
  type: DELETE_PROGRAM_TYPE.FAIL,
  payload: error,
});
