import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  input_error: {
    color: Colors.RED,
    fontSize: 10,
    fontFamily: "'Open Sans', sans-serif",
    margin: 0,
    left: 5,
  },
}));
