import React, { useEffect } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import Textbox from '../../../components/Textbox';
import Button from '../../../components/Button';
import { AddNotesSchema } from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createIssueNote } from '../../../redux/actions/clientActions/addIssueNote';
import { createJobNote } from '../../../redux/actions/jobActions/jobActions/addJobNote';
import { useAlert } from 'react-alert';
import {
  EditorFormats,
  EditorModules,
  getErrorMessage,
  isClient,
  manageNotesHref,
  quillData,
} from '../../../utils/appUtils';
import { Stack, useMediaQuery } from '@mui/material';
import FormSwitch from '../../../components/Switch';
import { updateJobNote } from '../../../redux/actions/jobActions/jobActions/updateJobNote';
import { updateIssueNote } from '../../../redux/actions/jobActions/issuesActions/updateIssueNote';
import ReactQuillTextbox from '../../../components/ReactQuillTextbox';
import ReactQuill from 'react-quill';

const AddNotes = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  const formik = useFormik({
    initialValues: {
      notes: '',
      internalNote: true,
      notifyClient: false,
    },
    validationSchema: AddNotesSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = (values) => {
    if (props?.isEditJobNote) {
      const data = {
        note: values.notes?.replace('<p><br></p>', ''),
        internalNotes: values.internalNote,
      };
      props.updateJobNote(
        props?.selectedNoteData?.jobId,
        props?.selectedNoteData?.noteId,
        data,
        onError,
        () => props?.onNewClose()
      );
    } else if (props?.isEditIssueNote) {
      const data = {
        note: values.notes?.replace('<p><br></p>', ''),
        noteType: values.internalNote ? 'internalNotes' : 'notes',
      };
      props.updateIssueNote(
        props?.selectedNote?.issueId,
        props?.selectedNote?._id,
        data,
        onError,
        () => {
          if (!data.internalNotes && isClient())
            onSuccess('Your comment on this task was successfully submitted');
          props?.onNewClose();
        }
      );
    } else {
      if (props.isIssue) {
        const data = {
          note: values.notes?.replace('<p><br></p>', ''),
          internalNotes: isClient() ? false : values.internalNote,
          notifyClient: isClient() ? false : values.notifyClient,
        };
        props.createIssueNote(
          props.selectedId,
          data,
          (res) => {
            props.onNewClose();
            props.onSuccess(res);
          },
          (err) => onError(err)
        );
      }
      if (props.isJob) {
        const data = {
          note: values.notes?.replace('<p><br></p>', ''),
          internalNotes: isClient() ? false : values.internalNote,
        };
        props.createJobNote(
          props.selectedId,
          data,
          () => props.onNewClose(),
          (err) => onError(err)
        );
      } else if (Object.keys(props?.isClientDirective).length > 0) {
        const data = {
          status: props?.isClientDirective?.action,
          note: values.notes?.replace('<p><br></p>', ''),
        };

        props.updateIssueByPerformAction(
          props?.isClientDirective?.id,
          data,
          (err) => onError(err),
          props.onNewClose
        );
      } else {
        const data = {
          note: values.notes?.replace('<p><br></p>', ''),
        };
        if (!isClient()) {
          data.internalNotes = values.internalNote;
          data.notifyClient = values.notifyClient;
        }
        props.createIssueNote(
          props.selectedId,
          data,
          () => {
            if (!data.internalNotes && isClient())
              onSuccess('Your comment on this task was successfully submitted');
            props.onNewClose();
          },
          (err) => onError(err)
        );
      }
    }
  };

  useEffect(() => {
    if (props?.isEditJobNote && props?.selectedNoteData) {
      const data = props?.selectedNoteData;
      setFieldValue('notes', data?.note || '');
      setFieldValue('internalNote', data?.internalNotes || false);
    }
  }, [props?.isEditJobNote, props?.selectedNoteData]);

  useEffect(() => {
    if (props?.isEditIssueNote && props?.selectedNote) {
      const data = props?.selectedNote;
      setFieldValue('notes', data?.note || '');
      setFieldValue('internalNote', data?.isInternalNote || false);
    }
  }, [props?.isEditIssueNote, props?.selectedNote]);

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.notes) manageNotesHref('notesQuill');
  }, [values.notes]);

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={`${classes.dialog_container} noteDialog-style`}>
          <NewFormHeader
            title={
              mobileView
                ? props?.isEditIssueNote || props?.isEditJobNote
                  ? Strings.EDIT_NOTE
                  : Strings.ADD_NOTE
                : props.isJob
                ? Strings.INTERNAL_NOTES
                : Strings.NOTES
            }
            onNewClose={props.onNewClose}
            containerClassName={classes.commentsDialogStyle}
          />
          <div className={classes.form_container}>
            <FormikProvider
              value={formik}
              style={{ display: 'flex', height: '100%' }}
            >
              <Form
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                {/* <Textbox
                  name='notes'
                  onChange={handleChange('notes')}
                  value={values.notes}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                  placeholder={Strings.TYPE_YOUR_NOTES}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  multiline
                  rows={1}
                /> */}
                <Stack spacing={1}>
                  <Stack height={200}>
                    <ReactQuill
                      id='notesQuill'
                      theme={quillData.theme}
                      modules={EditorModules}
                      formats={EditorFormats}
                      style={{ height: 150 }}
                      onChange={(value) => setFieldValue('notes', value)}
                      value={values.notes}
                      placeholder={
                        props.isJob
                          ? Strings.TYPE_YOUR_NOTES
                          : Strings.TYPE_YOUR_COMMENTS
                      }
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                    />
                  </Stack>
                  <Stack>
                    {!props.isJob &&
                      !isClient() &&
                      !props?.isClientDirective && (
                        <FormSwitch
                          label={Strings.INTERNAL_NOTES}
                          value={values.internalNote}
                          containerClassName={classes.switch_container}
                          onChange={() =>
                            setFieldValue('internalNote', !values.internalNote)
                          }
                        />
                      )}
                    {!values.internalNote &&
                      !isClient() &&
                      !props?.isEditJobNote &&
                      !props?.isEditIssueNote && (
                        <FormSwitch
                          label={Strings.NOTIFY_CLIENT}
                          value={values.notifyClient}
                          containerClassName={classes.switch_container}
                          onChange={() =>
                            setFieldValue('notifyClient', !values.notifyClient)
                          }
                        />
                      )}
                    <Button
                      type='submit'
                      text={
                        props?.isEditIssueNote
                          ? Strings.EDIT_NOTE
                          : props.isJob
                          ? Strings.ADD_INTERNAL_NOTE
                          : Strings.NOTES
                      }
                      loading={
                        props.addJobNoteLoading ||
                        props.addIssueNoteLoading ||
                        props.updateIssueByActionLoading ||
                        props.updateJobNoteLoading ||
                        props.updateIssueNoteLoading
                      }
                      disabled={
                        props.addJobNoteLoading ||
                        props.addIssueNoteLoading ||
                        props.updateIssueByActionLoading ||
                        props.updateJobNoteLoading ||
                        props.updateIssueNoteLoading
                      }
                      containerClassName={classes.add_button_container}
                      className={classes.add_button}
                      loaderColor={theme.palette.background_button_text}
                    />
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  addIssueNoteLoading: state.addIssueNoteData.loading,
  addJobNoteLoading: state.addJobNoteData.loading,

  updateIssueByActionLoading: state.updateIssueByActionData.loading,

  updateJobNoteLoading: state.updateJobNoteData.loading,

  updateIssueNoteLoading: state.updateIssueNoteData.loading,
});

export default connect(mapStateToProps, {
  createIssueNote,
  createJobNote,
  updateJobNote,
  updateIssueNote,
})(AddNotes);
