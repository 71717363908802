import axios from 'axios';
import { DELETE_FILTER } from '../../constants';

export const deleteFilter = (fId, onError, onDialogClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteFilter());

  axios
    .delete(`/user/filter/${fId}`, config)
    .then((res) => {
      dispatch(setDeleteFilter(res));
      onDialogClose();
    })
    .catch((err) => {
      dispatch(errorDeleteFilter(err.response));
      onError(err.response);
    });
};

export const loadDeleteFilter = () => ({
  type: DELETE_FILTER.LOAD,
});

export const setDeleteFilter = (data) => ({
  type: DELETE_FILTER.SUCCESS,
  payload: data.data,
});

export const errorDeleteFilter = (error) => ({
  type: DELETE_FILTER.FAIL,
  payload: error,
});
