import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  next_previous_container: {
    display: 'flex',
    alignItems: 'center',
  },
  next_previous_button: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  date_view: {
    color: theme.palette.profile_title,
    fontWeight: 400,
    fontSize: 20,
    // margin: '0px 40px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  date_year: {
    color: theme.palette.background_button,
    fontSize: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  date_picker: {
    padding: 0,
    '& .MuiOutlinedInput-notchedOutline ': {
      border: 'none !important',
    },
    '& .MuiOutlinedInput-input': {
      width: 0,
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
}));
