import axios from 'axios';
import { UPDATE_CLIENT } from '../../constants';

export const updateClient =
  (cId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateClient());
    axios
      .put(`client/updateClient?clientId=${cId}`, data, config)
      .then((res) => {
        dispatch(setUpdateClient(res));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateClient(err.response));
        onError(err.response);
      });
  };

export const loadUpdateClient = () => ({
  type: UPDATE_CLIENT.LOAD,
});

export const setUpdateClient = (data) => ({
  type: UPDATE_CLIENT.SUCCESS,
  payload: data.data,
});

export const errorUpdateClient = (error) => ({
  type: UPDATE_CLIENT.FAIL,
  payload: error,
});
