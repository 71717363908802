import axios from 'axios';
import { GET_ISSUE_BY_ID } from '../../../constants';

export const fetchIssueById = (issueId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadIssueById());

  axios
    .get(`/jobs/Issue/${issueId}`, config)
    .then((res) => dispatch(setIssueById(res)))
    .catch((err) => {
      dispatch(errorIssueById(err.response));
      onError(err.response);
    });
};

export const loadIssueById = () => ({
  type: GET_ISSUE_BY_ID.LOAD,
});

export const setIssueById = (issueData) => ({
  type: GET_ISSUE_BY_ID.SUCCESS,
  payload: issueData.data.data,
});

export const errorIssueById = (error) => ({
  type: GET_ISSUE_BY_ID.FAIL,
  payload: error,
});
