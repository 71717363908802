import React, { useState, useEffect } from 'react';
import TableToolbox from '../../../components/TableToolbox';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EnhancedTableHead from '../../../components/DataTables/TableHead';
import {
  applySortFilter,
  getComparator,
  getErrorMessage,
  getLocaleStorageVal,
  setLocaleStorageVal,
  tableCell,
} from '../../../utils/appUtils';
import { useTheme } from '@mui/material/styles';
import NewProgramType from './NewProgramType';
import { connect } from 'react-redux';
import { fetchProgramList } from '../../../redux/actions/clientActions/programTypeList';
import { CircularProgress } from '@material-ui/core';
import { useAlert } from 'react-alert';
import CustomizedTooltips from '../../../components/CustomizedTooltips';
import { icons } from '../../../utils/icons';
import CommonDialog from '../../../components/CommonDialog';
import { deleteProgramType } from '../../../redux/actions/clientActions/deleteProgramType';

const programTypeDataStore = 'ProgramTypeData';

// ======== Table Head =========
const headCells = [
  { id: 'title', label: 'TITLE', isSort: true },
  { id: 'clientNumbers', label: '# CLIENTS ON THIS', isSort: true },
  { id: 'status', label: 'STATUS', isSort: true },
  { id: '' },
];

const ProgramTypes = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const existingProgramTypeData =
    getLocaleStorageVal(programTypeDataStore) || {};
  const [newDialog, setNewDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProgramType, setSelectedProgramType] = useState({});
  const [deleteProgramTypeDialog, setDeleteProgramTypeDialog] = useState(false);
  // const [programListData, setProgramListData] = useState([]);
  const programListData = props.programListData || [];
  const [order, setOrder] = useState(
    existingProgramTypeData?.sortBy
      ? existingProgramTypeData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(
    existingProgramTypeData?.sortBy?.key || ''
  );
  const theme = useTheme();
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    props.fetchProgramList((err) => onError(err));
  }, [
    props.addProgramData,
    props.updateProgramData,
    props.deleteProgramTypeData,
  ]);

  // useEffect(() => {
  //   const data = props.programListData ? props.programListData : [];
  //   setProgramListData(data);
  // }, [props.programListData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setLocaleStorageVal(programTypeDataStore, {
      ...existingProgramTypeData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  const filteredPrograms = applySortFilter(
    programListData,
    getComparator(order, orderBy)
  );

  const onDeleteData = () => {
    props.deleteProgramType(
      selectedProgramType._id,
      () => setDeleteProgramTypeDialog(false),
      (err) => onError(err)
    );
  };

  return (
    <div className={classes.container}>
      <TableToolbox
        isBack
        isLabel
        isPrimaryButton
        labelText={Strings.PROGRAM_TYPE + 's'}
        onClickBack={() => navigate(-1)}
        onNewClick={() => setNewDialog(true)}
      />
      <div className={classes.list_container}>
        {props.programListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {programListData.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {programListData &&
                      filteredPrograms?.map((data, index) => {
                        const { clientNumbers, status, title } = data;
                        return (
                          <TableRow key={data._id} sx={{ width: '100%' }}>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {title}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {clientNumbers}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <label className={classes.common_cell}>
                                  {status}
                                </label>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{ ...tableCell(theme) }}
                              component='td'
                              scope='row'
                            >
                              <Stack
                                direction='row'
                                justifyContent='end'
                                alignItems='flex-start'
                              >
                                <CustomizedTooltips
                                  title={Strings.EDIT_PROGRAM_TYPE}
                                >
                                  <img
                                    className={classes.action_icons}
                                    src={icons(theme).editIcon}
                                    style={{ marginRight: 15 }}
                                    alt=''
                                    onClick={(e) => {
                                      setIsEdit(true);
                                      setSelectedProgramType(data);
                                      setNewDialog(true);
                                    }}
                                  />
                                </CustomizedTooltips>
                                <CustomizedTooltips
                                  title={Strings.DELETE_PROGRAM_TYPE}
                                >
                                  <img
                                    className={classes.action_icons}
                                    src={icons(theme).deleteIcon}
                                    alt=''
                                    onClick={(e) => {
                                      setSelectedProgramType(data);
                                      setDeleteProgramTypeDialog(true);
                                    }}
                                  />
                                </CustomizedTooltips>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>
      {newDialog && (
        <NewProgramType
          data={selectedProgramType}
          onNewClose={() => {
            setIsEdit(false);
            setNewDialog(false);
          }}
          isEdit={isEdit}
        />
      )}
      {deleteProgramTypeDialog && (
        <CommonDialog
          title={Strings.PROGRAM_TYPE}
          message={Strings.DELETE_PROGRAM}
          onSuccess={() => onDeleteData()}
          onClose={() => {
            setDeleteProgramTypeDialog(false);
          }}
          loading={props.deleteProgramTypeLoading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  addProgramData: state.addProgramData.data,
  programListData: state.programListData.data,
  programListLoading: state.programListData.loading,
  updateProgramData: state.updateProgramTypeData.data,
  deleteProgramTypeLoading: state.deleteProgramTypeData.loading,
  deleteProgramTypeData: state.deleteProgramTypeData.data,
});

export default connect(mapStateToProps, {
  fetchProgramList,
  deleteProgramType,
})(ProgramTypes);
