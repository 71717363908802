import { useEffect, useMemo } from 'react';
import { useThemeState } from '../context/ThemeContext';
import { createTheme } from '@mui/material/styles';
import defaultTheme from '../themes/default';
import darkTheme from '../themes/dark';

export default function useWindowTheme() {
  var themeState = useThemeState();
  const mode = themeState.mode;

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      mode === 'light' ? 'light' : 'dark'
    );
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light' ? defaultTheme : darkTheme),
        },
      }),
    [mode]
  );

  return theme;
}
