import React, { useState, useEffect, useCallback } from 'react';
import TableToolbox from '../../components/TableToolbox';
import NewUser from './NewVendor';
import useStyles from './styles';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EnhancedTableHead from '../../components/DataTables/TableHead';
import { connect } from 'react-redux';
import { fetchVendorList } from '../../redux/actions/adminActions/vendorList';
import { deleteVendor } from '../../redux/actions/adminActions/deleteVendor';
import {
  applySortFilter,
  getComparator,
  getErrorMessage,
  secondaryTableCell,
  tableCell,
  vendorTempObj,
  PAGE_SIZE,
  getLocaleStorageVal,
  setLocaleStorageVal,
  phoneFormatUS,
} from '../../utils/appUtils';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from '@material-ui/core';
import { Strings } from '../../utils/strings';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import { icons } from '../../utils/icons';
import CommonDialog from '../../components/CommonDialog';
import debounce from 'lodash/debounce';
import CustomPagination from '../../components/CustomPagination';

const vendorDataStore = 'VendorData';

// ======== Table Head =========
const headCells = [
  { id: 'name', label: 'NAME', isSort: true },
  { id: 'email', label: 'EMAIL', isSort: true },
  { id: 'phoneNo', label: 'PHONE' },
  { id: 'category', label: 'CATEGORY', isSort: true },
  { id: '' },
];

const Vendor = (props) => {
  const classes = useStyles();
  const existingVendorData = getLocaleStorageVal(vendorDataStore);
  const [newDialog, setNewDialog] = useState(false);
  const alert = useAlert();
  // const [vendorListData, setVendorListData] = useState([]);
  const vendorListData = props.vendorListData?.vendors || [];
  const [search, setSearch] = useState(existingVendorData?.searchString || '');
  const [isEdit, setIsEdit] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [deleteVendorDialog, setDeleteVendorDialog] = useState(false);
  const [preferredVendorProgram, setPreferredVendorProgram] = useState(false);
  const [openRows, setOpenRows] = useState([]);
  const [shouldStateInclude, setShouldStateInclude] = useState(true);

  const [order, setOrder] = useState(
    existingVendorData?.sortBy
      ? existingVendorData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'asc'
  );
  const [orderBy, setOrderBy] = useState(existingVendorData?.sortBy?.key || '');
  const [pageCount, setPageCount] = useState(
    existingVendorData?.pageFilter?.pageNumber || 1
  );
  const [pageSize, setPageSize] = useState(
    existingVendorData?.pageFilter?.pageSize || PAGE_SIZE
  );

  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getVendorList = (data, preferredProgram) => {
    const existingData = getLocaleStorageVal(vendorDataStore);
    let newData = structuredClone(data) || {};
    const pageFilter = existingData?.pageFilter;
    const sortBy = existingData?.sortBy;
    newData.preferredVendorProgram = preferredProgram || false;
    if (!newData?.page_number)
      newData.page_number = pageFilter?.pageNumber || pageCount;
    if (!newData?.page_size)
      newData.page_size = pageFilter?.pageSize || pageSize;
    if (!newData.sortBy && sortBy)
      newData.sortBy = { [sortBy?.key]: sortBy?.value };
    if (shouldStateInclude && state?.id) newData.vendorId = state?.id;
    props.fetchVendorList(newData, onError);
  };

  const delayedSearch = useCallback(
    debounce((q) => {
      let data = { search: q, page_number: 1 };
      getVendorList(data, preferredVendorProgram);
    }, 600),
    [pageSize, preferredVendorProgram, state, shouldStateInclude]
  );

  useEffect(() => {
    getVendorList({ search }, preferredVendorProgram);
  }, [
    state,
    props.addVendorData,
    props.updateVendorData,
    props.deleteVendorData,
    // search,
  ]);

  // useEffect(() => {
  //   const data = props.vendorListData ? props.vendorListData : [];
  //   setVendorListData(data);
  // }, [props.vendorListData]);

  const onDeleteData = async () => {
    setShouldStateInclude(false);
    await props.deleteVendor(
      selectedVendor._id,
      () => setDeleteVendorDialog(false),
      onError
    );
  };

  // const descendingComparator = (a, b, orderBy) => {
  //   if (a[orderBy] === undefined) return -1;
  //   if (b[orderBy] === undefined) return 1;
  //   let asceding;
  //   let descding;
  //   if (orderBy === 'category') {
  //     asceding =
  //       b[orderBy]?.categoryName?.toLowerCase() <
  //       a[orderBy]?.categoryName?.toLowerCase();
  //     descding =
  //       b[orderBy]?.categoryName?.toLowerCase() >
  //       a[orderBy]?.categoryName?.toLowerCase();
  //   } else {
  //     asceding = b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase();
  //     descding = b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase();
  //   }
  //   if (asceding) return -1;
  //   if (descding) return 1;
  //   return 0;
  // };

  const handleRequestSort = (event, property) => {
    setShouldStateInclude(false);
    setOpenRows([]);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortBy = { [property]: isAsc ? -1 : 1 };
    getVendorList({ search, sortBy }, preferredVendorProgram);
    setLocaleStorageVal(vendorDataStore, {
      ...existingVendorData,
      sortBy: { key: property, value: isAsc ? -1 : 1 },
    });
  };

  // const filteredVendors = applySortFilter(
  //   vendorListData,
  //   getComparator(order, orderBy, descendingComparator)
  // );

  const onOpenRow = (rowIndex) => {
    if (openRows.findIndex((item, index) => item === rowIndex) === -1) {
      setOpenRows([...openRows, rowIndex]);
    } else {
      setOpenRows(openRows.filter((item) => item !== rowIndex));
    }
  };

  const redirectToItem = async () => {
    if (vendorListData?.length > 0 && state?.id) {
      const fIndexOfJob = vendorListData?.findIndex(
        (el) => el?._id === state.id
      );
      const fRowIndex = openRows?.findIndex((el) => el === fIndexOfJob);
      if (fRowIndex === -1) {
        await onOpenRow(fIndexOfJob);
      }
      let elem = document.getElementById(state.id);
      let wrapper = document.getElementById('mainContainer');
      if (elem) {
        // elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
        let count = elem.offsetTop - wrapper.scrollTop;
        wrapper.scrollBy({ top: count, left: 0, behavior: 'smooth' });
        setShouldStateInclude(false);
      }
    }
  };

  useEffect(() => {
    redirectToItem();
  }, [state, vendorListData]);

  // ===== call when page change =====
  const handlePageChange = (e, page) => {
    setShouldStateInclude(false);
    if (pageCount !== page) {
      getVendorList({ search, page_number: page }, preferredVendorProgram);
    }
    setLocaleStorageVal(vendorDataStore, {
      ...existingVendorData,
      pageFilter: { pageNumber: page, pageSize },
    });
    setPageCount(page);
    setOpenRows([]);
  };

  // ===== call when page size change =====
  const handlePageSize = (val) => {
    setShouldStateInclude(false);
    setPageSize(val);
    setPageCount(1);
    getVendorList({ search, page_size: val }, preferredVendorProgram);
    setLocaleStorageVal(vendorDataStore, {
      ...existingVendorData,
      pageFilter: { pageNumber: 1, pageSize: val },
    });
  };

  return (
    <Stack className={classes.vendor_container} id='mainContainer'>
      <TableToolbox
        isSearch
        // isFilter
        isPrimaryButton
        search={search}
        onChangeSearch={(e) => {
          setShouldStateInclude(false);
          delayedSearch(e.target.value);
          setSearch(e.target.value);
          setPageCount(1);
          setLocaleStorageVal(vendorDataStore, {
            ...existingVendorData,
            searchString: e.target.value,
          });
        }}
        onNewClick={() => setNewDialog(true)}
        isSecondaryButton
        secondaryButtonText={Strings.CATEGORY_TYPES}
        secondaryButtonClick={() =>
          navigate('/app/vendor/categories', {
            state: {
              type: 'vendor',
            },
          })
        }
        isToggle={true}
        toggleLabel={Strings.PREFERRED_VENDOR_PROGRAM}
        toggleValue={preferredVendorProgram}
        onChangeToggle={() => {
          setPreferredVendorProgram(!preferredVendorProgram);
          getVendorList({ search, page_number: 1 }, !preferredVendorProgram);
          setPageCount(1);
          setOpenRows([]);
          setLocaleStorageVal(vendorDataStore, {
            ...existingVendorData,
            pageFilter: { pageNumber: 1, pageSize: pageSize },
          });
          // getVendorList(search, 1, preferredVendorProgram);
        }}
      />
      <div className={classes.list_container}>
        {props.vendorListLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <>
            {vendorListData.length === 0 ? (
              <div className={classes.flexView}>
                <p className={classes.no_data_message}>
                  {Strings.NO_DATA_FOUND}
                </p>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                  <TableBody>
                    {vendorListData &&
                      vendorListData?.map((data, index) => {
                        const {
                          fullName,
                          email,
                          phoneNo,
                          category,
                          address,
                          preferredTechnician,
                          techPhone,
                          websiteLink,
                          notes,
                        } = data;
                        let detailArr = [];
                        if (
                          preferredTechnician !== '' &&
                          preferredTechnician !== undefined
                        ) {
                          detailArr.push({ preTech: preferredTechnician });
                        }
                        if (techPhone !== '' && techPhone !== undefined) {
                          detailArr.push({ techPhone: techPhone });
                        }
                        if (address !== '' && address !== undefined) {
                          detailArr.push({ address: address });
                        }
                        if (websiteLink)
                          detailArr.push({ websiteLink: websiteLink });
                        if (notes) detailArr.push({ notes: notes });
                        return (
                          <React.Fragment key={data._id + index}>
                            <TableRow
                              id={data._id}
                              sx={{ width: '100%' }}
                              onClick={() => onOpenRow(index)}
                            >
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack direction='row' alignItems='flex-start'>
                                  <img
                                    className={classes.toggle_arrow}
                                    src={
                                      openRows.includes(index)
                                        ? icons(theme).downArrow
                                        : icons(theme).rightArrow
                                    }
                                    alt=''
                                  />
                                  <label className={classes.name_cell}>
                                    {fullName}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {email || '-'}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {phoneNo ? phoneFormatUS(phoneNo) : '-'}
                                    {/* {phoneNo} */}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='column'
                                  alignItems='flex-start'
                                >
                                  <label className={classes.common_cell}>
                                    {category?.categoryName}
                                  </label>
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{
                                  ...tableCell(theme, openRows.includes(index)),
                                }}
                                component='td'
                                scope='row'
                              >
                                <Stack
                                  direction='row'
                                  justifyContent='end'
                                  alignItems='flex-start'
                                >
                                  <CustomizedTooltips
                                    title={Strings.EDIT_VENDOR}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).editIcon}
                                      style={{ marginRight: 15 }}
                                      alt=''
                                      onClick={(e) => {
                                        setIsEdit(true);
                                        setSelectedVendor(data);
                                        setNewDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                  <CustomizedTooltips
                                    title={Strings.DELETE_VENDOR_TT}
                                  >
                                    <img
                                      className={classes.action_icons}
                                      src={icons(theme).deleteIcon}
                                      alt=''
                                      onClick={(e) => {
                                        setSelectedVendor(data);
                                        setDeleteVendorDialog(true);
                                      }}
                                    />
                                  </CustomizedTooltips>
                                </Stack>
                              </TableCell>
                            </TableRow>
                            {openRows.findIndex((item) => item === index) !==
                              -1 &&
                              detailArr.map((item, ind) => {
                                return (
                                  Object.values(item)[0] && (
                                    <TableRow
                                      key={data._id + ind}
                                      sx={{ width: '100%' }}
                                    >
                                      <TableCell
                                        style={{
                                          ...secondaryTableCell(
                                            theme,
                                            detailArr.length === ind + 1
                                          ),
                                          cursor: 'pointer',
                                        }}
                                        component='td'
                                        scope='row'
                                      >
                                        <Stack
                                          direction='row'
                                          alignItems='center'
                                        >
                                          <label
                                            style={{
                                              marginLeft: 39,
                                              cursor: 'pointer',
                                            }}
                                            className={classes.name_cell}
                                          >
                                            {
                                              vendorTempObj[
                                                Object.keys(item)[0]
                                              ]
                                            }
                                          </label>
                                        </Stack>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          ...secondaryTableCell(
                                            theme,
                                            detailArr.length === ind + 1
                                          ),
                                          cursor: 'pointer',
                                        }}
                                        component='td'
                                        scope='row'
                                        colSpan={5}
                                      >
                                        <Stack
                                          direction='row'
                                          alignItems='flex-start'
                                        >
                                          {vendorTempObj[
                                            Object.keys(item)[0]
                                          ] === 'Website Link' ? (
                                            <a
                                              target='_blank'
                                              href={Object.values(item)[0]}
                                              style={{
                                                fontSize: 14,
                                                color: '#0088CC',
                                              }}
                                            >
                                              {Object.values(item)[0]}
                                            </a>
                                          ) : (
                                            <label
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              className={classes.common_cell}
                                            >
                                              {Object.values(item)[0]}
                                            </label>
                                          )}
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  )
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>
      {props?.vendorListData?.totalRecords ? (
        <Stack flex={1} pb={2} alignItems='center'>
          <CustomPagination
            pageCount={pageCount}
            pageSize={pageSize}
            totalRecords={props?.vendorListData?.totalRecords || 0}
            handleChange={handlePageChange}
            handlePageSize={handlePageSize}
          />
        </Stack>
      ) : null}

      {newDialog && (
        <NewUser
          onNewClose={() => {
            setIsEdit(false);
            setSelectedVendor({});
            setNewDialog(false);
          }}
          isEdit={isEdit}
          data={selectedVendor}
        />
      )}
      {deleteVendorDialog && (
        <CommonDialog
          title={Strings.VENDOR}
          message={Strings.DELETE_VENDOR}
          onSuccess={() => onDeleteData()}
          onClose={() => {
            setSelectedVendor({});
            setDeleteVendorDialog(false);
          }}
          loading={props.deleteVendorLoading}
        />
      )}
    </Stack>
  );
};
const mapStateToProps = (state) => ({
  addVendorData: state.addVendorData.data,
  vendorListData: state.vendorListData.data,
  vendorListLoading: state.vendorListData.loading,
  updateVendorData: state.updateVendorData.data,
  deleteVendorData: state.deleteVendorData.data,
  deleteVendorLoading: state.deleteVendorData.loading,
});

export default connect(mapStateToProps, {
  fetchVendorList,
  deleteVendor,
})(Vendor);
