import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import { Form, FormikProvider, useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import DateTimeContainer from '../../../components/DateTimeContainer';
import DateSelector from '../../../components/DateSelector';
import TimeSelector from '../../../components/TimeSelector';
import Button from '../../../components/Button';
import {
  AddArrivalDepartureSchema,
  AddArrivalDepartureForAdminSchema,
  AddAdminsDashboardArrivalDepartureSchema,
} from '../../../utils/validationSchemas';
import { connect } from 'react-redux';
import { fetchClientAllProperty } from '../../../redux/actions/clientActions/getClientAllProperty';
import { getErrorMessage, isClient } from '../../../utils/appUtils';
import { useAlert } from 'react-alert';
import { CircularProgress } from '@mui/material';
import { createJuncture } from '../../../redux/actions/clientActions/addJuncture';
import { updateJuncture } from '../../../redux/actions/clientActions/updateJuncture';
import moment from 'moment';
import classNames from 'classnames';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import { fetchClientById } from '../../../redux/actions/clientActions/getClientById';
import { getFixedTimestamp } from '../../../utils/timeZone';
import Textbox from '../../../components/Textbox';
import CommonDialog from '../../../components/CommonDialog';

const NewArrivalDeparture = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [propertyData, setPropertyData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [junctureId, setJunctureId] = useState('');
  const [minArrivalDate, setMinArrivalDate] = useState();
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const email = localStorage.getItem('clientAdminEmail');

  const onError = (err) => {
    const error = getErrorMessage(err, true)?.message;
    const askForPermission = getErrorMessage(err, true)?.askForPermission;
    if (error && !askForPermission) {
      alert.error(error);
    }
    if (askForPermission) setOpenPermissionDialog(true);
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  const selectArrivalValue = async (value, arrivalTime) => {
    const currentTime = await new Date();
    const after15 = new Date(arrivalTime);
    after15.setMinutes(15);
    const after1Day = new Date(value);
    after1Day.setDate(new Date(value).getDate() + 1);
    await setFieldValue('arrivalDate', value);
    if (!value || value === 'Invalid Date') {
      setFieldValue(
        'departureDate',
        props?.arrivalDepartureData?.departure || new Date()
      );
    } else if (value) {
      if (values.departureDate) await setFieldValue('departureDate', after1Day);
      // await setFieldValue('arrivalTime', moment(currentTime, 'HH:mm'));
      // await setFieldValue('departureTime', moment(after15, 'HH:mm'));
    }
  };

  useEffect(() => {
    if (!isClient() && props.isAdminsDashboardOnly) {
      props.fetchUserListByType('client', (err) => onError(err));
    }
    if (isClient()) {
      props.fetchClientAllProperty((err) => onError(err));
    }
  }, []);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title: item.lastName + ', ' + item.firstName,
          value: item._id,
        };
        // if (!props?.allTechnician && data?.type === 'array') tempData.image = item?.profilePicture;
        return tempData;
      }) || [];
    // if (!props?.allTechnician && data?.type === 'array') setTeamMemberList(newData);
    // if (data?.type === 'admin' && props.isEdit) {
    //   setAdminList(newData);
    //   setTempAdminList(newData);
    // }

    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  useEffect(() => {
    const data = props.clientAllPropertyData ? props.clientAllPropertyData : [];
    const newData = data.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setPropertyData(newData);
  }, [props.clientAllPropertyData]);

  const formik = useFormik({
    initialValues: {
      property:
        isClient() || props.isAdminsDashboardOnly ? '0' : props.propertyId,
      arrivalDate: null,
      arrivalTime: getFixedTimestamp(),
      departureDate: null,
      departureTime: getFixedTimestamp(),
      client: '0',
      arrivalDateNote: '',
      departureDateNote: '',
    },
    validationSchema: props.isAdminsDashboardOnly
      ? AddAdminsDashboardArrivalDepartureSchema
      : isClient()
      ? AddArrivalDepartureSchema
      : AddArrivalDepartureForAdminSchema,
    onSubmit: (values) => {
      handleJuncture(values);
    },
  });

  const handleJuncture = (values, allowOverlapJob) => {
    const data = {
      property: values.property,
      arrivalNotes: values.arrivalDateNote,
      departureNotes: values.departureDateNote,
    };
    if (values.arrivalDate && values.arrivalTime) {
      data.arrival = moment(
        moment(values.arrivalDate).format('YYYY-MM-DD') +
          ' ' +
          moment(values.arrivalTime).format('HH:mm')
      ).format();
    }
    if (values.departureDate && values.departureTime) {
      data.departure = moment(
        moment(values.departureDate).format('YYYY-MM-DD') +
          ' ' +
          moment(values.departureTime).format('HH:mm')
      ).format();
    }
    if (allowOverlapJob) data.allowOverlapJob = allowOverlapJob;
    if (props.isEdit && junctureId) {
      let newData = {
        arrival: data.arrival,
        departure: data.departure,
        arrivalNotes: values.arrivalDateNote,
        departureNotes: values.departureDateNote,
      };
      props.updateJuncture(
        junctureId,
        newData,
        (err) => onError(err),
        () => {
          if (isClient())
            onSuccess(
              'Your Arrival and Departure have been successfully submitted'
            );
          props.onNewClose();
        }
      );
    } else {
      props.createJuncture(
        data,
        () => {
          if (isClient())
            onSuccess(
              'Your Arrival and Departure have been successfully submitted'
            );
          props.onNewClose();
        },
        (err) => onError(err)
      );
    }
  };

  useEffect(() => {
    if (props?.isEdit && props?.arrivalDepartureData) {
      const {
        _id,
        arrival,
        departure,
        property,
        client,
        arrivalNotes,
        departureNotes,
      } = props?.arrivalDepartureData;

      if (props.isAdminsDashboardOnly) {
        setFieldValue('client', client?._id);
      }
      setFieldValue('property', property?._id || property);
      if (arrival) {
        setFieldValue('arrivalDate', arrival);
        // setFieldValue('arrivalTime', arrival);
      }
      if (departure) {
        setFieldValue('departureDate', departure);
        // setFieldValue('departureTime', departure);
      }
      setFieldValue('arrivalDateNote', arrivalNotes || '');
      setFieldValue('departureDateNote', departureNotes || '');

      setJunctureId(_id);
      arrival
        ? new Date(arrival) > new Date()
          ? setMinArrivalDate(new Date())
          : setMinArrivalDate(new Date(arrival))
        : setMinArrivalDate();
    } else setJunctureId('');
  }, [props?.arrivalDepartureData]);

  const { errors, touched, values, handleChange, setFieldValue, handleSubmit } =
    formik;

  useEffect(() => {
    if (values.client && values.client !== '0')
      props.fetchClientById(values.client, (err) => onError(err));
  }, [values.client]);

  useEffect(() => {
    if (values.client !== '0') {
      const data = props.clientData ? props.clientData : {};
      const newData = data?.properties?.map((item) => ({
        title: item.name,
        value: item._id,
      }));
      setPropertyData(newData || []);
      if (newData && newData.length === 1 && values.client) {
        setFieldValue(
          'property',
          (values.property !== '0' && values.property) || newData[0].value
        );
      } else {
        setFieldValue(
          'property',
          (values.property !== '0' && values.property) || '0'
        );
      }
    }
  }, [props.clientData]);

  const adminEmailHandle = () => {
    setOpenPermissionDialog(false);
    props?.onNewClose?.();
    window.location.href = `mailto:${email}`;
  };

  const TextWithLink = () => {
    return (
      <div>
        <p className={classes.dialogText}>
          Your scheduled arrival is within the next 48 Hours. Please contact
          your Administrative Concierge directly, or if after normal business
          hours, please contact us at,&nbsp;
          <a href='tel:+12397776173' className={classes.phoneLinkStyle}>
            (239) 777-6173
          </a>
          . Thank You!
        </p>
      </div>
    );
  };

  if (openPermissionDialog) {
    return !isClient() ? (
      <CommonDialog
        title={Strings.PERMISSION}
        message={Strings.ASK_FOR_JUNCTURE_PERMISSION}
        onSuccess={() => handleJuncture(values, true)}
        onClose={() => {
          setOpenPermissionDialog(false);
          props?.onNewClose?.();
        }}
        loading={
          props.isEdit ? props.updateJunctureLoading : props.addJunctureLoading
        }
        positiveBtnText={Strings.YES}
      />
    ) : (
      <CommonDialog
        title={Strings.ATTENSION}
        onSuccess={() => adminEmailHandle()}
        onClose={() => {
          setOpenPermissionDialog(false);
          props?.onNewClose?.();
        }}
        loading={
          props.isEdit ? props.updateJunctureLoading : props.addJunctureLoading
        }
        positiveBtnText={Strings.EMAIL_MY_ADMIN}
      >
        <TextWithLink />
      </CommonDialog>
    );
  }

  return (
    <>
      <div className={classes.dialog_mask}>
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={
              props?.isEdit
                ? Strings.EDIT_ARRIVALS_DEPARTURE
                : Strings.NEW_ARRIVALS_DEPARTURE
            }
            onNewClose={props.onNewClose}
            containerClassName={classes.arrivalDepartureStyle}
          />
          {props.clientAllPropertyLoading || props.UserListByTypeLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{
                  color: theme.palette.background_button,
                }}
              />
            </div>
          ) : (
            <FormikProvider value={formik}>
              <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <div className={classes.form_container}>
                  {!isClient() && props.isAdminsDashboardOnly && (
                    <Dropdown
                      name='client'
                      onChange={(e) => {
                        setFieldValue('client', e.target.value);
                        setFieldValue('property', '0');
                      }}
                      value={values.client}
                      label={Strings.CLIENT}
                      error={Boolean(touched.client && errors.client)}
                      helperText={touched.client && errors.client}
                      title={Strings.SELECT_AN_OPTION}
                      labelClassName={classes.form_label}
                      inputContainerClass={
                        props?.isEdit && classes.disableInput
                      }
                      optionArray={clientList}
                      disabled={props?.isEdit ? true : false}
                    />
                  )}
                  {(isClient() || props.isAdminsDashboardOnly) && (
                    <Dropdown
                      name='property'
                      onChange={handleChange('property')}
                      value={values.property}
                      label={Strings.PROPERTY}
                      error={Boolean(touched.property && errors.property)}
                      helperText={touched.property && errors.property}
                      title={Strings.SELECT_AN_OPTION}
                      labelClassName={classes.form_label}
                      inputContainerClass={
                        props?.isEdit && classes.disableInput
                      }
                      containerClassName={classes.form_input_container}
                      optionArray={propertyData}
                      disabled={
                        (props.isAdminsDashboardOnly &&
                          values.client === '0') ||
                        props.clientLoading ||
                        props?.isEdit
                      }
                    />
                  )}
                  {/* <div style={{ paddingTop: 40 }}>
                    <label className={classes.note_text}>
                      {props?.isEdit
                        ? Strings.EDIT_NOTE_TEXT
                        : Strings.ADD_NOTE_TEXT}
                    </label>
                  </div> */}
                  <DateTimeContainer
                    label={Strings.ARRIVAL_DATE_TIME}
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='arrivalDate'
                      onChange={(value) =>
                        selectArrivalValue(value, values.arrivalTime)
                      }
                      value={values.arrivalDate}
                      error={Boolean(touched.arrivalDate && errors.arrivalDate)}
                      helperText={touched.arrivalDate && errors.arrivalDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      containerClassName={classes.date_selector_container}
                      // minDate={minArrivalDate}
                    />
                    {/* <TimeSelector
                      name='arrivalTime'
                      onChange={(value) => {
                        setFieldValue(
                          'arrivalTime',
                          value ? moment(value, 'HH:mm') : ''
                        );
                      }}
                      value={values.arrivalTime}
                      error={Boolean(touched.arrivalTime && errors.arrivalTime)}
                      helperText={touched.arrivalTime && errors.arrivalTime}
                      placeholder={Strings.SAMPLE_TIME}
                    /> */}
                  </DateTimeContainer>
                  <Textbox
                    name='arrivalDateNote'
                    onChange={handleChange('arrivalDateNote')}
                    value={values.arrivalDateNote}
                    // label={Strings.TITLE}
                    label={'Arrival Time & Notes'}
                    error={Boolean(
                      touched.arrivalDateNote && errors.arrivalDateNote
                    )}
                    helperText={
                      touched.arrivalDateNote && errors.arrivalDateNote
                    }
                    placeholder={'Enter arrival notes'}
                    inputContainerClass={props?.isEdit && classes.disableInput}
                    containerClassName={classes.form_input_container}
                    className={classes.form_input}
                    labelClassName={classes.form_label}
                    multiline
                    rows={2}
                  />
                  <DateTimeContainer
                    label={Strings.DEPARTURE_DATE_TIME}
                    containerClassName={classes.form_input_container}
                  >
                    <DateSelector
                      name='departureDate'
                      onChange={(value) =>
                        setFieldValue('departureDate', value)
                      }
                      value={values.departureDate}
                      error={Boolean(
                        touched.departureDate && errors.departureDate
                      )}
                      helperText={touched.departureDate && errors.departureDate}
                      format='MMM dd, yyyy'
                      placeholder={Strings.SAMPLE_DATE}
                      containerClassName={classes.date_selector_container}
                      minDate={
                        values.arrivalDate &&
                        new Date(values?.arrivalDate).setDate(
                          new Date(values?.arrivalDate).getDate()
                        )
                      }
                    />
                    {/* <TimeSelector
                      name='departureTime'
                      onChange={(value) =>
                        setFieldValue(
                          'departureTime',
                          value ? moment(value, 'HH:mm') : ''
                        )
                      }
                      value={values.departureTime}
                      error={Boolean(
                        touched.departureTime && errors.departureTime
                      )}
                      helperText={touched.departureTime && errors.departureTime}
                      placeholder={Strings.SAMPLE_TIME}
                    /> */}
                  </DateTimeContainer>
                  <Textbox
                    name='departureDateNote'
                    onChange={handleChange('departureDateNote')}
                    value={values.departureDateNote}
                    // label={Strings.TITLE}
                    label={'Departure Time & Notes'}
                    error={Boolean(
                      touched.departureDateNote && errors.departureDateNote
                    )}
                    helperText={
                      touched.departureDateNote && errors.departureDateNote
                    }
                    placeholder={'Enter departure notes'}
                    inputContainerClass={props?.isEdit && classes.disableInput}
                    containerClassName={classes.form_input_container}
                    className={classes.form_input}
                    labelClassName={classes.form_label}
                    multiline
                    rows={2}
                  />
                  <Button
                    type='submit'
                    text={props?.isEdit ? Strings.SAVE : Strings.ADD}
                    loading={
                      props?.isEdit
                        ? props.updateJunctureLoading
                        : props.addJunctureLoading
                    }
                    disabled={
                      props?.isEdit
                        ? props.updateJunctureLoading
                        : props.addJunctureLoading
                    }
                    containerClassName={classes.add_button_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>
              </Form>
            </FormikProvider>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  clientAllPropertyData: state.clientAllPropertyData.data,
  clientAllPropertyLoading: state.clientAllPropertyData.loading,

  addJunctureLoading: state.addJunctureData.loading,
  addJunctureData: state.addJunctureData.data,

  updateJunctureLoading: state.updateJunctureData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  clientData: state.clientData.data,
  clientLoading: state.clientData.loading,
});

export default connect(mapStateToProps, {
  fetchClientAllProperty,
  createJuncture,
  updateJuncture,
  fetchUserListByType,
  fetchClientById,
})(NewArrivalDeparture);
