import axios from 'axios';
import { JUNCTURES_LIST } from '../../constants';

export const fetchJuncturesList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJuncturesList());

  axios
    .get(`/client/listJunctures`, config)
    .then((res) => dispatch(setJuncturesList(res)))
    .catch((err) => {
      dispatch(errorJuncturesList(err.response));
      onError(err.response);
    });
};

export const loadJuncturesList = () => ({
  type: JUNCTURES_LIST.LOAD,
});

export const setJuncturesList = (userList) => ({
  type: JUNCTURES_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorJuncturesList = (error) => ({
  type: JUNCTURES_LIST.FAIL,
  payload: error,
});
