import classNames from 'classnames';
import React from 'react';
import useStyles from './styles';
import { icons } from '../../utils/icons';

export default function CustomRadioButton(props) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, props.containerStyle)}>
      <div
        className={classNames(
          classes.check_container,
          props.checked === props.value && classes.checkedContainer
        )}
        onClick={() => {
          if (!props.disabled) {
            props.onChange(props.value);
          }
        }}
      >
        {props.checked === props.value && (
          <img
            className={classes.checked_icon}
            src={icons().rightIcon}
            alt=''
          />
        )}
      </div>
      <label className={classes.check_label}>{props.label}</label>
    </div>
  );
}
