import axios from 'axios';
import { UPDATE_ISSUE_BY_PERFORM_ACTION } from '../../../constants';

export const updateIssueByPerformAction =
  (issueId, data, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateIssueByPerformAction());
    axios
      .put(`/jobs/performAction?issueId=${issueId}`, data, config)
      .then((res) => {
        dispatch(setUpdateIssueByPerformAction(res.data));
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateIssueByPerformAction(err.response));
        onError(err.response);
      });
  };

export const loadUpdateIssueByPerformAction = () => ({
  type: UPDATE_ISSUE_BY_PERFORM_ACTION.LOAD,
});

export const setUpdateIssueByPerformAction = (data) => ({
  type: UPDATE_ISSUE_BY_PERFORM_ACTION.SUCCESS,
  payload: data,
});

export const errorUpdateIssueByPerformAction = (err) => ({
  type: UPDATE_ISSUE_BY_PERFORM_ACTION.FAIL,
  payload: err,
});
