export const Strings = {
  // common string
  FILTER_NAME: 'Filter Name',
  FILTER_ATTRIBUTES: 'Filter Attributes',
  SAVE_FILTER: 'Save Filter',
  NEW_FILTER: 'New Filter',
  EDIT_FILTER: 'Edit Filter',
  REMOVE: 'Remove',
  SELECT_SAVED_FILTER: 'Select a saved filter',
  SELECT_DATE_RANGE: 'Select Date Range',
  TODAY: 'Today',
  ROWS_PER_PAGE_SIZE: 'Rows per page',
  YES: 'Yes',
  SEE_MORE: 'See More',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',

  //button
  OK: 'Ok',
  CANCEL: 'Cancel',

  //sidebar menu
  DASHBOARD: 'Dashboard',
  CLIENTS: 'Clients',
  TEAM: 'Team',
  CALENDAR: 'Calendar',
  VENDOR: 'Vendor',
  JOBS: 'Jobs',
  ISSUES: 'Tasks',
  NEW_TASKS: 'New Tasks',
  REPORTS: 'Reports',
  CHAT: 'Chat',
  MEDIA: 'Media',
  PROPERTY: 'Property',
  PROPERTY_DETAILS: 'Property Details',

  //tooltip text
  DELETE_MEMBER: 'Delete Member',
  DELETE_ANNOUNCEMENT: 'Delete Announcement',
  EDIT_MEMBER: 'Edit Member',
  DELETECLIENT: 'Delete Client',
  DELETEPROPERTY: 'Delete Property',
  DELETEJOB: 'Delete Job',
  REPORT: 'Report',
  DELETEISSUE: 'Delete Task',
  DELETE_CATEGORY_TYPE: 'Delete Category Type',
  EDIT_VENDOR: 'Edit Vendor',
  DELETE_VENDOR_TT: 'Delete Vendor',
  VISIBLE_TO_CLIENT: 'Visible to Client',
  HIDE_TO_CLIENT: 'Hide to Client',
  CURRENTLE_NOT_VISIBLE_TO_CLIENT:
    'Currently not visible to client, Click to make visible.',
  CURRENTLE_VISIBLE_TO_CLIENT:
    'Currently visible to client, Click to make invisible.',

  //login
  WELCOME_BACK: 'Welcome back',
  EMAIL_ADDRESS: 'Email Address',
  PASSWORD: 'Password',
  REMEMBER_PASSWORD: 'Remember Password',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  DONT_HAVE_ACCOUNT: `Don't have an account?`,
  ENTER_EMAIL: 'Enter email',
  ENTER_PASSWORD: 'Enter Password',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  SUBMIT: 'Submit',

  //navbar
  TYPE_TO_SEARCH: 'Type to search',
  LIGHT_MODE: 'Light Mode',
  DARK_MODE: 'Dark Mode',
  LOGOUT: 'Logout',
  SURE_LOGOUT: 'Are you sure you want to logout?',
  NO_NOTIFICATION: 'There is no notification',
  CLEAR: 'Clear',
  CLEAR_ALL: 'Clear All',

  //table-toolbox
  ADD_NEW: 'Add New',
  FILTERS: 'Filters',
  DOWNLOAD_PDF: 'Download PDF',
  DOWNLOAD_CSV: 'Download CSV',
  VIEW_ALL: 'View All',
  WE_NEED_INPUT: 'We need your input',
  PRINT: 'Print',
  MANAGE_TAGS: 'Manage Tags',

  //new-user
  NEW_USER: 'New User',
  EDIT_USER: 'Edit User',
  FULL_NAME: 'Full Name',
  ENTER_FULL_NAME: 'Enter full name',
  PHONE_NUMBER: 'Phone Number',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  SELECT_AN_OPTION: 'Select an option',
  ROLE: 'Role',
  ADD_NEW_USER: 'Add New User',
  UPDATE_USER: 'Update User',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  ENTER_FIRST_NAME: 'Enter first name',
  ENTER_LAST_NAME: 'Enter last name',

  //team
  NO_DATA_FOUND: 'No data found!',
  NO_ANNOUNCEMENT: 'No current Announcements',
  DELETE_TEAM_MEMBER: 'Are you sure you want to delete team member?',
  DELETE_ANNOUNCEMENT_QUE: 'Are you sure you want to delete announcement?',
  ANNOUNCEMENT: 'Announcements',

  //new-vendor
  NEW_VENDOR: 'New Vendor',
  ADD_NEW_VENDOR: 'Add New Vendor',
  ENTER_COMPANY_NAME: 'Enter company name',
  COMPANY: 'Company',
  CATEGORY: 'Category',
  ENTER_COMPANY_FULL_NAME: 'Enter company full name',
  ENTER_COMPANY_EMAIL: 'Enter company email',
  PHONE: 'Phone',
  TECH_PHONE: 'Tech Phone',
  ENTER_TECHNICIAN_PHONE: 'Enter technician phone',
  PREFERRED_TECHNICIAN: 'Preferred Technician',
  INTERNAL_NOTES: 'Internal Notes',
  TYPE_YOUR_NOTES: 'Type your notes here',
  TYPE_YOUR_COMMENTS: 'Type your comments here',
  ADD_NOTE: 'Add Comment',
  ADD_INTERNAL_NOTE: 'Add Internal Note',
  ADD_COMMENT: 'Add Comment',
  EDIT_NOTE: 'Edit Comment',
  DELETE_NOTE: 'Delete Comment',
  NOTES: 'Comments',
  DELETE_VENDOR: 'Are you sure you want to delete vendor?',
  UPDATE_VENDOR: 'Update Vendor',
  WEBSITE_URL: 'Website URL',
  ENTER_URL: 'Enter URL',
  PREFERRED_VENDOR_PROGRAM: 'Preferred Vendor Program',

  //dashboard
  ALL_ANNOUNCEMENTS: 'All Announcements',
  VIEW_ALL_ANNOUNCEMENTS: 'View all announcements',
  UPCOMING_ARRIVALS_DEPARTURE: 'Upcoming Arrivals and Departures',
  ARRIVAL: 'Arrival',
  DEPARTURES: 'Departures',
  DEPARTURE: 'Departure',
  YOUR_ARRIVALS_DEPARTURE: 'Your Arrivals and Departures',
  SEND_MANAGEMENT_FEEDBACK: 'Send Management Feedback',
  VIEW_LIST: 'View List',
  VIEW_DETAILS: 'View details',
  FEEDBACK_FROM_THE_CLIENT: 'Feedback from the client',
  SENT_BY: 'Sent By',
  SUPPLIES_NEEDED_FOR_DAY: 'Supplies Needed for the Day',
  TEAM_DISCUSSION_NEEDED: 'Team Discussion Needed',
  SEND: 'Send',
  SEND_FEEDBACK_NOTE1:
    // 'Please note: This feedback is sent only to management, not your dedicated Administrative Concierge. Please use the ',
    'Please note: This feedback is sent only to management, not your dedicated Administrative Concierge.',
  SEND_FEEDBACK_NOTE2:
    ' feature if you’d like to provide communication to your admin.',
  MESSAGE_TO_ADMIN: 'Message to Admin',
  DELETE_ARRIVAL_DEPARTURE: 'Delete Arrival & Departure',
  ARE_YOU_SURE_DELETE_ARRIVAL_DEPARTURE:
    'Are you sure you want to delete arrival & departure data ?',
  EMAIL_MY_ADMIN: 'Email my Admin',
  VIEW_LATEST_EBLAST: 'View Latest E-blast',
  YOUR_DEDICATED_ADMIN: 'Your Dedicated Admin',
  EMAIL: 'Email',
  CELL: 'Cell',
  OFFICE: 'Office',
  MANAGE_EBLAST: 'Manage E-blast',
  ENTER_BUTTON_TEXT: 'Enter Button Text',
  ONE_WEEK_FORECAST: 'One Week Forecast',
  REFRESH: 'Refresh',
  MY_NOTES: 'My Notes',
  VIEW_RADAR: 'View Radar',
  ARRIVAL_NOTES: 'Arrival Notes',
  DEPARTURE_NOTES: 'Departure Notes',
  CLEAR_NOTE: 'Clear Note',
  ARE_YOU_SURE_CLEAR_NOTE: 'Are you sure you want to clear the note?',
  ASK_FOR_JUNCTURE_PERMISSION:
    'A job is scheduled during this time, would you still like to proceed?',
  CONTACT_SPECTRUM: `For arrivals within the next 48 Hours, please contact your administrative concierge directly or if after-hours, please contact the main Spectrum line.
  `,
  ALL_CLIENT_FEEDBACKS: 'Client Feedbacks',
  ALL_CLIENT_FEEDBACK_NOTE:
    'Are you sure you want to clear the client feedbacks?',
  CLIENT_FEEDBACKS: 'Client Feedback',
  CLIENT_FEEDBACK_NOTE: 'Are you sure you want to clear the client feedback?',

  //new announcement
  NEW_ANNOUNCEMENT: 'New Announcement',
  TITLE: 'Title',
  MESSAGE: 'Message',
  VISIBLE_TO: 'Visible To',
  START_DATE_TIME: 'Start Date and Time',
  END_DATE_TIME: 'End Date and Time',
  ADD_NEW_ANNOUNCEMENT: 'Add New Announcement',
  ENTER_TITLE: 'Enter title',
  TYPE_HERE: 'Type here',

  //arrival departure
  NEW_ARRIVALS_DEPARTURE: 'New Arrivals and Departures',
  EDIT_ARRIVALS_DEPARTURE: 'Edit Arrivals and Departures',
  ARRIVAL_DATE_TIME: 'Arrival Date and Time',
  DEPARTURE_DATE_TIME: 'Departure Date and Time',
  SAMPLE_DATE: 'Select Date',
  SAMPLE_TIME: 'xx : xx',
  ADD: 'Add',
  ADD_NOTE_TEXT: `You can add both information, or just one. Leave it in blank if you don't want to inform arrival or departure.`,
  EDIT_NOTE_TEXT: `You can edit both information, or just one. Leave it in blank if you don't want to inform arrival or departure.`,
  ARRIVALS_AND_DEPARTURES: 'Arrivals and Departures',
  VIEW_MORE: 'View More',
  VIEW_LESS: 'View Less',

  //sidebar
  WELCOME: 'Welcome,',

  //client
  DELETE_CLIENT: 'Are you sure you want to delete client?',
  DELETE_PROPERTY: 'Are you sure you want to delete property?',
  ARCHIVE_CLIENT_NOTE: 'Are you sure you want to archive client',
  UNARCHIVE_CLIENT_NOTE: 'Are you sure you want to unarchive client',
  ARCHIVE_CLIEN: 'Archive Client',
  UNARCHIVE_CLIENT: 'Unarchive Client',
  SHOW_ARCHIVE_CLIENT_LIST: 'Show archive client list',

  //new-client
  NEW_CLIENT: 'New Client',
  EDIT_CLIENT: 'Edit Client',
  PRIMARY_ADDRESS: 'Primary Address',
  TYPE_ADDRESS: 'Type the address',
  ADMIN_ASSIGNED: 'Admin Assigned',
  PROGRAM_TYPE: 'Program Type',
  ADD_NEW_CLIENT: 'Add New Client',
  UPDATE_CLIENT: 'Update Client',
  TYPE_OR_SELECT: 'Type or Select an option',
  TYPE_YOUR_NOTE: 'Type your note',
  SEND_PASSWORD: 'Send Password',
  HERE_AWAY: 'Here vs Away',

  //client-filter
  CLIENTS_WITH_ISSUES: `Show only client's with tasks`,
  SHOW_ALL_ADMIN_CLIENTS: `Show all admin client's`,
  SHOW_ALL_CLIENTS: `Show All Clients`,

  //add-property
  RESIDENCE_NAME: 'Residence Name',
  ADD_PROPERTY: 'Add Property',
  SECURITY_INFO: 'Security Information',
  PROPERTY_INFO: 'Property Information',
  CABLE_INTERNET_INFO: 'Cable and Internet Information',
  CONTACT_INFO: 'Contact Information',
  VENDOR_INFO: 'Vendor Information',
  HOA_INFO: 'Community/HOA Information',
  ARRIVAL_DEPARTURE_PREFERENCES: 'Arrival & Departure Preferences',
  GENERAL_PROPERTY_NOTES: 'General Property Notes',
  ADD_IMAGE: 'Add Property Image',
  ADDRESS: 'Address',
  ADDED_ON: 'ADDED ON',
  ADDED_ON_DATE: 'Added On',
  CLIENT_PROPERTY: 'Client Property',
  VENDOR_NAME: 'Vendor Name',

  //view-property
  EDIT: 'Edit',
  NO_PROPERTY_FOUND: 'No property found!',

  //edit-property
  SAVE_CHANGES: 'Save Changes',

  //new program-type
  NEW_PROGRAM_TYPE: 'New Program Type',
  PROGRAM_NAME: 'Program Name',
  ENTER_PROGRAM_NAME: 'Enter program name',
  ADD_NEW_PROGRAM: 'Add New Program',
  DELETE_PROGRAM_TYPE: 'Delete Program Type',
  EDIT_PROGRAM_TYPE: 'Edit Program Type',
  UPDATE_PROGRAM: 'Update Program',
  DELETE_PROGRAM: 'Are you sure you want to delete program type?',

  // jobs button
  JOB_FORMS: 'Job Forms',
  NEW_JOB: 'New Job',
  EDIT_JOB: 'Edit Job',
  VIEW_REPORT: 'View Report',

  //job
  DELETE_JOB: 'Are you sure you want to delete job?',
  SEARCH_AND_SELECT_TAGS: 'Search and Select Tags',
  NEW_MEDIA: 'New Media',
  UPLOAD: 'Upload',
  REPLACE_MEDIA: 'Replace Media',

  // new-job
  JOB_TITLE: 'Job Title',
  JOB: 'Job',
  JOB_TYPE: 'Job Type',
  JOB_FORM: 'Job Form',
  JOB_STATUS: 'Job Status',
  CLIENT: 'Client',
  ADMIN: 'Admin',
  TEAM_MEMBER_ASSIGNED: 'Team Member Assigned',
  DETAILS: 'Details',
  RECURRING: 'Recurring',
  ADD_NEW_JOB: 'Add New Job',
  FREQUENCY: 'Frequency',
  REPEAT_EVERY: 'Repeat Every',
  WORK_DAYS: 'Work Days',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  FORM: 'Form',
  UPDATE_JOB: 'Update Job',
  SELECT_THE_JOB_TYPE: 'Select the job type',
  SELECT_THE_CLIENT: 'Select the client',
  CREATED_BY: 'Created By',
  UPDATED_BY: 'Updated By',
  ADD_ATTACHMENTS: 'Add Attachments',
  PERMISSION: 'Permission',
  ATTENSION: 'Attention',
  ASK_FOR_PERMISSION:
    'Client will be home during this time, would you still like to proceed?',
  BIO_LINK: 'Bio Link',
  DELETE_JOB_FORM: 'Delete Job Form',
  DELETE_JOB_FORM_NOTE: 'Are you sure want to delete job form?',
  SELECT_THE_ISSUE: 'Select the task',

  //job-forms
  CATEGORY_TYPES: 'Category Types',
  CATEGORY_NAME: 'Category Name',

  // new-job-forms
  NEW_FORM: 'New Form',
  NEW_JOB_FORM: 'New Job Form',
  EDIT_JOB_FORM: 'Edit Job Form',
  FORM_NAME: 'Form Name',
  FORM_STATUS: 'Form Status',
  ADD_NEW_FORM: 'Add New Form',
  UPDATE_FORM: 'Update Form',
  GROUP: 'GROUP',
  TEXT_INPUT: 'TEXT INPUT',
  REQUIRED: 'Required',
  DROPDOWN: 'DROPDOWN',
  RADIO: 'RADIO',
  CHECKLIST: 'CHECKLIST',
  UPLOAD_FILE: 'UPLOAD FILE',
  FOCUS_WEEK_PLACEHOLDER: 'Focus Week 2 - Gas, Electric, & AV',
  SELECTED_DROPDOWN: 'Test all Gas Appliances',
  NO_ISSUES_FOUND: 'No tasks found',
  NO_NEW_TASKS: 'No New Tasks',
  ISSUES_FOUND: 'Tasks found',
  NA: 'N/A',
  Add_OPTION: 'Add Option',
  FILE_SIZE: 'File Size (i.e 10 MB)',
  FILE_TYPE: 'File Type (i.e image,audio)',
  NUMBER_OF_FILE: 'Number of File',
  TITLE_EMPTY: 'Element title can not be empty',
  FILL_UPLOAD_FILE_ALL_FIELDS: `Please fill Upload File element's all fields`,
  OPTION_EMPTY: 'Element option can not be empty',
  GROUPS_ELEMENTS_TITLE_EMPTY: `Group's element's title can not be empty`,
  GROUPS_ELEMENTS_OPTION_EMPTY: `Group's element's options can not be empty`,
  FILL_GROUP_UPLOAD_FILE_ALL_FIELDS: `Please fill Group's Upload File element's all fields`,
  NO_ISSUE: 'No task',
  COPY_FORM: 'Copy Form',

  // new-category
  NEW_CATEGORY_TYPE: 'New Category Type',
  NEW_CATEGORY: 'New Category',
  EDIT_CATEGORY_TYPE: 'Edit Category Type',
  ADD_NEW_CATEGORY: 'Add New Category',
  UPDATE_CATEGORY: 'Update Category',
  ENTER_CATEGORY_NAME: 'Enter category name',
  DELETE_CATEGORY: 'Are you sure you want to delete category?',

  // job-details
  OUTDOOR_CLEANING: 'Outdoor Cleaning',
  ASSIGNED_TO: 'Assigned To',
  DESCRIPTION: 'Description',
  UPDATE: 'Update',
  CANCEL_RECURRENCE: 'Cancel recurrence',
  JOB_SUMMARY: 'Job Summary',
  REPORT_HISTORY: 'Report History',
  REPORT_SENT_TO: 'REPORT SENT TO',
  APPOINTMENTS: 'Appointments',

  // job-filter
  FILTER_BY: 'Filter By',
  DATE_AND_TIME: 'Date and time',
  DATE: 'Date',
  TIME: 'Time',
  JOB_NOTES: 'Job Notes',
  FILTER_NOW: 'Filter Now',
  CLEAR_FILTERS: 'Clear Filters',
  TYPE_A_DATE: 'Type a date',
  SHOW_ALL_ADMIN_JOB: `Show all admin jobs's`,
  SHOW_ALL_JOB: `Show All Jobs`,
  SHOW_ALL_ADMIN_ISSUE: `Show all admin tasks`,
  SHOW_ALL_ISSUE: `Show All Tasks`,
  CONDUCTED_ON: 'conducted On',

  // issue-page
  ACTION: 'Action',
  STATUS: 'Status',
  DELETE_ISSUE: 'Are you sure you want to delete task?',
  SELECT_OPTION: 'Select an option to this issue',
  REPORTED_BY: 'Reported By',
  RESPOND: 'Respond',
  ISSUE: 'Task',
  ACTIONS: 'Actions',
  NOTIFY_CLIENT: 'Notify Client',
  ISSUE_SUMMARY: 'Task Summary',

  // new-issue
  NEW_ISSUE: 'New Task',
  EDIT_ISSUE: 'Edit Task',
  ISSUE_TITLE: 'Task Title',
  DUE_DATE: 'Next Step Due',
  TYPE: 'Type',
  RECEIPIENT: 'Recipient',
  ENTER_RECEIPIENT: 'Enter recipient',
  TYPE_TO_SEARCH_CLIENT: 'Type to search the client',
  SELECT_THE_JOB: 'Select the job',
  TYPE_TO_SEARCH_VENDOR: 'Type to search the vendor',
  TYPE_TO_SEARCH_JOB: 'Type to search the job',
  PRIORITY: 'Priority',
  SUPPLIES_NEEDED: 'Supplies Needed',
  NOTIFICATION: 'Notification',
  TEAM_MEMBER_OR_GROUP: 'Team Member or Group',
  NEW_MEMBER: 'New Member',
  ADD_NEW_ISSUE: 'Add New Task',
  SELECT_DATE_TIME: 'Select Date and Time',
  UPDATE_ISSUE: 'Update Task',
  SUPPLIES: 'Supplies',
  CONFIRM_ISSUE: 'Confirm Task',
  REMINDER: 'Reminder',

  // view-report
  AUDIT_TITLE: 'Audit Title',
  PREPARED_BY: 'Prepared By',
  VIEWED_BY_CLIENT: 'Viewed by client',
  APPROVE: 'Approve',
  APPROVE_AND_SEND: 'Approve and Send',
  COMMENTS_AND_HISTORY: 'Comments and History',

  // file-information
  FILE_INFORMATION: 'File Information',

  // chat
  ASSIGNED_TASK: 'Assigned Task',
  YOUR_MESSAGE: 'Your message',
  MESSAGES: 'Messages',
  MEMBER_SINCE: 'Member since',
  INITIAT_CHAT: 'Select user and make your conversation start.',
  DOWNLOAD: 'Download',

  // send-report
  SEND_REPORT: 'Send Report',
  WHAT_YOU_SEND: "Here's what you are about to send:",
  REVIEW_REPORT: 'Review Report',
  APPROVE_SEND_EMAIL: 'Yes, approve and send the email',
  NEED_TO_REVIEW: 'No, I need to review',
  CANCEL_TEXT: 'Cancel',
  SEND_UPDATE: 'Send Update',
  SEND_THE_EMAIL: 'Send the Email',
  ADD_SUBJECT: 'Add Subject',
  SUBJECT_LINE: 'Subject Line',

  // report details
  ITEMS: 'items',
  SEND_REPORT_TO_CLIENT: 'Send Report to Client',
  ITEM_ADDED_TO_ISSUES_MODULE: 'Item added to tasks module',
  TASK_FOUND: 'Task Requiring Approval',
  ITEM_NOT_VISIBLE_TO_CLIENT: 'Item not yet visible in Tasks Module',

  // assign-to-user
  ASSIGN_TO_USER: 'Assign to user',
  ASSIGN_TO: 'Assign to',

  // calendar
  TECHNICIAN: 'Technician',
  SELECT_FILTER_TEAM: 'Select and filter by team',
  SELECT_ALL: 'Select All',
  SHOW_24_HRS_TIME: 'Show 24 hrs Time',
  SHOW_WEEKDAYS_ONLY: 'Show Week Days Only',
  COLLAPSE_VIEW: 'Collapse View',
  EXPAND_VIEW: 'Expand View',

  // media
  ADDED_BY: 'added by',
  UPDATE_INFORMATION: 'Update Information',
  CREATE_FOLDER: 'Create Folder',
  PASTE: 'Paste',
  SEARCH_BY_TAGS: 'Search by Tags',
  SHARE_FILE: 'Share File',

  // report
  APPROVED: 'Approved',
  SELECT_FILES: 'Select Files',

  //Manage tags
  TAGS: 'Tags',
  SAVE: 'Save',

  // job series
  JOB_SERIES: 'Job Series',
  MAIN_JOB: 'Main Job',
  SHOW_COMPLETED_JOBS: 'Show Completed Jobs',
  DELETE: 'Delete',
  VIEW_JOB_SERIES: 'View Job Series',
  ARE_YOU_SURE_DELETE_MAIN_JOB: 'Are you sure you want to delete the main job?',
  NO_KEEP_IT: 'No, keep it',
  YES_DELETE: 'Yes, delete',
  JOB_SERIES: 'Job Series',
  DELETE_JOB_SERIES: 'Are you sure want to delete series jobs?',
  UPDATE_STATUS: 'Update Status',
};
