import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    height: 145,
  },
  msgLabel: {
    color: theme.palette.title_text,
    fontWeight: 400,
    fontSize: '14px !important',
    fontFamily: "'Open Sans', sans-serif !important",
  },
  reactQuillStyle: {
    height: 70,
  },
  reactQuillWithoutBorder: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    '& .ql-container.ql-snow': {
      border: 'none !important',
      padding: '0 !important',
    },
    '& .ql-editor': {
      padding: '0 !important',
    },
  }
}));
