import axios from 'axios';
import { ADD_ISSUE } from '../../../constants';

export const createIssue = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddIssue());
  axios
    .post('/jobs/createIssue', data, config)
    .then((res) => {
      onNewClose();
      dispatch(setAddIssue(res));
    })
    .catch((err) => {
      dispatch(errorAddIssue(err.response));
      onError(err.response);
    });
};

export const loadAddIssue = () => ({
  type: ADD_ISSUE.LOAD,
});

export const setAddIssue = (data) => ({
  type: ADD_ISSUE.SUCCESS,
  payload: data.data,
});

export const errorAddIssue = (error) => ({
  type: ADD_ISSUE.FAIL,
  payload: error,
});
