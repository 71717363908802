import axios from 'axios';
import { TAGS_LIST } from '../../constants';

export const fetchTagsList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;

  dispatch(loadTagsList());
  axios
    .get(`/media/tag/list`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => dispatch(setTagsList(res)))
    .catch((err) => {
      dispatch(errorTagsList(err.response));
      onError(err.response);
    });
};

export const loadTagsList = () => ({
  type: TAGS_LIST.LOAD,
});

export const setTagsList = (mediaList) => ({
  type: TAGS_LIST.SUCCESS,
  payload: mediaList.data.data,
});

export const errorTagsList = (error) => ({
  type: TAGS_LIST.FAIL,
  payload: error,
});
