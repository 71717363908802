import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  tabs_container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  tabs_button: {
    border: 'none',
    background: 'transparent',
    marginRight: 30,
    color: theme.palette.profile_title,
    fontWeight: 600,
    fontSize: 14,
    cursor: 'pointer',
    padding: 0,
  },
  underline_tabs_button_container: {
    marginRight: 25,
  },
  tab_container: {
    display: 'flex',
    alignItems: 'center',
  },
  underline_tabs_button: {
    fontSize: 16,
    padding: '8px 0px',
    borderBottom: '2px solid transparent',
  },
  active_tab: {
    color: theme.palette.background_button,
  },
  underline_tab_active: {
    color: theme.palette.background_button,
    borderBottom: `2px solid ${theme.palette.background_button}`,
  },
  badge: {
    width: 15,
    height: 15,
    marginLeft: 3,
    borderRadius: '50%',
  },
  tab_width: {
    width: 'max-content',
  },
  dot: {
    height: 7,
    width: 7,
    borderRadius: '50%',
    backgroundColor: Colors.RED,
    marginLeft: 5,
  },
}));
