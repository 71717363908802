import React, { useState } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { menuList } from '../../utils/sidebarMenu';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { Hidden, useMediaQuery } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { ProfileBox } from '../../components/ProfileBox';
import CommonDialog from '../../components/CommonDialog';
import store from '../../redux/store';
import { Strings } from '../../utils/strings';
import { logout } from '../../redux/actions/authActions/login';

const ActivateLink = (props) => {
  const { classes, onClick } = props;
  return (
    <NavLink
      onClick={onClick}
      className={`${classes.menu_item} side-menu-item`}
      to={props.to}
    >
      {props.children}
    </NavLink>
  );
};

export default function Sidebar() {
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const [logoutDialog, setLogoutDialog] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const userName = localStorage.getItem('userName');
  const userProfile = localStorage.getItem('profilePicture');
  const decoded = jwtDecode(localStorage.getItem('jwtToken'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <div
        className={classNames(
          classes.sidebar_container,
          layoutState.isSidebarOpened && classes.mobile_sidebar_container
        )}
      >
        <Hidden mdDown>
          <div className={classes.sidebar_header}>
            <img src={icons().logo} alt='' className={classes.sidebar_logo} />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.sidebar_top}>
            <img
              className={classes.close_icon}
              src={icons(theme).closeIcon}
              alt=''
              onClick={() => toggleSidebar(layoutDispatch)}
            />
          </div>
        </Hidden>
        <div className={classes.sidebar_body}>
          <div className={classes.menu_box}>
            {menuList(theme).map((data, index) => {
              return (
                <ActivateLink
                  key={`menu${index}`}
                  classes={classes}
                  to={data.path}
                  onClick={() => tabletView && toggleSidebar(layoutDispatch)}
                >
                  <img
                    src={
                      pathname.startsWith(data.path)
                        ? data.activeIcon
                        : data.icon
                    }
                    alt=''
                    className={classes.menu_icon}
                  />
                  <span className={classes.menu_label}>{data.title}</span>
                  <Hidden mdDown>
                    {pathname.startsWith(data.path) && (
                      <div className={classes.active_indicator} />
                    )}
                  </Hidden>
                </ActivateLink>
              );
            })}
          </div>
        </div>
        <Hidden mdUp>
          <div className={classes.sidebar_bottom}>
            <label htmlFor={Strings.WELCOME} className={classes.welcome_text}>
              {Strings.WELCOME}
            </label>
            <div className={classes.profile_container}>
              <ProfileBox
                name={userName}
                role={decoded.role}
                profileImage={userProfile}
              />
              <img
                src={icons(theme).logoutIcon}
                alt=''
                className={classes.logout_icon}
                onClick={() => {
                  toggleSidebar(layoutDispatch);
                  setLogoutDialog(true);
                }}
              />
            </div>
          </div>
        </Hidden>
      </div>
      <Hidden mdUp>
        {layoutState.isSidebarOpened && (
          <div
            className={classes.dialog_mask}
            onClick={() => toggleSidebar(layoutDispatch)}
          />
        )}
      </Hidden>
      {logoutDialog && (
        <CommonDialog
          title={Strings.LOGOUT}
          message={Strings.SURE_LOGOUT}
          onSuccess={() => store.dispatch(logout(navigate))}
          onClose={() => setLogoutDialog(false)}
        />
      )}
    </>
  );
}
