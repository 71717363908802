import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
import { icons } from '../../utils/icons';

export default makeStyles((theme) => ({
  login_container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  main_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  left_container: {
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '35%',
    },
    position: 'relative',
  },
  login_mask: {
    position: 'absolute',
    background: `linear-gradient(0deg, rgba(81, 99, 202, 0.54), rgba(81, 99, 202, 0.54)), url(${
      icons().loginImage
    })`,
    backgroundBlendMode: 'multiply, normal',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'center top',
    },
  },
  right_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  login_form_container: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  welcome_text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 32,
    color: Colors.BLACK,
    fontWeight: 300,
  },

  login_form: {
    width: '100%',
    margin: '30px 0px 15px 0px',
  },
  inputMargin: {
    marginTop: 18,
  },
  logo: {
    position: 'absolute',
    top: '10%',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  gray_logo: {
    width: 178,
    height: 68,
    marginTop: 12,
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  login_text: {
    position: 'absolute',
    bottom: '11%',
    // width: '15%',
    backgroundBlendMode: 'lighten',
    left: '12%',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
