import axios from 'axios';
import { CATEGORY_LIST } from '../../../constants';

export const fetchCategoryList =
  (type, onError, isJobForm, data) => (dispatch) => {
    const token = localStorage.jwtToken;
    const url = isJobForm
      ? `/jobs/listCategory?isNewJobForm=${true}&type=${type}`
      : `/jobs/listCategory?type=${type}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'post',
      url: url,
      data,
    };
    dispatch(loadCategoryList());

    axios(config)
      .then((res) => dispatch(setCategoryList(res.data.data)))
      .catch((err) => {
        dispatch(errorCategoryList(err.response));
        onError(err.response);
      });
  };

export const loadCategoryList = () => ({
  type: CATEGORY_LIST.LOAD,
});

export const setCategoryList = (categoryList) => ({
  type: CATEGORY_LIST.SUCCESS,
  payload: categoryList,
});

export const errorCategoryList = (error) => ({
  type: CATEGORY_LIST.FAIL,
  payload: error,
});
