import axios from 'axios';
import { UPDATE_FOLDER_NAME } from '../../constants';

export const updateFolderName = (fId, data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  dispatch(loadFolderName());

  axios
    .put(`/media/folder/${fId}`, data, config)
    .then((res) => {
      dispatch(setFolderName(res.data.data));
    })
    .catch((err) => {
      dispatch(errorFolderName(err.response));
      onError(err.response);
    });
};

export const loadFolderName = () => ({
  type: UPDATE_FOLDER_NAME.LOAD,
});

export const setFolderName = (data) => ({
  type: UPDATE_FOLDER_NAME.SUCCESS,
  payload: data,
});

export const errorFolderName = (error) => ({
  type: UPDATE_FOLDER_NAME.FAIL,
  payload: error,
});
