import classNames from 'classnames';
import { icons } from '../../../utils/icons';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../../utils/strings';
import ActionButton from '../ActionButton';
import { issuesActionsArray } from '../../../utils/appUtils';

const DrawerHeader = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.drawer_header}>
      <img
        className={classes.close_icon}
        src={props.closeIcon ? props.closeIcon : icons(theme).closeIcon}
        alt=''
        onClick={props.onClose}
      />
      <label htmlFor='title' className={classes.header_label}>
        {Strings.SELECT_OPTION}
      </label>
    </div>
  );
};

const ActionDrawer = (props) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={classNames(
          classes.dialog_mask,
          props.open && classes.active_mask
        )}
        onClick={props.onClose}
      />
      <div className={classNames(classes.drawer, props.open && classes.active)}>
        <div className={classes.drawer_container}>
          <DrawerHeader onClose={props.onClose} />
          <div className={classes.drawer_body}>
            {issuesActionsArray() &&
              issuesActionsArray()
                .filter((item) => {
                  if (props?.status === 'Completed') {
                    return item.value !== 'Create a job';
                  } else {
                    return item;
                  }
                })
                .map((item, index) => (
                  <ActionButton
                    text={item.title}
                    onClick={() => props.onSelect(item.value, props.id)}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default ActionDrawer;
