import axios from 'axios';
import { DELETE_TAG } from '../../constants';

export const deleteTag = (ID, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;

  dispatch(loadDeleteTag());
  axios
    .delete(`/media/tag/${ID}`,{ headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(setDeleteTag(res));
      onSuccess()
    })
    .catch((err) => {
      dispatch(errorDeleteTag(err.response));
      onError(err.response);
    });
};

export const loadDeleteTag = () => ({
  type: DELETE_TAG.LOAD,
});

export const setDeleteTag = (data) => ({
  type: DELETE_TAG.SUCCESS,
  payload: data,
});

export const errorDeleteTag = (error) => ({
  type: DELETE_TAG.FAIL,
  payload: error,
});
