import React, { useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import Searchbox from '../Searchbox';

export default function TitleContainer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [search, setSearch] = useState('');

  return (
    <div
      className={classNames(
        classes.title_container,
        props.containerClassName && props.containerClassName
      )}
      style={props.containerStyle && props.containerStyle}
    >
      <label className={classes.title}>{props.title}</label>
      {props.isSearch && (
        <Searchbox
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          containerClassName={classes.search_container}
        />
      )}
    </div>
  );
}
