import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material';
import { PAGE_SIZE, pageSizeList } from '../../utils/appUtils';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';
import { Strings } from '../../utils/strings';

const useStyles = makeStyles((theme) => ({
  pagination_root: {
    '& .MuiButtonBase-root': {
      '&.MuiPaginationItem-root': {
        fontSize: 14,
        fontWeight: 500,
        '&.Mui-selected': {
          color: Colors.WHITE,
          background: theme.palette.background_button,
        },
      },
    },
  },
  menu_paper: {
    background: theme.palette.background,
  },
}));

const CustomPagination = (props) => {
  const { pageCount, pageSize, totalRecords, handleChange } = props;
  const theme = useTheme();
  const className = useStyles();
  const count = Math.ceil(totalRecords / (pageSize || PAGE_SIZE)) || 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e?.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems='center'
      gap={2.5}
    >
      <Stack spacing={2}>
        <Pagination
          size='large'
          count={count}
          page={pageCount}
          onChange={(e, page) => handleChange(e, page)}
          classes={{ root: className.pagination_root }}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{
                previous: () => <img src={icons(theme).leftArrow} />,
                next: () => <img src={icons(theme).rightArrow} />,
              }}
            />
          )}
        />
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Typography fontSize={14} color={theme.palette.profile_title}>
          {Strings.ROWS_PER_PAGE_SIZE} &#58;
        </Typography>
        <Button sx={{ ml: 1 }} onClick={handleClick}>
          <Typography
            mr={1}
            fontSize={14}
            color={theme.palette.background_button}
          >
            {pageSize || PAGE_SIZE}
          </Typography>
          <img
            src={icons(theme).downArrow}
            height={20}
            width={20}
            style={{ rotate: open && '180deg' }}
          />
        </Button>
        <Menu
          id='menu'
          open={open}
          autoFocus={false}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: className.menu_paper }}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {(props?.pageSizeList || pageSizeList)?.map((el, index) => (
            <MenuItem
              key={index}
              style={{ color: `${el?.color}` }}
              onClick={(e) => {
                props?.handlePageSize?.(el);
                handleClose(e);
              }}
            >
              {el}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Stack>
  );
};

CustomPagination.propTypes = {
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  totalRecords: PropTypes.number,
  handleChange: PropTypes.func,
  handlePageSize: PropTypes.func,
  pageSizeList: PropTypes.array,
};

export default CustomPagination;
