import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../../components/Button';
import NewFormHeader from '../../../../components/NewFormHeader';
import Textbox from '../../../../components/Textbox';
import { Strings } from '../../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { getErrorMessage, jobStatusArray } from '../../../../utils/appUtils';
import MultiSelectDropdown from '../../../../components/MultiSelectDropdown';
import { connect } from 'react-redux';
import { fetchUserListByType } from '../../../../redux/actions/adminActions/getUserListByType';
import { fetchCategoryList } from '../../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { CircularProgress } from '@material-ui/core';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import { createFilter } from '../../../../redux/actions/filterActions/addFilter';
import { fetchFilterById } from '../../../../redux/actions/filterActions/getFilterById';
import { updateFilter } from '../../../../redux/actions/filterActions/updateFilter';
import { deleteFilter } from '../../../../redux/actions/filterActions/deleteFilter';
import { Colors } from '../../../../utils/color';

const NewCalendarFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [clientList, setClientList] = useState(props.clientList || []); // not calling api getting from previos dialog
  const [adminList, setAdminList] = useState(props.adminList || []); // not calling api getting from previos dialog
  const [technicianList, setTechnicianList] = useState(
    props.technicianList || []
  ); // not calling api getting from previos dialog
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (props?.isEdit) {
      // not needed here as we already get all list data in previous filter dialog - but we needed in edit case
      props.fetchUserListByType('client', (err) => onError(err));
      props.fetchUserListByType('admin', (err) => onError(err));
      props.fetchUserListByType('array', (err) => onError(err), '', [
        'admin',
        'technician',
        'superadmin'
      ]);
    }
  }, []);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title:
            data?.type === 'client'
              ? item.lastName + ', ' + item.firstName
              : item.name,
          value: item._id,
          image: item?.profilePicture,
        };
        if (data?.type === 'array') tempData.color = item?.color;
        return tempData;
      }) || [];
    if (data?.type === 'array') setTechnicianList(newData);
    if (data?.type === 'admin') setAdminList(newData);
    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  useEffect(() => {
    if (props.isEdit && props.selectedId) {
      props.fetchFilterById(props?.selectedId, onError);
    }
  }, [props.isEdit, props.selectedId]);

  useEffect(() => {
    if (props?.isEdit && props?.getFilterData) {
      let data = props.getFilterData.filter;
      const newClients = clientList?.filter((item) =>
        data?.client?.some((el) => el === item?.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        data?.jobStatus?.some((el) => el === item?.value)
      );
      const newAdmins = adminList?.filter((item) =>
        data?.admin?.some((el) => el === item?.value)
      );
      const newAssignedMember = technicianList?.filter((item) =>
        data?.assignedMember?.some((el) => el === item?.value)
      );
      setFieldValue('filterName', props.getFilterData?.filterName || '');
      setFieldValue('title', props.getFilterData?.title || '');
      setFieldValue('client', newClients || []);
      setFieldValue('admin', newAdmins || []);
      setFieldValue('assignedMember', newAssignedMember || []);
      setFieldValue('jobStatus', newJobStatus || []);
    }
  }, [
    props.getFilterData,
    clientList,
    jobStatusArray,
    adminList,
    technicianList,
  ]);

  useEffect(() => {
    if (props.selectedValues) {
      const newClients = clientList?.filter((item) =>
        props?.selectedValues?.client?.some((el) => el?.value === item?.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        props?.selectedValues?.jobStatus?.some(
          (el) => el?.value === item?.value
        )
      );
      const newAdmins = adminList?.filter((item) =>
        props?.selectedValues?.admin?.some((el) => el?.value === item?.value)
      );
      const newAssignedMember = technicianList?.filter((item) =>
        props?.selectedValues?.assignedMember?.some(
          (el) => el?.value === item?.value
        )
      );
      setFieldValue('title', props?.selectedValues?.title || '');
      setFieldValue('client', newClients || []);
      setFieldValue('admin', newAdmins || []);
      setFieldValue('assignedMember', newAssignedMember || []);
      setFieldValue('jobStatus', newJobStatus || []);
    }
  }, [
    props.selectedValues,
    clientList,
    jobStatusArray,
    adminList,
    technicianList,
  ]);

  const formik = useFormik({
    initialValues: {
      filterName: '',
      client: [],
      admin: [],
      assignedMember: [],
      jobStatus: [],
      title: '',
    },
    validationSchema: Yup.object().shape({
      filterName: Yup.string().required('Filtername is required.'),
    }),
    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const handleFilter = (values) => {
    const data = { type: 'CALENDAR_WEB', filterName: values.filterName };
    let filter = {};
    if (values.title) filter.title = values.title;
    if (values.client?.length > 0)
      filter.client = values.client?.map((el) => el.value);
    if (values.admin?.length > 0)
      filter.admin = values.admin?.map((el) => el.value);
    if (values.assignedMember?.length > 0)
      filter.assignedMember = values.assignedMember?.map((el) => el.value);
    if (values.jobStatus?.length > 0)
      filter.jobStatus = values.jobStatus?.map((el) => el.value);
    if (Object.keys(filter).length) data.filter = filter;

    if (props?.isEdit) {
      data.filterId = props?.selectedId;
      props.updateFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    } else {
      props.createFilter(data, onError, () => {
        props?.setIsFilterChange(true);
        props.onClose();
        props.filterDialogOnClose();
      });
    }
  };

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={props?.isEdit ? Strings.EDIT_FILTER : Strings.NEW_FILTER}
          onNewClose={props.onClose}
        />
        {props.categoryListLoading ||
        props.UserListByTypeLoading ||
        props.getFilterLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <Textbox
                  name='filterName'
                  onChange={handleChange('filterName')}
                  value={values.filterName}
                  label={Strings.FILTER_NAME}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  error={Boolean(touched.filterName && errors.filterName)}
                  helperText={touched.filterName && errors.filterName}
                />
                <label className={classes.attribute_title_text}>
                  {Strings.FILTER_ATTRIBUTES.toUpperCase()}
                </label>
                <MultiSelectDropdown
                  name='client'
                  onChange={(value) => setFieldValue('client', value)}
                  value={values.client}
                  label={Strings.CLIENT}
                  title={Strings.TYPE_TO_SEARCH + ' the client'}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={clientList}
                />
                <MultiSelectDropdown
                  name='admin'
                  onChange={(value) => setFieldValue('admin', value)}
                  value={values.admin}
                  label={Strings.ADMIN}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={adminList}
                />
                <MultiSelectDropdown
                  name='assignedMember'
                  onChange={(value) => setFieldValue('assignedMember', value)}
                  value={values.assignedMember}
                  label={Strings.TEAM_MEMBER_ASSIGNED}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                />
                <MultiSelectDropdown
                  name='jobStatus'
                  onChange={(value) => setFieldValue('jobStatus', value)}
                  value={values.jobStatus}
                  label={Strings.JOB_STATUS}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  containerClassName={classes.form_input_container}
                  optionArray={jobStatusArray}
                  hideAvatar
                />
                <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  label={Strings.JOB_TITLE}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <Button
                  type='submit'
                  text={props.isEdit ? Strings.UPDATE : Strings.SAVE}
                  loading={props.addFilterLoading || props.updateFilterLoading}
                  disabled={props.addFilterLoading || props.updateFilterLoading}
                  className={classes.add_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
                {props.isEdit && (
                  <Button
                    type='button'
                    text={Strings.REMOVE}
                    loading={props.deleteFilterLoading}
                    disabled={props.deleteFilterLoading}
                    className={classes.remove_button}
                    containerClassName={classes.form_input_container}
                    loaderColor={Colors.RED}
                    onClick={() => {
                      props.deleteFilter(props.selectedId, onError, () => {
                        props?.setIsFilterChange(true);
                        props.onClose();
                        props.filterDialogOnClose();
                      });
                    }}
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  addFilterLoading: state.addFilterData.loading,

  getFilterData: state.getFilterData.data,
  getFilterLoading: state.getFilterData.loading,

  updateFilterLoading: state.updateFilterData.loading,

  deleteFilterLoading: state.deleteFilterData.loading,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  fetchUserListByType,
  createFilter,
  fetchFilterById,
  updateFilter,
  deleteFilter,
})(NewCalendarFilter);
