import axios from 'axios';
import { CREATE_FILTER } from '../../constants';

export const createFilter = (data, onError, onNewClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddFilter());
  axios
    .post(`/user/filter`, data, config)
    .then((res) => {
      dispatch(setAddFilter(res));
      onNewClose();
    })
    .catch((err) => {
      dispatch(errorAddFilter(err.response));
      onError(err.response);
    });
};

export const loadAddFilter = () => ({
  type: CREATE_FILTER.LOAD,
});

export const setAddFilter = (data) => ({
  type: CREATE_FILTER.SUCCESS,
  payload: data.data,
});

export const errorAddFilter = (error) => ({
  type: CREATE_FILTER.FAIL,
  payload: error,
});
