import axios from 'axios';
import { GET_JOB_FORM_BY_ID } from '../../../constants';

export const fetchJobFormById = (fId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJobFormById());

  axios
    .get(`/jobs/form/${fId}`, config)
    .then((res) => dispatch(setJobFormById(res?.data?.data)))
    .catch((err) => {
      dispatch(errorJobFormById(err.response));
      onError(err.response);
    });
};

export const loadJobFormById = () => ({
  type: GET_JOB_FORM_BY_ID.LOAD,
});

export const setJobFormById = (formList) => ({
  type: GET_JOB_FORM_BY_ID.SUCCESS,
  payload: formList,
});

export const errorJobFormById = (error) => ({
  type: GET_JOB_FORM_BY_ID.FAIL,
  payload: error,
});
