import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  searchbar_container: {
    width: '40%',
    marginLeft: 'auto',
  },
  search_box: {
    paddingRight: 35,
    border: `1px solid ${theme.palette.search_border}`,
    backgroundColor: 'transparent',
    color: theme.palette.search_text,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.search_border}`,
    // },
    '&::placeholder': {
      color: theme.palette.search_text,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 48px)',
    }
  },
}));
