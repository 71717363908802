import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import NextPrevious from '../../../components/NextPreviousButton';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import Button from '../../../components/Button';
import { connect } from 'react-redux';
import { fetchCalendarJunctures } from '../../../redux/actions/adminActions/calendarJunctures';
import { getErrorMessage } from '../../../utils/appUtils';
import { useAlert } from 'react-alert';
import { icons } from '../../../utils/icons';
import { Strings } from '../../../utils/strings';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

const timePeriodArray = ['MONTH', 'WEEK', 'DAY'];

const CalendarHeader = (
  toolbar,
  getCalendarData,
  timePeriod,
  setTimePeriod,
  date,
  setDate,
  startEndDate,
  setStartEndDate,
  onNavigate
) => {
  const classes = useStyles();
  const theme = useTheme();

  const fetchStartEndDate = (fetchDate) => {
    let start = moment(fetchDate).startOf('week');
    let end = moment(fetchDate).endOf('week');
    if (moment(start).format('MMM') === moment(end).format('MMM')) {
      start = moment(start).format('MMM D');
      end = moment(end).format('D');
    } else {
      start = moment(start).format('MMM D');
      end = moment(end).format('D MMM');
    }
    setStartEndDate(`${start} - ${end}`);
    let startDate = moment(fetchDate).startOf('week').format('YYYY-MM-DD');
    let endDate = moment(fetchDate).endOf('week').format('YYYY-MM-DD');
    getCalendarData(startDate, endDate);
  };

  const onClickToday = () => {
    let startDate = moment(new Date()).format('YYYY-MM-DD');
    let endDate = moment(new Date()).format('YYYY-MM-DD');
    getCalendarData(startDate, endDate);
  };

  const goToDayView = () => {
    toolbar.onView('day');
    setTimePeriod('DAY');
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    getCalendarData(startDate, endDate);
  };
  const goToWeekView = () => {
    toolbar.onView('week');
    setTimePeriod('WEEK');
    fetchStartEndDate(date);
  };
  const goToMonthView = () => {
    toolbar.onView('month');
    setTimePeriod('MONTH');
    const startOfMonth = moment(date)
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date).clone().endOf('month').format('YYYY-MM-DD');
    getCalendarData(startOfMonth, endOfMonth);
  };
  const onNextClick = () => {
    let mDate = toolbar.date;
    let newDate;
    if (timePeriod === 'MONTH') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      const startOfMonth = moment(newDate)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment(newDate)
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD');
      getCalendarData(startOfMonth, endOfMonth);
    } else if (timePeriod === 'WEEK') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 7,
        1
      );
      fetchStartEndDate(newDate);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 1,
        1
      );
      let startDate = moment(newDate).format('YYYY-MM-DD');
      let endDate = moment(newDate).format('YYYY-MM-DD');
      getCalendarData(startDate, endDate);
    }
    setDate(newDate);
    toolbar.onNavigate('next', newDate);
  };

  const onPreviousClick = () => {
    let mDate = toolbar.date;
    let newDate;
    if (timePeriod === 'MONTH') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      const startOfMonth = moment(newDate)
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment(newDate)
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD');
      getCalendarData(startOfMonth, endOfMonth);
    } else if (timePeriod === 'WEEK') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 7,
        1
      );
      fetchStartEndDate(newDate);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 1,
        1
      );
      let startDate = moment(newDate).format('YYYY-MM-DD');
      let endDate = moment(newDate).format('YYYY-MM-DD');
      getCalendarData(startDate, endDate);
    }
    setDate(newDate);
    toolbar.onNavigate('prev', newDate);
  };

  return (
    <>
      <div className={classes.header_container} style={{ marginBottom: 15 }}>
        <div className={classes.header_top_container}>
          <Grid container>
            <Grid item xs={4}>
              <div className={classes.time_period_container}>
                {timePeriodArray.map((item, index) => (
                  <Button
                    key={index}
                    text={item}
                    containerClassName={classes.time_period_button_container}
                    className={classNames(
                      classes.time_period_button,
                      timePeriod === item && classes.selected_time_period_button
                    )}
                    onClick={() => {
                      setTimePeriod(item);
                      item === 'MONTH'
                        ? timePeriod !== 'MONTH' && goToMonthView()
                        : item === 'WEEK'
                        ? timePeriod !== 'WEEK' && goToWeekView()
                        : timePeriod !== 'DAY' && goToDayView();
                    }}
                  />
                ))}
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NextPrevious
                showDateView
                showStartEndDate={timePeriod === 'WEEK' ? true : false}
                date={moment(date).format(
                  timePeriod === 'MONTH' ? 'MMM' : 'D MMM'
                )}
                year={moment(date).format('YYYY')}
                startEndDate={startEndDate}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                containerClassName={classes.date_action_container}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'end',
                gridAutoFlow: 'column',
                columnGap: 15,
              }}
            >
              {(timePeriod !== 'DAY' ||
                !moment(date).isSame(new Date(), 'day')) && (
                <Button
                  text={Strings.TODAY}
                  className={classes.new_button}
                  onClick={async () => {
                    setTimePeriod('DAY');
                    onNavigate(new Date());
                    toolbar.onView('day');
                    // toolbar.setDate(new Date());
                    await onClickToday();
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const EventComponent = (props) => {
  const { event } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div
        className={classNames(
          classes.event_container,
          event?.arrival ? classes.arrival_event : classes.departure_event
        )}
      >
        <div
          className={classes.header_top_container}
          style={{ alignItems: 'flex-end', gap: 5 }}
        >
          <img
            src={
              event?.arrival
                ? icons(theme).arrivalIcon
                : icons(theme).departuresIcon
            }
            alt='Arrival'
            className={classes.common_event_icon}
          />
          <span
            className={classNames(
              classes.event_subTitle_text,
              classes.event_text_overflow
            )}
          >
            {event.subTitle}
          </span>
        </div>
        <span
          className={classNames(
            classes.event_title_text,
            classes.event_text_overflow
          )}
        >
          {event?.title}
        </span>
      </div>
    </>
  );
};

const Junctures = (props) => {
  const classes = useStyles();
  const localizer = momentLocalizer(moment);
  const [view, setView] = useState('month');
  const [timePeriod, setTimePeriod] = useState('MONTH');
  const theme = useTheme();
  const [startEndDate, setStartEndDate] = useState();
  const [date, setDate] = useState(new Date());
  const alert = useAlert();
  const [event, setEvent] = useState([]);
  const DnDCalendar = withDragAndDrop(Calendar);

  // === start week from monday ===
  moment.locale('en-gb', {
    week: {
      dow: 1,
    },
  });

  // ==== date box background ===
  const calendarStyle = () => ({
    style: {
      backgroundColor: theme.palette.background,
    },
  });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const getCalendarJunctures = (startDate, endDate) => {
    props.fetchCalendarJunctures(startDate, endDate, (err) => onError(err));
  };

  useEffect(() => {
    let startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(date).endOf('month').format('YYYY-MM-DD');

    getCalendarJunctures(startDate, endDate);
  }, []);

  useEffect(() => {
    const tempData = props.calendarJuncturesData
      ? props.calendarJuncturesData
      : [];
    const newTempData = tempData.map((item) => {
      const data = {
        title: item?.property?.name,
        subTitle: item?.client?.name,
        id: item?._id,
      };
      if (item?.arrival) {
        data.arrival = new Date(item.arrival);
        data.start = new Date(item.arrival);
        data.end = new Date(item.arrival).setHours(
          new Date(item.arrival).getHours() + 1
        );
      }
      if (item?.departure) {
        data.departure = new Date(item.departure);
        data.start = new Date(item.departure);
        data.end = new Date(item.departure).setHours(
          new Date(item.departure).getHours() + 1
        );
      }
      return data;
    });
    setEvent(newTempData);
  }, [view, props.calendarJuncturesData]);

  const { components, defaultDate, formats } = useMemo(
    () => ({
      components: {
        toolbar: (toolbar) =>
          CalendarHeader(
            toolbar,
            getCalendarJunctures,
            timePeriod,
            setTimePeriod,
            date,
            setDate,
            startEndDate,
            setStartEndDate,
            onNavigate
          ),
        event: EventComponent,
      },
      defaultDate: date,
      formats: {
        dateFormat: (date, culture, localizer) =>
          localizer.format(date, 'D', culture),
        weekdayFormat: (date, culture, localizer) =>
          localizer.format(date, 'ddd', culture).toUpperCase(),
        dayFormat: (date, culture, localizer) =>
          localizer.format(date, 'D ddd', culture).toUpperCase(),
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, 'ha', culture),
      },
    }),
    [props.calendarJuncturesData, timePeriod, date, startEndDate]
  );

  const handleShowMore = (events, date) => {
    setDate(date);
    setTimePeriod('DAY');
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    getCalendarJunctures(startDate, endDate);
  };

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  return (
    <div className={classes.calendar_container}>
      <DnDCalendar
        startAccessor='start'
        endAccessor='end'
        localizer={localizer}
        events={props.calendarJuncturesLoading ? [] : event}
        components={components}
        defaultDate={defaultDate}
        defaultView={view}
        formats={formats}
        showMultiDayTimes
        dayPropGetter={calendarStyle}
        onView={(view, date) => {
          setTimePeriod(
            view === 'day' ? 'DAY' : view === 'month' ? 'MONTH' : 'WEEK'
          );
          setView(view);
        }}
        style={{ width: '100%', maxHeight: '100%' }}
        allowMultiple
        onShowMore={(events, date) => handleShowMore(events, date)}
        onNavigate={onNavigate}
        onSelectEvent={(slotInfo) => {
          if (view !== 'day') {
            setTimePeriod('DAY');
            setView('day');
            onNavigate(slotInfo?.start);
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  calendarJuncturesData: state.calendarJuncturesData.data,
  calendarJuncturesLoading: state.calendarJuncturesData.loading,
});

export default connect(mapStateToProps, {
  fetchCalendarJunctures,
})(Junctures);
