import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../../utils/icons';
import useStyles from './styles';
import classNames from 'classnames';

export default function EnhancedTableHead(props) {
  const theme = useTheme();
  const { headCells } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            style={{
              border: 'none',
              backgroundColor: theme.palette.table_head_background,
              padding: '7px 16px',
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 600,
              fontSize: 12,
              color: theme.palette.menu_indicator,
            }}
          >
            <div
              className={classes.header_container}
              style={{ cursor: headCell.isSort ? 'pointer' : 'auto' }}
              onClick={(e) =>
                headCell.isSort && props.handleRequestSort(e, headCell.id)
              }
            >
              {headCell.label}
              {headCell.isSort && headCell.id === props.orderBy && (
                <img
                  className={classNames(
                    classes.sort_icon,
                    props.order === 'desc' && classes.sort_down
                  )}
                  src={icons(theme).sortIcon}
                  alt=''
                />
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
