import axios from 'axios';
import moment from 'moment-timezone';
import { isClient } from '../../../../utils/appUtils';
import { JOB_LIST } from '../../../constants';

export const fetchJobList = (data, onError, category) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    method: isClient() && category !== 'reportJobModule' ? 'get' : 'post',
    url: isClient()
      ? category === 'reportJobModule'
        ? '/client/listJobs'
        : `/client/getReportsByProperty?propertyId=${data}`
      : `/jobs/listJobs`,
    headers: { Authorization: `Bearer ${token}` },
  };

  if ((isClient() && category === 'reportJobModule') || !isClient()) {
    data.currentDate = moment().format('YYYY-MM-DD');
    data.timeZone = moment.tz.guess();
    config.data = data;
  }

  dispatch(loadJobList());

  axios(config)
    .then((res) => dispatch(setJobList(res?.data?.data)))
    .catch((err) => {
      dispatch(errorJobList(err.response));
      onError(err.response);
    });
};

export const loadJobList = () => ({
  type: JOB_LIST.LOAD,
});

export const setJobList = (jobList) => ({
  type: JOB_LIST.SUCCESS,
  payload: jobList,
});

export const errorJobList = (error) => ({
  type: JOB_LIST.FAIL,
  payload: error,
});
