import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#666666',
    zIndex: 6,
  },
  dialog_image_box: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 400,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '30px 0',
    // overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      right: 325,
    },
  },
  dialog_image_container: {
    height: '75%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '0 50px',
    position: 'relative',
  },
  dialog_information_container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    width: 350,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: 275,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // height: 'calc(100% - 50px)',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: Colors.BLACK,
      display: 'flex',
      flexDirection: 'column',
      padding: '25px 0',
    },
  },
  mobile_view_image_container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  file_image: {
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    // objectPosition: 'center',
    // [theme.breakpoints.down('lg')]: {
    //   objectFit: 'contain',
    // },
  },
  fileImage_mobile: {
    maxWidth: '100%',
    maxHeight: 400,
    objectFit: 'contain',
  },
  file_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: 25,
    },
  },
  flex_view: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  flex_column_view: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  icon_image: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  text_blue: {
    fontSize: 16,
    fontWeight: 600,
    color: Colors.LIGHT_BLUE,
    cursor: 'pointer',
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      color: Colors.WHITE,
    },
  },
  text_light: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.title_text,
    [theme.breakpoints.down('sm')]: {
      color: Colors.WHITE,
    },
  },
  text_small_dark: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.title_text,
    [theme.breakpoints.down('sm')]: {
      color: Colors.WHITE,
    },
  },
  no_data_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  no_data_message: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.background_button,
    [theme.breakpoints.down('sm')]: {
      color: Colors.LIGHT_BLUE,
    },
  },
  header_container: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px',
    },
  },
  tags_container: {
    display: 'flex',
    marginTop: 16,
    gap: 5,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  tags: {
    border: `1px solid ${theme.palette.background_button}`,
    borderRadius: 32,
    padding: '2px 7px',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.background_button,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // cursor: 'pointer',
  },
  tag_close_icon: {
    height: 16,
    width: 16,
    cursor: 'pointer',
    marginLeft: 5,
  },
  tag_title_text: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      color: Colors.WHITE,
    },
  },
  form_input: {
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    fontSize: 21,
    fontWeight: 600,
    border: 'none',
    borderBottom: `1px solid ${theme.palette.background_button}`,
    borderRadius: 0,
    padding: '13px 0',
    '&:focus': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.background_button}`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginRight: 20,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  form_input_container: {
    marginTop: 25,
  },
  submit_button_outer_container: {
    width: '100%',
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'flex-end',
  },
  submit_button_container: {
    marginTop: 30,
    width: '100%',
  },
  submit_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  pdfViewDialog: {
    height: '100%',
    // padding: "30px 0",
  },
  pdf_viewer_box: {
    backgroundColor: '#666666',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 400px)',
    height: '100%',
    // overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: 'calc(100% - 325px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    position: 'relative',
  },
  mobile_pdf_viewer_box: {
    display: 'flex',
    height: '100%',
    maxHeight: '60vh',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow: 'auto',
    position: 'relative',
  },
  icon_btn: {
    height: 32,
    width: 32,
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    background: 'rgba(255, 255, 255, 0.6)',
    borderRadius: '50%',
  },
  btn_disabled: {
    opacity: 0.5,
  },
}));
