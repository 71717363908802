import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  action_button: {
    backgroundColor: theme.palette.background,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background,
    },
    cursor: 'pointer',
    padding: 0,
    display:'flex',
    alignItems: 'center',
    marginRight: 10
  },
  action_button_arrow: {
    marginLeft: 0,
    height: 24,
    width: 24,
  },
}));
