import axios from 'axios';
import { GET_MEDIA_BY_ID } from '../../constants';

export const fetchMediaById = (mId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadMediaById());

  axios
    .get(`/media/view/${mId}`, config)
    .then((res) => dispatch(setMediaById(res.data.data)))
    .catch((err) => {
      dispatch(errorMediaById(err.response));
      onError(err.response);
    });
};

export const loadMediaById = () => ({
  type: GET_MEDIA_BY_ID.LOAD,
});

export const setMediaById = (data) => ({
  type: GET_MEDIA_BY_ID.SUCCESS,
  payload: data,
});

export const errorMediaById = (error) => ({
  type: GET_MEDIA_BY_ID.FAIL,
  payload: error,
});
