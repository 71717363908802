import axios from 'axios';
import { TEAM_USER_LIST } from '../../constants';

export const fetchTeamUserList = (data, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadTeamUserList());

  let search = data?.searchString;
  let payload = structuredClone(data) || {};
  delete payload?.searchString;

  axios
    .post(`/admin/listUsers?searchString=${search || ''}`, payload, config)
    .then((res) => dispatch(setTeamUserList(res)))
    .catch((err) => {
      dispatch(errorTeamUserList(err.response));
      onError(err.response);
    });
};

export const loadTeamUserList = () => ({
  type: TEAM_USER_LIST.LOAD,
});

export const setTeamUserList = (userList) => ({
  type: TEAM_USER_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorTeamUserList = (error) => ({
  type: TEAM_USER_LIST.FAIL,
  payload: error,
});
