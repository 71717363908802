import axios from 'axios';
import { GET_ISSUES_BY_PROPERTY } from '../../constants';

export const fetchIssuesByProperty = (pId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadIssuesByProperty());

  axios
    .get(`/client/getIssuesByProperty?propertyId=${pId}`, config)
    .then((res) => dispatch(setIssuesByProperty(res.data.data)))
    .catch((err) => {
      dispatch(errorIssuesByProperty(err.response));
      onError(err.response);
    });
};

export const loadIssuesByProperty = () => ({
  type: GET_ISSUES_BY_PROPERTY.LOAD,
});

export const setIssuesByProperty = (data) => ({
  type: GET_ISSUES_BY_PROPERTY.SUCCESS,
  payload: data,
});

export const errorIssuesByProperty = (error) => ({
  type: GET_ISSUES_BY_PROPERTY.FAIL,
  payload: error,
});
