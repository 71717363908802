import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../utils/icons';
import { debounce } from 'lodash';

export default function DateSelector(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [date, setDate] = useState(props?.value || '');

  const theme1 = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            // background: `transparent url(${Calendar})`,
            marginRight: -7,
            // borderRadius: 0,
            // '&:hover': {
            //   backgroundColor: 'transparent',
            // },
            // '&:focus': {
            //   backgroundColor: 'transparent',
            // },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.background_button} !important`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.input_border}`,
            },
          },
          notchedOutline: {
            border: `1px solid ${theme.palette.input_border}`,
          },
          input: {
            padding: 13,
            fontSize: 14,
            borderRadius: 8,
            fontFamily: "'Open Sans', sans-serif",
            outline: 'none',
            fontWeight: 400,
            backgroundColor: 'transparent',
            color: theme.palette.profile_title,
            '&::placeholder': {
              color: props.placeholderColor
                ? props.placeholderColor
                : theme.palette.input_placeholder,
              opacity: 1,
            },
            '&.Mui-disabled': {
              color: theme.palette.profile_title,
              '-webkit-text-fill-color': theme.palette.profile_title,
            },
          },
        },
      },
    },
  });

  // const handleOnChange = useCallback(
  //   debounce((value) => {
  //     props.onChange(value);
  //   }, 1000),
  //   [props.onChange]
  // );

  // useEffect(() => {
  //   if (props?.value) setDate(props?.value);
  //   else setDate('');
  // }, [props?.value]);

  return (
    <div
      className={classNames(
        classes.date_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classNames(
            classes.date_label,
            props.labelClassName && props.labelClassName
          )}
          htmlFor={props.label}
        >
          {props.label}
        </label>
      )}
      <ThemeProvider theme={theme1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {props.minDate ? (
            <DesktopDatePicker
              views={props?.views || ['year', 'month', 'day']}
              value={props.value}
              onChange={(newValue) => props.onChange(newValue)}
              inputFormat={props?.format || 'MMM dd, yyyy'}
              disableMaskedInput
              components={{
                OpenPickerIcon: () => (
                  <img src={icons(theme).calendarIcon} alt='' />
                ),
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: props.placeholder,
                    }}
                    sx={{
                      svg: { width: '0px', height: '0px' },
                    }}
                  />
                );
              }}
              disabled={props.disabled}
              // disablePast={props.disablePast}
              minDate={props.minDate || undefined}
            />
          ) : (
            <DesktopDatePicker
              views={props?.views || ['year', 'month', 'day']}
              value={props?.value}
              onChange={(val) => props.onChange(val)}
              inputFormat={props?.format || 'MMM dd, yyyy'}
              disableMaskedInput
              components={{
                OpenPickerIcon: () => (
                  <img src={icons(theme).calendarIcon} alt='' />
                ),
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: props.placeholder,
                    }}
                    sx={{
                      svg: { width: '0px', height: '0px' },
                    }}
                  />
                );
              }}
              disabled={props.disabled}
              // disablePast={props.disablePast}
            />
          )}
        </LocalizationProvider>
      </ThemeProvider>
      {/* <input
        type='date'
        className={classNames(classes.date, props.className && props.className)}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
      /> */}
      {props.error && <p className={classes.date_error}>{props.helperText}</p>}
    </div>
  );
}
