import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  button_container: {
    display: 'flex',
    // width: '100%',
  },
  custom_button: {
    backgroundColor: Colors.NEVY_BLUE,
    width: '100%',
    height: 45,
    color: Colors.WHITE,
    borderRadius: 8,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'grid',
    // alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      backgroundColor: Colors.BLUE,
    },
  },
  button_text_container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftIconStyle: {
    marginRight: 10
  }
}));
