import axios from 'axios';
import { UPLOAD_FILE } from '../../constants';

export const clearUploadFile = () => (dispatch) => {
    dispatch(setClearUploadFile());
};

export const addClearUploadFile = () => ({
  type: UPLOAD_FILE.LOAD,
});

export const setClearUploadFile = () => ({
  type: UPLOAD_FILE.SUCCESS,
  payload: { file: null },
});

export const errorClearUploadFile = (error) => ({
  type: UPLOAD_FILE.FAIL,
  payload: error,
});
