import axios from 'axios';
import { DELETE_JOB_SERIES } from '../../../constants';

export const deleteJobSeries = (data, onError, callback) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteJobSeries());
  axios
    .post(`/jobs/deleteJobs`, data, config)
    .then((res) => {
      dispatch(setDeleteJobSeries(res));
      callback?.();
    })
    .catch((err) => {
      dispatch(errorDeleteJobSeries(err.response));
      onError(err.response);
    });
};

export const loadDeleteJobSeries = () => ({
  type: DELETE_JOB_SERIES.LOAD,
});

export const setDeleteJobSeries = (data) => ({
  type: DELETE_JOB_SERIES.SUCCESS,
  payload: data,
});

export const errorDeleteJobSeries = (error) => ({
  type: DELETE_JOB_SERIES.FAIL,
  payload: error,
});
