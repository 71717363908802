import React, { useRef, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/styles';
import { Strings } from '../../utils/strings';
import { Avatar } from '@mui/material';
import { icons } from '../../utils/icons';
import moment from 'moment/moment';
import { CircularProgress } from '@material-ui/core';
import { getErrorMessage } from '../../utils/appUtils';
import { useAlert } from 'react-alert';
import CommonDialog from '../CommonDialog';
import { clearClientFeedback } from '../../redux/actions/adminActions/clearClientFeedback';
import { connect } from 'react-redux';
import { fetchClientFeedbackList } from '../../redux/actions/adminActions/clientFeedbackList';

const FeedBackContainer = (props) => {
  const { dataList } = props;
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const clientId = useRef();
  const [openFeedback, setOpenFeedback] = useState([]);
  const [openClearFeedbackModal, setOpenClearFeedbackModal] = useState(false);

  const handleOpenFeedback = (index) => {
    if (openFeedback.findIndex((item, i) => item === index) === -1) {
      setOpenFeedback([...openFeedback, index]);
    } else {
      setOpenFeedback(openFeedback.filter((item) => item !== index));
    }
  };
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const clearFeedback = () => {
    const id = clientId.current;
    props.clearClientFeedback(id, onError, () => {
      setOpenClearFeedbackModal(false);
      props.fetchClientFeedbackList((err) => onError(err));
    });
  };

  return (
    <>
      {props.loading ? (
        <div className={classes.flexView}>
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : props.dataList?.length === 0 ? (
        <div className={classes.flexView}>
          <p className={classes.no_data_message}>{Strings.NO_DATA_FOUND}</p>
        </div>
      ) : (
        <>
          {dataList.map((data, index) => {
            return (
              <div className={classes.container}>
                <div
                  className={classes.feedback_header}
                  onClick={() => handleOpenFeedback(index)}
                >
                  <img
                    className={classes.toggle_arrow}
                    src={
                      openFeedback.findIndex((item) => item === index) !== -1
                        ? icons(theme).downArrow
                        : icons(theme).rightArrow
                    }
                    alt=''
                  />
                  <label htmlFor='date' className={classes.feedback_title}>
                    {data?.title && data?.title}
                  </label>
                  <img
                    className={classes.clear_icon}
                    src={icons(theme).deleteIcon}
                    alt=''
                    onClick={() => {
                      setOpenClearFeedbackModal(true);
                      clientId.current = data?._id;
                    }}
                  />
                </div>
                {openFeedback.findIndex((item) => item === index) !== -1 && (
                  <div className={classes.feedback_container}>
                    <label className={classes.text_small_light}>
                      {data?.description}
                    </label>
                  </div>
                )}
                <div className={classes.feedback_footer}>
                  <label className={classes.text_small_light}>
                    {Strings.SENT_BY + ' : '}
                    <span className={classes.text_small_dark}>
                      {data?.createdBy && data?.createdBy?.name}
                    </span>
                  </label>
                  <label
                    className={classes.text_small_light}
                    style={{ marginTop: 2 }}
                  >
                    on {moment(data?.createdAt).format('ll')} at{' '}
                    {moment(data?.createdAt).format('h:mmA')}
                  </label>
                  <div
                    className={classes.feedback_header}
                    style={{ marginTop: 5 }}
                  >
                    <label className={classes.text_small_light}>
                      {Strings.ADMIN + ' : '}
                      {data?.admin && data?.admin?.name}
                    </label>
                    <Avatar
                      src={
                        data?.admin?.profilePicture &&
                        data?.admin?.profilePicture
                      }
                      alt=''
                      style={{
                        border: 'none',
                        marginLeft: 'auto',
                        height: 32,
                        width: 32,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {openClearFeedbackModal ? (
        <CommonDialog
          title={Strings.CLIENT_FEEDBACKS}
          message={Strings.CLIENT_FEEDBACK_NOTE}
          onSuccess={clearFeedback}
          onClose={() => setOpenClearFeedbackModal(false)}
          loading={props.clearClientFeedbackLoading}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => ({
  clientFeedbackListData: state.clientFeedbackListData.data,
  clientFeedbackListLoading: state.clientFeedbackListData.loading,

  clearClientFeedbackLoading: state.clearClientFeedbackData.loading,
  clearClientFeedbackData: state.clearClientFeedbackData.data,
});

export default connect(mapStateToProps, {
  clearClientFeedback,
  fetchClientFeedbackList,
})(FeedBackContainer);
