import axios from 'axios';
import { UPDATE_ISSUE } from '../../../constants';

export const updateIssue =
  (issueId, data, onDialogClose, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateIssue());
    axios
      .put(`/jobs/updateIssue?issueId=${issueId}`, data, config)
      .then((res) => {
        dispatch(setUpdateIssue(res.data));
        onDialogClose();
      })
      .catch((err) => {
        dispatch(errorUpdateIssue(err.response));
        onError(err.response);
      });
  };

export const loadUpdateIssue = () => ({
  type: UPDATE_ISSUE.LOAD,
});

export const setUpdateIssue = (data) => ({
  type: UPDATE_ISSUE.SUCCESS,
  payload: data,
});

export const errorUpdateIssue = (err) => ({
  type: UPDATE_ISSUE.FAIL,
  payload: err,
});
