import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Button from '../../../components/Button';
import DateSelector from '../../../components/DateSelector';
import DateTimeContainer from '../../../components/DateTimeContainer';
import Dropdown from '../../../components/Dropdown';
import NewFormHeader from '../../../components/NewFormHeader';
import Textbox from '../../../components/Textbox';
import TimeSelector from '../../../components/TimeSelector';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { getErrorMessage, jobStatusArray } from '../../../utils/appUtils';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useAlert } from 'react-alert';
import NewFilter from './NewFilter';

const CalendarFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [technicianList, setTechnicianList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [openNewFilterDialog, setOpenNewFilterDialog] = useState(false);
  const alert = useAlert();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  // useEffect(() => {
  //   props.fetchCategoryList('form', (err) => onError(err));
  //   props.fetchUserListByType('client', (err) => onError(err));
  //   props.fetchUserListByType('admin', (err) => onError(err));
  //   // props.fetchUserListByType('technician', (err) => onError(err));
  // }, []);

  // useEffect(() => {
  //   const data = props.categoryListData ? props.categoryListData : [];
  //   const newData = data.map((item) => ({
  //     title: item.categoryName,
  //     value: item._id,
  //   }));
  //   setCategoryList(newData);
  // }, [props.categoryListData]);

  useEffect(() => {
    if (props.filterData) {
      const { client, admin, title, jobStatus, assignedMember } =
        props.filterData;
      const newClients = clientList?.filter((item) =>
        client?.some((el) => el === item?.value)
      );
      const newJobStatus = jobStatusArray?.filter((item) =>
        jobStatus?.some((el) => el === item?.value)
      );
      const newAdmins = adminList?.filter((item) =>
        admin?.some((el) => el === item?.value)
      );
      const newAssignedMembers = technicianList?.filter((item) =>
        assignedMember?.some((el) => el === item?.value)
      );
      setFieldValue('client', newClients || []);
      setFieldValue('admin', newAdmins || []);
      setFieldValue('assignedMember', newAssignedMembers || []);
      setFieldValue('title', title || '');
      setFieldValue('jobStatus', newJobStatus || []);
      // setFieldValue('jobType', jobType || '0');
    }
  }, [props.filterData, clientList, adminList, technicianList]);

  useEffect(() => {
    // props.fetchCategoryList('form', (err) => onError(err));
    props.fetchUserListByType('client', (err) => onError(err));
    props.fetchUserListByType('admin', (err) => onError(err));
    props.fetchUserListByType('array', (err) => onError(err), '', [
      'admin',
      'technician',
      'superadmin',
    ]);
    // props.fetchUserListByType('technician', (err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title:
            data?.type === 'client'
              ? item.lastName + ', ' + item.firstName
              : item.name,
          value: item._id,
          image: item?.profilePicture,
        };
        // if (data?.type === 'technician') tempData.image = item?.profilePicture;
        if (data?.type === 'array') tempData.color = item?.color;
        return tempData;
      }) || [];
    if (data?.type === 'array') setTechnicianList(newData);
    if (data?.type === 'admin') setAdminList(newData);
    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  const formik = useFormik({
    initialValues: {
      client: [],
      admin: [],
      assignedMember: [],
      // jobDate: '',
      // jobTime: '',
      // arrivalDate: '',
      // arrivalTime: '',
      // departureDate: '',
      // departureTime: '',
      // date: '',
      // jobType: '0',
      jobStatus: [],
      title: '',
    },
    // validationSchema: FilterJobSchema,

    onSubmit: (values) => {
      handleFilter(values);
    },
  });
  const handleFilter = (values) => {
    const data = {};
    if (values.jobStatus?.length > 0)
      data.jobStatus = values.jobStatus?.map((el) => el.value);
    if (values.client?.length > 0)
      data.client = values.client?.map((el) => el.value);
    if (values.admin?.length > 0)
      data.admin = values.admin?.map((el) => el.value);
    if (values.assignedMember?.length > 0)
      data.assignedMember = values.assignedMember?.map((el) => el.value);
    // if (values.jobType !== '0') data.jobType = values.jobType;
    if (values.title) data.title = values.title;
    props.setFilterData(data, true);
    props.onClose();
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader title={Strings.FILTER_BY} onNewClose={props.onClose} />
        {props.categoryListLoading || props.UserListByTypeLoading ? (
          <div className={classes.flexView}>
            <CircularProgress
              style={{
                color: theme.palette.background_button,
              }}
            />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <div className={classes.form_details}>
                <MultiSelectDropdown
                  name='client'
                  onChange={(value) => setFieldValue('client', value)}
                  value={values.client}
                  label={Strings.CLIENT}
                  // error={Boolean(touched.client && errors.client)}
                  // helperText={touched.client && errors.client}
                  title={Strings.TYPE_TO_SEARCH + ' the client'}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={clientList}
                  searchable
                />
                <MultiSelectDropdown
                  name='admin'
                  onChange={(value) => setFieldValue('admin', value)}
                  value={values.admin}
                  label={Strings.ADMIN}
                  // error={Boolean(touched.admin && errors.admin)}
                  // helperText={touched.admin && errors.admin}
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={adminList}
                  searchable
                />
                <MultiSelectDropdown
                  name='assignedMember'
                  onChange={(value) => setFieldValue('assignedMember', value)}
                  value={values.assignedMember}
                  label={Strings.TEAM_MEMBER_ASSIGNED}
                  // error={Boolean(
                  //   touched.assignedMember && errors.assignedMember
                  // )}
                  // helperText={
                  //   touched.assignedMember && errors.assignedMember
                  // }
                  title={Strings.TYPE_TO_SEARCH}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={technicianList}
                />
                {/* <DateTimeContainer
                  containerClassName={classes.form_input_container}
                  label={Strings.JOB + ' ' + Strings.DATE_AND_TIME}
                >
                  <DateSelector
                    name='jobDate'
                    onChange={(value) => setFieldValue('jobDate', value)}
                    value={values.jobDate}
                    // error={Boolean(touched.jobDate && errors.jobDate)}
                    // helperText={touched.jobDate && errors.jobDate}
                    format='dd MMM yyyy'
                    placeholder={Strings.SAMPLE_DATE}
                    containerClassName={classes.date_selector_container}
                  />
                  <TimeSelector
                    name='jobTime'
                    onChange={(value) => setFieldValue('jobTime', value)}
                    value={values.jobTime}
                    placeholder={Strings.SAMPLE_TIME}
                    // error={Boolean(touched.jobTime && errors.jobTime)}
                    // helperText={touched.jobTime && errors.jobTime}
                  />
                </DateTimeContainer>
                <DateTimeContainer
                  containerClassName={classes.form_input_container}
                  label={Strings.ARRIVAL}
                >
                  <DateSelector
                    name='arrivalDate'
                    onChange={(value) => setFieldValue('arrivalDate', value)}
                    value={values.arrivalDate}
                    // error={Boolean(touched.arrivalDate && errors.arrivalDate)}
                    // helperText={touched.arrivalDate && errors.arrivalDate}
                    format='dd MMM yyyy'
                    placeholder={Strings.SAMPLE_DATE}
                    containerClassName={classes.date_selector_container}
                  />
                  <TimeSelector
                    name='arrivalTime'
                    onChange={(value) => setFieldValue('arrivalTime', value)}
                    value={values.arrivalTime}
                    placeholder={Strings.SAMPLE_TIME}
                    // error={Boolean(touched.arrivalTime && errors.arrivalTime)}
                    // helperText={touched.arrivalTime && errors.arrivalTime}
                  />
                </DateTimeContainer>
                <DateTimeContainer
                  containerClassName={classes.form_input_container}
                  label={Strings.DEPARTURES}
                >
                  <DateSelector
                    name='departureDate'
                    onChange={(value) => setFieldValue('departureDate', value)}
                    value={values.departureDate}
                    // error={Boolean(touched.departureDate && errors.departureDate)}
                    // helperText={touched.departureDate && errors.departureDate}
                    format='dd MMM yyyy'
                    placeholder={Strings.SAMPLE_DATE}
                    containerClassName={classes.date_selector_container}
                  />
                  <TimeSelector
                    name='departureTime'
                    onChange={(value) => setFieldValue('departureTime', value)}
                    value={values.departureTime}
                    placeholder={Strings.SAMPLE_TIME}
                    // error={Boolean(touched.departureTime && errors.departureTime)}
                    // helperText={touched.departureTime && errors.departureTime}
                  />
                </DateTimeContainer>
                <DateSelector
                  name='date'
                  onChange={(value) => setFieldValue('date', value)}
                  value={values.date}
                  label={Strings.DATE}
                  // error={Boolean(touched.date && errors.date)}
                  // helperText={touched.date && errors.date}
                  format='dd MMM yyyy'
                  placeholder={Strings.SAMPLE_DATE}
                  containerClassName={classes.form_input_container}
                /> */}
                {/* <Dropdown
                  name='jobType'
                  onChange={handleChange('jobType')}
                  value={values.jobType}
                  label={Strings.JOB_TYPE}
                  // error={Boolean(touched.jobType && errors.jobType)}
                  // helperText={touched.jobType && errors.jobType}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={categoryList}
                /> */}
                <MultiSelectDropdown
                  name='jobStatus'
                  onChange={(value) => setFieldValue('jobStatus', value)}
                  value={values.jobStatus}
                  label={Strings.JOB_STATUS}
                  // error={Boolean(touched.jobStatus && errors.jobStatus)}
                  // helperText={touched.jobStatus && errors.jobStatus}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobStatusArray}
                  hideAvatar
                />
                <Textbox
                  name='title'
                  onChange={handleChange('title')}
                  value={values.title}
                  label={Strings.JOB_TITLE}
                  // error={Boolean(touched.title && errors.title)}
                  // helperText={touched.title && errors.title}
                  placeholder={Strings.TYPE_HERE}
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                />
                <div
                  className={classes.flexView}
                  style={{ marginTop: 0, gap: 15 }}
                >
                  <Button
                    type='button'
                    text={Strings.SAVE_FILTER}
                    className={classes.save_filter_button}
                    containerClassName={classes.button_container}
                    loaderColor={theme.palette.background_button_text}
                    onClick={() => setOpenNewFilterDialog(true)}
                  />
                  <Button
                    type='submit'
                    text={Strings.FILTER_NOW}
                    // loading={props.addVendorLoading}
                    // disabled={props.addVendorLoading}
                    className={classes.add_button}
                    containerClassName={classes.button_container}
                    loaderColor={theme.palette.background_button_text}
                  />
                </div>
                <Button
                  type='button'
                  text={Strings.CLEAR_FILTERS}
                  onClick={() => {
                    resetForm();
                    props.onClose();
                    props.setFilterData({}, true);
                  }}
                  // loading={props.addVendorLoading}
                  // disabled={props.addVendorLoading}
                  className={classes.secondary_button}
                  containerClassName={classes.form_input_container}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
            </Form>
          </FormikProvider>
        )}
      </div>
      {openNewFilterDialog && (
        <NewFilter
          filterDialogOnClose={() => props.onClose()}
          onClose={() => setOpenNewFilterDialog(false)}
          selectedValues={values}
          setIsFilterChange={props?.setIsFilterChange}
          clientList={clientList}
          adminList={adminList}
          technicianList={technicianList}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,
});

export default connect(mapStateToProps, {
  fetchCategoryList,
  fetchUserListByType,
})(CalendarFilter);
