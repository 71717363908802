import { makeStyles } from '@mui/styles';
import { Colors } from '../../utils/color';

export default makeStyles((theme) => ({
  mainBox: {
    display: 'grid',
    // maxHeight: 590,
    overflow: 'hidden',
    padding: 15,
    borderRadius: 16,
    backgroundColor: Colors.LIGHT_BLUE,
    gridTemplateRows: '1fr',
    position: 'relative',
  },
  innerBox: {
    display: 'grid',
    rowGap: 15,
    height: '100%',
    minHeight: 0,
  },
  justifyCenterContainer: {
    display: 'grid',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 20,
    fontWeight: 400,
    color: Colors.WHITE,
  },
  tempText: {
    fontSize: 70,
    fontWeight: 300,
    color: Colors.WHITE,
    textAlign: 'center',
  },
  gridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    columnGap: 15,
  },
  normalBoldText: {
    fontSize: 16,
    fontWeight: 600,
    color: Colors.WHITE,
  },
  bigIcon: {
    height: 48,
    width: 48,
  },
  smallLightText: {
    fontSize: 14,
    fontWeight: 600,
    color: Colors.WHITE,
    textAlign: 'center',
  },
  smallLightText1: {
    fontSize: 14,
    fontWeight: 600,
    color: Colors.WHITE,
  },
  commonIcon: {
    height: 24,
    width: 24,
  },
  hourTempContainer: {
    background: 'rgba(217, 217, 217, 0.1)',
    borderRadius: 8,
    padding: 12,
    overflow: 'auto',
  },
  gridRow: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    rowGap: 15,
  },
  forCastContainer: {
    display: 'grid',
    gap: 10,
    marginTop: 10,
    gridTemplateRows: '1fr',
    minHeight: 0,
  },
  noDataView: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  primary_button: {
    backgroundColor: Colors.WHITE,
    color: Colors.LIGHT_BLUE,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: Colors.WHITE,
    },
    padding: '8px 15px',
    marginTop: 15,
  },
  viewRadarText: {
    position: 'absolute',
    top: 15,
    right: 15,
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 900,
    '&:hover': {
      color: '#2D3D95',
    },
  },
}));
