import React, { useRef } from 'react';
import useStyles from './styles';
import { icons } from '../../utils/icons';
import { useTheme } from '@mui/material/styles';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '../Button';

const ImageCropper = (props) => {
  const { url, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const cropperRef = useRef();

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      // console.log('cropperRef.current?.cropper', cropperRef.current?.cropper)
    //   console.log('cropped data', cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      props.setCropData(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      props.onClose();
    }
  };

  return (
    <div className={classes.modal_mask_container}>
      <img
        src={icons(theme).closeWhiteIcon}
        className={classes.close_icon}
        onClick={() => onClose && onClose()}
      />
      <Cropper
        ref={cropperRef}
        style={{ width: '100%', objectFit: 'contain', height: '100%' }}
        zoomTo={0}
        initialAspectRatio={1}
        // aspectRatio={props.aspectRatio}
        // preview=".img-preview"
        src={url}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        // minCropBoxHeight={props.minCropBoxHeight}
        // minCropBoxWidth={props.minCropBoxWidth}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        guides={true}
      />
      <Button
        text={'Crop'}
        // containerClassName={classes.add_new_container}
        className={classes.crop_btn}
        onClick={getCropData}
      />
    </div>
  );
};

export default ImageCropper;
