import axios from 'axios';
import { isAdmin, isSuperAdmin } from '../../../utils/appUtils';
import { GET_ROOMS_LIST } from '../../constants';

export const fetchRoomsList = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadRoomsList());
  let url = '/room/list';
  axios
    .get(url, config)
    .then((res) => dispatch(setRoomsList(res.data.data)))
    .catch((err) => {
      dispatch(errorRoomsList(err.response));
      onError(err.response);
    });
};

export const loadRoomsList = () => ({
  type: GET_ROOMS_LIST.LOAD,
});

export const setRoomsList = (countData) => ({
  type: GET_ROOMS_LIST.SUCCESS,
  payload: countData,
});

export const errorRoomsList = (error) => ({
  type: GET_ROOMS_LIST.FAIL,
  payload: error,
});
