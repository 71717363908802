import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  new_field_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  plus_icon: {
    height: 32,
    width: 32,
    marginRight: 10,
    cursor: 'pointer',
  },
  common_text: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.background_button,
    cursor: 'pointer',
  },
}));
