import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import NewFormHeader from '../../../components/NewFormHeader';
import { useFormik, Form, FormikProvider } from 'formik';
import { Strings } from '../../../utils/strings';
import Dropdown from '../../../components/Dropdown';
import Button from '../../../components/Button';
import { useTheme } from '@mui/material/styles';
import DateTimeContainer from '../../../components/DateTimeContainer';
import DateSelector from '../../../components/DateSelector';
import TimeSelector from '../../../components/TimeSelector';
import Textbox from '../../../components/Textbox';
import FormSwitch from '../../../components/Switch';
import { AddJobSchema } from '../../../utils/validationSchemas';
import { connect, useDispatch } from 'react-redux';
import {
  defaultMediaType,
  frequencyArray,
  getErrorMessage,
  jobStatusArray,
  numberRegex,
  workingDaysArray,
} from '../../../utils/appUtils';
import { CircularProgress } from '@material-ui/core';
import { fetchUserListByType } from '../../../redux/actions/adminActions/getUserListByType';
import moment from 'moment';
import { createJob } from '../../../redux/actions/jobActions/jobActions/addJob';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import classNames from 'classnames';
import { fetchClientById } from '../../../redux/actions/clientActions/getClientById';
import {
  clearJobData,
  fetchJobById,
} from '../../../redux/actions/jobActions/jobActions/getJobById';
import { updateJob } from '../../../redux/actions/jobActions/jobActions/updateJob';
import { useAlert } from 'react-alert';
import { fetchCategoryList } from '../../../redux/actions/jobActions/categoryActions/categoryTypeList';
import { fetchJobFormsList } from '../../../redux/actions/jobActions/jobActions/jobFormsList';
import { fetchVendorDropDownList } from '../../../redux/actions/adminActions/vendorDropdownList';
import NewUser from '../../Vendor/NewVendor';
import { icons } from '../../../utils/icons';
import MediaDetails from '../../Media/MediaDetails';
import Attachment from '../../../components/Attachment';
import { uploadFile } from '../../../redux/actions/adminActions/uploadFile';
import { deleteFile } from '../../../redux/actions/adminActions/deleteFile';
import { dropdownIssueList } from '../../../redux/actions/jobActions/issuesActions/dropdownIssueList';
import CommonDialog from '../../../components/CommonDialog';
import { deleteJob } from '../../../redux/actions/jobActions/jobActions/deleteJob';
import ReactQuillTextbox from '../../../components/ReactQuillTextbox';
import { Stack } from '@mui/material';

const NewJob = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  // const [recurring, setRecurring] = useState(false);
  const [jobFormList, setJobFormList] = useState([]);
  // const [jobFormCategoryList, setJobFormCategoryList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [tempAdminList, setTempAdminList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [minStartDate, setMinStartDate] = useState(new Date());
  const [minRecurringStartDate, setMinRecurringStartDate] = useState(
    moment().add(1, 'day')
  );
  const [vendorList, setVendorList] = useState([]);
  const [openAddVendorDialog, setOpenAddVendorDialog] = useState(false);
  const [openImageInfoDialog, setOpenImageInfoDialog] = useState(false);
  const [fetchedFileInfo, setFetchedFileInfo] = useState({});
  const [deletedFilesList, setDeletedFilesList] = useState([]);
  const [deleteAppointments, setDeleteAppointments] = useState([]);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [deleteJobDialog, setDeleteJobDialog] = useState(false);

  const appointment = {
    assignedMember: [],
    startDate: new Date(),
    startTime: moment(),
    endDate: new Date(),
    endTime: moment().add(1, 'hours'),
  };

  const onError = (err) => {
    const error = getErrorMessage(err, true)?.message;
    const askForPermission = getErrorMessage(err, true)?.askForPermission;
    if (error && !askForPermission) {
      alert.error(error);
    }
    if (askForPermission) setOpenPermissionDialog(true);
  };

  const handleClose = (data) => {
    setSelectedJobData(null);
    dispatch(clearJobData());
    props?.onNewClose(data);
  };

  useEffect(() => {
    props.fetchVendorDropDownList((err) => onError(err));
  }, [props?.addVendorData]);

  useEffect(() => {
    const data = props.vendorDropDownListData
      ? props.vendorDropDownListData
      : [];
    const newData =
      data?.map((item) => {
        const tempData = {
          title: item.fullName,
          value: item._id,
        };
        return tempData;
      }) || [];
    setVendorList(newData);
  }, [props.vendorDropDownListData]);

  useEffect(() => {
    if (props.isEdit) {
      setFieldValue('isEdit', true);
      props.fetchJobById(props?.data?._id, (err) => onError(err));
    }
    props.fetchJobFormsList((err) => onError(err));
    // props.fetchCategoryList('form', (err) => onError(err));
    props.fetchUserListByType('client', (err) => onError(err));
    if (props.isEdit) props.fetchUserListByType('admin', (err) => onError(err));
    if (!props?.allTechnician || props?.allTechnician?.length === 0) {
      props.fetchUserListByType(
        'array',
        (err) => onError(err),
        '',
        ['admin', 'technician', 'superadmin']
        // 'superadmin&roles=admin&roles=technician'
      );
    }
  }, []);

  useEffect(() => {
    if (props?.allTechnician && props?.allTechnician?.length > 0) {
      const newData =
        props.allTechnician?.map((item) => {
          const tempData = {
            title: item.name,
            value: item._id,
          };
          tempData.image = item?.profilePicture;
          tempData.color = item?.color;
          return tempData;
        }) || [];
      setTeamMemberList(newData);
    }
  }, []);

  useEffect(() => {
    const data = props.jobFormsListData ? props.jobFormsListData : [];
    const newData = data.map((item) => ({
      title: item.formName,
      value: item._id,
      // formCategory: item.formCategory,
    }));
    setJobFormList(newData);
  }, [props.jobFormsListData]);

  useEffect(() => {
    const data = props.UserListByTypeData ? props.UserListByTypeData : {};
    const newData =
      data?.data?.map((item) => {
        const tempData = {
          title:
            data?.type === 'client'
              ? item.lastName + ', ' + item.firstName
              : item.name,
          value: item._id,
        };
        if (!props?.allTechnician && data?.type === 'array')
          tempData.image = item?.profilePicture;
        if (data?.type === 'array') tempData.color = item?.color;
        return tempData;
      }) || [];
    if (!props?.allTechnician && data?.type === 'array')
      setTeamMemberList(newData);
    if (data?.type === 'admin' && props.isEdit) {
      setAdminList(newData);
      setTempAdminList(newData);
    }

    if (data?.type === 'client') setClientList(newData);
  }, [props.UserListByTypeData]);

  useEffect(() => {
    if (props.isEdit) {
      const isAdmin = tempAdminList.filter(
        (item) => item.value === selectedJobData?.admin?._id
      );
      if (isAdmin.length === 0)
        setAdminList([
          {
            title: selectedJobData?.admin?.name,
            value: selectedJobData?.admin?._id,
          },
          ...tempAdminList,
        ]);
      else setAdminList(tempAdminList);
    }
  }, [tempAdminList, selectedJobData]);

  useEffect(() => {
    if (props.isEdit && selectedJobData && adminList) {
      const isAdmin = adminList.filter(
        (item) => item.value === selectedJobData?.admin?._id
      );
      if (isAdmin.length > 0)
        setFieldValue('admin', selectedJobData?.admin?._id);
      else setFieldValue('admin', '');
    }
  }, [selectedJobData, adminList]);

  // useEffect(() => {
  //   const data = props.categoryListData ? props.categoryListData : [];
  //   const newData = data.map((item) => ({
  //     title: item.categoryName,
  //     value: item._id,
  //     form: item.form,
  //   }));
  //   setJobFormCategoryList(newData);
  // }, [props.categoryListData]);

  useEffect(() => {
    const data = props?.jobData
      ? structuredClone(props?.jobData) // ? JSON.parse(JSON.stringify(props.jobData))
      : null;
    // console.log('jobData', props.jobData)
    if (data) setSelectedJobData(data);
  }, [props.jobData]);

  const formik = useFormik({
    initialValues: {
      title: '',
      // jobType: '',
      jobForm: '0',
      jobStatus: 'New',
      client: '',
      property: '',
      issue: [],
      admin: '',
      vendor: '',
      vendorName: '',
      appointments: [{ ...appointment }],
      // assignedTeamMember: [],
      // startDate: new Date(),
      // startTime: moment(),
      // endDate: new Date(),
      // endTime: moment().add(1, 'hours'),
      details: '',
      jobNotes: '',
      recurring: false,
      // frequency: '0',
      count: '1',
      timePeriod: workingDaysArray[0].value,
      recurringStartDate: '',
      recurringEndDate: '',
      isEdit: false,
      attachments: [],
      deletedFiles: [],
      // suppliesNeeded: '',
    },
    validationSchema: AddJobSchema,

    onSubmit: (values) => {
      handleJob(values);
    },
  });

  const handleJob = (values, allowOverlapJob) => {
    let allAppointments = JSON.parse(JSON.stringify([...values.appointments]));
    const appointments = allAppointments.map((item) => {
      item.assignedMember = item.assignedMember.map((i) => i.value);
      item.startDate =
        item.startDate &&
        item.startTime &&
        moment(
          moment(item.startDate).format('YYYY-MM-DD') +
            ' ' +
            moment(item.startTime).format('HH:mm')
        ).format();
      item.endDate =
        item.endDate &&
        item.endTime &&
        moment(
          moment(item.endDate).format('YYYY-MM-DD') +
            ' ' +
            moment(item.endTime).format('HH:mm')
        ).format();
      delete item.startTime;
      delete item.endTime;
      return item;
    });
    const data = {
      title: values.title,
      // jobType: values.jobType,
      jobStatus: values.jobStatus,
      client: values.client,
      property: values.property,
      admin: values.admin,
      appointments: [...appointments],
      // assignedMember: values.assignedTeamMember.map((item) => item.value),
      // startDate:
      //   values.startDate &&
      //   values.startTime &&
      //   moment(
      //     moment(values.startDate).format('YYYY-MM-DD') +
      //       ' ' +
      //       moment(values.startTime).format('HH:mm')
      //   ).format(),
      // endDate:
      //   values.endDate &&
      //   values.endTime &&
      //   moment(
      //     moment(values.endDate).format('YYYY-MM-DD') +
      //       ' ' +
      //       moment(values.endTime).format('HH:mm')
      //   ).format(),
      recurring: values.recurring,
      attachments: values.attachments,
    };
    if (values.jobForm !== '0') data.jobForm = values.jobForm;
    if (values.vendor) data.vendor = values.vendor;
    if (values.vendorName) data.vendorName = values.vendorName;
    if (!props.isEdit && values.details?.replace('<p><br></p>', ''))
      data.details = values.details;
    if (props.isEdit) data.details = values.details?.replace('<p><br></p>', '');
    // data.suppliesNeeded = values?.suppliesNeeded || '';
    if (values.jobNotes?.replace('<p><br></p>', '') && !props.isEdit)
      data.jobNotes = values.jobNotes;
    if (values.recurring) {
      // data.frequency = values.frequency;
      data.repeat = {
        count: values.count,
        timePeriod: values.timePeriod,
      };
      data.recurringStartDate = moment(values.recurringStartDate).format();
      data.recurringEndDate = moment(values.recurringEndDate).format();
    }
    if (values.issue) {
      data.linkedIssues = values.issue?.map((el) => el.value);
    }
    if (allowOverlapJob) data.allowOverlapJob = allowOverlapJob;
    // console.log('values.issue', values.issue)
    if (props.isEdit) {
      if (deleteAppointments?.length > 0) {
        data.deletedAppointments = [...deleteAppointments] || [];
      }
      data.deletedFiles = values.deletedFiles || [];
      props.updateJob(
        selectedJobData._id,
        data,
        () => {
          setDeleteAppointments([]);
          if (props?.data?.appointmentId) {
            data._id = selectedJobData._id;
            const tempAdmin = adminList.find(
              (item) => item.value === values.admin
            );
            data.admin = { name: tempAdmin.title, _id: tempAdmin.value };
            let selectedAppointment = values.appointments.filter(
              (item) => item._id === props?.data?.appointmentId
            );
            let members = selectedAppointment[0].assignedMember;
            data.users = members.map((item) => {
              return {
                name: item.title,
                _id: item.value,
                profilePicture: item.image,
              };
            });
            let selectedClient = clientList.filter(
              (i) => i.value === values.client
            );
            data.client = {
              name: selectedClient[0].title,
              _id: selectedClient[0].value,
            };
            data.startDate = moment(
              moment(selectedAppointment[0].startDate).format('YYYY-MM-DD') +
                ' ' +
                moment(selectedAppointment[0].startTime).format('HH:mm')
            ).format();
            data.endDate = moment(
              moment(selectedAppointment[0].endDate).format('YYYY-MM-DD') +
                ' ' +
                moment(selectedAppointment[0].endTime).format('HH:mm')
            ).format();
            // props.onNewClose(data);
            handleClose(data);
          } else {
            // props.onNewClose();
            handleClose();
          }
        },
        (err) => onError(err)
      );
    } else {
      props.createJob(data, (err) => onError(err), handleClose);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  // useEffect(() => {
  //   if (values.jobType !== '0' && jobFormCategoryList.length > 0)
  //     setFieldValue(
  //       'jobForm',
  //       jobFormCategoryList.filter((item) => item.value === values.jobType)[0]
  //         .form?._id || ''
  //     );
  //   else setFieldValue('jobForm', '');
  // }, [values.jobType, jobFormCategoryList]);

  useEffect(() => {
    if (
      !props.isEdit &&
      props?.dateData &&
      Object.keys(props?.dateData)?.length > 0 &&
      teamMemberList.length > 0
    ) {
      let newAppointment = { ...appointment };
      newAppointment.startDate = props?.dateData?.start;
      newAppointment.startTime = props?.dateData?.start;
      newAppointment.endDate = props?.dateData?.end;
      newAppointment.endTime = props?.dateData?.end;

      if (props?.dateData?.technician) {
        let technicians = props?.dateData?.technician
          ? teamMemberList?.filter(
              (item) => item.value === props?.dateData?.technician
            )
          : [];
        newAppointment.assignedMember = [...technicians];
      }
      setFieldValue('appointments', [{ ...newAppointment }]);
    }
  }, [teamMemberList]);

  useEffect(() => {
    if (values.client)
      props.fetchClientById(values.client, (err) => onError(err));
  }, [values.client]);

  useEffect(() => {
    if (values.property)
      props.dropdownIssueList(values.property, (err) => onError(err));
  }, [values.property]);

  useEffect(() => {
    const data = props.clientData ? props.clientData : {};
    const newData = data?.properties?.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setPropertyList(newData || []);
    if (newData && newData.length === 1 && values.client) {
      setFieldValue('property', newData[0].value);
    }
  }, [props.clientData]);

  useEffect(() => {
    const data = props.dropdownIssueListData ? props.dropdownIssueListData : [];
    const newData = data?.map((item) => ({
      title: item.issueTitle,
      value: item._id,
    }));
    setIssueList(newData || []);
  }, [props.dropdownIssueListData]);

  useEffect(() => {
    if (props.isEdit && selectedJobData) {
      const {
        title,
        jobForm,
        jobStatus,
        client,
        admin,
        vendorName,
        details,
        jobNotes,
        recurring,
        // frequency,
        repeat,
        startDate,
        endDate,
        recurringStartDate,
        recurringEndDate,
        property,
        vendor,
        attachments,
        issue,
        // suppliesNeeded,
        // jobType,
      } = selectedJobData;
      setFieldValue('title', title || '');
      // setFieldValue('jobType', jobType?._id || '0');
      setFieldValue('jobForm', jobForm?._id || '0');
      setFieldValue('jobStatus', jobStatus || '0');
      setFieldValue('client', client?._id || '');
      // setFieldValue('issue', issue || '');
      setFieldValue('property', property?._id || '');
      setFieldValue('vendor', vendor || '');
      setFieldValue('attachments', attachments || []);
      setFieldValue('vendorName', vendorName || '');
      setFieldValue('details', details || '');
      // if (jobNotes) setFieldValue('jobNotes', jobNotes);
      setFieldValue('recurring', recurring);
      // setFieldValue('suppliesNeeded', suppliesNeeded || '');
      if (recurring) {
        // setFieldValue('frequency', frequency);
        setFieldValue('count', repeat.count);
        setFieldValue('timePeriod', repeat.timePeriod);
        setFieldValue('recurringStartDate', recurringStartDate);
        setFieldValue('recurringEndDate', recurringEndDate);
      }
      // setFieldValue('startDate', startDate);
      // setFieldValue('startTime', startDate);
      // setFieldValue('endDate', endDate);
      // setFieldValue('endTime', endDate);
      // recurringStartDate
      //   ? new Date(recurringStartDate) > new Date()
      //     ? setMinRecurringStartDate(new Date())
      //     : setMinRecurringStartDate(new Date(recurringStartDate))
      //   : setMinRecurringStartDate(new Date());
      // startDate
      //   ? new Date(startDate) > new Date()
      //     ? setMinStartDate(new Date())
      //     : setMinStartDate(new Date(startDate))
      //   : setMinStartDate(new Date());
      setAttachments(attachments);
    }
  }, [selectedJobData]);

  useEffect(() => {
    if (props.selectedTech) {
      const newData = teamMemberList.filter(
        (item) => item.value === props.selectedTech
      );
      setFieldValue('assignedTeamMember', newData);
    }
  }, [props.selectedTech, teamMemberList]);

  useEffect(() => {
    if (props?.isEdit && selectedJobData) {
      const newData = issueList.filter((item) =>
        selectedJobData?.linkedIssues.some((x) => x === item.value)
      );
      setFieldValue('issue', newData);
    }
  }, [selectedJobData, issueList]);

  useEffect(() => {
    if (props.issue) {
      const { client, property, _id } = props?.data;
      if (clientList.length > 0) setFieldValue('client', client?._id || '');
      if (propertyList.length > 0)
        setFieldValue('property', property?._id || '');
      if (issueList.length > 0) {
        const newData = issueList.filter((item) => item.value === _id);
        setFieldValue('issue', newData || '');
      }
    }
  }, [issueList, propertyList, clientList]);

  // useEffect(() => {
  //   if (props.isEdit && selectedJobData) {
  //     setFieldValue('property', selectedJobData?.property?._id || '');
  //   }
  // }, [selectedJobData]);

  useEffect(() => {
    if (selectedJobData && teamMemberList.length > 0 && props.isEdit) {
      const { appointments } = selectedJobData;
      const newAppointments = structuredClone(appointments);
      const teamList = structuredClone(teamMemberList);
      let newAppointment = newAppointments.map((item) => {
        const data = teamList?.filter((el) =>
          item?.assignedMember?.some((o) => o?._id === el?.value)
        );
        item.assignedMember = data;
        item.startTime = item.startDate;
        item.endTime = item.endDate;
        return item;
      });
      setFieldValue('appointments', newAppointment);
      // const newData = teamMemberList.filter((item) =>
      //   selectedJobData.assignedMember.some((x) => x._id === item.value)
      // );
      // setFieldValue('assignedTeamMember', newData);
    }
  }, [selectedJobData, teamMemberList]);

  const onUploadAttachmentsFile = (files) => {
    let fetchedData;
    if (props.isEdit) {
      fetchedData = files?.map((el) => ({
        ...el,
        new: true,
      }));
    } else fetchedData = files;
    const newData = [...attachments, ...fetchedData];
    setAttachments(newData);
    setFieldValue('attachments', newData);
  };

  const handleFileUpload = async (file) => {
    let newFiles = file.target.files || {};
    if (Object(newFiles)?.length > 0) {
      const data = { files: newFiles };
      props.uploadFile(data, (res) => onUploadAttachmentsFile(res), onError);
    }
    // let urlFiles = [...attachments];
    // let allAttachments = [...values.attachments, ...file.target.files];
    // for (let i of file.target.files) {
    //   let image = URL.createObjectURL(i);
    //   let size = await formatBytes(i.size);
    //   let type = defaultMediaType[i.type] ? defaultMediaType[i.type] : i.type;
    //   let data = { url: image, type: type, name: i.name, size: size };
    //   urlFiles.push(data);
    // }
    // setAttachments(urlFiles);
    // setFieldValue('attachments', allAttachments);
  };

  const onClickRemove = async (file, index) => {
    let data = { url: file?.url };
    if (!props.isEdit) props.deleteFile(data, onError);
    let newAttachments = attachments.filter((i, ind) => index !== ind);
    let attachs = [...values.attachments];
    let newAttachments1 = attachs.filter((i, ind) => index !== ind);
    await setAttachments(newAttachments);
    await setFieldValue('attachments', newAttachments1);
    if (props?.isEdit) {
      let deleteData = {
        _id: file?._id,
        url: file?.url,
      };
      setDeletedFilesList([...deletedFilesList, deleteData]);
      setFieldValue('deletedFiles', [...deletedFilesList, deleteData]);
    }
  };

  const onClickPreview = (item, index) => {
    setOpenImageInfoDialog(true);
    setFetchedFileInfo(item);
  };

  //========= delete job =========
  const onDeleteJob = () => {
    props.deleteJob(
      props?.data?._id,
      () => {
        setDeleteJobDialog(false);
        props.onNewClose();
      },
      (err) => onError(err)
    );
  };

  // ===== find maxDate from appointments and set recurring minDate =====
  useEffect(() => {
    if (values?.appointments?.length) {
      const dateData = values?.appointments?.map(
        (el) => new Date(el?.startDate)
      );
      const maxDate = new Date(Math.max(...dateData)) || new Date();
      setMinRecurringStartDate(moment(maxDate).add(1, 'day'));
    }
  }, [values?.appointments]);

  if (openAddVendorDialog) {
    return <NewUser onNewClose={() => setOpenAddVendorDialog(false)} />;
  } else {
    return (
      <>
        <div className={classes.dialog_mask} />
        <div className={classes.dialog_container}>
          <NewFormHeader
            title={props.isEdit ? Strings.EDIT_JOB : Strings.NEW_JOB}
            onNewClose={() => handleClose()}
          />
          {props.categoryListLoading ||
          props.UserListByTypeLoading ||
          props.jobLoading ||
          props.jobFormsListLoading ? (
            <div className={classes.flexView}>
              <CircularProgress
                style={{
                  color: theme.palette.background_button,
                }}
              />
            </div>
          ) : (
            <FormikProvider value={formik}>
              <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <div className={classes.form_details}>
                  <Textbox
                    name='title'
                    onChange={handleChange('title')}
                    value={values.title}
                    label={Strings.JOB_TITLE}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    placeholder={Strings.ENTER_TITLE}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                  />

                  {/* <Dropdown
                  name='jobType'
                  onChange={handleChange('jobType')}
                  value={values.jobType}
                  label={Strings.JOB_TYPE}
                  error={Boolean(touched.jobType && errors.jobType)}
                  helperText={touched.jobType && errors.jobType}
                  title={Strings.SELECT_AN_OPTION}
                  labelClassName={classes.form_label}
                  // className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={jobFormCategoryList}
                /> */}

                  {/* <Textbox
                  name='jobForm'
                  // onChange={handleChange('jobType')}
                  value={
                    jobFormCategoryList.filter(
                      (item) => item?.form?._id === values.jobForm
                    )?.[0]?.form?.formName || ''
                  }
                  label={Strings.JOB_FORM}
                  error={Boolean(touched.jobForm && errors.jobForm)}
                  helperText={touched.jobForm && errors.jobForm}
                  placeholder={Strings.SELECT_THE_JOB_TYPE}
                  labelClassName={classes.form_label}
                  className={classNames(
                    classes.form_input,
                    classes.disableInput
                  )}
                  containerClassName={classes.form_input_container}
                  // disabled
                /> */}
                  <Dropdown
                    name='jobForm'
                    onChange={handleChange('jobForm')}
                    value={values.jobForm}
                    label={Strings.JOB_FORM}
                    error={Boolean(touched.jobForm && errors.jobForm)}
                    helperText={touched.jobForm && errors.jobForm}
                    title={Strings.SELECT_AN_OPTION}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={jobFormList}
                  />

                  <Dropdown
                    name='jobStatus'
                    onChange={handleChange('jobStatus')}
                    value={values.jobStatus}
                    label={Strings.JOB_STATUS}
                    error={Boolean(touched.jobStatus && errors.jobStatus)}
                    helperText={touched.jobStatus && errors.jobStatus}
                    title={Strings.SELECT_AN_OPTION}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={jobStatusArray}
                  />

                  {/* {props.issue && (
                    <Textbox
                      name='issue'
                      // onChange={handleChange('jobType')}
                      value={props?.data?.issueTitle}
                      label={Strings.ISSUE}
                      // error={Boolean(touched.jobForm && errors.jobForm)}
                      // helperText={touched.jobForm && errors.jobForm}
                      // placeholder={Strings.SELECT_THE_JOB_TYPE}
                      labelClassName={classes.form_label}
                      className={classNames(
                        classes.form_input,
                        classes.disableInput
                      )}
                      containerClassName={classes.form_input_container}
                      disabled
                    />
                  )} */}

                  <Dropdown
                    name='client'
                    onChange={(value) => {
                      setFieldValue('client', value);
                      setFieldValue('property', '');
                      setFieldValue('issue', []);
                    }}
                    value={values.client}
                    label={Strings.CLIENT}
                    error={Boolean(touched.client && errors.client)}
                    helperText={touched.client && errors.client}
                    title={Strings.TYPE_TO_SEARCH}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={clientList}
                    searchable
                  />
                  <Dropdown
                    name='property'
                    onChange={(value) => {
                      setFieldValue('property', value);
                      setFieldValue('issue', []);
                    }}
                    value={values.property}
                    label={Strings.PROPERTY}
                    error={Boolean(touched.property && errors.property)}
                    helperText={touched.property && errors.property}
                    title={
                      !values.client
                        ? Strings.SELECT_THE_CLIENT
                        : Strings.TYPE_TO_SEARCH
                    }
                    labelClassName={classes.form_label}
                    className={classNames(
                      classes.form_input,
                      classes.disableInput
                    )}
                    containerClassName={classes.form_input_container}
                    searchable
                    optionArray={propertyList}
                    disabled={!values.client || props.clientLoading}
                  />
                  <MultiSelectDropdown
                    name='issue'
                    onChange={(value) => setFieldValue('issue', value)}
                    value={values.issue}
                    label={Strings.ISSUES}
                    title={
                      !values.client
                        ? Strings.SELECT_THE_ISSUE
                        : Strings.TYPE_TO_SEARCH
                    }
                    labelClassName={classes.form_label}
                    containerClassName={classes.form_input_container}
                    optionArray={issueList}
                    hideAvatar
                    disabled={
                      !values.property || props.dropdownIssueListLoading
                    }
                  />
                  {props.isEdit && (
                    <Dropdown
                      name='admin'
                      onChange={handleChange('admin')}
                      value={values.admin}
                      label={Strings.ADMIN}
                      error={Boolean(touched.admin && errors.admin)}
                      helperText={touched.admin && errors.admin}
                      title={Strings.TYPE_TO_SEARCH}
                      labelClassName={classes.form_label}
                      // className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      optionArray={adminList}
                      searchable
                    />
                  )}
                  <Dropdown
                    name='vendor'
                    onChange={handleChange('vendor')}
                    value={values.vendor}
                    label={Strings.VENDOR}
                    // error={Boolean(touched.vendor && errors.vendor)}
                    // helperText={touched.vendor && errors.vendor}
                    title={Strings.TYPE_TO_SEARCH_VENDOR}
                    labelClassName={classes.form_label}
                    // className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    optionArray={vendorList}
                    searchable
                    addOption
                    onAddClick={() => setOpenAddVendorDialog(true)}
                  />

                  <Textbox
                    name='vendorName'
                    onChange={handleChange('vendorName')}
                    value={values.vendorName}
                    label={Strings.VENDOR_NAME}
                    // error={Boolean(touched.vendorName && errors.vendorName)}
                    // helperText={touched.vendorName && errors.vendorName}
                    placeholder={Strings.TYPE_HERE}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classes.form_input_container}
                  />

                  {/* <Textbox
                    name='details'
                    onChange={handleChange('details')}
                    value={values.details}
                    label={Strings.JOB_SUMMARY}
                    // error={Boolean(touched.details && errors.details)}
                    // helperText={touched.details && errors.details}
                    placeholder={Strings.TYPE_HERE}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    multiline
                    rows={3}
                  /> */}
                  <Stack mt={1}>
                    <ReactQuillTextbox
                      id='jobSummary'
                      onChange={(value) => setFieldValue('details', value)}
                      value={values.details}
                      label={Strings.JOB_SUMMARY}
                    />
                  </Stack>

                  {/* {!props.isEdit && (
                    <Textbox
                      name='jobNotes'
                      onChange={handleChange('jobNotes')}
                      value={values.jobNotes}
                      label={Strings.INTERNAL_NOTES}
                      // error={Boolean(touched.details && errors.details)}
                      // helperText={touched.details && errors.details}
                      placeholder={Strings.TYPE_HERE}
                      labelClassName={classes.form_label}
                      className={classes.form_input}
                      containerClassName={classes.form_input_container}
                      multiline
                      rows={3}
                    />
                  )} */}
                  {!props.isEdit ? (
                    <ReactQuillTextbox
                      id='jobInternalNotes'
                      onChange={(value) => setFieldValue('jobNotes', value)}
                      value={values.jobNotes}
                      label={Strings.INTERNAL_NOTES}
                    />
                  ) : null}

                  {/* <Textbox
                    name='suppliesNeeded'
                    onChange={handleChange('suppliesNeeded')}
                    value={values.suppliesNeeded}
                    label={Strings.SUPPLIES_NEEDED}
                    placeholder={Strings.TYPE_HERE}
                    labelClassName={classes.form_label}
                    className={classes.form_input}
                    containerClassName={classes.form_input_container}
                    multiline
                    rows={3}
                  /> */}
                  {values?.appointments.map((item, index) => {
                    return (
                      <div
                        style={{ marginTop: 15 }}
                        key={`appointments + ${index}`}
                      >
                        <MultiSelectDropdown
                          name={`appointments[${index}].assignedMember`}
                          onChange={async (value) => {
                            setFieldValue(
                              `appointments[${index}].assignedMember`,
                              value
                            );
                            if (props.isEdit && item?.outlookEventId) {
                              item.appointmentType = 'Updated';
                            }
                          }}
                          value={teamMemberList?.filter((el) => {
                            return item.assignedMember.some(
                              (_el) => _el?.value === el?.value
                            );
                          })}
                          label={Strings.TEAM_MEMBER_ASSIGNED}
                          error={Boolean(
                            touched?.appointments?.length > 0 &&
                              touched?.appointments[index]?.assignedMember &&
                              errors?.appointments?.length > 0 &&
                              errors?.appointments[index]?.assignedMember
                          )}
                          helperText={
                            touched?.appointments?.length > 0 &&
                            touched?.appointments[index]?.assignedMember &&
                            errors?.appointments?.length > 0 &&
                            errors?.appointments[index]?.assignedMember
                          }
                          title={Strings.TYPE_TO_SEARCH}
                          labelClassName={classes.form_label}
                          // className={classes.form_input}
                          containerClassName={classes.form_input_container}
                          optionArray={teamMemberList}
                          isAddIcon={index === 0}
                          onClickAddIcon={() => {
                            let newApp = {
                              assignedMember: [],
                              startDate: new Date(),
                              startTime: moment(),
                              endDate: new Date(),
                              endTime: moment().add(1, 'hours'),
                            };
                            let appointmentNew = props.isEdit
                              ? { ...newApp, appointmentType: 'New' }
                              : { ...newApp };
                            let data = [
                              { ...appointmentNew },
                              ...values.appointments,
                            ];
                            setFieldValue('appointments', data);
                          }}
                          isDeleteIcon={values.appointments.length > 1}
                          onClickDeleteIcon={() => {
                            if (item?.outlookEventId) {
                              setDeleteAppointments([
                                ...deleteAppointments,
                                item?.outlookEventId,
                              ]);
                            }
                            let appointments = values.appointments.filter(
                              (i, ind) => ind !== index
                            );
                            setFieldValue('appointments', appointments);
                          }}
                        />
                        <DateTimeContainer
                          label={Strings.START_DATE_TIME}
                          containerClassName={classes.form_input_container}
                        >
                          <DateSelector
                            name={`appointments[${index}].startDate`}
                            onChange={async (value) => {
                              if (props.isEdit && item?.outlookEventId) {
                                item.appointmentType = 'Updated';
                              }
                              await setFieldValue(
                                `appointments[${index}].startDate`,
                                value
                              );
                              await setFieldValue(
                                `appointments[${index}].endDate`,
                                moment(value).add(1, 'hours')
                              );
                            }}
                            value={item.startDate}
                            error={Boolean(
                              touched?.appointments?.length > 0 &&
                                touched?.appointments[index]?.startDate &&
                                errors?.appointments?.length > 0 &&
                                errors?.appointments[index]?.startDate
                            )}
                            helperText={
                              touched?.appointments?.length > 0 &&
                              touched?.appointments[index]?.startDate &&
                              errors?.appointments?.length > 0 &&
                              errors?.appointments[index]?.startDate
                            }
                            format='MMM dd, yyyy'
                            placeholder={Strings.SAMPLE_DATE}
                            containerClassName={classes.date_selector_container}
                            // minDate={minStartDate}
                          />
                          <TimeSelector
                            name={`appointments[${index}].startTime`}
                            onChange={async (value) => {
                              if (props.isEdit && item?.outlookEventId) {
                                item.appointmentType = 'Updated';
                              }
                              await setFieldValue(
                                `appointments[${index}].startTime`,
                                value ? moment(value, 'hh:mm') : ''
                              );
                              if (value) {
                                await setFieldValue(
                                  `appointments[${index}].endTime`,
                                  value
                                    ? moment(value, 'hh:mm').add(1, 'hours')
                                    : ''
                                );
                              }
                            }}
                            value={item.startTime}
                            error={Boolean(
                              touched?.appointments?.length > 0 &&
                                touched?.appointments[index]?.startTime &&
                                errors?.appointments?.length > 0 &&
                                errors?.appointments[index]?.startTime
                            )}
                            helperText={
                              touched?.appointments?.length > 0 &&
                              touched?.appointments[index]?.startTime &&
                              errors?.appointments?.length > 0 &&
                              errors?.appointments[index]?.startTime
                            }
                            placeholder={Strings.SAMPLE_TIME}
                            handleDecreseTime={() => {
                              setFieldValue(
                                `appointments[${index}].startTime`,
                                moment(item.startTime).subtract(30, 'minutes')
                              );
                              setFieldValue(
                                `appointments[${index}].endTime`,
                                moment(item.startTime).add(30, 'minutes')
                              );
                            }}
                            handleIncreseTime={() => {
                              setFieldValue(
                                `appointments[${index}].startTime`,
                                moment(item.startTime).add(30, 'minutes')
                              );
                              setFieldValue(
                                `appointments[${index}].endTime`,
                                moment(item.startTime).add(1.5, 'hours')
                              );
                            }}
                            isIncreseDecrese={
                              item.startTime === '' ? false : true
                            }
                          />
                        </DateTimeContainer>
                        <DateTimeContainer
                          label={Strings.END_DATE_TIME}
                          containerClassName={classes.form_input_container}
                        >
                          <DateSelector
                            name={`appointments[${index}].endDate`}
                            onChange={async (value) => {
                              if (props.isEdit && item?.outlookEventId) {
                                item.appointmentType = 'Updated';
                              }
                              await setFieldValue(
                                `appointments[${index}].endDate`,
                                value
                              );
                            }}
                            value={item.endDate}
                            error={Boolean(
                              touched?.appointments?.length > 0 &&
                                touched?.appointments[index]?.endDate &&
                                errors?.appointments?.length > 0 &&
                                errors?.appointments[index]?.endDate
                            )}
                            helperText={
                              touched?.appointments?.length > 0 &&
                              touched?.appointments[index]?.endDate &&
                              errors?.appointments?.length > 0 &&
                              errors?.appointments[index]?.endDate
                            }
                            format='MMM dd, yyyy'
                            placeholder={Strings.SAMPLE_DATE}
                            containerClassName={classes.date_selector_container}
                            // minDate={minStartDate}
                          />
                          <TimeSelector
                            name={`appointments[${index}].endTime`}
                            onChange={async (value) => {
                              if (props.isEdit && item?.outlookEventId) {
                                item.appointmentType = 'Updated';
                              }
                              await setFieldValue(
                                `appointments[${index}].endTime`,
                                value ? moment(value, 'hh:mm') : ''
                              );
                            }}
                            value={item.endTime}
                            error={Boolean(
                              touched?.appointments?.length > 0 &&
                                touched?.appointments[index]?.endTime &&
                                errors?.appointments?.length > 0 &&
                                errors?.appointments[index]?.endTime
                            )}
                            helperText={
                              touched?.appointments?.length > 0 &&
                              touched?.appointments[index]?.endTime &&
                              errors?.appointments?.length > 0 &&
                              errors?.appointments[index]?.endTime
                            }
                            placeholder={Strings.SAMPLE_TIME}
                            handleDecreseTime={() => {
                              setFieldValue(
                                `appointments[${index}].endTime`,
                                moment(item.endTime).subtract(30, 'minutes')
                              );
                            }}
                            handleIncreseTime={() => {
                              setFieldValue(
                                `appointments[${index}].endTime`,
                                moment(item.endTime).add(30, 'minutes')
                              );
                            }}
                            isIncreseDecrese={
                              item.endTime === '' ? false : true
                            }
                          />
                        </DateTimeContainer>
                      </div>
                    );
                  })}

                  <FormSwitch
                    label={Strings.RECURRING + '?'}
                    value={values.recurring}
                    containerClassName={classes.form_input_container}
                    onChange={() =>
                      setFieldValue('recurring', !values.recurring)
                    }
                  />
                  {values.recurring && (
                    <>
                      {/* <Dropdown
                        name='frequency'
                        onChange={handleChange('frequency')}
                        value={values.frequency}
                        label={Strings.FREQUENCY}
                        error={Boolean(touched.frequency && errors.frequency)}
                        helperText={touched.frequency && errors.frequency}
                        title={Strings.SELECT_AN_OPTION}
                        labelClassName={classes.form_label}
                        // className={classes.form_input}
                        containerClassName={classes.form_input_container}
                        optionArray={frequencyArray}
                      /> */}
                      <DateTimeContainer
                        label={Strings.REPEAT_EVERY}
                        containerClassName={classes.form_input_container}
                      >
                        <Textbox
                          name='count'
                          onChange={handleChange('count')}
                          value={values.count}
                          type='number'
                          error={Boolean(touched.count && errors.count)}
                          helperText={touched.count && errors.count}
                          placeholder='Eg: 2'
                          labelClassName={classes.form_label}
                          className={classes.form_input}
                          onKeyDown={(e) => numberRegex(e)}
                          min={1}
                        />
                        <Dropdown
                          name='timePeriod'
                          onChange={handleChange('timePeriod')}
                          value={values.timePeriod}
                          error={Boolean(
                            touched.timePeriod && errors.timePeriod
                          )}
                          helperText={touched.timePeriod && errors.timePeriod}
                          // title={Strings.WORK_DAYS}
                          labelClassName={classes.form_label}
                          // className={classes.form_input}
                          optionArray={workingDaysArray}
                        />
                      </DateTimeContainer>
                      <DateTimeContainer
                        containerClassName={classes.form_input_container}
                      >
                        <DateSelector
                          name='recurringStartDate'
                          onChange={(value) =>
                            setFieldValue('recurringStartDate', value)
                          }
                          value={values.recurringStartDate}
                          error={Boolean(
                            touched.recurringStartDate &&
                              errors.recurringStartDate
                          )}
                          helperText={
                            touched.recurringStartDate &&
                            errors.recurringStartDate
                          }
                          label={Strings.START_DATE}
                          labelClassName={classes.form_label}
                          format='MMM dd, yyyy'
                          placeholder={Strings.SAMPLE_DATE}
                          minDate={new Date(minRecurringStartDate)}
                        />
                        <DateSelector
                          name='recurringEndDate'
                          onChange={(value) =>
                            setFieldValue('recurringEndDate', value)
                          }
                          value={values.recurringEndDate}
                          error={Boolean(
                            touched.recurringEndDate && errors.recurringEndDate
                          )}
                          helperText={
                            touched.recurringEndDate && errors.recurringEndDate
                          }
                          label={Strings.END_DATE}
                          labelClassName={classes.form_label}
                          format='MMM dd, yyyy'
                          placeholder={Strings.SAMPLE_DATE}
                          minDate={new Date(values.recurringStartDate)}
                        />
                      </DateTimeContainer>
                    </>
                  )}
                  <input
                    type='file'
                    ref={inputRef}
                    className={classes.selectFiles}
                    onChange={(event) => handleFileUpload(event)}
                    multiple
                    accept='*'
                  />
                  <Button
                    text={Strings.ADD_ATTACHMENTS}
                    className={classes.add_new_button}
                    leftIcon={icons(theme).attachmentIcon}
                    onClick={() => inputRef.current.click()}
                    loading={props.uploadFileLoading}
                    disabled={
                      props.uploadFileLoading ||
                      props.addJobLoading ||
                      props.updateJobLoading
                    }
                    loaderColor={theme.palette.background_button}
                  />
                  <Attachment
                    attachments={attachments}
                    onClickRemove={onClickRemove}
                    onClickPreview={onClickPreview}
                  />
                  <Button
                    type='submit'
                    text={
                      props.isEdit ? Strings.UPDATE_JOB : Strings.ADD_NEW_JOB
                    }
                    loading={
                      props.isEdit
                        ? props.updateJobLoading
                        : props.addJobLoading
                    }
                    disabled={
                      props.uploadFileLoading || props.isEdit
                        ? props.updateJobLoading
                        : props.addJobLoading
                    }
                    containerClassName={classes.add_button_container}
                    className={classes.add_button}
                    loaderColor={theme.palette.background_button_text}
                  />
                  {props.isEdit && (
                    <Button
                      text={Strings.DELETEJOB}
                      loading={props.deleteJobLoading}
                      disabled={
                        props.deleteJobLoading || props.updateJobLoading
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeleteJobDialog(true);
                      }}
                      containerClassName={classes.add_button_container}
                      className={classes.add_button}
                      loaderColor={theme.palette.background_button_text}
                    />
                  )}
                </div>
              </Form>
            </FormikProvider>
          )}
        </div>
        {openImageInfoDialog && (
          <MediaDetails
            fileInfo={fetchedFileInfo}
            onNewClose={() => {
              setOpenImageInfoDialog(false);
              setFetchedFileInfo();
            }}
            isOnlyMediaPreview={true}
            isAddTagsRequired={true}
          />
        )}
        {openPermissionDialog && (
          <CommonDialog
            title={Strings.PERMISSION}
            message={Strings.ASK_FOR_PERMISSION}
            onSuccess={() => handleJob(values, true)}
            onClose={() => setOpenPermissionDialog(false)}
            loading={
              props.isEdit ? props.updateJobLoading : props.addJobLoading
            }
            positiveBtnText={'Yes'}
            // negativeBtnText={'NO'}
          />
        )}
        {deleteJobDialog && (
          <CommonDialog
            title={Strings.JOBS}
            message={Strings.DELETE_JOB}
            onSuccess={() => onDeleteJob()}
            onClose={() => setDeleteJobDialog(false)}
            loading={props.deleteJobLoading}
          />
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  clientData: state.clientData.data,
  clientLoading: state.clientData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  addJobLoading: state.addJobData.loading,

  jobData: state.jobData.data,
  jobLoading: state.jobData.loading,

  updateJobLoading: state.updateJobData.loading,

  categoryListData: state.categoryListData.data,
  categoryListLoading: state.categoryListData.loading,

  jobFormsListData: state.jobFormsListData.data,
  jobFormsListLoading: state.jobFormsListData.loading,

  vendorDropDownListData: state.vendorDropDownListData.data,
  addVendorData: state.addVendorData.data,

  dropdownIssueListData: state.dropdownIssueListData.data,
  dropdownIssueListLoading: state.dropdownIssueListData.loading,

  uploadFileLoading: state.uploadFileData.loading,

  deleteJobLoading: state.deleteJobData.loading,
  deleteJobData: state.deleteJobData.data,
});

export default connect(mapStateToProps, {
  fetchClientById,
  fetchUserListByType,
  createJob,
  fetchJobById,
  updateJob,
  fetchCategoryList,
  fetchJobFormsList,
  fetchVendorDropDownList,
  uploadFile,
  deleteFile,
  dropdownIssueList,
  deleteJob,
})(NewJob);
